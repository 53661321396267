import AWS from "aws-sdk";
import Axios from "axios";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactExport from "react-data-export";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import ArrowImg from "../../../../../assets/images/arrow.png";
import {
  default as SingleVodaImg,
  default as VodafoneImg,
} from "../../../../../assets/vil/vil-red-logo.png";
import AxiosWeb from "../../../../../axios_ext";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import "../../VodafoneIdea.css";
import "../PriCaf/PRICafForm.css";
import SectionHeader from "../../vilBranding/SectionHeader";
import "./ac.css";
import "../../common.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AC extends Component {
  state = {
    acCafData: false,
    stopdidmount: 0,
    acDataUpdated: 1,
    authorizedSignName: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    companySeal: null,
    imageData: null,
    imageType: null,
    acctMngSignImageData: null,
    acctMngSignImageType: null,
    photoGraph: null,
    photoGraphImageData: null,
    photoGraphImageType: null,
    excelJson: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };
  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1692,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 16302) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 16303) {
            photoGraph = fieldObj.field_value;
            console.log("photoGraph:: " + photoGraph);
          }
          if (fieldObj.field_id == 16301) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("companySeal:: " + companySeal);
          console.log("photoGraph:: " + photoGraph);
          console.log("authorizedSignatoryName:: " + authorizedSignatoryName);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          custPhotoGraph: photoGraph,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.photoGraph);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 1687; // DIGITAL VSDM CAF form Id
    let orderform = 1841;
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        acCafData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12963
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+JSON.stringify(submittedFormArry));
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );
        this.setState({
          ...this.state,
          acCafData: true,
        });
      }

      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12963
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("16001") &&
      this.state.stopdidmount === 0
    ) {
      let x = this.props.fieldList;

      if (!!this.state.custPhotoGraph) {
        utils.loadImageBlob(
          this.s3,
          this.state.custPhotoGraph,
          (data, type) => {
            this.setState({
              ...this.state,
              photoGraphImageData: data,
              photoGraphImageType: type,
              stopdidmount: 1,
              acDataUpdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          acDataUpdated: this.props.fieldList,
        });
      }

      if (!!this.state.custCompanySeal) {
        utils.loadImageBlob(
          this.s3,
          this.state.custCompanySeal,
          (data, type) => {
            this.setState({
              ...this.state,
              imageData: data,
              imageType: type,
              stopdidmount: 1,
              acDataUpdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          acDataUpdated: this.props.fieldList,
        });
      }

      if (!!x[16125] && x[16125].value) {
        // manager sign url
        let acctManagerSignUrl = x[16125].value;
        utils.loadImageBlob(this.s3, acctManagerSignUrl, (data, type) => {
          this.setState({
            ...this.state,
            acctMngSignImageData: data,
            acctMngSignImageType: type,
            stopdidmount: 1,
            acDataUpdated: this.props.fieldList,
          });
        });
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          acDataUpdated: this.props.fieldList,
        });
      }
    }
  }

  render() {
    let planTariff = "";
    let planType = "";

    let acCafContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );

    if (
      this.state.acDataUpdated != null &&
      this.state.acDataUpdated !== "" &&
      this.state.stopdidmount == 1
    ) {
      let x = this.state.acDataUpdated;
      planType = this.state.acDataUpdated[19885].value;
      console.log("planType : " + planType);

      planTariff = this.state.acDataUpdated[16065].value
        ? this.state.acDataUpdated[16065].value
        : this.state.acDataUpdated[16066].value;

      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }
      let firstCaps = "";
      let secondBold = "";
      let Signcss;

      //console.log('this.state.authorizedSignName :: '+this.state.authorizedSignName);

      if (
        this.state.authorizedSignName != null &&
        this.state.authorizedSignName != ""
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        //console.log(firstCaps,"console.log(firstCaps);");
      }

      acCafContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ paddingTop: "57px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsStyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsStyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <SectionHeader name="Customer Application Form" />
                  <div className="over_hid w_85">
                    <div className="">
                      <div className="textcenter">
                        Unique ID
                        <input
                          className="form_col brdr_b1 "
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width75">
                      <p className="ligthbg">Name Address </p>
                      <p className="auth">
                        {" "}
                        Name of Company
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16003].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Comany's Registered
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16004].value}
                          readOnly
                        />
                      </p>
                      <div className="auth margin0">
                        <span className="width54px">Address</span>
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Landmark
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16005].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          State
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16007].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          Pincode
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16008].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landline No. +91
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16009].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="faxn">Fax No.</span>
                          <input
                            className="form_col width52 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16010].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          CIN Number
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16011].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="pad_t5">
                            Company PAN Card Number
                          </span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16012].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright cross_fill">
                      <div className="crossign" style={{ height: "100px" }}>
                        {this.state.photoGraphImageData != null &&
                        this.state.photoGraphImageData ? (
                          <div
                            style={{
                              padding: "5px",
                              position: "relative",
                              textAlign: "center",
                            }}
                            className="signate_img"
                          >
                            <img
                              style={{ height: "90px" }}
                              src={
                                this.state.photoGraphImageData != null
                                  ? "data:" +
                                    this.state.photoGraphImageType +
                                    ";base64," +
                                    this.encode(this.state.photoGraphImageData)
                                  : ""
                              }
                              alt={"PhotoGraph with Crosssign"}
                            />
                            {firstCaps != "" ? (
                              <span
                                style={{
                                  padding: "10px",
                                  position: "absolute",
                                  left: "-20px",
                                  top: "20px",
                                }}
                                className={Signcss}
                              >
                                {firstCaps + "  .  "}
                                <span className="under_line">{secondBold}</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">Basic Organisation Details </p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <p className="margin0">
                        <strong>
                          Address 1 (Authorised Signatory Location)
                        </strong>
                      </p>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Name</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16015].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Father's/ Husbands</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16016].value}
                          readOnly
                        />
                      </div>

                      <div className="auth margin0">
                        <span className="width54px">Nationality</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16017].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Billing Address</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16018].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">&nbsp;</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landmark
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16019].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">City</span>
                          <input
                            className="form_col width70 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16020].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          State *
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16021].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">Pincode</span>
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16022].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16023].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Landline No. +91</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16024].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Email ID</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16025].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width33">
                          Designation
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16026].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Emp.Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16027].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Vehicle No.
                          <input
                            className="form_col flright width33"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16028].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p>*Mandatory for GST registered companies</p>
                      <div className="check margin0 over_hid">
                        <div className="fleft width20">
                          GSTIN
                          {this.state.acDataUpdated[16049].value === "GSTIN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                        <div className="fleft width20">
                          UIN
                          {this.state.acDataUpdated[16049].value === "UIN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                        <div className="fleft width20">
                          GST_ISD
                          {this.state.acDataUpdated[16049].value ===
                          "GST_ISD" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="margin0">
                        <strong>Address 2 (Key contract Location)</strong>
                      </p>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Name</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16030].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Address</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16031].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">&nbsp;</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landmark
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16032].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">City</span>
                          <input
                            className="form_col width70 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16033].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          State *
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16034].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">Pincode</span>
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16035].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16036].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Landline No. +91</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16037].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Email ID</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16038].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width33">
                          Designation
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16039].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Emp.Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16040].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Vehicle No.
                          <input
                            className="form_col flright width33"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16041].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p className="margin0">
                        <strong>Organisation Type </strong>
                      </p>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft pad_r3">
                          {this.state.acDataUpdated[16042].value ===
                          "Partner Ship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Partnership
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.acDataUpdated[16042].value ===
                          "Proprietorship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Proprietorship
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.acDataUpdated[16042].value ===
                          "Private Limited" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Private Limited{" "}
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.acDataUpdated[16042].value ===
                          "Public Sector" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Public Sector
                        </div>
                        <div className="fleft">
                          {this.state.acDataUpdated[16042].value ===
                          "Government" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Government
                        </div>
                      </div>
                      <div className="margin0 over_hid pad_5t">
                        <div className="fleft">
                          <span className="">
                            {this.state.acDataUpdated[16042].value ===
                            "Others" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                            Others
                          </span>{" "}
                          <span className="auth">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16043].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid typecheck">
                        <div className="fleft width12 pad_t2">
                          <strong>Telemarketer</strong>
                        </div>
                        <div className="fleft width12">
                          {this.state.acDataUpdated[16044].value === "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft width12">
                          {this.state.acDataUpdated[16044].value === "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <div className="auth margin0">
                        {" "}
                        If Yes, Telemarketer Registration Number
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16045].value}
                          readOnly
                        />
                      </div>
                      <div className="check margin0 over_hid typecheck">
                        <div className="fleft pad_t2">
                          <strong>OSP &nbsp; &nbsp; </strong>
                        </div>
                        <div className="fleft width12">
                          {this.state.acDataUpdated[16046].value === "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft width12">
                          {this.state.acDataUpdated[16046].value === "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <div className="auth margin0">
                        {" "}
                        If Yes, OSP Registration Number
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16047].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="check margin0 over_hid typecheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Type
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16050].value === "Normal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Normal
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16050].value === "SEZ" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SEZ
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16050].value === "Diploma" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diploma
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16050].value === "Embassy" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Embassy
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16050].value === "Consulate" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consulate
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16050].value ===
                      "Special Agency" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Special Agency
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16050].value ===
                      "Person/Class of Persons" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Person/Class of Persons
                    </div>
                  </div>
                  <p className="auth">
                    GSTIN / UIN / GST_ISD No
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.acDataUpdated[16051].value}
                      readOnly
                    />
                  </p>
                  <div className="auth">
                    <span className="width70px in_block">
                      GST Regd. Address**
                    </span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.acDataUpdated[16052].value}
                      readOnly
                    />
                  </div>
                  <div className="auth">
                    <span className="width70px in_block">State**</span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.acDataUpdated[16053].value}
                      readOnly
                    />
                  </div>
                  <p>If GST Address is diffrent from Billing Address</p>
                  <div className="check over_hid typecheck gstcheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Dept
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "IT" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "Tech" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Tech
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value ===
                      "Cust Service" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Cust Service
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "Legal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Legal
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "Finance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finance
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "Purchase" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Purchase
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "SCM" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SCM
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "Delivery" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Delivery
                    </div>
                    <div className="fleft width12">
                      {this.state.acDataUpdated[16055].value === "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                    <div className="fleft width20 pad_t2">
                      Others
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16056].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="auth namsupp">
                    Name of Supporting Document
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.acDataUpdated[16057].value}
                      readOnly
                    />
                  </p>
                  <p className="gsttext">
                    (GST Certificate, SEZ Certificate, Diploma Crtificate)
                  </p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <p className="ligthbg">Proof of Identity Details</p>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Type of POI
                          <input
                            className="form_col width60"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16059].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Document No.</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16060].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width45">
                          Date of Issue
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16061].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          <span className="faxn">Place of Issue</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16062].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Issuing Authority</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16063].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 pad_5t">
                        {" "}
                        Multiple Connections (Please fill enclosed declaration)
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="ligthbg">Proof of Address Details</p>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Type of POA
                          <input
                            className="form_col width60"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16069].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Document No.</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16070].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width45">
                          Date of Issue
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16071].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          <span className="faxn">Place of Issue</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16072].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Issuing Authority</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16073].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Existing VIL Numbers (Count)</span>
                        <input
                          className="form_col width55 flright"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16074].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width40">
                      <span className="">Tariff Plan applied</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={planTariff}
                        readOnly
                      />
                    </div>
                    <div className="fleft width60">
                      <span className="">
                        Value Added Services applied (if any)
                      </span>
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16067].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">
                    Local Reference (For National Outstation and Foreign
                    National Customers)
                  </p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width45">
                      <span className="">Name</span>
                      <input
                        className="form_col width85 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16076].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      <span className="">Contact No.</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16077].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      <span className="">Time&Date of Call</span>
                      <input
                        className="form_col width45 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16079].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width75">
                      <span className="">Address</span>
                      <input
                        className="form_col width85 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16078].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      <span className="">
                        Calling Party's No.
                        <br />
                        <span className="fnt6">(to be filled by POS)</span>
                      </span>
                      <input
                        className="form_col width45 flright posinput"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16080].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="ligthbg">Current Requirement</p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <div className="auth margin0 pad_5t over_hid">
                        <div className="fleft width55">
                          <span className="">No. of connections required</span>
                          <input
                            className="form_col width25 flright"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16082].value}
                            readOnly
                          />
                        </div>
                        <div className="flright ">
                          <span className="">NDNC Regestration Opt IN</span>
                          <input
                            className="flright"
                            type="checkbox"
                            name=""
                            value=""
                          />
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf("No commercial Call/ SMS") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          1. No commercial Call/ SMS{" "}
                        </div>
                        <div className="flright">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf(
                              "Banking/ Insurance/ Financal Produts/ Credit Card"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          2. Banking/ Insurance/ Financal Produts/ Credit Card{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf("Real Estate") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          3. Real Estate{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf("Education") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          4. Education{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf("Health") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          5. Health{" "}
                        </div>
                        <div className="flright">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf("Consumer goods and Automoblies") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          6. Consumer goods and Automoblies{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf(
                              "Communication/ Broadcasting/ Entertainment/ IT"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          7. Communication/ Broadcasting/ Entertainment/ IT{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf("Tourism and Leisure") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          8. Tourism and Leisure{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.acDataUpdated[16083].value
                            .split("|")
                            .indexOf("Any other category") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          9. Any other category{" "}
                        </div>
                      </div>
                      <p className="margin0">Type of Service</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16084].value ===
                          "Mobile Voice" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Voice{" "}
                        </div>
                        <div className="fleft">
                          {this.state.acDataUpdated[16084].value ===
                          "Mobile Broadband 2G 3G" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Broadbond 2G/3G/4G{" "}
                        </div>
                        <div className="fleft">
                          {this.state.acDataUpdated[16084].value ===
                          "Wireline Voice (E1 DID)" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Wireline Voice (E1 DID){" "}
                        </div>
                        <div className="fleft">
                          {this.state.acDataUpdated[16084].value ===
                          "Domestic SIP Trunk" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Domestic SIP Trunk{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16084].value ===
                          "SIP Central" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          SIP Central{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value ===
                          "Domestic Toll Free" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Domestic Toll Free{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value ===
                          "International Toll Free" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          International Toll Free{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value ===
                          "Audio Conferencing" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Audio Conferencing{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16084].value ===
                          "Video Conferencing " ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Video Conferencing{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value ===
                          "2G/3G/4G API Solution" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          2G/3G/4G API Solution{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value === "GVN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GVN{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value ===
                          "Cloud Telephony" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Cloud Telephony{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16084].value ===
                          "Call Recording" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Call Recording{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value ===
                          "Mobile Applications" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Applications{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16084].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others
                          <span className="in_subput_date">
                            <input
                              className="form_col flright"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16085].value}
                              readOnly
                            />
                          </span>
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft pad_t3">
                          {" "}
                          <strong>Internet of Things</strong>{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16086].value ===
                          "Connectivity" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Connectivity{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16086].value ===
                          "Managed Connectivity" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Managed Connectivity{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16086].value ===
                          "SuperIOT" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Integrated IOT{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16086].value ===
                          "Location Based Services" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Location tracking solution{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16086].value ===
                          "Logistics Tracking" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Logistics Tracking{" "}
                        </div>
                        <div className="fleft ">
                          {this.state.acDataUpdated[16086].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput_date">
                            <input
                              className="form_col flright"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16087].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <p className="auth">
                        Tariff Plan
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={planTariff}
                          readOnly
                        />
                      </p>
                    </div>
                    <div className="flright width49">
                      <p className="margin0">Services on Voice</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16088].value === "NCUG" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          NCUG{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16088].value === "IR" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          IR{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16088].value ===
                          "Itemised Bill" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Itemised Bill{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16088].value === "GPRS" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GPRS{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16088].value === "ISD" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          ISD{" "}
                        </div>
                        <div className="fleft pad_l_2 ">
                          {this.state.acDataUpdated[16088].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16089].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <p className="margin0">Value Added Services:</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16090].value === "CCT" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          CCT{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16090].value === "MCI" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          MCI{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16090].value ===
                          "Enterprise Alerts" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Enterprise Alerts{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16090].value === "VMC" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          VMC{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16090].value ===
                          "Mobile Internet 2G/3G/4G" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Internet 2G/3G/4G{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {this.state.acDataUpdated[16090].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16091].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <p className="margin0">Devices</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16092].value ===
                          "BlackBerry" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Blackberry{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16092].value ===
                          "Smartphone" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Smartphone{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16092].value ===
                          "Tablet" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Tablet{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16092].value ===
                          "Netbook" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Netbook{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16092].value ===
                          "USB Stick" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          USB Stick{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16092].value ===
                          "Moible Wifi" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Moible Wifi{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.acDataUpdated[16092].value ===
                          "Sharing Dock" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Sharing Dock{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.acDataUpdated[16092].value ===
                          "GSM Gateway" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GSM Gateway{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {this.state.acDataUpdated[16092].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16093].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {" "}
                          CUG Code{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16097].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      E-Bill Opt In (Go Green)
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width30"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16095].value}
                        readOnly
                      />
                    </div>
                    <div className="flright width50">
                      {" "}
                      E-Mail ID for sending bills
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16096].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">Payment Details</p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width70">
                      <span className="">
                        Name of Person Responsiable for Payments
                      </span>
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16099].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      <span className="">Mobile No. +91</span>
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16100].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid font_8">
                    <div className="fleft width30"> Billing Address</div>
                    <div className="fleft pad_l_5">
                      {this.state.acDataUpdated[16101].value ===
                      "Authorised Sign Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Authorised Sign Location
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.acDataUpdated[16101].value ===
                      "Key Contact Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Key Contact Location
                    </div>
                    <div className="fleft ">
                      {this.state.acDataUpdated[16101].value ===
                      "Company Registered Address" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Company Registered Address
                    </div>
                  </div>
                  <div className="check margin0 over_hid font_8">
                    <div className="fleft pad_l_5 width30"> Payment Mode</div>
                    <div className="fleft pad_l_5">
                      {this.state.acDataUpdated[16102].value === "ECS" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ECS
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.acDataUpdated[16102].value === "SI" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SI
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.acDataUpdated[16102].value === "CHEQUE" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CHEQUE
                    </div>
                    <div className="fleft pad_l_5 pad_5t">
                      {" "}
                      Check (for ECS and SI, please fill adational form){" "}
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft">
                      {" "}
                      Deposit Amount{" "}
                      <span className="in_subput">
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16103].value}
                          readOnly
                        />
                      </span>{" "}
                    </div>
                    <div className="fleft pad_l10"> Deposit Type</div>
                    <div className="fleft pad_l_2">
                      {" "}
                      Security
                      {this.state.acDataUpdated[16104].value === "Security" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2">
                      {" "}
                      IR Deposit
                      {this.state.acDataUpdated[16104].value ===
                      "IR Deposit" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2">
                      {" "}
                      Others
                      {this.state.acDataUpdated[16104].value === "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2 ">
                      {" "}
                      <span className="in_subput">
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16105].value}
                          readOnly
                        />
                      </span>{" "}
                    </div>
                  </div>
                  <p className="ligthbg">Customer Declaration</p>
                  <div className="check margin0 over_hid">
                    <div className="fleft width70">
                      <div className="pad_r15_pri">
                        I/We agree to refundable security deposit and all
                        charges associated with the services selected by me/us.
                        I/We have read and terms and conditions mentioned
                        overleaf and unconditionally accept them as binding on
                        me/us. I/'We have understood all the rates. tariffs and
                        other related co it telecommunications services be
                        provided inside and outside India as applicable as on
                        this date and as amended from time to time. I/We hereby
                        undertake to pay all charges raised on account Of
                        Services availed, l/We undertake that in the event this
                        connection is used for any telemarketing purpose. I/We
                        shall be solely responsible for registration of the
                        connection with the mandated statutory authorities.
                        further declare and undertake that above information
                        provided by me/us is true and correct in all respect.
                        I/We understand that the SIM non-transferable. Any
                        misuse of SIM by customer or any other person is illegal
                        and shall be liable for criminal actions.
                      </div>
                      <div className="check margin0 over_hid pad_t10">
                        <div className="">
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                          I agree to receive a welcome mail giving details of
                          service(s) subscribed{" "}
                        </div>
                      </div>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td width="50%">
                                {firstCaps != "" ? (
                                  <div
                                    style={{
                                      padding: "10px",
                                      position: "absolute",
                                    }}
                                    className={Signcss}
                                  >
                                    {firstCaps + "  .  "}
                                    <span className="under_line">
                                      {secondBold}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="50%">
                                {this.state.imageData != null &&
                                this.state.imageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      src={
                                        this.state.imageData != null
                                          ? "data:" +
                                            this.state.imageType +
                                            ";base64," +
                                            this.encode(this.state.imageData)
                                          : ""
                                      }
                                      alt={"Company Seal"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col flright"
                                      type="text"
                                      name=""
                                      value=""
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">VIL Internet Use</p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width60">
                      <span className="">Enterprise Description Code</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16107].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      <span className="">Large Account Code</span>
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16108].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width60">
                      <span className="">FA/BA/PCN ID</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16109].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft width49">
                      <div className="check margin0 over_hid">
                        <div className="fleft width65 pad_r15_pri">
                          {" "}
                          Verification by Dealer/Agent (Distributor/ Retailer):
                          I confirm that the applicant has signed the form and
                          documents attached in my presence and that the photo
                          affixed in this form is of the applicant. I have
                          verified the documents With their respective originals
                          and certify the same to be true. I further confirm
                          that all necessary documents are in order including
                          that the signatures on the self attested photo match
                          with the signature on application form and Photo on
                          the form matches the one on the photo ID document.{" "}
                        </div>
                        <div className="flright width34">
                          {this.state.acctMngSignImageData != null &&
                          this.state.acctMngSignImageData != "" ? (
                            <img
                              height="75px"
                              width="75px"
                              src={
                                this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != ""
                                  ? "data:" +
                                    this.state.acctMngSignImageType +
                                    ";base64," +
                                    this.encode(this.state.acctMngSignImageData)
                                  : ""
                              }
                              alt={"Sign"}
                            />
                          ) : (
                            ""
                          )}
                          <div>Sign in with Seal/Stamp &nbsp; &nbsp; </div>
                          <div className="in_subput_date">
                            Date
                            <input
                              className="form_col"
                              type="text"
                              name=""
                              value={this.state.acDataUpdated[16160].value}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="ligthbg">
                        Declaration of VIL employee activating SIM
                      </p>
                      <div className="check margin0 over_hid">
                        <div className="fleft width55 pad_r15_pri">
                          {" "}
                          I hereby confirm that the required documentation Such
                          as POI/POA is available and that details of the
                          Customer is complete and have been entered in the
                          database.{" "}
                        </div>
                        <div className="flright width45">
                          <div className="activatesim">
                            <div className="check margin0 over_hid">
                              <div className="flright width70">
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <img
                                    height="25px"
                                    width="75px"
                                    src={
                                      this.state.acctMngSignImageData != null &&
                                      this.state.acctMngSignImageData != ""
                                        ? "data:" +
                                          this.state.acctMngSignImageType +
                                          ";base64," +
                                          this.encode(
                                            this.state.acctMngSignImageData
                                          )
                                        : ""
                                    }
                                    alt={"Sign"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="flright width70">
                                <div className="font7">
                                  <div className="in_subput">
                                    Employee Name
                                    <input
                                      className="form_col width40px"
                                      type="text"
                                      name=""
                                      value=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="font7 in_subput">
                                  <input
                                    className="form_col width100per"
                                    type="text"
                                    name=""
                                    value={
                                      this.state.acDataUpdated[16127].value
                                    }
                                    readOnly
                                  />
                                </div>
                                <div className="font7">
                                  <div className="in_subput">
                                    Designation
                                    <input
                                      className="form_col width40px"
                                      type="text"
                                      name=""
                                      value=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="font7">
                                  <div className="in_subput_date">
                                    Date
                                    <input
                                      className="form_col"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.acDataUpdated[16160].value
                                      }
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                    Cellular Limited )<br />
                    An Adity Birla Group and Vodafone partneship
                    <br />
                    MergerCo CIN No: L32100GJ1996PLC030976
                    <br />
                    <strong>Registred Office:</strong> Suman Tower, Plot no. 18,
                    Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79 66714000
                    | F: +91 79 23232251
                    <br />
                    <strong>Circle Office Address:</strong>{" "}
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="ligthbg font8">TERMS &amp; CONDITIONS </p>
                  <p className="font10">
                    <strong>
                      The "Customer Application Form" (CAF) shall mean the
                      Customer information form. to which the following
                      conditions ("Regulatory Terms") are required to be
                      provided/completed with effect from /prior to activation
                      of any Services, and continue during the term of service.
                      These are conditions specified by the Department of
                      Telecommunication ("DOT) Ministry of Communication and
                      Information Technology ("MCIT"), Government of India
                      ("Go") and are mandatory conditions. There shall be no
                      deletion or deviation from the following Regulatory Terms.
                      This CAF shall be read with the Product Specific Terms
                      which shall form the Agreement between the Parties.
                      Licensed Service Area • shall mean the area where Operator
                      is permitted to offer telecommunication services, (i)
                      Operator • shall mean VIL entity having license to operate
                      in Circle. o 'Authority' shall mean the Department of
                      Telecommunications (DOT). Telecom Regulatory Authority of
                      India (TRAI), Ministry of Communication and Information
                      Technology (MOCIT), Government of India (GOI) and includes
                      any officer of the Authority,' "Corporate Plan" shall mean
                      tariff structure devised by for Corporate Customers. •
                      'Charges' means all fees, charges/ tariffs,
                      interconnection cost and rates chargeable by VIL from time
                      to time for provisioning the Services to the Customer •
                      'Customer' shall mean Corporate organization named in the
                      CAF entering into the requisite contract comprising of
                      CAF, Verification documents etc, For Internet of Things
                      products, refer Intemet Of Things GTCs agreed by the
                      customer.{" "}
                    </strong>{" "}
                  </p>
                  <div className="over_hid">
                    <div className="fleft width49">
                      <ul className="list">
                        <li>
                          It is agreed betweet "Vodafone Idea Limited (VIL)" &
                          "Customer" as fallows;
                        </li>
                        <li>
                          1. PROVISION SERVICES: VIL agrees to provide the
                          services to the Customer subject to terms and
                          condition of the Agreement.{" "}
                        </li>
                        <li>
                          2. OBLIGATIONS OF VIL: VIL shall provide services with
                          reference to and subject to the telecommunication
                          permitting the operations of a telephony services. The
                          Customer expressly agrees that activation of the
                          Services shall be Subject to verification of the
                          particulars and documents submitted by the Customer
                          including tele-verification. The availability and
                          quality of services may be affected by factor outside
                          VIL control such as physical obstruction. geographic
                          and weather conditions and other cause of radio
                          interference or faults in other telecommunications
                          network to which network is connected. The Services
                          may be Suspended in Whole or in part at any time with
                          proper notice and in compliance to existing
                          guidelines. policies and regulations.. however if the
                          network fails or requires modifications or maintenance
                          due to a sudden or force majeure event beyond control
                          of operator. such advance notice may not be possible.
                          Please note all extra discounting promos on special
                          deals or on Corporate Plans and Closed User Group
                          (COG). Vi <sup>TM</sup> Mobile Connect. Vi{" "}
                          <sup>TM</sup> Live. Blackberry (BB) and other Data
                          services along with respective discounts on usage will
                          be activated minimum of 72hrs from date of number
                          activation or the request as the case may be, The
                          allotment of a number will be made by VIL in its
                          absolute discretion. In the event of any provisioned
                          SIM ( where a Service involves a provisioning of SIM)
                          being lost or stolen. VIL will replace the SIM card as
                          soon as it is reasonable & practicable. subject to the
                          recovery of any charges for the same. VIL reserve the
                          right to apply a monthly financial limit and such
                          other conditions for charges incurred by the Customer
                          and to demand interim advance payment and also suspend
                          (and/or disconnect access to the services if such
                          limit is exceeded with proper intimation and in
                          compliance to existing guidelines, policies and
                          regulations VIL has the right to check the credential
                          Of the Customer including the Customer financial
                          standing & to use the services of any person or agency
                          for such purposes. Any waiver. concession or extra
                          time allowed or granted by VIL to the Customer is
                          limited the specific circumstances in which it was
                          given and the same shall not effect VIL's right under
                          this agreement in any ways.
                        </li>
                        <li>
                          3. OBLIGATIONS OF THE CUSTOMER: The Customer shall
                          ensure (i) that it signs all bulk connection
                          declarations and forms as are created pursuant to DOT
                          guidelines on bulk connections; (ii) that it has the
                          users comply with the said regulations and conclude
                          the tele-verification process by calling the call
                          centre at 1 17 so as to enable VIL to activate his/her
                          subscribed service plan: (iii) thet it shall not use
                          or cause or allow others to use the Services for any
                          improper. immoral or unlawful purpose including in any
                          manner which may jeopardise or impair the operation Of
                          the Network and/or the Services. Customer agrees to
                          inform VIL immediately and confirm the same in writing
                          if the Equipment and/or the SIM Card is lost. stolen
                          or damaged. The Customer will remain liable for all
                          Charges incurred until the SIM Card is de-activated by
                          VIC (iv) to furnish correct and complete information
                          and documents as required by VIL from time to time.
                          The Services agreed to be provided by VIL shall always
                          be subject to verification Of the Customer'S
                          credentials and documents and if at any time, any
                          information and/or documents furnished by the Customer
                          Mare found incorrect or incomplete or suspicious. VIL
                          shall be entitled to suspend/terminate the Service
                          forthwith without any further notice. In case any
                          change of address of the Customer or end user. the
                          Customer agrees to inform VIL in writing with regard
                          to change in address and furnish documents in support
                          Of such new address. In case during any time during
                          the subscription, in case the Customer does not inform
                          with regard to change in his address or end user then
                          VIL reserves its right to disconnect the Services
                          and/or cancel the connection without any notice in
                          this regard and VIL shall not be liable to the
                          Customer/end user in any manner whatsoever.{" "}
                        </li>
                        <li>
                          4. DOT LICENSING AND VERIFICATION OF SUBSCRIBE: (i)
                          VIL may be requited to disclose any information
                          pertaining to the Customer/Subscriber to any statutory
                          / regulatory / security authority, financial
                          institution or bank and as per regulation. VIL can do
                          so without any prior intimation to the Customer: (ii)
                          Customer shall indemnify and keep indemnified VIL for
                          all and any claims. proceedings or actions brought
                          against VIL arising out of any breach of the Customer
                          of the Regulatory Terms: (iii) VIL may use. process
                          and/or transfer Personal Information: (iv) in
                          connection with the provision Of Services; (v) to
                          incorporate Personal Information into databases
                          controlled by VIL for the purpose of account
                          administration, billing and reconciliation.
                          operational maintenance and support activities. fraud
                          detection and prevention. and customer and market
                          analysis and reporting. (vi) The Customer shall use
                          the Services only for the purposes Specified by the
                          Customer in the Agreement / Customer Application Form
                          (or Customer Order Form or Customer Information Form
                          hereinafter CAF/COF/CIF) and shall not be used for or
                          connected to any telecommunication service unless
                          otherwise permitted by the Governmental Authority with
                          specific approvals/ licenses. The Customer understands
                          and agrees that VIL shall be entitled to terminate the
                          Service and collect financial penalty as levied by the
                          Governmental Authority for any unauthorized use. VIL
                          shall also. at its sole discretion, be entitled to
                          disconnect the unauthorized circuit with immediate
                          effect. The Services shall not be used for call
                          center/ telemarketing/ other service provider (OSP)
                          operations without registration With the Department of
                          Telecommunications and/ or Telecom Regulatory
                          Authority of India as a call center/ OSP. The Customer
                          who wants to take the services for call center/ OSP
                          operations shall submit copy of call center/ OSP
                          license or registration to VIL (vii)The Customer shall
                          be allotted a phone number/iden- tity number by VIL
                          which is and shall always remain the property of VIL.
                          The Customer cannot transfer market, re•brand. resell
                          and/or otherwise assign Services purchased including
                          the phone number/iden tity number. Services to any
                          Other person/entity unless agreed by VIL in writing.
                          (viii) The Customer undertakes to comply with the
                          applicable laws and regulations regarding the use Of
                          the Service. including without limitation. the TRAI
                          Act 1997. the Indian Telegraph Act 1885. the Indian
                          Wireless
                        </li>
                      </ul>
                    </div>
                    <div className="fright width49">
                      <ul className="list">
                        <li>
                          Telegraphy Act 1933. Information Technology Act 2000
                          and all amendments, replacements, rules and
                          regulations made under such legislations from time to
                          time. The Customer shall obtain any relevant consents
                          and approvals for the installation and use of any
                          equipment located at the Customer sites and associated
                          with the Service and any permits, consents or
                          approvals required by the Subscriber to use the
                          Service. including any applicable other service
                          provider permits ("Permits"). Customer shall use the
                          Services in accordance with the Permits. Customer
                          shall provide VIL with an up-to-date copy of the
                          Customefs CIJG approval (if any) issued by the
                          Department Of Telecommunications from time-to-time.
                          Customer shall ensure that any network equipment (not
                          provided by Services Provider) which it uses in
                          relation to the Services meet the relevant
                          International Telecommunication Union
                          (ITU)/Telecommunication Engineering Centre (TEC)
                          standarc'fr (ix) The Customer shall ensure that
                          unauthorized or any Other Content. messages or
                          communica- tians infringing copynght. Intellectual
                          property right and international & domestic cyber
                          laws. in any form or inconsistent with the laws of
                          India. are not carried in his network by him or any
                          other person using his network or dunng the
                          provisioning of Semces. The Customer must take all
                          necessary measures to prevent such use, The use of the
                          Service for anti-national activities shall be
                          construed as an offence punishable under the Indian
                          Penal Code or other applicable law. The Customer must
                          ensure that any service provided by Service Provider
                          is not used for any unlawful purposes or any Other
                          person using the Services as End User. G) Customer
                          agrees and acknowledges that regulatory and law
                          enforcement agencies Law Enforcement Agency (LEA),
                          shatt have rights to access the network and intercept
                          or listen or record calls being made using the
                          Services and facilities provided by Service Provider
                          and Customer shall provide necessary
                          facilities/access/consent to Service Provider and Law
                          Enforcement Agency (LEA), including but not limited to
                          T RAJ. DOT. Police authorities. Reserve Bank of India.
                          Enforcement Directorate and Or any other Government
                          Agencies to monitor. contiol. prevent counteract
                          espionage. subversive act sabotage or any other
                          unlavvfulactivity. (xi) Customer understands Customer
                          and its End Users use the Services at their own risk
                          and consequences and Service Provider has no control
                          over and accepts no responsibility whatsoever for such
                          the Customer use of the Services and the transacted
                          business/transmitted communication and Customer shall
                          comply with all regulatory/legal and Statutory
                          requirements during such use of the Services. (xii)
                          Customer understands and agrees that Service Provider
                          shall terminate the Service provided to the Customer
                          immediately without any prior notice. upon any
                          violation of these terms and conditions by the
                          Customer and/or On account Of any regulatory violation
                          by Customer or its End Users in using the Service or
                          in case of any directive of any Law Enforcement Agency
                          (LEA), Department Of Telecommunication ("DOT"),
                          Judicial authority or any other Government agency.
                          (xiii) The customer is required to maintain and
                          provide updated list Of actual users to VIL at ad
                          times and the customer agrees to give VIL the
                          unhindered right to visit the customer premises and
                          verify bonafide use of the servrces , (o) Customer
                          shall not use the Services for any abusive, improper.
                          immoral. offensive. illegal or fraudulent purposes
                          including making calls or sending data that in VILS
                          reasonable opinion is a nuisance. hoax, menacing,
                          indecent. racist. obscene and/ Or defamatory or do
                          anything that causes the Network to be impaired or
                          damaged. Using Services to access the Internet: In the
                          event that Customer or End User uses Services to
                          access facilities and services not included in a
                          Tariff, VIL shall not be liable under this Agreement
                          for the security or confidentiality of such facilities
                          or services. (xiv) No information with regard to an
                          account information can be shared with any third party
                          other than the duly notified authorized signatory of
                          the Customer as notified by Customer to VIL and VIL
                          cannot provide any account related information Outside
                          India.{" "}
                        </li>
                        <li>
                          5. CONTRACT TERMS AND COMMERCIAL TERMS: This CAF,
                          shall be read With the General Terms and Conditions
                          except where any master services agreement (MSA) is
                          entered including (or any India specific in-country
                          agreement (ICC) between the Customer and Service
                          Provider entered subsequent to or pursuant to any
                          globat level MSA entered between the global
                          principals/affiliates Of the Customer and Service
                          Provider) and the MSA provisions shall prevail over
                          the GTC). Any Specific terms agreed for the mobility
                          shall be set Out in the Part C Or a Product Terms and
                          Conditions and Schedules to the GTC. The CAF terms
                          will prevail over any provisions in the GTC/ or the
                          MSA/ICC. which conflict With terms in CAF. All types
                          of Voice. SMS and Data offerings are a part of the
                          licensed services. The usage charges for these
                          services would be governed by the Tariff plan opted by
                          the customer.
                        </li>
                        <li>
                          6. GOVERNING LAW: This CAF shall be subject to Indian
                          Law and courts Of jurisdiction shall include Courts in
                          the place where Customer has a place of business or
                          where the cause of action arises or the place where
                          the Services have been offered.{" "}
                        </li>
                        <li>
                          7. MISCELLANEOUS: The Authorised Signatory signing
                          this CAF on behalf of the customer hereby confirm,
                          consent and authorize VIL to sell Vodafone Reload Plan
                          or such other plans as may be branded by VIL for their
                          COCP users. Such Plan/s will be activated upon the end
                          user's request and their advance payments made
                          directly by the end user either through VILS dedicated
                          channels or including but not limited to Vodafone
                          website, app. etc.. with no liability on the
                          enterprise customer entity. Subscriber hereby declare
                          and gives their consent to VIL for the collection,
                          processing and use Of their personal data such as.
                          including but not limited to. their name. contact
                          details and employment related information for the
                          purposes of ustng the Services and additional services
                          according to VIL's Privacy Policy displayed on its
                          website www.myvi.in.
                        </li>
                        <li>
                          8. GST &amp; SEZ STATUS: The customer will be solely
                          responsible for providing the correct GST number and
                          'SEZ" related information against the "Place Of
                          Supply" as per the GST law. S/he waives Off all the
                          rights to dispute or withhold any payments in case of
                          incorrect GST Numbers or incorrect SEZ certificates/
                          status being provided. which donet match the details
                          available on the Government GSTN portal. The customer
                          acknowledges that s/he will not be able to claim the
                          GST tax credit under such circumstances and shall pay
                          100% invoiced amount. including taxes.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p className="ligthbg font8">Customer Acceptance </p>
                  <div className="margin0 over_hid ">
                    <div className="fleft width70">
                      <p className="auth pad_t10">
                        Customer Name
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16111].value}
                          readOnly
                        />
                      </p>
                      <p className="auth pad_t10">
                        Designation
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16112].value}
                          readOnly
                        />
                      </p>
                      <p className="auth pad_t10">
                        Company Name
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16113].value}
                          readOnly
                        />
                      </p>
                      <p>
                        <strong>
                          Note: I/We accept the responsiblates of providing the
                          list of mobile nos/DID nos with user name &nbsp;
                          Designation, alongwith CFA
                        </strong>
                      </p>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td width="50%">
                                {firstCaps != "" ? (
                                  <div
                                    style={{
                                      padding: "10px",
                                      position: "absolute",
                                    }}
                                    className={Signcss}
                                  >
                                    {firstCaps + "  .  "}
                                    <span className="under_line">
                                      {secondBold}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="50%">
                                {this.state.imageData != null &&
                                this.state.imageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      src={
                                        this.state.imageData != null
                                          ? "data:" +
                                            this.state.imageType +
                                            ";base64," +
                                            this.encode(this.state.imageData)
                                          : ""
                                      }
                                      alt={"Company Seal"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col flright"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.acDataUpdated[16114].value
                                      }
                                      readOnly
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg font8">
                    {" "}
                    Fulfilment and channel details{" "}
                  </p>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Documents Verified by
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16117].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Date
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16118].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Channel Partner</div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16119].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16120].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Vi <sup>TM</sup> Store
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16121].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16122].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Vi <sup>TM</sup> No.
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16123].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Sim Card No.
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16124].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft width70">
                      <div className="pad_r15 pad_t10">
                        I/We hereby undertake and confirm that I/We have seen
                        the customer and certify that (a) the customer enrolment
                        form has been duly filled by the applicant, (b) the
                        photograph submitted has been matched with the
                        applicant, (c )the form has been personally signed by
                        the applicant in my presence, (d) proof of identity &
                        address has been collected, (e) the original proof of
                        identity & address has been matched and verified with
                        the self-attested documents submitted by the applicant.
                        Details on the Vi <sup>TM</sup> CAF Match with these
                        documents. In case of outstation customer, I/ We hereby
                        confirm that I/We tele-verified the particulars
                        furnished by the applicant with local reference and
                        recorded its correctness. Name of Local Reference
                        contacted ( for Outstation subscribers).
                        <span className="in_subput">
                          <input
                            className="form_col width150px"
                            type="text"
                            name=""
                            value=""
                            readOnly
                          />
                        </span>{" "}
                      </div>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      height="25px"
                                      width="100px"
                                      src={
                                        this.state.acctMngSignImageData !=
                                          null ||
                                        this.state.acctMngSignImageData != ""
                                          ? "data:" +
                                            this.state.acctMngSignImageType +
                                            ";base64," +
                                            this.encode(
                                              this.state.acctMngSignImageData
                                            )
                                          : ""
                                      }
                                      alt={"Signature of Account Manager"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col flright"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.acDataUpdated[16160].value
                                      }
                                      readOnly
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width40">
                      Enterprise Code
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16126].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width35">
                      Account Manager Name
                      <input
                        className="form_col width45"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16127].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Code
                      <input
                        className="form_col width70 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16128].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            {planType !== "Jumbo plan 1" ? (
              <page size="A4">
                <div class="section2">
                  <div class="font8">
                    <div class="over_hid">
                      <div class="fleft width70 textcenter">
                        <p>
                          {" "}
                          This TEF includes Schedule 1 (General Terms and
                          Conditions for Delivery of Services); Schedule 2
                          (Product Specific); and Schedule 3 (Regulatory
                          Requirements and Compliance)
                        </p>
                      </div>
                      <div class="flright width30 text_l">
                        {" "}
                        <img src={VodafoneImg} />{" "}
                      </div>
                    </div>
                    <p class="redcolor font15 margin0"> Exhibit - 1 </p>
                    <p class="redcolor font20">
                      <strong>Tariff and Tariff Enrollment Form</strong>
                    </p>
                    <p class="font15">
                      <strong>Audio Conferencing Services</strong>
                    </p>
                    <p class="auth">
                      Total no. of employees in organization*:
                      <input
                        class="form_col width72 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16131].value}
                        readOnly
                      />
                    </p>
                    <p class="auth">
                      Total number of conference users in organization
                      (recommended 1:10 owner IDs)*:
                      <input
                        class="form_col width45 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16132].value}
                        readOnly
                      />
                    </p>
                    <p class="pad_t20">
                      <strong>Requirement – Type of Conferencing:</strong>
                    </p>
                    <div class="check margin0 over_hid typecheck ">
                      {this.state.acDataUpdated[16133].value ===
                      "Basic (Reservation less/Automated)" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Basic (Reservation less/Automated){" "}
                    </div>
                    <div class="check margin0 over_hid typecheck">
                      {this.state.acDataUpdated[16133].value ===
                      "Enhanced (Operator Assisted/Event)" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Enhanced (Operator Assisted/Event){" "}
                    </div>
                    <p class="pad_t20">
                      <strong>
                        {" "}
                        PORT CHARGES (INCLUSIVE OF LOCAL NUMBER DIAL IN
                      </strong>
                    </p>
                    <div class="domestictable text_l width90">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <strong>Conference Type</strong>{" "}
                            </td>
                            <td>
                              <strong>Rate INR/Min </strong>
                            </td>
                            <td>
                              <strong>Minutes of Usage</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Basic</td>
                            <td>{this.state.acDataUpdated[16136].value}</td>
                            <td>{this.state.acDataUpdated[16137].value}</td>
                          </tr>
                          <tr>
                            <td>Enhanced</td>
                            <td>{this.state.acDataUpdated[16139].value}</td>
                            <td>{this.state.acDataUpdated[16140].value}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="pad_t20">
                      <strong> ACCESS (Optional)</strong>
                    </p>
                    <div class="domestictable text_l width70">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <strong>Access Type</strong>{" "}
                            </td>
                            <td>
                              <strong>Rate INR/Min</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Domestic Toll Free</td>
                            <td>{this.state.acDataUpdated[16142].value}</td>
                          </tr>
                          <tr>
                            <td>Domestic Dial Out</td>
                            <td>{this.state.acDataUpdated[16144].value}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="pad_t20">
                      <strong>Recording (Optional)</strong>
                    </p>
                    <div class="domestictable text_l width70">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              Recording required?
                              <br />
                              (INR 500 per recording instance){" "}
                            </td>
                            <td>
                              <div class="check margin0 over_hid typecheck ">
                                {this.state.acDataUpdated[16147].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes{" "}
                              </div>
                              <div class="check margin0 over_hid typecheck ">
                                {this.state.acDataUpdated[16147].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No{" "}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="textcenter">
                              <p class="textcenter">
                                Please include the moderators that require
                                recording under the moderator list
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      <strong>
                        {" "}
                        INTERNATIONAL TOLL FREE ACCESS (Optional)
                      </strong>
                    </p>
                    <div class="over_hid">
                      <div class="fleft width45">
                        <div class="intertable">
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                          >
                            <tbody>
                              <tr>
                                <th>Country</th>
                                <th>Charges (Rs/min)</th>
                              </tr>
                              <tr>
                                <td>Argentina </td>
                                <td> 17.50</td>
                              </tr>
                              <tr>
                                <td>Australia </td>
                                <td> 6.00</td>
                              </tr>
                              <tr>
                                <td>Austria </td>
                                <td> 11.50</td>
                              </tr>
                              <tr>
                                <td>Austria Mobile </td>
                                <td> 24.75</td>
                              </tr>
                              <tr>
                                <td>Bahrain </td>
                                <td> 10.00</td>
                              </tr>
                              <tr>
                                <td>Bahrain Mobile </td>
                                <td> 13.50</td>
                              </tr>
                              <tr>
                                <td>Belgium </td>
                                <td> 11.50</td>
                              </tr>
                              <tr>
                                <td>Belgium Mobile </td>
                                <td> 49.00</td>
                              </tr>
                              <tr>
                                <td>Bermuda </td>
                                <td> 22.00</td>
                              </tr>
                              <tr>
                                <td>Brazil </td>
                                <td> 18.00</td>
                              </tr>
                              <tr>
                                <td>Brazil Mobile </td>
                                <td> 36.00</td>
                              </tr>
                              <tr>
                                <td>Bulgaria </td>
                                <td> 27.00</td>
                              </tr>
                              <tr>
                                <td>Bulgaria Mobile </td>
                                <td> 99.00</td>
                              </tr>
                              <tr>
                                <td>Canada </td>
                                <td> 6.50</td>
                              </tr>
                              <tr>
                                <td>Cayman Islands </td>
                                <td> 36.00</td>
                              </tr>
                              <tr>
                                <td>Chile </td>
                                <td> 30.00</td>
                              </tr>
                              <tr>
                                <td>China South </td>
                                <td> 28.00</td>
                              </tr>
                              <tr>
                                <td>China North </td>
                                <td> 28.00</td>
                              </tr>
                              <tr>
                                <td>Colombia </td>
                                <td> 27.00</td>
                              </tr>
                              <tr>
                                <td>Croatia </td>
                                <td> 22.00</td>
                              </tr>
                              <tr>
                                <td>Czech Republic </td>
                                <td> 17.50</td>
                              </tr>
                              <tr>
                                <td>Denmark </td>
                                <td> 6.50</td>
                              </tr>
                              <tr>
                                <td>Denmark Mobile </td>
                                <td> 45.00</td>
                              </tr>
                              <tr>
                                <td>Egypt </td>
                                <td> 29.00</td>
                              </tr>
                              <tr>
                                <td>El Salvador </td>
                                <td> 30.00</td>
                              </tr>
                              <tr>
                                <td>Estonia </td>
                                <td> 11.00</td>
                              </tr>
                              <tr>
                                <td>Estonia Mobile </td>
                                <td> 54.00</td>
                              </tr>
                              <tr>
                                <td>Finland </td>
                                <td> 8.00</td>
                              </tr>
                              <tr>
                                <td>Finland Mobile </td>
                                <td> 9.00</td>
                              </tr>
                              <tr>
                                <td>France </td>
                                <td> 7.50</td>
                              </tr>
                              <tr>
                                <td>Germany </td>
                                <td> 6.50</td>
                              </tr>
                              <tr>
                                <td>Germany Mobile </td>
                                <td> 22.00</td>
                              </tr>
                              <tr>
                                <td>Greece </td>
                                <td> 9.50</td>
                              </tr>
                              <tr>
                                <td>Hong Kong </td>
                                <td> 7.00</td>
                              </tr>
                              <tr>
                                <td>Hungary </td>
                                <td> 13.00</td>
                              </tr>
                              <tr>
                                <td>Hungary Mobile </td>
                                <td> 38.00</td>
                              </tr>
                              <tr>
                                <td>Indonesia </td>
                                <td> 30.00</td>
                              </tr>
                              <tr>
                                <td>Ireland </td>
                                <td> 20.00</td>
                              </tr>
                              <tr>
                                <td>Ireland Mobile </td>
                                <td> 45.00</td>
                              </tr>
                              <tr>
                                <td>Israel </td>
                                <td> 10.00</td>
                              </tr>
                              <tr>
                                <td>Italy </td>
                                <td> 8.00</td>
                              </tr>
                              <tr>
                                <td>Italy Mobile </td>
                                <td> 47.00</td>
                              </tr>
                              <tr>
                                <td>Japan </td>
                                <td> 18.75</td>
                              </tr>
                              <tr>
                                <td>Kenya </td>
                                <td> 28.00</td>
                              </tr>
                              <tr>
                                <td>Latvia </td>
                                <td> 22.00</td>
                              </tr>
                              <tr>
                                <td>Lithuania </td>
                                <td> 28.00</td>
                              </tr>
                              <tr>
                                <td>Luxembourg </td>
                                <td> 8.50</td>
                              </tr>
                              <tr>
                                <th>Country</th>
                                <th>Charges (Rs/min)</th>
                              </tr>
                              <tr>
                                <td>Malaysia </td>
                                <td> 8.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="flright width45">
                        <div class="intertable">
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                          >
                            <tbody>
                              <tr>
                                <td>Mauritius </td>
                                <td>36.00</td>
                              </tr>
                              <tr>
                                <td>Mexico </td>
                                <td>18.00</td>
                              </tr>
                              <tr>
                                <td>Netherlands</td>
                                <td> 8.00</td>
                              </tr>
                              <tr>
                                <td>Netherlands Mobile </td>
                                <td>54.00</td>
                              </tr>
                              <tr>
                                <td>New Zealand </td>
                                <td>9.00</td>
                              </tr>
                              <tr>
                                <td>New Zealand Mobile </td>
                                <td>38.00</td>
                              </tr>
                              <tr>
                                <td>Norway</td>
                                <td> 12.00</td>
                              </tr>
                              <tr>
                                <td>Panama </td>
                                <td>15.00</td>
                              </tr>
                              <tr>
                                <td>Peru </td>
                                <td>30.00</td>
                              </tr>
                              <tr>
                                <td>Philippines</td>
                                <td> 20.00</td>
                              </tr>
                              <tr>
                                <td>Poland</td>
                                <td>9.00</td>
                              </tr>
                              <tr>
                                <td>Portugal </td>
                                <td>13.00</td>
                              </tr>
                              <tr>
                                <td>Portugal Mo bile</td>
                                <td> 30.00</td>
                              </tr>
                              <tr>
                                <td>Puerto Rico </td>
                                <td>35.00</td>
                              </tr>
                              <tr>
                                <td>Qatar</td>
                                <td> 44.00</td>
                              </tr>
                              <tr>
                                <td>Romania </td>
                                <td>16.00</td>
                              </tr>
                              <tr>
                                <td>Romania Mobile</td>
                                <td> 38.00</td>
                              </tr>
                              <tr>
                                <td>Russia </td>
                                <td>10.00</td>
                              </tr>
                              <tr>
                                <td>Saudi Arabia </td>
                                <td>35.00</td>
                              </tr>
                              <tr>
                                <td>Singapore</td>
                                <td> 6.50</td>
                              </tr>
                              <tr>
                                <td>Slovakia </td>
                                <td>15.00</td>
                              </tr>
                              <tr>
                                <td>Slovakia Mobile</td>
                                <td> 30.00</td>
                              </tr>
                              <tr>
                                <td>Slovenia</td>
                                <td> 15.00</td>
                              </tr>
                              <tr>
                                <td>South Africa </td>
                                <td>33.00</td>
                              </tr>
                              <tr>
                                <td>South Korea</td>
                                <td> 10.50</td>
                              </tr>
                              <tr>
                                <td>Spain</td>
                                <td> 12.00</td>
                              </tr>
                              <tr>
                                <td>Spain Mobile </td>
                                <td>30.00</td>
                              </tr>
                              <tr>
                                <td>Sri Lanka </td>
                                <td>36.00</td>
                              </tr>
                              <tr>
                                <td>Sri Lanka Mobile</td>
                                <td> 85.00</td>
                              </tr>
                              <tr>
                                <td>Sweden </td>
                                <td>12.00</td>
                              </tr>
                              <tr>
                                <td>Sweden Mobile</td>
                                <td> 9.00</td>
                              </tr>
                              <tr>
                                <td>Switzerland </td>
                                <td>8.50</td>
                              </tr>
                              <tr>
                                <td>Switzerland Mobile</td>
                                <td> 18.00</td>
                              </tr>
                              <tr>
                                <td>Taiwan </td>
                                <td>20.00</td>
                              </tr>
                              <tr>
                                <td>Thailand </td>
                                <td>24.00</td>
                              </tr>
                              <tr>
                                <td>Thailand Mobile </td>
                                <td>108.00</td>
                              </tr>
                              <tr>
                                <td>Turkey </td>
                                <td>24.00</td>
                              </tr>
                              <tr>
                                <td>Turkey Mobile </td>
                                <td>26.00</td>
                              </tr>
                              <tr>
                                <td>UAE </td>
                                <td>30.00</td>
                              </tr>
                              <tr>
                                <td>Ukraine </td>
                                <td>33.00</td>
                              </tr>
                              <tr>
                                <td>United Kingdom </td>
                                <td>6.00</td>
                              </tr>
                              <tr>
                                <td>Uruguay</td>
                                <td> 17.00</td>
                              </tr>
                              <tr>
                                <td>USA </td>
                                <td>5.50</td>
                              </tr>
                              <tr>
                                <td>Venezuela </td>
                                <td>22.50</td>
                              </tr>
                              <tr>
                                <td>Vietnam </td>
                                <td>42.50</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <p>
                      <strong> INTERNATIONAL TOLL ACCESS (Optional)</strong>
                    </p>
                    <div class="over_hid">
                      <div class="fleft width45">
                        <div class="intertable">
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                          >
                            <tbody>
                              <tr>
                                <th>Country</th>
                                <th>Charges (Rs/min)</th>
                              </tr>
                              <tr>
                                <td>Argentina</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>Australia</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>Austria</td>
                                <td> 4.00</td>
                              </tr>
                              <tr>
                                <td>Bahrain</td>
                                <td> 5.50</td>
                              </tr>
                              <tr>
                                <td>Belgium </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>Brazil </td>
                                <td>6.00</td>
                              </tr>
                              <tr>
                                <td>Bulgaria </td>
                                <td>3.5</td>
                              </tr>
                              <tr>
                                <td>Canada </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>Chile </td>
                                <td>8.5</td>
                              </tr>
                              <tr>
                                <td>China </td>
                                <td>24</td>
                              </tr>
                              <tr>
                                <td>Czech Republic </td>
                                <td>5.00</td>
                              </tr>
                              <tr>
                                <td>Denmark </td>
                                <td>4.50</td>
                              </tr>
                              <tr>
                                <td>Estonia</td>
                                <td> 3</td>
                              </tr>
                              <tr>
                                <td>Finland</td>
                                <td> 8.00</td>
                              </tr>
                              <tr>
                                <td>France</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>Germany</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>Greece</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>Hong Kong</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>Hungary</td>
                                <td> 5</td>
                              </tr>
                              <tr>
                                <td>Ireland </td>
                                <td>5.00</td>
                              </tr>
                              <tr>
                                <td>Israel </td>
                                <td>5.00</td>
                              </tr>
                              <tr>
                                <td>Italy </td>
                                <td>4.50</td>
                              </tr>
                              <tr>
                                <td>Japan </td>
                                <td>4.50</td>
                              </tr>
                              <tr>
                                <td>Latvia</td>
                                <td> 4</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="flright width45">
                        <div class="intertable">
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                          >
                            <tbody>
                              <tr>
                                <td>Lithuania</td>
                                <td> 4</td>
                              </tr>
                              <tr>
                                <th>Country</th>
                                <th>Charges (Rs/min)</th>
                              </tr>
                              <tr>
                                <td>Mexico </td>
                                <td>7.50</td>
                              </tr>
                              <tr>
                                <td>Netherlands </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>New Zealand </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>Norway</td>
                                <td> 3.5</td>
                              </tr>
                              <tr>
                                <td>Peru</td>
                                <td> 4.5</td>
                              </tr>
                              <tr>
                                <td>Poland</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>Portugal </td>
                                <td>5.5</td>
                              </tr>
                              <tr>
                                <td>Romania</td>
                                <td> 5.00</td>
                              </tr>
                              <tr>
                                <td>Russia </td>
                                <td>5.5</td>
                              </tr>
                              <tr>
                                <td>Singapore </td>
                                <td>4.50</td>
                              </tr>
                              <tr>
                                <td>Slovakia </td>
                                <td>3.5</td>
                              </tr>
                              <tr>
                                <td>Slovenia</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>South Africa </td>
                                <td>11.00</td>
                              </tr>
                              <tr>
                                <td>Spain </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>Sri Lanka </td>
                                <td>25.00</td>
                              </tr>
                              <tr>
                                <td>Sweden </td>
                                <td>4.50</td>
                              </tr>
                              <tr>
                                <td>Switzerland </td>
                                <td>4.50</td>
                              </tr>
                              <tr>
                                <td>Taiwan </td>
                                <td>8.5</td>
                              </tr>
                              <tr>
                                <td>United Kingdom</td>
                                <td> 4.50</td>
                              </tr>
                              <tr>
                                <td>USA </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>Vietnam</td>
                                <td> 10.00 </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <p class="pad_t30 font13"> Reservation less Conferencing</p>
                    <p>Moderator List</p>
                    <div class="subtablein">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <th>
                              Sr <br />
                              No.
                            </th>
                            <th>Moderator Name</th>
                            <th>Email ID </th>
                            <th>Phone #</th>
                            <th>
                              Recording Required?
                              <br />
                              Yes/ No
                            </th>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[16151].value}</td>
                            <td>{this.state.acDataUpdated[16152].value}</td>
                            <td>{this.state.acDataUpdated[16153].value}</td>
                            <td>{this.state.acDataUpdated[16154].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[16155].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[16155].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18446].value}</td>
                            <td>{this.state.acDataUpdated[18447].value}</td>
                            <td>{this.state.acDataUpdated[18448].value}</td>
                            <td>{this.state.acDataUpdated[18449].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18450].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18450].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18451].value}</td>
                            <td>{this.state.acDataUpdated[18452].value}</td>
                            <td>{this.state.acDataUpdated[18453].value}</td>
                            <td>{this.state.acDataUpdated[18454].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18455].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18455].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18456].value}</td>
                            <td>{this.state.acDataUpdated[18457].value}</td>
                            <td>{this.state.acDataUpdated[18458].value}</td>
                            <td>{this.state.acDataUpdated[18459].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18460].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18460].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18461].value}</td>
                            <td>{this.state.acDataUpdated[18462].value}</td>
                            <td>{this.state.acDataUpdated[18463].value}</td>
                            <td>{this.state.acDataUpdated[18464].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18465].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18465].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18466].value}</td>
                            <td>{this.state.acDataUpdated[18467].value}</td>
                            <td>{this.state.acDataUpdated[18468].value}</td>
                            <td>{this.state.acDataUpdated[18469].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18470].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18470].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18471].value}</td>
                            <td>{this.state.acDataUpdated[18472].value}</td>
                            <td>{this.state.acDataUpdated[18473].value}</td>
                            <td>{this.state.acDataUpdated[18474].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18475].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18475].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18476].value}</td>
                            <td>{this.state.acDataUpdated[18477].value}</td>
                            <td>{this.state.acDataUpdated[18478].value}</td>
                            <td>{this.state.acDataUpdated[18479].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18480].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18480].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18481].value}</td>
                            <td>{this.state.acDataUpdated[18482].value}</td>
                            <td>{this.state.acDataUpdated[18483].value}</td>
                            <td>{this.state.acDataUpdated[18484].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18485].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18485].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18486].value}</td>
                            <td>{this.state.acDataUpdated[18487].value}</td>
                            <td>{this.state.acDataUpdated[18488].value}</td>
                            <td>{this.state.acDataUpdated[18489].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18490].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18490].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18491].value}</td>
                            <td>{this.state.acDataUpdated[18492].value}</td>
                            <td>{this.state.acDataUpdated[18493].value}</td>
                            <td>{this.state.acDataUpdated[18494].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18495].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18495].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18496].value}</td>
                            <td>{this.state.acDataUpdated[18497].value}</td>
                            <td>{this.state.acDataUpdated[18498].value}</td>
                            <td>{this.state.acDataUpdated[18499].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18500].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18500].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18501].value}</td>
                            <td>{this.state.acDataUpdated[18502].value}</td>
                            <td>{this.state.acDataUpdated[18503].value}</td>
                            <td>{this.state.acDataUpdated[18504].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18505].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18505].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18506].value}</td>
                            <td>{this.state.acDataUpdated[18507].value}</td>
                            <td>{this.state.acDataUpdated[18508].value}</td>
                            <td>{this.state.acDataUpdated[18509].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18510].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18510].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18511].value}</td>
                            <td>{this.state.acDataUpdated[18512].value}</td>
                            <td>{this.state.acDataUpdated[18513].value}</td>
                            <td>{this.state.acDataUpdated[18514].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18515].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18515].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18516].value}</td>
                            <td>{this.state.acDataUpdated[18517].value}</td>
                            <td>{this.state.acDataUpdated[18518].value}</td>
                            <td>{this.state.acDataUpdated[18519].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18520].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18520].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18521].value}</td>
                            <td>{this.state.acDataUpdated[18522].value}</td>
                            <td>{this.state.acDataUpdated[18523].value}</td>
                            <td>{this.state.acDataUpdated[18524].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18525].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18525].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18526].value}</td>
                            <td>{this.state.acDataUpdated[18527].value}</td>
                            <td>{this.state.acDataUpdated[18528].value}</td>
                            <td>{this.state.acDataUpdated[18529].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18530].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18530].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18531].value}</td>
                            <td>{this.state.acDataUpdated[18532].value}</td>
                            <td>{this.state.acDataUpdated[18533].value}</td>
                            <td>{this.state.acDataUpdated[18534].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18535].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18535].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18536].value}</td>
                            <td>{this.state.acDataUpdated[18537].value}</td>
                            <td>{this.state.acDataUpdated[18538].value}</td>
                            <td>{this.state.acDataUpdated[18539].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18540].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18540].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18541].value}</td>
                            <td>{this.state.acDataUpdated[18542].value}</td>
                            <td>{this.state.acDataUpdated[18543].value}</td>
                            <td>{this.state.acDataUpdated[18544].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18545].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18545].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18546].value}</td>
                            <td>{this.state.acDataUpdated[18547].value}</td>
                            <td>{this.state.acDataUpdated[18548].value}</td>
                            <td>{this.state.acDataUpdated[18549].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18550].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18550].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18551].value}</td>
                            <td>{this.state.acDataUpdated[18552].value}</td>
                            <td>{this.state.acDataUpdated[18553].value}</td>
                            <td>{this.state.acDataUpdated[18554].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18555].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18555].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18556].value}</td>
                            <td>{this.state.acDataUpdated[18557].value}</td>
                            <td>{this.state.acDataUpdated[18558].value}</td>
                            <td>{this.state.acDataUpdated[18559].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18560].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18560].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18561].value}</td>
                            <td>{this.state.acDataUpdated[18562].value}</td>
                            <td>{this.state.acDataUpdated[18563].value}</td>
                            <td>{this.state.acDataUpdated[18564].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18565].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18565].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18566].value}</td>
                            <td>{this.state.acDataUpdated[18567].value}</td>
                            <td>{this.state.acDataUpdated[18568].value}</td>
                            <td>{this.state.acDataUpdated[18569].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18570].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18570].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18571].value}</td>
                            <td>{this.state.acDataUpdated[18572].value}</td>
                            <td>{this.state.acDataUpdated[18573].value}</td>
                            <td>{this.state.acDataUpdated[18574].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18575].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18575].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18576].value}</td>
                            <td>{this.state.acDataUpdated[18577].value}</td>
                            <td>{this.state.acDataUpdated[18578].value}</td>
                            <td>{this.state.acDataUpdated[18579].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18580].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18580].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18581].value}</td>
                            <td>{this.state.acDataUpdated[18582].value}</td>
                            <td>{this.state.acDataUpdated[18583].value}</td>
                            <td>{this.state.acDataUpdated[18584].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18585].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18585].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[18586].value}</td>
                            <td>{this.state.acDataUpdated[18587].value}</td>
                            <td>{this.state.acDataUpdated[18588].value}</td>
                            <td>{this.state.acDataUpdated[18589].value}</td>
                            <td>
                              {" "}
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18590].value ===
                                "Yes" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                Yes
                              </div>
                              <div className="fleft width12">
                                {this.state.acDataUpdated[18590].value ===
                                "No" ? (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    className="form_col"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    disabled
                                  />
                                )}
                                No
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </page>
            ) : (
              <page size="A4">
                <div class="section2">
                  <div class="font8">
                    <div class="over_hid">
                      <div class="fleft width70 textcenter">
                        <p>
                          {" "}
                          This TEF includes Schedule 1 (General Terms and
                          Conditions for Delivery of Services); Schedule 2
                          (Product Specific); and Schedule 3 (Regulatory
                          Requirements and Compliance)
                        </p>
                      </div>
                      <div class="flright width30 text_r">
                        {" "}
                        <img src={SingleVodaImg} />{" "}
                      </div>
                    </div>
                    <p class="redcolor font15 margin0"> Exhibit - 1 </p>
                    <p class="redcolor font20">
                      <strong>Tariff and Tariff Enrollment Form</strong>
                    </p>
                    <p class="font15">
                      <strong>Audio Conferencing Services</strong>
                    </p>
                    <p class="auth">
                      Total no. of employees in organization*:
                      <input
                        class="form_col width72 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[19798].value}
                        readOnly
                      />
                    </p>
                    <p class="auth">
                      Total number of moderators required in organization
                      <input
                        class="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[19799].value}
                        readOnly
                      />
                    </p>
                    <p class="pad_t20">
                      <strong>Requirement – Type of Plan:</strong>
                    </p>
                    <div class="pad_l10">
                      <div class="check margin0 over_hid typecheck ">
                        {" "}
                        <img src={ArrowImg} />{" "}
                        <span class="width80px in_block">Standard plan 5 </span>{" "}
                        <span class="wid120p in_block">
                          ports (5000 minutes)
                        </span>{" "}
                        <strong>Tariff</strong>: INR 2500{" "}
                      </div>
                      <div class="check margin0 over_hid typecheck">
                        {" "}
                        <img src={ArrowImg} />{" "}
                        <span class="width80px in_block">Jumbo plan</span>{" "}
                        <span class="wid120p in_block">
                          10 ports (7500 minutes)
                        </span>{" "}
                        <strong>Tariff</strong>: INR 4000{" "}
                      </div>
                    </div>
                    <p class="pad_t20">
                      <strong>Overage Option: (Select One)</strong>
                    </p>
                    <div class="pad_l10">
                      <div class="check margin0 over_hid typecheck ">
                        {this.state.acDataUpdated[19803].value ===
                        "Continue usage post bundled minutes at INR 1/port/min" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        <strong>
                          Continue usage post bundled minutes at INR 1/port/min.
                        </strong>{" "}
                      </div>
                      <div class="check margin0 over_hid typecheck">
                        {this.state.acDataUpdated[19803].value ===
                        "Stop the account until next bill cycles post bundled minutes." ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        <strong>
                          Stop the account until next bill cycles post bundled
                          minutes.
                        </strong>{" "}
                      </div>
                    </div>
                    <p class="pad_t20">Audio Conferencing Moderator list</p>
                    <div class="domestictable tablepad text_l">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <strong>Sr No</strong>{" "}
                            </td>
                            <td>
                              <strong>Moderator Name </strong>
                            </td>
                            <td>
                              <strong>Email ID</strong>
                            </td>
                            <td>
                              <strong>Phone # </strong>
                            </td>
                            <td>
                              <strong>
                                Plan enrolled for (5 port or 10 port)
                              </strong>
                            </td>
                            <td>
                              <strong>Bundled Minutes</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19805].value}</td>
                            <td>{this.state.acDataUpdated[19806].value}</td>
                            <td>{this.state.acDataUpdated[19807].value}</td>
                            <td>{this.state.acDataUpdated[19808].value}</td>
                            <td>{this.state.acDataUpdated[19809].value}</td>
                            <td>{this.state.acDataUpdated[19810].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19811].value}</td>
                            <td>{this.state.acDataUpdated[19812].value}</td>
                            <td>{this.state.acDataUpdated[19813].value}</td>
                            <td>{this.state.acDataUpdated[19814].value}</td>
                            <td>{this.state.acDataUpdated[19815].value}</td>
                            <td>{this.state.acDataUpdated[19816].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19817].value}</td>
                            <td>{this.state.acDataUpdated[19818].value}</td>
                            <td>{this.state.acDataUpdated[19819].value}</td>
                            <td>{this.state.acDataUpdated[19820].value}</td>
                            <td>{this.state.acDataUpdated[19821].value}</td>
                            <td>{this.state.acDataUpdated[19822].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19823].value}</td>
                            <td>{this.state.acDataUpdated[19824].value}</td>
                            <td>{this.state.acDataUpdated[19825].value}</td>
                            <td>{this.state.acDataUpdated[19826].value}</td>
                            <td>{this.state.acDataUpdated[19827].value}</td>
                            <td>{this.state.acDataUpdated[19828].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19829].value}</td>
                            <td>{this.state.acDataUpdated[19830].value}</td>
                            <td>{this.state.acDataUpdated[19831].value}</td>
                            <td>{this.state.acDataUpdated[19832].value}</td>
                            <td>{this.state.acDataUpdated[19833].value}</td>
                            <td>{this.state.acDataUpdated[19834].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19835].value}</td>
                            <td>{this.state.acDataUpdated[19836].value}</td>
                            <td>{this.state.acDataUpdated[19837].value}</td>
                            <td>{this.state.acDataUpdated[19838].value}</td>
                            <td>{this.state.acDataUpdated[19839].value}</td>
                            <td>{this.state.acDataUpdated[19840].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19841].value}</td>
                            <td>{this.state.acDataUpdated[19842].value}</td>
                            <td>{this.state.acDataUpdated[19843].value}</td>
                            <td>{this.state.acDataUpdated[19844].value}</td>
                            <td>{this.state.acDataUpdated[19845].value}</td>
                            <td>{this.state.acDataUpdated[19846].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19847].value}</td>
                            <td>{this.state.acDataUpdated[19848].value}</td>
                            <td>{this.state.acDataUpdated[19849].value}</td>
                            <td>{this.state.acDataUpdated[19850].value}</td>
                            <td>{this.state.acDataUpdated[19851].value}</td>
                            <td>{this.state.acDataUpdated[19852].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19853].value}</td>
                            <td>{this.state.acDataUpdated[19854].value}</td>
                            <td>{this.state.acDataUpdated[19855].value}</td>
                            <td>{this.state.acDataUpdated[19856].value}</td>
                            <td>{this.state.acDataUpdated[19857].value}</td>
                            <td>{this.state.acDataUpdated[19858].value}</td>
                          </tr>
                          <tr>
                            <td>{this.state.acDataUpdated[19859].value}</td>
                            <td>{this.state.acDataUpdated[19860].value}</td>
                            <td>{this.state.acDataUpdated[19861].value}</td>
                            <td>{this.state.acDataUpdated[19862].value}</td>
                            <td>{this.state.acDataUpdated[19863].value}</td>
                            <td>{this.state.acDataUpdated[19864].value}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="raised">
                      The invoices will be raised monthly as per Vodafone’s
                      billing cycle (the first invoice will be raised on the
                      first billing cycle post the activation date for the
                      Services), which will include (i) applicable pro-rated
                      charges for the previous or current month; (ii) advance
                      monthly rental for the next month; and (iii) overage and
                      other charges, as applicable.{" "}
                    </div>
                  </div>
                </div>
              </page>
            )}
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <p>
                        <strong>
                          GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                        </strong>
                      </p>
                      <p>
                        <strong>THE PARTIES AGREE:</strong>
                      </p>
                      <p>
                        These general terms and conditions for delivery of
                        services (“<strong>Terms and Conditions</strong>”) are
                        applicable to the Customer Agreement Form (as defined
                        hereinafter) entered into between the Customer and the
                        Service Provider. Each Customer Agreement Form, when
                        submitted by the Customer and accepted by the Service
                        Provider in accordance with Clause 3, shall incorporate
                        and shall be subject to these Terms and Conditions.
                        These Terms and Conditions are in addition to and not in
                        derogation of the terms and conditions contained in the
                        Customer Agreement Form and/ or the Master Services
                        Agreement, if any (as defined hereinafter). The Service
                        Provider and the Customer are hereinafter individually
                        be referred to as the “Party” and collectively be
                        referred to as the “<strong>Parties</strong>”.
                      </p>
                      <div class="pad_l10">
                        <p>
                          <strong>1. INCORPORATION OF OTHER DOCUMENTS</strong>
                        </p>
                        <div class="pad_l10">
                          <p>
                            The Parties expressly acknowledge and agree that,
                            depending on the type of Services ordered by the
                            Customer, certain Service Schedules will be
                            applicable to Customer’s use and Service Provider’s
                            delivery of said Services. Each relevant Service
                            Schedule shall be attached to each applicable
                            Customer Agreement Form and shall be considered a
                            material part of these Terms and Conditions.
                          </p>
                          <p>
                            <strong>Term</strong>
                          </p>
                          <div class="">
                            <ul class="nolist">
                              <li>
                                <span class="pad_r15">(a)</span>
                                <span>
                                  Start date: This Agreement shall start on the
                                  date set out the Commercial Terms in Annex 1
                                  to this GTC.
                                </span>
                              </li>
                              <li>
                                <span class="pad_r15">(b)</span>
                                <span>
                                  Duration: This Agreement shall continue for a
                                  period of one (1) year (“Term”) and will be
                                  automatically renewed at the end of the Term
                                  for one (1) year each, successively, unless
                                  explicitly terminated by written notice one
                                  (1) month prior to expiry of the Term or
                                  extended Term as the case may be.
                                </span>
                              </li>
                              <li>
                                <span class="pad_r15">(c)</span>
                                <span>
                                  Incorporation of terms: Each accepted Purchase
                                  Order shall be deemed to incorporate the terms
                                  of this Customer Agreement Form.{" "}
                                </span>
                              </li>
                              <li>
                                <span class="pad_r15">(d)</span>
                                <span>
                                  <li>
                                    <span class="pad_r5">(c)</span>
                                    <span>
                                      Incorporation of terms: Each accepted
                                      Purchase Order shall be deemed to
                                      incorporate the terms of this Customer
                                      Agreement Form.{" "}
                                    </span>
                                  </li>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <p>
                        <strong>2. DEFINITIONS AND INTERPRETATION</strong>
                      </p>
                      <p>
                        <strong>2.1 Definitions</strong>
                      </p>
                      <p class="pad_l10">
                        “<strong>Acceptance Order</strong>” means a written
                        notice from the Service Provider to the Customer
                        accepting the Customer Agreement Form.
                        <br />“<strong>Affiliate</strong>” in relation to a
                        Party, means and refers to any person which, directly or
                        indirectly, (a) Controls such Party, (b) is controlled
                        by such Party, (c) is under the common Control with such
                        Party. For the purpose of this definition, “
                        <strong>Controlling</strong>”, “
                        <strong>Controlled by</strong>” or “Control” with
                        respect to any person, which is a company, shall mean
                        the ownership, directly or indirectly, of more than 50%
                        (fifty percent) of the voting interest and with respect
                        to any person, which is not a company, shall mean the
                        power to direct the management and policies of such
                        person, whether by operation of Law or by contract or
                        otherwise.
                        <br />“<strong>Bill</strong>” means any bill, invoice or
                        statement issued or rendered by or on behalf of the
                        Service Provider to the Customer for the Service Fees
                        for any Service; “<strong>Credit Limits</strong>” means
                        the meaning assigned to such term in Clause 4.7. “
                        <strong>Customer</strong>” means the entity so named on
                        the Customer Agreement Form. “
                        <strong>Customer Agreement Form(s)</strong>” means the
                        form(s) so entitled (including any attachments thereto)
                        signed by the Customer and the Service Provider setting
                        out matters relating to the Service Provider’s delivery
                        of Services to the Customer and governed by these Terms
                        and Conditions and the relevant Service Schedule and/or
                        the Master Services Agreement (if any).
                        <br />“<strong>Customer Premises</strong>” means the
                        location or locations occupied by the Customer or the
                        End Users to which Service is delivered.
                        <br />“<strong>Customer Technology</strong>” means the
                        Customer’s proprietary technology, including without
                        limitation, all text, pictures, sound, video, and log
                        files, the Customer’s software (in source and object
                        forms), user interface designs, architecture and
                        documentation (both printed and electronic), know-how,
                        and any related intellectual property rights throughout
                        the world (whether owned by the Customer or licensed to
                        the Customer from a third party).
                        <br />“<strong>Dispute</strong>” means the meaning
                        assigned to such term in Clause 12.1.
                      </p>
                    </div>
                    <div class="flright width48">
                      <p>
                        “<strong>End Users</strong>” means any person or entity
                        deriving use of the Services through the Customer
                        including but not limited to the Customer, an Affiliate
                        of the Customer or a customer of the Customer.
                        <br />“<strong>Force Majeure Event</strong>” means any
                        cause beyond a Party’s reasonable control, including,
                        without limitation, lightning, fire, flood, earthquake,
                        elements of nature or acts of god, acts of war,
                        terrorism, riots, civil disorders, rebellions or
                        revolutions, earthquake, hurricanes, flood, fire or
                        other similar casualty, embargo, riot, terrorism,
                        sabotage, strikes, act of Governmental Authority,
                        epidemics, quarantines, inability to procure materials
                        or transportation facilities, failure of power, expiry /
                        non-renewal termination of a telecom license,
                        restrictive governmental Laws or regulations, court
                        orders, condemnation or other reason of a like nature
                        not resulting from the actions or inactions of a Party.
                        <br />“<strong>Indemnified Party</strong>” means the
                        meaning assigned to such term in Clause 8.1.
                        <br />“<strong>Insolvency Event</strong>” means, any
                        event as a result of which a person:-
                      </p>
                      <ul class="nolist">
                        <li>
                          <span class="pad_r15">(a)</span>
                          <span>
                            is adjudged insolvent and such adjudication remains
                            valid and in force for a period of twenty one (21)
                            days from the date of such adjudication; or
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">(b)</span>
                          <span>
                            commences voluntary winding up or liquidation or any
                            similar proceedings; or
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">(c)</span>
                          <span>
                            is subjected to the appointment of a receiver,
                            administrative receiver, official liquidator
                            including provisional liquidator, trustee, other
                            encumbrances or similar officer over its undertaking
                            or corporate entity or a material part of its assets
                            or undertaking; or{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">(d)</span>
                          <span>
                            ceases to carry on business, or a distress or
                            execution levied or enforced against any of its
                            assets, or is dissolved, or suffers any analogous
                            event to any of the above under applicable law in
                            any jurisdiction;
                          </span>
                        </li>
                      </ul>
                      <div class="pad_l10">
                        {" "}
                        “<strong>LOA</strong>” means the meaning assigned to
                        such term in Clause 4.9.
                        <br />“<strong>Local Loop</strong>” means the connection
                        between the Customer Premises and a Service
                        <br />
                        Provider Point of Presence.
                        <br />“<strong>Losses</strong>” means the meaning
                        assigned to such term in Clause 8.1.{" "}
                      </div>
                      <div class="pad_l10">
                        {" "}
                        “<strong>Master Services Agreement</strong>” means the
                        Agreement so titled and signed by the Customer and the
                        Service Provider to which these Terms and Conditions and
                        the applicable Service Schedules are attached. In the
                        event the Parties have not signed a Master Services
                        Agreement, then these Terms and Conditions shall be
                        construed in such a manner that any reference to the
                        term “Master Services Agreement” is disregarded and will
                        have no import or effect, while these Terms and
                        Conditions shall govern the provision of the Services to
                        the Customer.
                        <br />“<strong>Personal Information</strong>” means any
                        information that may identify an individual. “
                        <strong>Service(s)</strong>” means all services set
                        forth in the Customer Agreement Form to be provided by
                        the Service Provider to the Customer.
                        <br />“<strong>Service Commencement Date</strong>” means
                        (i) the date on which the Customer has accepted or has
                        deemed to have accepted, the Services in accordance with
                        the provisions of the Service Schedule; or (ii) the date
                        on which the Customer begins using the Services other
                        than for testing purposes, whichever is earlier.
                        <br />“<strong>Services Fees</strong>” means charges for
                        Services (including but not limited to monthly recurring
                        charges and non-recurring charges) as identified in the
                        Customer Agreement Form(s) or Service Schedules.
                        <br />“<strong>Service Provider</strong>” means the
                        Service Provider entity so named on the Customer
                        Agreement Form and/or Master Services Agreement (if any)
                        and shall be the operator on record for the respective
                        Circle where Services are offered.
                        <br />“<strong>Service Provider Equipment</strong>”
                        means any equipment or thing owned, operated, installed
                        and/or hired out by any Service Provider or any of its
                        Affiliates (including, any customer premise equipment
                        and where the context so admits, any software installed
                        therein or used by Service Provider or any of its
                        Affiliates in conjunction with the operation of such
                        equipment or thing).
                        <br />“<strong>Service Provider Network</strong>” means
                        the telecommunications network consisting of fibres and
                        optical and transmission equipment which is owned and/or
                        leased and operated and maintained by Service Provider
                        or its Affiliates.
                        <br />“
                        <strong>Service Provider Point of Presence</strong>”
                        means data center space owned or leased by Service
                        Provider or any of its Affiliates for the purpose of
                        among other things locating and collocating
                        communications equipment and to which the Service
                        Provider Network is directly connected.
                        <br />“<strong>Service Provider Technology</strong>”
                        means Service Provider’s proprietary technology,
                        including without limitation, Services, software tools,
                        hardware designs, algorithms, software (in source and
                        object forms), user interface designs, architecture,
                        class libraries, objects and documentation (both printed
                        and electronic), network designs, know-how, business
                        methods, and any related intellectual property rights
                        throughout{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <p class="pad_l10">
                        the world (whether owned by Service Provider or licensed
                        to Service Provider from a third party).
                        <br />“<strong>Service Schedules</strong>” means the
                        forms so entitled and applicable to each Service ordered
                        by the Customer pursuant to the Customer Agreement Form
                        “<strong>System</strong>” means the computer systems,
                        programming and communication facilities and any other
                        equipment forming part of the Service and/or part of the
                        operation of any Service, including Service Provider
                        Equipment;
                        <br />“<strong>Taxes</strong>” means the meaning
                        assigned to such term in Clause 4.5 (a). “
                        <strong>Term</strong>” means the period of time, as set
                        forth in the relevant Customer Agreement Form (and/or
                        the Service Schedules), during which the Service
                        Provider and the Customer are obligated to perform under
                        these Terms and Conditions.
                      </p>
                      <p>
                        <strong>2.2 Interpretation</strong>
                      </p>
                      <p>In these Terms and Conditions</p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              any reference to any statute or statutory
                              provision shall include:
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              all subordinate legislation made from time to time
                              under that provision (whether or not amended,
                              modified, re-enacted or consolidated);
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              such provision as from time to time amended,
                              modified, re-enacted or consolidated (whether
                              before or after the date of these Terms and
                              Conditions) to the extent such amendment,
                              modification, re-enactment or consolidation
                              applies or is capable of applying to any
                              transactions entered into under these Terms and
                              Conditions prior to the Agreement Date;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              any reference to the singular shall include the
                              plural and vice-versa;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(e)</span>
                            <span>
                              any references to the masculine, the feminine and
                              the neuter shall include each other;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(f)</span>
                            <span>
                              {" "}
                              any references to a “company” shall include a body
                              corporate;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(g)</span>
                            <span>
                              {" "}
                              any reference to a document “in the agreed form”
                              is to the form of the relevant document agreed
                              between the Parties and for the purpose of
                              identification initialled by each of them or on
                              their behalf (in each case with such amendments as
                              may be agreed by or on behalf of the Parties);{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(h)</span>
                            <span>
                              {" "}
                              the recitals and annexure form part of these Terms
                              and Conditions and shall have the same force and
                              effect as if expressly set out in the body of
                              these Terms and Conditions, and any reference to
                              these Terms and Conditions shall include any
                              recitals and annexures to it. Any references to
                              Clauses are to clauses of these Terms and
                              Conditions;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(i)</span>
                            <span>
                              {" "}
                              references to these Terms and Conditions or any
                              other document shall be construed as references to
                              these Terms and Conditions or that other document
                              as amended, varied, novated, supplemented or
                              replaced from time to time;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(j)</span>
                            <span>
                              {" "}
                              the expression “this Clause” shall, unless
                              followed by reference to a specific provision, be
                              deemed to refer to the whole Clause in which the
                              expression occurs;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(k)</span>
                            <span>
                              {" "}
                              unless the contrary is expressly stated, no Clause
                              in these Terms and Conditions limits the extent or
                              application of another Clause;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(l)</span>
                            <span>
                              {" "}
                              any reference to books, files, records or other
                              information or any of them means books, files,
                              records or other information or any of them in any
                              form or in whatever medium held including paper,
                              electronically stored data, magnetic media, film
                              and microfilm;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(m)</span>
                            <span>
                              {" "}
                              headings to Clauses, parts and paragraphs of
                              annexures and annexures are for convenience only
                              and do not affect the interpretation of these
                              Terms and Conditions;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(n)</span>
                            <span>
                              {" "}
                              “in writing” includes any communication made by
                              letter or fax or e-mail;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(o)</span>
                            <span>
                              {" "}
                              unless otherwise specified, any reference to a
                              time of day is to India time;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(p)</span>
                            <span>
                              {" "}
                              references to a person (or to a word importing a
                              person) shall be construed so as to include:{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> &nbsp; </span>
                            <span>
                              <ul class="nolist">
                                <li>
                                  <span class="pad_r15">(i)</span>
                                  <span>
                                    individual, firm, partnership, trust, joint
                                    venture, company, corporation, body
                                    corporate, unincorporated body, association,
                                    organisation, any government, or state or
                                    any agency of a government or state, or any
                                    local or municipal authority or other
                                    governmental body (whether or not in each
                                    case having separate legal personality);{" "}
                                  </span>
                                </li>
                                <li>
                                  <span class="pad_r15">(ii)</span>
                                  <span>
                                    that person’s successors in title and
                                    assigns or transferees permitted in
                                    accordance with the terms of these Terms and
                                    Conditions; and
                                  </span>
                                </li>
                                <li>
                                  <span class="pad_r15">(iii)</span>
                                  <span>
                                    references to a person’s representatives
                                    shall be to its officers, employees, legal
                                    or other professional advisers,
                                    sub-contractors, agents, attorneys and other
                                    duly authorised representatives;
                                  </span>
                                </li>
                              </ul>
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(q)</span>
                            <span>
                              the words “include”, “including” and “in
                              particular” shall be construed as being by way of
                              illustration or emphasis only and shall not be
                              construed as, nor shall they take effect as,
                              limiting the generality of any preceding words;
                              and{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="flright width48">
                      <ul class="nolist">
                        <li>
                          <span class="pad_r15">(r)</span>
                          <span>
                            where a wider construction is possible, the words
                            “other” and “otherwise” shall not be construed
                            ejusdem generis with any foregoing words.
                          </span>
                        </li>
                      </ul>
                      <p>
                        <strong> 3. ORDERS</strong>
                      </p>
                      <p>
                        <strong>3.1 Ordering Process</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Customer shall request for Services by submitting a
                        signed Customer Agreement Form to the Service Provider.
                        The Customer shall accurately complete all fields set
                        out in the Customer Agreement Form.{" "}
                      </div>
                      <p>
                        <strong>3.2 Order Acceptance or Rejection</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Service provider shall accept, reject or request
                        further information about all Customer Agreement Form(s)
                        within 5 (five) days of receipt by the Service Provider
                        of the Customer Agreement Form, by way of an Acceptance
                        Order.{" "}
                      </div>
                      <p>
                        <strong>3.3 Binding nature of Accepted Order</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        Upon acceptance of Customer Agreement Form by the
                        Service Provides, the same shall be binding on the
                        Parties and therefore, the Service Provider shall supply
                        and the Customer shall accept all Services set out in
                        the Customer Agreement Form subject to Clause 3.4 of
                        these Terms and Conditions
                      </div>
                      <p>
                        <strong>3.4 Unauthorised Orders</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        In relation to any Customer Agreement Form submitted by
                        the Customer, if the Customer receives a notice of
                        acceptance or rejection or a request for further
                        information, which such Customer believes, was submitted
                        without Customer’s authorisation, the Customer shall
                        notify the Service Provider immediately. The Customer
                        shall not accept any equipment delivered under such
                        unauthorised Customer Agreement Form provided that the
                        Customer has notified the Service Provider immediately
                        and within 24 hours of receipt of Acceptance Order or
                        request for further information that the Customer
                        Agreement Form is unauthorised
                      </div>
                      <p>
                        <strong>4. SERVICES</strong>
                      </p>
                      <p>
                        <strong>4.1 Credit Approval and Deposits</strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              The Service Provider reserves the right to carry
                              out a credit check of the Customer at any time
                              prior to issue of Acceptance Order or during the
                              Term. The Customer hereby authorizes, and shall
                              assist, the Service Provider in obtaining
                              information about the Customer’s commercial
                              activities and financial condition from third
                              parties, including, without limitation, banks,
                              credit reporting agencies and other businesses
                              that provide like information.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              The Customer shall deposit with the Service
                              Provider such sums as interest free security
                              deposit, as may be reasonably requested or
                              determined by the Service Provider from time to
                              time in respect of any Service. The Customer shall
                              not be able to require the Service Provider to
                              apply any sum deposited with the Service Provider
                              in payment of Service Fees or any charges. A
                              security deposit held by the Service Provider
                              shall not relieve the Customer of its obligations
                              to pay any amounts to the Service Provider as
                              those amounts become due and payable, nor does it
                              constitute a waiver of the Service Provider’s
                              right to suspend, disconnect, or terminate any
                              Service due to non-payment of any sums due or
                              payable to the Service Provider. The Service
                              Provider:{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(i)</span>
                            <span>
                              shall be entitled to retain all sums deposited by
                              the Customer with the Service Provider for so long
                              as any Service (notwithstanding that it has been
                              suspended) or any the Service Provider equipment
                              continues to be provided or made available or
                              retained by the Customer;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(ii)</span>
                            <span>
                              may, at any time, utilise any or all of the sum
                              deposited with the Service Provider to settle any
                              amount due, payable or owed to the Service
                              Provider by the Customer; and{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(iii)</span>
                            <span>
                              may, at its election and without any request or
                              authorisation from the Customer, pay any or all
                              such sums to the Service Provider or any of its
                              Affiliate for the account of the Customer to
                              settle any sum due, payable or owed by the
                              Customer to the Service Provider or any of its
                              Affiliate.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <p> iv</p>
                      <p>
                        <strong>4.2 Term </strong>
                      </p>
                      <div class="pad_l10">
                        <p>
                          {" "}
                          The Term for each Service ordered shall be set forth
                          in each Customer Agreement Form (and/or the Service
                          Schedules).
                        </p>
                      </div>
                      <p>
                        <strong>4.3 Lock-in Period</strong>
                      </p>
                      <div class="pad_l10">
                        <p>
                          On commissioning of Services, the Customer agrees to a
                          lock-in period, if any provided in the Customer
                          Agreement Form, from the date of commissioning of
                          Services by the Service Provider under the Customer
                          Agreement Form.{" "}
                        </p>
                      </div>
                      <p>
                        <strong>4.4 Service Fees and Payment Mechanism</strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              The Service Fees for each Service ordered under
                              any Customer Agreement Form shall be set forth in
                              such Customer Agreement Form and the Customer
                              agrees to pay for the Services for such period of
                              time or such longer time as Customer actually uses
                              the Services.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              Unless otherwise agreed between the Parties in
                              writing, billing for the Services shall commence
                              on the Service Commencement Date, regardless of
                              whether the Customer is otherwise prepared to
                              accept delivery of the Services.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              The Service Provider may render a Bill in respect
                              of Services monthly, or at such other intervals or
                              time as may be agreed between the Service Provider
                              and the Customer. This is subject to any
                              directions issued by a Department of
                              Telecommunication or Telecom Regulatory Authority
                              of India or any other regulator in any relevant
                              territory. Bills will be sent by registered post
                              or courier at the billing address of the Customer
                              as mentioned in the Customer Agreement Form. The
                              Service Provider shall not be responsible for
                              non-receipt of such Bill, and in such event, it
                              shall be the responsibility of the Customer to
                              inquire about the dues and settle the same. In
                              case the Service Provider provides the option to
                              apply for the Service Provider’s e-bill facility,
                              and the Customer opts for such facility, relevant
                              user ID, password or other information or details
                              shall be exchanged through the medium of the
                              internet and the Service Provider shall not in any
                              manner be responsible or liable for the secrecy or
                              security of the same under any circumstances.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              The Customer shall pay the Service Provider the
                              total amount shown or stated as due or payable to
                              the Service Provider on a Bill within the period
                              prescribed in the Bill or, in the absence of any
                              such period being prescribed, within fourteen (14)
                              days after the date of that Bill. If the Customer
                              disputes, in good faith, any amount stated in any
                              Bill and intends to withhold payment of the
                              amount, then the Customer must give the Service
                              Provider a written notice of such dispute before
                              the end of the period prescribed in such Bill for
                              payment or, in the absence of such period being
                              prescribed, within fourteen (14) days after the
                              date of that Bill and must state in such notice
                              the grounds and reasons for such dispute and the
                              Service Provider shall provide a written response
                              to the Customer within thirty (30) days of its
                              receipt of such written notice. The Customer shall
                              pay interest at the prescribed rate on the amount
                              determined (either by agreement between the
                              Customer and the Service Provider or by any court
                              or authority of competent jurisdiction) to be due
                              or payable to the Service Provider which the
                              Customer has withheld payment of, by virtue of
                              such dispute, calculated and compounded in such
                              manner determined by the Service Provider from
                              time to time as from the date when the amount
                              should have become payable, but for such dispute,
                              to the date of payment in full.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(e)</span>
                            <span>
                              If the Customer pays a Bill, and subsequently
                              chooses to dispute, in good faith, any amount
                              stated in the Bill, the Customer must give the
                              Service Provider a written notice of such dispute
                              not later than six (6) months from the date of
                              such Bill. If the Customer serves any such written
                              notice on the Service Provider, then the Customer
                              shall promptly provide the Service Provider with
                              all information and assistance reasonably
                              requested by the Service Provider to enable the
                              Service Provider to investigate and resolve the
                              dispute. The Service Provider will provide a
                              written response to the Customer within thirty
                              (30) days of its receipt of the Customer’s written
                              notice.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(f)</span>
                            <span>
                              Any overpayment by the Customer with respect to
                              any matter stated in a Bill shall be credited by
                              the Service Provider (without interest) to the
                              relevant account after the Service Provider has
                              completed its investigations and is satisfied as
                              to the error or inaccuracy of such matter.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="flright width48">
                      <p>
                        <strong>4.5 Taxes and Fees</strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              All charges for Services are net of applicable
                              Taxes. Except for taxes based on Service
                              Provider’s net income, the Customer shall be
                              responsible for payment of all applicable VAT,
                              GST, service tax, consumption tax, use, excise,
                              access, bypass, franchise, regulatory or other
                              like taxes, fees, charges or surcharges, whether
                              now or hereafter enacted, however designated,
                              imposed on or based on the provision, sale or use
                              of the Services (“Taxes”). To the extent the
                              Customer believes it is exempt from payment of
                              certain Taxes, it shall provide to the Service
                              Provider a copy of a valid tax exemption
                              certificate. The Service Provider will give effect
                              to all valid exemption certificates in the next
                              full billing cycle following receipt of the
                              certificate from the Customer, but only to the
                              extent the Service Provider is permitted to do so
                              under applicable Laws. Notwithstanding the
                              foregoing, in the event that the Customer’s
                              exemption certificate is or becomes invalid during
                              the Term, and the Service Provider is assessed or
                              responsible for additional Taxes, penalties or
                              late charges, the Customer shall be responsible
                              for such charges in accordance with this Clause.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              If Customer is or was required by Law to make any
                              deduction or withholding from any payment due
                              hereunder to Service Provider, then,
                              notwithstanding anything to the contrary contained
                              herein, the gross amount payable by the Customer
                              to the Service Provider will be increased so that,
                              after any such deduction or withholding for Taxes,
                              the net amount received by the Service Provider
                              will not be less than Service Provider would have
                              received had no such deduction or withholding been
                              required. If any taxing or Governmental Authority
                              asserts that the Customer should have made a
                              deduction or withholding for or on account of any
                              Taxes with respect to all or a portion of any
                              payments made hereunder, or that the Service
                              Provider should have collected certain Taxes from
                              the Customer which the Service Provider did not
                              collect, the Customer hereby agrees to indemnify
                              the Service Provider for such Taxes and hold the
                              Service Provider harmless on an after-tax basis
                              from and against any Taxes, interest or penalties
                              levied or asserted in connection therewith.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>4.6 Credit Limit</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        Customer shall be subject to the credit limit (“Credit
                        Limit”) as may be specified in the Customer Agreement
                        Form(s). The Credit Limit may be varied only with the
                        written approval of the Service Provider. At no time
                        shall the sum of the outstanding invoiced amounts plus
                        the unbilled accrued amounts payable by the Customer
                        exceed its Credit Limit.
                      </div>
                      <p>
                        <strong>4.7 Customer Premise Equipment</strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              All customer premise equipment shall remain the
                              property of the Service Provider and the Customer
                              shall be entitled to use customer premise
                              equipment provided for use with the Services and
                              for as long as the Service Provider supplies the
                              relevant Services to the Customer.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              All customer premise equipment supplied to the
                              Customer by the Service Provider shall be enabled
                              for use on the Service Provider Network from the
                              date of activation. At the request of the
                              Customer, inactive (i.e. service disabled)
                              customer premise equipment can be dispatched to
                              the Customer. Such customer premise equipment will
                              be activated (i.e. service enabled) within one (1)
                              day following the date of dispatch by the Service
                              Provider subject to the Customer submitting all
                              the relevant documents for a particular Service
                              and completion of verification process as mandated
                              by Department of Telecommunication/ Telecom
                              Regulatory Authority of India. All customer
                              premise equipment that are enabled for use on the
                              Service Provider Network shall be subject to
                              charges from the Service Commencement Date. The
                              Customer shall return customer premise equipment
                              to the Service provider when requested by the
                              Service Provider.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>Customer shall: </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pad_l20">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(i)</span>
                            <span>
                              use all reasonable efforts to ensure that the
                              customer premise equipment are only used with the
                              Customer’s authorisation.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <div class="pad_l10">
                        <div class="pad_l10">
                          <ul class="nolist">
                            <li>
                              <span class="pad_r15">(ii)</span>
                              <span>
                                inform the Service Provider as soon as is
                                reasonably practicable after the Customer
                                becomes aware that a customer premise equipment
                                is lost, stolen or damaged{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15">(iii)</span>
                              <span>
                                if requested by the Service Provider use all
                                reasonable efforts to return customer premise
                                equipment to the Service Provider after they
                                have been disabled from use on the Service
                                Provider Network.{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15">(iv)</span>
                              <span>
                                shall not tamper with or use the customer
                                premise equipment with any other third party
                                equipment/operator or operator equipment without
                                prior written consent of the authorised
                                signatory of the Service Provider.{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15">(v)</span>
                              <span>
                                shall not carry out any modification/alteration
                                or maintenance of the customer premise equipment
                                except as mandated by the Service Provider{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p>
                        <strong>
                          4.8 Services Provided by Service Provider Affiliates{" "}
                        </strong>
                      </p>
                      <p class="pad_l10">
                        If an Customer Agreement Form requires the provision of
                        Service to Customer in a jurisdiction other than a
                        jurisdiction within which Service Provider is authorized
                        to provide services, such Service may be provided to
                        Customer by an Affiliate of Service Provider and the
                        Service Provider acts as the agent of that Affiliate for
                        the Services in that jurisdiction. In jurisdictions,
                        where an Affiliate of the Service Provider is providing
                        the Service, then the relevant Service Provider
                        Affiliate has the right to demand a letter of
                        authorization (“LOA”) from the End User in a format
                        prescribed by the Service Provider Affiliate. The LOA
                        may require the End User to comply with all Laws, rules
                        and regulations in the relevant jurisdiction and give
                        the Service Provider Affiliate a right to inspect the
                        premises of the End User to ensure compliance and
                        terminate the Service in the event of any
                        non-compliance. The Service Provider and the Service
                        Provider Affiliate shall have no obligation to provide
                        any Service until it receives such LOA. If a Customer
                        Agreement Form requires the delivery of Service in a
                        jurisdiction where, in order for such Customer Agreement
                        Form to be enforceable, additional terms must be added,
                        the Parties shall incorporate such additional terms in
                        the Customer Agreement Form (preserving to the fullest
                        extent these Terms and Conditions).
                      </p>
                      <p>
                        <strong>4.9 Services Provided by Third Parties</strong>
                      </p>
                      <p class="pad_l10">
                        {" "}
                        The Service may be provided in conjunction with other
                        foreign-end administrations, underlying third party
                        carriers, Local Loop providers or any other common
                        carriers (“Third Party Service Provider”). Service
                        Provider’s obligations under these Terms and Conditions
                        do not apply, unless otherwise specified, to the lines,
                        facilities, or services provided by any Third Party
                        Service Provider for use in accessing the Service.
                      </p>
                      <p>
                        <strong>
                          5. OBLIGATIONS AND COVENANTS OF THE PARTIES
                        </strong>
                      </p>
                      <p>
                        <strong>
                          5.1 Representations and Warranties of the Customer
                        </strong>
                      </p>
                      <div class="pad_l10">
                        <p>The Customer represents and warrants that:</p>
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              it has the legal right and authority, and will
                              maintain the legal right and authority during the
                              Term, to use the Services as contemplated
                              hereunder;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              the execution, delivery and performance of these
                              Terms and Conditions by the Customer, use of
                              Services and the covenants, agreements or
                              undertakings of the Customer under these Terms and
                              Conditions do not violate any Law, rule,
                              regulation or order applicable to the Customer or
                              violate or contravene the provisions of or
                              constitute a default under any documents,
                              contracts, agreements or any other instruments to
                              which it is a party;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              these Terms and Conditions, when executed and
                              delivered by the Customer shall constitute its
                              valid and binding obligation enforceable in
                              accordance with the terms hereof;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              It is not subject to any material injunction
                              order, judgment or decree, which in any manner
                              challenges or seeks to prevent, enjoin, alter or
                              materially delay the transactions contemplated in
                              these Terms and Conditions; and{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(e)</span>
                            <span>
                              the Customer is authorized and has completed all
                              required corporate actions necessary to execute
                              the applicable Customer Agreement Form(s).{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>
                          5.2 Representations and Warranties of the Service
                          Provider
                        </strong>
                      </p>
                    </div>
                    <div class="flright width48">
                      <p>The Service Provider represents and warrants that:</p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              it has the legal right and authority, and will
                              maintain the legal right and authority during the
                              Term, to provide the Services ordered by the
                              Customer hereunder;{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              the execution, delivery and performance of these
                              Terms and Conditions by the Service Provider, use
                              of Services and the covenants, agreements or
                              undertakings of the Service Provider under these
                              Terms and Conditions do not violate any Law, rule,
                              regulation or order applicable to the Service
                              Provider or violate or contravene the provisions
                              of or constitute a default under any documents,
                              contracts, agreements or any other instruments to
                              which it is a party;
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              these Terms and Conditions, when executed and
                              delivered by the Customer Service Provider shall
                              constitute its valid and binding obligation
                              enforceable in accordance with the terms hereof;
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              It is not subject to any material injunction
                              order, judgment or decree, which in any manner
                              challenges or seeks to prevent, enjoin, alter or
                              materially delay the transactions contemplated in
                              these Terms and Conditions; and
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(e)</span>
                            <span>
                              the Service Provider is authorized and has
                              completed all required corporate actions necessary
                              to execute the applicable Customer Agreement
                              Form(s).
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>
                          5.3 Access to Customer Premises and Inspection
                        </strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              The Customer will allow the Service Provider
                              access to and use of the Customer Premises to the
                              extent required by the Service Provider for the
                              installation, connection, inspection and scheduled
                              or emergency maintenance or removal of the Service
                              Provider Equipment relating to the Services.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              The Service Provider shall have a right to inspect
                              any Customer Premises or any premises used by an
                              End User to ensure that the Customer and/or End
                              User is complying with all applicable Laws, rules
                              and regulations. Any such inspection or
                              non-inspection however, shall not relieve the
                              Customer of any of its duties under these Terms
                              and Conditions nor shall it cause the Service
                              Provider to waive any of its rights hereunder or
                              impose any duty, obligation or liability onto the
                              Service Provider.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              The Customer represents to the Service Provider
                              that the Customer has obtained or will obtain on a
                              timely basis all permissions and consents from
                              third parties necessary to allow the Service
                              Provider access as set forth herein, including
                              permission to cross real property to access the
                              Customer Premises. The Customer will be
                              responsible for providing and maintaining at its
                              own expense, the level of power, heating and air
                              conditioning necessary to maintain the proper
                              environment for the Service Provider Equipment on
                              the Customer Premises.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              The Customer will provide a safe place to work and
                              comply with all Laws and regulations regarding the
                              working conditions on the Customer Premises. In
                              the event that the Customer fails to meet its
                              obligations regarding the Customer Premises
                              hereunder and, as a result, the Service Provider
                              is unable to install or continue the delivery of
                              Services, then, notwithstanding the absence of
                              Services, the Customer will pay all charges for
                              such Services during such time. Except for
                              emergency repairs or for inspection at the
                              instruction of Governmental Authority, the Service
                              Provider will notify Customer 7 (seven) days in
                              advance of any regularly scheduled maintenance
                              that will require access to the Customer Premises.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong> 5.4 Service Provider Equipment</strong>
                      </p>
                      <div class="pad_l10">
                        <p> The Customer shall:</p>
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              ensure that any and all equipment (other than
                              Service Provider Equipment) connected to or used
                              in conjunction with any Service is connected or
                              used in accordance with the Law and shall obtain
                              the prior written approval or consent of the
                              Service Provider before connecting or permitting
                              any person to connect: (i) any equipment to any
                              telecommunications system operated by the Service
                              Provider (or any Affiliate of the Service
                              Provider), or any Service Provider Equipment; or
                              (ii) any part of a Service to any private or
                              public network whatsoever;
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              at its own expense, carry out such additions,
                              improvements, adjustments, modifications,
                              alterations or replacements to any equipment
                              (other than maintenance of the Service Provider
                              Equipment by the Customer or third parties
                              provided access to the Service Provider Equipment
                              by the Customer.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <div class="pad_l10">
                        {" "}
                        Service Provider Equipment) that is connected to or used
                        in connection with any telecommunications system
                        operated by the Service Provider or any the Affiliates
                        of the Service Provider, or to any Service Provider
                        Equipment as and when required by the Service Provider
                        for the purpose of maintaining the quality or increasing
                        the efficiency of any Service or of efficiently handling
                        the volume or increased volume of telecommunications
                        traffic or for any other purpose whatsoever;{" "}
                      </div>
                      <ul class="nolist">
                        <li>
                          <span class="pad_r15">(c)</span>
                          <span>
                            {" "}
                            install, use and keep all Service Provider Equipment
                            in good working condition (fair wear and tear
                            excepted) in accordance with the specifications,
                            guidelines and recommendations of the manufacturer
                            or distributor thereof and of the Service Provider,
                            and shall disconnect or cease to use any such
                            equipment at the request of the Service Provider;{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">(d)</span>
                          <span>
                            ensure that all equipment (other than Service
                            Provider Equipment) used or installed by or for the
                            Customer in conjunction with any Service, or which
                            connects to the System: (i) is compatible and may
                            properly function and operate with all other
                            equipment of the Service Provider used or installed
                            for the purpose; and (ii) will perform and is
                            configured according to published technical
                            specifications for such equipment and the Service
                            Provider’s interface specifications and otherwise
                            complies with the Service Provider’s specifications
                            for the Service.{" "}
                          </span>
                        </li>
                      </ul>
                      <p>
                        <strong>5.5 No obligation to Upgrade</strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              {" "}
                              The Service Provider is not obliged to change or
                              upgrade any telecommunications equipment, system
                              or network maintained or operated by the Service
                              Provider or any the Service Provider Equipment,
                              unless and to the extent such change or upgrade is
                              necessary for the provision/rendering of any
                              Service under these Terms and Conditions. If the
                              Service Provider elects to change or upgrade any
                              telecommunications equipment, system or network
                              maintained or operated by the Service Provider or
                              any the Service Provider Equipment, then the
                              Service Provider may request that the Customer
                              changes, upgrades, acquires and/or installs all
                              such equipment that may be necessary in order to
                              continue to enjoy or utilise any Service, and the
                              Customer may elect to carry out the foregoing,
                              upon agreement with the Service Provider as to the
                              costs payable by the Customer. In any such case,
                              the Customer shall pay the applicable Services
                              Fees including enhanced security deposit, if any,
                              as may be reasonably determined by the Service
                              Provider. The Customer shall promptly return the
                              Service Provider Equipment in exchange for any
                              replacement or upgrade to the same.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              The Service Provider may at any time change or
                              upgrade any telecommunications equipment, system
                              or network maintained or operated by the Service
                              Provider or any the Service Provider Equipment.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>6. OWNERSHIP</strong>
                      </p>
                      <p>
                        <strong>6.1 Intellectual Property</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Customer is and shall remain exclusively entitled to
                        all right and interest in and to all the Customer
                        Technology and its confidential information, and the
                        Service Provider is and shall remain exclusively
                        entitled to all right and interest in and to all Service
                        Provider Technology and its confidential information.
                        Neither Party shall, directly or indirectly, reverse
                        engineer, de-compile, disassemble or otherwise attempt
                        to derive source code or other trade secrets from the
                        property of the other Party.{" "}
                      </div>
                      <p>
                        <strong>6.2 Service Provider Equipment</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        Except as otherwise agreed in writing, title to all
                        Service Provider Equipment shall remain with the Service
                        Provider. The Service Provider will provide and maintain
                        the Service Provider Equipment in good working order.
                        The Customer shall not, and shall not permit others to,
                        change, rearrange, disconnect, remove, repair, attempt
                        to repair, or otherwise tamper with any Service Provider
                        Equipment, without the prior written consent of the
                        Service Provider. The Customer shall ensure that the
                        Service Provider Equipment shall not be used for any
                        purpose other than that for which the Service Provider
                        provides them. The Customer shall not take any action
                        that causes the imposition of any lien or encumbrance on
                        the Service Provider Equipment. In no event, the Service
                        Provider shall be liable to the Customer or any other
                        person for interruption of service or for any other
                        loss, cost or damage caused or related to improper use
                        or
                      </div>
                    </div>
                    <div class="flright width48">
                      <p>
                        <strong> 7. INDEMNITY</strong>
                      </p>
                      <div class="pad_l10">
                        <p>
                          {" "}
                          The Customer undertakes to indemnify, defend and hold
                          harmless the Service Provider and its directors,
                          officers, employees, representatives, agents and
                          Affiliates (“Indemnified Party”) to the fullest extent
                          permitted under applicable Law from and against any
                          and all claims, actions, losses, damages, penalties,
                          liabilities, costs, charges, expenses, suits, or
                          proceedings of whatever nature made, suffered or
                          incurred, including, any legal or other fees and
                          expenses actually incurred in connection with
                          investigating, disputing, preparing or defending any
                          action or claim, but excluding any consequential
                          losses or loss of profit, (“Losses”), to resulting
                          from, arising out or in connection with:{" "}
                        </p>
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              the use or operation by the Customer or any person
                              in any way (whether with or without the
                              authorisation and/or permission of the Customer)
                              of any Service subscribed by or provided to the
                              Customer, the Service Provider Equipment; or
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              any damage to Service Provider Equipment operated
                              or used, or any disruption or interference with
                              the provision or operation of any
                              telecommunications service, by any the Service
                              Provider or any if its Affiliates arising from the
                              installation and/or use of any Service Provider
                              Equipment by the Customer or any other person
                              (whether with or without the authorisation and/or
                              permission of the Customer); or
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              any loss of or damage to or any modification or
                              alteration of any the Service Provider Equipment
                              or the software of the Service Provider; or
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              caused or occurring at any time after the same has
                              been delivered or provided by the Service Provider
                              to the Customer or any person designated by the
                              Customer, and before the same is returned to the
                              Service Provider; or
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(e)</span>
                            <span>
                              any changes relating to the Customer’s network
                              configuration directly or indirectly affecting the
                              Service Provider’s network; or
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(f)</span>
                            <span></span>any content that passes through the
                            Service and that is transmitted, published or
                            circulated by or via the Customer; or
                          </li>
                          <li>
                            <span class="pad_r15">(g)</span>
                            <span>
                              any infringement or alleged infringement of a
                              third party’s rights arising in connection with
                              the Customer’s breach of the terms of these Terms
                              and Conditions.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>8. TERMINATION</strong>
                      </p>
                      <p>
                        <strong>8.1 Termination by either Party</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        Subject to Clause 4.3, either party can terminate the
                        Customer Agreement Form and these Terms and Conditions
                        by giving a sixty (60) days prior written notice to the
                        other Party.{" "}
                      </div>
                      <p>
                        <strong>
                          8.2 Termination by the Service Provider{" "}
                        </strong>
                      </p>
                      <div class="pad_l10">
                        <p>
                          The Service Provider may immediately (unless provided
                          otherwise) terminate Customer Agreement Form and these
                          Terms and Conditions upon written notice of
                          termination to the Customer if:
                        </p>
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              the Customer breaches a material provision (other
                              than a payment provision) of the Customer
                              Agreement Form or these Terms and Conditions and,
                              fails to cure such breach within thirty (30) days
                              after receipt of written notice of breach from the
                              Service Provider; or{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              the Customer breaches a payment provision of the
                              Customer Agreement Form or these Terms and
                              Conditions and, fails to cure such breach within
                              ten (10) days after receipt of written notice of
                              breach from the Service Provider; or{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              in the opinion of the Service Provider, the
                              Customer or any of its Affiliates have used, or
                              attempted to use any Service or the Service
                              Provider Equipment subscribed by the Customer
                              (whether with or without the authorisation and/or
                              permission of the Customer) in contravention of
                              any Law or these Terms and Conditions; or
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              any gift or consideration of any kind was offered
                              or given to any officer, employee, agent,
                              contractor or Affiliate of the Service Provider by
                              the secrets, contracts, agreements,
                              communications, formulae, invoices, financial
                              information, computer software, samples and other
                              information;
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <ul class="nolist">
                        <li>
                          <span class="pad_r15"> &nbsp; </span>
                          <span>
                            {" "}
                            Customer and/or its Affiliates, as an inducement or
                            reward, in connection with the provision of any
                            Service or the Service Provider Equipment; or{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">(e) </span>
                          <span>
                            the Customer causes or is likely to cause any
                            failure, interruption, disruption or congestion of
                            or in any telecommunications network, system or
                            services (whether of the Service Provider or any
                            other person); or{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">(f) </span>
                          <span>
                            an Insolvency Event vis-à-vis the Customer occurs.{" "}
                          </span>
                        </li>
                      </ul>
                      <p>
                        <strong>
                          {" "}
                          8.3 Additional Termination or Suspension by the
                          Service Provider
                        </strong>
                      </p>
                      <div class="pad_l10">
                        <p>
                          {" "}
                          The Service Provider shall have the right, upon
                          written notice, to immediately terminate or suspend
                          the Master Services Agreement (if any) or the Customer
                          Agreement Form or these Terms and Conditions, and
                          discontinue or suspend the delivery of the affected
                          Services (without liability) in the event that:
                        </p>
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15"> (a)</span>
                            <span>
                              {" "}
                              Customer has violated any Law, of any Governmental
                              Authority related to the Services or Customer’s or
                              an End User’s use thereof; or{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (b)</span>
                            <span>
                              Customer has engaged in conduct that has caused or
                              may cause (in Service Provider’s sole reasonable
                              judgment) damage to the Service Provider Equipment
                              or Service Provider Network; or{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (c)</span>
                            <span>
                              In the event Service Provider receives any
                              direction, notification or instruction from any
                              Governmental Authority to suspend or terminate the
                              provision of Services to Customer (through no
                              fault or negligence of Service Provider).{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>8.4 Termination by the Customer</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Customer may terminate the Customer Agreement Form,
                        in accordance with Clause 13.21.
                      </div>
                      <p>
                        <strong> 8.5 Service Provider’s Remedies</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The rates and charges set forth in each Customer
                        Agreement Form are established in reliance on the Term
                        commitment made therein. If the Customer cancels a
                        Service or Customer Agreement Form during a Term
                        commitment under Clause 9.1 above or in a particular
                        Service Schedule, or in the event Service Provider
                        terminates a Customer Agreement Form because of any
                        reasons set forth in Clauses 9.2 or 9.3, then the
                        Customer agrees to pay to the Service Provider which the
                        Customer acknowledges is a genuine pre-estimate of the
                        Service Provider’s loss, within 10 (ten) days of such
                        termination: (i) an amount equal to the total of any and
                        all waived installation charges as reflected on the
                        terminated Customer Agreement Form(s); (ii) an amount
                        equal to 100% (one hundred percent) of the Service Fees
                        payable for the unexpired remainder, plus (iii) any
                        documented third party expenses not covered by (i) and
                        (ii) above incurred by the Service Provider in respect
                        of the terminated Customer Agreement Form (including any
                        Local Loop charges). In any event, the Customer shall be
                        obligated to pay Service Provider for any Service
                        delivered to the Customer up to the date of termination.
                      </div>
                      <p>
                        <strong>9. CONFIDENTIALITY</strong>
                      </p>
                      <ul class="nolist">
                        <li>
                          <span class="pad_r15"> 9.1</span>
                          <span>
                            Save and except as otherwise permitted herein,
                            neither Party shall use, divulge or communicate to
                            any person, or publish or disclose or permit to be
                            published or disclosed, any secret or confidential
                            information relating to the other Party which it has
                            received or obtained, or may receiver or obtain{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15"> 9.2</span>
                          <span>
                            The foregoing obligations of confidentiality as set
                            out in Clause 10.1 shall not apply to:{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15"> 9.2</span>
                          <span>
                            The foregoing obligations of confidentiality as set
                            out in Clause 10.1 shall not apply to:{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15"> &nbsp; </span>
                          <span>
                            (a) information that is already publicly available;
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15"> &nbsp; </span>
                          <span>
                            (b) disclosure of information which is already known
                            to the recipient on a nonconfidential basis;
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15"> &nbsp; </span>
                          <span>
                            (c) disclosure of information to the extent so
                            required by any applicable Law to which the such
                            Party is subject or pursuant to a court order or
                            Government / Regulatory Authority;
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15"> &nbsp; </span>
                          <span>
                            (d) disclosure to be made in connection with the
                            performance of the obligations under these Terms and
                            Conditions or exercise of rights (including
                            remedies) under these Terms and Conditions.
                          </span>
                        </li>
                      </ul>
                      <p>
                        {" "}
                        9.3 For the purposes of this Clause 10, ‘information’
                        includes, without limitation, the following:{" "}
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15"> (a)</span>
                            <span>
                              any tangible or intangible information obtained or
                              disclosed to a Party, including all details,
                              documents, data, business information, know-how,
                              trade{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="flright width48">
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15"> &nbsp; </span>
                            <span>
                              secrets, contracts, agreements, communications,
                              formulae, invoices, financial information,
                              computer software, samples and other information;
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              information, documents, records and assets
                              relating to any customer of such Party; and{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              information on the terms of these Terms and
                              Conditions.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>10. GOVERNING LAW AND JURISDICTION</strong>
                      </p>
                      <p>
                        10.1 These Terms and Conditions shall be governed by the
                        Laws of India. The courts in Mumbai shall have the
                        exclusive jurisdiction over the subject matter of these
                        Terms and Conditions.{" "}
                      </p>
                      <p>
                        {" "}
                        10.2 Nothing in this Clause or Clause 12 below shall
                        prevent the Service Provider from commencing and
                        pursuing debt collection proceedings in any court of
                        competent jurisdiction for the recovery of any
                        undisputed Service Fees.
                      </p>
                      <p>
                        <strong>11. DISPUTE RESOLUTION</strong>
                      </p>
                      <p>
                        <strong>11.1 Amicable Resolution </strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        Any dispute, controversy, claim or disagreement of any
                        kind whatsoever between or among the Parties in
                        connection with or arising out of these Terms and
                        Conditions or the breach, termination or invalidity
                        thereof (hereinafter referred to as a “Dispute”) shall
                        be first referred to the designated senior management of
                        the Parties, to initiate discussions for amicable
                        resolution of the Dispute. If a Dispute is not resolved
                        by such senior management within a period of thirty (30)
                        days, then each of the Party to the Dispute shall be
                        entitled to refer the Dispute to arbitration in the
                        manner described below.{" "}
                      </div>
                      <p>
                        <strong>11.2 Arbitration Procedure</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        Any Dispute not resolved by the senior management within
                        thirty (30) days of commencement of discussions as
                        stated above, if referred to by any Party to the
                        Dispute, shall finally be resolved by arbitration
                        irrespective of the amount in Dispute or whether such
                        Dispute would otherwise be considered justifiable for
                        resolution by any court. These Terms and Conditions and
                        the rights and obligations of the Parties shall remain
                        in full force and effect pending the award in such
                        arbitration proceeding.{" "}
                      </div>
                      <p>
                        <strong>
                          11.3 Venue, Rules and Language of Arbitration
                        </strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The seat of the arbitration shall be Mumbai and shall be
                        conducted under, and in accordance with the Arbitration
                        and Conciliation Act, 1996, for the time being in force
                        which is deemed to be incorporated by reference into
                        this Clause. The language of the arbitration shall be
                        English.{" "}
                      </div>
                      <p>
                        <strong>
                          {" "}
                          11.4 Number and Appointment of Arbitrator
                        </strong>
                      </p>
                      <div class="pad_l10">
                        The arbitration panel shall consist of three (3)
                        arbitrators. Each Party shall be entitled to appoint one
                        (1) arbitrator each and the two (2) arbitrators so
                        appointed by the Parties shall appoint a third
                        arbitrator who will act as chairman of the proceedings.
                      </div>
                      <p>
                        <strong> 11.5 Award; Apportionment of Costs</strong>
                      </p>
                      <div class="pad_l10">
                        The award rendered shall be in writing and shall set out
                        the reasons for the arbitral tribunal’s decision. The
                        award shall allocate or apportion the costs of the
                        arbitration as the arbitral tribunal deems fair.
                      </div>
                      <p>
                        <strong>11.6 Award Final and Binding</strong>
                      </p>
                      <div class="pad_l10">
                        The Parties agree that the arbitration award shall be
                        final and binding on the Parties. The Parties agree that
                        no Party shall have any right to commence or maintain
                        any suit or legal proceedings (other than for interim or
                        conservatory measures) until the Dispute has been
                        determined in accordance with the arbitration procedure
                        provided herein and then only for enforcement of the
                        award rendered in the arbitration. Judgment upon the
                        arbitration award may be rendered in any court of
                        competent jurisdiction or application may be made to
                        such court for a judicial acceptance of the award and an
                        order of enforcement, as the case may be.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <p>
                        <strong>11.7 Right to Damages</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Parties expressly waive and forego any right to
                        punitive, exemplary, or similar damages in connection
                        with any Dispute and no such damages shall be awarded or
                        provided for in any Dispute resolution proceeding under
                        or in aid of this Clause{" "}
                      </div>
                      <p>
                        <strong>11.8 Specific Performance</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Parties agree that damages may not be an adequate
                        remedy and the Parties shall be entitled to an
                        injunction, restraining order, right for recovery, suit
                        for specific performance or such other equitable relief
                        as a court of competent jurisdiction may deem necessary
                        or appropriate to restrain the other Party from
                        committing any violation or enforce the performance of
                        the covenants, warranties or obligations contained in
                        these Terms and Conditions.
                      </div>
                      <p>
                        <strong class="underline redcolor">12. Payment</strong>
                      </p>
                      <div class="pad_l10">
                        <p>
                          {" "}
                          12.1 <strong>What the Customer shall pay:</strong> The
                          Customer shall pay the Charges{" "}
                        </p>
                        <p>
                          {" "}
                          12.2 <strong>When to pay:</strong> Service Provider
                          shall issue a monthly invoice to the Customer (or,
                          where relevant, make available such invoice for
                          download. The Customer shall pay the Charges within
                          eighteen (18) days of the date on any invoice.
                        </p>
                        <p>
                          {" "}
                          12.3 <strong> How to pay:</strong> The Customer shall
                          pay the Charges by direct debit unless otherwise set
                          out in the Commercial Terms.
                        </p>
                        <p>
                          12.4 <strong>Invoice Disputes: </strong>Where the
                          Customer disputes that an invoice or any part of it is
                          payable by the Customer, the Customer shall notify
                          Service Provider within twentyone (21) days of receipt
                          of the invoice, including details of why the invoiced
                          amount is incorrect and, if possible, how much the
                          Customer considers is due. All other Charges not
                          related to the dispute shall be paid in accordance
                          with clause 12.2 (When to Pay). If the Parties are
                          unable to resolve the disputed amount then the matter
                          shall be referred for resolution in accordance with
                          clause 11.1 (Escalation Procedure).{" "}
                        </p>
                        <p>
                          12.5{" "}
                          <strong>Payment following Invoice Disputes:</strong>{" "}
                          If an invoice dispute is resolved in favour of the
                          Customer then Service Provider shall provide for the
                          credit in favour of the Customer in the subsequent
                          invoice as per Service Provider’s billing cycle. If
                          the value of any disputed part of an invoice is
                          greater than Rs. 25,000 (twenty five thousand) then
                          the Customer may request that Service Provider add
                          interest to the credit note at the rate in clause 12.7
                          (Charge Interest). If the dispute is resolved in
                          favour of Service Provider then the Customer shall
                          immediately pay the disputed amount to Vodafone. If
                          the value of any disputed part of an invoice is
                          greater than Rs. 25,000 (twenty five thousand) then
                          Service Provider may charge interest on such amount at
                          the rate agreed between the Parties.
                        </p>
                        <p>
                          12.6{" "}
                          <strong>
                            What Service Provider may do if the Customer does
                            not pay on time:
                          </strong>{" "}
                          Where the Customer does not pay the Charges by the due
                          date, and has not raised a Dispute in accordance with
                          clause 12.4 (Invoice Disputes), Service Provider shall
                          be entitled to do the following:
                        </p>
                        <p>
                          12.7 <strong>Charge interest:</strong> Interest may be
                          charged on any unpaid amount at the applicable
                          statutory rate from time to time accruing on a daily
                          basis from the payment due date until payment is made
                          by the Customer in full.{" "}
                        </p>
                        <p>
                          12.8{" "}
                          <strong>
                            Suspend the provision of the Services/Equipment:
                          </strong>{" "}
                          If the Customer has not paid within fourteen (14) days
                          of receiving notice of the failure to pay, Service
                          Provider shall be entitled to suspend any further
                          deliveries of any Equipment or the provision of any
                          Services until Service Provider has been paid in full.
                        </p>
                        <p>
                          12.9 <strong>Withhold any sums owing:</strong> Service
                          Provider may withhold any sums currently owing to the
                          Customer by Service Provider by way of credit note or
                          rebate and offset such sums against the sums owing.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="flright width48">
                      <p>
                        {" "}
                        12.10 Tax: The Charges shall be exclusive of any
                        applicable Tax which shall be paid in addition by the
                        Customer to Vodafone.{" "}
                      </p>
                      <p>
                        <strong class=" redcolor">
                          13.{" "}
                          <span class="underline">Suspension of Services</span>
                        </strong>
                      </p>
                      <p>
                        13.1 <strong>Material breach:</strong> If the Customer
                        or a User commits a material breach of this Agreement,
                        Service Provider shall be entitled to suspend the
                        Services or other Equipment from using the Network. Such
                        suspension shall be without notice if this is required
                        by law or there is breach of clauses 3, 4, 5(Use of
                        Services or Equipment) or 12 (Payment). In all other
                        circumstances, Service Provider shall first provide
                        notice to the Customer and allow a period of five (5)
                        Business Days for the Customer to remedy any
                        non-compliance
                      </p>
                      <p>
                        {" "}
                        13.2 <strong>Maintenance: </strong> From time to time
                        Service Provider will need to carry out maintenance,
                        modification and testing of the Network, during which
                        time Service Provider shall be entitled to suspend the
                        Network Services. Service Provider may also suspend the
                        Network Services if there is a technical failure of the
                        Network, to safeguard the security and integrity of the
                        Network or if required by law. Service Provider shall
                        keep all suspensions to a minimum.{" "}
                      </p>
                      <p>
                        <strong class=" redcolor">
                          14. <span class="underline">Warranties</span>
                        </strong>
                      </p>
                      <p>
                        14.1 <strong>Full Power and Authority: </strong>Each of
                        Service Provider and the Customer warrants that it has
                        full power and authority to perform and observe its
                        obligations under this Agreement.
                      </p>
                      <p>
                        14.2 <strong>No other warranties:</strong> Unless
                        expressly set out in this Agreement all other warranties
                        and representations are expressly excluded.{" "}
                      </p>
                      <p>
                        <strong class=" redcolor">
                          15. <span class="underline">Data Protection</span>
                        </strong>
                      </p>
                      <p>
                        15.1 Each of Service Provider and the Customer shall
                        comply with Data Protection Legislation in respect of
                        Information.
                      </p>
                      <p>
                        15.2 Necessary Consents: The Customer shall ensure that
                        Information is accurate and up to date when disclosed.
                        The Customer consents to and permits, and shall also
                        ensure that it has or will obtain from all its Users all
                        necessary consents under applicable Data Protection
                        Legislation to permit:
                      </p>
                      <div class="pad_l10">
                        <p>
                          15.2.1{" "}
                          <strong>
                            Processing for the purposes of the Agreement:
                          </strong>{" "}
                          the processing by Service Provider of the Information
                          for the purposes of performing the Agreement and
                          providing the Services (including, for the avoidance
                          of doubt, for the purposes of market research and
                          analysis), and in certain circumstances passing the
                          data back to the Customer;
                        </p>
                        <p>
                          15.2.2 <strong>Network Security:</strong> the
                          interception, monitoring or recording of
                          communications between the Users’ mobile terminals and
                          other networks or resources to safeguard the security
                          and integrity of the Network and the Services; and
                        </p>
                        <p>
                          15.2.3 <strong>Quality Control:</strong> the
                          monitoring or recording of Users’ communications with
                          Vodafone’s customer care for the purposes of quality
                          control and training programmes. Service Provider
                          shall use all reasonable endeavours to notify Users
                          that calls to customer care may be recorded.{" "}
                        </p>
                        <p>
                          15.2.4 <strong>Disclosure to third parties; </strong>
                          disclosure to third parties (i) if necessary for the
                          above purposes (ii) if required by applicable law or
                          any court order, or the Regulatory Agencies or LEA
                          (iii) who are financial agencies (including debt
                          collection or credit reference agencies, fraud
                          monitoring schemes and credit providers).
                        </p>
                      </div>
                      <p>
                        <strong>16. MISCELLANEOUS</strong>
                      </p>
                      <p>
                        <strong>16.1 Expenses</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <p class="pad_l10">
                        Each Party shall pay its own costs and expenses in
                        connection with these Terms and Conditions.
                      </p>
                      <p>
                        <strong>16.2 No Third Party Beneficiary</strong>
                      </p>
                      <div class="pad_l10">
                        The terms and provisions of these Terms and Conditions
                        are intended solely for the benefit of the Parties
                        hereof and it is not the intention of the Parties to
                        confer third party beneficiary rights upon any other
                        person.
                      </div>
                      <p>
                        <strong>16.3 Binding Effect</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        These Terms and Conditions are binding upon and will
                        inure to the benefit of the Parties.
                      </div>
                      <p>
                        <strong>16.4 Invalid Provisions</strong>
                      </p>
                      <div class="pad_l10">
                        If any provision of these Terms and Conditions shall be
                        held to be illegal, invalid or unenforceable, in whole
                        or in part, under any enactment or Applicable Laws, such
                        provision or part shall to that extent be deemed not to
                        form part of these Terms and Conditions, and the
                        legality and enforceability of the remainder of these
                        Terms and Conditions shall not be affected.
                      </div>
                      <p>
                        <strong>16.5 Consent to Disclose</strong>
                      </p>
                      <div class="pad_l10">
                        The Service Provider reserves the right to provide any
                        customer or potential customer bound by a non-disclosure
                        agreement access to a list of the Service Provider’s
                        customers and a description of the Services purchased by
                        such customers. Customer consents to such disclosure;
                        including the listing of Customer’s name and the
                        Services purchased by Customer.
                      </div>
                      <p>
                        <strong>16.6 Contents of Communications</strong>
                      </p>
                      <div class="pad_l10">
                        The Service Provider does not monitor and will have no
                        liability or responsibility for the content of any
                        communications transmitted via the Services, and the
                        Customer will indemnify, defend and hold the Service
                        Provider harmless from any and all claims (including
                        claims by any Governmental Authority seeking to impose
                        penal sanctions) related to such content or for claims
                        by third parties relating to the Customer’s use of the
                        Service.
                      </div>
                      <p>
                        <strong>16.7 Application of Tariffs</strong>
                      </p>
                      <div class="pad_l10">
                        In the event, the Service Provider is required to file
                        tariffs with a Governmental Authority, the terms set
                        forth in the applicable tariff shall govern the Service
                        Provider’s delivery of, and the Customer’s consumption
                        or use of, such Service but only to the extent required
                        by Law.
                      </div>
                      <p>
                        <strong>16.8 Personal Information</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Customer acknowledges and agrees that the Service
                        Provider may use, process and/or transfer Personal
                        Information (including intra-group transfers and
                        transfers between countries): (i) in connection with the
                        provision of Services; (ii) to incorporate Personal
                        Information into databases controlled by the Service
                        Provider for the purpose of account administration,
                        billing and reconciliation, operational maintenance and
                        support activities, fraud detection and prevention, and
                        customer and market analysis and reporting; and (iii) to
                        communicate to the Customer by voice, letter, fax or
                        email regarding products and services of the Service
                        Provider. If Customer believes that, in the course of
                        providing Services under these Terms and Conditions, the
                        Service Provider will have access to data, which the
                        Customer does not want the Service Provider personnel to
                        comprehend then subject to applicable Law, the Customer
                        should encrypt such data so that it will be
                        unintelligible.{" "}
                      </div>
                      <p>
                        <strong>16.9 Force Majeure</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        If and to the extent that either Party’ performance of
                        any of its obligations pursuant to these Terms and
                        Conditions is prevented, hindered or delayed by a Force{" "}
                      </div>
                    </div>
                    <div class="flright width48">
                      <div class="pad_l10">
                        <p>
                          Majeure Event shall immediately notify the other Party
                          of the occurrence of the Force Majeure Event and
                          described in reasonable detail the nature thereof. The
                          nonperforming Party is however, obligated to mitigate
                          the effects of the Force Majeure Event. If the
                          performance of the Services are prevented for a period
                          in excess of ninety (90) days as a result of a Force
                          Majeure Event, the Parties shall mutually decide upon
                          the way forward.
                        </p>
                      </div>
                      <p>
                        <strong> 16.10 Notices</strong>
                      </p>
                      <div class="pad_l10">
                        Any notice or communication required to be given by the
                        Service Provider to the Customer hereunder, may be
                        delivered by hand, sent by overnight courier, email
                        (with confirmation of delivery) or facsimile (with
                        confirmation of delivery), at the addresses set forth in
                        the Customer Agreement Form(s) or at such other address
                        as may hereafter be furnished. Such notice will be
                        deemed to have been given as of the date it is
                        delivered, emailed, or faxed, as applicable. Any notice
                        to be served by the Customer to the Service Provider
                        hereunder shall be in writing and sent to the address,
                        as the Service Provider may direct the Customer to use
                        from time to time.
                      </div>
                      <p>
                        <strong>16.11 Waiver, Rights and Remedies</strong>
                      </p>
                      <div class="pad_l10">
                        No failure or delay by any Party in exercising any
                        right, power or remedy under these Terms and Conditions
                        shall operate as a waiver thereof. No single or partial
                        exercise of any right, power or remedy under these Terms
                        and Conditions by any Party shall preclude any further
                        exercise thereof or the exercise of any other right,
                        power or remedy by that Party. Without limiting the
                        foregoing, no waiver by any Party of any breach by any
                        other Party of any provision hereof shall be deemed to
                        be a waiver of any subsequent breach of that or any
                        other provision hereof.
                      </div>
                      <p>
                        <strong>
                          16.12 Assignment, Novation, Sub-contract, Transfer
                        </strong>
                      </p>
                      <div class="pad_l10">
                        The Services shall not be shared, sublet or assigned by
                        the Customer or used for any purpose other than those
                        approved by the Service Provider in any manner. It is
                        hereby expressly clarified and the Customer understands
                        that the granted service is for the benefit and bonafide
                        use of the Customer alone except as otherwise specified
                        these General Terms and Conditions. These Terms and
                        Conditions shall not be assigned, novated,
                        sub-contracted or otherwise transferred by the Customer
                        to any person, other than by prior written approval of
                        the Service Provider.
                      </div>
                      <p>
                        <strong>16.13 Further Assurances</strong>
                      </p>
                      <div class="pad_l10">
                        The Parties shall, with reasonable diligence, do all
                        such things and provide all such reasonable assurances
                        as may be required to consummate the transactions
                        contemplated by these Terms and Conditions in the manner
                        contemplated herein, and each Party shall provide such
                        further documents or instruments required by the other
                        Party as may be reasonably necessary or desirable to
                        effect the purpose of these Terms and Conditions and
                        carry out its provisions.
                      </div>
                      <p>
                        <strong>16.14 Amendments</strong>
                      </p>
                      <div class="pad_l10">
                        These Terms and Conditions can only be amended when
                        reduced in writing and on being duly signed by both the
                        Parties.
                      </div>
                      <p>
                        <strong>16.15 Counterparts</strong>
                      </p>
                      <div class="pad_l10">
                        These Terms and Conditions may be executed
                        simultaneously in any number of counterparts, each of
                        which will be deemed an original, but all of which will
                        constitute one and the same instrument.
                      </div>
                      <p>
                        <strong>16.16 Successors</strong>
                      </p>
                      <div class="pad_l10">
                        The provisions of these Terms and Conditions shall
                        ensure to the benefit of and be binding on the Parties
                        and their respective successors, legal heirs (including,
                        without limitation, any successor by reason of
                        amalgamation, scheme of arrangement, merger, de-merger
                        or acquisition of any Party) and legal representatives.
                      </div>
                      <p>
                        <strong>16.17 Relationship of the Parties</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 1</strong>
                  </p>
                  <p class="ligthbg_big">
                    GENERAL TERMS AND CONDITIONS FOR DELIVERY OF SERVICES
                  </p>
                  <div class="over_hid ">
                    <div class="fleft width48">
                      <div class="pad_l10">
                        <p>
                          Nothing contained in these Terms and Conditions is
                          intended to or shall be deemed to (a) make either
                          Party a general or special agent, legal
                          representative, joint venture party, partner or
                          employee of the other Party; or (b) constitute a
                          partnership or association of persons between the
                          Parties. No Party shall hold itself out as an agent
                          for the other Party, except with the express prior
                          written consent of the other Party. These Terms and
                          Conditions does not grant either Party any right or
                          authority to assume or create any obligation of any
                          kind, express or implied or to make any
                          representation, guarantee or warranty on behalf of or
                          binding upon the other Party.
                        </p>
                      </div>
                      <p>
                        <strong>16.18 No Contra Preferentem</strong>
                      </p>
                      <div class="pad_l10">
                        No rule of construction shall apply in the
                        interpretation of these Terms and Conditions to the
                        disadvantage of one Party on the basis that such Party
                        put forward or drafted these Terms and Conditions or any
                        provision thereof.{" "}
                      </div>
                      <p>
                        <strong>16.19 Publicity</strong>
                      </p>
                      <div class="pad_l10">
                        Neither Party shall advertise or publish any information
                        related to these Terms and Conditions without the prior
                        approval of the other Party.{" "}
                      </div>
                      <p>
                        <strong>16.20 Content of the Internet</strong>
                      </p>
                      <div class="pad_l10">
                        The Service Provider provides only access to the
                        internet. The Service Provider does not operate or
                        control the information, services, opinions or other
                        content of the internet, and the Service Provider makes
                        no warranties or representations regarding any such
                        information, services, opinions or other content. The
                        Customer agrees that it shall make no claim whatsoever
                        against the Service Provider relating to the content of
                        the internet or respecting any information, product,
                        service or software ordered through or provided by
                        virtue of the internet. The Service Provider reserves
                        the right to take such measures as may be reasonably
                        necessary, in the Service Provider’s sole discretion, to
                        ensure security and continuity of service on the Service
                        Provider Network, including but not limited to
                        identification and blocking or filtering of internet
                        traffic sources which the Service Provider deems to pose
                        a security or operational risk. In addition, the
                        Customer understands that the Service Provider does not
                        own or control other third party networks outside of the
                        Service Provider Network, and Service Provider is not
                        responsible or liable for any filtering or access
                        restrictions imposed by such networks or for the
                        performance (or non-performance) within such networks or
                        within interconnection points between the Service
                        Provider Network and other third party networks.
                      </div>
                      <p>
                        <strong>16.21 Regulatory and Legal Changes</strong>
                      </p>
                      <div class="pad_l10">
                        In the event of any change in applicable Law,
                        regulation, decision, rule or order that materially
                        increases the costs or other terms of delivery of
                        Service, then (a) the Service Provider may pass such
                        increased costs through to the Customer upon thirty (30)
                        days’ notice, and (b) the Customer may terminate the
                        affected Customer Agreement Form without termination
                        liability by delivering written notice of termination no
                        later than thirty (30) days after the effective date of
                        the rate increase.{" "}
                      </div>
                      <p>
                        <strong>16.22 Data</strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              Each Party shall comply with its obligations
                              pursuant to data protection laws and regulations
                              (if any) governing the provision of the Services
                              in each applicable territory, provided that:
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pad_l20">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(i)</span>
                            <span>
                              the Customer acknowledges that the Service
                              Provider or the Affiliates of the Service
                              Provider, may, by virtue of the Services, come
                              into possession of customer data; and
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(ii)</span>
                            <span>
                              the Customer acknowledges and agrees that the
                              Service Provider or the Affiliates of the Service
                              Provider may use, process and/or transfer customer
                              data in connection with the provision of the
                              Service(s), provided that the Customer may
                              withdraw its consent to the use, processing or
                              transfer of customer data pursuant to this Clause,
                              unless it is required in order for the Service
                              Provider or the Affiliates of the Service Provider
                              to provide or invoice for the Services, and / or
                              in order to comply with applicable Laws.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="flright width48">
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              The Customer warrants that it has obtained all
                              legally required consents and permissions from
                              relevant parties for the use, processing and
                              transfer of customer data, as described in this
                              Clause.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong> 16.23 Entire Understanding</strong>
                      </p>
                      <div class="pad_l10">
                        These Terms and Conditions read with the Service
                        Schedules, the Customer Agreement Form and the Master
                        Services Agreement, if any, constitutes the entire
                        understanding between the Parties and supersedes any
                        previous written or oral agreements, understandings,
                        negotiations and discussions between the Parties in
                        relation to the matters dealt with in these Terms and
                        Conditions provided that this Clause shall not exclude
                        any liability for (or remedy in respect of) fraudulent
                        misrepresentation. 16.24 Order of Precedence{" "}
                      </div>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              In the event of any conflict between any provision
                              of these Terms and Conditions, the Service
                              Schedules, the Customer Agreement Form and the
                              Master Services Agreement, if any, then only to
                              the extent that such conflict or inconsistency
                              cannot be resolved, the following order of
                              precedence shall prevail to the extent necessary
                              to resolve such conflict or inconsistency:{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pad_l20">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(i)</span>
                            <span> the Customer Agreement Form; </span>
                          </li>
                          <li>
                            <span class="pad_r15">(ii)</span>
                            <span> the applicable Service Schedules; </span>
                          </li>
                          <li>
                            <span class="pad_r15">(iii)</span>
                            <span>the General Terms and Conditions and </span>
                          </li>
                          <li>
                            <span class="pad_r15">(iv)</span>
                            <span>the Master Services Agreement (if any) </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              In the event of any conflict or inconsistency
                              between any provision of any document forming part
                              of these Terms and Conditions all rights conferred
                              on the Service Provider under these Terms and
                              Conditions with respect to any matter or event
                              shall be additional to the rights conferred on the
                              Service Provider under any other agreement with
                              the Customer with respect to that matter or event.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong>
                          16.25 Implementation of Applicable Law Compliance{" "}
                        </strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The Customer agrees that the Service Provider is subject
                        to the licensing requirement of Department of
                        Telecommunication and other applicable Laws, in force
                        and therefore, the Customer shall co-operate with the
                        Service Provider with regard to its compliance
                        requirements and provide any information as is requested
                        by the Service Provider. The Service Provider shall have
                        the right to enforce its rights and claim damages and
                        penalties as may be imposed by security agencies on the
                        Service Provider for which the Customer is solely
                        responsible.
                      </div>
                      <p>
                        <strong>16.26 Survival</strong>
                      </p>
                      <div class="pad_l10">
                        {" "}
                        The provisions contained in Clauses 7 (Ownership), 8
                        (Indemnity and Limitation of Liability), 9
                        (Termination), 10 (Confidentiality), 11 (Governing Law
                        and Jurisdiction), 12 (Dispute Resolution) and 13
                        (Miscellaneous) shall survive any expiry/ termination of
                        these Terms and Conditions.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 2</strong>
                  </p>
                  <p class="ligthbg_big">PRODUCT SPECIFIC</p>
                  <p>
                    <strong> 1.0 Establishment of Accounts</strong>
                  </p>
                  <div class="pad_l10">
                    <ul class="nolist">
                      <li>
                        <span class="pad_r15">(a)</span>
                        <span>
                          {" "}
                          Customer information will be established in the
                          Service Provider reservation and billing system
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(b)</span>
                        <span>
                          {" "}
                          Any Customer and/or End User information will be
                          established in the Service Provider reservation and
                          billing system Subsequent to establishing Customer or
                          User information within the Service Provider systems,
                          an email notification will be sent to the either the
                          Customer or End User as applicable The email will
                          advise that the account has been established, and will
                          provide access information required in order to
                          initiate use of the Product.{" "}
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(c)</span>
                        <span>
                          {" "}
                          Service Provider shall accept new Customer or End User
                          information either via facsimile, email or verbally
                          via phone.{" "}
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(d)</span>
                        <span>
                          {" "}
                          At all times during the provisioning of Service,
                          Customer shall ensure that the information required
                          for invoices/billing information relating to the
                          Customer are accurate and proper.{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <p>
                    <strong>2.0 Customised Reservations</strong>
                  </p>
                  <p>
                    Service Provider shall provide a twenty-four (24) hour,
                    seven (7) days per week free-phone reservations number for
                    the use of Customer and its Users. Service Provider shall
                    respond to all calls on the reservation line with a generic
                    greeting. Service Provider shall provide to Customer a
                    facsimile number/email id, in the event that Customer or its
                    End Users choose to fax/email their conference call request.
                  </p>
                  <p>
                    <strong>
                      3.0 Provision for phone and toll free Services
                    </strong>
                  </p>
                  <p>
                    Service Provider will maintain for the use of Customer and
                    its End Users a permanent free-phone toll free number(s) for
                    use in accessing the helpdesk. Service Provider reserves the
                    right to reclaim any dial-in numbers provided to Customer or
                    its End Users for Products and Services (“Dial-In Numbers”)
                    if such Dial-In Numbers are “inactive” for 120 days. The
                    term “inactive” is defined as no usage on a specific
                    number/service and no upcoming reservations scheduled for
                    such number/service. The Dial-In Numbers will be reassigned
                    to the Customer or its End Users, upon request, in Service
                    Provider’s sole discretion only if such Dial-In Numbers
                    continue to be available, otherwise, Customer or its Users
                    will be provided new Dial-In Numbers.
                  </p>
                  <p>
                    <strong>4.0 Terms and Conditions of Use.</strong>
                  </p>
                  <div class="pad_l10">
                    <ul class="nolist">
                      <li>
                        <span class="pad_r15">(a)</span>
                        <span>
                          Customer shall not carry out any reverse engineering,
                          decompiling of the Product;
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(b)</span>
                        <span>
                          Customer or End User agrees that it is solely
                          responsible for the content of all visual, written or
                          audible communications using User's account, User
                          shall not violate the rights of any third party in
                          using the Products and User shall comply with all
                          applicable laws while using the Products. Although
                          Service Provider or its Third Party Supplier is not
                          responsible for any such communications, Supplier or
                          its Third Party Supplier may delete any such
                          communications which do not comply with the
                          requirements of this clause of which they become
                          aware, at any time without notice.{" "}
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(c)</span>
                        <span>
                          . Other than using the Products for conferences or
                          meetings in which Customer or its End User is an
                          active participant, and as permitted under the terms
                          and conditions the Customer or End User shall not use
                          the same for any other purpose. The Customer shall not
                          resell the Products or otherwise generate income from
                          the Products.
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(d)</span>
                        <span>
                          {" "}
                          (i) Customer and End Users are responsible for all
                          uses of the Products in association with their
                          accounts, whether or not authorised by End User; (ii)
                          Customer and End Users are responsible for maintaining
                          the confidentiality of Customer and End User’s account
                          and owner numbers and necessary conference codes,
                          passwords and personal identification numbers used in
                          conjunction with the Products; and (iii) End Users
                          should notify as soon as reasonably possible of any
                          unauthorised use of End User’s account of which the
                          End User becomes aware
                        </span>
                      </li>
                    </ul>
                  </div>
                  <p>
                    <strong>
                      5.0 Responsibility of Content of Communication
                    </strong>
                  </p>
                  <div class="pad_l10">
                    <ul class="nolist">
                      <li>
                        <span class="pad_r15">(a)</span>
                        <span>
                          The End Users are solely responsible for the content
                          of all conference communications (visual, written or
                          audible) communicated via the Products using Users’
                          accounts. Customer acknowledges and agrees that
                          Service Provider does not control or monitor
                          Customer’s content nor guarantee the accuracy of
                          content communicated via the Products (notwithstanding
                          any Service Provider’s obligations to implement and
                          maintain the appropriate SLA’s).{" "}
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(b)</span>
                        <span>
                          If requested, by competent authorities Service
                          Provider may suspend any such communications{" "}
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(c)</span>
                        <span>
                          Customers or End Users are responsible for and
                          obligated to provide notification to the participants
                          prior to commencement of said conference that this is
                          a "recorded session".{" "}
                        </span>
                      </li>
                      <li>
                        <span class="pad_r15">(d)</span>
                        <span>
                          Service Provider shall ensure that the Products
                          contain the automatic functionality to provide
                          notification prior to commencement of said conference
                          that the conference is a ‘recorded session’ to all
                          Users and meeting participants when the Products
                          utilise any recording feature.{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="flright width30 text_l">
                      {" "}
                      <img src={VodafoneImg} />{" "}
                    </div>
                  </div>
                  <p class="font30 redcolor margin0">
                    <strong>Schedule 3</strong>
                  </p>
                  <p class="ligthbg_big">
                    REGULATORY REQUIREMENTS AND COMPLIANCE
                  </p>
                  <div class="thefallow">
                    The following conditions (“Regulatory Terms”) are required
                    to be provided/completed prior to activation of any
                    Services. These are conditions specified by the Department
                    of Telecommunication (“DOT”) Ministry of Communication and
                    Information Technology (“MCIT”), Government of India (“GoI”)
                    and are mandatory conditions. There shall be no deletion or
                    deviation from the following Regulatory Terms and the same
                    shall prevail over any other General or Special Conditions
                    that are signed between Customer and Operator.
                  </div>
                  <div class="over_hid">
                    <div class="fleft width48">
                      <p>
                        <strong class="redcolor">1. DEFINITION</strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">(a)</span>
                            <span>
                              <strong class="redcolor">“Agreement”</strong>{" "}
                              shall mean the CAF, Regulatory Terms, General
                              Terms and Conditions and any Special Conditions
                              and Product Contract (if and as signed between the
                              Parties;
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(b)</span>
                            <span>
                              <strong class="redcolor">
                                “Company” or “Service Provider”
                              </strong>{" "}
                              shall mean the entity providing the Service as
                              contemplated under the Agreement.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(c)</span>
                            <span>
                              <strong class="redcolor">“Circle”</strong> shall
                              mean the telecommunication Licensed Service Area
                              as set out in Exhibit 1 to these Regulatory Terms.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(d)</span>
                            <span>
                              <strong class="redcolor">
                                “CAF" shall mean the Customer Agreement Form”
                              </strong>
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(e)</span>
                            <span>
                              <strong class="redcolor">“Customer”</strong> shall
                              mean the entity or person signing the Agreement.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(f)</span>
                            <span>
                              <strong class="redcolor">"DOT"</strong> shall mean
                              Department of Telecommunications
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(g)</span>
                            <span>
                              <strong class="redcolor">“Equipment”</strong>{" "}
                              shall mean equipment supplied by Service Provider
                              for rendering the Services.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(h)</span>
                            <span>
                              {" "}
                              <strong class="redcolor">
                                “Governmental Authority”
                              </strong>{" "}
                              means any national, state, or local government;
                              any subdivision, agency, court, commission, or
                              authority thereof exercising any policymaking,
                              regulatory, judicial, or taxing authority in
                              India; and any statutory authority having
                              jurisdiction over and in respect of the Parties
                              including without limitation, the Telecom
                              Regulatory Authority of India or the Department of
                              Telecommunications
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(i)</span>
                            <span>
                              <strong class="redcolor">“Law(s)”</strong> shall
                              mean all applicable laws, by-laws, rules,
                              regulations, orders, ordinances, protocols, codes,
                              guidelines, policies, notices, directions,
                              judgments, decrees or other requirements or
                              official directive of any Governmental Authority
                              or person acting under the authority of any
                              Governmental Authority, whether in effect on the
                              date of these Terms and Conditions or thereafter;
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(j)</span>
                            <span>
                              <strong class="redcolor">
                                “Licensed Service Area”
                              </strong>{" "}
                              or "Circle" shall mean the area where Operator is
                              permitted to offer telecommunication services
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(k)</span>
                            <span>
                              <strong class="redcolor">“Operator”</strong> shall
                              mean Vodafone India entity(ies) as named under
                              Exhibit 1 having a license to operate in a Circle
                              as also more particularly described in Exhibit 1.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(l)</span>
                            <span>
                              <strong class="redcolor">“Service”</strong> shall
                              mean the Services as set out in Exhibit 2.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">(m)</span>
                            <span>
                              <strong class="redcolor">“Vodafone India”</strong>{" "}
                              shall mean the Vodafone India group of Companies
                              licensed to operate telecommunication licenses in
                              India and more particularly described in Exhibit
                              1.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong class="redcolor">
                          2. VERIFICATION OF SUBSCRIBER (CUSTOMER) AND
                          DOCUMENTATION REQUIRED
                        </strong>
                      </p>
                      <p>
                        {" "}
                        Under the current conditions mandated by DOT all
                        subscribers are required to provide proof of identity
                        and the following are accepted as mandatory proof of
                        identity documents:
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">
                              <strong>(a)</strong>
                            </span>
                            <span>
                              {" "}
                              <strong>
                                For Companies (all connections in the name of
                                the Company):
                              </strong>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pad_l20">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15"> (i) </span>
                            <span>
                              {" "}
                              Memorandum and Articles of Association duly signed
                              by a Director or the Company Secretary with
                              company seal.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (ii) </span>
                            <span>
                              {" "}
                              Certificate of Incorporation duly signed by a
                              Director or the Company Secretary with company
                              seal.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (iii) </span>
                            <span>
                              Certified (with Company Stamp) true copy of Board
                              Resolution of Company showing the clear authority
                              of the person signing the CAF. (Certification has
                              to be given by Company Secretary or Director).{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (iv) </span>
                            <span>
                              Annual Return of the Company for the last two
                              years.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (v) </span>
                            <span>
                              {" "}
                              Latest Form 32 ( as per the Companies (Central
                              Government's) General Rules and Forms, 1956)
                              showing the details of the Directors{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (vi) </span>
                            <span>
                              {" "}
                              Latest Form 18 ( as per the Companies (Central
                              Government's) General Rules and Forms, 1956)
                              showing the current registered office of the
                              Company{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (vii) </span>
                            <span>Proof of Address </span>
                          </li>
                        </ul>
                        <div class="pad_l10">
                          <ul class="nolist">
                            <li>
                              <span class="pad_r15"> 1. </span>
                              <span>
                                Registered Lease agreement/title deed for the
                                address where the connection is sought or the
                                billing address;{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> 2. </span>
                              <span>
                                Telephone bill/electricity bill/gas bill/water
                                bill or any other utility bill in the name of
                                the Company;{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15"> (viii) </span>
                            <span>
                              Proof of identity of persons signing the CAF:{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> (ix) </span>
                            <span>
                              Copy of Board Resolution for Authorized Signatory;
                            </span>
                          </li>
                        </ul>
                        <p class="redcolor">
                          <strong>4. FRAUDULENT USE OF SERVICES </strong>
                        </p>
                        <div class="pad_l10">
                          {" "}
                          The Customer shall be solely responsible for all
                          charges incurred as a result of fraudulent or
                          unauthorized use of the Services including but not
                          limited to use of Service beyond authorized credit
                          limits and in areas/outside the scope of the Licensed
                          Service Area notwithstanding the availability of such
                          Service on account of any technical or test reasons.
                        </div>
                        <p class="redcolor">
                          <strong>5. CUSTOMER NETWORK SECURITY</strong>
                        </p>
                        <div class="pad_l10">
                          {" "}
                          The Customer is responsible for maintaining the
                          security of its internal network from unauthorized
                          access. Service provider is not responsible for access
                          to the Services, active or passive, or any facilities
                          available to persons outside the Customer/not
                          authorized by Customer. Service Provider and/or its
                          authorized service providers/third party vendor for
                          the provided Service can access/intercept the
                          Services/facilities for the purposes of
                          maintenance/provision of any Services and/or
                          facilitation/correction of any Services.{" "}
                        </div>
                        <p class="redcolor">
                          <strong>6. PERSONAL INFORMATION</strong>
                        </p>
                        <div class="pad_l10">
                          {" "}
                          The Customer acknowledges and agrees that the Service
                          Provider may use, process and/or transfer Personal
                          Information (including intra-group transfers and
                          transfers between countries): (i) in connection with
                          the provision of Services; (ii) to incorporate
                          Personal Information into databases controlled by the
                          Service Provider for the purpose of account
                          administration, billing and reconciliation,
                          operational maintenance and support activities, fraud
                          detection and prevention, and customer and market
                          analysis and reporting;{" "}
                        </div>
                        <p class="redcolor">
                          <strong>7. RESALE AND USE OF SERVICES</strong>
                        </p>
                        <div class="pad_l10">
                          <p class="redcolor">
                            <strong>7.1 Resale of Services</strong>
                          </p>
                          <div class="pad_l10">
                            {" "}
                            The Customer shall not have any right to market
                            and/or re-brand the Services purchased by it as its
                            own product and services, and/or to resell or
                            sublicense the Services to End Users unless it has
                            all required legal and/or regulatory licenses and
                            consents from all relevant Governmental Authority.
                            The Customer shall strictly liable for all the legal
                            and/or regulatory consequences of
                            reselling/subletting the Services without proper
                            licenses and consents from all relevant Governmental
                            Authority with respect to the resale of Services.
                            The Customer shall be solely responsible and liable
                            for any misuse of Services in respect of the
                            Customer’s resale of Services and shall indemnify
                            and hold harmless the Service Provider against any
                            and all claims or proceedings from such resale or
                            sublicense of Services by the Customer. In case of
                            resale or sublicense of Services the Customer shall
                            not (i) refer to the Service Provider in any
                            marketing or service literature except with the
                            Service Provider’s prior written consent; or (ii)
                            act or purport to act on behalf of the Service
                            Provider; or (iii) any resale or sublicense by the
                            Customer of the Services shall not relieve the
                            Customer of its obligations under any applicable
                            Customer Order Form or these Terms and Conditions.{" "}
                          </div>
                          <p class="redcolor">
                            <strong> 7.2 Customer’s Obligation </strong>
                          </p>
                          <div class="pad_l10">
                            <p>
                              {" "}
                              In the event the Customer resells or sublicenses
                              the Services to an End User (permitted End User
                              being an employee or as per Customer license), it
                              shall do so only under the conditions that:
                            </p>
                            <ul class="nolist">
                              <li>
                                <span class="pad_r15"> (a) </span>
                                <span>
                                  any and all of such End User’s acts and
                                  omissions (including any failure by the End
                                  User to comply with any applicable Law rule or
                                  regulation in the jurisdiction in which its
                                  uses or resells the Services) shall be
                                  attributable to the Customer for the purposes
                                  of these Terms and Conditions; and{" "}
                                </span>
                              </li>
                              <li>
                                <span class="pad_r15"> (b) </span>
                                <span>
                                  any resale or sublicense by the Customer of
                                  the Services shall not relieve the Customer of
                                  its obligations under any applicable Customer
                                  Order Form or these Terms and Conditions or
                                  any Service Schedule.
                                </span>
                              </li>
                            </ul>
                          </div>
                          <p class="redcolor">
                            <strong>7.3 Access to System</strong>
                          </p>
                          <div class="pad_l10">
                            {" "}
                            The Service Provider reserves the right to manage
                            and control access to the System and any data,
                            including customer data, stored in the System in a
                            manner deemed reasonably appropriate by the Service
                            Provider in order for the Service Provider to comply
                            with subject to jurisdiction and enforceability of
                            Indian Courts and nothing contained in any other
                            schedules/annexure to this CAF or any other
                            agreement between the Parties shall amend or nullify
                            the applicability of Indian law or licensing
                            conditions and the jurisdiction of Indian courts to
                            these Regulatory Terms or the provisioning of the
                            Services.
                          </div>
                          <p>
                            9.2 Should any provision of this agreement be or
                            become ineffective or be held to be invalid, this
                            shall not affect the validity of the remaining
                            provisions. Any invalid provision herein shall be
                            replaced, interpreted or supplemented as the case
                            may be in such a manner that the intended economic
                            purpose of the provision will be achieved.
                          </p>
                          <p>
                            9.3 (a)The CAF; (b) These Regulatory Terms; (c)
                            General Terms and Conditions; (d) Product specific
                            terms and conditions (Special Terms); and (e) any
                            other attendant documents thereto shall be and are
                            subject to the Indian Telegraph Act of 1885, Indian
                            Wireless Telegraphy Act of 1933, DOT License issued
                            to Service Provider and Service Provider’s third
                            party vendors for the Services being provided and
                            all rules and regulations issued thereunder. The
                            rules and regulation framed thereunder and any
                            statutory modifications or re-enactment for the time
                            being in force and any other Government regulations
                            issued from time to time.
                          </p>
                        </div>
                        <p class="redcolor">
                          <strong>10.OBLIGATIONS OF THE CUSTOMER</strong>
                        </p>
                        <p>
                          {" "}
                          The Customer shall throughout the duration of the
                          Services:
                        </p>
                        <div class="pad_l10">
                          <ul class="nolist">
                            <li>
                              <span class="pad_r15"> (a) </span>
                              <span>
                                {" "}
                                Acknowledge that any equipment provided to the
                                Customer at the time of activation of the
                                Services is the sole and absolute property of
                                the Service Provider even after termination of
                                the Services unless sold/transferred formally
                                through commercial arrangement to the Customer
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (b) </span>
                              <span>
                                The Service shall not be used to make foul,
                                profane expressions, impersonate another person
                                with fraudulent or malicious intent, to call
                                another person so frequently or at such times of
                                the day or any other manner so as to annoy,
                                abuse, threaten or harass any third party{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (c) </span>
                              <span>
                                The Service shall not be used for any purpose in
                                violation of the law or against public policy or
                                national integrity and security{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (d) </span>
                              <span>
                                {" "}
                                The Service shall not be used in such a manner
                                as to interfere unreasonably with the use of the
                                Service by one or more Customers or interfere
                                with Service Provider's reasonable ability to
                                provide the Services to others
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (e) </span>
                              <span>
                                The Customer shall not make any modification or
                                alteration or any reverse engineering or
                                re-assembling to the Equipment.{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (f) </span>
                              <span>
                                {" "}
                                The Customer shall not link / network the
                                Services with any other telecommunication
                                network including but not limited to Internet
                                Service network among others
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (g) </span>
                              <span>
                                {" "}
                                The Customer agrees that Service Provider shall
                                not be liable or responsible for any alleged
                                fault of any nature in the Equipment.
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (h) </span>
                              <span>
                                The Customer shall intimate in writing details
                                of change of name, address, title or
                                constitution whenever such change takes place.
                                If such change is not intimated/ not acceptable
                                to Service Provider, Service Provider shall have
                                the right to proceed against the existing
                                Customer to recover its due and/or, disconnect
                                the Services. This does not include any
                                assignment of Services to any third party.{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (i) </span>
                              <span>
                                {" "}
                                Any request for transfer of Services available
                                to a particular Customer in favour of any other
                                person would have to be made in writing and
                                Service Provider may in its sole discretion
                                either allow the transfer or refuse to effect
                                such transfer without assigning any reasons
                                whatsoever. The decision of Service Provider in
                                this regard is final and binding on the
                                Customer. Charges for this shall be as
                                prescribed by Service Provider from time to
                                time.
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (j) </span>
                              <span>
                                The Customer hereby expressly agrees that
                                Service Provider has the right to change the
                                Regulatory Terms of providing the Service
                                whether or not such change is necessitated by
                                reason of Government directions contained in a
                                letter, memorandum, circular agreement,
                                Supplementary license or the like{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (k) </span>
                              <span>
                                It is understood that any Customer information
                                provided to Service Provider will be made
                                available to any statutory and regulatory
                                authority if so required without prior
                                intimation to the Customer.{" "}
                              </span>
                            </li>
                            <li>
                              <span class="pad_r15"> (l) </span>
                              <span>
                                The Customer shall be allotted a phone
                                number/identity number by Service Provider which
                                is and shall always remain the property of
                                Service Provider. The Customer cannot transfer
                                or otherwise assign the phone number/identity
                                number, Services to any other person/entity
                                unless agreed by Service Provider in writing.
                                Service Provider shall be at liberty to reject
                                the request made by the Customer without
                                assigning any reason. Service Provider reserves
                                the right to reassign/ change the phone
                                number/identity number after giving reasonable
                                notice to the Customer.{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <p class="redcolor">
                          <strong> 11.FORCE MAJEURE </strong>
                        </p>
                      </div>
                    </div>
                    <div class="flright width48">
                      <div class="pad_l20">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15"> x </span>
                            <span>
                              Copy of identity proof of Company Secretary (and
                              his / her enrolment number with the Institute of
                              Company Secretaries of India (ICICI)) and/or
                              Director{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> xi </span>
                            <span>
                              Copy of his identity card and letter from Company
                              that he / she is employed with the Company.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15"> xii </span>
                            <span>
                              Original copy of Power of Attorney with photo of
                              executor and the POA holder
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              <strong> xiii </strong>
                            </span>
                            <span>
                              <strong>
                                Acceptable individual identity proofs:
                              </strong>{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pad_l10 subtablein subin">
                        <table
                          width="100%"
                          border="0"
                          cellspacing="0"
                          cellpadding="0"
                        >
                          <tbody>
                            <tr>
                              <td>Voter’s Identity Card;</td>
                              <td>Passport (valid);</td>
                            </tr>
                            <tr>
                              <td>Ration Card;</td>
                              <td>Driving License;</td>
                            </tr>
                            <tr>
                              <td>Pan Card</td>
                              <td>Credit/Debit Card (with Photograph) </td>
                            </tr>
                            <tr>
                              <td>Arms License</td>
                              <td>CGHS/ECHS Card </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                Smart card issued by CSD, Defence/Paramilitary
                                or any other identity issued by a State or
                                Central Government
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="pad_l20">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>xiv</strong>{" "}
                            </span>
                            <span>
                              <strong>
                                xiv. Acceptable additional Address proof:
                              </strong>{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pad_l10 subtablein subin">
                        <table
                          width="100%"
                          border="0"
                          cellspacing="0"
                          cellpadding="0"
                        >
                          <tbody>
                            <tr>
                              <td>Individuals only</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Voter’s Identity Card;</td>
                              <td>Passport</td>
                            </tr>
                            <tr>
                              <td>Ration Card;</td>
                              <td>Driving License;</td>
                            </tr>
                            <tr>
                              <td>
                                Current passbook of post office/PSU bank with
                                photo and address{" "}
                              </td>
                              <td>Arms License</td>
                            </tr>
                            <tr>
                              <td>
                                Address card with photo issued by department of
                                post
                              </td>
                              <td>
                                Certificate of photo issued by MP/MLA/group A
                                gazetteer officer
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Certificate of address having photo issued by
                                village Panchayat
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Individuals and Company</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>PAN Card</td>
                              <td>Water Bill (not older than 3 months)</td>
                            </tr>
                            <tr>
                              <td>Fixed Line Phone Bill</td>
                              <td>Electricity Bill</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>
                        <strong class="redcolor">3. SCOPE </strong>{" "}
                      </p>
                      <ul class="nolist">
                        <li>
                          <span class="pad_r15">
                            {" "}
                            <strong>a</strong>{" "}
                          </span>
                          <span>
                            The Services are provided by the Service Provider,
                            by virtue of a license provided by the DOT,
                            Government of India, it is specifically agreed, that
                            in the event of any change in the law concerning the
                            Service or any government policy, in relation to the
                            Services, any and all such changes and alterations
                            shall be deemed to be applicable to the Services and
                            these terms and conditions deemed to have been
                            amended in accordance with the revised alterations
                            or policies based on the Government Policy.{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">
                            {" "}
                            <strong>b</strong>{" "}
                          </span>
                          <span>
                            {" "}
                            The Service Provider reserves the right to reject
                            any application of any Customer in case of
                            incomplete and/or incorrect information and
                            documentation or failure to qualify the terms and
                            conditions laid down by Service Provider and any
                            Government policy.{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">
                            {" "}
                            <strong>c</strong>{" "}
                          </span>
                          <span>
                            Services will be activated only after proper
                            verification of documents submitted by the Customer.
                            In the event of incomplete and/or incorrect
                            information, Service Provider has the right to
                            cancel the Services in whole or in part without
                            prior notice and without assigning any reason{" "}
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">
                            {" "}
                            <strong>d</strong>{" "}
                          </span>
                          <span>
                            {" "}
                            Service Provider reserves the right to seek/verify
                            financial and other information from the Customer's
                            bank associates, neighbours or any other third party
                            at the discretion of Service Provider and Services
                            even after activation of Services for any reason
                            without any liability of any nature. The information
                            provided by the Customer gathered by Service
                            Provider shall become the property of Service
                            Provider. Service Provider may be required to
                            disclose any information pertaining to the
                            Customer/Subscriber to any statutory or regulatory
                            authority, security agency, financial institution or
                            bank and reserves the right to disclose at its
                            discretion without any prior intimation to the
                            Customer. e. The value added/plus service can be
                            availed on payment of applicable tariff. Service
                            Provider reserves the right to change, alter, vary,
                            add or withdraw any such service at its sole
                            discretion
                          </span>
                        </li>
                      </ul>
                      <div class="pad_l20">
                        {" "}
                        applicable laws and regulations governing the provision
                        of the Services in each applicable territory.{" "}
                      </div>
                      <p>
                        <strong class="redcolor">
                          7.4 Customer Network Security
                        </strong>
                      </p>
                      <div class="pad_l20">
                        {" "}
                        The Customer is responsible for maintaining the security
                        of its internal network from unauthorized access through
                        the internet. The Service Provider shall not be liable
                        for unauthorized access to the Customer’s network or
                        other breaches of Customer’s network security.
                      </div>
                      <p>
                        <strong class="redcolor">7.5 Use of Services</strong>
                      </p>
                      <ul class="nolist">
                        <li>
                          <span class="pad_r15">
                            {" "}
                            <strong>a</strong>{" "}
                          </span>
                          <span>
                            The Customer shall use the Services shall only for
                            the purposes specified by the Customer in the
                            Customer Order Form and shall not be used for or
                            connected to any telecommunication service including
                            public switched telephone network (PSTN)/ public
                            land mobile network (PLMN) or any other services of
                            whatsoever nature unless otherwise permitted by the
                            Governmental Authority with specific approvals/
                            licenses. In case the Service is found to have
                            terminated on a PSTN/ PLMN at either end other than
                            as mentioned above, the Customer understands and
                            agrees that the Service Provider shall be entitled
                            to terminate the Service and collect financial
                            penalty as levied by the Governmental Authority.
                            Such charges shall become due and payable by the
                            Customer to the Service Provider within 5 (five)
                            working days of the termination of the Service and
                            the Customer Order Form. The Service Provider shall
                            also, at its sole discretion, be entitled to
                            disconnect the circuit with immediate effect.
                          </span>
                        </li>
                        <li>
                          <span class="pad_r15">
                            {" "}
                            <strong>b</strong>{" "}
                          </span>
                          <span>
                            The Services shall not be used for call centre/
                            other service provider (OSP) operations without
                            registration with the Department of
                            Telecommunications as a call centre/ OSP. The
                            Customer who wants to take the Services for call
                            centre/ OSP operations shall submit copy of call
                            centre/ OSP license or registration to the Service
                            Provider.
                          </span>
                        </li>
                      </ul>
                      <p>
                        <strong class="redcolor">
                          {" "}
                          7.6 Bulk Encryption by the Customer{" "}
                        </strong>
                      </p>
                      <div class="pad_l20">
                        {" "}
                        The Customer shall not employ bulk encryption equipment
                        in the network. Any encryption equipment are to be
                        deployed for specific requirements, then the Customer
                        shall do so with the prior evaluation and approval of
                        Governmental Authority specifically designated for the
                        purpose.{" "}
                      </div>
                      <p>
                        <strong class="redcolor">
                          8. SERVICE PROVIDER’S PROVISION OF SERVICES
                        </strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>a</strong>{" "}
                            </span>
                            <span>
                              {" "}
                              Company shall provide the Services with reference
                              to and subject to the telecommunication operating
                              license provided by DOT for permitting the
                              operations of a telephony services.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>b</strong>{" "}
                            </span>
                            <span>
                              {" "}
                              Service Provider shall use reasonable effort to
                              make the Services available to Customer at all
                              times.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>c</strong>{" "}
                            </span>
                            <span>
                              The availability and quality of Services may be
                              affected by factor outside Service Provider
                              control such physical obstruction, geographic and
                              weather conditions and other cause of radio
                              interference or faults in other telecommunications
                              network to which network is connected.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>d</strong>{" "}
                            </span>
                            <span>
                              The Services may be suspended in whole or in part
                              at any time with proper notice and in compliance
                              to existing guidelines, policies and regulations.,
                              however if the network fails or requires
                              modifications or maintenance due to a sudden or
                              force majeure event beyond control of operator,
                              such advance notice may not be possible. The
                              Customer may remain liable for charges during the
                              period of suspension per any service level
                              agreement in place between the Parties.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <strong class="redcolor">
                          9. GOVERNING LAW AND JURISDICTION:
                        </strong>
                      </p>
                      <div class="pad_l10">
                        <p>
                          9.1 The courts in India shall have exclusive
                          jurisdiction on the provision of Services and for all
                          regulatory requirements/compliances under Applicable
                          Law. If the commercial arrangement between the Service
                          Provider and Customer is made applicable to any other
                          applicable law other than or in addition to laws of
                          India, the laws of India and DOT licensing conditions
                          shall have always and have complete precedence and
                          prevailing effect and any other condition shall be
                          overridden and shall be subservient to any applicable
                          DOT licensing condition and to that extent such other
                          condition shall be considered void. These Regulatory
                          Terms shall always be subject to Indian law and{" "}
                        </p>
                      </div>
                      <div class="pad_l10">
                        <p>
                          Service Provider shall be not be responsible for break
                          or disruption of Services on account of Force majeure
                          circumstances, such as riots, strike and lockouts
                          either in the works and office of Service Provider or
                          general strike in the city or in the country, civil
                          disobedience. act of war, flood, tempest, fire,
                          earthquake or any other, act of God or policy of the
                          Government, systems failure, Network failure, any
                          external network or any cause of nature beyond the
                          control of Service Provider, making it impossible to
                          render the Services to the Customer. However, the
                          Customer shall continue to pay all Tariffs as per
                          Conditions. It is understood by the Customer that
                          provision of Services by Service Provider is subject
                          to certain external factors and agencies who are
                          actively involved in providing the Services to the
                          Customer and Service Provider shall not be liable for
                          the same
                        </p>
                      </div>
                      <p>
                        <strong class="redcolor">
                          12.REGULATORY COMPLIANCE SPECIFIC PROVISIONS
                        </strong>
                      </p>
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>a</strong>{" "}
                            </span>
                            <span>
                              The Customer expressly waives notice from Service
                              Provider for the following: (i) to suspend the
                              Services (ii) to disconnect the Customer from the
                              Services and Network (iii) to change Tariff.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>b</strong>{" "}
                            </span>
                            <span>
                              {" "}
                              Service Provider reserves the right to
                              modify/alter/amend or change all or any of the
                              Regulatory Terms of this CAF without notice, or by
                              a written notice addressed by Service Provider to
                              the last address given by the Customer, at its
                              sole discretion, due to regulatory compulsions.
                              Service Provider shall also have the right to
                              amend this CAF as this is necessary for the proper
                              provisioning and conduct of the services in public
                              interest or is mandated by any change in
                              applicable law or regulation or consequent to
                              change in the terms of the License Agreement
                              granted to Service Provider.
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>c</strong>{" "}
                            </span>
                            <span>
                              The Customer shall indemnify and keep indemnified
                              Service Provider against all loss, damages,
                              claims, actions that are initiated against Service
                              Provider for any act or omission by the
                              Customer/End User in utilizing the Services of
                              Service Provider under this CAF which is contrary
                              to the Regulatory Terms.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>d</strong>{" "}
                            </span>
                            <span>
                              (The CAF binds the Customer, his/her heirs,
                              executors, administrators, successors and
                              permitted assignees to the terms & conditions of
                              the CAF.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>e</strong>{" "}
                            </span>
                            <span>
                              Customer shall allow or obtain the required
                              permission to enable Service Provider employees or
                              authorized personnel, appointed distributors,
                              agents or subcontractors to enter at all times
                              into the premises where the Services are provided
                              for periodical inspection, installing,
                              maintaining, replacing and removing equipment
                              hardware and/or software prior to, during and
                              after the provision of the Services, as well as to
                              inspect the network and/or to the CPE or any other
                              equipment used in or in connection with the
                              Services. Also to check any possible misuse of
                              Services by the Customer. The Customer shall
                              render all assistance in this regard and shall
                              provide safe access and conditions for Service
                              Provider’s employees or appointed personnel whilst
                              in the premises.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>f</strong>{" "}
                            </span>
                            <span>
                              The Customer undertakes to comply with the
                              applicable laws and regulations regarding the use
                              of the Service, including without limitation, the
                              TRAI Act 1997, the Indian Telegraph Act 1885, the
                              Indian Wireless Telegraphy Act 1933, Information
                              Technology Act 2000 and all amendments,
                              replacements, rules and regulations made under
                              such legislations from time to time. The Customer
                              shall also be responsible to obtain any relevant
                              consents and approvals for the installation and
                              use of any equipment located at the Customer sites
                              and associated with the Service and any permits,
                              consents or approvals required by the Subscriber
                              to use the Service, including any applicable other
                              service provider permits (“Permits”). Customer
                              shall use the Services in accordance with the
                              Permits. Customer shall provide Service Provider
                              with an up-to-date copy of the Customer’s CUG
                              approval (if any) issued by the Department of
                              Telecommunications from time-to-time. Customer
                              shall ensure that any network equipment (not
                              provided by Services Provider) which it uses in
                              relation to the Services meet the relevant
                              International Telecommunication Union
                              (ITU)/Telecommunication Engineering Centre (TEC)
                              standards.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>g</strong>{" "}
                            </span>
                            <span>
                              The Customer shall ensure that objectionable,
                              obscene, unauthorized or any other content,
                              messages or communications infringing copyright,
                              Intellectual property right and international &
                              domestic cyber laws, in any form or inconsistent
                              with the laws of India, are not carried in his
                              network by him or any other person using his
                              network or during the provisioning of Services.
                              The Customer must take all necessary measures to
                              prevent such use. The use of the Service for
                              anti-national activities shall be construed as an
                              offence punishable under the Indian Penal Code or
                              other applicable law. The Customer must ensure
                              that the Internet Leased Line service provided by
                              Service Provider is not used for any unlawful
                              purposes or any other person using the Services as
                              End User.{" "}
                            </span>
                          </li>
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>h</strong>{" "}
                            </span>
                            <span>
                              Customer agrees and acknowledges that regulatory
                              and law enforcement agencies (Law Enforcement
                              Agency (LEA)), shall have rights to access the
                              network and intercept or listen or record calls
                              being made using the Services and facilities
                              provided by Service Provider and Customer shall
                              provide necessary facilities/access/consent to
                              Service Provider and Law Enforcement Agency (LEA),
                              including but not limited to TRAI, DOT, Police
                              authorities, Reserve Bank of India, Enforcement
                              Directorate and or any other Government Agencies
                              to monitor, control, prevent counteract espionage,
                              subversive act, sabotage or any other unlawful
                              activity{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div class="section2">
                <div class="font8">
                  <div class="over_hid">
                    <div class="fleft width48">
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>i</strong>{" "}
                            </span>
                            <span>
                              Customer understands Customer and its End Users
                              use the Services at their own risk and
                              consequences and Service Provider has no control
                              over and accepts no responsibility whatsoever for
                              such the Customer use of the Services and the
                              transacted business/transmitted communication and
                              Customer shall comply with all regulatory/legal
                              and statutory requirements during such use of the
                              Services.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="flright width48">
                      <div class="pad_l10">
                        <ul class="nolist">
                          <li>
                            <span class="pad_r15">
                              {" "}
                              <strong>j</strong>{" "}
                            </span>
                            <span>
                              Customer understands and agrees that Service
                              Provider shall terminate the Service provided to
                              the Customer immediately without any prior notice,
                              upon any violation of these terms and conditions
                              by the Customer and/or on account of any
                              regulatory violation by Customer or its End User’s
                              in using the Service or in case of any directive
                              of any Law Enforcement Agency (LEA), Department of
                              Telecommunication (“DOT”), Judicial authority or
                              any other Government agency.{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>
                    <strong class="redcolor">DECLARATION</strong>
                  </p>
                  <p>
                    I/We hereby declare that I/We have read, being explained in
                    regional language, verbatim understood and accepted the
                    terms and conditions mentioned in this CAF and thereupon. I
                    am/We are signing this CAF and subscribing to the Services
                    of Service Provider. I/We declare that I/We shall abide by
                    and be bound to the terms and conditions mentioned herein
                    above without any breach, violation, misuse or default. I/We
                    further declare that the information given by me/us in this
                    CAF are true and correct in every respect and any
                    mis-declaration will invite legal consequences. I/We further
                    declare that Service Provider will have the authority to
                    disconnect/terminate the Services if any information is
                    found to be incorrect.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>* Signature of the Customer:</strong>
                        </td>
                        <td>
                          {firstCaps != "" ? (
                            <div className={Signcss}>
                              {firstCaps + "  .  "}
                              <span className="under_line">{secondBold}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p class="auth">
                            <strong>Name of the Customer:</strong>
                          </p>
                        </td>
                        <td className="in_subput_date">
                          <input
                            class="form_col width70"
                            type="text"
                            name=""
                            value={this.state.acDataUpdated[16158].value}
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="over_hid">
                    <div class="fleft width30">
                      <p class="auth">
                        <strong>Place :</strong>
                        <input
                          class="form_col width70"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16159].value}
                          readOnly
                        />
                      </p>
                    </div>
                    <div class="fleft width30">
                      <p class="auth">
                        <strong>Date :</strong>
                        <input
                          class="form_col width70"
                          type="text"
                          name=""
                          value={this.state.acDataUpdated[16160].value}
                          readOnly
                        />
                      </p>
                    </div>
                  </div>
                  <div class="sealcom">
                    <div>
                      {this.state.imageData != null &&
                      this.state.imageData != "" ? (
                        <div style={{ padding: "0px" }} className="signate_img">
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <strong>(Seal of Customer/Company/Firm/Concern)</strong>
                  </div>
                  <div class="limitedoffice">
                    <p>
                      <strong class="redcolor">
                        Vodafone India Limited office use only
                      </strong>
                      <br />
                      Vodafone and (Vodafone Logo) are Registered Trade Mark of
                      Vodafone Group Plc. and are used under License. Vodafone
                      India Limited or its subsidiaries may not operate or
                      provide any all the Services under this Agreement and the
                      Services may be offered by Vodafone India under license
                      and managed services agreement with specialized vendors
                      and any warranties or representations of the third party
                      vendor are transferred to the Customer on as is basis and
                      no additional warranties or representation or service
                      level agreements are provided herein. The Customer and
                      Vodafone India and End User and/or person named in the CAF
                      as subscriber/ Customer is under contract with Vodafone
                      India Entity providing the Services.
                    </p>
                  </div>
                  <p>
                    <strong class="redcolor">
                      Channel Partner/Distributor's Declaration
                    </strong>
                    <br />
                    I/We declare that the details and photograph filled/attached
                    in this CAF match the details and photograph given in the
                    supporting documents of proof of identity and address. This
                    form has been personally signed by the Customer in my
                    presence.{" "}
                  </p>
                  <p>
                    I/We have physically (directly or indirectly through an
                    outsourced agency) verified the address of the Customer and
                    confirm that the address as mentioned in the CAF is found to
                    be correct. I/We confirm that the same identity has not been
                    used to generate multiple connections.
                  </p>
                  <p>If yes, then the details of existing connection are </p>
                  <div class="width40">
                    <p class="auth">
                      <strong>Name:</strong>
                      <input
                        class="form_col width75 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16163].value}
                        readOnly
                      />
                    </p>
                    <p class="auth">
                      <strong>Company:</strong>
                      <input
                        class="form_col width70 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16164].value}
                        readOnly
                      />
                    </p>
                    <p class="auth">
                      <strong>Office Address:</strong>
                      <input
                        class="form_col width70 flright"
                        type="text"
                        name=""
                        value={this.state.acDataUpdated[16165].value}
                        readOnly
                      />
                    </p>
                    <p class="auth">
                      <input
                        class="form_col width100"
                        type="text"
                        name=""
                        value=""
                      />
                    </p>
                  </div>
                  <p class="stamsin">
                    <strong>(Stamp and Signature)</strong>
                  </p>
                </div>
              </div>
            </page>
          </div>
        </div>
      );
    }
    return <div>{acCafContentView}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AC);
