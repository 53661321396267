import React,{Component} from "react";
import Dropzone from "react-dropzone";
import {connect} from "react-redux";
import * as actions from "../../../store/Actions/index";
import "./FormFields.css";
import * as utils from "../../../shared/Utils";
import S3Client from "aws-s3";
import {ProgressBar} from "react-bootstrap";
import Select from "react-select";
import Axiosweb from "../../../axios_ext";
import Axios from "axios";
import {GreneAutoComplete} from "../../../components/GreneAutoComplete/GreneAutoComplete";
import trash from "../../../assets/images/Icons/remove-icon.png";
import ProductCart from './DataTypeComponents/ProductCart'
import DocumentRepository from './DataTypeComponents/DocumentRepository'
import DropzoneFiles from './DataTypeComponents/DropzoneFiles';
import BuissnessCard from './DataTypeComponents/BuissnessCard';
import CreditDebitCard from './DataTypeComponents/CreditDebitCard';
import ActivityRefrence from './DataTypeComponents/ActivityRefrences';
import AssetRefrence from './DataTypeComponents/AssetRefrence';
import AWS from "aws-sdk";

import {
	getS3Instance,
	s3AccessKey,
	s3SecretKey,
	uploadFileToS3,
} from "../../../shared/awsS3.utils";
const fileTypeIcons = {
	image: require("../../../assets/images/Icons/image-icon.png"),
	pdf: require("../../../assets/images/Icons/pdf-icon.png"),
	sheet: require("../../../assets/images/Icons/excel-icon.png"),
	doc: require("../../../assets/images/Icons/doc-icon.png"),
	word: require("../../../assets/images/Icons/doc-icon.png"),
	mail: require("../../../assets/images/Icons/mail-icon.png"),
	ppt: require("../../../assets/images/Icons/ppt-icon.png"),
	video: require("../../../assets/images/Icons/attach-icon.png"),
	zip: require("../../../assets/images/Icons/attach-icon.png"),
	text: require("../../../assets/images/Icons/doc-icon.png"),
	attach: require("../../../assets/images/Icons/attach-icon.png"),
	zip: require("../../../assets/images/Icons/attach-icon.png"),
	text: require("../../../assets/images/Icons/doc-icon.png"),
};

const supportedFiles = [
	"video/mp4",
	"video/mpeg",
	"video/3gp",
	"video/avi",
	"video/msvideo",
	"video/x-msvideo",
	"image/png",
	"image/gif",
	"video/x-ms-wmv",
	"video/x-ms-asf",
	"image/jpeg",
	"image/jpg",
	"image/svg",
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"text/plain",
	"text/csv",
	"application/vnd.ms-excel",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-excel.sheet.binary.macroenabled.12",
	"application/vnd.ms-excel.sheet.binary.macroEnabled.12",
	"application/vnd.ms-excel.sheet.macroenabled.12",
	"application/vnd.ms-excel.sheet.macroEnabled.12",
	"message/*",
	"application/vnd.ms-powerpoint",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/zip",
	"application/octet-stream",
	"application/x-zip-compressed",
	"multipart/x-zip",
	"application/x-rar-compressed",
	"application/x-rar",
	"application/vnd.ms-outlook",
	"application/x-7z-compressed",
	"application/x-xz",
	"",
].join(", ");

class InputElement extends Component {
	constructor(props) {
		super(props)
		AWS.config.update({
			accessKeyId: s3AccessKey(),
			secretAccessKey: s3SecretKey(),
		});
		this.s3 = getS3Instance();
		this.state = {
			fieldVal: "",
			accepted: [],
			rejected: [],
			isProgress: 0,
			uploaded: false,
			searchString: "",
			selectedFile: null,
			timer: null,
			checkvalue: "",
			excelValidate: "",
			selectedFiles: [],
			annexureFieldArray: [
				"11182i",
				"11746i",
				"12415i",
				"12949i",
				"12963i",
				"12993i",
				"12891i",
				"10489i",
				"11748i",
				"11029i",
				"26440i",
			],
		};
		this.workflowSearchDebounce = utils.debounce(
			this.props.onFetchWorkflows,
			500
		);
		this.assetSearchDebounce = utils.debounce(this.props.onFetchAssets,500);
		this.roleBasedAssetFetchDebounce = utils.debounce(
			this.props.loadRoleBasedAssets,
			500
		);
	}

	componentDidMount() {
		//perform operation
		if(Number(this.props.elementType) === 57) {
			if(this.props.shouldValidate.rules) {
				this.props.onFetchWorkflows(
					this.buildWorkflowsRequestParams(""),
					"select",
					57
				);
			}
		}
		if(Number(this.props.elementType) === 67) {
			this.props.onFetchWorkflows(
				this.buildWorkflowsRequestParams("",""),
				"select",
				67
			);
		}
		if(Number(this.props.elementType) === 62) {
			if(this.props.shouldValidate.rules) {
				let activityTypeId = this.props.shouldValidate.rules.workflow_reference_restriction ?
					this.props.shouldValidate.rules.workflow_reference_restriction.activity_type_id : this.props.urlData.activity_type_id;
				console.log(this.props.elementType,activityTypeId);
				this.props.onFetchWorkflows(
					this.buildWorkflowRequestParams62(0,"",activityTypeId),
					"select",
					62
				);
			}
		}
		// if (Number(this.props.elementType) === 71) {
		// 	this.props.onFetchProductCategories(this.buildProductCategoriesParams());
		// }
	}
	// buildProductCategoriesParams = () => {
	// 	return {
	// 	  organization_id: this.props.urlData.organization_id,
	// 	  account_id: this.props.urlData.account_id,
	// 	  workforce_id: this.props.urlData.workforce_id,
	// 	  asset_id: this.props.urlData.asset_id,
	// 	  asset_token_auth: this.props.urlData.asset_token_auth,
	// 	  tag_type_category_id: 5,
	// 	  tag_type_id: 0,
	// 	  start_from: 0,
	// 	  limit_value: 50,
	// 	  device_os_id: 5,
	// 	};
	//   };
	handleOnChange = (event,field = null) => {
		let fieldValue = !!event.target ? event.target.value : event;
		let eventType = !!event.target ? event.target.type : "";
		this.setState({
			...this.state,
			fieldVal: event.target ? event.target.value : fieldValue,
			value: fieldValue,
		});
		// setTimeout(() => {
		this.props.changed(
			{
				target: {
					fieldValue,
					eventType,
				},
				persist: () => null,
			},
			field
		);
		// }, 1000);
	};
	handleFileUpload = (accepted,rejected) => {
		this.setState({
			...this.state,
			isEditing: true,
		});

		if(rejected.length > 0) {
			alert("Some Files format not supported,Please Upload valid format");
			return false;
		}
		if(accepted.length > 1) {
			alert("You can upload only one file");
			return false;
		} else if(accepted[0].name.split(".").length > 2) {
			alert("File not supported!");
			return false;
		} else {
			let elementType = null;
			let onFly = false;
			this.props.onFormFieldSetValiadtion(false);
			this.setState({
				accepted,
				rejected,
				isProgress: 10,
			});

			let bucketName = !!this.props.urlData.s3BucketName
				? this.props.urlData.s3BucketName
				: "";
			const {
				urlData: {organization_id,account_id,workforce_id,asset_id},
			} = this.props;

			let filename = this.state.accepted[0];
			let blob = filename.slice(0,filename.size,filename.type);
			let newFile = new File([blob],filename.name.replace(/ /gi,"-"),{
				type: blob.type,
			});

			const year = utils.getYear(new Date());
			const month = utils.getMonth(new Date());
			// const bucketName = `worlddesk-${Axios.defaults.bucketName}${year}-${month}`;
			let prefixPath = `${organization_id}/${account_id}/${workforce_id}/${asset_id}/${year}/${month}/103/${utils.getMessageUniqueId(
				asset_id
			)}/${newFile.name}`;

			const params = {
				Bucket: bucketName,
				Key: prefixPath,
				Body: newFile,
				ContentType: newFile.type,
				ACL: "private",
			};

			// uploadFileToS3WithInstance(this.s3, params)
			this.s3
				.putObject(params)
				.promise()
				.then((data) => {
					let location = `https://${bucketName}.s3.ap-south-1.amazonaws.com/${prefixPath}`;
					if(this.state.annexureFieldArray.includes(this.props.controlKey)) {
						this.validateExcel(location,this.props.elementTypeId,elementType);
					} else {
						if(this.props.elementType === 66) {
							this.props.changed(filename.name,"file_title");
							let docAddParams = this.buildDocumentAddParams(location);
							this.props.saveDocument(docAddParams);
						}
						this.setState({
							...this.state,
							isProgress: 100,
							fileName: filename.name,
						});
						this.props.onFormFieldSetValiadtion(true);
						if(this.props.onFlyEnabled) {
							onFly = true;
							this.props.inputChangedHandler(
								location,
								this.props.elementTypeId,
								elementType,
								onFly
							);
						} else {
							onFly = false;
							this.props.inputChangedHandler(
								location,
								this.props.elementTypeId,
								elementType,
								onFly
							);
						}
					}
				})
				.catch((err) => {
					console.error(err);
					this.props.onFormFieldSetValiadtion(false);
				});
		}
	};

	handleFileUploadv1 = async (file,type) => {
		const blob = file.slice(0,file.size,file.type);
		const newFile = new File([blob],file.name.replace(/ /gi,"-"),{
			type: blob.type,
		});
		const
			{urlData: {org_id,account_id,workforce_id,asset_id},
			} = this.props;

		const year = utils.getYear(new Date());
		const month = utils.getMonth(new Date());
		let bucketName = !!this.props.userData.s3BucketName
			? this.props.userData.s3BucketName
			: this.props.urlData
				? this.props.urlData.s3BucketName
				: "";
		let prefixPath = `${org_id}/${account_id}/${workforce_id}/${asset_id}/${year}/${month}/103/${utils.getMessageUniqueId(
			asset_id
		)}/${newFile.name}`;

		const params = {
			Bucket: bucketName,
			Key: prefixPath,
			Body: newFile,
			ContentType: newFile.type,
			ACL: "private",
		};

		try {
			//let res = await this.s3Instance.putObject(params).promise();
			// await uploadFileToS3WithInstance(this.s3, params);
			await uploadFileToS3(params);
			return [
				newFile.name,
				`https://${bucketName}.s3.ap-south-1.amazonaws.com/${prefixPath}`,
				newFile.type,
				1,
			];
		} catch(err) {
			console.log("Error: while upload!",err);
			return [newFile.name,"",newFile.type,0];
		}
	};

	handleDropboxFileUpload = async (accepted,rejected) => {
		this.setState({
			...this.state,
			isEditing: true,
		});

		if(rejected.length > 0) {
			alert("Some Files format not supported,Please Upload valid format");
			return false;
		} else {
			this.props.onFormFieldSetValiadtion(false);
			let dropboxFiles = [];
			let elementType = null;
			let onFly = false;
			this.setState({
				selectedFiles: [...this.state.selectedFiles,...accepted],
				rejected,
			});
			this.setState({
				isProgress: 10,
			});

			for(let file of this.state.selectedFiles) {
				const [name,url,status] = await this.handleFileUploadv1(
					file,
					this.getFileType(file)
				);
				if(status === 1) {
					dropboxFiles.push({
						file_name: name,
						file_url: url,
					});
				} else if(status === 0) {
					console.log("Unable to upload the image to S3");
				}
			}
			this.setState({
				...this.state,
				isProgress: 100,
			});
			this.props.onFormFieldSetValiadtion(true);
			console.log(dropboxFiles,"dropboxFiles");
			this.props.inputChangedHandler(
				JSON.stringify(dropboxFiles),
				this.props.elementTypeId,
				null,
				this.props.onFlyEnabled
			);
		}
	};

	async validateExcel(path) {
		let x = await this.buildParams(path);

		if(Number(x.data.status) === 200) {
			//alert(x.data.response);
			setTimeout(() => {
				if(!this.state.uploaded) {
					this.setState({
						isProgress: 50,
					});
				}
			},1000);
			setTimeout(() => {
				if(!this.state.uploaded) {
					this.setState({
						isProgress: 80,
					});
				}
			},2000);
			this.props.inputChangedHandler(path,this.props.elementTypeId);
			setTimeout(() => {
				if(!this.state.uploaded) {
					this.setState({
						isProgress: 100,
						uploaded: true,
					});
				}
			},3000);
			this.props.onFormFieldSetValiadtion(true);
		} else {
			// this.props.inputChangedHandler("", this.props.elementTypeId);
			alert(x.data.response);
			this.setState({
				isProgress: 0,
				uploaded: false,
				accepted: [],
			});
			this.props.onFormFieldSetValiadtion(false);
		}
		return x;
	}

	async buildParams(path) {
		let params = {
			organization_id: this.props.orgId,
			account_id: this.props.accId,
			workforce_id: 0,
			asset_id: this.props.entry
				? this.props.urlData.asset_id
				: this.props.userData.asset_id,
			asset_token_auth: this.props.entry
				? this.props.urlData.asset_token_auth
				: this.props.authToken,
			bucket_url: path,
		};
		try {
			let result = await Axiosweb.post("process/annexure",params);
			//console.log('result :: '+JSON.stringify(result));
			return result;
		} catch(err) {
			return err;
		}
	}

	inputWorkflowSelectChange = (e,elemenType) => {
		const srchStr = e;
		this.setState(
			{
				...this.state,
				searchString: srchStr,
			},
			() => {
				this.workflowSearchDebounce(
					this.buildWorkflowsRequestParams(srchStr),
					"select",
					elemenType
				);
			}
		);
	};
	inputAssetSelectChange = (e) => {
		const srchStr = e;
		this.setState(
			{
				...this.state,
				searchString: srchStr,
			},
			() => {
				if(
					!!this.props.shouldValidate.rules &&
					Object.keys(this.props.shouldValidate.rules).length > 0 &&
					!!this.props.shouldValidate.rules.asset_reference_restriction &&
					!!this.props.shouldValidate.rules.asset_reference_restriction
						.asset_type_id &&
					this.props.shouldValidate.rules.asset_reference_restriction
						.asset_ref_flag_filter !== 3
				) {
					this.roleBasedAssetFetchDebounce(
						this.buildRoleBaseAssetParams(srchStr)
					);
				} else {
					this.assetSearchDebounce(
						this.buildAssetRequestParamsV1(srchStr),
						"select"
					);
				}
			}
		);
	};
	inputSelectChange = (e) => {
		if(
			Number(this.props.elementType) === 57 ||
			Number(this.props.elementType) === 62
		) {
			const srchStr = e;
			clearTimeout(this.state.timer);
			this.setState({
				...this.state,
				searchString: srchStr,
				timer: setTimeout(() => {
					console.log("inside the select");
					this.props.onFetchWorkflows(
						this.buildWorkflowsRequestParams(srchStr),
						"select",
						57
					);
					//this.props.onSearchOrgContacts(this.buildUserSearchParams(srchStr, 0));
				},1000),
			});
			//  this.props.onSearchOrgContacts(this.buildUserSearchParams(e));
		} else if(Number(this.props.elementType) === 58) {
			const srchStr = e;
			clearTimeout(this.state.timer);
			this.setState({
				...this.state,
				searchString: srchStr,
				timer: setTimeout(() => {
					console.log("inside the select");
					this.props.onDocumentFiles(
						this.buildDocumentRequestParams(0,srchStr),
						"select"
					);
					//this.props.onSearchOrgContacts(this.buildUserSearchParams(srchStr, 0));
				},1000),
			});
			//  this.props.onSearchOrgContacts(this.buildUserSearchParams(e));
		} else if(Number(this.props.elementType) === 59) {
			const srchStr = e;
			clearTimeout(this.state.timer);
			this.setState({
				...this.state,
				searchString: srchStr,
				timer: setTimeout(() => {
					console.log("inside the select");
					this.props.onFetchAssets(
						this.buildAssetRequestParams(srchStr),
						"select"
					);
					//this.props.onSearchOrgContacts(this.buildUserSearchParams(srchStr, 0));
				},1000),
			});
			//  this.props.onSearchOrgContacts(this.buildUserSearchParams(e));
		} else if(Number(this.props.elementType) === 62) {
			const srchStr = e;
			clearTimeout(this.state.timer);
			this.setState({
				...this.state,
				searchString: srchStr,
				timer: setTimeout(() => {
					console.log("inside the select");
					if(this.props.shouldValidate.rules) {
						let activityTypeId = this.props.shouldValidate.rules
							.workflow_reference_restriction.activity_type_id;
						console.log(this.props.elementType,activityTypeId);
						this.props.onFetchWorkflows(
							this.buildWorkflowRequestParams62(0,"",activityTypeId),
							"select",
							62
						);
					}
					//this.props.onSearchOrgContacts(this.buildUserSearchParams(srchStr, 0));
				},1000),
			});
			//  this.props.onSearchOrgContacts(this.buildUserSearchParams(e));
		} else {
			const srchStr = e;
			clearTimeout(this.state.timer);

			this.setState({
				...this.state,
				searchString: srchStr,
				timer: setTimeout(() => {
					console.log("inside the select");
					this.props.onFetchFiles(
						this.buildFilesRequestParams(0,0,-3,0,0,1,srchStr),
						"select"
					);
					//this.props.onSearchOrgContacts(this.buildUserSearchParams(srchStr, 0));
				},1000),
			});
			//  this.props.onSearchOrgContacts(this.buildUserSearchParams(e));
		}
	};
	handleFileChange = (selectOption) => {
		this.setState({
			...this.state,
			selectedFile: selectOption,
		});
		this.props.inputChangedHandler(
			selectOption.value,
			this.props.elementTypeId
		);
		//this.props.changed(selectOption.value);
	};
	handleFileChangeSelect = (selectOption) => {
		this.setState({
			...this.state,
			selectedFile: selectOption,
		});
		this.props.inputChangedHandler(selectOption,this.props.elementTypeId);
		//this.props.changed(selectOption.value);
	};
	handleWorkflowChange = (selectedOption) => {
		this.setState({
			...this.state,
			selectedWorkflow: selectedOption,
		});
		this.props.inputChangedHandler(
			selectedOption,
			this.props.elementTypeId,
			null,
			!!this.props.onFlyEnabled
		);
	};

	handleAssetChange = (selectedOption) => {
		this.setState({
			...this.state,
			selectedAsset: selectedOption,
		});
		this.props.inputChangedHandler(
			selectedOption,
			this.props.elementTypeId,
			null,
			!!this.props.onFlyEnabled
		);
	};

	handleCheckChange = (selectOption) => {
		let finalCheckValue;
		if(selectOption.target.checked) {
			finalCheckValue = this.state.checkvalue + "|" + selectOption.target.value;
			this.setState({
				...this.state,
				checkvalue: this.state.checkvalue + "|" + selectOption.target.value,
			});
		} else {
			finalCheckValue = this.state.checkvalue.replace(
				selectOption.target.value + "|",
				""
			);
			this.setState({
				...this.state,
				checkvalue: finalCheckValue,
			});
		}
		this.props.inputCheckChangedHandler(
			finalCheckValue,
			this.props.elementTypeId
		);
	};
	buildFilesRequestParams = (
		pageStart,
		sortFlag,
		activityTypeCategoryId,
		activitySubTypeId,
		isUnread,
		isStatus,
		srchStr
	) => {
		let txtToSrch = "";
		if(srchStr && srchStr !== "") {
			txtToSrch = this.state.searchString;
		}
		return {
			account_id: this.props.entry
				? this.props.urlData.account_id
				: this.props.accId,
			activity_sub_type_id: activitySubTypeId,
			activity_type_category_id: activityTypeCategoryId,
			app_version: 1.0,
			asset_id: this.props.entry
				? this.props.urlData.asset_id
				: this.props.userData.asset_id,
			asset_token_auth: this.props.entry
				? this.props.urlData.asset_token_auth
				: this.props.authToken,
			auth_asset_id: this.props.entry
				? this.props.urlData.auth_asset_id
				: this.props.userData.asset_id,
			device_os_id: 5,
			end_datetime: "2050-01-01 00:00:00",
			flag: sortFlag,
			organization_id: this.props.entry
				? this.props.urlData.organization_id
				: this.props.orgId,
			page_limit: 50,
			page_start: pageStart,
			search_string: txtToSrch,
			service_version: 0.1,
			start_datetime: "1970-01-01 00:00:00",
			workforce_id: this.props.entry
				? this.props.urlData.workforce_id
				: this.props.workforceId,
			is_unread: isUnread,
			is_status: isStatus,
			is_due_date: 0,
			is_sort: 3,
			is_search: txtToSrch !== "" ? 1 : 0,
		};
	};

	buildWorkflowRequestParams62 = (pageStart,srchStr,activity_id = 0) => {
		let txtToSrch = "";
		if(srchStr && srchStr !== "") {
			txtToSrch = this.state.searchString;
		}
		return {
			account_id: this.props.entry
				? this.props.urlData.account_id
				: this.props.accId,
			asset_id: this.props.entry
				? this.props.urlData.asset_id
				: this.props.userData.asset_id,
			asset_token_auth: this.props.entry
				? this.props.urlData.asset_token_auth
				: this.props.authToken,
			//auth_asset_id: this.props.entry ? this.props.urlData.auth_asset_id : this.props.userData.asset_id,
			organization_id: this.props.entry
				? this.props.urlData.organization_id
				: this.props.orgId,
			page_limit: 50,
			page_start: pageStart,
			search_string: txtToSrch,
			workforce_id: this.props.entry
				? this.props.urlData.workforce_id
				: this.props.workforceId,
			activity_type_id: activity_id,
			flag_status: 0,
			flag_participating: 0,
		};
	};
	buildWorkflowsRequestParams = (srchStr) => {
		let txtToSrch = "";
		if(srchStr && srchStr !== "") {
			txtToSrch = this.state.searchString;
		}

		let activityTypeId = 0;
		let tagId = 0;
		let tagTypeId = 0;
		let isParticipant = 0;
		let activityStatusTypeId = 0;
		let flagDependent = 0;
		let dependentFieldId = 0;
		let dependentFieldServiceName = "";
		if(
			!!this.props.shouldValidate.rules &&
			Object.keys(this.props.shouldValidate.rules).length > 0 &&
			this.props.shouldValidate.rules.workflow_reference_restriction
		) {
			const {
				activity_type_id,
				tag_id,
				tag_type_id,
				flag_participating,
				activity_status_type_id,
				flag_dependent,
				dependent_field_id,
				dependent_field_service_param_name,
			} = this.props.shouldValidate.rules.workflow_reference_restriction;
			activityTypeId = activity_type_id || 0;
			tagId = tag_id || 0;
			tagTypeId = tag_type_id || 0;
			isParticipant = flag_participating || 0;
			activityStatusTypeId = activity_status_type_id || 0;
			flagDependent = flag_dependent || 0;
			dependentFieldId = dependent_field_id || 0;
			dependentFieldServiceName = dependent_field_service_param_name || "";
		}
		let params;
		if(flagDependent === 0) {
			params = {
				organization_id: this.props.entry
					? this.props.urlData.organization_id
					: this.props.orgId,
				account_id: this.props.entry
					? this.props.urlData.account_id
					: this.props.accId,
				workforce_id: 0,
				search_string: txtToSrch,
				service_version: 0.1,
				app_version: 1.0,
				flag_status: 0,
				flag_participating: isParticipant,
				asset_id: this.props.entry
					? this.props.urlData.asset_id
					: this.props.userData.asset_id,
				asset_token_auth: this.props.entry
					? this.props.urlData.asset_token_auth
					: this.props.authToken,
				auth_asset_id: this.props.entry
					? this.props.urlData.auth_asset_id
					: this.props.userData.asset_id,
				device_os_id: 5,
				page_limit: 50,
				page_start: 0,
				activity_type_category_id: 48,
				activity_type_id: activityTypeId,
				tag_id: tagId,
				tag_type_id: tagTypeId,
				activity_status_type_id: activityStatusTypeId,
			};
		} else {
			params = {
				organization_id: this.props.entry
					? this.props.urlData.organization_id
					: this.props.orgId,
				account_id: this.props.entry
					? this.props.urlData.account_id
					: this.props.accId,
				workforce_id: 0,
				search_string: txtToSrch,
				service_version: 0.1,
				app_version: 1.0,
				flag_status: 0,
				flag_participating: isParticipant,
				asset_id: this.props.entry
					? this.props.urlData.asset_id
					: this.props.userData.asset_id,
				asset_token_auth: this.props.entry
					? this.props.urlData.asset_token_auth
					: this.props.authToken,
				auth_asset_id: this.props.entry
					? this.props.urlData.auth_asset_id
					: this.props.userData.asset_id,
				device_os_id: 5,
				page_limit: 50,
				page_start: 0,
				activity_type_category_id: 48,
				activity_type_id: activityTypeId,
				tag_id: tagId,
				tag_type_id: tagTypeId,
				activity_status_type_id: activityStatusTypeId,
				flag_dependent: flagDependent,
			};
			if(
				!!this.props.fieldList &&
				!!this.props.fieldList[`${dependentFieldId}i`]
			) {
				const data = this.props.fieldList[`${dependentFieldId}i`]
					.fileUploadData;
				params[`${dependentFieldServiceName}`] = data.value;
			}
		}
		params = {
			...params,
			isElasticSearchEnabled: this.props.urlData
				.organization_flag_elasticsearch_enabled,
		};
		return params;
	};
	buildDocumentRequestParams = (pageStart,srchStr) => {
		let txtToSrch = "";
		if(srchStr && srchStr !== "") {
			txtToSrch = this.state.searchString;
		}
		return {
			account_id: this.props.entry
				? this.props.urlData.account_id
				: this.props.accId,
			asset_id: this.props.entry
				? this.props.urlData.asset_id
				: this.props.userData.asset_id,
			asset_token_auth: this.props.entry
				? this.props.urlData.asset_token_auth
				: this.props.authToken,
			auth_asset_id: this.props.entry
				? this.props.urlData.auth_asset_id
				: this.props.userData.asset_id,
			organization_id: this.props.entry
				? this.props.urlData.organization_id
				: this.props.orgId,
			page_limit: 50,
			page_start: pageStart,
			search_string: txtToSrch,
			workforce_id: this.props.entry
				? this.props.urlData.workforce_id
				: this.props.workforceId,
			activity_type_id: 0,
			activity_id: "",
			attachment_type_id: 0,
			flag: 0,
		};
	};
	buildAssetRequestParamsV1 = (str) => {
		let txtToSrch = "";
		let asset_type_category_id = 0;
		let flag = 0;
		let asset_type_id = 0;
		let asset_form_id = 0;
		if(str && str !== "") {
			txtToSrch = str;
		}
		if(
			!!this.props.shouldValidate.rules &&
			Object.keys(this.props.shouldValidate.rules).length > 0 &&
			!!this.props.shouldValidate.rules.asset_reference_restriction
		) {
			asset_type_category_id = this.props.shouldValidate.rules
				.asset_reference_restriction.asset_type_category_id;
			flag =
				this.props.shouldValidate.rules.asset_reference_restriction
					.asset_ref_flag_filter || 0;
			asset_form_id = !!this.props.shouldValidate.rules
				.asset_reference_restriction.form_id
				? this.props.shouldValidate.rules.asset_reference_restriction.form_id
				: 0;
			asset_type_id = this.props.shouldValidate.rules
				.asset_reference_restriction.asset_type_id;
		}

		let params = {
			organization_id: this.props.entry
				? this.props.urlData.organization_id
				: this.props.orgId,
			account_id: this.props.entry
				? this.props.urlData.account_id
				: this.props.accId,
			workforce_id: 0,
			asset_id: this.props.entry
				? this.props.urlData.asset_id
				: this.props.userData.asset_id,
			asset_token_auth: this.props.entry
				? this.props.urlData.asset_token_auth
				: this.props.authToken,
			auth_asset_id: this.props.entry
				? this.props.urlData.auth_asset_id
				: this.props.userData.asset_id,
			device_os_id: 5,
			flag_filter: flag, // 1 / 2,
			activity_id: this.props.workflowInfo
				? this.props.workflowInfo.activity_id
				: 0,
			search_string: txtToSrch,
			asset_type_category_id,
			asset_type_id,
		};

		if(Number(flag) === 4) {
			params["form_id"] = asset_form_id;
		}
		return params;
	};

	buildAssetRequestParams = (srchStr) => {
		let txtToSrch = "";
		if(srchStr && srchStr !== "") {
			txtToSrch = this.state.searchString;
		}
		return {
			organization_id: this.props.entry
				? this.props.urlData.organization_id
				: this.props.orgId,
			account_id: this.props.entry
				? this.props.urlData.account_id
				: this.props.accId,
			workforce_id: 0,
			search_string: txtToSrch,
			service_version: 0.1,
			app_version: 1.0,
			flag_status: 0,
			flag_participating: 0,
			asset_id: this.props.entry
				? this.props.urlData.asset_id
				: this.props.userData.asset_id,
			asset_token_auth: this.props.entry
				? this.props.urlData.asset_token_auth
				: this.props.authToken,
			auth_asset_id: this.props.entry
				? this.props.urlData.auth_asset_id
				: this.props.userData.asset_id,
			device_os_id: 5,
			page_limit: 50,
			page_start: 0,
			asset_type_category_id: this.props.shouldValidate.rules
				.asset_reference_restriction.asset_type_category_id,
		};
	};
	manageDateSpin = (e) => {
		if(e.key === "ArrowUp" || e.key === "ArrowDown") {
			e.preventDefault();
		}
	};
	manageDateKeyboardSpin = (e) => {
		e.preventDefault();
	};

	getFileType = (file) => {
		const fileType = file.type ? file.type.toLowerCase() : "";
		if(
			!!["gif","jpg","jpeg","png","ico"].find((item) =>
				fileType.includes(item)
			)
		) {
			return "image";
		} else if(!!["pdf"].find((item) => fileType.includes(item))) {
			return "pdf";
		} else if(
			!![
				"xlsx",
				"xlsm",
				"xlsb",
				"xltx",
				"xltm",
				"xls",
				"csv",
				"xlc",
				"sheet",
				"application/vnd.ms-excel",
			].find((item) => fileType.includes(item))
		) {
			return "sheet";
		} else if(
			!!["word","msword","doc","txt","docx"].find((item) =>
				fileType.includes(item)
			)
		) {
			return "doc";
		} else if(
			!!["eml","mail","msg","message","oft","ost"].find((item) =>
				fileType.includes(item)
			) ||
			!fileType
		) {
			return "mail";
		} else if(
			!![
				"mspowerpoint",
				"powerpoint",
				"mspowerpoint",
				"presentation",
			].find((item) => fileType.includes(item))
		) {
			return "ppt";
		} else if(
			!!["mp4","mpg","mkv","avi","mov","wmv"].find((item) =>
				fileType.includes(item)
			)
		) {
			return "video";
		} else if(
			!!["zip","x-7z","x-xz"].find((item) => fileType.includes(item))
		) {
			return "zip";
		} else if(!!["plain"].find((item) => fileType.includes(item))) {
			return "text";
		}
		console.log("fileType",fileType);
		return "attach";
	};

	handleRemoveAttachment = (index) => {
		this.setState(
			{
				selectedFiles: this.state.selectedFiles.filter((x,i) => i !== index),
				isProgress: 20,
			},
			async () => {
				let dropboxFiles = [];
				this.props.onFormFieldSetValiadtion(false);
				for(let file of this.state.selectedFiles) {
					const [name,url,status] = await this.handleFileUploadv1(
						file,
						this.getFileType(file)
					);
					if(status === 1) {
						dropboxFiles.push({
							file_name: name,
							file_url: url,
						});
					} else if(status === 0) {
						console.log("Unable to upload the image to S3");
					}
				}
				this.setState({
					...this.state,
					isProgress: 100,
				});
				this.props.onFormFieldSetValiadtion(true);
				console.log(dropboxFiles,"dropboxFiles");
				this.props.inputChangedHandler(
					JSON.stringify(dropboxFiles),
					this.props.elementTypeId,
					null,
					this.props.onFlyEnabled
				);
			}
		);
	};
	// buildProductWorkflowTypeParams = (data) => {
	// 	return {
	// 		organization_id: this.props.urlData.organization_id,
	// 		account_id: this.props.urlData.account_id,
	// 		workforce_id: this.props.urlData.workforce_id,
	// 		asset_id: this.props.urlData.asset_id,
	// 		asset_token_auth: this.props.urlData.asset_token_auth,
	// 		tag_id: data.value,
	// 		start_from: 0,
	// 		limit_value: 50,
	// 		device_os_id: 5,
	// 	};
	//   };
	// productCategoryChange = (selectOption) => {
	// 	this.setState({
	// 	  ...this.state,
	// 	  selectedProductCategory: selectOption,
	// 	  selectedProductWorkflowType: null,
	// 	  selectedProductWorkflow: null,
	// 	  selectedProductVariants: [],
	// 	});
	// 	// this.props.changed(selectOption, "product_category");
	// 	// this.props.changed([], "product_variant");
	// 	this.props.onFetchProductWorkflowTypes(
	// 	  this.buildProductWorkflowTypeParams(selectOption)
	// 	);
	//   };
	//   buildProductWorkflowParams = (data) => {
	// 	return {
	// 		organization_id: this.props.urlData.organization_id,
	// 		account_id: this.props.urlData.account_id,
	// 		workforce_id: this.props.urlData.workforce_id,
	// 		asset_id: this.props.urlData.asset_id,
	// 		asset_token_auth: this.props.urlData.asset_token_auth,
	// 		activity_type_category_id: data.details.activity_type_category_id,
	// 		activity_type_id: data.value,
	// 		flag: 0,
	// 		tag_id: data.details.tag_id,
	// 		tag_type_id: data.details.tag_type_id,
	// 		search_string: "",
	// 		flag_status: 0,
	// 		flag_participating: 2,
	// 		start_from: 0,
	// 		limit_value: 50,
	// 		device_os_id: 5,
	// 	};
	//   };
	//   handleWorkflowTypeChange = (selectedOption) => {
	// 	this.setState({
	// 	  ...this.state,
	// 	  selectedProductWorkflowType: selectedOption,
	// 	  selectedProductWorkflow: null,
	// 	  selectedProductVariants: [],
	// 	});
	// 	// this.handlechanged(selectedOption, "product_workflow_type");
	// 	// this.handlechanged([], "product_variant");
	// 	this.props.onFetchProductWorkflows(
	// 	  this.buildProductWorkflowParams(selectedOption)
	// 	);
	//   };
	//   handlechanged = (event, field = null) => {
	// 	// event.persist();
	// 	if (this.props.previewMode && !this.state.editEnabled) {
	// 	  return;
	// 	}
	// 	let fieldValue = !!event.target ? event.target.value : event;
	// 	let eventType = !!event.target ? event.target.type : "";
	// 	this.setState({
	// 	  value: fieldValue,
	// 	});

	// 	// if (!this.props.previewMode) {
	// 	//   this.handleCommitChanges(fieldValue, field, eventType);
	// 	// }
	//   };

	//   handleCommitChanges = (value, field = null, type = "") => {
	// 	this.props.changed(
	// 	  {
	// 		target: {
	// 		  value,
	// 		  type,
	// 		},
	// 		persist: () => null,
	// 	  },
	// 	  field
	// 	);
	//   };
	buildProductVariantsParams = (data) => {
		return {
			organization_id: this.props.urlData.organization_id,
			account_id: this.props.urlData.account_id,
			workforce_id: this.props.urlData.workforce_id,
			asset_id: this.props.urlData.asset_id,
			asset_token_auth: this.props.urlData.asset_token_auth,
			parent_activity_id: data.value,
			flag: 1,
			sort_flag: 1,
			datetime_start: utils.getCurrentDateUTC(
				data.activity_datetime_start_expected
			),
			datetime_end: utils.getAddToCurrentDateUTC(
				data.activity_datetime_end_deferred
			),
			start_from: 0,
			limit_value: 50,
			device_os_id: 5,
		};
	};

	handleProductWorkflowChange = (selectedOption) => {
		this.setState({
			...this.state,
			selectedProductWorkflow: selectedOption,
			selectedProductVariants: [],
		});
		// this.props.changed(selectedOption, "product_workflow");
		// this.props.changed([], "product_variant");
		this.props.onFetchProductVariants(
			this.buildProductVariantsParams(selectedOption)
		);
	};
	handleProductVariantChange = (selectedOptions) => {
		let total = 0;
		selectedOptions.forEach((variant) => {
			let quantity = variant.quantity ? variant.quantity : 0;
			if(!!variant.productPrice) {
				total += quantity * Number(variant.details.activity_cuid_3);
			}
		});
		this.setState({
			...this.state,
			selectedProductVariants: selectedOptions,
			cartValue: total,
		});
		this.props.changed(selectedOptions,"product_variant");
		this.props.changed(total,"cart_value");
	};
	handleVariantQuantity = (e,option) => {
		let newArray = [...this.state.selectedProductVariants];
		let total = 0;

		let productPrice = e.target.value * Number(option.details.activity_cuid_3);
		let productIndex = this.state.selectedProductVariants.findIndex(
			(variant) => Number(variant.value) == Number(option.value)
		);
		newArray[productIndex] = {
			...newArray[productIndex],
			productPrice: productPrice,
			quantity: e.target.value,
		};

		newArray.forEach((variant) => {
			if(!!variant.quantity) {
				total +=
					Number(variant.quantity) * Number(variant.details.activity_cuid_3);
			}
		});
		this.setState({
			selectedProductVariants: newArray,
			cartValue: total,
		});
		this.props.changed(newArray,"product_variant");
		this.props.changed(total,"cart_value");
	};

	handleVariantPrice = (e,option) => {
		let newArray = [...this.state.selectedProductVariants];
		let total = 0;

		let productPrice = e.target.value * Number(option.quantity);
		let productIndex = this.state.selectedProductVariants.findIndex(
			(variant) => Number(variant.value) == Number(option.value)
		);
		newArray[productIndex].details.activity_cuid_3 = e.target.value;
		newArray[productIndex] = {
			...newArray[productIndex],
			productPrice: productPrice,
			quantity: option.quantity,
		};

		newArray.forEach((variant) => {
			if(!!variant.quantity && !!variant.productPrice) {
				total +=
					Number(variant.quantity) * Number(variant.details.activity_cuid_3);
			}
		});
		this.setState({
			selectedProductVariants: newArray,
			cartValue: total,
		});
		this.props.changed(newArray,"product_variant");
		this.props.changed(total,"cart_value");
	};
	render() {
		let inputElement = null;
		let disabled;
		if(!!this.props.urlData) {
			disabled = this.props.urlData.disabledProperty;
		} else {
			disabled = false;
		}
		let userIp = "";
		switch(Number(this.props.elementType)) {
			case 1: // Date
				inputElement = (
					<input
						className="textbox"
						type="date"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
						onKeyDown={this.manageDateSpin}
					/>
				);
				break;
			case 2: // Future Date
				inputElement = (
					<input
						className="textbox no-spin"
						type="date"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
						onKeyDown={this.manageDateKeyboardSpin}
						min={utils.getAddToCurrentDate(
							utils.getCurrentDateISTWidgetDash(),
							1
						)}
					/>
				);
				break;
			case 3: // Past Date
				inputElement = (
					<input
						className="textbox no-spin"
						type="date"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
						onKeyDown={this.manageDateKeyboardSpin}
						//readonly="readonly"
						max={utils.getAddToCurrentDate(
							utils.getCurrentDateISTWidgetDash(),
							0
						)}
					/>
				);
				break;
			case 4: // Date and Time
				inputElement = (
					<input
						className="textbox no-spin"
						type="datetime-local"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onKeyDown={this.manageDateSpin}
						onChange={this.handleOnChange}
					/>
				);
				break;
			case 5: // Number
				inputElement = (
					<input
						className="textbox"
						type="number"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
					/>
				);
				break;
			case 6: // Decimal
				inputElement = (
					<input
						className="textbox"
						type="number"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
					/>
				);
				break;
			case 19: // Short text
				inputElement = (
					<input
						disabled={disabled}
						className="textbox"
						type="text"
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}//{this.props.changed}
					/>
				);
				break;
			case 17: // Location
				inputElement = (
					<input
						className="textbox"
						type="text"
						disabled={disabled}
						{...this.props.elementConfig}
						value={
							this.props.userCoords !== null
								? this.props.userCoords.lat + ", " + this.props.userCoords.long
								: "NA"
						}
						onChange={this.props.changed}
						disabled={true}
					/>
				);
				break;
			case 20: // Long text
				inputElement = (
					<textarea
						className="textarea"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
					></textarea>
				);
				break;
			case 21: // Label
				inputElement = this.props.label;
				break;
			case 22: // Email
				inputElement = (
					<input
						className="textbox"
						type="email"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
					/>
				);
				break;
			case 23: // Phone number
				inputElement = (
					<input
						className="textbox"
						type="tel"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
					/>
				);
				break;

			case 24: // Gallery Image
				inputElement = (
					<DropzoneFiles
						changed={this.props.changed}
						value={this.props.value}
						handleFileUpload={this.handleFileUpload}
						isProgress={this.state.isProgress}
						accepted={this.state.accepted}
						fileName={this.state.fileName}
					/>
					// <div>
					// 	<Dropzone
					// 		disabled={disabled}
					// 		className="upload_dropzone_fields"
					// 		accept="image/jpeg, image/png"
					// 		onDrop={
					// 			disabled
					// 				? null
					// 				: (accepted, rejected) =>
					// 						this.handleFileUpload(accepted, rejected)
					// 		}
					// 	>
					// 		<div className="uploaded_files" style={{ width: "100%" }}>
					// 			{this.state.accepted.map((filedata) => {
					// 				return (
					// 					<div key={filedata.name} className="thumbnail_dropzone">
					// 						<img
					// 							src={
					// 								filedata.type === "application/pdf"
					// 									? require("../../../assets/images/Pdf_thumb.png")
					// 									: filedata.preview
					// 							}
					// 							alt={filedata.name}
					// 						/>
					// 					</div>
					// 				);
					// 			})}
					// 		</div>

					// 		{this.state.accepted.length === 0 && !disabled ? (
					// 			<p className="upload_dropzone_title">
					// 				Try dropping some files here, or click to select files to
					// 				upload.
					// 			</p>
					// 		) : null}
					// 	</Dropzone>
					// 	{this.state.isProgress > 0 ? (
					// 		<ProgressBar
					// 			style={{ width: "100%" }}
					// 			striped
					// 			bsStyle="success"
					// 			now={this.state.isProgress}
					// 		/>
					// 	) : null}
					// </div>
				);
				break;
			case 25:
				inputElement = (
					<DropzoneFiles
						changed={this.props.changed}
						value={this.props.value}
						handleFileUpload={this.handleFileUpload}
						isProgress={this.state.isProgress}
						accepted={this.state.accepted}
						fileName={this.state.fileName}
					/>)
				break;
			case 27: // General Picnature
				inputElement = (
					<DropzoneFiles
						changed={this.props.changed}
						value={this.props.value}
						handleFileUpload={this.handleFileUpload}
						isProgress={this.state.isProgress}
						accepted={this.state.accepted}
						fileName={this.state.fileName}
					/>
					// <div>
					// 	<Dropzone
					// 		disabled={disabled}
					// 		className="upload_dropzone_fields"
					// 		accept="image/jpeg, image/png"
					// 		onDrop={
					// 			disabled
					// 				? null
					// 				: (accepted, rejected) =>
					// 						this.handleFileUpload(accepted, rejected)
					// 		}
					// 	>
					// 		<div className="uploaded_files" style={{ width: "100%" }}>
					// 			{this.state.accepted.map((filedata) => {
					// 				return (
					// 					<div key={filedata.name} className="thumbnail_dropzone">
					// 						<img
					// 							src={
					// 								filedata.type === "application/pdf"
					// 									? require("../../../assets/images/Pdf_thumb.png")
					// 									: filedata.preview
					// 							}
					// 							alt={filedata.name}
					// 						/>
					// 					</div>
					// 				);
					// 			})}
					// 		</div>

					// 		{this.state.accepted.length === 0 && !disabled ? (
					// 			<p className="upload_dropzone_title">
					// 				Try dropping some files here, or click to select files to
					// 				upload.
					// 			</p>
					// 		) : null}
					// 	</Dropzone>
					// 	{this.state.isProgress > 0 ? (
					// 		<ProgressBar
					// 			style={{ width: "100%" }}
					// 			striped
					// 			bsStyle="success"
					// 			now={this.state.isProgress}
					// 		/>
					// 	) : null}
					// </div>
				);
				break;
			case 28: // General Picnature
				inputElement = (
					<div>
						<Dropzone
							disabled={disabled}
							className="upload_dropzone_fields"
							accept="image/jpeg, image/png"
							onDrop={
								disabled
									? null
									: (accepted,rejected) =>
										this.handleFileUpload(accepted,rejected)
							}
						>
							<div className="uploaded_files" style={{width: "100%"}}>
								{this.state.accepted.map((filedata) => {
									return (
										<div key={filedata.name} className="thumbnail_dropzone">
											<img
												src={
													filedata.type === "application/pdf"
														? require("../../../assets/images/Pdf_thumb.png")
														: filedata.preview
												}
												alt={filedata.name}
											/>
										</div>
									);
								})}
							</div>

							{this.state.accepted.length === 0 && !disabled ? (
								<p className="upload_dropzone_title">
									Try dropping some files here, or click to select files to
									upload.
								</p>
							) : null}
						</Dropzone>
						{this.state.isProgress > 0 ? (
							<ProgressBar
								style={{width: "100%"}}
								striped
								bsStyle="success"
								now={this.state.isProgress}
							/>
						) : null}
					</div>
				);
				break;
			case 33: // Single Selection
				inputElement = (
					<select
						disabled={disabled}
						className="textbox"
						value={this.props.value}
						onChange={this.props.changed}
					>
						{this.props.elementConfig.options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.name}
							</option>
						))}
					</select>
				);
				break;
			case 34: // Multi Selection
				inputElement = (
					<div>
						{this.props.elementConfig.options.map((option,index) => (
							<div key={index}>
								<input
									className="checkboxfield"
									type="checkbox"
									disabled={disabled}
									onChange={this.handleCheckChange}
									value={option.value}
								/>
								<span className="checkboxspan">{option.name}</span>
							</div>
						))}
					</div>
				);
				break;
			case 50: //File reference
				inputElement = (
					<Select
						isSearchable={true}
						onInputChange={this.inputSelectChange}
						disabled={disabled}
						className="employee_srch"
						onChange={this.handleFileChange}
						options={this.props.FileList}
						value={this.state.selectedFile}
						name="lead_search"
						placeholder="Search by File name"
					/>
				);
				/* inputElement = <input
					className="textbox"
					type="text"
					{...this.props.elementConfig}
					value={this.props.value}
					onChange={this.props.changed} />; */
				break;
			case 51: //Pdf scan
				inputElement = (
					<div>
						<Dropzone
							className="upload_dropzone_fields"
							disabled={disabled}
							accept="application/pdf"
							onDrop={
								disabled
									? null
									: (accepted,rejected) =>
										this.handleFileUpload(accepted,rejected)
							}
						>
							<div className="uploaded_files" style={{width: "100%"}}>
								{this.state.accepted.map((filedata) => {
									return (
										<div key={filedata.name} className="thumbnail_dropzone">
											<img
												src={
													filedata.type === "application/pdf"
														? require("../../../assets/images/Pdf_thumb.png")
														: filedata.preview
												}
												alt={filedata.name}
											/>
										</div>
									);
								})}
							</div>

							{this.state.accepted.length === 0 && !disabled ? (
								<p className="upload_dropzone_title">
									Try dropping some files here, or click to select files to
									upload.
								</p>
							) : null}
						</Dropzone>
						{this.state.isProgress > 0 ? (
							<ProgressBar
								style={{width: "100%"}}
								striped
								bsStyle="success"
								now={this.state.isProgress}
							/>
						) : null}
					</div>
				);
				break;

			case 52: // Excel upload
				inputElement = (
					<div>
						<Dropzone
							className="upload_dropzone_fields"
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							disabled={disabled}
							onDrop={
								disabled
									? null
									: (accepted,rejected) =>
										this.handleFileUpload(accepted,rejected)
							}
						>
							<div className="uploaded_files" style={{width: "100%"}}>
								{this.state.accepted.map((filedata) => {
									return (
										<div key={filedata.name} className="thumbnail_dropzone">
											<img
												src={
													filedata.type ===
														"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
														? require("../../../assets/images/Excel_Thumbnail.png")
														: filedata.preview
												}
												alt={filedata.name}
											/>
										</div>
									);
								})}
							</div>

							{this.state.accepted.length === 0 && !disabled ? (
								<p className="upload_dropzone_title">
									Try dropping some files here, or click to select files to
									upload.
								</p>
							) : null}
						</Dropzone>
						{this.state.isProgress > 0 ? (
							<ProgressBar
								style={{width: "100%"}}
								striped
								bsStyle="success"
								now={this.state.isProgress}
							/>
						) : null}
					</div>
				);
				break;
			case 53: // Ip Address
				inputElement = (
					<input
						className="textbox"
						type="text"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.userIp === null ? userIp : this.props.userIp}
						onChange={this.props.changed}
						disabled={true}
					/>
				);
				break;
			case 55: // Word upload
				inputElement = (
					<div>
						<Dropzone
							disabled={disabled}
							className="upload_dropzone_fields"
							accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
							onDrop={
								disabled
									? null
									: (accepted,rejected) =>
										this.handleFileUpload(accepted,rejected)
							}
						>
							<div className="uploaded_files" style={{width: "100%"}}>
								{this.state.accepted.map((filedata) => {
									return (
										<div key={filedata.name} className="thumbnail_dropzone">
											<img
												src={
													filedata.type ===
														"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
														filedata.type === "application/msword"
														? require("../../../assets/images/word_thumbnail.png")
														: filedata.preview
												}
												alt={filedata.name}
											/>
										</div>
									);
								})}
							</div>

							{this.state.accepted.length === 0 && !disabled ? (
								<p className="upload_dropzone_title">
									Try dropping some files here, or click to select files to
									upload.
								</p>
							) : null}
						</Dropzone>
						{this.state.isProgress > 0 ? (
							<ProgressBar
								style={{width: "100%"}}
								striped
								bsStyle="success"
								now={this.state.isProgress}
							/>
						) : null}
					</div>
				);
				break;
			case 56: // outlook email upload
				inputElement = (
					<div>
						<Dropzone
							className="upload_dropzone_fields"
							onDrop={
								disabled
									? null
									: (accepted,rejected) =>
										this.handleFileUpload(accepted,rejected)
							}
						>
							<div className="uploaded_files" style={{width: "100%"}}>
								{this.state.accepted.map((filedata) => {
									return (
										<div key={filedata.name} className="thumbnail_dropzone">
											<img
												src={require("../../../assets/images/outlook_thumbnail.png")}
												alt={filedata.name}
											/>
										</div>
									);
								})}
							</div>

							{this.state.accepted.length === 0 && !disabled ? (
								<p className="upload_dropzone_title">
									Try dropping some files here, or click to select files to
									upload.
								</p>
							) : null}
						</Dropzone>
						{this.state.isProgress > 0 ? (
							<ProgressBar
								style={{width: "100%"}}
								striped
								bsStyle="success"
								now={this.state.isProgress}
							/>
						) : null}
					</div>
				);
				break;



			case 57: //Activity reference
			case 68: //Multi Activity reference
				{
					let activity_type_id = 0;
					let isMulti = !!(parseInt(this.props.elementType) == 68);
					if(
						!!this.props.shouldValidate &&
						!!this.props.shouldValidate.rules &&
						this.props.shouldValidate.rules.workflow_reference_restriction
					) {
						const {
							workflow_reference_restriction,
						} = this.props.shouldValidate.rules;
						activity_type_id = workflow_reference_restriction.activity_type_id;
					}
					let workflowOptions = [];
					if(
						this.props.workflowListRef.activity_type_id === activity_type_id
					) {
						workflowOptions = this.props.workflowListRef.workflowListOptions;
					}
					let value_edit_enabled = !!this.props.field_value_edit_enabled
						? this.props.field_value_edit_enabled
						: 0;
					let value_prefill_enabled = !!this.props.field_value_prefill_enabled
						? this.props.field_value_prefill_enabled
						: 0;
					let isDisableSelect =
						value_edit_enabled === 1 && value_prefill_enabled === 1
							? true
							: this.props.elementConfig.disabled ||
							this.props.disabled ||
							false;
					inputElement = (
						<GreneAutoComplete
							config={{
								name: "workflow_search",
								helperText: "Search by workflow name",
								isSearchable: true,
								isMulti: isMulti,
								isDisabled:
									parseInt(this.props.elementType) === 57 &&
										value_edit_enabled === 1
										? isDisableSelect
										: this.props.disabled,
								className: "reference_search",
								isCuidVisible: true,
							}}
							value={this.props.fileUploadData || this.state.selectedWorkflow}
							options={workflowOptions}
							onSelectionChange={this.handleWorkflowChange}
							onInputChange={(e) =>
								this.inputWorkflowSelectChange(
									e,
									Number(this.props.elementType)
								)
							}
						/>
					);
				}
				break;
			case 58: //File reference
				inputElement = (
					<Select
						isSearchable={true}
						onInputChange={this.inputSelectChange}
						disabled={disabled}
						className="employee_srch"
						onChange={this.handleFileChange}
						options={this.props.DocumentList}
						value={this.state.selectedFile}
						name="lead_search"
						placeholder="Search by Document name"
					/>
				);
				break;

			case 59: //Asset reference
			case 69: //multi Asset reference
				{
					let asset_type_category_id = 0;
					let asset_type_id = 0;
					let isMulti = !!(parseInt(this.props.elementType) == 69);
					if(
						!!this.props.shouldValidate &&
						!!this.props.shouldValidate.rules &&
						this.props.shouldValidate.rules.asset_reference_restriction
					) {
						const {
							asset_reference_restriction,
						} = this.props.shouldValidate.rules;
						asset_type_category_id =
							asset_reference_restriction.asset_type_category_id;
						asset_type_id = asset_reference_restriction.asset_type_id;
					}
					let assetOptions = [];
					if(
						!!asset_type_id &&
						this.props.roleBasedAssetListRef.asset_type_category_id ==
						asset_type_category_id
					) {
						assetOptions = this.props.roleBasedAssetListRef.roleBasedAssetList;
					} else if(
						this.props.assetListRef.asset_type_category_id ===
						asset_type_category_id
					) {
						assetOptions = this.props.assetListRef.assetListOptions;
					}
					inputElement = (
						<GreneAutoComplete
							config={{
								name: "asset_search",
								helperText: "Search by Resource name",
								isSearchable: true,
								isMulti: isMulti,
								isDisabled: this.props.disabled,
								className: "reference_search",
								isAssetDetails: true,
							}}
							value={this.state.selectedAsset}
							options={assetOptions}
							onInputChange={this.inputAssetSelectChange}
							onSelectionChange={this.handleAssetChange}
						/>
					);
				}
				break;
				// case 59: //Asset reference
				// case 69: //multi Asset reference
				// 	inputElement = <AssetRefrence
				// 		shouldValidate={this.props.shouldValidate}
				// 		elementType={Number(this.props.elementType)}
				// 		changed={this.handleOnChange}
				// 		values={this.props.value}
				// 		urlData={this.props.urlData}
				// 	/>
				// {
				// 	const asset_type_category_id = this.props.shouldValidate.rules
				// 		.asset_reference_restriction.asset_type_category_id;
				// 	let assetOptions = [];
				// 	if (
				// 		this.props.assetListRef.asset_type_category_id ===
				// 		asset_type_category_id
				// 	) {
				// 		assetOptions = this.props.assetListRef.assetListOptions;
				// 	}
				// 	inputElement = (
				// 		<GreneAutoComplete
				// 			config={{
				// 				name: "asset_search",
				// 				helperText: "Search by Resource name",
				// 				isSearchable: true,
				// 				isMulti: true,
				// 				disabled: disabled,
				// 				className: "reference_search",
				// 			}}
				// 			value={this.state.selectedAsset}
				// 			options={assetOptions}
				// 			onInputChange={this.inputAssetSelectChange}
				// 			onSelectionChange={this.handleAssetChange}
				// 		/>
				// 	);
				// }
				break;
			case 62: // Credit Debit Card
				{
					let {
						transaction_type_id,
						activity_type_id,
						transaction_amount,
						transaction_note,
					} = this.props.value;
					inputElement = (<CreditDebitCard
						changed={this.handleOnChange}
						shouldValidate={this.props.shouldValidate}
						values={this.props.value}
						urlData={this.props.urlData}
					/>
					);
				}
				break; //Add Bot definitions For Combo Fields
			case 64:
				let editedFieldVal = this.props.value;
				inputElement = (
					<div className="field_edit_content">
						<input
							className="textbox"
							placeholder="office unit number"
							value={editedFieldVal.office_unit_number}
							onChange={(e) => this.props.changed(e,"office_unit_number")}
						/>
						<input
							className="textbox"
							placeholder="floor number"
							value={editedFieldVal.floor_number}
							onChange={(e) => this.props.changed(e,"floor_number")}
						/>
						<input
							className="textbox"
							placeholder="building name"
							value={editedFieldVal.building_name}
							onChange={(e) => this.props.changed(e,"building_name")}
						/>
						<input
							className="textbox"
							placeholder="street number"
							value={editedFieldVal.street_number}
							onChange={(e) => this.props.changed(e,"street_number")}
						/>
						<input
							className="textbox"
							placeholder="street name"
							value={editedFieldVal.street_name}
							onChange={(e) => this.props.changed(e,"street_name")}
						/>
						<input
							className="textbox"
							placeholder="area name"
							value={editedFieldVal.area_name}
							onChange={(e) => this.props.changed(e,"area_name")}
						/>
						<input
							className="textbox"
							placeholder="city"
							value={editedFieldVal.city}
							onChange={(e) => this.props.changed(e,"city")}
						/>
						<input
							className="textbox"
							placeholder="pincode"
							value={editedFieldVal.pincode}
							onChange={(e) => this.props.changed(e,"pincode")}
						/>
						<input
							className="textbox"
							placeholder="state"
							value={editedFieldVal.state}
							onChange={(e) => this.props.changed(e,"state")}
						/>
					</div>
				);

				break;
			case 65:
				let {
					customer_name_first,
					customer_name_last,
					customer_phone_number,
					customer_email,
					customer_cuid,
					customer_gender,
					customer_industry,
					customer_location,
				} = this.props.value;
				inputElement = (<BuissnessCard
					changed={this.props.changed} />
				);
				break;
			case 66:
				inputElement = <DocumentRepository urlData={this.props.urlData}
					changed={this.props.changed}
					value={this.props.value}
					handleFileUpload={this.handleFileUpload}
					isProgress={this.state.isProgress}
					accepted={this.state.accepted}
					fileName={this.state.fileName}
				/>
				break;
			case 67:
				let {
					message,
					alert_timestamp,
					prereminder_alert_duration_hours,
				} = this.props.value;

				inputElement = (
					<div className="field_edit_content">
						<input
							className="textbox margin-5"
							value={message}
							onChange={(e) => {
								this.props.changed(e,"message");
							}}
							type="text"
							placeholder={"message"}
						/>
						<input
							className="textbox margin-5"
							value={alert_timestamp}
							onChange={(e) => {
								this.props.changed(e,"alert_timestamp");
							}}
							type="datetime-local"
							placeholder={"alert timestamp"}
						/>
						<input
							className="textbox margin-5"
							value={prereminder_alert_duration_hours}
							onChange={(e) => {
								this.props.changed(e,"prereminder_alert_duration_hours");
							}}
							type="datetime-local"
							placeholder={"prereminder alert duration hours"}
						/>
						<Select
							isMulti={true}
							onChange={(e) => this.handleFileChangeSelect(e)}
							// value={workflow_references}
							options={this.props.workflowListRef.workflowListOptions}
							placeholder={"workflow References"}
						/>
					</div>
				);
				break;
			case "select":
				inputElement = (
					<select
						className="textbox"
						value={this.props.value}
						onChange={this.props.changed}
						disabled={disabled}
					>
						{this.props.elementConfig.options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.displayValue}
							</option>
						))}
					</select>
				);
				break;
			case 71:
				inputElement = (<ProductCart urlData={this.props.urlData}
					// changed={this.props.changed}
					changed={this.handleOnChange}
				/>)
				break;
			case 76: //DropBox upload
				inputElement = (
					<div>
						<Dropzone
							disabled={this.props.disabled}
							className="upload_dropzone_fields"
							accept={supportedFiles}
							onDrop={(accepted,rejected) =>
								// this.handleFileUpload(accepted, rejected)
								this.handleDropboxFileUpload(accepted,rejected)
							}
						>
							<p className="upload_dropzone_title">
								Try dropping some files here, or click to select files to
								upload.
							</p>
						</Dropzone>
						{this.state.isProgress > 0 ? (
							<ProgressBar
								style={{width: "100%"}}
								striped
								bsStyle="success"
								now={this.state.isProgress}
							/>
						) : null}
						{this.state.selectedFiles.map((file,index) => (
							<div className="dragfiles" key={String(index)}>
								<div className="file-details">
									<img
										src={fileTypeIcons[this.getFileType(file)]}
										alt=""
										className=""
									/>
									<p style={{color: "#000000"}}>{file.name}</p>
								</div>
								<div className="minus_icon">
									<img
										src={trash}
										alt=""
										className=""
										onClick={() => this.handleRemoveAttachment(index)}
									/>
								</div>
							</div>
						))}
					</div>
				);
				break;
			default:
				inputElement = (
					<input
						className="textbox"
						disabled={disabled}
						{...this.props.elementConfig}
						value={this.props.value}
						onChange={this.handleOnChange}
					/>
				);
		}

		return (
			<tr>
				{Number(this.props.elementType) === 21 ? (
					<td colSpan="2">
						<div className="labelField"> {inputElement}</div>
					</td>
				) : (
					[
						<td key="0" className="tdleft">
							{this.props.label}
							{this.props.shouldValidate.required ? (
								<span style={{padding: "5px",color: "#ed1d23"}}>*</span>
							) : null}
						</td>,
						<td key="1" className="tdright">
							{inputElement}
						</td>
					]
				)}
			</tr>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		userIp: state.loginAuth.userIp,
		orgId: state.loginAuth.orgId,
		accId: state.loginAuth.accId,
		authToken: state.loginAuth.authToken,
		userData: state.loginAuth.userData,
		FileList: state.files.fileListRef,
		workflowListRef: state.files.workflowListRef,
		DocumentList: state.files.documentListRef,
		assetListRef: state.files.assetListRef,
		userCoords: state.loginAuth.userCoords,
		workforceId: state.loginAuth.workforceId,
		workflowListCredit: state.files.workflowListCredit,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		inputCheckChangedHandler: (event,formElementId,eleType) =>
			dispatch(actions.formFieldCheckChange(event,formElementId,eleType)),
		inputChangedHandler: (event,formElementId,eleType) =>
			dispatch(actions.formFileFieldInputChange(event,formElementId,eleType)),
		onFormFieldSetValiadtion: (validate) =>
			dispatch(actions.formFieldSetValiadtion(validate)),
		onFetchFiles: (requestData,reference) =>
			dispatch(actions.filesFetch(requestData,reference)),
		onFetchWorkflows: (requestData,reference,target) =>
			dispatch(actions.workflowsFetch(requestData,reference,target)),
		onDocumentFiles: (requestData,reference) =>
			dispatch(actions.documentsFetch(requestData,reference)),
		onFetchAssets: (requestData,reference) =>
			dispatch(actions.assetsFetch(requestData,reference)),
	};
};
export default connect(mapStateToProps,mapDispatchToProps)(InputElement);
