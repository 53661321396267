export const LOGIN_AUTH_START = "LOGIN_AUTH_START";
export const LOGIN_AUTH_FAIL = "LOGIN_AUTH_FAIL";
export const ENABLE_DND_FOR_USER = "ENABLE_DND_FOR_USER";
export const CHANGE_USER_AWAY_STATUS = "CHANGE_USER_AWAY_STATUS";
export const LOGIN_AUTH_SUCCESS = "LOGIN_AUTH_SUCCESS";
export const LOGIN_AUTH_LOGOUT = "LOGIN_AUTH_LOGOUT";
export const SEARCH_ORG_CONTACTS_SUCCESS = "SEARCH_ORG_CONTACTS_SUCCESS";
export const SEARCH_ORG_CONTACTS_START = "SEARCH_ORG_CONTACTS_START";
export const SEARCH_ORG_EMPLOYEE_SUCCESS = "SEARCH_ORG_EMPLOYEE_SUCCESS";
export const SEARCH_ORG_EMPLOYEE_START = "SEARCH_ORG_EMPLOYEE_START";
export const LOGIN_USER_DESK_COUNT_FETCH_START = "LOGIN_USER_DESK_COUNT_FETCH_START";
export const LOGIN_USER_DESK_COUNT_FETCH_SUCCESS = "LOGIN_USER_DESK_COUNT_FETCH_SUCCESS";
export const LOGIN_ACCOUNT_FETCH_START = "LOGIN_ACCOUNT_FETCH_START";
export const LOGIN_ACCOUNT_FETCH_SUCCESS = "LOGIN_ACCOUNT_FETCH_SUCCESS";
export const SET_LOGOUT_TIMER = "SET_LOGOUT_TIMER";
export const TOGGLE_MY_DESK_VIEW = "TOGGLE_MY_DESK_VIEW";
export const SEND_PASSCODE_EMAIL = "SEND_PASSCODE_EMAIL";
export const ORG_SIGNUP_START = "ORG_SIGNUP_START";
export const ORG_SIGNUP_END = "ORG_SIGNUP_END";
export const ACCOUNT_CREATION_START = "ACCOUNT_CREATION_START";
export const ACCOUNT_CREATION_END = "ACCOUNT_CREATION_END";
export const S3_FETCH_START = "S3_FETCH_START";
export const S3_FETCH_END = "S3_FETCH_END";

// cognito functions

export const VERIFY_COGNITO_CHALLENGE_FAILED = 'VERIFY_COGNITO_CHALLENGE_FAILED';
export const LOAD_COGNITO_SIGN_IN_TOKEN_FAILED = 'LOAD_COGNITO_SIGN_IN_TOKEN_FAILED';
export const LOAD_COGNITO_SIGN_IN_TOKEN_SUCCESS = 'LOAD_COGNITO_SIGN_IN_TOKEN_SUCCESS';


export const FLOOR_LIST_FETCH = "FLOOR_LIST_FETCH";
export const FLOOR_LIST_FETCH_SUCCESS = "FLOOR_LIST_FETCH_SUCCESS";
export const FLOOR_LIST_FETCH_FAIL = "FLOOR_LIST_FETCH_FAIL";
export const BUILDING_RELOAD = "BUILDING_RELOAD";
export const SET_WORKFORCE_TYPE_ID = "SET_WORKFORCE_TYPE_ID";

export const EMPLOYEE_DESK_FETCH = "EMPLOYEE_DESK_FETCH";
export const EMPLOYEE_DESK_FETCH_SUCCESS = "EMPLOYEE_DESK_FETCH_SUCCESS";
export const EMPLOYEE_DESK_FETCH_FAIL = "EMPLOYEE_DESK_FETCH_FAIL";
export const EMPLOYEE_DESK_FETCH_START = "EMPLOYEE_DESK_FETCH_START";
export const EMPLOYEE_RESET_DESKS = "EMPLOYEE_RESET_DESKS";
export const EMPLOYEE_DESK_STATE_CHANGE = "EMPLOYEE_DESK_STATE_CHANGE";
export const EMPLOYEE_DESK_HERENOW_STATE_CHANGE = "EMPLOYEE_DESK_HERENOW_STATE_CHANGE";
export const FETCH_ORG_CONTACTS_SUCCESS = "FETCH_ORG_CONTACTS_SUCCESS";

export const EMPLOYEE_DESK_UNREAD_COUNT_FETCH = "EMPLOYEE_DESK_UNREAD_COUNT_FETCH";
export const EMPLOYEE_DESK_PENDING_COUNT_FETCH = "EMPLOYEE_DESK_PENDING_COUNT_FETCH";
export const EMPLOYEE_DESK_UNREAD_COUNT_FETCH_SUCCESS = "EMPLOYEE_DESK_UNREAD_COUNT_FETCH_SUCCESS";
export const EMPLOYEE_DESK_PENDING_COUNT_FETCH_SUCCESS = "EMPLOYEE_DESK_PENDING_COUNT_FETCH_SUCCESS";

export const EMPLOYEE_SINGLE_DESK_COUNT_FETCH_START = "EMPLOYEE_SINGLE_DESK_COUNT_FETCH_START";
export const EMPLOYEE_SINGLE_DESK_COUNT_FETCH_SUCCESS = "EMPLOYEE_SINGLE_DESK_COUNT_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_TIMELINE_FETCH_START = "EMPLOYEE_ASSET_TIMELINE_FETCH_START";
export const EMPLOYEE_ASSET_TIMELINE_FETCH_SUCCESS = "EMPLOYEE_ASSET_TIMELINE_FETCH_SUCCESS";
// process and forms actions
export const EMPLOYEE_ASSET_REPORTING_DESK_FETCH_START = "EMPLOYEE_ASSET_REPORTING_DESK_FETCH_START";
export const EMPLOYEE_ASSET_REPORTING_DESK_FETCH_SUCCESS = "EMPLOYEE_ASSET_REPORTING_DESK_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_PROCCESS_LIST_FETCH_START = "EMPLOYEE_ASSET_PROCCESS_LIST_FETCH_START";
export const EMPLOYEE_ASSET_PROCCESS_LIST_FETCH_SUCCESS = "EMPLOYEE_ASSET_PROCCESS_LIST_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_PROCCESS_FORM_LIST_FETCH_START = "EMPLOYEE_ASSET_PROCCESS_FORM_LIST_FETCH_START";
export const EMPLOYEE_ASSET_PROCCESS_FORM_LIST_FETCH_SUCCESS = "EMPLOYEE_ASSET_PROCCESS_FORM_LIST_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_PERFORMANCE_FETCH_START = "EMPLOYEE_ASSET_PERFORMANCE_FETCH_START";
export const EMPLOYEE_ASSET_PERFORMANCE_FETCH_SUCCESS = "EMPLOYEE_ASSET_PERFORMANCE_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_PERFORMANCE_WORKEXPERIENCE_FETCH_SUCCESS = "EMPLOYEE_ASSET_PERFORMANCE_WORKEXPERIENCE_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_EFFICIENCY_FETCH_START = "EMPLOYEE_ASSET_EFFICIENCY_FETCH_START";
export const EMPLOYEE_ASSET_EFFICIENCY_FETCH_SUCCESS = "EMPLOYEE_ASSET_EFFICIENCY_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_EFFICIENCY_WEEKLY_FETCH_START = "EMPLOYEE_ASSET_EFFICIENCY_WEEKLY_FETCH_START";
export const EMPLOYEE_ASSET_EFFICIENCY_WEEKLY_FETCH_SUCCESS = "EMPLOYEE_ASSET_EFFICIENCY_WEEKLY_FETCH_SUCCESS";
export const EMPLOYEE_ASSET_ROLLBACK_FETCH_START = "EMPLOYEE_ASSET_ROLLBACK_FETCH_START";
export const EMPLOYEE_ASSET_ROLLBACK_FETCH_SUCCESS = "EMPLOYEE_ASSET_ROLLBACK_FETCH_SUCCESS";

// SET ASSET ID AS SELECTED DESK TO MARK THE BACKGROUND WHITE
export const SET_SELECTED_DESK_ASSET_ID = "SET_SELECTED_DESK_ASSET_ID";

// LOAD ASSETS FROM COMMON POOL
export const LOAD_DESK_FROM_COMMON_POOL_START = "LOAD_DESK_FROM_COMMON_POOL_START";
export const LOAD_DESK_FROM_COMMON_POOL_SUCCESS = "LOAD_DESK_FROM_COMMON_POOL_SUCCESS";

export const EMPLOYEE_DESK_DESK_FETCH_START = "EMPLOYEE_DESK_DESK_FETCH_START";
export const EMPLOYEE_DESK_DESK_FETCH_SUCCESS = "EMPLOYEE_DESK_DESK_FETCH_SUCCESS";
// ACTION TYPE FOR FORM FIELD HISTORY LOAD FUNCTIONS

export const QUEUE_FORM_FIELD_LOAD_HISTORY_START = "QUEUE_FORM_FIELD_LOAD_HISTORY_START";
export const QUEUE_FORM_FIELD_LOAD_HISTORY_SUCCESS = "QUEUE_FORM_FIELD_LOAD_HISTORY_SUCCESS";
export const QUEUE_FORM_FIELD_LOAD_HISTORY_FAILED = "QUEUE_FORM_FIELD_LOAD_HISTORY_FAILED";

export const DELETE_SELECT_OPTION_START = "DELETE_SELECT_OPTION_START";
export const DELETE_SELECT_OPTION_SUCCESS = "DELETE_SELECT_OPTION_SUCCESS";
export const DELETE_SELECT_OPTION_FAIL = "DELETE_SELECT_OPTION_FAIL";


//Files ,Tasks , ID_CARD and Projects 
export const TASKS_FETCH_START = "TASKS_FETCH_START";
export const TASKS_FETCH_SUCCESS = "TASKS_FETCH_SUCCESS";
export const TASKS_FETCH_FAIL = "TASKS_FETCH_FAIL";
export const TASKS_FETCH = "TASKS_FETCH";
export const TASK_RESET_COVER_DATA = "TASK_RESET_COVER_DATA";
export const TASK_RESET_COUNT_DATA = "TASK_RESET_COUNT_DATA";
export const FILES_FETCH_APPEND = "FILES_FETCH_APPEND";

export const FILES_FETCH_START = "FILES_FETCH_START";
export const FILES_FETCH_SUCCESS = "FILES_FETCH_SUCCESS";
export const FILES_FETCH_FAIL = "FILES_FETCH_FAIL";
export const ID_CARD_FETCH_START = "ID_CARD_FETCH_START";
export const ID_CARD_FETCH_SUCCESS = "ID_CARD_FETCH_SUCCESS";
export const ID_CARD_FETCH_SCORE_START = "ID_CARD_FETCH_SCORE_START";
export const ID_CARD_FETCH_SCORE_SUCCESS = "ID_CARD_FETCH_SCORE_SUCCESS";
export const FILE_TIMELINE_FETCH_SUCCESS = "FILE_TIMELINE_FETCH_SUCCESS";
export const FILE_TIMELINE_FETCH_START = "FILE_TIMELINE_FETCH_START";
export const FILE_TIMELINE_APPEND = "FILE_TIMELINE_APPEND";
export const FILE_PARTICIPANTS_FETCH_START = "FILE_PARTICIPANTS_FETCH_START";
export const FILE_PARTICIPANTS_FETCH_SUCCESS = "FILE_PARTICIPANTS_FETCH_SUCCESS";
export const FILE_CONTENT_PAGINATION = "FILE_CONTENT_PAGINATION";
export const FILE_SELECTED_DATA = "FILE_SELECTED_DATA";
export const FILE_RESET_UNREAD_COUNT = "FILE_RESET_UNREAD_COUNT";
export const FILE_RESET_COVER_DATA = "FILE_RESET_COVER_DATA";
export const FILE_EDIT_COVER_START = "FILE_EDIT_COVER_START";
export const FILE_EDIT_COVER_END = "FILE_EDIT_COVER_END";
export const FILE_SET_LOCAL_PARTICIPANTS = "FILE_SET_LOCAL_PARTICIPANTS";
export const FILES_CLEAR_STORE = "FILES_CLEAR_STORE";
export const FILES_LIST_FOR_REFERENCE = "FILES_LIST_FOR_REFERENCE";
export const FILE_PARTICIPANT_RESET_START = "FILE_PARTICIPANT_RESET_START";
export const FILE_PARTICIPANT_RESET_SUCCESS = "FILE_PARTICIPANT_RESET_SUCCESS";


export const PROJECTS_FETCH_START = "PROJECTS_FETCH_START";
export const PROJECTS_FETCH_SUCCESS = "PROJECTS_FETCH_SUCCESS";
export const PROJECT_FETCH_PAST_DUE_COUNT = "PROJECT_FETCH_PAST_DUE_COUNT";
export const PROJECT_SEARCH_FETCH_SUCCESS = "PROJECT_SEARCH_FETCH_SUCCESS";
export const PROJECT_FETCH_APPEND = "PROJECT_FETCH_APPEND";


//Fetch workforce defaults
export const ACTIVITY_TYPES_FETCH_START = "ACTIVITY_TYPES_FETCH_START";
export const ACTIVITY_TYPES_FETCH_SUCCESS = "ACTIVITY_TYPES_FETCH_SUCCESS";
export const ACTIVITY_STATUSES_FETCH_START = "ACTIVITY_STATUSES_FETCH_START";
export const ACTIVITY_STATUSES_FETCH_SUCCESS = "ACTIVITY_STATUSES_FETCH_SUCCESS";
export const ASSET_TYPES_FETCH_WORKFORCE = "ASSET_TYPES_FETCH_WORKFORCE"

//Fetch Postits Data
export const POSTIT_FETCH_START = "POSTIT_FETCH_START";
export const POSTIT_FETCH_SUCCESS = "POSTIT_FETCH_SUCCESS";
export const POSTIT_FETCH_NEXT = "POSTIT_FETCH_NEXT";
export const POSTIT_FETCH_PREV = "POSTIT_FETCH_PREV";
export const POSTIT_RESET_CURR_MESSAGE = "POSTIT_RESET_CURR_MESSAGE";
export const POSTIT_RESET_STATE = "POSTIT_RESET_STATE";

//MYDESK UNREADS
export const SET_POSTIT_UNREAD_COUNT = "SET_POSTIT_UNREAD_COUNT";
export const SET_VIDEO_UNREAD_COUNT = "SET_VIDEO_UNREAD_COUNT";

//Video call props set
export const SET_VIDEO_CALL_PROPS = "SET_VIDEO_CALL_PROPS";
export const CURRENT_VIDEO_CALL_ACTIVITY = "CURRENT_VIDEO_CALL_ACTIVITY";


//Chat Handlers
export const CHAT_FETCH_CHAT_LIST_START = "CHAT_FETCH_CHAT_LIST_START";
export const CHAT_FETCH_CHAT_LIST_SUCCESS = "CHAT_FETCH_CHAT_LIST_SUCCESS";
export const CHAT_FETCH_TIMELINE_START = "CHAT_FETCH_TIMELINE_START";
export const CHAT_FETCH_TIMELINE_SUCCESS = "CHAT_FETCH_TIMELINE_SUCCESS";
export const CHAT_EXISTS_CHECK_START = "CHAT_EXISTS_CHECK_START";
export const CHAT_EXISTS_CHECK_SUCCESS = "CHAT_EXISTS_CHECK_SUCCESS";
export const CHAT_TIMELINE_FETCH_START = "CHAT_TIMELINE_FETCH_START";
export const CHAT_TIMELINE_FETCH_SUCCESS = "CHAT_TIMELINE_FETCH_SUCCESS";
export const CHAT_TIMELINE_PREPEND = "CHAT_TIMELINE_PREPEND";
export const CHAT_TIMELINE_APPEND = "CHAT_TIMELINE_APPEND";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const CHAT_PUSH_TO_TIMELINE = "CHAT_PUSH_TO_TIMELINE";
export const CHAT_MESSAGE_SUBMIT_START = "CHAT_MESSAGE_SUBMIT_START";
export const CHAT_MESSAGE_SUBMIT_SUCCESS = "CHAT_MESSAGE_SUBMIT_SUCCESS";
export const CHAT_UNREAD_COUNT_RESET = "CHAT_UNREAD_COUNT_RESET";


export const SEARCH_EMPLOYEE_DESK_START = "SEARCH_EMPLOYEE_DESK_START";
export const SEARCH_EMPLOYEE_DESK_SUCCESS = "CSEARCH_EMPLOYEE_DESK_SUCCESS";
export const SEARCH_EMPLOYEE_DESK_FAILED = "SEARCH_EMPLOYEE_DESK_FAILED";

//Analytics code
export const ANALYTICS_FETCH_STATUS_SINGLE_COUNT_SUCCESS = "ANALYTICS_FETCH_STATUS_SINGLE_COUNT_SUCCESS";
export const ANALYTICS_FETCH_STATUS_SINGLE_VALUE_SUCCESS = "ANALYTICS_FETCH_STATUS_SINGLE_VALUE_SUCCESS";
export const ANALYTICS_FETCH_STATUS_RANGE_VALUE_SUCCESS = "ANALYTICS_FETCH_STATUS_RANGE_VALUE_SUCCESS";
export const ANALYTICS_FETCH_STATUS_RANGE_COUNT_SUCCESS = "ANALYTICS_FETCH_STATUS_RANGE_COUNT_SUCCESS";
export const ANALYTICS_FETCH_TAT_RANGE_SUCCESS = "ANALYTICS_FETCH_TAT_RANGE_SUCCESS";


export const SHOW_DOCUMENT_REPOSITORY = "SHOW_DOCUMENT_REPOSITORY";
export const CLOSE_DOCUMENT_REPOSITORY = "CLOSE_DOCUMENT_REPOSITORY";


///----- GreneLoader
export const SHOW_GRENELOADER = "SHOW_GRENELOADER";
export const CLOSE_GRENELOADER = "CLOSE_GRENELOADER";

// Forms
export const FORMS_LIST_FETCH_START = "FORMS_LIST_FETCH_START";
export const FORMS_LIST_FETCH_SUCCESS = "FORMS_LIST_FETCH_SUCCESS";
export const FORMS_FIELD_LIST_FETCH_START = "FORMS_FIELD_LIST_FETCH_START";
export const FORMS_FIELD_LIST_FETCH_SUCCESS = "FORMS_FIELD_LIST_FETCH_SUCCESS";
export const FORMS_MODIFY_FIELD_DATA = "FORMS_MODIFY_FIELD_DATA";
export const FORMS_MODIFY_FILE_FIELD_DATA = "FORMS_MODIFY_FILE_FIELD_DATA";
export const FORMS_MODIFY_CHECK_FIELD_DATA = "FORMS_MODIFY_CHECK_FIELD_DATA";
export const FORMS_SUBMIT_START = "FORMS_SUBMIT_START";
export const FORMS_SUBMIT_SUCCESS = "FORMS_SUBMIT_SUCCESS";
export const FORMS_FORMNAME_FETCH_SUCCESS = "FORMS_FORMNAME_FETCH_SUCCESS";
export const FORMS_MAP_VIEW_DATA_SUCCESS = "FORMS_MAP_VIEW_DATA_SUCCESS";
export const FORMS_FIELD_VALIDATE = "FORMS_FIELD_VALIDATE";
export const FORMS_ACCESS_LOG_ADD_SUCCESS = "FORMS_ACCESS_LOG_ADD_SUCCESS";
export const FORM_SUBMITTED_SET_FALSE = "FORM_SUBMITTED_SET_FALSE";


//Widgets
export const WIDGET_LIST_FETCH_START = "WIDGET_LIST_FETCH_START";
export const WIDGET_LIST_FETCH_SUCCESS = "WIDGET_LIST_FETCH_SUCCESS";
export const WIDGET_DATASET_UPDATE = "WIDGET_DATASET_UPDATE";
export const WIDGET_TIMELINE_SUPPORTED_SUCCESS = "WIDGET_TIMELINE_SUPPORTED_SUCCESS";
export const WIDGET_TYPE_CATEGORY_SUCCESS = "WIDGET_TYPE_CATEGORY_SUCCESS";
export const WIDGET_AGGREGATE_SUCCESS = "WIDGET_AGGREGATE_SUCCESS";
export const WIDGET_FORMS_LIST_SUCCESS = "WIDGET_FORMS_LIST_SUCCESS";
export const WIDGET_FIELDS_BY_CATEGORY_FORMS_SUCCESS = "WIDGET_FIELDS_BY_CATEGORY_FORMS_SUCCESS";
export const WIDGET_CHART_SUPPORTED_SUCCESS = "WIDGET_CHART_SUPPORTED_SUCCESS";
export const WIDGET_TYPE_SUCCESS = "WIDGET_TYPE_SUCCESS";
export const WIDGET_DATA_PARSE_START = "WIDGET_DATA_PARSE_START";
export const WIDGET_DATA_PARSE_SUCCESS = "WIDGET_DATA_PARSE_SUCCESS";
export const WIDGET_DRILL_DATA_START = "WIDGET_DRILL_DATA_START";
export const WIDGET_DRILL_DATA_SUCCESS = "WIDGET_DRILL_DATA_SUCCESS";
export const PROCESSES_WITH_WIDGETS_START = "PROCESSES_WITH_WIDGETS_START";
export const PROCESSES_WITH_WIDGETS_SUCCESS = "PROCESSES_WITH_WIDGETS_SUCCESS";
export const USER_SPECIFIC_WIDGETS_FOR_A_PROCESS_START = "USER_SPECIFIC_WIDGETS_FOR_A_PROCESS_START";
export const USER_SPECIFIC_WIDGETS_FOR_A_PROCESS_SUCCESS = "USER_SPECIFIC_WIDGETS_FOR_A_PROCESS_SUCCESS";

//Queue
export const QUEUE_LIST_FETCH_START = "QUEUE_LIST_FETCH_START";
export const QUEUE_LIST_FETCH_SUCCESS = "QUEUE_LIST_FETCH_SUCCESS";
export const QUEUE_FORM_FIELD_ALTER_START = "QUEUE_FORM_FIELD_ALTER_START";
export const QUEUE_FORM_FIELD_ALTER_SUCCESS = "QUEUE_FORM_FIELD_ALTER_SUCCESS";
export const QUEUE_FORMIDS_ON_NEWORDERFORM_START = "QUEUE_FORMIDS_ON_NEWORDERFORM_START";
export const QUEUE_FORMIDS_ON_NEWORDERFORM_SUCCESS = "QUEUE_FORMIDS_ON_NEWORDERFORM_SUCCESS";
export const QUEUE_ACTIVITY_LIST_FETCH_START = "QUEUE_ACTIVITY_LIST_FETCH_START";
export const QUEUE_ACTIVITY_LIST_FETCH_SUCCESS = "QUEUE_ACTIVITY_LIST_FETCH_SUCCESS";
export const QUEUE_ACTIVITY_LIST_STATUSES_FETCH_START = "QUEUE_ACTIVITY_LIST_STATUSES_FETCH_START";
export const QUEUE_ACTIVITY_LIST_STATUSES_FETCH_SUCCESS = "QUEUE_ACTIVITY_LIST_STATUSES_FETCH_SUCCESS";
export const QUEUE_SUBMITTED_FORMS_FETCH_START = "QUEUE_SUBMITTED_FORMS_FETCH_START";
export const QUEUE_SUBMITTED_FORMS_FETCH_SUCCESS = "QUEUE_SUBMITTED_FORMS_FETCH_SUCCESS";
export const QUEUE_CHECK_FORM_ACTIVITYID_START = "QUEUE_CHECK_FORM_ACTIVITYID_START";
export const QUEUE_CHECK_FORM_ACTIVITYID_SUCCESS = "QUEUE_CHECK_FORM_ACTIVITYID_SUCCESS";
export const QUEUE_CAF_VALIDATION_DATA_START = "QUEUE_CAF_VALIDATION_DATA_START";
export const QUEUE_CAF_VALIDATION_DATA_SUCCESS = "QUEUE_CAF_VALIDATION_DATA_SUCCESS";
export const QUEUE_CAF_VALIDATION_FIELD_FETCH_START = "QUEUE_CAF_VALIDATION_FIELD_FETCH_START";
export const QUEUE_CAF_VALIDATION_FIELD_FETCH_SUCCESS = "QUEUE_CAF_VALIDATION_FIELD_FETCH_SUCCESS";
export const QUEUE_CHECK_SUBMITTED_FORMS_FETCH_START = "QUEUE_CHECK_SUBMITTED_FORMS_FETCH_START";
export const QUEUE_CHECK_SUBMITTED_FORMS_FETCH_SUCCESS = "QUEUE_CHECK_SUBMITTED_FORMS_FETCH_SUCCESS";
//export const QUEUE_CAF_APPROVAL_START = "QUEUE_CAF_APPROVAL_START";
export const QUEUE_CAF_APPROVAL_SUCCESS = "QUEUE_CAF_APPROVAL_SUCCESS";
export const CAF_VALIDATION_MODIFY_FIELD_DATA = "CAF_VALIDATION_MODIFY_FIELD_DATA";
export const CAF_VALIDATION_UPDATE_FIELD_DATA = "CAF_VALIDATION_UPDATE_FIELD_DATA";
export const QUEUE_FORM_VALUES_FETCH_START = "QUEUE_FORM_VALUES_FETCH_START";
export const QUEUE_FORM_VALUES_FETCH_SUCCESS = "QUEUE_FORM_VALUES_FETCH_SUCCESS";
export const QUEUE_DROPDOWN_FETCH_START = "QUEUE_DROPDOWN_FETCH_START";
export const QUEUE_DROPDOWN_FETCH_SUCCESS = "QUEUE_DROPDOWN_FETCH_SUCCESS";
export const QUEUE_FETCH_STATUS_START = "QUEUE_FETCH_STATUS_START";
export const QUEUE_FETCH_STATUS_SUCCESS = "QUEUE_FETCH_STATUS_SUCCESS";
export const QUEUE_UNASSIGN_PARTICIPANT_START = "QUEUE_UNASSIGN_PARTICIPANT_START";
export const QUEUE_UNASSIGN_PARTICIPANT_SUCCESS = "QUEUE_UNASSIGN_PARTICIPANT_SUCCESS";
export const GET_WORKFORCE_LEVEL_FORMS_START = "GET_WORKFORCE_LEVEL_FORMS_START";
export const GET_WORKFORCE_LEVEL_FORMS_SUCCESS = "GET_WORKFORCE_LEVEL_FORMS_SUCCESS";
export const SEARCH_WORKFORCE_LEVEL_FORMS_START = "SEARCH_WORKFORCE_LEVEL_FORMS_START";
export const SERACH_WORKFORCE_LEVEL_FORM_SUCCESS = "SERACH_WORKFORCE_LEVEL_FORM_SUCCESS";
export const QUEUE_NAME_EDIT_START = "QUEUE_NAME_EDIT_START";
export const QUEUE_NAME_EDIT_SUCCESS = "QUEUE_NAME_EDIT_SUCCESS";
//get user ip
export const GET_USER_IP_SUCCESS = "GET_USER_IP_SUCCESS";
export const GET_USER_COORDS_SUCCESS = "GET_USER_COORDS_SUCCESS";

// activity type load
export const LOAD_ACTIVITY_TYPE_BY_CATEGORY_START = "LOAD_ACTIVITY_TYPE_BY_CATEGORY_START";
export const LOAD_ACTIVITY_TYPE_BY_CATEGORY_SUCCESS = "LOAD_ACTIVITY_TYPE_BY_CATEGORY_SUCCESS";
// activity type load
export const LOAD_ACTIVITY_STATUS_BY_TYPE_CATEGORY_START = "LOAD_ACTIVITY_STATUS_BY_TYPE_CATEGORY_START";
export const LOAD_ACTIVITY_STATUS_BY_TYPE_CATEGORY_SUCCESS = "LOAD_ACTIVITY_STATUS_BY_TYPE_CATEGORY_SUCCESS";

//Process 
export const PROCESS_LIST_FETCH_START = "PROCESS_LIST_FETCH_START";
export const PROCESS_LIST_FETCH_SUCCESS = "PROCESS_LIST_FETCH_SUCCESS";
// to load activity for process creation
export const PROCESS_ACTIVTY_CATAGORY_TYPE_FETCH_START = "PROCESS_ACTIVTY_CATAGORY_TYPE_FETCH_START";
export const PROCESS_ACTIVTY_CATAGORY_TYPE_FETCH_SUCCESS = "PROCESS_ACTIVTY_CATAGORY_TYPE_FETCH_SUCCESS";
export const PROCESS_LIST_ADD = "PROCESS_LIST_ADD";
export const PROCESS_STATUS_LIST_FETCH_START = "PROCESS_STATUS_LIST_FETCH_START";
export const PROCESS_STATUS_LIST_FETCH_SUCCESS = "PROCESS_STATUS_LIST_FETCH_SUCCESS";
export const GLOBAL_QUEUE_STATUS_LIST_FETCH_START = "GLOBAL_QUEUE_STATUS_LIST_FETCH_START";
export const GLOBAL_QUEUE_STATUS_LIST_FETCH_SUCCESS = "GLOBAL_QUEUE_STATUS_LIST_FETCH_SUCCESS";
export const QUEUE_EDIT_START = "QUEUE_EDIT_START";
export const QUEUE_EDIT_SUCCESS = "QUEUE_EDIT_SUCCESS";
export const PROCESS_FORMS_LIST_FETCH_START = "PROCESS_FORMS_LIST_FETCH_START";
export const PROCESS_FORMS_LIST_FETCH_SUCCESS = "PROCESS_FORMS_LIST_FETCH_SUCCESS";
export const PROCESS_COMMUNICATION_LIST_FETCH_START = "PROCESS_COMMUNICATION_LIST_FETCH_START";
export const PROCESS_COMMUNICATION_LIST_FETCH_SUCCESS = "PROCESS_COMMUNICATION_LIST_FETCH_SUCCESS";
export const PROCESS_FORM_UNMAPPING_START = "PROCESS_FORM_UNMAPPING_START";
export const PROCESS_FORM_UNMAPPING_SUCCESS = "PROCESS_FORM_UNMAPPING_SUCCESS";
export const PROCESS_QUEUELIST_FETCH_START = "PROCESS_QUEUELIST_FETCH_START";
export const PROCESS_QUEUELIST_FETCH_SUCCESS = "PROCESS_QUEUELIST_FETCH_SUCCESS";
export const PROCESS_BOTLIST_FETCH_START = "PROCESS_BOTLIST_FETCH_START";
export const PROCESS_BOTLIST_FETCH_SUCCESS = "PROCESS_BOTLIST_FETCH_SUCCESS";
export const PROCESS_FORM_SETTINGS_START = "PROCESS_FORM_SETTINGS_START";
export const PROCESS_FORM_SETTINGS_SUCCESS = "PROCESS_FORM_SETTINGS_SUCCESS";
export const PROCESS_WIDGETLIST_FETCH_START = "PROCESS_WIDGETLIST_FETCH_START";
export const PROCESS_WIDGETLIST_FETCH_SUCCESS = "PROCESS_WIDGETLIST_FETCH_SUCCESS";
export const PROCESS_WIDGET_DELETE_SUCCESS = "PROCESS_WIDGET_DELETE_SUCCESS";
export const PROCESS_QUEUE_DELETE_START = "PROCESS_QUEUE_DELETE_START";
export const PROCESS_QUEUE_DELETE_SUCCSSS = "PROCESS_QUEUE_DELETE_SUCCSSS";
export const PROCESS_USER_MAPPING_QUEUE_START = "PROCESS_USER_MAPPING_QUEUE_START";
export const PROCESS_USER_MAPPING_QUEUE_SUCCESS = "PROCESS_USER_MAPPING_QUEUE_SUCCESS";
export const PROCESS_ADD_QUEUE_START = "PROCESS_ADD_QUEUE_START";
export const PROCESS_ADD_QUEUE_SUCCESS = "PROCESS_ADD_QUEUE_SUCCESS";
export const PROCESS_ADD_STATUS_START = "PROCESS_ADD_STATUS_START";
export const PROCESS_ADD_STATUS_SUCCESS = "PROCESS_ADD_STATUS_SUCCESS";
export const PROCESS_DELETE_STATUS_START = "PROCESS_DELETE_STATUS_START";
export const PROCESS_DELETE_STATUS_SUCCESS = "PROCESS_DELETE_STATUS_SUCCESS";
export const PROCESS_QUEUE_MAPPING_FETCH_START = "PROCESS_QUEUE_MAPPING_FETCH_START";
export const PROCESS_QUEUE_MAPPING_FETCH_SUCCESS = "PROCESS_QUEUE_MAPPING_FETCH_SUCCESS";
export const QUEUE_PARTICIPANT_RESET_START = "QUEUE_PARTICIPANT_RESET_START";
export const QUEUE_PARTICIPANT_RESET_SUCCESS = "QUEUE_PARTICIPANT_RESET_SUCCESS";
export const WIDGETS_FORMS_NUMERIC_FIELDS_SUCCESS = "WIDGETS_FORMS_NUMERIC_FIELDS_SUCCESS";
export const WIDGETS_FORMS_NUMERIC_FIELDS_START = "WIDGETS_FORMS_NUMERIC_FIELDS_START";
export const PROCESS_UNMAPPING_START = "PROCESS_UNMAPPING_START";
export const PROCESS_UNMAPPING_SUCCESS = "PROCESS_UNMAPPING_SUCCESS";
export const PROCESS_ADD_STATUS_TAG_START = "PROCESS_ADD_STATUS_TAG_START";
export const PROCESS_ADD_STATUS_TAG_SUCCESS = "PROCESS_ADD_STATUS_TAG_SUCCESS";
export const PROCESS_STATUS_TAG_LIST_FETCH_START = "PROCESS_STATUS_TAG_LIST_FETCH_START";
export const PROCESS_STATUS_TAG_LIST_FETCH_SUCCESS = "PROCESS_STATUS_TAG_LIST_FETCH_SUCCESS";
export const TAGS_LIST_FETCH_SUCESS = "TAGS_LIST_FETCH_SUCESS";
export const TAG_TYPES_LIST_FETCH_SUCESS = "TAG_TYPES_LIST_FETCH_SUCESS";
//roles
export const PROCES_ROLES_LIST_FETCH_SUCCESS = "PROCES_ROLES_LIST_FETCH_SUCCESS";

// Bot
export const PROCESS_BOT_UNMAPPING_START = "PROCESS_BOT_UNMAPPING_START";
export const PROCESS_BOT_UNMAPPING_SUCCESS = "PROCESS_BOT_UNMAPPING_SUCCESS";
export const GET_SINGLE_BOT_DETAILS_START = "GET_SINGLE_BOT_DETAILS_START";
export const GET_SINGLE_BOT_DETAILS_SUCCESS = "GET_SINGLE_BOT_DETAILS_SUCCESS";
export const EMPLOYEE_DETAILSBASEDON_PHNO_FETCH_START = "EMPLOYEE_DETAILSBASEDON_PHNO_FETCH_START";
export const EMPLOYEE_DETAILSBASEDON_PHNO_SUCCESS = "EMPLOYEE_DETAILSBASEDON_PHNO_SUCCESS";
export const EMPLOYEE_DETAILSBASEDON_PHNO_FETCH_END = "EMPLOYEE_DETAILSBASEDON_PHNO_FETCH_END";
export const PROCESS_FORMS_LIST_FORBOT_FETCH_START = "PROCESS_FORMS_LIST_FORBOT_FETCH_START";
export const PROCESS_FORMS_LIST_FORBOT_FETCH_SUCCESS = "PROCESS_FORMS_LIST_FORBOT_FETCH_SUCCESS";


//Desk Add and Delete
export const EMPLOYEE_ADD_TO_WORKFORCE_START = "EMPLOYEE_ADD_TO_WORKFORCE_START";
export const EMPLOYEE_ADD_TO_WORKFORCE_END = "EMPLOYEE_ADD_TO_WORKFORCE_END";
export const EMPLOYEE_UPDATE_IN_WORKFORCE_START = "EMPLOYEE_UPDATE_IN_WORKFORCE_START";
export const EMPLOYEE_UPDATE_IN_WORKFORCE_END = "EMPLOYEE_UPDATE_IN_WORKFORCE_END";
export const SETUP_NEW_FLOOR_START = "SETUP_NEW_FLOOR_START";
export const SETUP_NEW_FLOOR_END = "SETUP_NEW_FLOOR_END";
export const MOVE_DESK_TO_FLOOR_START = "MOVE_DESK_TO_FLOOR_START";
export const MOVE_DESK_TO_FLOOR_END = "MOVE_DESK_TO_FLOOR_END";
export const EMPLOYEE_ASSIGN_SUCCESS = "EMPLOYEE_ASSIGN_SUCCESS";
export const REMOVE_EMPLOYEE_FROM_DESK_START = "REMOVE_EMPLOYEE_FROM_DESK_START";
export const REMOVE_EMPLOYEE_FROM_DESK_END = "REMOVE_EMPLOYEE_FROM_DESK_END";
export const REMOVE_DESK_FROM_FLOOR_START = "REMOVE_DESK_FROM_FLOOR_START";
export const REMOVE_DESK_FROM_FLOOR_END = "REMOVE_DESK_FROM_FLOOR_END";

// Building and related workforce related
export const WORKFORCE_TITLE_UPDATE_SUCCESS = "WORKFORCE_TITLE_UPDATE_SUCCESS";
export const GOT_WORKFORCE_LIST_OF_BUILDING = "GOT_WORKFORCE_LIST_OF_BUILDING";
export const GOT_ACCOUNT_LIST = "GOT_ACCOUNT_LIST";

// Files Refs
export const WORKFLOWS_FETCH_START = "WORKFLOWS_FETCH_START";
export const WORKFLOWS_FETCH_SUCCESS = "WORKFLOWS_FETCH_SUCCESS";
export const WORKFLOWS_FETCH_FAIL = "WORKFLOWS_FETCH_FAIL";
export const WORKFLOWS_LIST_FOR_REFERENCE = "WORKFLOWS_LIST_FOR_REFERENCE";
export const WORKFLOWS_LIST_FOR_Credit = "WORKFLOWS_LIST_FOR_Credit";
export const WORKFLOWS_CLEAR_STORE = "WORKFLOWS_CLEAR_STORE";

export const ASSET_FETCH_START = "ASSET_FETCH_START";
export const ASSET_FETCH_SUCCESS = "ASSET_FETCH_SUCCESS";
export const ASSET_FETCH_FAIL = "ASSET_FETCH_FAIL";
export const ASSET_LIST_FOR_REFERENCE = "ASSET_LIST_FOR_REFERENCE";
export const ASSET_CLEAR_STORE = "ASSET_CLEAR_STORE";

export const DOCUMENT_FETCH_START = "DOCUMENT_FETCH_START";
export const DOCUMENT_FETCH_SUCCESS = "DOCUMENT_FETCH_SUCCESS";
export const DOCUMENTS_LIST_FOR_REFERENCE = "DOCUMENTS_LIST_FOR_REFERENCE";
export const DOCUMENTS_FETCH_FAIL = "DOCUMENTS_FETCH_FAIL";

export const FLOOR_LIST_FETCH_SUCCESS_1 = "FLOOR_LIST_FETCH_SUCCESS_1";

export const FORM_WORKFORCE_ACCESS_LIST_SUCCESS = "FORM_WORKFORCE_ACCESS_LIST_SUCCESS";
export const FORM_WORKFORCE_ACCESS_LIST_START = "FORM_WORKFORCE_ACCESS_LIST_START";
export const FORM_WORKFORCE_ACCESS_SAVE = "FORM_WORKFORCE_ACCESS_SAVE";

export const ACCOUNT_SUMMARY_MONTHLY_SUCCESS = "ACCOUNT_SUMMARY_MONTHLY_SUCCESS";
export const ACCOUNT_SUMMARY_QUARTERLY_SUCCESS = "ACCOUNT_SUMMARY_QUARTERLY_SUCCESS";
export const ACCOUNT_SUMMARY_YEARLY_SUCCESS = "ACCOUNT_SUMMARY_YEARLY_SUCCESS";

// Building and related workforce related
export const RESOURCE_WEIGHTAGES_FETCH_START = "RESOURCE_WEIGHTAGES_FETCH_START";
export const RESOURCE_WEIGHTAGES_FETCH_SUCCESS = "RESOURCE_WEIGHTAGES_FETCH_SUCCESS";
export const RESOURCE_WEIGHTAGES_FETCH_FAILED = "RESOURCE_WEIGHTAGES_FETCH_FAILED";

export const RESOURCE_WEIGHTAGES_ADD_START = "RESOURCE_WEIGHTAGES_ADD_START";
export const RESOURCE_WEIGHTAGES_ADD_SUCCESS = "RESOURCE_WEIGHTAGES_ADD_SUCCESS";
export const RESOURCE_WEIGHTAGES_ADD_FAILED = "RESOURCE_WEIGHTAGES_ADD_FAILED";


export const PROCESS_STATUS_FORMS_LIST_MAPPING_START = "PROCESS_STATUS_FORMS_LIST_MAPPING_START";
export const PROCESS_STATUS_FORMS_LIST_MAPPING_SUCCESS = "PROCESS_STATUS_FORMS_LIST_MAPPING_SUCCESS";

export const LOAD_STATUS_FORMS_LIST_MAPPING_START = "LOAD_STATUS_FORMS_LIST_MAPPING_START";
export const LOAD_STATUS_FORMS_LIST_MAPPING_SUCCESS = "LOAD_STATUS_FORMS_LIST_MAPPING_SUCCESS";

export const FORM_STATUS_DELETE_START = "FORM_STATUS_DELETE_START";
export const FORM_STATUS_DELETE_SUCCESS = "FORM_STATUS_DELETE_SUCCESS";

// CREATE DESK NEW REDESIGN VIEW 
export const CUID_EXIST_CHECK_SUCCESS = 'CUID_EXIST_CHECK_SUCCESS';
export const PHONE_EXIST_CHECK_SUCCESS = 'PHONE_EXIST_CHECK_SUCCESS'
export const CREATE_DESK_SUCCESS = 'CREATE_DESK_SUCCESS';
export const CREATE_DESK_FAILED = 'CREATE_DESK_FAILED';
export const CREATE_DESK_START = 'CREATE_DESK_START';
export const TAGTYPE_EDIT_START = "TAGTYPE_EDIT_START";
export const TAGTYPE_EDIT_SUCCESS = "TAGTYPE_EDIT_SUCCESS";

export const TAGTYPE_UNMAP_START = "TAGTYPE_UNMAP_START";
export const TAGTYPE_UNMAP_SUCCESS = "TAGTYPE_UNMAP_SUCCESS";

export const ENTITY_MAPPING_LIST_START = "ENTITY_MAPPING_LIST_START";
export const ENTITY_MAPPING_LIST_SUCCESS = "ENTITY_MAPPING_LIST_SUCCESS";
// SET REPORTING MANAGER
export const SET_REPORTING_MANAGER_SUCCESS = "SET_REPORTING_MANAGER_SUCCESS";
export const SET_REPORTING_MANAGER_FAILED = "SET_REPORTING_MANAGER_FAILED";

// DOTTED LINE MANAGERS
export const SET_DOTTED_LINE_MANAGER_SUCCESS = "SET_DOTTED_LINE_MANAGER_SUCCESS";
export const SET_DOTTED_LINE_MANAGER_FAILED = "SET_DOTTED_LINE_MANAGER_FAILED";

export const REMOVE_DOTTED_LINE_MANAGER_SUCCESS = "REMOVE_DOTTED_LINE_MANAGER_SUCCESS";
export const REMOVE_DOTTED_LINE_MANAGER_FAILED = "REMOVE_DOTTED_LINE_MANAGER_FAILED";

//FETCH MANAGERS LIST
export const FETCH_MANAGER_LIST_SUCCESS = "FETCH_MANAGER_LIST_SUCCESS";
export const FETCH_MANAGER_LIST_FAILED = "FETCH_MANAGER_LIST_FAILED";

// SET EMPLOYEE ACCESS
export const SET_EMPLOYEE_ACCESS_SUCCESS = "SET_EMPLOYEE_ACCESS_SUCCESS";
export const SET_EMPLOYEE_ACCESS_FAILED = "SET_EMPLOYEE_ACCESS_FAILED";

// GET ASSET ACCESS
export const GET_ASSET_ACCESS_SUCCESS = "GET_ASSET_ACCESS_SUCCESS";
export const GET_ASSET_ACCESS_FAILED = "GET_ASSET_ACCESS_FAILED"

export const ENTITY_MAPPING_UNMAP_START = "ENTITY_MAPPING_UNMAP_START";
export const ENTITY_MAPPING_UNMAP_SUCCESS = "ENTITY_MAPPING_UNMAP_SUCCESS";

export const ENTITY_TAG_MAPPING_LIST_START = "ENTITY_TAG_MAPPING_LIST_START";
export const ENTITY_TAG_MAPPING_LIST_SUCCESS = "ENTITY_TAG_MAPPING_LIST_SUCCESS";

//UPDATE DOCUMENT IN DOCUMENT REPOSITORY
export const DOCUMENT_UPDATE_START = "DOCUMENT_UPDATE_START";
export const DOCUMENT_UPDATE_SUCCESS = "DOCUMENT_UPDATE_SUCCESS";
export const DOCUMENT_UPDATE_FAIL = "DOCUMENT_UPDATE_FAIL";

// Get Timesheets Data
export const FETCH_TIMESHEET_DATA_START = "FETCH_TIMESHEET_DATA_START";
export const FETCH_TIMESHEET_DATA_SUCCESS = "FETCH_TIMESHEET_DATA_SUCCESS";

// Get Manager Resource List
export const MANAGER_RESOURCE_LIST_START = "MANAGER_RESOURCE_LIST_START";
export const MANAGER_RESOURCE_LIST_SUCCESS = "MANAGER_RESOURCE_LIST_SUCCESS";
export const MANAGER_RESOURCE_LIST_FAILED = "MANAGER_RESOURCE_LIST_FAILED";

export const CURRENT_USER_DATA_SUCCESS = "CURRENT_USER_DATA_SUCCESS";

// Get My Focus Count
export const MY_FOCUS_DATA_FETCH_START = "MY_FOCUS_DATA_FETCH_START";
export const MY_FOCUS_DATA_FETCH_SUCCESS = "MY_FOCUS_DATA_FETCH_SUCCESS";
//SAVE DOCUMENT IN DOCUMENT REPOSITORY
export const DOCUMENT_SAVE_START = "DOCUMENT_SAVE_START";
export const DOCUMENT_SAVE_SUCCESS = "DOCUMENT_SAVE_SUCCESS";
export const DOCUMENT_SAVE_FAIL = "DOCUMENT_SAVE_FAIL";
/////////////////////////////
export const AUTO_POPULATE_FIELDS_SUCCESS = "AUTO_POPULATE_FIELDS_SUCCESS";
export const AUTO_POPULATE_FIELDS_FAILED="AUTO_POPULATE_FIELDS_FAILED";
export const FETCH_COMPOSIT_LOV_CITY_LIST_SUCCESS="FETCH_COMPOSIT_LOV_CITY_LIST_SUCCESS";
export const FETCH_COMPOSIT_LOV_CITY_LIST_START="FETCH_COMPOSIT_LOV_CITY_LIST_START";
export const FETCH_COMPOSIT_LOV_STATE_LIST_START="FETCH_COMPOSIT_LOV_STATE_LIST_START";
export const FETCH_COMPOSIT_LOV_STATE_LIST_SUCCESS="FETCH_COMPOSIT_LOV_STATE_LIST_SUCCESS";
export const FETCH_COMPOSIT_LOV_ZIPCODE_LIST_START="FETCH_COMPOSIT_LOV_ZIPCODE_LIST_START";
export const FETCH_COMPOSIT_LOV_ZIPCODE_LIST_SUCCESS="FETCH_COMPOSIT_LOV_ZIPCODE_LIST_SUCCESS";
export const FETCH_PRODUCT_CATEGORY_START="FETCH_PRODUCT_CATEGORY_START";
export const FETCH_PRODUCT_CATEGORY_SUCCESS="FETCH_PRODUCT_CATEGORY_SUCCESS";
export const FETCH_PRODUCT_WORKFLOW_TYPE_START="FETCH_PRODUCT_WORKFLOW_TYPE_START";
export const FETCH_PRODUCT_VARIANTS_SUCCESS="FETCH_PRODUCT_VARIANTS_SUCCESS";
export const FETCH_PRODUCT_VARIANTS_START="FETCH_PRODUCT_VARIANTS_START";
export const FETCH_PRODUCT_WORKFLOW_TYPE_SUCCESS="FETCH_PRODUCT_WORKFLOW_TYPE_SUCCESS";
export const FETCH_PRODUCT_WORKFLOW_START="FETCH_PRODUCT_WORKFLOW_START";
export const FETCH_PRODUCT_WORKFLOW_SUCCESS="FETCH_PRODUCT_WORKFLOW_SUCCESS";
export const FETCH_COMPOSIT_SLOT_LIST_SUCCESS="FETCH_COMPOSIT_SLOT_LIST_SUCCESS";
export const FETCH_COMPOSIT_SLOT_LIST_START="FETCH_COMPOSIT_SLOT_LIST_START";
export const LOV_DATATYPE_LIST="LOV_DATATYPE_LIST";
export const LOV_DATATYPE_LIST_START="LOV_DATATYPE_LIST_START";
// export const FETCH_COMPOSIT_SLOT_LIST_START="FETCH_COMPOSIT_SLOT_LIST_START";