import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    formData: {},
    loadingForms: false,
    loadingFields: false,
    loadingFormSubmit: false,
    formsList: [],
    fieldList: {},
    formsListOpt: [],
    formSubmitted: false,
    formName: null,
    formFullLoaded: 0,
    formValidated: true,
    AccessLogData: [],
    fieldHistoryList: [],
    fieldHistoryError: false,
}

const reducer = (state = initialState,action) => {

    switch(action.type) {
        case actionTypes.FORMS_LIST_FETCH_START:
            //console.log("load chats called");
            return {
                ...state,
                formsList: [],
                fieldList: {},
                loadingForms: true,
                formSubmitted: false,
            }
        case actionTypes.FORMS_LIST_FETCH_SUCCESS:
            if(action.mode && action.mode !== undefined) {
                return {
                    ...state,
                    formsListOpt: action.formListData,
                    loadingForms: false,
                }
            }
            else {
                return {
                    ...state,
                    formsList: action.formListData,
                    loadingForms: false,
                }
            }

        case actionTypes.FORMS_FIELD_LIST_FETCH_START:
            //console.log("load chats called");
            return {
                ...state,
                fieldList: {},
                loadingFields: true,
                formSubmitted: false,
            }
        case actionTypes.FORMS_FIELD_LIST_FETCH_SUCCESS:
            return {
                ...state,
                fieldList: action.fieldData,
                loadingFields: false,
                formValidated: true,
            }
        case actionTypes.FORMS_FIELD_VALIDATE:
            return {
                ...state,
                formValidated: action.validation,
            }

        case actionTypes.FORMS_FORMNAME_FETCH_SUCCESS:
            return {
                ...state,
                formName: action.formName,
            }
        case actionTypes.FORMS_ACCESS_LOG_ADD_SUCCESS:
            return {
                ...state,
                AccessLogData: action.accessData,
            }


        case actionTypes.FORMS_SUBMIT_START:
            return {
                ...state,
                loadingFormSubmit: true,
            }
        case actionTypes.FORMS_SUBMIT_SUCCESS:
            return {
                ...state,
                loadingFormSubmit: false,
                formSubmitted: true,
                fieldList: {}
            }


        case actionTypes.FORMS_MODIFY_FIELD_DATA:
            //action.fieldValue.persist();
            const updatedForm = {
                ...state.fieldList
            };
            const updatedFormElement = {
                ...updatedForm[action.elementId]
            };
            if(action.field) {
                updatedFormElement.value = {...updatedFormElement.value}
                updatedFormElement.value[action.field] = action.fieldValue
            }
            else
                updatedFormElement.value = action.fieldValue;

            updatedForm[action.elementId] = updatedFormElement;

            return {
                ...state,
                fieldList: updatedForm,
                //loadingFields :false
            }
        case actionTypes.FORMS_MODIFY_FILE_FIELD_DATA:
            const updatedFile = {
                ...state.fieldList
            };
            const updatedFileElement = {
                ...updatedFile[action.elementId]
            };
            updatedFileElement.fileUploadData = action.fieldValue;
            updatedFile[action.elementId] = updatedFileElement;
            console.log(action);
            return {
                ...state,
                fieldList: updatedFile,
            }
        case actionTypes.FORMS_MODIFY_CHECK_FIELD_DATA:
            const updatedCheck = {
                ...state.fieldList
            };
            const updatedCheckElement = {
                ...updatedCheck[action.elementId]
            };
            updatedCheckElement.value = action.fieldValue;
            updatedCheck[action.elementId] = updatedCheckElement;

            return {
                ...state,
                fieldList: updatedCheck,
                //loadingFields :false
            }
        case actionTypes.FORMS_MAP_VIEW_DATA_SUCCESS:
            return {
                ...state,
                fieldList: action.ValidViewData,
                formFullLoaded: 1
            }
        case actionTypes.FORM_SUBMITTED_SET_FALSE:
            return {
                ...state,
                formSubmitted: false
            }
        case actionTypes.QUEUE_FORM_FIELD_LOAD_HISTORY_START:
            return {
                ...state,
                fieldHistoryList: [],
                fieldHistoryError: false,
            }
        case actionTypes.QUEUE_FORM_FIELD_LOAD_HISTORY_SUCCESS:
            return {
                ...state,
                fieldHistoryList: action.historyList,
                fieldHistoryError: false,
            }
        case actionTypes.QUEUE_FORM_FIELD_LOAD_HISTORY_FAILED:
            return {
                ...state,
                fieldHistoryList: [],
                fieldHistoryError: action.error,
            }
        default:
            return state;


    }

}
export default reducer;