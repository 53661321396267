import React,{Component} from 'react';
import {connect} from 'react-redux';
import * as utils from '../../../shared/Utils';
import * as actions from '../../../store/Actions/index';
import './FieldTimelineHistory.css';
class FieldTimelineHistory extends Component {
    state = {}

    refreshComponent = () => {
        this.props.loadFieldHistory({
            form_id: this.props.fieldData.form_id,
            field_id: this.props.fieldData.field_id,
            form_transaction_id: this.props.urlFormData.form_transaction_id,
            organization_id: this.props.urlFormData.org_id,
            asset_id: this.props.urlFormData.asset_id,
            asset_token_auth: this.props.urlFormData.auth_token,
        });
    }

    componentDidMount() {
        if(this.props) {
            this.refreshComponent();
        }
    }

    render() {
        console.log("timeline render");
        return (
            <div className="field-timeline-history-container">
                {this.props && this.props.fieldHistoryList && this.props.fieldHistoryList.length > 0 &&
                    <div class="timeline-container">
                        <div className="timeline-header">
                            <h4>{this.props.fieldHistoryList[0].field_name} <small>(Timeline history)</small></h4>
                        </div>
                        <div className="timeline-content">
                            {this.props.fieldHistoryList.map((fieldItem) => {
                                return (<div className="field-info">
                                    <small><i><strong>
                                        {utils.getLocalTimeDate(
                                            fieldItem.log_datetime
                                        )}</strong></i></small>
                                    <p>
                                        <strong>{fieldItem.operating_asset_first_name} </strong> updated the field value to
                                        <strong> {fieldItem.data_entity_text_1} </strong>
                                    </p>

                                </div>);
                            })}
                        </div>
                    </div>
                }
                {
                    this.props && this.props.fieldHistoryError && <div className="timeline-load-error" >
                        <p>Something went wrong while loading timeline</p>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fieldHistoryList: state.forms.fieldHistoryList,
        fieldHistoryError: state.forms.fieldHistoryError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadFieldHistory: (payload) => dispatch(actions.fetchFormFieldHistory(payload))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(FieldTimelineHistory);
