import React, { Component } from "react";
import vilLogoRed from "../../../../assets/vil/vil-red-logo.png";
import "./vil-branding.css";
export default class SectionHeader extends Component {
  render() {
    return (
      <div className="section-header">
        <div className="vil-logo">
          <img src={vilLogoRed} alt="" />
        </div>
        <div className="section-head-detail">
          <p className="customfom"> {this.props.name}</p>
          <strong>
            Vi<sup>TM</sup> business
          </strong>{" "}
          <span className="pad_l10 section-note">
            Kindly fill the form in BLOCK letters
          </span>
        </div>
      </div>
    );
  }
}
