import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import S3Client from "aws-s3";
import AWS from "aws-sdk";
import PubNubReact from "pubnub-react";
import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FaEdit, FaHistory, FaRegTrashAlt, FaRegFilePdf } from "react-icons/fa";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import Select from "react-select";
import fieldEditConfig from "./config/field_edit_config";
import EditIcon from "@material-ui/icons/EditOutlined";
import alertImg from "../../../assets/images/Icons/alert_caf.png";
import CancelRed from "../../../assets/images/Icons/CancelRed.png";
import checkImg from "../../../assets/images/Icons/check.png";
import deleteList from "../../../assets/images/Icons/delete_list.png";
import Axios from "axios";
import Axiosweb from "../../../axios_ext";
import { GreneAutoComplete } from "../../../components/GreneAutoComplete/GreneAutoComplete";
import * as actions from "../../../store/Actions/index";
import * as utils from "../../../shared/Utils";
import FieldTimelineHistory from "./FieldTimelineHistory";
import PdfViewer from "./PdfViewer";
import trash from "../../../assets/images/Icons/remove-icon.png";
import ProductCart from "../../FormEntry/FormFields/DataTypeComponents/ProductCart";
const styles = {
  root: {
    flexGrow: 1,
  },
};

const supportedFiles = [
  "video/mp4",
  "video/mpeg",
  "video/3gp",
  "video/avi",
  "video/msvideo",
  "video/x-msvideo",
  "image/png",
  "image/gif",
  "video/x-ms-wmv",
  "video/x-ms-asf",
  "image/jpeg",
  "image/jpg",
  "image/svg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel.sheet.binary.macroenabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "message/*",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/zip",
  "application/octet-stream",
  "application/x-zip-compressed",
  "multipart/x-zip",
  "application/x-rar-compressed",
  "application/x-rar",
  "application/vnd.ms-outlook",
  "application/x-7z-compressed",
  "application/x-xz",
  "",
].join(", ");

const fileTypeIcons = {
  image: require("../../../assets/images/Icons/image-icon.png"),
  pdf: require("../../../assets/images/Icons/pdf-icon.png"),
  sheet: require("../../../assets/images/Icons/excel-icon.png"),
  doc: require("../../../assets/images/Icons/doc-icon.png"),
  word: require("../../../assets/images/Icons/doc-icon.png"),
  mail: require("../../../assets/images/Icons/mail-icon.png"),
  ppt: require("../../../assets/images/Icons/ppt-icon.png"),
  video: require("../../../assets/images/Icons/attach-icon.png"),
  zip: require("../../../assets/images/Icons/attach-icon.png"),
  text: require("../../../assets/images/Icons/doc-icon.png"),
  attach: require("../../../assets/images/Icons/attach-icon.png"),
  zip: require("../../../assets/images/Icons/attach-icon.png"),
  text: require("../../../assets/images/Icons/doc-icon.png"),
};

const annexureJson = {
  11182: "1068",
  11746: "1074",
  12415: "1346",
  12949: "1368",
  12891: "1137",
  11029: "1278",
  10489: "1261",
  11748: "1266",
  12963: "1369",
  12993: "1372",
  26440: "2279",
};
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class FieldMapping extends Component {
  state = {
    editedField: 0,
    //editedFieldVal: null,
    editedFieldVal: "",
    accepted: [],
    rejected: [],
    isProgress: 0,
    uploaded: false,
    formDataValuesState: [],
    editedFieldComboId: 0,
    fieldDropDown: [],
    showImageModal: false,
    showPDFModal: false,
    imageData: null,
    imageType: null,
    open: false,
    imageSeal: null,
    imageSealType: null,
    downloadLoader: false,
    dialogopen: false,
    deleteElementData: [],
    excelValidate: "",
    annexureFieldArray: [
      "11182i",
      "11746i",
      "12415i",
      "12949i",
      "12963i",
      "12993i",
      "12891i",
      "10489i",
      "11748i",
      "11029i",
      "26440i",
    ],
    selectedWorkflow: null,
    selectedAsset: null,
    referenceId: {},
    showEdit: true,
    showFieldHistory: false,
    fileName: "",
    selectedFiles: [],
  };
  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
    this.PubNub = new PubNubReact({
      publishKey: "pub-c-2df152ea-248e-493d-8271-a21463a0c1b4",
      subscribeKey: "sub-c-d5a2bff8-2c13-11e3-9343-02ee2ddab7fe",
      ssl: true,
      presenceTimeout: 600,
      heartbeatInterval: 610,
      uuid: props.urlFormData.asset_id + "-" + props.urlFormData.org_id,
    });
    this.PubNub.init(this);
  }
  componentWillMount() {
    this.PubNub.subscribe({
      channels: [
        this.props.urlFormData.org_id.toString(),
        this.props.urlFormData.asset_id.toString(),
      ],
      withPresence: true,
    });
    this.PubNub.getMessage(
      this.props.urlFormData.asset_id.toString(),
      (msg) => {
        this.setState({
          showEdit: true,
        });
        this.props.reloadComponent(true);
      }
    );
  }
  componentWillUnmount() {
    this.PubNub.unsubscribe({
      channels: [
        this.props.urlFormData.org_id.toString(),
        this.props.urlFormData.asset_id.toString(),
      ],
    });
  }
  componentDidMount() {
    const url =
      "https://worlddesk-2019-02.s3.amazonaws.com/858/974/5353/31476/2018/11/103/1550673538782/Seal.png";
    const BucketName = url.slice(8, 25);
    const KeyName = url.slice(43);
    const FileNameArr = url.split("/");
    this.s3.getObject(
      {
        Bucket: BucketName,
        Key: KeyName,
      },
      (err, data) => {
        if (err) {
          console.log("error 1", err);
        } else {
          let ContentType = data.ContentType;
          if (
            ContentType === "application/pdf" ||
            ContentType === "binary/octet-stream" ||
            ContentType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            //this.showFile(data.Body, FileName);
          } else {
            this.setState({
              ...this.state,
              imageSeal: data.Body,
              imageSealType: ContentType,
            });
          }
        }
      }
    );
  }
  buildDropdownParams(FieldData) {
    return {
      organization_id: this.props.urlFormData.org_id,
      account_id: this.props.urlFormData.account_id,
      workforce_id: this.props.urlFormData.workforce_id,
      asset_id: this.props.urlFormData.asset_id,
      asset_token_auth: this.props.urlFormData.auth_token,
      form_id: FieldData.form_id,
      field_id: FieldData.field_id,
      page_start: 0,
      page_limit: 50,
      service_version: 1.0,
      device_os_id: 5,
      app_version: 2,
    };
  }
  getDropDownField(requestParams) {
    Axiosweb.post("form/field/combo/list", requestParams)
      .then((res) => {
        const FieldDDData = res.data.response;
        this.setState({
          ...this.state,
          fieldDropDown: FieldDDData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  fieldUpdateViewHandler = async (event) => {
    if (Number(event.field_data_type_category_id) === 14) {
      // dropdown get master data

      this.getDropDownField(this.buildDropdownParams(event));
      this.setState({
        ...this.state,
        editedField: event.field_id,
        editedFieldVal:
          event.data_type_combo_id + "|||" + event.data_type_combo_value,
      });
    } else if (
      Number(event.field_data_type_category_id) === 4 ||
      Number(event.field_data_type_category_id) === 1
    ) {
      // dropdown get master data

      if (
        Number(event.field_data_type_id) === 68 ||
        Number(event.field_data_type_id) === 62
      ) {
        let activityRef = await this.requestFieldData(event);
        const inlineData = JSON.parse(activityRef.field_inline_data);
        const { activity_type_id } = inlineData.workflow_reference_restriction;
        this.props.onFetchWorkflows(
          this.buildWorkflowsRequestParams("", activity_type_id),
          "select",
          Number(event.field_data_type_id)
        );
      } else if (Number(event.field_data_type_id) === 69) {
        let assetRef = await this.requestFieldData(event);
        const inlineData = JSON.parse(assetRef.field_inline_data);
        const { asset_type_category_id } =
          inlineData.asset_reference_restriction;
        this.props.onFetchAssets(
          this.buildAssetRequestParams("", asset_type_category_id),
          "select"
        );
      } else if (Number(event.field_data_type_id) === 67) {
        this.props.onFetchWorkflows(
          this.buildWorkflowsRequestParams("", ""),
          "select",
          Number(event.field_data_type_id)
        );
      }

      this.setState({
        ...this.state,
        editedField: event.field_id,
        editedFieldVal: event.field_value,
      });
    } else if (
      Number(event.field_data_type_category_id) === 13 &&
      Number(event.field_data_type_id) === 66
    ) {
      this.setState({
        ...this.state,
        editedField: event.field_id,
        editedFieldVal: event.field_value,
      });
    } else {
      this.setState({
        ...this.state,
        editedField: event.field_id,
        editedFieldVal: event.field_value,
      });
    }

    this.props.reloadComponent(false);
  };
  buildWorkflowRequestParams = (pageStart, srchStr, activity_id = 0) => {
    let txtToSrch = "";
    if (srchStr && srchStr !== "") {
      txtToSrch = this.state.searchString;
    }

    return {
      account_id: this.props.urlFormData.account_id,
      asset_id: this.props.urlFormData.asset_id,
      asset_token_auth: this.props.urlFormData.auth_token,
      organization_id: this.props.urlFormData.org_id,
      page_limit: 50,
      page_start: pageStart,
      search_string: txtToSrch,
      workforce_id: this.props.urlFormData.workforce_id,
      activity_type_id: 0, //activity_id,
      flag_status: 0,
      flag_participating: 0,
    };
  };
  fieldDeleteHandler = (event) => {
    this.setState({
      ...this.state,
      editedField: event.field_id,
      editedFieldVal: "",
      dialogopen: false,
    });
    setTimeout(() => {
      this.fieldEditHandler(event);
    }, 10);
  };
  onChangedHandler = (event, field = null) => {
    if (Number(this.props.fieldData.field_data_type_category_id) === 14) {
      // dropdown get master data
      let optionStr = event.target.value;
      let selectedOptionArr = optionStr.split("|||");
      this.setState({
        ...this.state,
        editedFieldComboId: parseInt(selectedOptionArr[0], 10),
        editedFieldVal: event.target.value,
      });
    } else if (Number(this.props.fieldData.field_data_type_id) === 62) {
      let editedFieldVal = this.state.editedFieldVal;
      if (typeof editedFieldVal === "object") {
        editedFieldVal = this.props.fieldData.field_value;
      }

      editedFieldVal.transaction_data[field] = event.target.value;
      editedFieldVal.transaction_data["transaction_type_name"] =
        field === "transaction_type_id"
          ? event.target.value === 1
            ? "Credit"
            : "Debit"
          : editedFieldVal.transaction_data["transaction_type_name"];
      this.setState({
        ...this.state,
        editedFieldVal: editedFieldVal,
      });
    } else if (
      Number(this.props.fieldData.field_data_type_id) === 65 ||
      Number(this.props.fieldData.field_data_type_id) === 64
    ) {
      let editedFieldVal = this.state.editedFieldVal;
      editedFieldVal = editedFieldVal;
      editedFieldVal[field] = event.target.value;
      this.setState({
        ...this.state,
        editedFieldVal: editedFieldVal,
      });
    } else if (Number(this.props.fieldData.field_data_type_id) === 66) {
      let editedFieldVal = this.state.editedFieldVal;
      editedFieldVal[field] = event.target.value;
      this.setState({
        ...this.state,
        editedFieldVal: editedFieldVal,
      });
    } else if (Number(this.props.fieldData.field_data_type_id) === 67) {
      let editedFieldVal = this.state.editedFieldVal;
      if (field == "workflow_references") editedFieldVal[field] = event;
      else editedFieldVal[field] = event.target.value;
      this.setState({
        ...this.state,
        editedFieldVal: editedFieldVal,
      });
    } else {
      this.setState({
        ...this.state,
        editedFieldVal: event.target.value,
      });
    }
  };
  handleFileChange(selectOption) {
    if (Number(this.props.fieldData.field_data_type_id) === 62) {
      let editedFieldVal = this.state.editedFieldVal;
      editedFieldVal =
        typeof editedFieldVal === "object"
          ? editedFieldVal
          : this.props.fieldData.field_value.transaction_data;
      let selected = selectOption.value.activity_title;
      editedFieldVal.transaction_data["activity_name"] = selectOption.label;
      editedFieldVal.transaction_data["activity_id"] =
        selectOption.value.activity_id;
      this.setState({
        ...this.state,
        editedFieldVal,
      });
    }
  }
  fieldUpdateViewCancelHandler = (event) => {
    this.setState({
      ...this.state,
      editedField: null,
      selectedFiles: [],
    });
    if (this.props.fieldData.field_data_type_id === 66) {
      this.setState({
        ...this.state,
        editedField: null,
        editedFieldVal: this.props.fieldData.field_value,
      });
    }
    this.props.reloadComponent(true);
  };
  handleFileUpload = (accepted, rejected) => {
    if (rejected.length > 0) {
      alert("Some Files format not supported,Please Upload Image");
      return false;
    }
    if (accepted.length > 1) {
      alert("You can upload only one file");
      return false;
    } else {
      this.setState({
        accepted,
        rejected,
        isProgress: 10,
      });
      const orgId = !!this.props.orgId
        ? this.props.orgId
        : this.props.urlFormData.org_id;
      const userData = {
        account_id: this.props.account_id || this.props.urlFormData.account_id,
        workforce_id:
          this.props.workforce_id || this.props.urlFormData.workforce_id,
        asset_id: this.props.asset_id || this.props.urlFormData.asset_id,
      };
      const year = utils.getYear(new Date());
      const month = utils.getMonth(new Date());
      // let bucketName = `worlddesk-${Axios.defaults.bucketName}${year}-${month}`;
      let bucketName = !!this.props.urlFormData
        ? this.props.urlFormData.s3BucketName
        : `worlddesk-${Axios.defaults.bucketName}${year}-${month}`;

      let filename = this.state.accepted[0];
      let blob = filename.slice(0, filename.size, filename.type);
      let newFile = new File([blob], filename.name.replace(/ /gi, "-"), {
        type: blob.type,
      });

      let prefixPath = `${orgId}/${userData.account_id}/${
        userData.workforce_id
      }/${userData.asset_id}/${year}/${month}/103/${utils.getMessageUniqueId(
        userData.asset_id
      )}/${newFile.name}`;

      const params = {
        Bucket: bucketName,
        Key: prefixPath,
        Body: newFile,
        ContentType: newFile.type,
        ACL: "private",
      };

      // uploadFileToS3WithInstance(this.s3, params)
      this.s3
        .putObject(params)
        .promise()
        .then((data) => {
          let location = `https://${bucketName}.s3.ap-south-1.amazonaws.com/${prefixPath}`;
          if (
            this.state.annexureFieldArray.includes(
              this.props.fieldData.field_id + "i"
            )
          ) {
            this.validateExcel(location);
          } else {
            setTimeout(() => {
              if (!this.state.uploaded) {
                this.setState({
                  isProgress: 50,
                });
              }
            }, 1000);
            setTimeout(() => {
              if (!this.state.uploaded) {
                this.setState({
                  isProgress: 80,
                });
              }
            }, 2000);
            if (Number(this.props.fieldData.field_data_type_id) === 66) {
              let editedFieldVal = this.state.editedFieldVal;
              let docUpdateParams = {
                organization_id: this.props.urlFormData.org_id,
                account_id: this.props.urlFormData.account_id,
                workforce_id: this.props.urlFormData.workforce_id,
                asset_id: this.props.urlFormData.asset_id,
                asset_token_auth: this.props.urlFormData.auth_token,
                product: editedFieldVal.product,
                document_title: editedFieldVal.document_title,
                document_desc: editedFieldVal.document_description,
                document_version: editedFieldVal.document_version,
                file_title: filename.name,
                url_path: location,
                device_os_id: 5,
                document_id: editedFieldVal.document_id,
                activity_id: this.props.urlFormData.activity_id,
              };
              editedFieldVal["url_path"] = location;
              this.setState({
                ...this.state,
                editedFieldVal: editedFieldVal,
              });
              this.props.onDocumentUpdate(docUpdateParams);
            } else {
              this.setState({
                ...this.state,
                editedFieldVal: location,
              });
            }

            this.setState({
              isProgress: 100,
              uploaded: true,
              fileName: filename.name,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  async validateExcel(path) {
    let x = await this.buildParams(path);

    if (Number(x.data.status) === 200) {
      this.setState({
        ...this.state,
        editedFieldVal: path,
      });
      this.setState({
        isProgress: 100,
        uploaded: true,
      });
    } else {
      // this.props.inputChangedHandler("", this.props.elementTypeId);

      this.setState({
        isProgress: 0,
        uploaded: false,
        editedFieldVal: "",
        accepted: [],
      });
    }
    return x;
  }

  async buildParams(path) {
    let params = {
      organization_id: this.props.urlFormData.org_id,
      account_id: this.props.urlFormData.account_id,
      workforce_id: this.props.urlFormData.workforce_id,
      asset_id: this.props.urlFormData.asset_id,
      asset_token_auth: this.props.urlFormData.auth_token,
      bucket_url: path,
    };
    try {
      let result = await Axiosweb.post("process/annexure", params);

      return result;
    } catch (err) {
      return err;
    }
  }

  buildFormEditParams(FieldData) {
    let activity_inline_data_build;
    if (Number(FieldData.field_data_type_category_id) === 14) {
      let optionStrVal = this.state.editedFieldVal;
      let selectedOptionArr = optionStrVal.split("|||");
      activity_inline_data_build = [
        {
          form_id: FieldData.form_id,
          form_transaction_id: this.props.urlFormData.form_transaction_id,
          field_id: FieldData.field_id,
          field_value: selectedOptionArr[1],
          data_type_combo_id: this.state.editedFieldComboId,
          form_name: this.props.urlFormData.form_name,
          field_name: this.props.fieldData.field_name,
          field_data_type_id: this.props.fieldData.field_data_type_id,
          data_type_combo_value: selectedOptionArr[1],
          data_type_category_name: "",
          field_data_type_category_id:
            this.props.fieldData.field_data_type_category_id,
        },
      ];
    } else if (
      FieldData.field_data_type_category_id == 4 &&
      [57, 59, 68, 69].includes(parseInt(FieldData.field_data_type_id))
    ) {
      // only for activity & asset multi reference
      let field_value;
      if (
        FieldData.field_data_type_id == 68 ||
        FieldData.field_data_type_id == 69
      ) {
        field_value = JSON.stringify(
          this.props.fieldItem.fileUploadData.map((i) => i.details)
        );
      } else {
        const value = this.props.fieldItem.fileUploadData;
        field_value = value ? Object.values(value.details).join("|") : "";
      }
      activity_inline_data_build = [
        {
          field_value,
          form_id: FieldData.form_id,
          form_transaction_id: this.props.urlFormData.form_transaction_id,
          field_id: FieldData.field_id,
          data_type_combo_id: this.state.editedFieldComboId,
          form_name: this.props.urlFormData.form_name,
          field_name: this.props.fieldData.field_name,
          field_data_type_id: this.props.fieldData.field_data_type_id,
          data_type_combo_value: "",
          data_type_category_name: "",
          field_data_type_category_id:
            this.props.fieldData.field_data_type_category_id,
        },
      ];
    } else {
      activity_inline_data_build = [
        {
          form_id: FieldData.form_id,
          form_transaction_id: this.props.urlFormData.form_transaction_id,
          field_id: FieldData.field_id,
          field_value: this.state.editedFieldVal,
          data_type_combo_id: this.state.editedFieldComboId,
          form_name: this.props.urlFormData.form_name,
          field_name: this.props.fieldData.field_name,
          field_data_type_id: this.props.fieldData.field_data_type_id,
          data_type_combo_value: "",
          data_type_category_name: "",
          field_data_type_category_id:
            this.props.fieldData.field_data_type_category_id,
        },
      ];
    }

    return {
      organization_id: this.props.urlFormData.org_id,
      account_id: this.props.urlFormData.account_id,
      workforce_id: this.props.urlFormData.workforce_id,
      asset_id: this.props.urlFormData.auth_asset_id,
      auth_asset_id: this.props.urlFormData.asset_id,
      asset_token_auth: this.props.urlFormData.auth_token,
      form_transaction_id: this.props.urlFormData.form_transaction_id,
      form_id: FieldData.form_id,
      field_id: FieldData.field_id,
      activity_inline_data: JSON.stringify(activity_inline_data_build),
      activity_id: FieldData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.urlFormData.asset_id
      ),
      track_latitude: 0,
      track_longitude: 0,
      track_gps_accuracy: 0,
      track_gps_status: 0,
      track_gps_location: "Hyderabad",
      track_gps_datetime: utils.getCurrentDateUTC(),
      device_os_id: 5,
      activity_type_category_id: 9,
    };
  }
  fieldEditHandler(FieldData) {
    this.props.onFormFieldEdit(this.buildFormEditParams(FieldData));
    this.checkRPA(FieldData);
    this.setState({
      editedField: null,
      isProgress: 0,
      accepted: [],
      rejected: [],
    });
  }
  checkRPA = (FieldData) => {
    if (
      FieldData.field_inline_data != "{}" &&
      FieldData.field_inline_data != "null"
    ) {
      let parsedInfo = JSON.parse(FieldData.field_inline_data);
      if (!!parsedInfo && typeof parsedInfo.bots === "object") {
        this.setState({
          ...this.state,
          showEdit: false,
        });
        this.props.reloadComponent(false);
        this.editEnable();
      } else {
        this.editEnable();
      }
    } else {
      this.editEnable();
    }
  };

  editEnable = () => {
    let timeSet = setTimeout(() => {
      this.setState({
        ...this.state,
        showEdit: true,
      });
      this.props.reloadComponent(true);
      clearTimeout(timeSet);
    }, 5000);
  };

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };
  showFile = (blob, fileName) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should

    var newBlob = new Blob([blob], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(
      // For Firefox it is necessary to delay revoking the ObjectURL
      () => {
        window.URL.revokeObjectURL(data);
        this.setState({
          ...this.state,
          downloadLoader: false,
        });
      },
      100
    );
  };

  async handleUrlExists(url) {
    this.setState({
      ...this.state,
      downloadLoader: true,
    });
    //const BucketName = url.slice(8, 25);
    //const KeyName = url.slice(43);
    //const FileNameArr = url.split('/');
    //const FileName = FileNameArr[FileNameArr.length - 1];

    const [BucketName, KeyName, FileName] = await utils.getBucketKeyFileName(
      url
    );

    //return false;
    this.s3.getObject(
      {
        Bucket: BucketName,
        Key: KeyName,
      },
      (err, data) => {
        if (err) {
          console.log("error 1", err);
        } else {
          let ContentType = data.ContentType;
          //let objectData = data.Body.toString('utf-8');

          if (ContentType === "application/pdf") {
            this.showFile(data.Body, FileName);
            // setTimeout(
            //     () => {
            //     this.setState({
            //         ...this.state,
            //         open: true,
            //         showPDFModal: true,
            //         imageData: data.Body,
            //         imageType: ContentType,
            //         downloadLoader:false,
            //     })
            //     }, 5000)
          } else if (
            ContentType === "binary/octet-stream" ||
            ContentType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            "application/msword"
          ) {
            this.showFile(data.Body, FileName);
          } else {
            setTimeout(() => {
              this.setState({
                ...this.state,
                open: true,
                showImageModal: true,
                imageData: data.Body,
                imageType: ContentType,
                downloadLoader: false,
              });
            }, 3000);
          }

          /*  */
        }
      }
    );
  }
  onCloseModal = () => {
    this.setState({
      ...this.state,
      open: false,
      showFileCover: false,
      showPrintForm: false,
      showSupportChart: false,
      showFormModal: false,
      showImageModal: false,
      showPDFModal: false,
      imageData: null,
      imageType: null,
    });
  };
  deleteElementClicked = (data) => {
    this.setState({
      ...this.state,
      dialogopen: true,
      deleteElementData: data,
    });
  };
  handleDialogClose = () => {
    this.setState({
      ...this.state,
      dialogopen: false,
      deleteElementData: [],
    });
  };
  handleDialogAgree = () => {
    // this.setState({
    //     ...this.state,
    //     dialogopen: false,
    // });
    this.fieldDeleteHandler(this.state.deleteElementData);
  };
  manageDateSpin = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  manageDateKeyboardSpin = (e) => {
    //if (e.key === "ArrowUp" || e.key === "ArrowDown") {
    e.preventDefault();
    //}
  };
  async requestFieldData(event) {
    try {
      let result = await Axiosweb.post(
        "form/field/list",
        this.buildReferenceRequestParams(event)
      );

      this.setState({
        ...this.state,
        referenceId: result.data.response[0],
      });

      return result.data.response[0];
    } catch (err) {
      return err;
    }
  }

  buildReferenceRequestParams = (event) => {
    return {
      organization_id: this.props.urlFormData.org_id,
      account_id: this.props.urlFormData.account_id,
      workforce_id: 0,
      form_id: event.form_id,
      field_id: event.field_id,
      service_version: 0.1,
      app_version: 1.0,
      flag_status: 0,
      flag_participating: 0,
      asset_id: this.props.urlFormData.asset_id,
      asset_token_auth: this.props.urlFormData.auth_token,
      auth_asset_id: this.props.urlFormData.auth_asset_id,
      device_os_id: 5,
      page_limit: 50,
      page_start: 0,
    };
  };
  inputWorkflowSelectChange = (e) => {
    const srchStr = e;
    clearTimeout(this.state.timer);

    this.setState({
      ...this.state,
      searchString: srchStr,
      timer: setTimeout(() => {
        this.props.onFetchWorkflows(
          this.buildWorkflowsRequestParams(srchStr),
          "select"
        );
      }, 1000),
    });
    //  this.props.onSearchOrgContacts(this.buildUserSearchParams(e));
  };
  inputAssetSelectChange = (e) => {
    const srchStr = e;
    clearTimeout(this.state.timer);

    this.setState({
      ...this.state,
      searchString: srchStr,
      timer: setTimeout(() => {
        this.props.onFetchAssets(
          this.buildAssetRequestParams(srchStr),
          "select"
        );
      }, 1000),
    });
    //  this.props.onSearchOrgContacts(this.buildUserSearchParams(e));
  };
  handleWorkflowChange = (selectedOption) => {
    this.setState({
      ...this.state,
      selectedWorkflow: selectedOption,
    });
    this.props.inputChangedHandler(
      selectedOption,
      `${this.props.fieldData.field_id}i`
    );
  };
  handleAssetChange = (selectedOption) => {
    this.setState({
      ...this.state,
      selectedAsset: selectedOption,
    });
    this.props.inputChangedHandler(
      selectedOption,
      `${this.props.fieldData.field_id}i`
    );
  };

  buildWorkflowsRequestParams = (srchStr, activity_type_id = null) => {
    let txtToSrch = "";
    let idActivityType = -1;
    if (srchStr && srchStr !== "") {
      txtToSrch = this.state.searchString;
    }
    if (this.state.referenceId.hasOwnProperty("field_inline_data")) {
      if (
        JSON.parse(this.state.referenceId.field_inline_data).hasOwnProperty(
          "workflow_reference_restriction"
        )
      )
        idActivityType = JSON.parse(this.state.referenceId.field_inline_data)
          .workflow_reference_restriction.activity_type_id;
    }
    return {
      organization_id: this.props.urlFormData.org_id,
      account_id: this.props.urlFormData.account_id,
      workforce_id: 0,
      search_string: txtToSrch,
      service_version: 0.1,
      app_version: 1.0,
      flag_status: 0,
      flag_participating: 0,
      asset_id: this.props.urlFormData.asset_id,
      asset_token_auth: this.props.urlFormData.auth_token,
      auth_asset_id: this.props.urlFormData.auth_asset_id,
      device_os_id: 5,
      page_limit: 50,
      page_start: 0,
      activity_type_id: !!activity_type_id
        ? activity_type_id
        : this.state.referenceId !== null &&
          !!this.state.referenceId.workflow_reference_restriction
        ? this.state.referenceId.workflow_reference_restriction.activity_type_id
        : 0,
    };
  };

  viewFieldHistory = (fieldData) => {
    this.setState({
      ...this.state,
      showFieldHistory: true,
      selectedFieldData: fieldData,
    });
  };

  onCloseTimeline = () => {
    this.setState({
      ...this.state,
      showFieldHistory: false,
    });
  };

  buildAssetRequestParams = (srchStr, idAssetTypeCategory = 0) => {
    let txtToSrch = "";
    if (srchStr && srchStr !== "") {
      txtToSrch = this.state.searchString;
    }
    if (this.state.referenceId.hasOwnProperty("field_inline_data")) {
      if (
        JSON.parse(this.state.referenceId.field_inline_data).hasOwnProperty(
          "asset_reference_restriction"
        )
      )
        idAssetTypeCategory = JSON.parse(
          this.state.referenceId.field_inline_data
        ).asset_reference_restriction.asset_type_category_id;
    }
    return {
      organization_id: this.props.urlFormData.org_id,
      account_id: this.props.urlFormData.account_id,
      workforce_id: 0,
      search_string: txtToSrch,
      service_version: 0.1,
      app_version: 1.0,
      flag_status: 0,
      flag_participating: 0,
      asset_id: this.props.urlFormData.asset_id,
      asset_token_auth: this.props.urlFormData.auth_token,
      auth_asset_id: this.props.urlFormData.auth_asset_id,
      device_os_id: 5,
      page_limit: 50,
      page_start: 0,
      asset_type_category_id: idAssetTypeCategory,
    };
  };
  checkSingleSelection = () => {
    try {
      let inlineData = JSON.parse(this.props.fieldData.field_inline_data);
      let keys = Object.keys(inlineData.bots);
      let keys2 = Object.keys(inlineData.bots[keys[0]].bot_operations);
      if (
        inlineData.bots[keys[0]].bot_operations[keys2[0]]
          .bot_operation_type_id == 18
      )
        return false;
    } catch (error) {
      return true;
    }
    return true;
  };

  getBotTypeId = (data) => {
    let bot_id = 0;
    for (var i in data) {
      for (var j in data[i]) {
        for (var k in data[i][j]) {
          for (var l in data[i][j][k]) {
            for (var m in data[i][j][k][l]) {
              if (m == "bot_operation_type_id") {
                bot_id = data[i][j][k][l][m];
              }
            }
          }
        }
      }
    }
    return bot_id;
  };

  fieldEditEnable = (FieldData) => {
    if (!!this.props.fieldItem) {
      const {
        form_workflow_activity_type_id,
        form_id,
        field_id,
        field_value_edit_enabled,
      } = this.props.fieldItem;
      if (this.props.isSmartForm && FieldData.field_data_type_id == 33) {
        // single select with smart form edit disabled
        return null;
      } else if (
        this.props.urlFormData.isFormDisabled &&
        !(
          fieldEditConfig[form_workflow_activity_type_id] &&
          fieldEditConfig[form_workflow_activity_type_id][form_id] &&
          fieldEditConfig[form_workflow_activity_type_id][form_id][field_id] &&
          this.props.userData.asset_id === this.props.urlFormData.lead_asset_id
        )
      ) {
        return null;
      } else if (
        !this.state.editedField &&
        ((fieldEditConfig[form_workflow_activity_type_id] &&
          fieldEditConfig[form_workflow_activity_type_id][form_id] &&
          fieldEditConfig[form_workflow_activity_type_id][form_id][field_id] &&
          this.props.userData.asset_id ===
            this.props.urlFormData.lead_asset_id) ||
          (!!field_value_edit_enabled &&
            this.state.showEdit &&
            this.props.showEditAll))
      ) {
        return (
          <button
            type="button"
            onClick={() => this.fieldUpdateViewHandler(FieldData)}
            style={{
              float: "right",
              borderWidth: 0,
              backgroundColor: "transparent",
              color: "#000",
              margin: 0,
            }}
          >
            <EditIcon size={20} style={{ fontSize: "medium" }} />
          </button>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  handleFileUploadv1 = async (file, type) => {
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], file.name.replace(/ /gi, "-"), {
      type: blob.type,
    });
    const {
      orgId,
      userData: { account_id, workforce_id, asset_id },
    } = this.props;

    const year = utils.getYear(new Date());
    const month = utils.getMonth(new Date());
    const bucketName = `worlddesk-${Axios.defaults.bucketEnv}${year}-${month}`;
    let prefixPath =
      "858/974/5353/31476/2018/11/103/" + utils.getMessageUniqueId(12);

    const config = {
      bucketName: bucketName,
      dirName: prefixPath /* optional */,
      region: "ap-south-1",
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    };

    return new Promise((resolve, reject) => {
      S3Client.uploadFile(newFile, config)
        .then((data) => {
          if (data.result.ok) {
            data.location = `https://${data.bucket}.s3.amazonaws.com/${data.key}`;
            resolve([file.name, data.location, 1]);
          }
        })
        .catch((err) => {
          console.error(err);
          resolve(["", "", 0]);
        });
    });
  };

  handleDropboxFileUpload = async (accepted, rejected) => {
    this.setState({
      ...this.state,
      isEditing: true,
    });

    if (rejected.length > 0) {
      alert("Some Files format not supported,Please Upload valid format");
      return false;
    } else {
      let dropboxFiles = [];
      this.setState({
        selectedFiles: [...this.state.selectedFiles, ...accepted],
        rejected,
        isProgress: 10,
      });

      for (let file of this.state.selectedFiles) {
        const [name, url, status] = await this.handleFileUploadv1(
          file,
          this.getFileType(file)
        );
        if (status === 1) {
          dropboxFiles.push({
            file_name: name,
            file_url: url,
          });
        } else if (status === 0) {
          console.log("Unable to upload the image to S3");
        }
      }
      this.setState({
        ...this.state,
        isProgress: 100,
        editedFieldVal: JSON.stringify(dropboxFiles),
      });
      console.log(dropboxFiles, "dropboxFiles");
    }
  };

  getFileType = (file) => {
    const fileType = file.type ? file.type.toLowerCase() : "";
    if (
      !!["gif", "jpg", "jpeg", "png", "ico"].find((item) =>
        fileType.includes(item)
      )
    ) {
      return "image";
    } else if (!!["pdf"].find((item) => fileType.includes(item))) {
      return "pdf";
    } else if (
      !![
        "xlsx",
        "xlsm",
        "xlsb",
        "xltx",
        "xltm",
        "xls",
        "csv",
        "xlc",
        "sheet",
        "application/vnd.ms-excel",
      ].find((item) => fileType.includes(item))
    ) {
      return "sheet";
    } else if (
      !!["word", "msword", "doc", "txt", "docx"].find((item) =>
        fileType.includes(item)
      )
    ) {
      return "doc";
    } else if (
      !!["eml", "mail", "msg", "message", "oft", "ost"].find((item) =>
        fileType.includes(item)
      ) ||
      !fileType
    ) {
      return "mail";
    } else if (
      !!["mspowerpoint", "powerpoint", "mspowerpoint", "presentation"].find(
        (item) => fileType.includes(item)
      )
    ) {
      return "ppt";
    } else if (
      !!["mp4", "mpg", "mkv", "avi", "mov", "wmv"].find((item) =>
        fileType.includes(item)
      )
    ) {
      return "video";
    } else if (
      !!["zip", "x-7z", "x-xz"].find((item) => fileType.includes(item))
    ) {
      return "zip";
    } else if (!!["plain"].find((item) => fileType.includes(item))) {
      return "text";
    }
    console.log("fileType", fileType);
    return "attach";
  };

  handleRemoveAttachment = async (index) => {
    this.setState(
      {
        selectedFiles: this.state.selectedFiles.filter((x, i) => i !== index),
        isProgress: 20,
      },
      async () => {
        let dropboxFiles = [];
        for (let file of this.state.selectedFiles) {
          const [name, url, status] = await this.handleFileUploadv1(
            file,
            this.getFileType(file)
          );
          if (status === 1) {
            dropboxFiles.push({
              file_name: name,
              file_url: url,
            });
          } else if (status === 0) {
            console.log("Unable to upload the image to S3");
          }
        }
        this.setState({
          ...this.state,
          isProgress: 100,
          editedFieldVal: JSON.stringify(dropboxFiles),
        });
        console.log(dropboxFiles, "dropboxFiles");
      }
    );
  };

  render() {
    const { classes } = this.props;
    let ModalContent = null;
    if (this.state.showImageModal) {
      let fileData =
        "data:" +
        this.state.imageType +
        ";base64," +
        this.encode(this.state.imageData);
      ModalContent = <img src={fileData} alt="image_preview" />;
    }
    if (this.state.showPDFModal) {
      let sealImage =
        "data:" +
        this.state.imageSealType +
        ";base64," +
        this.encode(this.state.imageSeal);
      ModalContent = (
        <PdfViewer imageSeal={sealImage} dataPdf={this.state.imageData} />
      );
    }
    if (this.state.dialogopen) {
      return (
        <Dialog
          open={this.state.dialogopen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.onCloseModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Are You Sure You want to delete this field?"}
          </DialogTitle>
          <DialogContent id="alert-dialog-slide-content">
            <DialogContentText id="alert-dialog-slide-description">
              Once Field is deleted this will be deleted for ever Anyways you
              can edit and update.
            </DialogContentText>
          </DialogContent>
          <DialogActions id="alert-dialog-slide-action">
            <Button onClick={this.handleDialogClose} color="primary">
              Disagree
            </Button>
            <Button onClick={this.handleDialogAgree} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    if (this.state.showFieldHistory) {
      return (
        <Modal
          showCloseIcon={true}
          open={this.state.showFieldHistory}
          onClose={this.onCloseTimeline}
          center
        >
          <FieldTimelineHistory
            urlFormData={this.props.urlFormData}
            fieldData={this.state.selectedFieldData}
          />
        </Modal>
      );
    }

    let FieldData = this.props.fieldData;
    let inputElement = null;
    let inputElementEdit = null;
    let dataTypeCatId = 0;
    let dataTypeId = 0;
    let fieldValue = null;
    let fieldName = "";
    let rule = null;
    let fieldValueEditEnabled = null;
    let editButton = this.fieldEditEnable(FieldData);
    const disabled = !!editButton;

    if (this.props.validation) {
      dataTypeCatId = FieldData.elementCatType;
      fieldValue = FieldData.value;
      fieldName = utils.getCleanValue(FieldData.label);
      rule = FieldData.validation.rules;
      fieldValueEditEnabled = FieldData.field_value_edit_enabled
        ? FieldData.field_value_edit_enabled
        : null;
    } else {
      dataTypeCatId = FieldData.field_data_type_category_id;
      dataTypeId = Number(FieldData.field_data_type_id);
      if (FieldData.field_data_type_id == 68) {
        try {
          fieldValue = JSON.parse(FieldData.field_value)
            .map((item) => item.activity_title)
            .join(", ");
        } catch (e) {
          fieldValue = FieldData.field_value;
        }
      } else if (FieldData.field_data_type_id == 69) {
        try {
          fieldValue = JSON.parse(FieldData.field_value)
            .map(
              (item) =>
                `${item.asset_first_name} (${item.operating_asset_first_name})`
            )
            .join(", ");
        } catch (e) {
          fieldValue = FieldData.field_value;
        }
      } else {
        fieldValue =
          FieldData.data_type_combo_id !== "0"
            ? FieldData.field_value === "undefined"
              ? FieldData.field_data_type_combo_value
              : FieldData.field_value
            : FieldData.field_value;
      }
      fieldName = utils.getCleanValue(FieldData.field_name);
      fieldValueEditEnabled = FieldData.field_value_edit_enabled
        ? FieldData.field_value_edit_enabled
        : null;
    }
    let editableElement;
    if (
      fieldValueEditEnabled === 1 &&
      this.state.showEdit &&
      this.props.showEditAll &&
      this.checkSingleSelection()
    ) {
      editableElement = (
        <span className="div_span_edit">
          <FaEdit
            size="20px"
            color="#222222"
            onClick={() => this.fieldUpdateViewHandler(FieldData)}
          />
        </span>
      );
    } else {
      editableElement = null;
    }
    let viewHistoryButton;
    if (this.state.showEdit && this.props.showEditAll) {
      viewHistoryButton = (
        <span className="div_span_history">
          <FaHistory
            size="20px"
            color="#222222"
            onClick={() => this.viewFieldHistory(FieldData)}
          />
        </span>
      );
    } else {
      viewHistoryButton = null;
    }
    switch (Number(dataTypeCatId)) {
      case 1:
        if (dataTypeId === 2) {
          //Future Date
          inputElementEdit = (
            <div className="field_edit_content">
              <input
                className="textbox no-spin"
                type="date"
                value={this.state.editedFieldVal}
                onChange={this.onChangedHandler}
                onKeyDown={this.manageDateKeyboardSpin}
                min={utils.getAddToCurrentDate(
                  utils.getCurrentDateISTWidgetDash(),
                  1
                )}
              />
            </div>
          );

          inputElement = (
            <div className="field_det_content">
              {fieldValue}
              {editableElement}
              {viewHistoryButton}
            </div>
          );
        } else if (dataTypeId === 3) {
          //Past Date
          inputElementEdit = (
            <div className="field_edit_content">
              <input
                className="textbox no-spin"
                type="date"
                value={this.state.editedFieldVal}
                onChange={this.onChangedHandler}
                onKeyDown={this.manageDateKeyboardSpin}
                max={utils.getAddToCurrentDate(
                  utils.getCurrentDateISTWidgetDash(),
                  0
                )}
              />
            </div>
          );

          inputElement = (
            <div className="field_det_content">
              {fieldValue}
              {editableElement}
              {viewHistoryButton}
            </div>
          );
        } else if (dataTypeId === 4) {
          //// Date and Time
          inputElementEdit = (
            <div className="field_edit_content">
              <input
                className="textbox no-spin"
                type="datetime-local"
                value={this.state.editedFieldVal}
                onChange={this.onChangedHandler}
                onKeyDown={this.manageDateSpin}
              />
            </div>
          );

          inputElement = (
            <div className="field_det_content">
              {fieldValue}
              {editableElement}
              {viewHistoryButton}
            </div>
          );
        } else if (dataTypeId === 67) {
          //reminder data type
          let {
            message,
            alert_timestamp,
            prereminder_alert_duration_hours,
            workflow_references,
          } = fieldValue;
          let editValue = this.state.editedFieldVal;
          if (!!workflow_references)
            workflow_references = workflow_references.map((wr) => {
              let values = wr.split("|");
              return { label: values[1], value: wr };
            });
          inputElement = (
            <div>
              <div className="field_det_content">
                {message} {editableElement}
                {viewHistoryButton}{" "}
              </div>
              <div className="field_det_content">{alert_timestamp}</div>
              <div className="field_det_content">
                {prereminder_alert_duration_hours}
              </div>
              <Select
                isDisabled={true}
                isMulti={true}
                // onChange={(e) => this.onFieldUpdate(e,'workflow_references')}
                value={workflow_references}
                options={this.props.workflowListRef.workflowListOptions}
                placeholder={"workflow References"}
              />
            </div>
          );
          inputElementEdit = (
            <div className="field_edit_content">
              <input
                className="textbox margin-5"
                value={editValue.message}
                onChange={(e) => {
                  this.onChangedHandler(e, "message");
                }}
                type="text"
                placeholder={"message"}
              />
              <input
                className="textbox margin-5"
                value={editValue.alert_timestamp}
                onChange={(e) => {
                  this.onChangedHandler(e, "alert_timestamp");
                }}
                type="datetime-local"
                placeholder={"alert timestamp"}
              />
              <input
                className="textbox margin-5"
                value={editValue.prereminder_alert_duration_hours}
                onChange={(e) => {
                  this.onChangedHandler(e, "prereminder_alert_duration_hours");
                }}
                type="datetime-local"
                placeholder={"prereminder alert duration hours"}
              />
              <Select
                isMulti={true}
                onChange={(e) =>
                  this.onChangedHandler(e, "workflow_references")
                }
                value={workflow_references}
                options={this.props.workflowListRef.workflowListOptions}
                placeholder={"workflow References"}
              />
            </div>
          );
        } else {
          inputElementEdit = (
            <div className="field_edit_content">
              <input
                className="textbox no-spin"
                type="date"
                value={this.state.editedFieldVal}
                onKeyDown={this.manageDateSpin}
                onChange={this.onChangedHandler}
              />
            </div>
          );
          inputElement = (
            <div className="field_det_content">
              {fieldValue}
              {editableElement}
              {viewHistoryButton}
            </div>
          );
        }

        break;
      case 2:
        inputElementEdit = (
          <div className="field_edit_content">
            <input
              className="textbox"
              type="number"
              value={this.state.editedFieldVal}
              onChange={this.onChangedHandler}
            />
          </div>
        );
        inputElement = (
          <div className="field_det_content">
            {fieldValue}
            {editableElement}
            {viewHistoryButton}
          </div>
        );
        break;

      case 4:
        // multi activity reference
        if (dataTypeId === 68 || dataTypeId === 57) {
          let idActivityType = 0;
          if (this.state.referenceId.hasOwnProperty("field_inline_data")) {
            if (
              JSON.parse(
                this.state.referenceId.field_inline_data
              ).hasOwnProperty("workflow_reference_restriction")
            )
              idActivityType = JSON.parse(
                this.state.referenceId.field_inline_data
              ).workflow_reference_restriction.activity_type_id;
          }
          let workflowOptions = [];
          let isMulti = !!(dataTypeId === 68);
          if (this.props.workflowListRef.activity_type_id === idActivityType) {
            workflowOptions = this.props.workflowListRef.workflowListOptions;
          }
          inputElementEdit = (
            <div className="field_edit_content">
              <GreneAutoComplete
                config={{
                  name: "reference_search",
                  helperText: "Search by workflow name",
                  isSearchable: true,
                  isMulti: isMulti,
                  className: "reference_search",
                }}
                value={this.state.selectedWorkflow}
                options={workflowOptions}
                onInputChange={this.inputWorkflowSelectChange}
                onSelectionChange={this.handleWorkflowChange}
              />
            </div>
          );
          if (dataTypeId === 68 && typeof fieldValue === "object") {
            inputElement = (
              <div className="field_det_content">
                {JSON.stringify(fieldValue)}
                {editableElement}
                {viewHistoryButton}
              </div>
            );
          } else {
            inputElement = (
              <div className="field_det_content">
                {fieldValue}
                {editableElement}
                {viewHistoryButton}
              </div>
            );
          }
        }
        // multi asset reference
        if (dataTypeId === 69 || dataTypeId === 59) {
          let asset_type_category_id = 0;
          if (this.state.referenceId.hasOwnProperty("field_inline_data")) {
            if (
              JSON.parse(
                this.state.referenceId.field_inline_data
              ).hasOwnProperty("asset_reference_restriction")
            )
              asset_type_category_id = JSON.parse(
                this.state.referenceId.field_inline_data
              ).asset_reference_restriction.asset_type_category_id;
          }
          let assetOptions = [];
          if (
            this.props.assetListRef.asset_type_category_id ===
            asset_type_category_id
          ) {
            assetOptions = this.props.assetListRef.assetListOptions;
          }
          let isMulti = !!(dataTypeId === 69);
          inputElementEdit = (
            <div className="field_edit_content">
              <GreneAutoComplete
                config={{
                  name: "asset_search",
                  helperText: "Search by Resource name",
                  isSearchable: true,
                  isMulti: isMulti,
                  className: "reference_search",
                }}
                value={this.state.selectedAsset}
                options={assetOptions}
                onInputChange={this.inputAssetSelectChange}
                onSelectionChange={this.handleAssetChange}
              />
            </div>
          );
          if (typeof fieldValue === "object") {
            inputElement = (
              <div className="field_det_content">
                {JSON.stringify(fieldValue)}
                {editableElement}
                {viewHistoryButton}
              </div>
            );
          } else {
            inputElement = (
              <div className="field_det_content">
                {fieldValue}
                {editableElement}
                {viewHistoryButton}
              </div>
            );
          }
        }
        // credit / debit data type
        if (dataTypeId === 62) {
          let fieldProceesData = {};
          if (typeof fieldValue === "string")
            fieldProceesData =
              fieldValue === "" ? null : JSON.parse(fieldValue);
          else fieldProceesData = fieldValue;
          if (fieldProceesData && fieldProceesData.transaction_data) {
            let {
              activity_name,
              transaction_amount,
              transaction_note,
              transaction_type_id,
              transaction_type_name,
            } = fieldProceesData.transaction_data;
            let editedData = this.state.editedFieldVal.transaction_data
              ? this.state.editedFieldVal.transaction_data
              : {};
            inputElement = (
              <div>
                <div className="field_det_content">
                  {activity_name} {editableElement}
                  {viewHistoryButton}
                </div>
                <div className="field_det_content">{transaction_type_name}</div>
                <div className="field_det_content">{transaction_amount}</div>
                <div className="field_det_content">{transaction_note}</div>
              </div>
            );
            inputElementEdit = (
              <div>
                <Select
                  isSearchable={true}
                  // onInputChange={(e)=>this.inputWorkflowSelectChange(e,Number(this.props.elementType))}
                  className="reference_search margin-5"
                  style={{ color: "#fff" }}
                  onChange={(e) => {
                    this.handleFileChange(e);
                  }}
                  options={this.props.workflowListCredit}
                  value={
                    editedData.activity_name
                      ? {
                          label: editedData.activity_name,
                          value: editedData.activity_id,
                        }
                      : ""
                  }
                  name="lead_search"
                  placeholder="Search by Workflow name"
                />
                <select
                  className="textbox margin-5"
                  value={
                    editedData.transaction_type_id
                      ? editedData.transaction_type_id
                      : ""
                  }
                  onChange={(e) => {
                    this.onChangedHandler(e, "transaction_type_id");
                  }}
                >
                  <option value="-1">Select</option>
                  <option value="1">Credit</option>
                  <option value="2">Debit</option>
                </select>
                <input
                  className="textbox margin-5"
                  value={
                    editedData.transaction_amount
                      ? editedData.transaction_amount
                      : ""
                  }
                  onChange={(e) => {
                    this.onChangedHandler(e, "transaction_amount");
                  }}
                  type="number"
                  placeholder={"Amount"}
                />
                <textarea
                  onChange={(e) => {
                    this.onChangedHandler(e, "transaction_note");
                  }}
                  className="textarea_view margin-5"
                  placeholder="notes"
                  value={
                    editedData.transaction_note
                      ? editedData.transaction_note
                      : ""
                  }
                ></textarea>
              </div>
            );
          } else {
            let editedData = this.state.editedFieldVal.transaction_data
              ? this.state.editedFieldVal.transaction_data
              : {};
            inputElement = (
              <div>
                <div className="field_det_content">{""}</div>
                <div className="field_det_content">{""}</div>
                <div className="field_det_content">{""}</div>
                <div className="field_det_content">{""}</div>
              </div>
            );
            inputElementEdit = (
              <div>
                <Select
                  isSearchable={true}
                  // onInputChange={(e)=>this.inputWorkflowSelectChange(e,Number(this.props.elementType))}
                  className="reference_search margin-5"
                  style={{ color: "#fff" }}
                  onChange={(e) => {
                    this.handleFileChange(e);
                  }}
                  options={this.props.workflowListCredit}
                  value={
                    editedData.activity_name
                      ? {
                          label: editedData.activity_name,
                          value: editedData.activity_id,
                        }
                      : ""
                  }
                  name="lead_search"
                  placeholder="Search by Workflow name"
                />
                <select
                  className="textbox margin-5"
                  value={
                    editedData.transaction_type_id
                      ? editedData.transaction_type_id
                      : ""
                  }
                  onChange={(e) => {
                    this.onChangedHandler(e, "transaction_type_id");
                  }}
                >
                  <option value="-1">Select</option>
                  <option value="1">Credit</option>
                  <option value="2">Debit</option>
                </select>
                <input
                  className="textbox margin-5"
                  value={
                    editedData.transaction_amount
                      ? editedData.transaction_amount
                      : ""
                  }
                  onChange={(e) => {
                    this.onChangedHandler(e, "transaction_amount");
                  }}
                  type="number"
                  placeholder={"Amount"}
                />
                <textarea
                  onChange={(e) => {
                    this.onChangedHandler(e, "transaction_note");
                  }}
                  className="textarea_view margin-5"
                  placeholder="notes"
                  value={
                    editedData.transaction_note
                      ? editedData.transaction_note
                      : ""
                  }
                ></textarea>
              </div>
            );
          }
        }
        if (dataTypeId === 71) {
          /////
          let fieldProcessData = {};
          if (typeof fieldValue === "string") {
            fieldProcessData =
              fieldValue === "" ? null : JSON.parse(fieldValue);
          } else fieldProcessData = fieldValue;
          if (fieldProcessData) {
            let {
              product_tag_name,
              product_activity_type_name,
              product_activity_title,
              cart_total_cost,
              cart_items,
            } = fieldProcessData;
            if (typeof cart_items === "string") {
              cart_items = JSON.parse(cart_items);
            }
            let fieldValues = JSON.parse(fieldValue);
            inputElement = (
              <div>
                <div className="field_det_content">{product_tag_name}</div>
                <div className="field_det_content">
                  {product_activity_type_name}
                </div>
                <div className="field_det_content">
                  {product_activity_title}
                </div>
                <table
                  className="table"
                  style={{
                    display:
                      !!cart_items && cart_items.length > 0
                        ? "inline-table"
                        : "none",
                    color: "#000",
                  }}
                >
                  <thead>
                    <tr>
                      <th scope="col">Variant Name</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Monthly Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!cart_items &&
                    Array.isArray(cart_items) &&
                    cart_items.length > 0
                      ? cart_items.map((variant, index) => {
                          return (
                            <tr key={index}>
                              <td>{variant.product_variant_activity_title}</td>
                              <td>{variant.product_variant_quantity}</td>
                              <td>{variant.product_variant_unit_price}</td>
                            </tr>
                          );
                        })
                      : ""}
                    <tr>
                      <td>Total Monthly Cart Value</td>
                      <td></td>
                      <td>{cart_total_cost}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
            let productCategoryOptions = this.props.productCategoryList;
            let productWorkflowTypes = this.props.productWorkflowTypes;
            let productWorkflows = this.props.productWorkflows;
            let productVariantsProps = this.props.productVariants;
            let productVariants = productVariantsProps;
            if (!!this.state.selectedProductWorkflow) {
              productVariants = [
                ...[
                  {
                    details: {
                      activity_cuid_2: null,
                      activity_cuid_3: "0",
                    },
                    label: "Custom variant",
                    value: 0,
                  },
                ],
                ...productVariantsProps,
              ];
            }

            inputElementEdit = (
              <ProductCart
                urlData={this.props.urlFormData}
                changed={this.props.changed}
                values={fieldValues}
              />
            );
          } else {
            let value = "";
            try {
              value =
                typeof fieldValue === "object"
                  ? fieldValue.inline_data.activity_title
                  : fieldValue;
            } catch (error) {
              value =
                typeof fieldValue === "object"
                  ? JSON.stringify(fieldValue)
                  : "";
            }

            inputElement = <div className="field_det_content">{value}</div>;
          }
        }
        break;
      case 5:
        if (Number(FieldData.field_data_type_id) === 17) {
          const fieldValue = FieldData.field_value.includes("{")
            ? JSON.parse(FieldData.field_value)
            : FieldData.field_value;
          if (fieldValue.hasOwnProperty("location_data")) {
            inputElement = (
              <div className="field_det_content">
                {fieldValue.location_data.location_latitude +
                  " , " +
                  fieldValue.location_data.location_longitude}
              </div>
            );
          } else {
            inputElement = (
              <div className="field_det_content">{fieldValue}</div>
            );
          }
        } else {
          inputElement = <div className="field_det_content">{fieldValue}</div>;
        }
        break;

      case 7:
        if (dataTypeId === "64") {
          let editedFieldVal = {};
          try {
            fieldValue = JSON.parse(fieldValue || "{}");
            editedFieldVal = JSON.parse(editedFieldVal || "{}");
          } catch (error) {
            editedFieldVal = this.state.editedFieldVal || {};
          }
          inputElementEdit = (
            <div className="field_edit_content">
              <label>Office Unit No : </label>
              <input
                className="textbox"
                placeholder="office unit number"
                value={editedFieldVal.office_unit_number}
                onChange={(e) => this.onChangedHandler(e, "office_unit_number")}
              />
              <label>Floor No : </label>
              <input
                className="textbox"
                placeholder="floor number"
                value={editedFieldVal.floor_number}
                onChange={(e) => this.onChangedHandler(e, "floor_number")}
              />
              <label>Building Name : </label>
              <input
                className="textbox"
                placeholder="building name"
                value={editedFieldVal.building_name}
                onChange={(e) => this.onChangedHandler(e, "building_name")}
              />
              <label>Street No : </label>
              <input
                className="textbox"
                placeholder="street number"
                value={editedFieldVal.street_number}
                onChange={(e) => this.onChangedHandler(e, "street_number")}
              />
              <label>Street Name : </label>
              <input
                className="textbox"
                placeholder="street name"
                value={editedFieldVal.street_name}
                onChange={(e) => this.onChangedHandler(e, "street_name")}
              />
              <label>Area Name : </label>
              <input
                className="textbox"
                placeholder="area name"
                value={editedFieldVal.area_name}
                onChange={(e) => this.onChangedHandler(e, "area_name")}
              />
              <label>City : </label>
              <input
                className="textbox"
                placeholder="city"
                value={editedFieldVal.city}
                onChange={(e) => this.onChangedHandler(e, "city")}
              />
              <label>Pincode : </label>
              <input
                className="textbox"
                placeholder="pincode"
                value={editedFieldVal.pincode}
                onChange={(e) => this.onChangedHandler(e, "pincode")}
              />
              <label>State : </label>
              <input
                className="textbox"
                placeholder="state"
                value={editedFieldVal.state}
                onChange={(e) => this.onChangedHandler(e, "state")}
              />
            </div>
          );
          inputElement = (
            <div className="field_det_content">
              <label>Office Unit No : </label>
              {fieldValue.office_unit_number}
              {editableElement}
              {viewHistoryButton}
              <br />
              <label>Floor No : </label>
              {fieldValue.floor_number}
              <br />
              <label>Building Name : </label>
              {fieldValue.building_name}
              <br />
              <label>Street No : </label>
              {fieldValue.street_number}
              <br />
              <label>Street Name : </label>
              {fieldValue.street_name}
              <br />
              <label>Area Name : </label>
              {fieldValue.area_name}
              <br />
              <label>City : </label>
              {fieldValue.city}
              <br />
              <label>Pincode : </label>
              {fieldValue.pincode}
              <br />
              <label>State : </label>
              {fieldValue.state}
              <br />
              {fieldValue.editableElement}
              {fieldValue.viewHistoryButton}
            </div>
          );
        } else {
          inputElementEdit = (
            <div className="field_edit_content">
              <input
                className="textbox"
                value={this.state.editedFieldVal}
                onChange={this.onChangedHandler}
              />
            </div>
          );
          inputElement = (
            <div className="field_det_content">
              {fieldValue}
              {editableElement}
              {viewHistoryButton}
            </div>
          );
        }
        break;
      case 8:
        inputElement = <div className="">{fieldName}</div>;
        break;
      case 11:
        inputElementEdit = (
          <div>
            <Dropzone
              className="upload_dropzone_fields"
              accept="image/jpeg, image/png"
              onDrop={(accepted, rejected) =>
                this.handleFileUpload(accepted, rejected)
              }
            >
              <div className="uploaded_files" style={{ width: "100%" }}>
                {this.state.accepted.map((filedata) => {
                  return (
                    <div key={filedata.name} className="thumbnail_dropzone">
                      <img
                        src={
                          filedata.type === "application/pdf"
                            ? require("../../../assets/images/Pdf_thumb.png")
                            : filedata.preview
                        }
                        alt={filedata.name}
                      />
                    </div>
                  );
                })}
              </div>

              {this.state.accepted.length === 0 ? (
                <p className="upload_dropzone_title">
                  Try dropping some files here, or click to select files to
                  upload.
                </p>
              ) : null}
            </Dropzone>
            {this.state.isProgress > 0 ? (
              <ProgressBar
                style={{ width: "100%" }}
                striped
                bsStyle="success"
                now={this.state.isProgress}
              />
            ) : null}
          </div>
        );
        inputElement = (
          <div className="field_det_content_img">
            {fieldValue !== "" ? (
              <span
                className="cursor_pointer"
                onClick={() => this.handleUrlExists(fieldValue)}
              >
                <img
                  style={{ height: "100px" }}
                  src={require("../../../assets/images/image_thumbnail.png")}
                  alt="thumbnail"
                />
              </span>
            ) : (
              ""
            )}
            {editableElement}
            {this.state.downloadLoader ? (
              <div className={classes.root}>
                <LinearProgress />
              </div>
            ) : (
              ""
            )}
          </div>
        );
        if (this.state.showImageModal) {
          return (
            <Modal
              showCloseIcon={false}
              open={this.state.open}
              onClose={this.onCloseModal}
              center
            >
              {ModalContent}
            </Modal>
          );
        }
        break;

      case 12:
        inputElementEdit = (
          <div>
            <Dropzone
              className="upload_dropzone_fields"
              accept="image/jpeg, image/png"
              onDrop={(accepted, rejected) =>
                this.handleFileUpload(accepted, rejected)
              }
            >
              <div className="uploaded_files" style={{ width: "100%" }}>
                {this.state.accepted.map((filedata) => {
                  return (
                    <div key={filedata.name} className="thumbnail_dropzone">
                      <img
                        src={
                          filedata.type === "application/pdf"
                            ? require("../../../assets/images/Pdf_thumb.png")
                            : filedata.preview
                        }
                        alt={filedata.name}
                      />
                    </div>
                  );
                })}
              </div>

              {this.state.accepted.length === 0 ? (
                <p className="upload_dropzone_title">
                  Try dropping some files here, or click to select files to
                  upload.
                </p>
              ) : null}
            </Dropzone>
            {this.state.isProgress > 0 ? (
              <ProgressBar
                style={{ width: "100%" }}
                striped
                bsStyle="success"
                now={this.state.isProgress}
              />
            ) : null}
          </div>
        );
        inputElement = (
          <div className="field_det_content_img">
            {fieldValue !== "" ? (
              <span
                className="cursor_pointer"
                onClick={() => this.handleUrlExists(fieldValue)}
              >
                <img
                  style={{ height: "100px" }}
                  src={require("../../../assets/images/image_thumbnail.png")}
                  alt="thumbnail"
                />
              </span>
            ) : (
              ""
            )}
            {editableElement}
            {this.state.downloadLoader ? (
              <div className={classes.root}>
                <LinearProgress />
              </div>
            ) : (
              ""
            )}
          </div>
        );
        if (this.state.showImageModal) {
          return (
            <Modal
              showCloseIcon={false}
              open={this.state.open}
              onClose={this.onCloseModal}
              center
            >
              {ModalContent}
            </Modal>
          );
        }

        break;

      case 13:
        if (Number(FieldData.field_data_type_id) === 52) {
          inputElementEdit = (
            <div>
              <Dropzone
                className="upload_dropzone_fields"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onDrop={(accepted, rejected) =>
                  this.handleFileUpload(accepted, rejected)
                }
              >
                <div className="uploaded_files" style={{ width: "100%" }}>
                  {this.state.accepted.map((filedata) => {
                    return (
                      <div key={filedata.name} className="thumbnail_dropzone">
                        <img
                          src={
                            filedata.type ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ? require("../../../assets/images/Excel_Thumbnail.png")
                              : filedata.preview
                          }
                          alt={filedata.name}
                        />
                      </div>
                    );
                  })}
                </div>

                {this.state.accepted.length === 0 ? (
                  <p className="upload_dropzone_title">
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                ) : null}
              </Dropzone>
              {this.state.isProgress > 0 ? (
                <ProgressBar
                  style={{ width: "100%" }}
                  striped
                  bsStyle="success"
                  now={this.state.isProgress}
                />
              ) : null}
            </div>
          );
          inputElement = (
            <div className="field_det_content_img">
              {fieldValue !== "" ? (
                <span
                  className="cursor_pointer"
                  onClick={() => this.handleUrlExists(fieldValue)}
                >
                  <img
                    style={{ height: "100px" }}
                    src={require("../../../assets/images/Excel_Thumbnail.png")}
                    alt="thumbnail"
                  />
                </span>
              ) : (
                ""
              )}
              {fieldValue !== "" &&
              !annexureJson.hasOwnProperty(this.props.fieldData.field_id) ? (
                <aux>
                  {editableElement}
                  <span className="div_span_edit">
                    <img
                      className="div_span_img_edit"
                      title="Delete"
                      onClick={() => this.deleteElementClicked(FieldData)}
                      src={deleteList}
                      alt={this.props.fieldData.field_id + "img"}
                    />
                  </span>
                </aux>
              ) : (
                ""
              )}
              {fieldValue === "" ? <aux>{editableElement}</aux> : ""}
              {this.state.downloadLoader ? (
                <div className={classes.root}>
                  <LinearProgress />
                </div>
              ) : (
                ""
              )}
            </div>
          );
          break;
        } else if (Number(FieldData.field_data_type_id) === 55) {
          inputElementEdit = (
            <div>
              <Dropzone
                className="upload_dropzone_fields"
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                onDrop={(accepted, rejected) =>
                  this.handleFileUpload(accepted, rejected)
                }
              >
                <div className="uploaded_files" style={{ width: "100%" }}>
                  {this.state.accepted.map((filedata) => {
                    return (
                      <div key={filedata.name} className="thumbnail_dropzone">
                        <img
                          src={
                            filedata.type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            filedata.type === "application/msword"
                              ? require("../../../assets/images/word_thumbnail.png")
                              : filedata.preview
                          }
                          alt={filedata.name}
                        />
                      </div>
                    );
                  })}
                </div>

                {this.state.accepted.length === 0 ? (
                  <p className="upload_dropzone_title">
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                ) : null}
              </Dropzone>
              {this.state.isProgress > 0 ? (
                <ProgressBar
                  style={{ width: "100%" }}
                  striped
                  bsStyle="success"
                  now={this.state.isProgress}
                />
              ) : null}
            </div>
          );
          inputElement = (
            <div className="field_det_content_img">
              {fieldValue !== "" ? (
                <span
                  className="cursor_pointer"
                  onClick={() => this.handleUrlExists(fieldValue)}
                >
                  <img
                    style={{ height: "100px" }}
                    src={require("../../../assets/images/word_thumbnail.png")}
                    alt="world thumbnail"
                  />
                </span>
              ) : (
                ""
              )}
              {editableElement}
              {fieldValue !== "" ? (
                <span className="div_span_edit">
                  <img
                    className="div_span_img_edit"
                    title="Delete"
                    onClick={() => this.deleteElementClicked(FieldData)}
                    src={deleteList}
                    alt={this.props.fieldData.field_id + "img"}
                  />
                </span>
              ) : (
                ""
              )}
              {this.state.downloadLoader ? (
                <div className={classes.root}>
                  <LinearProgress />
                </div>
              ) : (
                ""
              )}
            </div>
          );
          break;
        } else if (Number(FieldData.field_data_type_id) === 56) {
          inputElementEdit = (
            <div>
              <Dropzone
                className="upload_dropzone_fields"
                onDrop={(accepted, rejected) =>
                  this.handleFileUpload(accepted, rejected)
                }
              >
                <div className="uploaded_files" style={{ width: "100%" }}>
                  {this.state.accepted.map((filedata) => {
                    return (
                      <div key={filedata.name} className="thumbnail_dropzone">
                        <img
                          src={require("../../../assets/images/outlook_thumbnail.png")}
                          alt={filedata.name}
                        />
                      </div>
                    );
                  })}
                </div>

                {this.state.accepted.length === 0 ? (
                  <p className="upload_dropzone_title">
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                ) : null}
              </Dropzone>
              {this.state.isProgress > 0 ? (
                <ProgressBar
                  style={{ width: "100%" }}
                  striped
                  bsStyle="success"
                  now={this.state.isProgress}
                />
              ) : null}
            </div>
          );
          inputElement = (
            <div className="field_det_content_img">
              {fieldValue !== "" ? (
                <span
                  className="cursor_pointer"
                  onClick={() => this.handleUrlExists(fieldValue)}
                >
                  <img
                    style={{ height: "100px" }}
                    src={require("../../../assets/images/outlook_thumbnail.png")}
                    alt="outlook thumbnail"
                  />
                </span>
              ) : (
                ""
              )}
              {editableElement}
              {fieldValue !== "" ? (
                <span className="div_span_edit">
                  <img
                    className="div_span_img_edit"
                    title="Delete"
                    onClick={() => this.deleteElementClicked(FieldData)}
                    src={deleteList}
                    alt={this.props.fieldData.field_id + "img"}
                  />
                </span>
              ) : (
                ""
              )}
              {this.state.downloadLoader ? (
                <div className={classes.root}>
                  <LinearProgress />
                </div>
              ) : (
                ""
              )}
            </div>
          );
          break;
        } else if (Number(FieldData.field_data_type_id) === 65) {
          // business card
          let editedFieldVal = {};
          try {
            fieldValue = JSON.parse(fieldValue);
            editedFieldVal = JSON.parse(this.state.editedFieldVal || "{}");
          } catch (error) {
            editedFieldVal = this.state.editedFieldVal || {};
          }
          inputElementEdit = (
            <div className="field_edit_content">
              <label>First Name : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_name_first}
                onChange={(e) =>
                  this.onChangedHandler(e, "customer_name_first")
                }
              />
              <label>Last Name : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_name_last}
                onChange={(e) => this.onChangedHandler(e, "customer_name_last")}
              />
              <label>Phone Number : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_phone_number}
                onChange={(e) =>
                  this.onChangedHandler(e, "customer_phone_number")
                }
              />
              <label>Email : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_email}
                onChange={(e) => this.onChangedHandler(e, "customer_email")}
              />
              <label>CUID : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_cuid}
                onChange={(e) => this.onChangedHandler(e, "customer_cuid")}
              />
              <label>Gender : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_gender}
                onChange={(e) => this.onChangedHandler(e, "customer_gender")}
              />
              <label>Industry : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_industry}
                onChange={(e) => this.onChangedHandler(e, "customer_industry")}
              />
              <label>Location : </label>
              <input
                className="textbox"
                value={editedFieldVal.customer_location}
                onChange={(e) => this.onChangedHandler(e, "customer_location")}
              />
            </div>
          );
          inputElement = (
            <div className="field_det_content test">
              <label>Contact Name : </label>
              {fieldValue.contact_name}
              <br />
              <label>Contact Email : </label>
              {fieldValue.contact_email}
              <br />
              <label>Phone : </label>
              {fieldValue.contact_phone}
              <br />
              <label>Alternative phone : </label>
              {fieldValue.contact_alternative_phone}
              <br />
              <label>Landline : </label>
              {fieldValue.contact_landline}
              <br />
              <label>Organization : </label>
              {fieldValue.contact_organization}
              <br />
              <label>Designation : </label>
              {fieldValue.contact_designation}
              <br />
              <label>Fax : </label>
              {fieldValue.contact_fax}
              <br />
              <label>Location : </label>
              {fieldValue.contact_address
                ? Object.values(fieldValue.contact_address).join(", ")
                : ""}
              <br />
            </div>
          );
        } else if (Number(FieldData.field_data_type_id) === 66) {
          let editedFieldVal = {};
          let productReference = {};
          try {
            editedFieldVal = JSON.parse(this.state.editedFieldVal || "{}");
            productReference = editedFieldVal.product_reference
              ? JSON.parse(editedFieldVal.product_reference)
              : "{}";
          } catch (error) {
            editedFieldVal = this.state.editedFieldVal || {};
            productReference = editedFieldVal.product_reference
              ? JSON.parse(editedFieldVal.product_reference)
              : "{}";
          }
          inputElementEdit = (
            <div className="field_edit_content">
              <input
                className="textbox"
                placeholder="Product Reference"
                value={productReference.activity_name}
                disabled
              />
              <input
                className="textbox"
                autoFocus={true}
                placeholder="Document Title"
                value={editedFieldVal.document_title}
                onChange={(e) => this.onChangedHandler(e, "document_title")}
              />
              <input
                className="textbox"
                placeholder="Document Description"
                value={editedFieldVal.document_description}
                onChange={(e) =>
                  this.onChangedHandler(e, "document_description")
                }
              />
              <input
                className="textbox"
                placeholder="Document Version"
                value={editedFieldVal.document_version}
                onChange={(e) => this.onChangedHandler(e, "document_version")}
              />
              <div>
                {" "}
                <Dropzone
                  className="upload_dropzone_fields"
                  accept="image/jpeg, image/png ,application/pdf"
                  onDrop={(accepted, rejected) =>
                    this.handleFileUpload(accepted, rejected)
                  }
                >
                  <div className="uploaded_files" style={{ width: "100%" }}>
                    {this.state.accepted.map((filedata) => {
                      return (
                        <div key={filedata.name} className="thumbnail_dropzone">
                          <img
                            src={require("../../../assets/images/outlook_thumbnail.png")}
                            alt={filedata.name}
                          />
                        </div>
                      );
                    })}
                  </div>

                  {this.state.accepted.length === 0 ? (
                    <p className="upload_dropzone_title">
                      Try dropping some files here, or click to select files to
                      upload.
                    </p>
                  ) : null}
                </Dropzone>
                {this.state.isProgress > 0 ? (
                  <ProgressBar
                    style={{ width: "100%" }}
                    striped
                    bsStyle="success"
                    now={this.state.isProgress}
                  />
                ) : null}
              </div>
              <input
                className="textbox"
                placeholder="Filename"
                disabled
                value={this.state.fileName}
              />
            </div>
          );
          let fieldParams = {};
          let product_reference = {};
          try {
            fieldParams = JSON.parse(fieldValue || "{}");
            product_reference = JSON.parse(
              fieldParams.product_reference
                ? fieldParams.product_reference
                : "{}"
            );
          } catch (error) {
            fieldParams = fieldValue || {};
            product_reference = JSON.parse(
              fieldValue.product_reference ? fieldValue.product_reference : "{}"
            );
          }

          console.log("fieldParams", fieldParams);
          console.log("productReference", product_reference);
          inputElement = (
            <div className="field_det_content test">
              {product_reference.activity_name}
              {editableElement}
              {viewHistoryButton}
              <br />
              {fieldParams.document_title}
              <br />
              {fieldParams.document_description}
              <br />
              {fieldParams.document_version}
              <div className="field_det_content_img">
                {fieldValue !== "" ? (
                  <span
                    className="cursor_pointer"
                    onClick={() => this.handleUrlExists(fieldParams.url_path)}
                  >
                    <img
                      style={{ height: "100px" }}
                      src={require("../../../assets/images/Pdf_thumb.png")}
                      alt="pdf thumbnail"
                    />
                  </span>
                ) : (
                  ""
                )}
                {this.state.downloadLoader ? (
                  <div className={classes.root}>
                    <LinearProgress />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {fieldParams.file_title}
            </div>
          );
        } else if (Number(FieldData.field_data_type_id) === 76) {
          inputElementEdit = (
            <div>
              <Dropzone
                className="upload_dropzone_fields"
                accept={supportedFiles}
                onDrop={
                  disabled
                    ? null
                    : (accepted, rejected) =>
                        this.handleDropboxFileUpload(accepted, rejected)
                }
              >
                <p className="upload_dropzone_title">
                  Try dropping some files here, or click to select files to
                  upload.
                </p>
              </Dropzone>
              {this.state.isProgress > 0 ? (
                <ProgressBar
                  style={{ width: "100%" }}
                  striped
                  bsStyle="success"
                  now={this.state.isProgress}
                />
              ) : null}
              {this.state.selectedFiles.map((file, index) => (
                <div className="dragfiles" key={String(index)}>
                  <div className="file-details">
                    <img
                      src={fileTypeIcons[this.getFileType(file)]}
                      alt=""
                      className=""
                    />
                    <p style={{ color: "#000000" }}>{file.name}</p>
                  </div>
                  <div className="minus_icon">
                    <img
                      src={trash}
                      alt=""
                      className=""
                      onClick={() => this.handleRemoveAttachment(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          );
          let dropboxFiles = [];
          if (fieldValue !== "" && typeof fieldValue === "string") {
            dropboxFiles = JSON.parse(fieldValue);
          } else {
            dropboxFiles = fieldValue;
          }
          inputElement = (
            <div className="field_det_content_img">
              {fieldValue !== "" ? (
                <div style={{ color: "#000" }}>
                  {Array.isArray(dropboxFiles) &&
                    dropboxFiles.map((files, index) => {
                      return (
                        <div key={index}>
                          <p
                            className="cursor_pointer"
                            onClick={() => this.handleUrlExists(files.file_url)}
                          >
                            <FaRegFilePdf
                              style={{ fontSize: "30px", marginRight: "10px" }}
                              alt="file thumbnail"
                            />
                            {files.file_name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              ) : (
                ""
              )}

              {this.props.urlFormData.isFormDisabled ||
              (this.props.isSmartForm && dataTypeCatId == 14) ? null : !!this
                  .props.fieldItem &&
                !!this.props.fieldItem.field_value_edit_enabled &&
                fieldValue !== "" ? (
                <span
                  className="div_span_edit"
                  onClick={() => this.deleteElementClicked(FieldData)}
                >
                  <FaRegTrashAlt
                    className="div_span_img_edit"
                    title="Delete"
                    alt={this.props.fieldData.field_id + "img"}
                  />
                </span>
              ) : (
                ""
              )}
              {this.state.downloadLoader ? (
                <div className={classes.root}>
                  <LinearProgress />
                </div>
              ) : (
                ""
              )}
            </div>
          );
          break;
        } else {
          inputElementEdit = (
            <div>
              <Dropzone
                className="upload_dropzone_fields"
                accept="image/jpeg, image/png ,application/pdf"
                onDrop={(accepted, rejected) =>
                  this.handleFileUpload(accepted, rejected)
                }
              >
                <div className="uploaded_files" style={{ width: "100%" }}>
                  {this.state.accepted.map((filedata) => {
                    return (
                      <div key={filedata.name} className="thumbnail_dropzone">
                        <img
                          src={
                            filedata.type === "application/pdf"
                              ? require("../../../assets/images/Pdf_thumb.png")
                              : filedata.preview
                          }
                          alt={filedata.name}
                        />
                      </div>
                    );
                  })}
                </div>

                {this.state.accepted.length === 0 ? (
                  <p className="upload_dropzone_title">
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                ) : null}
              </Dropzone>
              {this.state.isProgress > 0 ? (
                <ProgressBar
                  style={{ width: "100%" }}
                  striped
                  bsStyle="success"
                  now={this.state.isProgress}
                />
              ) : null}
            </div>
          );
          inputElement = (
            <div className="field_det_content_img">
              {fieldValue !== "" ? (
                <span
                  className="cursor_pointer"
                  onClick={() => this.handleUrlExists(fieldValue)}
                >
                  <img
                    style={{ height: "100px" }}
                    src={require("../../../assets/images/Pdf_thumb.png")}
                    alt="pdf thumbnail"
                  />
                </span>
              ) : (
                ""
              )}
              {editableElement}
              {fieldValue !== "" ? (
                <span className="div_span_edit">
                  <img
                    className="div_span_img_edit"
                    title="Delete"
                    onClick={() => this.deleteElementClicked(FieldData)}
                    src={deleteList}
                    alt={this.props.fieldData.field_id + "img"}
                  />
                </span>
              ) : (
                ""
              )}
              {this.state.downloadLoader ? (
                <div className={classes.root}>
                  <LinearProgress />
                </div>
              ) : (
                ""
              )}
            </div>
          );
          if (this.state.showPDFModal) {
            return (
              <Modal
                showCloseIcon={false}
                open={this.state.open}
                onClose={this.onCloseModal}
                center
              >
                {ModalContent}
              </Modal>
            );
          }

          break;
        }
        break;
      case 14:
        let bot_inline_data = JSON.parse(FieldData.field_inline_data);
        let bot_type_id = this.getBotTypeId(bot_inline_data);
        console.log(bot_inline_data, bot_type_id, "FieldData");
        inputElement = (
          <div className="field_det_content">
            {fieldValue}
            {Number(bot_type_id) === 18 ? null : editableElement}
            {viewHistoryButton}
          </div>
        );
        inputElementEdit = (
          <select
            className="textbox"
            value={this.state.editedFieldVal}
            onChange={this.onChangedHandler}
            disabled={Number(bot_type_id) === 18 ? true : false}
          >
            {this.state.fieldDropDown.map((option) => (
              <option
                key={option.data_type_combo_id}
                value={
                  option.data_type_combo_id +
                  "|||" +
                  option.data_type_combo_value
                }
              >
                {option.data_type_combo_value}
              </option>
            ))}
          </select>
        );
        break;
      case 15:
        inputElement = <div className="field_det_content">{fieldValue}</div>;
        break;
      case 21:
        if (Number(FieldData.field_data_type_id) === 53) {
          if (
            FieldData.field_value !== null &&
            FieldData.field_value.hasOwnProperty("ip_address_data")
          ) {
            inputElement = (
              <div className="field_det_content">
                {FieldData.field_value.ip_address_data.ip_address}
              </div>
            );
          } else {
            inputElement = (
              <div className="field_det_content">{fieldValue}</div>
            );
          }
        } else {
          inputElement = <div className="field_det_content">{fieldValue}</div>;
        }
        break;
      default:
        inputElementEdit = (
          <div className="field_edit_content">
            <input
              className="textbox"
              value={this.state.editedFieldVal}
              onChange={this.onChangedHandler}
            />
          </div>
        );
        inputElement = (
          <div className="field_det_content">
            {fieldValue}
            {editableElement}
            {viewHistoryButton}
          </div>
        );
        break;
    }
    let RowData = (
      <tr key={this.props.fieldData.field_id + "name"}>
        {Number(dataTypeCatId) === 8 ? (
          <td colSpan="4">
            <div className="labelField">{inputElement}</div>
          </td>
        ) : (
          [
            <td key="21" className="tdleft tdleft_caf">
              {fieldName}
            </td>,
            <td key="2321" className="tdright tdright_caf">
              {this.props.fieldData.field_id === this.state.editedField
                ? inputElementEdit
                : inputElement}
            </td>,
            <td key="8762" className="tdattach">
              {this.props.fieldData.field_id === this.state.editedField ? (
                <div
                  onClick={() => this.fieldEditHandler(FieldData)}
                  className="div_img_attach"
                >
                  <img
                    src={checkImg}
                    alt={this.props.fieldData.field_id + "img"}
                  />
                </div>
              ) : (
                ""
              )}
            </td>,
            <td key="213" className="tdattach">
              {this.props.fieldData.field_id === this.state.editedField ? (
                <div
                  onClick={() => this.fieldUpdateViewCancelHandler(FieldData)}
                  className="div_action"
                >
                  <img
                    src={CancelRed}
                    alt={this.props.fieldData.field_id + "img"}
                  />
                </div>
              ) : (
                ""
              )}
            </td>,
          ]
        )}
      </tr>
    );
    if (this.props.validation) {
      RowData = (
        <tr>
          <td key="21" className="tdleft tdleft_caf">
            {fieldName}
          </td>
          <td key="2321" className="tdright tdright_caf">
            {inputElement}
          </td>
          <td key="212423" className="tdicon">
            <div className="div_img_alert">
              <img
                src={!this.props.fieldData.valid ? alertImg : checkImg}
                alt={this.props.fieldData.field_id + "img"}
              />
            </div>
          </td>
          <td key="8762" className="tdattach">
            <div
              onClick={() => this.props.filePreview(rule)}
              className="div_img_attach"
            >
              <img
                src={require("../../../assets/images/Icons/attachment.png")}
                alt={this.props.fieldData.field_id + "img"}
              />
            </div>
          </td>
          <td key="213" className="tdattach">
            <div
              onClick={() => this.props.fieldApprove(FieldData)}
              className="div_action"
            >
              {!this.props.fieldData.valid ? "Approve" : ""}
            </div>
          </td>
        </tr>
      );
    }
    return RowData;
  }
}

const mapStateToProps = (state) => {
  return {
    accId: state.loginAuth.accId,
    formFieldAlter: state.queue.formFieldAlter,
    formDataValues: state.queue.formDataValues,
    workflowListCredit: state.files.workflowListCredit,
    workflowListRef: state.files.workflowListRef,
    assetListRef: state.files.assetListRef,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFormFieldEdit: (requestData) =>
      dispatch(actions.alterFormField(requestData)),
    getDropDownField: (requestData) =>
      dispatch(actions.getDropDownDefination(requestData)),
    inputChangedHandler: (event, formElementId, eleType) =>
      dispatch(actions.formFileFieldInputChange(event, formElementId, eleType)),
    onFetchWorkflows: (requestData, reference, target = null) =>
      dispatch(actions.workflowsFetch(requestData, reference, target)),
    onFetchAssets: (requestData, reference) =>
      dispatch(actions.assetsFetch(requestData, reference)),
    onDocumentUpdate: (requestData) =>
      dispatch(actions.updateDocumentInRepo(requestData)),
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FieldMapping)
);
