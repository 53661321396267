import config from "react-global-configuration";
import AWS from "aws-sdk";
// import {cognitoSession} from "./Utils";

export const s3AccessKey = () => {
    return config.get("s3_access_key");
};

export const s3SecretKey = () => {
    return config.get("s3_secret_key");
};

// export const setAwsConfig = () => {
//     AWS.config.region = "ap-south-1";
//     if(sessionStorage.getItem("isLoginOtp")) {
//         let cognitoSessionData = JSON.parse(
//             sessionStorage.getItem("cognitoSessionData")
//         );
//         const accessToken = cognitoSessionData.accessToken;
//         AWS.config.update({
//             credentials: new AWS.CognitoIdentityCredentials({
//                 IdentityPoolId: process.env.REACT_APP_OTP_IDENTITY_POOL_ID,
//                 Logins: {
//                     [`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_OTP_IDENTITY_POOL_ID}`]: accessToken.jwtToken,
//                 },
//             }),
//         });
//     } else {
//         cognitoSession()
//             .then((res) => {
//                 const accessToken = res.getAccessToken();
//                 console.log("session data",accessToken);
//                 AWS.config.update({
//                     credentials: new AWS.CognitoIdentityCredentials({
//                         IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//                         Logins: {
//                             [`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_IDENTITY_POOL_ID}`]: accessToken.jwtToken,
//                         },
//                     }),
//                 });
//             })
//             .catch((err) => {
//                 console.log(err);
//             });
//     }
// };

export const getS3Instance = () => {
    return new AWS.S3();
};

export const uploadFileToS3 = async (params) => {
    const s3Instance = getS3Instance();
    try {
        await s3Instance.putObject(params).promise();
        return true;
    } catch(error) {
        throw new Error(error.message);
    }
};

export const uploadFileToS3WithInstance = async (s3,params) => {
    try {
        await s3.putObject(params).promise();
        return true;
    } catch(error) {
        throw new Error(error.message);
    }
};

export const downloadS3File = async (params) => {
    const s3Instance = getS3Instance();
    try {
        let result = await s3Instance.getObject(params).promise();
        return result;
    } catch(error) {
        console.log("S3 download!",error);
        throw new Error(error.message);
    }
};

export const downloadS3FileWithInstance = async (s3,params) => {
    // const s3Instance = getS3Instance();
    try {
        let result = await s3.getObject(params).promise();
        return result;
    } catch(error) {
        console.log("S3 download!",error);
        throw new Error(error.message);
    }
};
