import * as actionTypes from './actionTypes';
import Axios from 'axios';
import AxiosWeb from '../../axios_ext';
import * as actions from './index';
import * as utils from '../../shared/Utils'

export const formsListFetchStart = () => {
    return {
        type: actionTypes.FORMS_LIST_FETCH_START,
    };
};
export const formsListFetchSuccess = (formListData,mode) => {
    return {
        type: actionTypes.FORMS_LIST_FETCH_SUCCESS,
        formListData: formListData,
        mode: mode
    };
};


export const formsListFetch = (requestData,mode) => {
    return dispatch => {
        dispatch(formsListFetchStart(requestData));
        Axios.post('workforceFormMappingSelectFormType',requestData)
            .then(res => {
                let formData = res.data.response.data;
                if(mode && mode === "options") {
                    let formDataAray = [];
                    for(let form of formData) {
                        const JsonForm = {
                            label: form.form_name,
                            value: {
                                form_id: form.form_id,
                                form_name: form.form_name,
                                form_type_category_id: form.form_type_category_id,
                                form_type_id: form.form_type_id,
                                organization_id: form.organization_id,
                                workforce_id: form.workforce_id,
                                account_id: form.account_id
                            }

                        }
                        formDataAray.push(JsonForm);
                    }
                    formData = formDataAray;
                }
                dispatch(formsListFetchSuccess(formData,mode))
            })

    }
}

export const formFieldListFetchStart = () => {
    return {
        type: actionTypes.FORMS_FIELD_LIST_FETCH_START,
    };
};
export const formFieldListFetchSuccess = (fieldData) => {
    return {
        type: actionTypes.FORMS_FIELD_LIST_FETCH_SUCCESS,
        fieldData: fieldData
    };
};
export const saveAccessLoginDetailsSuccess = (fieldData) => {
    return {
        type: actionTypes.FORMS_ACCESS_LOG_ADD_SUCCESS,
        accessData: fieldData
    };
};
export const formNameFetch = (formName) => {
    return {
        type: actionTypes.FORMS_FORMNAME_FETCH_SUCCESS,
        formName: formName
    };
};


export const formSubmitStart = () => {
    return {
        type: actionTypes.FORMS_SUBMIT_START,
    };
};
export const formSubmitSuccess = () => {
    return {
        type: actionTypes.FORMS_SUBMIT_SUCCESS,
    };
};

export const handleContentAppendToTimeline = (timelineData) => {
    return {
        type: actionTypes.FILE_TIMELINE_APPEND,
        contentData: timelineData
    }
}

export const alterFormFileStatus = (requestData) => {
    return dispatch => {
        requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
        AxiosWeb.post("activity/status/alter",requestData)
            .then(res => {
                dispatch(formSubmitSuccess());

            })
            .catch(err => {
                console.log(err);
                //dispatch(tasksFetchFail(err));
            })
    }
};


export const addFormFileTimelineEntry = (requestData) => {
    return dispatch => {

        requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
        AxiosWeb.post("activity/timeline/entry/add",requestData)
            .then(res => {
                dispatch(formSubmitSuccess());
                // dispatch(alterFormFileStatus(requestData)); hiding alter status for form Submittion
            })
            .catch(err => {
                console.log(err);
            })

    }
};

export const addFormFileTimelineEntryOnly = (requestData) => {
    return dispatch => {
        requestData.activity_id = requestData.file_activity_id;
        requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
        AxiosWeb.post("activity/timeline/entry/add",requestData)
            .then(res => {
                dispatch(actions.fileSetParticipant(requestData));
                if(requestData.update_timeline === 1) {
                    dispatch(handleContentAppendToTimeline(requestData)); //If adding from file to update timeline
                }

            })
            .catch(err => {
                console.log(err);
            })
    }
};

export const addWorkflowActivity = (requestData) => {
    return dispatch => {
        let newRequestData = Object.assign({},requestData);
        newRequestData.activity_type_category_id = 48;
        newRequestData.activity_type_id = requestData.workflow_activity_type_id;
        dispatch(formSubmitStart());
        const fetchDataStr = newRequestData;
        AxiosWeb.post('activity/add/v1',fetchDataStr)
            .then(res => {
                const activity_id = res.data.response.activity_id;
                if(res.data.status === 200) {
                    newRequestData.activity_id = activity_id;
                    newRequestData.workflow_activity_id = activity_id;
                    newRequestData.message_unique_id = utils.getMessageUniqueId(newRequestData.asset_id);

                    setTimeout(() => {
                        dispatch(addFormFileTimelineEntry(newRequestData));
                    },2000);

                    /*console.log("saveFormDetails Data", activity_id)
                    if (requestData.file_activity_id !== 0) {
                        setTimeout(() => {
                            dispatch(addFormFileTimelineEntryOnly(requestData))
                        }, 2000);
                    }*/
                }
            })
            .catch(err => {
                console.log(err);
                //dispatch(tasksFetchFail(err));
            })
    }
};

export const saveFormDetails = (requestData) => {
    return dispatch => {
        dispatch(formSubmitStart());
        const fetchDataStr = requestData;
        AxiosWeb.post('activity/add/v1',fetchDataStr)
            .then(res => {
                const activity_id = res.data.response.activity_id;
                const form_transaction_id = res.data.response.form_transaction_id;
                if(res.data.status === 200) {
                    requestData.activity_id = activity_id;
                    requestData.form_transaction_id = form_transaction_id;
                    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);

                    if(requestData.create_workflow === 1) {
                        dispatch(addWorkflowActivity(requestData));
                    }

                    setTimeout(() => {
                        dispatch(addFormFileTimelineEntry(requestData));
                    },2000);

                    if(requestData.file_activity_id !== 0) {
                        setTimeout(() => {
                            dispatch(addFormFileTimelineEntryOnly(requestData))
                        },2000);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                //dispatch(tasksFetchFail(err));
            })

    }
}

export const saveAccessLoginDetails = (requestData) => {
    return dispatch => {
        const fetchDataStr = requestData;
        AxiosWeb.post('activity/add/v1',fetchDataStr)
            .then(res => {
                const activity_id = res.data.response.activity_id;
                const form_transaction_id = res.data.response.form_transaction_id;
                if(res.data.status === 200) {
                    requestData.activity_id = activity_id;
                    requestData.form_transaction_id = form_transaction_id;
                    localStorage.setItem('access_activity_id',requestData.activity_id);
                    localStorage.setItem('access_form_transaction_id',requestData.form_transaction_id);
                    // requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
                    // let accessDataAray = [];
                    // accessDataAray.push(res.data.response);
                    // dispatch(saveAccessLoginDetailsSuccess(accessDataAray))
                }
            })
            .catch(err => {
                console.log(err);
                //dispatch(tasksFetchFail(err));
            })

    }
}

export const formFieldListFetch = (requestData,draftData,entry = null,tempArray = {}) => {
    return dispatch => {
        if(Object.keys(tempArray).length <= 1) {
            requestData.page_start = requestData.start_from;
            requestData.page_limit = requestData.limit_value;
            dispatch(formFieldListFetchStart(requestData));
        }
        requestData.page_start = requestData.start_from;
        requestData.page_limit = requestData.limit_value;
        AxiosWeb.post('form/access/global/entry/collection',requestData)
            .then(res => {
                if(res.data.response.hasOwnProperty('data')) {
                    const formFieldData = res.data.response.data;
                    dispatch(formNameFetch(formFieldData[0].form_name));
                    console.log("draft inline data",draftData);
                    const urlDecodedDraftData = !!draftData && draftData.length > 0 ? JSON.parse(draftData[0].form_draft_inline_data) : null;
                    console.log("decoded data",urlDecodedDraftData);
                    formFieldData.map(field => {
                        let fieldValue = '';
                        if(!!draftData && draftData.length > 0) {
                            fieldValue = urlDecodedDraftData.find(draftField => {
                                if(draftField.field_id == field.field_id) {
                                    return draftField.value
                                }
                            })
                        }
                        console.log("field values inside action",fieldValue);
                        if(field.data_type_combo_id > 0) {
                            let optionsJson = {
                                name: field.data_type_combo_value,
                                value: field.data_type_combo_value //field.data_type_combo_id
                            }
                            if(tempArray.hasOwnProperty(field.field_id + 'i')) {
                                tempArray[field.field_id + 'i'] = {
                                    ...field,
                                    label: field.field_name,
                                    elementFieldId: field.field_id,
                                    elementCatType: field.data_type_category_id,
                                    elementType: field.data_type_id,
                                    elementTypeName: field.data_type_name,
                                    elementConfig: {
                                        options: [...tempArray[field.field_id + 'i'].elementConfig.options,optionsJson]
                                    },
                                    value: tempArray[field.field_id + 'i'].elementConfig.options[0].value,
                                    validation: {
                                        required: !!field.field_mandatory_enabled,
                                        rules: field.field_inline_data,
                                    },
                                    valid: false,
                                    touched: false,
                                    validationEnabled: field.field_validation_enabled,
                                    fileUploadData: '',
                                    fieldSequenceId: field.field_sequence_id,
                                    nextFieldId: field.next_field_id + 'i'
                                }
                            }
                            else {
                                tempArray[field.field_id + 'i'] = {
                                    ...field,
                                    label: field.field_name,
                                    elementFieldId: field.field_id,
                                    elementCatType: field.data_type_category_id,
                                    elementType: field.data_type_id,
                                    elementTypeName: field.data_type_name,
                                    elementConfig: {
                                        options: [optionsJson]
                                    },
                                    value: optionsJson.value,
                                    validation: {
                                        required: !!field.field_mandatory_enabled,
                                        rules: field.field_inline_data,
                                    },
                                    valid: false,
                                    touched: false,
                                    validationEnabled: field.field_validation_enabled,
                                    fileUploadData: '',
                                    fieldSequenceId: field.field_sequence_id,
                                    nextFieldId: field.next_field_id + 'i'
                                }
                            }

                        }
                        else {
                            tempArray[field.field_id + 'i'] = {
                                ...field,
                                label: field.field_name,
                                elementFieldId: field.field_id,
                                elementCatType: field.data_type_category_id,
                                elementType: field.data_type_id,
                                elementTypeName: field.data_type_name,
                                elementConfig: {
                                    placeholder: 'Enter ' + field.field_name,

                                },
                                value: fieldValue !== '' ? fieldValue.value : '',
                                validation: {
                                    required: !!field.field_mandatory_enabled,
                                    rules: field.field_inline_data,
                                },
                                valid: false,
                                touched: false,
                                validationEnabled: field.field_validation_enabled,
                                fileUploadData: '',
                                fieldSequenceId: field.field_sequence_id,
                                nextFieldId: field.next_field_id + 'i'
                            }
                        }

                        //tempArray = {...tempArray , field.field_id :  }
                    })
                    if(formFieldData.length === requestData.limit_value) {

                        requestData.start_from = requestData.start_from + requestData.limit_value;
                        dispatch(formFieldListFetch(requestData,entry,tempArray))
                    }
                    else {
                        dispatch(formFieldListFetchSuccess(tempArray))
                        if(entry && entry === 'validation') {
                            dispatch(actions.getCAFValidationData(requestData));
                        }
                        else if(entry === 'view') {
                            dispatch(getCAFViewData(requestData));
                        }
                    }
                }
                else {
                    dispatch(formFieldListFetchSuccess(tempArray));
                    if(entry && entry === 'validation') {
                        dispatch(actions.getCAFValidationData(requestData));
                    }
                    else if(entry === 'view') {
                        dispatch(getCAFViewData(requestData));
                    }
                }

            })

    }
}
export const formFieldListFetchCRF = (requestData,entry = null,tempArray = {}) => {
    return dispatch => {
        if(Object.keys(tempArray).length <= 1) {
            requestData.page_start = requestData.start_from;
            requestData.page_limit = requestData.limit_value;
            dispatch(formFieldListFetchStart(requestData));
        }
        requestData.page_start = requestData.start_from;
        requestData.page_limit = requestData.limit_value;
        AxiosWeb.post('form/access/global/entry/collection',requestData)
            .then(res => {
                if(res.data.response.hasOwnProperty('data')) {
                    const formFieldData = res.data.response.data;
                    formFieldData.map(field => {

                        if(field.data_type_combo_id > 0) {
                            let optionsJson = {
                                name: field.data_type_combo_value,
                                value: field.data_type_combo_value //field.data_type_combo_id
                            }
                            if(tempArray.hasOwnProperty(field.field_id + 'i')) {
                                tempArray[field.field_id + 'i'] = {
                                    label: field.field_name,
                                    elementCatType: field.data_type_category_id,
                                    elementType: field.data_type_id,
                                    elementTypeName: field.data_type_name,
                                    elementConfig: {
                                        options: [JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(tempArray[field.field_id + 'i'])).elementConfig)).options,optionsJson]
                                    },
                                    value: '',
                                    validation: {
                                        required: !!field.field_mandatory_enabled,
                                        rules: field.field_inline_data,
                                    },
                                    valid: false,
                                    touched: false,
                                    validationEnabled: field.field_validation_enabled,
                                    fileUploadData: ''
                                }
                            }
                            else {
                                tempArray[field.field_id + 'i'] = {
                                    label: field.field_name,
                                    elementCatType: field.data_type_category_id,
                                    elementType: field.data_type_id,
                                    elementTypeName: field.data_type_name,
                                    elementConfig: {
                                        options: [optionsJson]
                                    },
                                    value: '',
                                    validation: {
                                        required: !!field.field_mandatory_enabled,
                                        rules: field.field_inline_data,
                                    },
                                    valid: false,
                                    touched: false,
                                    validationEnabled: field.field_validation_enabled,
                                    fileUploadData: '',
                                }
                            }

                        }
                        else {
                            tempArray[field.field_id + 'i'] = {
                                label: field.field_name,
                                elementCatType: field.data_type_category_id,
                                elementType: field.data_type_id,
                                elementTypeName: field.data_type_name,
                                elementConfig: {
                                    placeholder: 'Enter ' + field.field_name,

                                },
                                value: '',
                                validation: {
                                    required: !!field.field_mandatory_enabled,
                                    rules: field.field_inline_data,
                                },
                                valid: false,
                                touched: false,
                                validationEnabled: field.field_validation_enabled,
                                fileUploadData: ''
                            }
                        }

                        //tempArray = {...tempArray , field.field_id :  }
                    })
                    if(formFieldData.length === requestData.limit_value) {

                        requestData.start_from = requestData.start_from + requestData.limit_value;
                        dispatch(formFieldListFetchCRF(requestData,entry,tempArray))
                    }
                    else {
                        dispatch(formFieldListFetchSuccess(tempArray))
                        if(entry && entry === 'validation') {
                            dispatch(actions.getCAFValidationData(requestData));
                        }
                        else if(entry === 'view') {
                            dispatch(getCAFViewData(requestData));
                        }
                    }
                }
                else {
                    dispatch(formFieldListFetchSuccess(tempArray))
                    if(entry && entry === 'validation') {
                        dispatch(actions.getCAFValidationData(requestData));
                    }
                    else if(entry === 'view') {
                        dispatch(getCAFViewData(requestData));
                    }
                }

            })

    }
}

export const formFieldInputChange = (eventdata,formElId,formElCatId,field = null) => {
    eventdata.persist();
    let value=eventdata.target && eventdata.target.fieldValue?eventdata.target.fieldValue:eventdata.target.value;
    return {
        type: actionTypes.FORMS_MODIFY_FIELD_DATA,
        fieldValue: eventdata.value?eventdata.value:value,
        elementId: formElId,
        elementCatTpId: formElCatId,
        field: field
    };
};

export const formFieldSetValiadtion = (validationData) => {
    return {
        type: actionTypes.FORMS_FIELD_VALIDATE,
        validation: validationData,
    };
};

export const formFileFieldInputChange = (eventdata,elementTypeId,formElCatId = null) => {

    return {
        type: actionTypes.FORMS_MODIFY_FILE_FIELD_DATA,
        fieldValue: eventdata,
        elementId: elementTypeId,
        formElCatId: formElCatId
    };
};
export const formFieldCheckChange = (eventdata,formElId,formElCatId = null) => {
    return {
        type: actionTypes.FORMS_MODIFY_CHECK_FIELD_DATA,
        fieldValue: eventdata,
        elementId: formElId,
        formElCatId: formElCatId
    };
};


export const getCAFViewData = (requestData) => {

    return (dispatch,getState) => {
        const {fieldList} = getState().forms;
        //dispatch(getCAFValidationDataStart(requestData));
        const fetchDataStr = requestData;
        let ValidatedArray = {};
        AxiosWeb.post('activity/form/validation/data',fetchDataStr)
            .then(res => {

                const FormDefinition = res.data.response;
                if(FormDefinition.length > 0) {
                    const FilledData = FormDefinition[0].activity_inline_data;

                    Object.keys(fieldList).forEach(key => {
                        const keyId = key.slice(0,-1);
                        const indexData = FilledData.findIndex(x => Number(x.field_id) === Number(keyId));
                        if(indexData > -1) {
                            fieldList[key].value = FilledData[indexData].field_value;
                            fieldList[key].valid = !!FilledData[indexData].field_validated;
                        }
                        fieldList[key].field_id = Number(keyId);

                        ValidatedArray[keyId] = fieldList[key];


                    })
                }
                dispatch(getCAFViewDataSuccess(ValidatedArray));
            })
            .catch(err => {console.log(err);})
    }

}
export const getCAFViewDataSuccess = (ValidViewData) => {
    return {
        type: actionTypes.FORMS_MAP_VIEW_DATA_SUCCESS,
        ValidViewData: ValidViewData
    }
}

export const setFormsubmittedToFalse = () => {
    return {
        type: actionTypes.FORM_SUBMITTED_SET_FALSE
    }
}



export const fetchFormFieldHistoryStart = () => {
    return {
        type: actionTypes.QUEUE_FORM_FIELD_LOAD_HISTORY_START
    };
};
export const fetchFormFieldHistorySuccess = (historyList) => {
    return {
        type: actionTypes.QUEUE_FORM_FIELD_LOAD_HISTORY_SUCCESS,
        historyList
    };
};
export const fetchFormFieldHistoryFailed = () => {
    return {
        type: actionTypes.QUEUE_FORM_FIELD_LOAD_HISTORY_FAILED,
        error: true,
        errorText: 'falied to load history',
    };
};
export const fetchFormFieldHistory = (reqPayload) => {
    return dispatch => {

        dispatch(fetchFormFieldHistoryStart());
        AxiosWeb.post('/form/fields/history',reqPayload)
            .then(res => {
                if(res.status === 200) {
                    const historyList = res.data.response;
                    dispatch(fetchFormFieldHistorySuccess(historyList));
                } else {
                    dispatch(fetchFormFieldHistoryFailed());
                }

            })
            .catch(err => {
                console.log(err);
                dispatch(fetchFormFieldHistoryFailed());
            })
    };
};

// Update Document in Document Repository
export const documentUpdateStart = () => {
    return {
        type: actionTypes.DOCUMENT_UPDATE_START
    };
};
export const documentUpdateSuccess = (documentData) => {
    return {
        type: actionTypes.DOCUMENT_UPDATE_SUCCESS,
        documentData
    };
};
export const documentUpdateFailed = () => {
    return {
        type: actionTypes.DOCUMENT_UPDATE_FAIL,
        error: true,
        errorText: 'falied to update document',
    };
};
export const updateDocumentInRepo = (reqPayload) => {
    return dispatch => {
        dispatch(documentUpdateStart());
        AxiosWeb.post('/document/update',reqPayload)
            .then(res => {
                console.log("res",res)
                const docData = res.data.response;
                dispatch(documentUpdateSuccess(docData));
            })
            .catch(err => {
                console.log(err);
                dispatch(documentUpdateFailed());
            })
    };
};

export const getAutoPopulateFields = (payload) => {
    return (dispatch) => {
      dispatch(getAutoPopulateFieldsStart());
      AxiosWeb.post("/form/auto-populate/list", payload)
        .then((res) => {
          dispatch(getAutoPopulateFieldsSuccess(res.data.response));
        })
        .catch((err) => {
          dispatch(getAutoPopulateFieldsFailed());
          console.log(err);
        });
    };
  };
  
  export const clearAutoPopulateFields = () => {
    return {
      type: actionTypes.AUTO_POPULATE_FIELDS_SUCCESS,
      autoPopulateFieldsList: [],
    };
  };
  export const getAutoPopulateFieldsSuccess = (data) => {
    return {
      type: actionTypes.AUTO_POPULATE_FIELDS_SUCCESS,
      autoPopulateFieldsList: data,
    };
  };
  
  export const getAutoPopulateFieldsStart = (params) => {
    return {
      type: actionTypes.AUTO_POPULATE_FIELDS_FAILED,
      autoPopulateFieldsList: [],
    };
  };
  
  export const getAutoPopulateFieldsFailed = (params) => {
    return {
      type: actionTypes.AUTO_POPULATE_FIELDS_FAILED,
    };
  };
  
// Save Document in Document Repository
export const documentSaveStart = () => {
    return {
      type: actionTypes.DOCUMENT_SAVE_START,
    };
  };
  export const documentSaveSuccess = (documentData) => {
    return {
      type: actionTypes.DOCUMENT_SAVE_SUCCESS,
      documentData,
    };
  };
  export const documentSaveFailed = () => {
    return {
      type: actionTypes.DOCUMENT_SAVE_FAIL,
      error: true,
      errorText: "falied to save document",
    };
  };
  export const saveDocumentInRepo = (reqPayload) => {
    return (dispatch) => {
      dispatch(documentSaveStart());
      AxiosWeb.post("/document/add", reqPayload)
        .then((res) => {
          console.log("res", res);
          const docData = res.data.response;
          dispatch(documentSaveSuccess(docData));
        })
        .catch((err) => {
          console.log(err);
          dispatch(documentSaveFailed());
        });
    };
  };
  