import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import "../../VodafoneIdea.css";

import vilLogoRed from "../../../../../assets/vil/vil-red-logo.png";
import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
import CloudImg from "../../../../../assets/images/Process/VodafoneIdea/cloud.jpg";
import lotImg from "../../../../../assets/images/Process/VodafoneIdea/lot.jpg";
import WireImg from "../../../../../assets/images/Process/VodafoneIdea/wire.jpg";
import WirelessImg from "../../../../../assets/images/Process/VodafoneIdea/wireless.jpg";

import ReactToPrint from "react-to-print";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import AxiosWeb from "../../../../../axios_ext";
import "./superWifiCafForm.css";
import { JsonToTable } from "react-json-to-table";
import Axios from "axios";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class SuperWifiCafForm extends Component {
  state = {
    superWifiCafData: false,
    stopdidmount: 0,
    superWifiCafDataUpdated: null,
    authorizedSignName: "",
    companySeal: "",
    custPhotoGraph: null,
    imageData: "",
    imageType: "",
    acctMngSignImageData: "",
    acctMngSignImageType: "",
    photoGraph: null,
    photoGraphImageData: null,
    photoGraphImageType: null,
    excelJson: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };

  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1374,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 13001) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 13000) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          if (fieldObj.field_id == 13180) {
            photoGraph = fieldObj.field_value;
            console.log("photoGraph:: " + photoGraph);
          }

          console.log("fieldObj:: " + fieldObj.field_id);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          authorizedSignName: authorizedSignatoryName,
          custPhotoGraph: photoGraph,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 1347; // SuperWifi CAF form Id
    let orderform = 1372;
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        superWifiCafData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12993
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+submittedFormArry);
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );

        this.setState({
          ...this.state,
          superWifiCafData: true,
        });
      }
      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12993
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("12417") &&
      this.state.stopdidmount === 0
    ) {
      let x = this.props.fieldList;
      if (!!this.state.custPhotoGraph) {
        utils.loadImageBlob(
          this.s3,
          this.state.custPhotoGraph,
          (data, type) => {
            this.setState({
              ...this.state,
              photoGraphImageData: data,
              photoGraphImageType: type,
              stopdidmount: 1,
              superWifiCafDataUpdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          superWifiCafDataUpdated: this.props.fieldList,
        });
      }

      if (!!this.state.custCompanySeal) {
        utils.loadImageBlob(
          this.s3,
          this.state.custCompanySeal,
          (data, type) => {
            this.setState({
              ...this.state,
              imageData: data,
              imageType: type,
              stopdidmount: 1,
              superWifiCafDataUpdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          superWifiCafDataUpdated: this.props.fieldList,
        });
      }

      if (!!x[12619] && x[12619].value) {
        // manager sign url
        let acctManagerSignUrl = x[12619].value;
        utils.loadImageBlob(this.s3, acctManagerSignUrl, (data, type) => {
          this.setState({
            ...this.state,
            acctMngSignImageData: data,
            acctMngSignImageType: type,
            stopdidmount: 1,
            superWifiCafDataUpdated: this.props.fieldList,
          });
        });
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          superWifiCafDataUpdated: this.props.fieldList,
        });
      }
    }
  }
  render() {
    console.log(
      "superWifiCafDataUpdated :: " + this.state.superWifiCafDataUpdated
    );
    let showClause = !!this.props.queueData
      ? utils.showCAFClause(this.props.queueData)
      : !!this.props.urlData
      ? utils.showCAFClause(this.props.urlData)
      : false;
    let SuperWifiContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );
    if (
      this.state.superWifiCafDataUpdated != null &&
      this.state.superWifiCafDataUpdated !== "" &&
      this.state.stopdidmount == 1 &&
      this.props.formFullLoaded === 1
    ) {
      let firstCaps = "";
      let secondBold = "";
      let Signcss;

      console.log(
        "this.state.authorizedSignName :: " + this.state.authorizedSignName
      );

      if (
        this.state.authorizedSignName != "" &&
        this.state.authorizedSignName != null
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        console.log(firstCaps, "console.log(firstCaps);");
      }

      let x = Object.assign({}, this.state.superWifiCafDataUpdated);
      x[12616].value = this.state.authorizedSignName;
      x[12612].value = this.state.superWifiCafDataUpdated[12421].value;

      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }

      SuperWifiContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ padding: "20px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsStyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsStyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={vilLogoRed} alt="" />
                  </p>
                  <p className="customfom">
                    {" "}
                    Customer Application Form - Managed WiFi{" "}
                  </p>
                  <div className="over_hid w_85">
                    <div className="">
                      <div className="caf">
                        CAF No :
                        <input
                          className="form_col brdr_b1 "
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12417].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid">
                    <div className="fleft width75">
                      <div className="">
                        <strong>Vodafone Idea Business Servies</strong>{" "}
                        <span className="pad_l10">
                          Kindly fill the form in BLOCK letters
                        </span>{" "}
                      </div>
                      <p className="auth pad_t10">
                        <strong>Company(Customer) Name </strong>
                        <input
                          className="form_col width74 flright input_red"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12418].value
                          }
                          readOnly
                        />
                      </p>
                      <p className="auth pad_t3">
                        <strong>Account Code </strong>
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12419].value
                          }
                          readOnly
                        />
                      </p>
                      <p className="">
                        <strong>Authorised Signatory Details</strong>{" "}
                      </p>
                      <p className="auth">
                        {" "}
                        Name
                        <input
                          className="form_col width92_sup flright"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12421].value
                          }
                          readOnly
                        />
                      </p>
                      <div className="over_hid auth pad_t5">
                        <div className="fleft width45">
                          Contact No:
                          <input
                            className="form_col width59"
                            type="text"
                            name=""
                            value={
                              this.state.superWifiCafDataUpdated[12422].value
                            }
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          Email:
                          <input
                            className="form_col width85 flright"
                            type="text"
                            name=""
                            value={
                              this.state.superWifiCafDataUpdated[12423].value
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright cross_fill">
                      <div className="crossign" style={{ height: "100px" }}>
                        {this.state.photoGraphImageData != null &&
                        this.state.photoGraphImageData != "" ? (
                          <div
                            style={{
                              padding: "5px",
                              position: "relative",
                              textAlign: "center",
                            }}
                            className="signate_img"
                          >
                            <img
                              style={{ height: "90px" }}
                              src={
                                this.state.photoGraphImageData != null
                                  ? "data:" +
                                    this.state.photoGraphImageType +
                                    ";base64," +
                                    this.encode(this.state.photoGraphImageData)
                                  : ""
                              }
                              alt={"PhotoGraph with Crosssign"}
                            />
                            {firstCaps != "" ? (
                              <span
                                style={{
                                  padding: "10px",
                                  position: "absolute",
                                  left: "-20px",
                                  top: "20px",
                                }}
                                className={Signcss}
                              >
                                {firstCaps + "  .  "}
                                <span className="under_line">{secondBold}</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="font5">
                    I/We hereby order for VIL SuperWifi Service subject to terms
                    and conditions as described in the service order form. We
                    furnish the necessary details as fallow.
                  </p>
                  <p className="ligthbg">BILLING ADDRESS</p>
                  <p className="auth">
                    Contact Person name
                    <input
                      className="form_col width60"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12427].value}
                      readOnly
                    />
                  </p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Designation
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12428].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Dept. Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12429].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0">
                    <span className="width54px">Address</span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12430].value}
                      readOnly
                    />
                  </div>
                  <div className="auth margin0">
                    <span className="width54px"> </span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value=""
                      readOnly
                    />
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width45">
                      City/Village/Post office
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12431].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      State
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12432].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15">
                      Pincode
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12433].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Telephone
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12434].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width58">
                      Landmark
                      <input
                        className="form_col width84"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12435].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      <span className="faxn">Fax No.</span>
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12436].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width58">
                      Contact Email Id
                      <input
                        className="form_col width77"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12437].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      Mobile No / Alt No.
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12438].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">
                    SHIPPING ADDRESS (If same as the Billing address then tick
                    the box if diffrent, than fill the form below )
                  </p>
                  <p className="auth">
                    Contact Person name
                    <input
                      className="form_col width60"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12440].value}
                      readOnly
                    />
                  </p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Designation
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12441].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Dept. Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12442].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0">
                    <span className="width54px">Address</span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12443].value}
                      readOnly
                    />
                  </div>
                  <div className="auth margin0">
                    <span className="width54px"> </span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width45">
                      City/Village/Post office
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12444].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      State
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12445].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15">
                      Pincode
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12446].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Telephone
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12447].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width58">
                      Landmark
                      <input
                        className="form_col width84"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12448].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      <span className="faxn">Fax No.</span>
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12449].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width58">
                      Contact Email Id
                      <input
                        className="form_col width77"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12450].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      Mobile No / Alt No.
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12451].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">INSTALLATION ADDRESS</p>
                  <p className="auth">
                    Contact Person name
                    <input
                      className="form_col width83"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12454].value}
                      readOnly
                    />
                  </p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Designation
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12455].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Dept. Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12456].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0">
                    <span className="width54px">Address</span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12457].value}
                      readOnly
                    />
                  </div>
                  <div className="auth pad_b5">
                    <span className="width54px"> </span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value=""
                      readOnly
                    />
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width45">
                      City/Village/Post office
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12458].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      State
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12459].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15">
                      Pincode
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12460].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Telephone
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12461].value}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="auth margin0 over_hid">
                    <div className="fleft width45">
                      Landmark
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12462].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Telecom Circle
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12463].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Fax No.
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12464].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width58">
                      Contact Email Id
                      <input
                        className="form_col width77"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12465].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      Mobile No / Alt No.
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12466].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft width15">
                      GSTIN
                      {this.state.superWifiCafDataUpdated[12467].value ===
                      "GSTIN" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width15">
                      UIN
                      {this.state.superWifiCafDataUpdated[12467].value ===
                      "UIN" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width15">
                      GST_ISD
                      {this.state.superWifiCafDataUpdated[12467].value ===
                      "GST_ISD" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check margin0 over_hid typecheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Type
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12468].value ===
                      "Normal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Normal
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12468].value ===
                      "SEZ" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SEZ
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12468].value ===
                      "Diplomat" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diplomat
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12468].value ===
                      "Embassy" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Embassy
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12468].value ===
                      "Consulate" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consulate
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12468].value ===
                      "Special Agency" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Special Agency
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12468].value ===
                      "Person/Class of Persons" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Person/Class of Persons
                    </div>
                  </div>
                  <p className="auth">
                    GSTIN / UIN / GST_ISD No
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12469].value}
                      readOnly
                    />
                  </p>
                  <div className="auth">
                    <span className="width70px in_block">GST Reg. Address</span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12470].value}
                      readOnly
                    />
                  </div>
                  <div className="auth">
                    <span className="width70px in_block">
                      in installation state
                    </span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                  <div className="check over_hid typecheck gstcheck pad_t10">
                    <div className="fleft width12 pad_t2">
                      GST Customer Dept
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "IT" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "Tech" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Tech
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "Cust Service" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Cust Service
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "Legal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Legal
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "Finance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finance
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "Purchase" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Purchase
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "SCM" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SCM
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "Delivery" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Delivery
                    </div>
                    <div className="fleft width12">
                      {this.state.superWifiCafDataUpdated[12471].value ===
                      "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                    <div className="fleft width20 pad_t2">
                      Others
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12472].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="auth namsupp">
                    Name of Supporting Document
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12473].value}
                      readOnly
                    />
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <p className="gsttext">
                    (GST Certificate, SEZ Certificate, Diplomat Certificate)
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="customfom">
                    {" "}
                    Customer Application Form - Managed WiFi{" "}
                  </p>
                  <p className="ligthbg">CUSTOMER INFORMATION</p>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">Customer Type</div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12475].value ===
                      "General" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      General
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12475].value ===
                      "BPO" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BPO
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12475].value ===
                      "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12475].value ===
                      "Telemarketer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Telemarketer
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12475].value ===
                      "ISP License No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ISP License No
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12475].value ===
                      "OSP Reg. No." ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      OSP Reg. No.
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width22">
                      <div className="fleft pd_t5 pad_r3">ISP License No</div>
                      <input
                        className="form_col width57px"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12476].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      <div className="fleft pd_t5 pad_r3">Date</div>
                      <input
                        className="form_col width57px"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12477].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      <div className="fleft pd_t5 pad_r3">OSP Reg. No.</div>
                      <input
                        className="form_col width57px"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12478].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width22">
                      <div className="fleft pd_t5 pad_r3">Date</div>
                      <input
                        className="form_col width57px"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12479].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t10">
                    <div className="fleft width15_2 pad_7t">
                      Customer Vertical Type
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Automotive" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Automotive
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Consumer Goods" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consumer Goods
                    </div>
                    <div className="fleft width14 fnt8">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Diversified & Projects" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diversified & Projects{" "}
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Education" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Education
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Engineering" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Engineering
                    </div>
                    <div className="fleft width14 fnt8">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Industry Associations" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Industry Associations
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t"> </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Health Care" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Health Care
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Finance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finance
                    </div>
                    <div className="fleft width14 fnt8">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Governament" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Government
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "IT & Telecom" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT & Telecom
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12475].value ===
                      "Logistics" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Logistics
                    </div>
                    <div className="fleft width14 fnt6">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Manufacturing & Processing" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Manufacturing & Processing
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t"> </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Trading" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Trading
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Services" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Services
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Projects EPC" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Projects EPC
                    </div>
                    <div className="fleft width14">
                      {this.state.superWifiCafDataUpdated[12480].value ===
                      "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                  </div>
                  <p className="ligthbg">CUSTOMER ORDER DETAILS</p>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">Type of Order</div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12482].value ===
                      "New" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      New
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12482].value ===
                      "Change" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Change
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12482].value ===
                      "Demo - Paid" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Demo-Paid
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12482].value ===
                      "Demo - Free" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Demo-Free
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">
                      Type of Change Order
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Wifi Upgrade") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Wifi Upgrade
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Wifi Downgrade") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Wifi Downgrade
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Feature(s) Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Features(s) Change
                    </div>
                    <div className="fleft width25">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Customer Provided Wi-Fi Hardware") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer Provided Wi-Fi Hardware
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Contract Extension") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Contract Extension
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Billing Address Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing Address Change
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Shipping Address Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Shipping Address Change
                    </div>
                    <div className="fleft width25">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Billing Periodicity Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing Periodicity Change
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width15 fnt7">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Bliing in (Payment Mode) Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing In (Payment Change){" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Customer Name Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer Name Change
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Customer Contact Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer Contact Change
                    </div>
                    <div className="fleft width25">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Billing Customer Name Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing Customer Name Change
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width15 fnt7">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Authorized Signatory Change") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Authorized Signaory Change{" "}
                    </div>
                    <div className="fleft width20 fnt8">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Other Non Commercial Changes") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other Non-Commercial Changes
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Price Revision") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Price Revision
                    </div>
                    <div className="fleft width25">
                      {this.state.superWifiCafDataUpdated[12483].value
                        .split("|")
                        .indexOf("Demo Extension") >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Demo Extention
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <span className="width15_2 in_block">
                      Change Order Remarks
                    </span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12484].value}
                      readOnly
                    />
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t">Demo Period</div>
                    <div className="fleft width50">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12485].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3"> &nbsp; in days</div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t">
                      Wi-Fi Feasibility ID
                    </div>
                    <div className="fleft width50">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12486].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t">
                      Wi-Fi Circute ID
                    </div>
                    <div className="fleft width50">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12487].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t">
                      Service Flavours
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12488].value ===
                      "Super WiFi Go" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Managed Wifi Go
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12488].value ===
                      "Super WiFi Pro" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Managed Wifi Pro
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t">
                      Wi-Fi Controller
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12489].value ===
                      "Centralised" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Centralised
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12489].value ===
                      "On Premise" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      On Premise
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <span className="width15_2 in_block">Remarks</span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.superWifiCafDataUpdated[12490].value}
                      readOnly
                    />
                  </div>
                  <div className="auth over_hid check ssidname">
                    <span className="width15_2 in_block fleft">SSID Name</span>
                    <div className="fleft width20">
                      {" "}
                      1.
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12492].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width16">
                      {" "}
                      2.
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12493].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width16">
                      {" "}
                      3.
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12494].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width16">
                      {" "}
                      4.
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12495].value}
                        readOnly
                      />
                    </div>
                    <div className="flright width16">
                      {" "}
                      5.
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12496].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t">Contact Period</div>
                    <div className="fleft width50">
                      <input
                        className="form_col fleft wid50per input_red"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12497].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        &nbsp; in months
                      </div>
                    </div>
                  </div>
                  <div className="wifitable">
                    <table
                      width="50%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Year</td>
                          <td>Yearly Price Reduction%</td>
                          <td>
                            Early Termation Penalty as % <br />
                            of rental for remaining
                            <br />
                            contract period
                          </td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12498].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12499].value}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12500].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12501].value}
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12502].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12503].value}
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12504].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12505].value}
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12506].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12507].value}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Billing In</div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12508].value ===
                      "Advance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Advance
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12508].value ===
                      "Arrears" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Arrears
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Billing Periodicity
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12509].value ===
                      "Monthly" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Monthly{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12509].value ===
                      "Quarterly" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quarterly
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12509].value ===
                      "Half-Yearly" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Half Yearly
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12509].value ===
                      "Annually" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Annually
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Wi-fi Hardware Provided By
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12510].value ===
                      "Vodafone Idea" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12510].value ===
                      "Customer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      VIL Provided Hardware On
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12511].value ===
                      "Rent" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Rent{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12511].value ===
                      "Sale" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Sale
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Vodafone Idea Provided Wi-Fi Hardware</strong>
                  </p>
                  <div className="wifitable">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr No</td>
                          <td>Hardware Type</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Quantity</td>
                          <td>Remarks</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Access Points</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12514].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12515].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12516].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12517].value}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>LAN Controller</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12519].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12520].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12521].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12522].value}
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Layer 2 Switch</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12524].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12525].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12526].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12527].value}
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>BYOD Hardware</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12529].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12530].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12531].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12532].value}
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Analytics Hardware</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12534].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12535].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12536].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12537].value}
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Others</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12539].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12540].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12541].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12542].value}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="customfom">
                    {" "}
                    Customer Application Form - Managed WiFi{" "}
                  </p>
                  <p className="margin0">
                    <strong>Customer Provided Wi-Fi Hardware</strong>
                  </p>
                  <div className="wifitable">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr No</td>
                          <td>Hardware Type</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Quantity</td>
                          <td>Remarks</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Access Points</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12545].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12546].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12547].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12548].value}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>LAN Controller</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12550].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12551].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12552].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12553].value}
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Layer 2 Switch</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12555].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12556].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12557].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12558].value}
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>BYOD Hardware</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12560].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12561].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12562].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12563].value}
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Analytics Hardware</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12565].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12566].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12567].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12568].value}
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Others</td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12570].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12571].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12572].value}
                          </td>
                          <td>
                            {this.state.superWifiCafDataUpdated[12573].value}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      Service Monitored & Managed By
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12574].value ===
                      "VodaFone Idea" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea Ltd
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12574].value ===
                      "Customer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      Service Maintained By
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12575].value ===
                      "VodaFone Idea" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea Ltd
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12575].value ===
                      "Customer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      Employee Authentication
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12576].value ===
                      "LDAP" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      LDAP
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12576].value ===
                      "MAC - TAL" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      MAC-TAL
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12576].value ===
                      "Other" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                  </div>
                  <div className="auth over_hid custype">
                    <div className="fleft width22 pad_7t">&nbsp;</div>
                    <div className="fleft width78">
                      <div className="auth over_hid">
                        <span className="fleft">If other, please specify</span>
                        <input
                          className="form_col width78"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12577].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width22 pad_7t">
                      Guest Authentication
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12578].value ===
                      "Voucher Based" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Voucher Based
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12578].value ===
                      "OTP Based" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      OTP based
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12578].value ===
                      "Email Based" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Email based
                    </div>
                    <div className="fleft width16">
                      {this.state.superWifiCafDataUpdated[12578].value ===
                      "Social Login Based" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Social Login based{" "}
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12578].value ===
                      "Admin Based" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Admin based
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">&nbsp;</div>
                    <div className="fleft width15">
                      {" "}
                      If Social Login based :{" "}
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12579].value ===
                      "Twitter" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Twitter
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12579].value ===
                      "Facebook" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Facebook{" "}
                    </div>
                    <div className="fleft width16">
                      {this.state.superWifiCafDataUpdated[12579].value ===
                      "LinkedIn" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Linkedin{" "}
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12579].value ===
                      "Google" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Google
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      Guest Access Policy
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12580].value ===
                      "Time Based" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Time based
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12580].value ===
                      "Quota Based" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quota based{" "}
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">&nbsp;</div>
                    <div className="fleft width50">
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        If Time based &nbsp; &nbsp;{" "}
                      </div>
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12581].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3"> &nbsp; in mins</div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">&nbsp;</div>
                    <div className="fleft width50">
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        If Quota based &nbsp;{" "}
                      </div>
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12582].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3"> &nbsp; in MB</div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      Guest Portal Customization
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12583].value ===
                      "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12583].value ===
                      "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      IP/URL Filtering Service
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12584].value ===
                      "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12584].value ===
                      "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      BYOD Management Service
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12585].value ===
                      "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12585].value ===
                      "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      Location Analytics Service
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12586].value ===
                      "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12586].value ===
                      "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <p className="ligthbg">WAN SERVICE DETAILS</p>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service for Wi-Fi
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12588].value ===
                      "Existing VIL" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Existing VIL{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12588].value ===
                      "Existing Non VIL" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Existing Non VIL
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12588].value ===
                      "New VIL" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      New VIL
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service Circuit ID
                    </div>
                    <div className="fleft width70">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12589].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        &nbsp; If WAN Service is Existing - VIL
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service Feasibility ID (FR ID)
                    </div>
                    <div className="fleft width70">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12590].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        &nbsp; If WAN Service is New - VIL
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service Provider
                    </div>
                    <div className="fleft width70">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12591].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        &nbsp; If WAN Service is Existing-Non - VIL
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN CPE Managed By
                    </div>
                    <div className="fleft width15">
                      {this.state.superWifiCafDataUpdated[12592].value ===
                      "Vodafone Idea" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea Ltd{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.superWifiCafDataUpdated[12592].value ===
                      "Customer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>
                  <div className="pad_t5">
                    <p className="ligthbg">CHARGE DESCRIPTION</p>
                  </div>
                  <div className="char">
                    <div className="check over_hid pad_t5">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Specify Currency{" "}
                      </div>
                      <div className="fleft width7">
                        {this.state.superWifiCafDataUpdated[12594].value ===
                        "INR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        INR
                      </div>
                      <div className="fleft width7">
                        {this.state.superWifiCafDataUpdated[12594].value ===
                        "US$" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        US${" "}
                      </div>
                      <div className="fleft width20 textcenter">
                        {" "}
                        One Time (A){" "}
                      </div>
                      <div className="fleft width20 textcenter">
                        {" "}
                        Annual Recurring (B){" "}
                      </div>
                      <div className="fleft width20 textcenter">
                        {" "}
                        Grand Total (A+B+C){" "}
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Access Point Charge
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12595].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12596].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12597].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Infrastructure Charge
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12598].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12599].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12600].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed Service Charge
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12601].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12602].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12603].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Change Management Charge
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12604].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12605].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12606].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        <strong>Total SuperWifi Service Charge</strong>{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75 input_red"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12607].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75 input_red"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12608].value
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75 input_red"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12609].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t10">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        <strong>Total Order Value</strong>{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width50 pad_l10">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12610].value
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="customfom">
                    {" "}
                    Customer Application Form - Managed WiFi{" "}
                  </p>
                  <div className="pad_t5">
                    <p className="ligthbg">COMMENTS</p>
                  </div>
                  <p>
                    <textarea
                      className="textarea"
                      placeholder="Comments/Special Consideration:"
                      value={this.state.superWifiCafDataUpdated[12611].value}
                    ></textarea>
                  </p>
                  <div className="over_hid">
                    <div className="fleft width65 pad_t10">
                      <p className="marb5">
                        Applicant's Name* / Authorised Signatory's Name**{" "}
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col widthfull inputext"
                          type="text"
                          name=""
                          value={
                            this.state.superWifiCafDataUpdated[12421].value
                          }
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_t30">
                        <div className="fleft width40">
                          Designation**
                          <input
                            className="form_col width55 inputext"
                            type="text"
                            name=""
                            value={
                              this.state.superWifiCafDataUpdated[12613].value
                            }
                            readOnly
                          />
                        </div>
                        <div className="fleft width30">
                          Place
                          <input
                            className="form_col width90 inputext design"
                            type="text"
                            name=""
                            value={
                              this.state.superWifiCafDataUpdated[12614].value
                            }
                            readOnly
                          />
                        </div>
                        <div className="fleft width30">
                          Date
                          <input
                            className="form_col width90 inputext design"
                            type="text"
                            name=""
                            value={
                              this.state.superWifiCafDataUpdated[12615].value
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright width30">
                      <div style={{ padding: "5px" }} className="signate">
                        {firstCaps != "" ? (
                          <div className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          Signature of the Customer / Authorised signatory with
                          in this box only (In case of institutinal, please
                          affix office/company seal){" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width65 pad_t10">
                    <p className="marb5">Account Manager Name</p>
                    <p className="margin0">
                      <input
                        className="form_col widthfull inputext"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12617].value}
                        readOnly
                      />
                    </p>
                    <p className="marb5">Account Manager Circle Office </p>
                    <p className="margin0">
                      <input
                        className="form_col width50 inputext_brd_list"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12618].value}
                        readOnly
                      />
                    </p>
                    <p className="marb5">Channel Partner Name</p>
                    <p className="margin0">
                      <input
                        className="form_col widthfull inputext"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12620].value}
                        readOnly
                      />
                    </p>
                    <p className="marb5">Channel Partner Code</p>
                    <p className="margin0">
                      <input
                        className="form_col width50 inputext_brd_list"
                        type="text"
                        name=""
                        value={this.state.superWifiCafDataUpdated[12621].value}
                        readOnly
                      />
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                      Cellular Limited )<br />
                      An Adity Birla Group and Vodafone partneship
                      <br />
                      MergerCo CIN No: L32100GJ1996PLC030976
                      <br />
                      <strong>Registred Office:</strong> Suman Tower, Plot no.
                      18, Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79
                      66714000 | F: +91 79 23232251
                      <br />
                      <strong>Circle Office Address:</strong>{" "}
                    </p>
                  </div>
                  <div className="flright width30">
                    <div
                      style={{ padding: "5px", height: "120px" }}
                      className="signate"
                    >
                      {this.state.acctMngSignImageData != null &&
                      this.state.acctMngSignImageData != "" ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            this.state.acctMngSignImageData != null
                              ? "data:" +
                                this.state.acctMngSignImageType +
                                ";base64," +
                                this.encode(this.state.acctMngSignImageData)
                              : ""
                          }
                          alt={"Signature of Account Manager"}
                        />
                      ) : (
                        ""
                      )}
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <div className="">
                    <p className="ligthbg font8">TERMS & CONDITIONS </p>
                    <p className="font10">
                      <strong>
                        This "Customer Application Form" (CAF) shall mean
                        together (i) the customer information form; and (ii) the
                        following conditions ("Regulatory Terms"), which are
                        required to be provided/completed with effect from /
                        prior to activation Of any Services, and continue during
                        the term Of service. They stem from the conditions /
                        directions by Department Of Telecommunication ("DOT'),
                        Telecom Regulatory Authority of India (TRAI), Ministry
                        of Communication and Information Technology ("MCIT"),
                        Government of India ("GOI") ("Authorities") and are
                        mandatory conditions. These terms shall be read together
                        with any specific agreement between VIL and the Customer
                        or any General Terms and Conditions ("GTC") communicated
                        with this CA
                      </strong>{" "}
                    </p>
                    <div className="regtext">Regulatory Terms</div>
                    <div className="over_hid">
                      <div className="fleft width49">
                        <ul className="list">
                          <li>
                            It is agreed between Vodafone Idea Ltd. OR its
                            successor, assignee, transferee, and you, (You' or
                            the 'Subscriber') to the following:{" "}
                          </li>
                          <li>
                            <strong>1. DEFINITION:</strong>
                            <br />
                            (a) Service shall mean the service set out on the
                            VIL customer information form and any related
                            solutions provided by VIL. (b) Agreement: means this
                            (i) CAF: and (ii) the Master Service Agreement (MSA)
                            or General Terms & Conditions (GTCs) together with
                            any schedules thereto, which shall constitute the
                            agreement between Customer and VIL (c) Customer:
                            shall mean the name as set out in the CAF.{" "}
                          </li>
                          <li>
                            <strong>2. LICENCE CONDITIONS:</strong>
                            <br />
                            (a) Telecommunication and related Services are
                            provided by Vodafone Idea Ltd, by virtue of the
                            Internet Service Provider license given by DOT, MC
                            T, and GOI. A change mandated by the DOT or Telecom
                            Regulatory Authority of India (TRAD shall apply to
                            the Service from the date of notification by
                            Government. VIL shall inform of any change impacting
                            the Customer or the Services from time to time; (b)
                            VIL may require resubmission of any CAF, should CAF
                            be incomplete or with insufficient information or
                            incorrect information (c) Services will be
                            provisioned after completion of CAF verification (d)
                            Customer shall indemnify and keep indemnified VIL
                            for all and any claims, proceedings or actions
                            brought against VIL arising out of any breach by the
                            Customer of the Regulatory Terms.{" "}
                          </li>
                          <li>
                            <strong>3. USE OF SERVICES:</strong>
                            <br />
                            The Customer shall use the Services only for the
                            purposes specified by the Customer in the CAF and
                            shall not use for any other purpose unless otherwise
                            permitted. The Customer understands and agrees that
                            VIL shall be entitled to suspend / terminate the
                            Service, for any unauthorized or fraudulent use and
                            be indemnified for financial penalty as levied by an
                            Authority. The Services shall not be used for call
                            center/ other service provider (OSP) operations
                            without registration with DOT as a call center/ OSP
                            and submit copy of license / registration. The
                            customer having OSP registration shall ensure that
                            logical partitioning of public network has been
                            undertaken with the NPLC/MPLS IP network and
                            necessary declaration to this effect has been given
                            to the respective TERM Cell of DOT (Licensor).{" "}
                          </li>
                          <li>
                            <strong>4. OBLIGATIONS OF THE CUSTOMER:</strong>
                            <br />A (a) The Customer shall intimate in writing
                            details of change of name, address, title or
                            constitution whenever such change takes place. (b)
                            The Customer shall be allotted an identity number by
                            VIL which is and shall always remain the property of
                            VIL. B What Customer shall do: Customer shall use
                            all reasonable efforts to ensure that user's use the
                            Services in accordance with this Agreement, all
                            applicable law, regulations, circulars, directives
                            and all relevant codes of practice of Governmental
                            Authorities. C. What Customer shall not do: Customer
                            shall not: (a) use any Services for any abusive,
                            improper, immoral, offensive, illegal or fraudulent
                            purposes: (b) do anything that causes the Network to
                            be impaired or damaged; (c) re sell any Services. D
                            VIL is (and shall) not be responsible for the
                            content, usage or any losses that arise to the
                            Customer from the use of the service and VIL shall
                            not be liable for the security or confidentiality of
                            such facilities or services. E Customer shall
                            cooperate with Governmental Security Agencies / or
                            Law & Enforcement Agencies in case of any inspection
                            / or investigation is carried out by them. F VIL is
                            only a managed solution provider for Wi-Fi service
                            to the customer and is not liable for any breach of
                            law committed by customer for use of the services. G
                            Customer hereby provides consent to the collection,
                            processing and use of its personal data such as,
                            including but not limited to, Customer name, contact
                            details and employment related information for the
                            purposes using the Services and Additional Services
                            according to (Short form of Company name)'s privacy
                            policy displayed on its web site www.myvi.in{" "}
                          </li>
                          <li>
                            <strong>
                              5. REGULATORY COMPLIANCE SPECIFIC PROVISIONS:
                            </strong>
                            <br />
                            (a) The CAF binds the Customer, their executors,
                            administrators, successors and permitted assignees
                            to the terms & conditions of the CAF; (b) Customer
                            shall allow or obtain the required permission to
                            enable VIL employees or authorized personnel, enter
                            at all times into the premises where the Services
                            are provided for periodical inspection, installing,
                            maintaining, replacing and removing equipment
                            hardware and/or software prior to, during and after
                            the provision of the services; (c) The Customer
                            undertakes to comply with relevant and applicable
                            laws, licensing conditions, directions,
                            notifications, rules and regulations regarding the
                            use of the Service, and its amendments, replacements
                            made from time to time by the Licensor in India and
                            TRAI and any other statutory authority in India; (d)
                            The Customer shall obtain any relevant consents and
                            approvals for the installation and use of any
                            equipment located at the Customer sites and
                            associated with the Service and any permits,
                            consents or approvals required by the Customer to
                            use the Service, including any applicable other VIL
                            permits ("Permits"); (e) Customer shall use the
                            Services in accordance with the Permits; (f)
                            Customer shall ensure that any network equipment
                            (not provided by Services Provider) which it uses in
                            relation to the Services meet the relevant
                            International Telecommunication Union (
                            TU)/Telecommunication Engineering Centre (TEC)
                            standards; (g) The Customer must ensure that Service
                            provided, is not used for any unlawful purposes
                            using the Services as End User. The Customer shall
                            ensure that objectionable, obscene, unauthorized or
                            any other content, messages or communications
                            infringing copyright, Intellectual property right
                            and international & domestic cyber laws, in any form
                            or inconsistent with the laws of India, are not
                            carried in their network by them or any other
                            authorized person using their network or during the
                            provisioning of Services. The Customer must take all
                            necessary measures to prevent such use. The use of
                            the Service for anti-national activities shall be
                            construed as an offence punishable under the Indian
                            Penal Code or other applicable law. (h) Customer
                            agrees and acknowledges that regulatory and law
                            enforcement agencies (Law Enforcement Agency (LEA)),
                            shall have rights to access the network including
                            access to customer premises and intercept or listen
                            or record calls being made using the Services and
                            facilities provided by Service Provider and Customer
                            shall provide necessary facilities/access/consent to
                            VIC including but not limited to LEAs /Authorities
                            to monitor, control, prevent counter any such or
                            other unlawful activity. (i) Customer understands
                            Customer and its End Users use the Services at their
                            own risk and consequences and VIL has no control
                            over and accepts no responsibility whatsoever for
                            such the Customer use of the Services and the
                            transacted business/transmitted communication and
                            Customer shall comply with all regulatory/legal and
                            statutory requirements during such use of the
                            SeNices. (j) Customer understands and agrees that
                            VIL shall terminate the Service provided to the
                            Customer immediately without any prior notice, upon
                            any violation of these terms and conditions by the
                            Customer and/or directive of any Law Enforcement
                            Agency (LEA) / Judicial / authority or any other
                            Government agency. (k) Any Wi Fi connectivity
                            deployed by customer will be activated only after it
                            is registered for centralised authentication as per
                            DOT instructions issued under F. No. 820-1 /2008-DS
                            Pt. II Dt. 23-02-2009. (l) Customer shall comply
                            with all applicable laws including rules,
                            regulations, orders, notifications, guidelines,
                            directions as issued under the Indian Telegraph Act
                            1885, TRAI Act 2000 and Information Technology Act
                            2008 by Department of Telecommunications, TRAI and
                            Ministry of Information & Broadcasting/ Department
                            of Electronics & Information Technology, from time
                            to time for use of Wi-Fi services (m)
                            Individuals/Groups/Organizations are permitted to
                            use as customer encryption up to only the permitted
                            bit key length in the RSA algorithms or its
                            equivalent in other algorithms without having to
                            obtain permission. However, if encryption equipment
                            higher than the permitted limit is to be deployed,
                            individuals/groups/organizations shall do so with
                            the permission of the Telecom Authority and deposit
                            the decryption key split into two parts, with the
                            Telecom Authority.{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="fright width49">
                        <ul className="list">
                          <li>
                            <strong>6. PRODUCT TERMS & CONDITIONS:</strong>
                            <br />A The detailed layout diagram for the Services
                            stating all the termination ends may be required
                            from the Customer, which may include the following
                            details viz. make, model and type of terminal
                            interface proposed to be used. In case the VIL is
                            not providing terminal equipment for the Services,
                            then Customer would have to arrange for the terminal
                            equipment and also maintain the same at the
                            Customers own cost. The terminal equipment arranged
                            and maintained by Customer shall at all-time be in
                            accordance with the specifications approved by the
                            Telecom Engineering Centre (TEC), Department of
                            Telecommunications, Ministry of Communications &
                            Information Technology, Government Of India, New
                            Delhi - 110 001 and any other amendment thereof. B
                            Before carrying out any changes or alteration in any
                            configuration of the equipment used under this
                            Service, Customer shall ensure prior written consent
                            has to be obtained from VIL C Without prior notice,
                            VIL may at any time through its authorized
                            representative inspect the Customer's operating room
                            and associated apparatus during the service contract
                            period and the Customer has agreed to co-operate in
                            all manners and provide all assistance, for and
                            during such inspection. D VIL shall have the right,
                            at any time and without notice to the Customer to
                            suspend and resume providing of Service for such
                            period or periods as it shall think fit in case it
                            is of the opinion that such an action is necessary
                            or expedient in the public interest. The decision of
                            VIL in this respect will be final and VIL shall not
                            be liable to pay any compensation or Other payment
                            Of any kind such as those in the nature Of damages
                            Of any kind to the customer for exercising such
                            rights. G VIL will issue to the Customer with
                            certain devices / equipment in order for the
                            Customer to avail of Wi-Fi services and connect to
                            the internet. These devices are liable to be
                            returned by the Customer to VIL on termination Of
                            services / connection or on request by VIL H The
                            Customer Shall keep the VIL owned equipment in good
                            condition. In case of non-return of VIL owned
                            devices/equipment or return of VIL owned
                            devices/equipment in damaged/non-working conditions,
                            penalty charges will be applicable and payable by
                            the Customer as per SuperWifi GTCs separately agreed
                            by the Customer. Upon receiving written request from
                            the Customer, Service termination will be done after
                            the receipt of all VIL provided equipment under this
                            service and settlement of all outstanding dues,
                            penalty/damages (if applicable) by the Customer. J
                            Disclaimer- Customer hereby acknowledges that,
                            except as specifically set forth herein, VIL has
                            neither made nor makes any warranty, representation
                            or indemnity with respect to the quality Of service
                            provided to Customer under the Service.{" "}
                          </li>
                          <li>
                            <strong>
                              7. CONTRACT TERMS AND COMMERCIAL TERMS:
                            </strong>
                            <br />A This CAF, shall be read with General Terms &
                            Conditions (GTCs) or any Master Service Agreement
                            (MSA) entered between the Customer and VIL or any
                            India specific in-country agreement (ICC) between
                            the Customer and VIL entered subsequent to or
                            pursuant to any global MSA entered between the
                            global principals/affiliates of the Customer and
                            VIL. B The CAF terms will prevail over any
                            provisions in the MSA/ICC or GTCs which conflict
                            with terms in CAF. C Minimum Contract Term for this
                            service is 1 year (1 2 months), which will be
                            effective from the date of service commissioning.
                            However, the actual contract term may vary basis
                            mutual agreement between parties. If contract is due
                            for renewal and the Customer has not put a written
                            request to VIL for renewal or termination, then, the
                            Services will be deemed accepted and renewed
                            automatically as per existing GTC or MSA/ CC. f
                            price revision is pending the due to ongoing
                            negotiation, then, the new prices shall be effective
                            from the date of receipt of renewal purchase order
                            or signed CAF or revised contract. D Customer has
                            agreed to make timely payment against invoice as per
                            service charges and terms of existing CAF, GTC or
                            MSA/ICC unless there is a revision mutually agreed
                            by both Customer and VIL. E In case of conflicting
                            terms between various documents, unless specifically
                            mentioned, following will be the priority of the
                            documents for the interpretation of the terms, in
                            descending order 1) CAF 2) MSA/ICC 3) GTC 4)
                            Customer PO. F In the event of early termination
                            request by customer before contract term expiry
                            date, the Customer has agreed to pay Early
                            Termination Charges as defined in GTC with this CAF.
                            {showClause &&
                              " (G)In the event the term or usage of a Link extends beyond the agreed expiry date or termination date of this CAF, then the term and conditions " +
                                "of this CAF shall be extended to the expiration /termination of the applicable Link. Customer shall be bound to make payment of Link " +
                                "during the extended period as per last agreed pricing terms. Either Party shall have a right to terminate a Link after giving prior " +
                                "written notice of thirty (30) days’ notice."}
                          </li>
                          <li>
                            <strong>8. GOVERNING LAW:</strong>
                            <br />
                            This CAF shall be subject to Indian Law and courts
                            of jurisdiction shall include courts in the place
                            where Customer has a place of business or where the
                            cause of action arises or the place where the
                            Services have been offered.{" "}
                          </li>
                          <li>
                            <strong>9. GST & SEZ STATUS:</strong>
                            <br />
                            The customer will be solely responsible for
                            providing the correct GST number and "SEZ" related
                            information against the "Place of Supply" as per the
                            GST law. S/he waives off all the rights to dispute
                            or withhold any payments in case of incorrect GST
                            Numbers or incorrect SEZ certificates/ status being
                            provided, which don't match the details available on
                            the Government GSTN portal. The customer
                            acknowledges that s/he will not be able to claim the
                            GST tax credit under such circumstances and shall
                            pay 100% invoiced amount, including taxes.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            {this.state.excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">Annexure to Superwifi CAF :- </p>
                  </div>
                  <JsonToTable json={this.state.excelJson} />
                  <div>
                    <p class="font10 " style={{ marginTop: "20px" }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div class="fleft width50 pad_t10">
                    <p class="marb5">Account Manager Name</p>
                    <p class="margin0">
                      <input
                        class="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        readonly=""
                        value={this.state.superWifiCafDataUpdated[12617].value}
                      />
                    </p>
                    <div className="signate">
                      <div className="signate_img input_sign">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Account Manager Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div className="over_hid ">
                        Authorised Signatory Name
                        {
                          <input
                            className="form_col inputext"
                            type="text"
                            name="Name_CD"
                            value={
                              this.state.superWifiCafDataUpdated[12421].value
                            }
                            readOnly
                          />
                        }
                      </div>
                    </div>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        {
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        }
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      SuperWifiContentView = this.state.superWifiCafData ? (
        <div style={{ paddingTop: "30%", marginLeft: "45%" }}>
          <PulseLoader color={"#000000"} loading={true} />
        </div>
      ) : (
        <div
          style={{ marginTop: "200px", height: "300px", textAlign: "center" }}
        >
          {" "}
          CAF Form not submitted yet.
        </div>
      );
    }
    return <div>{SuperWifiContentView}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SuperWifiCafForm);
