import axios from 'axios';

const instance = axios.create({
	//baseURL : 'https://api.ipstack.com/',
	baseURL: 'https://api.ipify.org',
});

//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';

export default instance;
