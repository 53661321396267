import moment from 'moment';
import config from 'react-global-configuration';
import AxiosWeb from '../axios_ext';

// Returns Value in secs
export const timeDiffSecs = (currTime, inputTime) => {
	let dateA = getUnixTimestamp();
	if (currTime !== null) {
		dateA = getUnixTimestamp(currTime);
	}
	let dateB = getUnixTimestamp();
	if (inputTime !== null) {
		dateB = getUnixTimestamp(inputTime);
	}
	const duration = dateA - dateB;
	return duration;
};

export const getCurrentDateUTC = (datetime) => {
	if (typeof datetime === 'undefined')
		return moment().utc().format('YYYY-MM-DD HH:mm:ss');
	else return moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss');
};

export const getCurrentDateISTWidgetDash = (datetime) => {
	if (typeof datetime === 'undefined') return moment().format('YYYY-MM-DD');
	else return moment(datetime).format('YYYY-MM-DD');
};

export const getCurrentTimeDateIST = (datetime) => {
	if (typeof datetime === 'undefined')
		return moment().format('h:mm A, ddd D MMM YYYY');
	else
		return moment(datetime)
			.add(330, 'minutes')
			.format('h:mm A, ddd D MMM YYYY');
};

export const getLocalTimeDate = (datetime) => {
	if (!datetime) {
		return moment().format('h:mm A, ddd D MMM YYYY');
	} else {
		const utcTime = moment.utc(datetime);
		return utcTime.local().format('h:mm A, ddd D MMM YYYY');
	}
};
export const getCurrentTimeDateISTTemp = (datetime) => {
	if (typeof datetime === 'undefined')
		return moment().format('h:mm A, ddd D MMM YYYY');
	else return moment(datetime).format('h:mm A, ddd D MMM YYYY');
};

export const formatDDMMYYYY = (datetime) => {
	if (typeof datetime === 'undefined') return moment().format('DD-MM-YYYY');
	else return moment(datetime).format('DD-MM-YYYY');
};

export const getUnixTimestamp = (datetime) => {
	if (typeof datetime === 'undefined' || datetime === '')
		return moment().unix();
	else return moment(datetime).unix();
};

export const getCleanValue = (FieldValue) => {
	if (!!FieldValue) {
		FieldValue = FieldValue.replace(null, ' ');
		FieldValue = FieldValue.replace(/%20/g, ' ');
		FieldValue = FieldValue.replace(/%2F/g, '/');
		FieldValue = FieldValue.replace(/%3A/g, ':');
		FieldValue = FieldValue.replace(/%2C/g, ',');
		FieldValue = FieldValue.replace(/%40/g, '@');
		FieldValue = FieldValue.replace(/%23/g, '#');
		FieldValue = FieldValue.replace(/%24/g, '$');
		FieldValue = FieldValue.replace(/%2B/g, '+');
		FieldValue = FieldValue.replace(/%3C/g, '<');
		FieldValue = FieldValue.replace(/%3E/g, '>');
		FieldValue = FieldValue.replace('||', '-');
		FieldValue = FieldValue.replace('&amp;', '&');
		return FieldValue;
	} else {
		return FieldValue;
	}
};

//Generate Message unique id
export const getMessageUniqueId = (asset_id) => {
	return asset_id + (Date.now() + Math.floor(100000 + Math.random() * 999999));
};

// add no of days to current date FORMAT YYYY-MM-DD
export const getAddToCurrentDate = (startdate, days) => {
	return moment(startdate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD');
};

// Get Year
export const getYear = (startdate) => {
	return moment(startdate, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
};
// Get Month
export const getMonth = (startdate) => {
	return moment(startdate, 'YYYY-MM-DD HH:mm:ss').format('MM');
};

// add no of minutes to current date
export const getAddMinutesToCurrentDateUTC = (startdate, minutes) => {
	return moment(startdate, 'YYYY-MM-DD HH:mm:ss')
		.add(minutes, 'minutes')
		.format('YYYY-MM-DD HH:mm:ss');
};

export const getBucketKeyFileName = (url) => {
	return new Promise((resolve) => {
		try {
			let BucketName = url.slice(8, 25);
			let KeyName = url.slice(43);

			if (url.includes('ap-south-1')) {
				KeyName = url.slice(54);
			}

			if (url.includes('staging') || url.includes('preprod')) {
				BucketName = url.slice(8, 33);
				KeyName = url.slice(51);

				if (url.includes('ap-south-1')) {
					KeyName = url.slice(62);
				}
			}
			const FileNameArr = url.split('/');
			const FileName = FileNameArr[FileNameArr.length - 1];
			resolve([BucketName, KeyName, FileName]);
		} catch (err) {
			resolve(['', '', '']);
		}
	});
};

export const s3AccessKey = () => {
	return config.get('s3_access_key');
};

export const s3SecretKey = () => {
	return config.get('s3_secret_key');
};

export const formatHHMMSS = (datetime) => {
	if (typeof datetime === 'undefined') return moment().format('HH:mm:ss');
	else return moment(datetime).format('HH:mm:ss');
};

export const getUrlId = async (custEmail, jsonData) => {
	let reqData = {
		organization_id: jsonData.organization_id,
		account_id: jsonData.account_id,
		workforce_id: jsonData.workforce_id,
		asset_id: jsonData.asset_id,
		auth_asset_id: 31347,
		asset_token_auth: '05986bb0-e364-11e8-a1c0-0b6831833754',
		url_form_data: JSON.stringify(jsonData),
		url_mail_receiver: custEmail,
	};
	let returnValue = 0;
	await AxiosWeb.post('/url/parameters/shorten/v1', reqData).then((res) => {
		if (res.data.response.length > 0) {
			returnValue = res.data.response[0];
			return res.data.response[0];
		} else {
			return returnValue;
		}
	});
	return returnValue;
};

export const loadImageBlob = async (s3Object, url, cb) => {
	let [bucketName, keyName, temp] = await getBucketKeyFileName(url);
	s3Object.getObject(
		{
			Bucket: bucketName,
			Key: keyName,
		},
		(err, blob) => {
			if (err) {
				console.log('error while loading cust photo', err);
			} else {
				cb(blob.Body, blob.ContentType);
			}
		},
	);
};

////--------------------------------Show Caf Clause-----------------------------------------------
export const showCAFClause = (activity) => {
	if (!!activity.activity_datetime_created) {
		var d1 = new Date(activity.activity_datetime_created);
		var d2 = new Date('2020-10-27 00:00:00');
		if (d1.getTime() < d2.getTime()) {
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
export const debounce = (callback, wait) => {
	let timeOut;
  
	return (...args) => {
	  const execute = () => {
		timeOut = null;
		callback(...args);
	  };
	  clearTimeout(timeOut);
	  timeOut = setTimeout(execute, wait);
	};
  };
  // add no of days to current date
export const getAddToCurrentDateUTC = (startdate, days) => {
	return moment(startdate, "YYYY-MM-DD HH:mm:ss")
	  .add(days, "days")
	  .format("YYYY-MM-DD HH:mm:ss");
  };
  