import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';

import Layout from './hoc/Layout/Layout';
import FormLayout from './hoc/FormLayout/FormLayout';

import FormPreview from './containers/FormPreview/FormPreview';
import FormEntry from './containers/FormEntry/SmartFormLayout';
import FormView from './containers/FormView/FormView';

class App extends Component {
	render() {
		return (<>
				<Layout>
					<Switch>
						<Route path='/layout' component={FormLayout} />
						<Route path='/forms/entry/:formsdata' component={FormEntry} />
						<Route path='/forms/view/:formsdata' component={FormView} />
						<Route path='/forms/preview/:formsdata' component={FormPreview} />
					</Switch>
				</Layout>
			</>
		);
	}
}

export default App;
