import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as utils from '../../../../shared/Utils'
import * as actions from '../../../../store/Actions/index'

import { GreneAutoComplete } from "../../../../components/GreneAutoComplete/GreneAutoComplete";
export class ActivityRefrences extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedWorkflow:[],
            searchString:''
        }
        this.workflowSearchDebounce = utils.debounce(
            this.props.onFetchWorkflows,
            500
        );
    }
    handleWorkflowChange = (selectOption) => {
  
      this.setState({
        ...this.state,
        selectedWorkflow: selectOption,
      });
      this.props.inputChangedHandler(
        selectOption.value,
        this.props.elementTypeId
      );
    };
    
    buildWorkflowsRequestParams = (srchStr) => {
          let txtToSrch = "";
        if (srchStr && srchStr !== "") {
          txtToSrch = this.state.searchString;
        }
    
        let activityTypeId = 0;
        let tagId = 0;
        let tagTypeId = 0;
        let isParticipant = 0;
        let activityStatusTypeId = 0;
        let flagDependent = 0;
        let dependentFieldId = 0;
        let dependentFieldServiceName = "";
        if (
          !!this.props.shouldValidate.rules &&
          Object.keys(this.props.shouldValidate.rules).length > 0 &&
          this.props.shouldValidate.rules.workflow_reference_restriction
        ) {
          const {
            activity_type_id,
            tag_id,
            tag_type_id,
            flag_participating,
            activity_status_type_id,
            flag_dependent,
            dependent_field_id,
            dependent_field_service_param_name,
          } = this.props.shouldValidate.rules.workflow_reference_restriction;
          activityTypeId = activity_type_id || 0;
          tagId = tag_id || 0;
          tagTypeId = tag_type_id || 0;
          isParticipant = flag_participating || 0;
          activityStatusTypeId = activity_status_type_id || 0;
          flagDependent = flag_dependent || 0;
          dependentFieldId = dependent_field_id || 0;
          dependentFieldServiceName = dependent_field_service_param_name || "";
        }
        let params = {
            organization_id:this.props.urlData.organization_id,
            account_id:this.props.urlData.account_id,
            workforce_id: 0,
            search_string: txtToSrch,
            service_version: 0.1,
            app_version: 1.0,
            flag_status: 0,
            flag_participating: isParticipant,
            asset_id: this.props.urlData.asset_id,
            asset_token_auth: this.props.urlData.asset_token_auth,
            auth_asset_id:this.props.urlData.auth_asset_id,
            device_os_id: 5,
            page_limit: 50,
            page_start: 0,
            activity_type_category_id: 48,
            activity_type_id: activityTypeId,
            tag_id: tagId,
            tag_type_id: tagTypeId,
            activity_status_type_id: activityStatusTypeId,
        }
        if (flagDependent !== 0) {
            params.flag_dependent=flagDependent;
          };
       
          if (
            !!this.props.fieldList &&
            !!this.props.fieldList[`${dependentFieldId}i`]
          ) {
            const data = this.props.fieldList[`${dependentFieldId}i`]
              .fileUploadData;
            params[`${dependentFieldServiceName}`] = data.value;
          }
        return params;
      };
    inputWorkflowSelectChange = (e, elemenType) => {
        const srchStr = e;
        this.setState(
          {
            ...this.state,
            searchString: srchStr,
          },
          () => {
            this.workflowSearchDebounce(
              this.buildWorkflowsRequestParams(srchStr),
              "select",
              elemenType
            );
          }
        );
      };
    render() {
        let activity_type_id = 0;
        let isMulti = !!(parseInt(this.props.elementType) == 68);
        if (
          !!this.props.shouldValidate &&
          !!this.props.shouldValidate.rules &&
          this.props.shouldValidate.rules.workflow_reference_restriction
        ) {
          const {
            workflow_reference_restriction,
          } = this.props.shouldValidate.rules;
          activity_type_id = workflow_reference_restriction.activity_type_id;
        }
        let workflowOptions = [];
        if (
          this.props.workflowListRef.activity_type_id === activity_type_id
        ) {
          workflowOptions = this.props.workflowListRef.workflowListOptions;
        }
        let value_edit_enabled = !!this.props.field_value_edit_enabled
          ? this.props.field_value_edit_enabled
          : 0;
        let value_prefill_enabled = !!this.props.field_value_prefill_enabled
          ? this.props.field_value_prefill_enabled
          : 0;
        // let isDisableSelect =
        //   value_edit_enabled === 1 && value_prefill_enabled === 1
        //     ? true
        //     : this.props.elementConfig.disabled ||
        //       this.props.disabled ||
        //       false;
        return (
            <div>
            <GreneAutoComplete
              config={{
                name: "workflow_search",
                helperText: "Search by workflow name",
                isSearchable: true,
                isMulti: isMulti,
                className: "reference_search",
                isCuidVisible: true,
              }}
              value={this.state.selectedWorkflow}
              options={workflowOptions}
              onSelectionChange={this.handleWorkflowChange}
              onInputChange={(e) =>
                this.inputWorkflowSelectChange(
                  e,
                  Number(this.props.elementType)
                )
              }
            />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        workflowListRef: state.files.workflowListRef,
}}

const mapDispatchToProps =(dispatch) => {
    return{
    inputChangedHandler: (event, formElementId, eleType, onFly) =>
      dispatch(
        actions.formFileFieldInputChange(event, formElementId, eleType, onFly)
      ),
    onFetchWorkflows: (requestData, reference, target = null) =>
            dispatch(actions.workflowsFetchByTags(requestData, reference, target)),
    }
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityRefrences)
