import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import "../../VodafoneIdea.css";

import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import ReactToPrint from "react-to-print";

import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import AxiosWeb from "../../../../../axios_ext";
import "./IllCrfForm.css";
import { JsonToTable } from "react-json-to-table";
import Axios from "axios";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class IllCrfForm extends Component {
  state = {
    illCrfData: false,
    stopdidmount: 0,
    illCrfDataupdated: null,
    authorizedSignName: "",
    companySeal: "",
    imageData: "",
    imageType: "",
    acctMngSignImageData: "",
    acctMngSignImageType: "",
    excelJson: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };
  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1306,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    console.log("REQUEST DATA : ", requestData);
    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        //console.log('formFieldData ::'+JSON.parse(res.data.response[0].data_entity_inline).form_submitted);

        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 11184) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 11183) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("fieldObj:: " + fieldObj.field_id);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.custCompanySeal);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 1229; // ILL CRF form Id
    //let orderform = 1329;
    let orderform = 1137;
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        crfData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12891
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+submittedFormArry);
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );
        this.setState({
          ...this.state,
          crfData: true,
        });
      }
      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12891
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("9452") &&
      this.state.stopdidmount === 0
    ) {
      //console.log('submitted forms list',this.props.fieldList);
      // console.log('this.props.fieldList : ', this.props.fieldList)
      let x = this.props.fieldList;
      console.log("********************");
      console.log("Company Seal URL : ", this.state.custCompanySeal);
      console.log("Account Manager Signature URL : ", x[9749].value);
      console.log("********************");

      let companySealUrl = "";
      let BucketName = "";
      let KeyName = "";
      let temp = "";

      if (
        this.state.custCompanySeal != null &&
        this.state.custCompanySeal != ""
      ) {
        companySealUrl = this.state.custCompanySeal || "";
        //BucketName = this.state.custCompanySeal.slice(8, 25);
        //KeyName = this.state.custCompanySeal.slice(43);
        [BucketName, KeyName, temp] = await utils.getBucketKeyFileName(
          this.state.custCompanySeal
        );
      }
      console.log("companySealUrl : ", companySealUrl);

      if (companySealUrl !== "") {
        this.s3.getObject(
          {
            Bucket: BucketName,
            Key: KeyName,
          },
          async (err, data) => {
            if (err) {
              console.log("error 1", err);

              this.setState({
                ...this.state,
                illCrfDataupdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 1 ", data);
              console.log("data is 1 ", data.Body);

              let AcctManagerSignUrl = x[9749].value;
              //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
              //const KeyName1 = AcctManagerSignUrl.slice(43);
              let BucketName1, KeyName1;

              [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
                AcctManagerSignUrl
              );

              this.s3.getObject(
                {
                  Bucket: BucketName1,
                  Key: KeyName1,
                },
                (err, data1) => {
                  if (err) {
                    console.log("error 2", err);

                    this.setState({
                      ...this.state,
                      illCrfDataupdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      stopdidmount: 1,
                    });
                  } else {
                    console.log("data is 2 IF PART", data1);
                    console.log("data is 2 IF PART", data1.Body);

                    this.setState({
                      ...this.state,
                      illCrfDataupdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      acctMngSignImageData: data1.Body,
                      acctMngSignImageType: data1.ContentType,
                      stopdidmount: 1,
                    });
                  }
                }
              );
            }
          }
        );
      } else {
        let AcctManagerSignUrl = x[9749].value;
        //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
        //const KeyName1 = AcctManagerSignUrl.slice(43);

        let BucketName1, KeyName1;
        let temp = "";

        [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
          AcctManagerSignUrl
        );

        this.s3.getObject(
          {
            Bucket: BucketName1,
            Key: KeyName1,
          },
          (err, data1) => {
            if (err) {
              console.log("error 2", err);

              this.setState({
                ...this.state,
                illCrfDataupdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 2 ELSE PART", data1);
              console.log("data is 2 ELSE PART", data1.Body);

              this.setState({
                ...this.state,
                illCrfDataupdated: this.props.fieldList,
                acctMngSignImageData: data1.Body,
                acctMngSignImageType: data1.ContentType,
                stopdidmount: 1,
              });
            }
          }
        );
      }
    }
  }

  render() {
    let crfContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );
    if (
      this.state.illCrfDataupdated != null &&
      this.state.illCrfDataupdated !== "" &&
      this.props.formFullLoaded === 1
    ) {
      let x = this.state.illCrfDataupdated;
      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }

      let firstCaps = "";
      let secondBold = "";
      let Signcss;

      console.log(
        "this.state.authorizedSignName :: " + this.state.authorizedSignName
      );

      if (
        this.state.authorizedSignName != "" &&
        this.state.authorizedSignName != null
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        console.log(firstCaps, "console.log(firstCaps);");
      }

      crfContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ paddingTop: "57px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsStyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsStyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <div className="pad_20t">
                    <div className="over_hid">
                      <div className="pd_t5">
                        {" "}
                        <span className="textcenter width70 fleft font15 mplhed">
                          ILL Change Request Form
                        </span>
                        <div className="caf">
                          CAF ID
                          <input
                            className="form_col"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9452].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="over_hid mar_t-5">
                      <div className="pd_t5">
                        <strong>Customer Account Code:</strong>{" "}
                        <span className="pad_l10">
                          <input
                            className="form_col_input"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9453].value}
                            readOnly
                          />
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="over_hid auth">
                    <div className="fleft widthfull">
                      Customer Name
                      <input
                        className="form_col flright width81"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9454].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width45 textcenter">
                      {" "}
                      Feasibility ID (FR ID){" "}
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9455].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_5t">
                    <div className="fleft width45 textcenter">
                      {" "}
                      Please mention circuit id.
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9456].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_5t">
                    <div className="fleft width45 textcenter">
                      Please mention VOX ID
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9457].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft width72px"> Type of Order</div>
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Company Name Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Company Name Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Customer Contact Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Customer Contact Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Billing Address change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Billing Address change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          CIR Bandwidth Upgrade -same interface{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("CIR Bandwidth Upgrade -same interface") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          CIR Bandwidth Upgrade with interface change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf(
                              "CIR Bandwidth Upgrade with interface change"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          CIR Bandwidth Downgrade- Same Interface{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf(
                              "CIR Bandwidth Downgrade- Same Interface"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          CIR Bandwidth Downgrade- Interface change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf(
                              "CIR Bandwidth Downgrade- Interface change"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Interface Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Interface Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Interface Change- Media Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Interface Change- Media Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Interface Change- LM provider change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Interface Change- LM provider change") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Change in Protocol
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Change in Protocol") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Change in CPE - Add/ Remove
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Change in CPE - Add/ Remove") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Additional WAN IP{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Additional WAN IP") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Additional LAN IP{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Additional LAN IP") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          VoIP- Enable{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("VoIP- Enable") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          VoIP- Disable{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("VoIP- Disable") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Site Shifting{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Site Shifting") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Billing Periodicity Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Billing Periodicity Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            Change of ILL variant (Flavour){" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Change of ILL variant (Flavour)") >=
                            0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            ToD - Subscribe ToD{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("ToD - Subscribe ToD") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            ToD - UnSubscribe ToD{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("ToD - UnSubscribe ToD") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            {" "}
                            ToD - Change of Port Speed (Bandwidth){" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf(
                                "ToD - Change of Port Speed (Bandwidth)"
                              ) >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="signate float_img">
                          {firstCaps != "" ? (
                            <div
                              style={{ padding: "10px" }}
                              className={Signcss}
                            >
                              {firstCaps + "  .  "}
                              <span className="under_line">{secondBold}</span>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.imageData != null &&
                          this.state.imageData != "" ? (
                            <div
                              style={{ padding: "10px" }}
                              className="signate_img"
                            >
                              <img
                                src={
                                  this.state.imageData != null
                                    ? "data:" +
                                      this.state.imageType +
                                      ";base64," +
                                      this.encode(this.state.imageData)
                                    : ""
                                }
                                alt={"Company Seal"}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            BoD - Subscribe ToD{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("BoD - Subscribe ToD") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            {" "}
                            BoD - UnSubscribe ToD{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("BoD - UnSubscribe ToD") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            BoD - Change of Port Speed (Bandwidth){" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf(
                                "BoD - Change of Port Speed (Bandwidth)"
                              ) >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            Subscribe Nth Percentile{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Subscribe Nth Percentile") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            {" "}
                            Un Subscribe Nth Percentile{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Un Subscribe Nth Percentile") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            {" "}
                            Change of Nth Percentile Value{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Change of Nth Percentile Value") >=
                            0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            {" "}
                            Nth Percentile- Change of Port Speed (Bandwidth){" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf(
                                "Nth Percentile- Change of Port Speed (Bandwidth)"
                              ) >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            {" "}
                            Subscribe DDoS{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Subscribe DDoS") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            Unsubscribe DDoS{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Unsubscribe DDoS") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            Change of DDoS bandwidth{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Change of DDoS bandwidth") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            Change of DDoS Flavour{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Change of DDoS Flavour") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            Subscribe Managed Services{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Subscribe Managed Services") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>

                        <div className="over_hid pad_t2">
                          <div className="fleft width80 pad_t2">
                            {" "}
                            Unsubscribe Managed Services{" "}
                          </div>
                          <div className="fleft">
                            {this.state.illCrfDataupdated[9458].value
                              .split("|")
                              .indexOf("Unsubscribe Managed Services") >= 0 ? (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col width37px"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <div className="over_hid pad_t15">
                    <div className="fleft width72px"> &nbsp; </div>
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Change of Managed Services Variant{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Change of Managed Services Variant") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Subscribe Managed Security Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Subscribe Managed Security Services") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Unsubscribe Managed Security Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Unsubscribe Managed Security Services") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Change of Managed security Services Variant{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf(
                              "Change of Managed security Services Variant"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Subscribe SMTP Relay Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Subscribe SMTP Relay Services") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Unsubscribe SMTP Relay Service{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Unsubscribe SMTP Relay Service") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Subscribe Authoritative DNS Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Subscribe Authoritative DNS Services") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Unsubscribe Authoritative DNS Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf(
                              "Unsubscribe Authoritative DNS Services"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Change of A DNS Service parameters{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Change of A DNS Service parameters") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Subscribe Managed WiFi Service{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Subscribe Managed WiFi Service") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Unsubscribe Managed WiFi Service{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Unsubscribe Managed WiFi Service") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Subscribe Managed Proxy service{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Subscribe Managed Proxy service") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Unsubscribe Managed Proxy service{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Unsubscribe Managed Proxy service") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          Change in SLA Type{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Change in SLA Type") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Change in Self care service variant{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Change in Self care service variant") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          Revision in charges{" "}
                        </div>
                        <div className="fleft">
                          {this.state.illCrfDataupdated[9458].value
                            .split("|")
                            .indexOf("Revision in charges") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Company Name Change
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9460].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9461].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Customer Contact Details Change
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing telephone Number
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9463].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New telephone Number
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9464].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing email address
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9465].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New email address
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9466].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Billing Address Change
                  </p>
                  <p className="font12 margin0"> New Billing Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9469].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9470].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9471].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9472].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9473].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9474].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col width90"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9475].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9476].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Site Shifting
                  </p>
                  <p className="font12 margin0"> New Installation Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9479].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9480].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9481].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9482].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9483].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9484].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col width90"
                            type="text"
                            name=""
                            value={this.state.illCrfDataupdated[9485].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9486].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Upgrade - Same Interface
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9488].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9489].value}
                          readOnly
                        />
                        Kbps / Mbps{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Downgrade - Same Interface
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9491].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9492].value}
                          readOnly
                        />
                        Kbps / Mbps{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Upgrade with Interface / LM / Media Change
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9494].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9495].value}
                          readOnly
                        />
                        Kbps / Mbps{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9496].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9496].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9496].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9496].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9496].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9496].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9497].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9497].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9497].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9497].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9497].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9497].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">Existing Media</span>
                        {this.state.illCrfDataupdated[9499].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9499].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9499].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">New Media</span>
                        {this.state.illCrfDataupdated[9500].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9500].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9500].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9502].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9503].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Downgrade with Interface / LM / Media Change
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9505].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9506].value}
                          readOnly
                        />
                        Kbps / Mbps{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9507].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9507].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9507].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9507].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9507].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9507].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9508].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9508].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9508].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9508].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9508].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9508].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="signate float_img">
                        {firstCaps != "" ? (
                          <div style={{ padding: "10px" }} className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <div
                            style={{ padding: "10px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">Existing Media</span>
                        {this.state.illCrfDataupdated[9510].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9510].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9510].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">New Media</span>
                        {this.state.illCrfDataupdated[9511].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9511].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9511].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9513].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9514].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Interface Change{" "}
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9516].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9516].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9516].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9516].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9516].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9516].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9517].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9517].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9517].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9517].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9517].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9517].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Interface Change with Media / LM Change
                  </p>

                  <div className="pad_t10">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9519].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9519].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9519].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9519].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9519].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9519].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9520].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9520].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9520].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9520].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9520].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.illCrfDataupdated[9520].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">Existing Media</span>
                        {this.state.illCrfDataupdated[9522].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9522].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9522].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9522].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>{" "}
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">New Media</span>
                        {this.state.illCrfDataupdated[9523].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9523].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9523].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9523].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9525].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9526].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Routing Protocol change
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">
                        {" "}
                        Existing Protocol{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9528].value === "BGP" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BGP
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9528].value ===
                        "Static" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        <span className="pad_5t flright pad_l">
                          {" "}
                          &nbsp; Static
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">New Protocol </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9529].value === "BGP" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BGP
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9529].value ===
                        "Static" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        <span className="pad_5t flright pad_l">
                          {" "}
                          &nbsp; Static
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width15 pad_t3 textcenter">
                        If BGP{" "}
                      </div>
                      <div className="fleft width10 pad_t3"> AS Number</div>
                      <div className="fleft">
                        <input
                          className="form_col width200px"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9530].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp; </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9531].value ===
                        "Partial Routes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Partial Routes
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9531].value ===
                        "Full Routes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Full Routes{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp; </div>
                      <div className="fleft width16 pad_t3">
                        {" "}
                        Loop Back IP Address
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9532].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Addition of CPE
                  </p>
                  <div className="domestictable">
                    <table
                      style={{
                        width: "70%",
                        cellSpacing: "0",
                        cellPadding: "0",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>{this.state.illCrfDataupdated[9534].value}</td>
                          <td>{this.state.illCrfDataupdated[9535].value}</td>
                          <td>{this.state.illCrfDataupdated[9536].value}</td>
                          <td>{this.state.illCrfDataupdated[9537].value}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Deletion of CPE
                  </p>
                  <div className="domestictable">
                    <table
                      style={{
                        width: "70%",
                        cellSpacing: "0",
                        cellPadding: "0",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>{this.state.illCrfDataupdated[9539].value}</td>
                          <td>{this.state.illCrfDataupdated[9540].value}</td>
                          <td>{this.state.illCrfDataupdated[9541].value}</td>
                          <td>{this.state.illCrfDataupdated[9542].value}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    {" "}
                    Additional WAN IP Address
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width30 pad_t3">
                        Number of WAN IP addresses requested
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width56im fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9544].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" value=""  /> */}
                        <span className="in_block pad_5t pad_l10">
                          (In multiple of 8 IP Addresses){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width35 pad_r15 pad_t3">
                        Mention if IP addresses are required from Specific Pool
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9545].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" value="" />
                                            <input className="form_col width30px fleft" type="text" name="" value=""  />
                                            <input className="form_col width30px fleft" type="text" name="" value=""  />
                                            <span className="fleft width50px pad_5t textcenter"> / </span>
                                            <input className="form_col width30px fleft" type="text" name="" value=""  /> */}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Addition of LAN IP Address
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">
                        IP Address range
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px  fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9547].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <span className="fleft width50px pad_5t textcenter"> / </span>
                                            <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp;</div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9548].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <span className="fleft width50px pad_5t textcenter"> / </span>
                                            <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp;</div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9549].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <span className="fleft width50px pad_5t textcenter"> / </span>
                                            <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Deletion of LAN IP Address
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">
                        IP Address range
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9551].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <span className="fleft width50px pad_5t textcenter"> / </span>
                                            <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp;</div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9552].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <span className="fleft width50px pad_5t textcenter"> / </span>
                                            <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp;</div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9553].value}
                          readOnly
                        />
                        {/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                            <span className="fleft width50px pad_5t textcenter"> / </span>
                                            <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    VOIP Enable{" "}
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">
                        {" "}
                        Existing status of VOIP Port{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9555].value ===
                        "Disable" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Disable
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">
                        {" "}
                        Desired Status of VOIP Port{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9556].value ===
                        "Enable" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Enable
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    VOIP Disable{" "}
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">
                        {" "}
                        Existing status of VOIP Port{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9558].value ===
                        "Disable" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Disable
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">
                        {" "}
                        Desired Status of VOIP Port{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9559].value ===
                        "Enable" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Enable
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Billing Periodicity Change
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        Existing Billing Periodicity{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9561].value ===
                        "Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9561].value ===
                        "Bi-Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Bi-Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9561].value ===
                        "Quarterly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Quarterly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9561].value ===
                        "Half Yearly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Half Yearly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9561].value ===
                        "Annualy" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Annualy
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        Revised Billing Periodicity{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9562].value ===
                        "Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9562].value ===
                        "Bi-Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Bi-Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9562].value ===
                        "Quarterly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Quarterly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9562].value ===
                        "Half Yearly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Half Yearly
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9562].value ===
                        "Annualy" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Annualy
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in Service Variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        Existing Service Variant{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9564].value ===
                        "Business ILL (1:1)" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Business ILL (1:1)
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9564].value ===
                        "Business Internet Port" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Business Internet Port{" "}
                      </div>
                      <div className="fleft width16 pad_l10">
                        {this.state.illCrfDataupdated[9564].value ===
                        "Value Plus ILL (1:4)" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Value Plus ILL (1:4)
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9564].value ===
                        "Priority ILL (1:1)" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Priority ILL (1:1)
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        New Service Variant{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9565].value ===
                        "Business ILL (1:1)" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Business ILL (1:1)
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9565].value ===
                        "Business Internet Port" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Business Internet Port{" "}
                      </div>
                      <div className="fleft width16 pad_l10">
                        {this.state.illCrfDataupdated[9565].value ===
                        "Value Plus ILL (1:4)" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Value Plus ILL (1:4)
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9565].value ===
                        "Priority ILL (1:1)" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Priority ILL (1:1)
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    ToD - Subscribe ToD
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        ToD Service Required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9567].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft width16">If Yes</div>
                    </div>
                  </div>
                  <div className="">
                    <div className="check over_hid">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft width25 pd_t5">
                        Maximum Port Speed (Bandwidth)
                      </div>
                      <div className="signate float_img">
                        {firstCaps != "" ? (
                          <div style={{ padding: "10px" }} className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <div
                            style={{ padding: "10px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="fleft wid36">
                        <input
                          className="form_col width56im fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9568].value}
                          readOnly
                        />
                        <span className="pad_5t fleft pad_l10">
                          (Kbps / Mbps)
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid">
                      <div className="fleft pad_t3 width30">&nbsp;</div>
                      <div className="fleft width50 pd_t5">
                        (Maximum port speed shall be eight times CIR bandwidth))
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    ToD - UnSubscribe ToD
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        ToD Service Required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9571].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    ToD - Change in port speed (bandwidth)
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width30 pad_t3">
                        Existing Maximum Port Speed (Bandwidth)
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9573].value}
                          readOnly
                        />
                        <span className="in_block pad_5t pad_l10">
                          (Kbps / Mbps){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width30 pad_t3">
                        New Maximum Port Speed (Bandwidth)
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9574].value}
                          readOnly
                        />
                        <span className="in_block pad_5t pad_l10">
                          (Kbps / Mbps){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp;</div>
                      <div className="fleft">
                        {" "}
                        (Maximum port speed shall be eight times CIR bandwidth){" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    BoD - Subscribe BoD
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        BoD Service Required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9577].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft width16">If Yes</div>
                    </div>
                  </div>
                  <div className="">
                    <div className="check over_hid">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft width25 pd_t5">
                        Maximum Port Speed (Bandwidth)
                      </div>
                      <div className="fleft wid36">
                        <input
                          className="form_col width56im fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9578].value}
                          readOnly
                        />
                        <span className="pad_5t fleft pad_l10">
                          (Kbps / Mbps)
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid">
                      <div className="fleft pad_t3 width30">&nbsp;</div>
                      <div className="fleft width50 pd_t5">
                        (Maximum port speed shall be eight times CIR bandwidth))
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    BoD - Un Subscribe BoD
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        BoD Service Required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9581].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    BoD - Change in port speed (bandwidth)
                  </p>

                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width30 pad_t3">
                        Existing Maximum Port Speed (Bandwidth)
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9583].value}
                          readOnly
                        />
                        <span className="in_block pad_5t pad_l10">
                          (Kbps / Mbps){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width30 pad_t3">
                        New Maximum Port Speed (Bandwidth)
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9584].value}
                          readOnly
                        />
                        <span className="in_block pad_5t pad_l10">
                          (Kbps / Mbps){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_t3">&nbsp;</div>
                      <div className="fleft">
                        {" "}
                        (Maximum port speed shall be eight times CIR bandwidth){" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Nth percentile
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        Nth percentile Service Required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9587].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft width16">If Yes</div>
                    </div>
                  </div>
                  <div className="">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft width10 pd_t5">Value of N</div>
                      <div className="fleft width16">
                        <input
                          className="form_col width150px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9588].value}
                          readOnly
                        />
                        (1 to 99)
                      </div>
                      <div className="fleft width16 pad_5t">
                        Port Speed (Bandwidth)
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width150px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9589].value}
                          readOnly
                        />
                        <span className="in_block pad_5t pad_l10">
                          (Kbps / Mbps){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Nth percentile
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        Nth percentile Service Required
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9591].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change of Nth percentile Value
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        Existing Value of N
                      </div>
                      <div className="fleft width16">
                        <input
                          className="form_col width150px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9593].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">New Value of N</div>
                      <div className="fleft width16">
                        <input
                          className="form_col width150px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9594].value}
                          readOnly
                        />
                        (1 to 99)
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Nth percentile - Change of port speed (bandwidth)
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width30 pad_t3">
                        Existing Port Speed (Bandwidth)
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9596].value}
                          readOnly
                        />
                        <span className="in_block pad_5t pad_l10">
                          (Kbps / Mbps){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width30 pad_t3">
                        New Port Speed (Bandwidth)
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width200px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9597].value}
                          readOnly
                        />
                        <span className="in_block pad_5t pad_l10">
                          (Kbps / Mbps){" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe DDoS Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        DDoS Service Required
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9599].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft width16">If Yes</div>
                    </div>
                  </div>
                  <div className="check over_hid ">
                    <div className="fleft pad_t3 width10">&nbsp;</div>
                    <div className="fleft width25 pd_t5">
                      DDoS bandwidth required
                    </div>
                    <div className="fleft width50">
                      <input
                        className="form_col width150px fleft"
                        type="text"
                        name=""
                        value={this.state.illCrfDataupdated[9600].value}
                        readOnly
                      />
                      (Values shall range from 1X, 2X to 10X where X is ILL port
                      bandwidth)
                    </div>
                  </div>
                  <div className="check over_hid pad_t5">
                    <div className="fleft pad_t3 width10">&nbsp;</div>
                    <div className="fleft width15 pd_t5">DDoS Flavour</div>
                    <div className="fleft width10">
                      {this.state.illCrfDataupdated[9601].value === "Gold" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft width20">
                      {this.state.illCrfDataupdated[9601].value === "Silver" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    {" "}
                    Un Subscribe DDoS Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        DDoS Service Required
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9603].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    {" "}
                    Change of DDoS Bandwidth
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        Existing DDoS bandwidth
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width150px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9605].value}
                          readOnly
                        />
                        (Values shall range from 1X, 2X to 10X where X is ILL
                        port bandwidth)
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width25">
                        New DDoS bandwidth required
                      </div>
                      <div className="fleft">
                        <input
                          className="form_col width150px fleft"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9606].value}
                          readOnly
                        />
                        (Values shall range from 1X, 2X to 10X where X is ILL
                        port bandwidth)
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change of DDoS Flavour
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        Existing DDoS Flavour
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9608].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9608].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        Managed Service Variants
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9609].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9609].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Managed Services
                  </p>

                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        Managed services required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9611].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        Managed Service Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9612].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9612].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9612].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9612].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9612].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9612].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9612].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Managed Services
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        Managed services required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9614].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change of Managed Services Variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        Existing Managed Service Variants
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9616].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9616].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9616].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9616].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9616].value ===
                        " Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9616].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9616].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        New Managed Service Variants
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9617].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9617].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9617].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9617].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9617].value ===
                        " Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9617].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9617].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Managed Security Services
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Managed security services required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9619].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Managed Security Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9620].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9620].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9620].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9620].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9620].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9620].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9620].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Managed Security Services
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Managed security services required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9622].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change of Managed security Services Variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Existing Managed Security Variant{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9624].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9624].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9624].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9624].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9624].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9624].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9624].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        New Managed Security Variant{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9625].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9625].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9625].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9625].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9625].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9625].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9625].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe SMTP Relay Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        SMTP Relay service required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9627].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe SMTP Relay Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        SMTP Relay service required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9629].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Authoritative DNS Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Authoritative DNS service required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9635].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft pad_r15">If Yes</div>
                      <div className="fleft width30">
                        <div className="domestictable">
                          <table
                            style={{
                              width: "100%",
                              cellSpacing: "0",
                              cellPadding: "0",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td>Domain Name</td>
                                <td>Record Type</td>
                              </tr>
                              <tr>
                                <td>
                                  {this.state.illCrfDataupdated[9632].value}
                                </td>
                                <td>
                                  {this.state.illCrfDataupdated[9633].value}
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Authoritative DNS Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Authoritative DNS service required
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9635].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change of Authoritative DNS Service parameters
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width10">&nbsp;</div>
                      <div className="fleft pad_r15">Existing</div>
                      <div className="fleft width30">
                        <div className="domestictable">
                          <table
                            style={{
                              width: "100%",
                              cellSpacing: "0",
                              cellPadding: "0",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td>Domain Name</td>
                                <td>Record Type</td>
                              </tr>
                              <tr>
                                <td>
                                  {this.state.illCrfDataupdated[9637].value}
                                </td>
                                <td>
                                  {this.state.illCrfDataupdated[9638].value}
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="fleft pad_r15 width10 text_r">New</div>
                      <div className="fleft width30">
                        <div className="domestictable">
                          <table
                            style={{
                              width: "100%",
                              cellSpacing: "0",
                              cellPadding: "0",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td>Domain Name</td>
                                <td>Record Type</td>
                              </tr>
                              <tr>
                                <td>
                                  {this.state.illCrfDataupdated[9639].value}
                                </td>
                                <td>
                                  {this.state.illCrfDataupdated[9640].value}
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Managed WiFi Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Subscribe Managed WiFi service{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9642].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Managed WiFi Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Un Subscribe Managed WiFi Service{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9644].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Managed Proxy Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Subscribe Managed Proxy Service{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9646].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Managed Proxy Service
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Un Subscribe Managed Proxy Service
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9648].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in SLA Type
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width15 pad_t3">
                        {" "}
                        Existing SLA Type{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9650].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9650].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width15 pad_t3"> New SLA Type </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9651].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft width16">
                        {this.state.illCrfDataupdated[9651].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_7t textcenter  pad_r5">
                        If SLA Type Customized{" "}
                      </div>
                      <div className="fleft width16 pad_r20">
                        Service Availability (%)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9653].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        MTTR (Hours)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9654].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        Latency (ms)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9655].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        Packet Loss (%)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9656].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width12">
                        Jitter (ms)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9657].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in Self care service variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        Existing Self Care Service Variant{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9659].value === "None" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        None
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9659].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9659].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9659].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9659].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9659].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9659].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width24">
                        New Self Care Service Variant
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9660].value === "None" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        None
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9660].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9660].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9660].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9660].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.illCrfDataupdated[9660].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft">
                        {this.state.illCrfDataupdated[9660].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Revision in Charges
                  </p>
                  <p>
                    (Only do required changes. Leave others blank if no change)
                  </p>
                  <div className="char">
                    <div className="check over_hid pad_t5">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Specify Currency{" "}
                      </div>
                      <div className="fleft width7">
                        {this.state.illCrfDataupdated[9663].value === "INR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        INR{" "}
                      </div>
                      <div className="fleft width7">
                        {this.state.illCrfDataupdated[9663].value === "US$" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        US${" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        One Time(A){" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Recurring (B){" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Security Deposit ©{" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Grand Total (A+B+C){" "}
                      </div>
                    </div>
                    <div className="check over_hid">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Service Rental
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9664].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9665].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9666].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9667].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        IP Address Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9668].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9669].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9670].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        SLA Charges{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9671].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9672].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9673].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Self Care Portal Service Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9674].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9675].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9676].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">BoD Charges</div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9677].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9678].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 fnt6">
                        <span className="fleft pad_5t">
                          Rate per Mbps / Day
                        </span>
                        <input
                          className="form_col wid35px flright"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9679].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9680].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        ToD Subscription Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9681].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9682].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 fnt6">
                        <span className="fleft pad_5t">
                          Rate per Mbps / Hour
                        </span>
                        <input
                          className="form_col wid35px flright"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9683].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9684].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Nth Percentile Subscription Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9685].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9686].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 fnt6">
                        <span className="fleft pad_5t">
                          Rate per Mbps / Month
                        </span>
                        <input
                          className="form_col wid35px flright"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9687].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9688].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        DDoS Service Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9689].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9690].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9691].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        SMTP Relay Service Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9692].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9693].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9694].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed Services Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9695].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9696].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9697].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed CPE Charges{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9698].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9699].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9700].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed Security Charges{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9700].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9701].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9702].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        CPE Rentals{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9704].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9705].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9706].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9707].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 1 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9708].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9709].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9710].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9711].value}
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 2 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9712].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9713].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9714].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9715].value}
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 3</div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9716].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9717].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9718].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9719].value}
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 4 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9720].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9721].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9722].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9723].value}
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 5 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9724].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9725].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9726].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9727].value}
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Miscellaneous Charges - 1{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9728].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9729].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9730].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Miscellaneous Charges - 2{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9731].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9732].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9733].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Shifting Charges{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9734].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9735].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        CPE Replacement Charges
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9736].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9737].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  ...
                  <br />
                  <br />
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    COMMENTS
                  </p>
                  <div className="cmt_spl">
                    {" "}
                    Comments/ Special Considerations:{" "}
                    {this.state.illCrfDataupdated[9739].value}{" "}
                  </div>
                  <div className="over_hid">
                    <div className="fleft width65 pad_t10">
                      <p className="margin0">
                        <strong>Declaration</strong>
                      </p>
                      <p className="margin0">
                        I / We confirm having received, read and understood the
                        Product Terms &amp; Conditions (provided overleaf) and
                        the General Terms &amp; Conditions. I / We further
                        confirm that the tariff plan selected and applicable
                        rates form part of this Agreement (as defined herein)
                        and I / We agree to abide by the Applicable Law in force
                        and also any statutory amendments, or new legistlations
                        as may be enacted from time to time, in so far as they
                        realte to the services. I / We hereby declare and
                        confirm that the above information provided by us is
                        true and correct in all respects and I / We hereby
                        undertake to be bound by the same.{" "}
                      </p>
                      <p className="margin0 pad_5t">
                        Authorised Signatory's Name
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9742].value}
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_t15">
                        <div className="fleft width30">
                          <p className="margin0">Designation</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.illCrfDataupdated[9743].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Place</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.illCrfDataupdated[9744].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Date</p>
                          <p className="margin0">
                            <input
                              className="form_col width96per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.illCrfDataupdated[9745].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                      <p className="margin0 pad_5t">Account Manager Name</p>
                      <div className="signate float_img">
                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <div
                            style={{ padding: "10px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9747].value}
                          readOnly
                        />
                      </p>
                      <p className="margin0 pad_20t">
                        Account Manager Circle Office
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col  wid120p cmnt_input"
                          type="text"
                          name=""
                          value={this.state.illCrfDataupdated[9748].value}
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_20t">
                        <div className="fleft width50">
                          <p className="margin0">Channel Partner Name</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.illCrfDataupdated[9750].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="flright width30">
                          <p className="margin0">Channel Partner Code</p>
                          <p className="margin0">
                            <input
                              className="form_col width92per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.illCrfDataupdated[9751].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flright width30">
                      <div style={{ padding: "5px" }} className="signate">
                        {firstCaps != "" ? (
                          <div className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          Signature of the Customer / Authorised signatory with
                          in this box only (In case of institutinal, please
                          affix office/company seal){" "}
                        </div>
                      </div>
                      <div
                        style={{ padding: "5px", height: "120px" }}
                        className="signate"
                      >
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            height="90px"
                            width="100px"
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Signature of Account Manager"}
                          />
                        ) : (
                          ""
                        )}
                        <div>Signature of the Account Manager </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter">
                    <strong>
                      Vodafone Idea Limited (formerly Idea Cellular Limited) An
                      Aditya Birla Group and Vodafone Partnership Merger Co CIN
                      No. L32100GJ1996PLC030976Registered Office: Suman Tower,
                      Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
                    </strong>
                  </p>
                </div>
              </div>
            </page>
            {this.state.excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">Annexure to ILL CRF :- </p>
                  </div>
                  <JsonToTable json={this.state.excelJson} />
                  <div>
                    <p class="font10 " style={{ marginTop: "20px" }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div class="fleft width50 pad_t10">
                    <p class="marb5">Account Manager Name</p>
                    <p class="margin0">
                      <input
                        class="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        readonly=""
                        value={this.state.illCrfDataupdated[9747].value}
                      />
                    </p>
                    <div className="signate">
                      <div className="signate_img input_sign">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Account Manager Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div className="over_hid ">
                        Authorised Signatory Name
                        {
                          <input
                            className="form_col inputext"
                            type="text"
                            name="Name_CD"
                            value={this.state.illCrfDataupdated[9742].value}
                            readOnly
                          />
                        }
                      </div>
                    </div>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
    return <div>{crfContentView}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IllCrfForm);
