import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    fileList: [],
    error: null,
    loadFiles: false,
    idCardData: null,
    fileParticipants: null,
    fileTimeline: [],
    currContent: null,
    currContentPage: 0,
    hasMoreFiles: false,
    totalContentPage: 0,
    filesCount: 0,
    scoreData: null,
    page_limit: 15,
    fileEditProcess: false,
    fileListParams: {
        activity_sub_type_id: -1,
        activity_type_category_id: 0,
        flag: 0,
        is_unread: 0,
        is_status: 1,
        page_start: 0
    },
    fileListRef: [],
    fileParticipantReset: false,

    workflowList: [],
    loadWorkflows: false,
    documentList: [],
    loadDocuments: false,

    workflowList: [],
    workflowListRef: [],
    workflowListCredit: [],
    loadWorkflows: false,
    hasMoreWorkflows: false,
    workflowsCount: 0,
    workflowListParams: [],

    documentsCount: 0,
    hasMoredocuments: false,
    documentListRef: [],
    documentListParams: {},

    assetList: [],
    assetListRef: [],
    loadAssets: false,
    hasMoreAssets: false,
    assetsCount: 0,
    assetListParams: {},

    timesheetData: [],
    managerResourceList: []
}

const reducer = (state = initialState,action) => {

    switch(action.type) {
        case actionTypes.FILES_FETCH_START:
            return {
                ...state,
                fileList: action.loadFiles === true ? [] : state.fileList,
                //loadFiles: action.loadFiles,
                loadFiles: true,
                hasMoreFiles: false
            }
        case actionTypes.FILES_FETCH_FAIL:
            return {
                ...state,
                error: action.error,
                loadTasks: false
            }
        case actionTypes.FILES_CLEAR_STORE:
            return {
                ...state,
                fileList: [],
                error: null,
                loadFiles: false,
                idCardData: null,
                fileParticipants: null,
                fileTimeline: null,
                currContent: null,
                currContentPage: 0,
                totalContentPage: 0,
                filesCount: 0,
                hasMoreFiles: false,
                scoreData: null,
                fileEditProcess: false,
                fileListParams: {
                    activity_sub_type_id: -1,
                    activity_type_category_id: 0,
                    flag: 0,
                    is_unread: 0,
                    is_status: 1,
                    page_start: 0
                },
            }
        case actionTypes.FILES_FETCH_SUCCESS:
            const TempFiles = [...state.fileList];
            let newFilesArray = [];
            if(action.fileListParams.page_start === 0) {
                newFilesArray = action.fileList;
            }
            else {
                newFilesArray = TempFiles.concat(action.fileList);
            }
            let moreFileCnt = false;
            if(action.fileList.length === state.page_limit) {

                moreFileCnt = true;
            }
            //newFilesArray = TempFiles.concat(action.fileList);
            const dataCount = action.fileList.length;
            return {
                ...state,
                fileList: newFilesArray,
                loadFiles: false,
                filesCount: dataCount,
                fileListParams: action.fileListParams,
                hasMoreFiles: moreFileCnt
            }
        case actionTypes.FILES_FETCH_APPEND:
            const newFileListAppend = [...state.fileList];
            return {
                ...state,
                fileList: newFileListAppend,
            }
        case actionTypes.ID_CARD_FETCH_START:
            return {
                ...state,
                idCardData: null
            }
        case actionTypes.ID_CARD_FETCH_SUCCESS:
            return {
                ...state,
                idCardData: action.idCardData
            }
        case actionTypes.ID_CARD_FETCH_SCORE_START:
            return {
                ...state,
                scoreData: null
            }
        case actionTypes.ID_CARD_FETCH_SCORE_SUCCESS:
            return {
                ...state,
                scoreData: action.scoreData
            }
        case actionTypes.FILE_PARTICIPANTS_FETCH_START:
            return {
                ...state,
                fileParticipants: null
            }
        case actionTypes.FILE_PARTICIPANTS_FETCH_SUCCESS:
            return {
                ...state,
                fileParticipants: action.participantList
            }
        case actionTypes.FILE_SET_LOCAL_PARTICIPANTS:
            const tempParticipants = [...state.fileParticipants];
            const newTemp = tempParticipants.concat(action.newParticipants);
            return {
                ...state,
                fileParticipants: newTemp
            }
        case actionTypes.FILE_TIMELINE_FETCH_START:
            return {
                ...state,
                fileTimeline: null,
                totalContentPage: 0,
                currContentPage: 0
            }
        case actionTypes.FILE_TIMELINE_FETCH_SUCCESS:
            const pageCount = action.timelineData.length;
            return {
                ...state,
                fileTimeline: action.timelineData,
                totalContentPage: pageCount,
                currContentPage: 0
            }
        case actionTypes.FILE_CONTENT_PAGINATION:
            let currPage = state.currContentPage;
            if(action.mode === 1) {
                if(state.currContentPage + 1 < state.totalContentPage) {
                    currPage = state.currContentPage + 1
                }
            }
            if(action.mode === -1) {
                if(state.currContentPage > 0) {
                    currPage = state.currContentPage - 1
                }
            }
            return {
                ...state,
                currContentPage: currPage
            }
        case actionTypes.FILE_TIMELINE_APPEND:
            //const pageCount = action.timelineData.length;
            const newFileTime = [...state.fileTimeline]
            const modifiedTimeline = newFileTime.unshift(action.contentData)
            return {
                ...state,
                fileTimeline: newFileTime,
                totalContentPage: modifiedTimeline,
                currContentPage: 0
            }
        case actionTypes.FILE_RESET_UNREAD_COUNT:
            const newFileList = [...state.fileList];
            const indexFi = state.fileList.findIndex(x => x.activity_id === action.activityId);
            if(indexFi > -1) {
                newFileList[indexFi].asset_unread_updates_count = 0;
            }
            return {
                ...state,
                fileList: newFileList
            }
        case actionTypes.FILE_RESET_COVER_DATA:
            const tempFileList = [...state.fileList];
            const indexFile = state.fileList.findIndex(x => x.activity_id === action.activityData.activity_id);
            if(indexFile > -1) {
                tempFileList[indexFile].activity_datetime_end_deferred = action.activityData.activity_datetime_end;
                tempFileList[indexFile].activity_inline_data = JSON.parse(action.activityData.activity_inline_data);
                tempFileList[indexFile].activity_title = action.activityData.activity_title;
                tempFileList[indexFile].activity_status_id = action.activityData.activity_status_id;
                tempFileList[indexFile].activity_status_name = action.activityData.activity_status_name;
                tempFileList[indexFile].parent_activity_id = action.activityData.activity_parent_id;
                tempFileList[indexFile].parent_activity_title = action.activityData.activity_parent_title;


            }
            return {
                ...state,
                fileList: tempFileList
            }
        case actionTypes.FILE_EDIT_COVER_START:
            return {
                ...state,
                fileEditProcess: true
            }
        case actionTypes.FILE_EDIT_COVER_END:
            return {
                ...state,
                fileEditProcess: false
            }
        case actionTypes.FILES_LIST_FOR_REFERENCE:
            return {
                ...state,
                fileListRef: action.fileList
            }
        case actionTypes.FILE_PARTICIPANT_RESET_START:
            return {
                ...state,
                fileParticipantReset: true
            }
        case actionTypes.FILE_PARTICIPANT_RESET_SUCCESS:
            return {
                ...state,
                fileParticipantReset: false
            }
        case actionTypes.WORKFLOWS_LIST_FOR_REFERENCE:
            return {
                ...state,
                workflowListRef: action.workflowListRef
            }
        case actionTypes.WORKFLOWS_LIST_FOR_Credit:
            return {
                ...state,
                workflowListCredit: action.workflowListCredit
            }
        case actionTypes.WORKFLOWS_FETCH_START:
            return {
                ...state,
                workflowList: action.loadWorkflows === true ? [] : state.WorkflowList,
                //loadFiles: action.loadFiles,
                loadWorkflows: true,
                hasMoreWorkflows: false
            }
        case actionTypes.FETCH_PRODUCT_CATEGORY_SUCCESS:
                return {
                  ...state,
                  productCategoryList: action.categoryList,
                  productWorkflowTypes: [],
                  productWorkflows: [],
                  productVariants: [],
                };
        case actionTypes.WORKFLOWS_FETCH_SUCCESS:
            const TempWorkflows = [...state.workflowList];
            let newWorkflowsArray = [];
            if(action.workflowListParams.page_start === 0) {
                newWorkflowsArray = action.workflowList;
            }
            else {
                newWorkflowsArray = TempWorkflows.concat(action.workflowList);
            }
            let moreWorkflowCnt = false;
            if(action.workflowList.length === state.page_limit) {

                moreWorkflowCnt = true;
            }
            //newWorkflowsArray = TempWorkflows.concat(action.workflowList);
            const dataWorkflowCount = action.workflowList.length;
            return {
                ...state,
                workflowList: newWorkflowsArray,
                loadWorkflows: false,
                workflowsCount: dataWorkflowCount,
                workflowListParams: action.workflowListParams,
                hasMoreWorkflows: moreWorkflowCnt
            }
        case actionTypes.WORKFLOWS_FETCH_FAIL:
            return {
                ...state,
                error: action.error,
                loadWorkflows: false
            }
        case actionTypes.WORKFLOWS_CLEAR_STORE:
            return {
                ...state,
                workflowListRef: []
            }
        case actionTypes.ASSET_LIST_FOR_REFERENCE:
            return {
                ...state,
                assetListRef: action.assetListRef
            }
        case actionTypes.ASSET_FETCH_START:
            return {
                ...state,
                assetList: action.loadAssets === true ? [] : state.assetList,
                //loadFiles: action.loadFiles,
                loadAssets: true,
                hasMoreAssets: false
            }
        case actionTypes.ASSET_FETCH_SUCCESS:
            const TempAssets = [...state.assetList];
            let newAssetsArray = [];
            if(action.assetListParams.page_start === 0) {
                newAssetsArray = action.assetList;
            }
            else {
                newAssetsArray = TempAssets.concat(action.assetList);
            }
            let moreAssetCnt = false;
            if(action.assetList.length === state.page_limit) {

                moreAssetCnt = true;
            }
            //newAssetsArray = TempAssets.concat(action.workflowList);
            const dataAssetCount = action.assetList.length;
            return {
                ...state,
                assetList: newAssetsArray,
                loadAssets: false,
                assetCount: dataAssetCount,
                assetListParams: action.assetListParams,
                hasMoreAssets: moreAssetCnt
            }
        case actionTypes.ASSET_FETCH_FAIL:
            return {
                ...state,
                error: action.error,
                loadAssets: false
            }
        case actionTypes.ASSET_CLEAR_STORE:
            return {
                ...state,
                assetListRef: []
            }
        // document
        case actionTypes.DOCUMENT_FETCH_START:
            return {
                ...state,
                documentList: action.loadDocuments === true ? [] : state.documentList,
                //loadDocuments: action.loadDocuments,
                loadDocuments: true,
                hasMoredocuments: false
            }
        case actionTypes.DOCUMENTS_FETCH_FAIL:
            return {
                ...state,
                error: action.error,
                loadTasks: false
            }
        case actionTypes.DOCUMENT_FETCH_SUCCESS:
            const Tempdocuments = [...state.documentList];
            let newdocumentsArray = [];
            if(action.documentListParams.page_start === 0) {
                newdocumentsArray = action.documentList;
            }
            else {
                newdocumentsArray = Tempdocuments.concat(action.documentList);
            }
            let moredocumentCnt = false;
            if(action.documentList.length === state.page_limit) {

                moredocumentCnt = true;
            }
            //newdocumentsArray = Tempdocuments.concat(action.documentList);
            const dataCountDo = action.documentList.length;
            return {
                ...state,
                documentList: newdocumentsArray,
                loadDocuments: false,
                documentsCount: dataCountDo,
                documentListParams: action.documentListParams,
                hasMoredocuments: moredocumentCnt
            }

        case actionTypes.DOCUMENTS_LIST_FOR_REFERENCE:
            return {
                ...state,
                documentListRef: action.documentList
            }

        // Timesheet Data
        case actionTypes.FETCH_TIMESHEET_DATA_START:
            return {
                ...state,
                timesheetData: []
            }
        case actionTypes.FETCH_TIMESHEET_DATA_SUCCESS:
            return {
                ...state,
                timesheetData: action.timesheetData
            }

        case actionTypes.MANAGER_RESOURCE_LIST_SUCCESS:
            return {
                ...state,
                managerResourceList: action.managerResourceList
            }
        case actionTypes.FETCH_COMPOSIT_LOV_STATE_LIST_START:
                return {
                  ...state,
                  compositLovStateList: [],
                };
        case actionTypes.FETCH_COMPOSIT_LOV_STATE_LIST_SUCCESS:
                return {
                  ...state,
                  compositLovStateList: action.compositLovStateList,
                };
        case actionTypes.FETCH_COMPOSIT_LOV_CITY_LIST_START:
                return {
                  ...state,
                  compositLovCityList: [],
                };
        case actionTypes.FETCH_COMPOSIT_LOV_CITY_LIST_SUCCESS:
                return {
                  ...state,
                  compositLovCityList: action.compositLovCityList,
                };
        case actionTypes.FETCH_COMPOSIT_LOV_ZIPCODE_LIST_START:
            return {
                ...state,
                compositLovPincodeList: [],
            };
            case actionTypes.FETCH_COMPOSIT_LOV_ZIPCODE_LIST_SUCCESS:
            return {
                ...state,
                compositLovPincodeList: action.compositLovZipCodeList,
            };
            case actionTypes.FETCH_PRODUCT_VARIANTS_START:
                return {
                    ...state,
                    productVariants: [],
                };
            case actionTypes.FETCH_PRODUCT_VARIANTS_SUCCESS:
                return {
                    ...state,
                    productVariants: action.productVariants,
                };
                case actionTypes.FETCH_PRODUCT_WORKFLOW_TYPE_START:
                    return {
                      ...state,
                      productWorkflowTypes: [],
                    };
            case actionTypes.FETCH_PRODUCT_WORKFLOW_TYPE_SUCCESS:
                return {
                    ...state,
                    productWorkflowTypes: action.workflowTypes,
                    productWorkflows: [],
                    productVariants: [],
                };
            case actionTypes.FETCH_PRODUCT_WORKFLOW_START:
                return {
                    ...state,
                    productWorkflows: [],
                };
            case actionTypes.FETCH_PRODUCT_WORKFLOW_SUCCESS:
                return {
                    ...state,
                    productWorkflows: action.workflows,
                    productVariants: [],
                };
            case actionTypes.FETCH_COMPOSIT_SLOT_LIST_START:
                return {
                    ...state,
                    slotListData: action.slotListData,
                };
            case actionTypes.FETCH_COMPOSIT_SLOT_LIST_SUCCESS:
                return {
                    ...state,
                    slotListData: action.slotListData,
                };
            case actionTypes.LOV_DATATYPE_LIST_START:
                return {
                    ...state,
                    lovListRef: [],
                };
                case actionTypes.LOV_DATATYPE_LIST:
                return {
                    ...state,
                    lovListRef: action.lovListRef,
                };
        default:
            return state;


    }

}
export default reducer;