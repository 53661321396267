import React, { Component } from "react";
import "./vil-branding.css";
import vilLogo from "../../../../assets/vil/vil_logo.svg";

export default class CafCoverPage extends Component {
  render() {
    return (
      <page size="A4" className="cover-page">
        <div className="page-container">
          <div className="vil">
            <img src={vilLogo} alt="" className="vil-logo" />
          </div>
          <div className="view-type">
            <h3 className="view-type-name">Customer Application Form</h3>
          </div>
          <div className="view-detail">
            <h1 className="view-type-company">
              Vi{" "}
              <sup>
                <small style={{ color: "#fff" }}>TM</small>
              </sup>{" "}
              business
            </h1>
            <h1 className="product-name">{this.props.formName}</h1>
          </div>
        </div>
      </page>
    );
  }
}
