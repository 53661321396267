import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AWS from 'aws-sdk';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from '../../../axios_ext';
import * as utils from '../../../shared/Utils';
import * as actions from '../../../store/Actions/index';
import FieldMapping from './FieldMapping';
import './Forms.scss';

class FormDetail extends Component {
	state = {
		formSet: [],
		downloadLoader: false,
		showEditAll: true,
		finalFormList: [],
	};

	constructor(props) {
		super(props);
		AWS.config.update({
			accessKeyId: utils.s3AccessKey(),
			secretAccessKey: utils.s3SecretKey(),
		});

		this.s3 = new AWS.S3();
	}

	componentDidMount() {
		this.props.onFormValuesFetch(
			this.buildFormFieldFetchParam(this.props.formData),
		);
		this.props.onFormFieldsFetch(
			this.buildFormFieldFetchParam(this.props.formData),
		);
		this.getFormFields();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(this.props.formDataValues) !==
			JSON.stringify(prevProps.formDataValues)
		) {
			this.getFormFields();
		}
	}

	getFormFields = () => {
		setTimeout(() => {
			const controlFieldIds = Object.keys(this.props.fieldList);
			const submittedControlIds = this.props.formDataValues.map(
				(item) => item.field_id,
			);
			const distinctFieldIds = controlFieldIds
				.map((item) => {
					if (!submittedControlIds.toString().includes(item.replace('i', ''))) {
						return item;
					}
				})
				.filter(Boolean);
			let formControlList = this.props.formDataValues.map((item) => {
				const fieldListItem = this.props.fieldList[`${item.field_id}i`];
				if (!!fieldListItem) {
					return {
						...item,
						field_name: fieldListItem.label,
					};
				}
				return item;
			});
			if (distinctFieldIds.length > 0) {
				const distinctFields = distinctFieldIds.map((fieldId) => {
					const field = this.props.fieldList[fieldId];
					return {
						form_id: field.form_id,
						activity_id: field.activity_id,
						field_id: field.field_id,
						field_name: field.label,
						field_value: '',
						message_unique_id: 0,
						data_type_combo_id: 0,
						data_type_combo_value: '0',
						field_value_edit_enabled: 1,
						field_inline_data: '{}',
						field_validated: field.valid ? 1 : 0,
						field_data_type_id: field.elementType,
						field_data_type_category_id: field.elementCatType,
					};
				});
				formControlList = [...formControlList, ...distinctFields];
			}
			this.setState({
				finalFormList: formControlList,
			});
		}, 1000);
	};

	buildFormFieldFetchParam = (formData) => {
		if (this.props.entry && this.props.entry === 'external') {
			return {
				organization_id: formData.org_id,
				account_id: formData.account_id,
				workforce_id: formData.workforce_id,
				asset_id: formData.asset_id,
				asset_token_auth: formData.auth_token,
				form_id: formData.form_id,
				form_transaction_id: formData.form_transaction_id,
				start_from: 0,
				limit_value: 50,
			};
		} else {
			return {
				organization_id: this.props.orgId,
				account_id: this.props.accId,
				workforce_id: this.props.workforceId,
				asset_id: this.props.userData.asset_id,
				asset_token_auth: this.props.authToken,
				form_id: formData.id,
				form_transaction_id: formData.form_transaction_id,
				start_from: 0,
				limit_value: 50,
			};
		}
	};

	showFile = (blob, fileName) => {
		var newBlob = new Blob([blob], { type: 'application/zip' });
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		// For other browsers:

		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement('a');
		link.href = data;
		link.download = fileName;
		link.click();
		setTimeout(
			// For Firefox it is necessary to delay revoking the ObjectURL
			() => {
				window.URL.revokeObjectURL(data);
				this.setState({
					...this.state,
					downloadLoader: false,
				});
			},
			100,
		);
	};

	async handleUrlExists(url) {
		let BucketName, KeyName, FileName;
		[BucketName, KeyName, FileName] = await utils.getBucketKeyFileName(url);
		this.s3.getObject(
			{
				Bucket: BucketName,
				Key: KeyName,
			},
			(err, data) => {
				if (err) {
					console.log('error while loading object', err);
				} else {
					let ContentType = data.ContentType;
					if (ContentType === 'application/zip') {
						this.showFile(data.Body, FileName);
					}
				}
			},
		);
	}

	downloadZip = async (x) => {
		let attachments = [];
		if (this.props.formDataValues.length > 0) {
			let fields = this.props.formDataValues;
			for (let i = 0; i < fields.length; i++) {
				let isValidCategory = [11, 12, 13].includes(
					Number(fields[i].field_data_type_category_id),
				);
				if (isValidCategory && !!fields[i].field_value) {
					attachments.push(fields[i].field_value);
				}
			}

			if (attachments.length > 0) {
				this.setState({
					...this.state,
					downloadLoader: true,
				});

				let params = {
					organization_id: this.props.formData.org_id,
					account_id: this.props.formData.account_id,
					workforce_id: this.props.formData.workforce_id,
					asset_id: this.props.formData.asset_id,
					asset_token_auth: this.props.formData.auth_token,
					attachments: attachments,
				};
				Axios.post('activity/form/download/attachements', params)
					.then((res) => {
						console.log(res.data.response);
						let url = res.data.response;
						if (url !== '') {
							this.handleUrlExists(url);
						}
					})
					.catch((err) => {
						console.log(err);

						this.setState({
							...this.state,
							downloadLoader: false,
						});
					});
			} else {
				alert('No attachments available for this Form!');
			}
		}
	};
	reloadComponent = (showEdit) => {
		this.setState({
			showEditAll: showEdit,
		});
		this.getFormFields();
	};

	render() {
		let tableFields = null;
		let formTitle = this.props.formData.form_name;
		const disabled =
			!!this.props.formData && this.props.formData.disabledProperty;
		const finalFormList = this.state.finalFormList;
		if (finalFormList && finalFormList.length > 0) {
			tableFields = finalFormList.map((field) => {
				const fieldItem = this.props.fieldList[`${field.field_id}i`];
				return (
					<FieldMapping
						showEditAll={disabled ? false : this.state.showEditAll}
						reloadComponent={this.reloadComponent}
						validation={false}
						urlFormData={this.props.formData}
						key={field.field_id + 'val'}
						fieldData={field}
						fieldItem={fieldItem}
					/>
				);
			});
		}

		let downloadButton;
		if (this.state.downloadLoader) {
			downloadButton = (
				<div>
					<LinearProgress />
				</div>
			);
		} else {
			downloadButton = (
				<Button
					onClick={disabled ? null : this.downloadZip}
					variant='contained'
					style={{
						pointer: 'cursor',
						fontSize: '12px',
						float: 'right',
					}}
				>
					Download attachments
				</Button>
			);
		}

		return (
			<>
				{disabled ? null : downloadButton}
				<form>
					<table className='Formtable'>
						<tbody>{tableFields}</tbody>
					</table>
				</form>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authToken: state.loginAuth.authToken,
		empAssetId: state.loginAuth.empAssetId,
		deskAssetId: state.loginAuth.deskAssetId,
		loading: state.loginAuth.loading,
		workforceId: state.loginAuth.workforceId,
		orgId: state.loginAuth.orgId,
		accId: state.loginAuth.accId,
		userData: state.loginAuth.userData,
		fieldList: state.forms.fieldList,
		formDataValues: state.queue.formDataValues,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onFormFieldsFetch: (requestData) =>
			dispatch(actions.formFieldListFetch(requestData)),
		onFormValuesFetch: (requestData) =>
			dispatch(actions.getFormDataWithValues(requestData)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(FormDetail);
