import AWS from "aws-sdk";
import Axios from "axios";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactExport from "react-data-export";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import AxiosWeb from "../../../../../axios_ext";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import SectionHeader from "../../vilBranding/SectionHeader";
import "../../common.css";
import "./vsdm.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class VSDM extends Component {
  state = {
    vsdmCafData: false,
    stopdidmount: 0,
    vsdmDataUpdated: 1,
    authorizedSignName: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    companySeal: null,
    imageData: null,
    imageType: null,
    acctMngSignImageData: null,
    acctMngSignImageType: null,
    photoGraph: null,
    photoGraphImageData: null,
    photoGraphImageType: null,
    excelJson: null,
    exturlLink: null,
    agentSign: null,
    agentImageData: null,
    agentImageType: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };
  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1730,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }

        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 18337) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 18338) {
            photoGraph = fieldObj.field_value;
            console.log("photoGraph:: " + photoGraph);
          }
          if (fieldObj.field_id == 18336) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("companySeal:: " + companySeal);
          console.log("photoGraph:: " + photoGraph);
          console.log("authorizedSignatoryName:: " + authorizedSignatoryName);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          custPhotoGraph: photoGraph,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.photoGraph);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 1726; // DIGITAL VSDM CAF form Id
    let orderform = 1847;
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        vsdmCafData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12963
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+JSON.stringify(submittedFormArry));
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );
        this.setState({
          ...this.state,
          vsdmCafData: true,
        });
      }

      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12963
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("19505") &&
      this.state.stopdidmount === 0
    ) {
      let x = this.props.fieldList;

      if (!!this.state.custPhotoGraph) {
        utils.loadImageBlob(
          this.s3,
          this.state.custPhotoGraph,
          (data, type) => {
            this.setState({
              ...this.state,
              photoGraphImageData: data,
              photoGraphImageType: type,
              stopdidmount: 1,
              vsdmDataUpdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          vsdmDataUpdated: this.props.fieldList,
        });
      }

      if (!!this.state.custCompanySeal) {
        utils.loadImageBlob(
          this.s3,
          this.state.custCompanySeal,
          (data, type) => {
            this.setState({
              ...this.state,
              imageData: data,
              imageType: type,
              stopdidmount: 1,
              vsdmDataUpdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          vsdmDataUpdated: this.props.fieldList,
        });
      }

      if (!!x[19579] && x[19579].value) {
        // manager sign url
        let acctManagerSignUrl = x[19579].value;
        utils.loadImageBlob(this.s3, acctManagerSignUrl, (data, type) => {
          this.setState({
            ...this.state,
            acctMngSignImageData: data,
            acctMngSignImageType: type,
            stopdidmount: 1,
            vsdmDataUpdated: this.props.fieldList,
          });
        });
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          vsdmDataUpdated: this.props.fieldList,
        });
      }

      if (!!x[19600]) {
        let agentUrl = x[19600].value || "";
        utils.loadImageBlob(this.s3, agentUrl, (data, type) => {
          this.setState({
            ...this.state,
            agentImageData: data,
            agentImageType: type,
            stopdidmount: 1,
            vsdmDataUpdated: this.props.fieldList,
          });
        });
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          vsdmDataUpdated: this.props.fieldList,
        });
      }
    }
  }

  render() {
    let planTariff = "";
    //console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
    //console.log(" IN RENDER :: " , this.state.vsdmDataUpdated);
    //console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
    let vsdmCafContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );

    if (
      this.state.vsdmDataUpdated != null &&
      this.state.vsdmDataUpdated !== "" &&
      this.state.stopdidmount == 1
    ) {
      let x = this.state.vsdmDataUpdated;

      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }
      let firstCaps = "";
      let secondBold = "";
      let Signcss;

      //console.log('this.state.authorizedSignName :: '+this.state.authorizedSignName);

      if (
        this.state.authorizedSignName != null &&
        this.state.authorizedSignName != ""
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        //console.log(firstCaps,"console.log(firstCaps);");
      }

      vsdmCafContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ paddingTop: "57px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsStyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsStyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <SectionHeader name="Customer Application Form" />
                  <div className="over_hid ">
                    <div className="fleft width50">
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Unique ID
                          <input
                            className="form_col width35"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19582].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid">
                    <div className="fleft width75">
                      <p className="ligthbg"> Name and Address </p>
                      <p className="auth">
                        Name of Company
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19583].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Company’s Registered
                        <input
                          className="form_col width75 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19509].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Address
                        <input
                          className="form_col width90 flright"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </p>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width25">
                          Landmark
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19510].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          City
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19511].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          State
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19512].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          Pin Code
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19513].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width40">
                          Landline No. +91
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19514].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width40">
                          Fax Number
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19515].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width40">
                          CIN Number
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19516].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          Company PAN Card Number
                          <input
                            className="form_col width45"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19517].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright cross_fill">
                      <div className="crossign" style={{ height: "100px" }}>
                        {this.state.photoGraphImageData != null &&
                        this.state.photoGraphImageData ? (
                          <div
                            style={{
                              padding: "5px",
                              position: "relative",
                              textAlign: "center",
                            }}
                            className="signate_img"
                          >
                            <img
                              style={{ height: "90px" }}
                              src={
                                this.state.photoGraphImageData != null
                                  ? "data:" +
                                    this.state.photoGraphImageType +
                                    ";base64," +
                                    this.encode(this.state.photoGraphImageData)
                                  : ""
                              }
                              alt={"PhotoGraph with Crosssign"}
                            />
                            {firstCaps != "" ? (
                              <span
                                style={{
                                  padding: "10px",
                                  position: "absolute",
                                  left: "-20px",
                                  top: "20px",
                                }}
                                className={Signcss}
                              >
                                {firstCaps + "  .  "}
                                <span className="under_line">{secondBold}</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">Basic Organisation Details</p>
                  <div className="auth margin0 over_hid pad_5t">
                    <p className="margin0">
                      <strong>Address 1 (Authorised Signatory location)</strong>
                    </p>
                    <div className="fleft width48">
                      <p className="auth">
                        Name
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19520].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Father’s/
                        <input
                          className="form_col width84 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19521].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Husband’s
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Name
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Nationality
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19522].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Address
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19523].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value=""
                        />
                      </p>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width60">
                          Landmark
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19524].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width40">
                          City
                          <input
                            className="form_col width75"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19525].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width45">
                          State
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19526].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          Pin Code
                          <input
                            className="form_col width68 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19527].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width45"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19528].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          Landline No. +91
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19529].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p className="auth">
                        Alternate Contact No
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19530].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        E-Mail ID
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19531].value}
                          readOnly
                        />
                      </p>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width40">
                          Designation
                          <input
                            className="form_col width35"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19532].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width30">
                          Emp. Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19533].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width30">
                          Vehicle No.
                          <input
                            className="form_col width30 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19534].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright width48">
                      <p className="auth">
                        Name
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19536].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Address
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19537].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value=""
                        />
                      </p>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width60">
                          Landmark
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19538].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width40">
                          City
                          <input
                            className="form_col width75"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19539].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width45">
                          State
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19540].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          Pin Code
                          <input
                            className="form_col width68 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19541].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width45"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19542].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          Landline No. +91
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19543].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p className="auth">
                        {" "}
                        E-Mail ID
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19544].value}
                          readOnly
                        />
                      </p>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width40">
                          Designation
                          <input
                            className="form_col width35"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19545].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width30">
                          Emp. Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19546].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width30">
                          Vehicle No.
                          <input
                            className="form_col width30 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19547].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="check over_hid custype font7 pad_t10">
                        <div className="fleft pad_r5">
                          Organization Type
                          {this.state.vsdmDataUpdated[19548].value ===
                          "Partner Ship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Partnership
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19548].value ===
                          "Proprietorship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Proprietorship
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19548].value ===
                          "Private Limited" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Private Limited
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19548].value ===
                          "Public Sector" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Public Sector
                        </div>
                      </div>
                      <div className="check over_hid custype font7 pad_t3">
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19548].value ===
                          "Government" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Government
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19548].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others
                          <span className="in_subput_date">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.vsdmDataUpdated[19549].value}
                              readOnly
                            />
                          </span>
                        </div>
                      </div>
                      <div className="check over_hid custype pad_t3">
                        <div className="fleft pad_r5 pad_t3">
                          {" "}
                          <strong>Telemarketer</strong>{" "}
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19550].value === "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19550].value === "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <p className="auth">
                        If Yes, Telemarketer Registration Number
                        <input
                          className="form_col width40 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19551].value}
                          readOnly
                        />
                      </p>
                      <div className="check over_hid custype pad_t3">
                        <div className="fleft pad_r5 pad_t3">
                          {" "}
                          <strong>OSP</strong>{" "}
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19552].value === "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft pad_r5">
                          {this.state.vsdmDataUpdated[19552].value === "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <p className="auth">
                        If Yes, OSP Registration Number
                        <input
                          className="form_col width50 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19553].value}
                          readOnly
                        />
                      </p>
                    </div>
                  </div>
                  <p className="ligthbg">Current Requirement</p>
                  <p className="auth">
                    1. Number of "SDM" licenses required
                    <input
                      className="form_col width10"
                      type="text"
                      name=""
                      value={this.state.vsdmDataUpdated[19555].value}
                      readOnly
                    />
                  </p>
                  <div className="check over_hid custype pad_5t">
                    <div className="fleft width13 pad_r5">
                      {" "}
                      2. Applicable Slab{" "}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      50
                      {this.state.vsdmDataUpdated[19556].value === "50" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      50-100
                      {this.state.vsdmDataUpdated[19556].value ===
                      "50 - 100" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      100-250
                      {this.state.vsdmDataUpdated[19556].value ===
                      "100 - 250" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      250-500
                      {this.state.vsdmDataUpdated[19556].value ===
                      "250 - 500" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      500-1000
                      {this.state.vsdmDataUpdated[19556].value ===
                      "500 - 1000" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      1000-5000
                      {this.state.vsdmDataUpdated[19556].value ===
                      "1000 - 5000" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check over_hid custype pad_5t">
                    <div className="fleft width13 pad_r5"> &nbsp; </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      5000-10000
                      {this.state.vsdmDataUpdated[19556].value ===
                      "5000 - 10000" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      10000-25000
                      {this.state.vsdmDataUpdated[19556].value ===
                      "10000 - 25000" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      25000-50000
                      {this.state.vsdmDataUpdated[19556].value ===
                      "25000 - 50000" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      50000+
                      {this.state.vsdmDataUpdated[19556].value === "50000+" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check over_hid custype pad_5t">
                    <div className="fleft width13 pad_r5">
                      3. Contract Duration{" "}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      1 Month
                      {this.state.vsdmDataUpdated[19557].value === "1 Month" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      3 Months
                      {this.state.vsdmDataUpdated[19557].value ===
                      "3 Months" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      6 Months
                      {this.state.vsdmDataUpdated[19557].value ===
                      "6 Months" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      12 Months
                      {this.state.vsdmDataUpdated[19557].value ===
                      "12 Months" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      24 Months
                      {this.state.vsdmDataUpdated[19557].value ===
                      "24 Months" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      36 Months
                      {this.state.vsdmDataUpdated[19557].value ===
                      "36 Months" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      60 Months
                      {this.state.vsdmDataUpdated[19557].value ===
                      "60 Months" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check over_hid custype pad_5t">
                    <div className="fleft width13 pad_r5">
                      4. Advance Payment{" "}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      Y
                      {this.state.vsdmDataUpdated[19558].value === "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      N
                      {this.state.vsdmDataUpdated[19558].value === "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check over_hid custype pad_5t">
                    <div className="fleft width13 pad_r5">
                      5. Trial Required{" "}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      Y
                      {this.state.vsdmDataUpdated[19559].value === "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {" "}
                      N
                      {this.state.vsdmDataUpdated[19559].value === "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <p className="ligthbg">Payment Details</p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width70">
                      Name of Person Responsible for Payments
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19561].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Mobile No. +91
                      <input
                        className="form_col width60"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19562].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid font5 pad_t10">
                    <div className="fleft pad_r5 pad_t3"> Billing Address </div>
                    <div className="fleft pad_r5">
                      {this.state.vsdmDataUpdated[19563].value ===
                      "Authorised Sign Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Authorised Sign Location{" "}
                    </div>
                    <div className="fleft pad_r5">
                      {this.state.vsdmDataUpdated[19563].value ===
                      "Key Contact Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Key Contact location{" "}
                    </div>
                    <div className="fleft pad_r5">
                      {this.state.vsdmDataUpdated[19563].value ===
                      "Company Registered Address" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Company Registered Address{" "}
                    </div>
                    <div className="fleft pad_r5 pad_l10 pad_t3">
                      {" "}
                      Payment Mode
                    </div>
                    <div className="fleft pad_r5">
                      {this.state.vsdmDataUpdated[19564].value === "ECS" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ECS{" "}
                    </div>
                    <div className="fleft pad_r5">
                      {this.state.vsdmDataUpdated[19564].value === "SI" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SI{" "}
                    </div>
                    <div className="fleft pad_r5">
                      {this.state.vsdmDataUpdated[19564].value === "CHEQUE" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Cheque (for ECS and SI, please fill additional form){" "}
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft pad_r5 pad_t3 auth">
                      {" "}
                      Deposit Amount
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19565].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft pad_r5 check pad_t3">
                      {" "}
                      Deposit Type
                    </div>
                    <div className="fleft pad_r5 check pad_t3">
                      {" "}
                      Security
                      {this.state.vsdmDataUpdated[19566].value ===
                      "Security" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_r5 check pad_t3">
                      {" "}
                      IR deposit
                      {this.state.vsdmDataUpdated[19566].value ===
                      "IR deposit" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_r5 check pad_t3 ">
                      Others
                      {this.state.vsdmDataUpdated[19566].value === "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      <span className="in_subput">
                        <input
                          className="form_col width55"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19567].value}
                          readOnly
                        />
                      </span>
                    </div>
                  </div>
                  <p className="ligthbg">Customer Declaration</p>
                  <div className="over_hid pad_5t">
                    <div className="fleft width75 fon8">
                      {" "}
                      I/We agree to pay the one time installation fee,
                      refundable security deposit and all charges associated
                      with the services selected by me/us. I/We have read and
                      fully understood the terms and conditions mentioned
                      overleaf and unconditionally accept them as binding on
                      me/us. I/We have understood all the rates, tariffs and
                      other related conditions at which telecommunications
                      services will be provided inside and outside India as
                      applicable as on this date and as amended from time to
                      time. I/We hereby undertake to pay all charges raised on
                      account of Services availed. I/We undertake that in the
                      event this connection is used for any telemarketing
                      purpose, I/We shall be solely responsible for registration
                      of the connection with the mandated statutory authorities.
                      I/We further declare and undertake that above information
                      provided by me/us is true and correct in all respect. I/We
                      understand that the SIM is non-transferable. Any misuse of
                      SIM by customer or any other person is illegal and shall
                      be liable for criminal actions.
                      <div className="check over_hid">
                        <div className="pad_t10">
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            checked
                            value=""
                            readOnly
                          />
                          I agree to receive a welcome mail giving details of
                          service(s) subscribed
                        </div>
                      </div>
                    </div>
                    <div className="flright width25">
                      <div className="signature_box">
                        <table>
                          <tbody>
                            <tr>
                              <td width="50%">
                                {firstCaps != "" ? (
                                  <div
                                    style={{
                                      padding: "10px",
                                      position: "absolute",
                                    }}
                                    className={Signcss}
                                  >
                                    {firstCaps + "  .  "}
                                    <span className="under_line">
                                      {secondBold}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="50%">
                                {this.state.imageData != null &&
                                this.state.imageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      src={
                                        this.state.imageData != null
                                          ? "data:" +
                                            this.state.imageType +
                                            ";base64," +
                                            this.encode(this.state.imageData)
                                          : ""
                                      }
                                      alt={"Company Seal"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col flright"
                                      type="text"
                                      name=""
                                      value=""
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">Vodafone Internal Use</p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width60">
                      Enterprise Description Code
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19573].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Large Account Code
                      <input
                        className="form_col width60"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19574].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width60">
                      {" "}
                      FA/BA/PCN ID
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19575].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width50 fon8">
                      <div className="over_hid pad_t10">
                        <div className="fleft width55 fon8">
                          {" "}
                          Verification by Dealer/Agent (Distributor/Retailer): I
                          confirm that the applicant has signed the form and
                          documents attached in my presence and that the photo
                          affixed in this form is of the applicant. I have
                          verified the documents with their respective originals
                          and certify the same to be true. I further confirm
                          that all necessary documents are in order including
                          that the signatures on the self attested photo match
                          with the signature on application form and photo on
                          the form matches the one on the photo ID document.{" "}
                        </div>
                        <div className="flright width45">
                          <div className="sealtwo">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    {this.state.acctMngSignImageData != null &&
                                    this.state.acctMngSignImageData != "" ? (
                                      <img
                                        height="50px"
                                        width="75px"
                                        src={
                                          this.state.acctMngSignImageData !=
                                            null &&
                                          this.state.acctMngSignImageData != ""
                                            ? "data:" +
                                              this.state.acctMngSignImageType +
                                              ";base64," +
                                              this.encode(
                                                this.state.acctMngSignImageData
                                              )
                                            : ""
                                        }
                                        alt={"Sign"}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div>Sign with Seal/Stamp </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="in_subput_date">
                                      Date:
                                      <input
                                        className="form_col width15 "
                                        type="text"
                                        name=""
                                        value={
                                          this.state.vsdmDataUpdated[19580]
                                            .value
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flright width48 fon8">
                      <p className="ligthbg">
                        Declaration of Vodafone employee activating the SIM
                      </p>
                      <div className="over_hid pad_t10">
                        <div className="fleft width40 fon8">
                          I hereby confirm that the required documentation such
                          as POI/POA is available and that details of the
                          customer is complete and have been entered in the data
                          base.
                        </div>
                        <div className="flright width60">
                          <div className="sealfour">
                            <div className="vodatex">
                              <div>
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <img
                                    height="25px"
                                    width="65px"
                                    src={
                                      this.state.acctMngSignImageData != null &&
                                      this.state.acctMngSignImageData != ""
                                        ? "data:" +
                                          this.state.acctMngSignImageType +
                                          ";base64," +
                                          this.encode(
                                            this.state.acctMngSignImageData
                                          )
                                        : ""
                                    }
                                    alt={"Sign"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="vodasign"> Sign</div>
                            <div className="vodahedcol">
                              <p className="auth">Employee Name:</p>
                              <p className="in_subput_date">
                                <input
                                  className="form_col width80"
                                  type="text"
                                  name=""
                                  value={
                                    this.state.vsdmDataUpdated[19577].value
                                  }
                                  readOnly
                                />
                              </p>
                              <p className="in_subput_date">
                                Designation
                                <input
                                  className="form_col width35"
                                  type="text"
                                  name=""
                                  value={
                                    this.state.vsdmDataUpdated[19578].value
                                  }
                                  readOnly
                                />
                              </p>
                              <p className="in_subput_date">
                                Date
                                <input
                                  className="form_col width60"
                                  type="text"
                                  name=""
                                  value={
                                    this.state.vsdmDataUpdated[19580].value
                                  }
                                  readOnly
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="fnt7 margin0">Circle Address:</p>
                  <br />
                  <br />
                  <p className="ligthbg">
                    Counterfoil to be retained with Customer
                  </p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width20">
                      Unique ID
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19582].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Name of the Company
                      <input
                        className="form_col width60"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19583].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Name of Authorised Signatory
                      <input
                        className="form_col width45"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19584].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width50">
                      <p className="ligthbg">Proof of Identity Details</p>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width50">
                          Type of PoI
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19586].value}
                            readOnly
                          />
                        </div>
                        <div className="flright width50">
                          Document No
                          <input
                            className="form_col width55 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19587].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width50">
                          Date of Issue
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19588].value}
                            readOnly
                          />
                        </div>
                        <div className="flright width50">
                          Place of Issue
                          <input
                            className="form_col width55 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19589].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p className="auth">
                        Issuing Authority
                        <input
                          className="form_col width72 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19590].value}
                          readOnly
                        />
                      </p>
                    </div>
                    <div className="flright width48 fon8">
                      <p className="ligthbg">Proof of Address Details</p>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width50">
                          Type of PoA
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19592].value}
                            readOnly
                          />
                        </div>
                        <div className="flright width50">
                          Document No
                          <input
                            className="form_col width55 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19593].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid">
                        <div className="fleft width50">
                          Date of Issue
                          <input
                            className="form_col width55"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19594].value}
                            readOnly
                          />
                        </div>
                        <div className="flright width50">
                          Place of Issue
                          <input
                            className="form_col width55 flright"
                            type="text"
                            name=""
                            value={this.state.vsdmDataUpdated[19595].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p className="auth">
                        Issuing Authority
                        <input
                          className="form_col width72 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19596].value}
                          readOnly
                        />
                      </p>
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width60 pad_t40">
                      <p className="auth relati">
                        {" "}
                        Address &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp;{" "}
                        <span className="asent">
                          (As entered above <br />
                          and as per POA)
                        </span>
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19597].value}
                          readOnly
                        />
                      </p>
                    </div>
                    <div className="flright width40">
                      <div className="sealfour">
                        {this.state.agentImageData != null &&
                        this.state.agentImageData != "" ? (
                          <div
                            style={{ padding: "0px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.agentImageData != null
                                  ? "data:" +
                                    this.state.agentImageType +
                                    ";base64," +
                                    this.encode(this.state.agentImageData)
                                  : ""
                              }
                              alt={"Agent Sign with Address"}
                              width="30%"
                              height="90%"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        <div> Sign & Stamp (with Address)</div>
                        <div className="vodahedcol">
                          <p className="in_subput_date">
                            Agent Name:
                            <input
                              className="form_col"
                              type="text"
                              name=""
                              value={this.state.vsdmDataUpdated[19598].value}
                              readOnly
                            />
                          </p>
                          <br />

                          <p className="in_subput_date">
                            Sales Code:
                            <input
                              className="form_col"
                              type="text"
                              name=""
                              value={this.state.vsdmDataUpdated[19599].value}
                              readOnly
                            />
                          </p>
                          <br />

                          <p className="in_subput_date">
                            Date
                            <input
                              className="form_col"
                              type="text"
                              name=""
                              value={this.state.vsdmDataUpdated[19601].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">Terms & conditions</p>
                  <p>
                    {" "}
                    The “Customer Application Form” (CAF) shall mean the
                    Customer information form, to which the following conditions
                    (“Regulatory Terms”) are required to be provided/completed
                    with effect from /prior to activation of any Services, and
                    continue during the term of service. These are conditions
                    specified by the Department of Telecommunication (“DOT”)
                    Ministry of Communication and Information Technology
                    (“MCIT”), Government of India (“GoI”) and are mandatory
                    conditions. There shall be no deletion or deviation from the
                    following Regulatory Terms. This CAF shall be read with the
                    Product Specific Terms which shall form the Agreement
                    between the Parties. Licensed Service Area - shall mean the
                    area where Operator is permitted to offer telecommunication
                    services. (i) Operator - shall mean VIL entity having
                    license to operate in Circle. • ‘Authority’ shall mean the
                    Department of Telecommunications (DoT), Telecom Regulatory
                    Authority of India (TRAI), Ministry of Communication and
                    Information Technology (MOCIT), Government of India (GOI)
                    and includes any officer of the Authority.• “Corporate Plan”
                    shall mean tariff structure devised by for Corporate
                    Customers. • ‘Charges’ means all fees, charges/ tariffs,
                    interconnection cost and rates chargeable by VIL from time
                    to time for provisioning the Services to the Customer •
                    ‘Customer’ shall mean Corporate organization named in the
                    CAF entering into the requisite contract comprising of CAF,
                    Verification documents etc.
                  </p>
                  <div className="over_hid pad_t10">
                    <div className="fleft width48">
                      {" "}
                      It is agreed between “VIL” & “Customer” as follows:
                      <br />
                      <strong>1. Provision of Services</strong> - VIL agrees to
                      provide the services to the Customer subject to terms and
                      condition of the Agreement.
                      <br />
                      <strong>2. Obligations OF VIL </strong>- VIL shall provide
                      services with reference to and subject to the
                      telecommunication operating license provided by DoT for
                      permitting the operations of a telephony services. The
                      Customer expressly agrees that activation of the Services
                      shall be subject to verification of the particulars and
                      documents submitted by the Customer including
                      tele-verification. The availability and quality of
                      services may be affected by factor outside VIL control
                      such as physical obstruction, geographic and weather
                      conditions and other cause of radio interference or faults
                      in other telecommunications network to which network is
                      connected. The Services may be suspended in whole or in
                      part at any time with proper notice and in compliance to
                      existing guidelines, policies and regulations., however if
                      the network fails or requires modifications or maintenance
                      due to a sudden or force majeure event beyond control of
                      operator, such advance notice may not be possible. Please
                      note all extra discounting promos on special deals or on
                      Corporate Plans and Closed User Group (CUG), VIL Mobile
                      Connect, VIL Live, Blackberry (BB) and other Data services
                      along with respective discounts on usage will be activated
                      minimum of 72hrs from date of number activation or the
                      request as the case may be. The allotment of a number will
                      be made by VIL in its absolute discretion. In the event of
                      any provisioned SIM ( where a Service involves a
                      provisioning of SIM) being lost or stolen, VIL will
                      replace the SIM card as soon as it is reasonable &
                      practicable, subject to the recovery of any charges for
                      the same. VIL reserve the right to apply a monthly
                      financial limit and such other conditions for charges
                      incurred by the Customer and to demand interim advance
                      payment and also suspend (and/or disconnect access to the
                      services if such limit is exceeded with proper intimation
                      and in compliance to existing guidelines, policies and
                      regulations VIL has the right to check the credential of
                      the Customer including the Customer financial standing &
                      to use the services of any person or agency for such
                      purposes. Any waiver, concession or extra time allowed or
                      granted by VIL to the Customer is limited the specific
                      circumstances in which it was given and the same shall not
                      effect VIL’s right under this agreement in any ways.
                      <br />
                      <strong>3. Obligations</strong> of the Customer - The
                      Customer shall ensure (i) that it signs all bulk
                      connection declarations and forms as are created pursuant
                      to DOT guidelines on bulk connections; (ii) that it has
                      the users comply with the said regulations and conclude
                      the tele-verification process by calling the call centre
                      at 117 so as to enable VIL to activate his/her subscribed
                      service plan; (iii) that it shall not use or cause or
                      allow others to use the Services for any improper, immoral
                      or unlawful purpose including in any manner which may
                      jeopardise or impair the operation of the Network and/or
                      the Services. Customer agrees to inform VIL immediately
                      and confirm the same in writing if the Equipment and/or
                      the SIM Card is lost, stolen or damaged. The Customer will
                      remain liable for all Charges incurred until the SIM Card
                      is de-activated by VIL; (iv) to furnish correct and
                      complete information and documents as required by VIL from
                      time to time. The Services agreed to be provided by VIL,
                      shall always be subject to verification of the Customer's
                      credentials and documents and if at any time, any
                      information and/or documents furnished by the Customer
                      is/are found incorrect or incomplete or suspicious. VIL
                      shall be entitled to suspend/terminate the Service
                      forthwith without any further notice. In case any change
                      of address of the Customer or end user, the Customer
                      agrees to inform VIL in writing with regard to change in
                      address and furnish documents in support of such new
                      address. In case during any time during the subscription,
                      in case the Customer does not inform with regard to change
                      in his address or end user then VIL reserves its right to
                      disconnect the Services and/or cancel the connection
                      without any notice in this regard and VIL shall not be
                      liable to the Customer/end user in any manner whatsoever.
                      <br />
                      <span className="redcolor">
                        1.0 DOT LICENSING AND VERIFICATION OF SUBSCRIBER:
                      </span>{" "}
                      (i) VIL may be required to disclose any information
                      pertaining to the Customer/Subscriber to any statutory /
                      regulatory / security authority, financial institution or
                      bank and as per regulation, VIL can do so without any
                      prior intimation to the Customer; (ii) Customer shall
                      indemnify and keep indemnified VIL for all and any claims,
                      proceedings or actions brought against VIL arising out of
                      any breach of the Customer of the Regulatory Terms; (iii)
                      VIL may use, process and/or transfer Personal Information;
                      (iv) in connection with the provision of Services; (v) to
                      incorporate Personal Information into databases controlled
                      by VIL for the purpose of account administration, billing
                      and reconciliation, operational maintenance and support
                      activities, fraud detection and prevention, and customer
                      and market analysis and reporting. (vi) The Customer shall
                      use the Services only for the purposes specified by the
                      Customer in the Agreement / Customer Application Form (or
                      Customer Order Form or Customer Information Form
                      hereinafter CAF/COF/CIF) and shall not be used for or
                      connected to any telecommunication service unless
                      otherwise permitted by the Governmental Authority with
                      specific approvals/ licenses. The Customer understands and
                      agrees that VIL shall be entitled to terminate the Service
                      and collect financial penalty as levied by the
                      Governmental Authority for any unauthorized use. VIL shall
                      also, at its sole discretion, be entitled to disconnect
                      the unauthorized circuit with immediate effect. The
                      Services shall not be used for call center/ telemarketing/
                      other service provider (OSP) operations without
                      registration with the Department of{" "}
                    </div>
                    <div className="flright width48">
                      {" "}
                      Telecommunications and/ or Telecom Regulatory Authority of
                      India as a call center/ OSP. The Customer who wants to
                      take the services for call center/ OSP operations shall
                      submit copy of call center/ OSP license or registration to
                      VIL. (vii)The Customer shall be allotted a phone
                      number/identity number by VIL which is and shall always
                      remain the property of VIL. The Customer cannot transfer
                      market, re-brand, resell and/or otherwise assign Services
                      purchased including the phone number/identity number,
                      Services to any other person/entity unless agreed by VIL
                      in writing. (viii) The Customer undertakes to comply with
                      the applicable laws and regulations regarding the use of
                      the Service, including without limitation, the TRAI Act
                      1997, the Indian Telegraph Act 1885, the Indian Wireless
                      Telegraphy Act 1933, Information Technology Act 2000 and
                      all amendments, replacements, rules and regulations made
                      under such legislations from time to time. The Customer
                      shall obtain any relevant consents and approvals for the
                      installation and use of any equipment located at the
                      Customer sites and associated with the Service and any
                      permits, consents or approvals required by the Subscriber
                      to use the Service, including any applicable other service
                      provider permits (“Permits”). Customer shall use the
                      Services in accordance with the Permits. Customer shall
                      provide VIL with an up-to-date copy of the Customer’s CUG
                      approval (if any) issued by the Department of
                      Telecommunications from time-to-time. Customer shall
                      ensure that any network equipment (not provided by
                      Services Provider) which it uses in relation to the
                      Services meet the relevant International Telecommunication
                      Union (ITU)/Telecommunication Engineering Centre (TEC)
                      standards. (ix) The Customer shall ensure that
                      unauthorized or any other content, messages or
                      communications infringing copyright, Intellectual property
                      right and international & domestic cyber laws, in any form
                      or inconsistent with the laws of India, are not carried in
                      his network by him or any other person using his network
                      or during the provisioning of Services. The Customer must
                      take all necessary measures to prevent such use. The use
                      of the Service for anti-national activities shall be
                      construed as an offence punishable under the Indian Penal
                      Code or other applicable law. The Customer must ensure
                      that any service provided by Service Provider is not used
                      for any unlawful purposes or any other person using the
                      Services as End User. (x) Customer agrees and acknowledges
                      that regulatory and law enforcement agencies (Law
                      Enforcement Agency (LEA)), shall have rights to access the
                      network and intercept or listen or record calls being made
                      using the Services and facilities provided by Service
                      Provider and Customer shall provide necessary
                      facilities/access/consent to Service Provider and Law
                      Enforcement Agency (LEA), including but not limited to
                      TRAI, DOT, Police authorities, Reserve Bank of India,
                      Enforcement Directorate and or any other Government
                      Agencies to monitor, control, prevent counteract
                      espionage, subversive act, sabotage or any other unlawful
                      activity. (xi) Customer understands Customer and its End
                      Users use the Services at their own risk and consequences
                      and Service Provider has no control over and accepts no
                      responsibility whatsoever for such the Customer use of the
                      Services and the transacted business/transmitted
                      communication and Customer shall comply with all
                      regulatory/legal and statutory requirements during such
                      use of the Services. (xii) Customer understands and agrees
                      that Service Provider shall terminate the Service provided
                      to the Customer immediately without any prior notice, upon
                      any violation of these terms and conditions by the
                      Customer and/or on account of any regulatory violation by
                      Customer or its End User’s in using the Service or in case
                      of any directive of any Law Enforcement Agency (LEA),
                      Department of Telecommunication (“DOT”), Judicial
                      authority or any other Government agency. (xiii) The
                      customer is required to maintain and provide updated list
                      of actual users to VIL at all times and the customer
                      agrees to give VIL the unhindered right to visit the
                      customer premises and verify bonafide use of the services
                      . (o) Customer shall not use the Services for any abusive,
                      improper, immoral, offensive, illegal or fraudulent
                      purposes including making calls or sending data that in
                      VIL’s reasonable opinion is a nuisance, hoax, menacing,
                      indecent, racist, obscene and/ or defamatory or do
                      anything that causes the Network to be impaired or
                      damaged. Using Services to access the Internet: In the
                      event that Customer or End User uses Services to access
                      facilities and services not included in a Tariff, VIL
                      shall not be liable under this Agreement for the security
                      or confidentiality of such facilities or services. (xiv)
                      No information with regard to an account information can
                      be shared with any third party other than the duly
                      notified authorized signatory of the Customer as notified
                      by Customer to VIL and VIL cannot provide any account
                      related information outside India.
                      <br />
                      <span className="redcolor">
                        2.0 CONTRACT TERMS AND COMMERCIAL TERMS:
                      </span>{" "}
                      This CAF, shall be read with the General Terms and
                      Conditions except where any master services agreement
                      (MSA) is entered including (or any India specific
                      in-country agreement (ICC) between the Customer and
                      Service Provider entered subsequent to or pursuant to any
                      global level MSA entered between the global
                      principals/affiliates of the Customer and Service
                      Provider) and the MSA provisions shall prevail over the
                      GTC). Any Specific terms agreed for the mobility shall be
                      set out in the Part C or a Product Terms and Conditions
                      and Schedules to the GTC. The CAF terms will prevail over
                      any provisions in the GTC/ or the MSA/ICC, which conflict
                      with terms in CAF.
                      <br />
                      <span className="redcolor">3.0 GOVERNING LAW:</span> This
                      CAF shall be subject to Indian Law and courts of
                      jurisdiction shall include courts in the place where
                      Customer has a place of business or where the cause of
                      action arises or the place where the Services have been
                      offered{" "}
                    </div>
                  </div>
                  <p className="ligthbg">Customer Acceptance</p>
                  <div className="over_hid pad_5t">
                    <div className="fleft width75">
                      <p className="auth pad_t10">
                        Customer Name
                        <input
                          className="form_col width83 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19603].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Designation
                        <input
                          className="form_col width83 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19604].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Company Name
                        <input
                          className="form_col width83 flright"
                          type="text"
                          name=""
                          value={this.state.vsdmDataUpdated[19605].value}
                          readOnly
                        />
                      </p>
                      <p className="pad_t10">
                        <strong>
                          {" "}
                          Note : I/We accept the responsibility of providing the
                          list of mobile nos/DID nos with user name &
                          designation, alongwith this CAF .
                        </strong>
                      </p>
                    </div>
                    <div className="flright width25">
                      <div className="sealone">
                        <div className="signature_box">
                          <table>
                            <tbody>
                              <tr>
                                <td width="50%">
                                  {firstCaps != "" ? (
                                    <div
                                      style={{
                                        padding: "10px",
                                        position: "absolute",
                                      }}
                                      className={Signcss}
                                    >
                                      {firstCaps + "  .  "}
                                      <span className="under_line">
                                        {secondBold}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td width="50%">
                                  {this.state.imageData != null &&
                                  this.state.imageData != "" ? (
                                    <div
                                      style={{ padding: "0px" }}
                                      className="signate_img"
                                    >
                                      <img
                                        src={
                                          this.state.imageData != null
                                            ? "data:" +
                                              this.state.imageType +
                                              ";base64," +
                                              this.encode(this.state.imageData)
                                            : ""
                                        }
                                        alt={"Company Seal"}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Sign &amp; Seal</td>
                                <td>
                                  <spa className=" pad_l_2 ">
                                    {" "}
                                    Date{" "}
                                    <span className="in_subput_date">
                                      <input
                                        className="form_col flright"
                                        type="text"
                                        name=""
                                        value=""
                                        readOnly
                                      />
                                    </span>{" "}
                                  </spa>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">Fulfilment and Channel details</p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Documents Verified by
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19607].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Date
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19608].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Channel Partner
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19609].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19610].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Vi <sup>TM</sup> Store
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19611].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19612].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_t3">
                    <div className="fleft width30">
                      Vi <sup>TM</sup> No.
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19613].value}
                        readOnly
                      />
                    </div>
                    <div className="flright width60">
                      SIM Card No.
                      <input
                        className="form_col width83 flright"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19614].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t10 fon8">
                    <div className="fleft width75 auth">
                      I/We hereby undertake and confirm that I/We have seen the
                      customer and certify that (a) the customer enrolment form
                      has been duly filled by the applicant, (b) the photograph
                      submitted has been matched with the applicant, (c) the
                      form has been personally signed by the applicant in my
                      presence (d) proof of identity & address has been
                      collected, (e) the original proof of identity & address
                      has been matched and verified with the self - attested
                      documents submitted by the applicant. Details on the Vi{" "}
                      <sup>TM</sup> CAF Match with these documents. In case of
                      outstation customer, I/We hereby confirm that I/We
                      tele-verified the particulars furnished by the applicant
                      with local reference
                      <br />
                      and recorded its correctness. Name of Local Reference
                      contacted (for Outstation subscribers)
                      <input
                        className="form_col width15"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="flright width25">
                      <div className="sealone_2">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <img
                                    height="50px"
                                    width="75px"
                                    src={
                                      this.state.acctMngSignImageData != null &&
                                      this.state.acctMngSignImageData != ""
                                        ? "data:" +
                                          this.state.acctMngSignImageType +
                                          ";base64," +
                                          this.encode(
                                            this.state.acctMngSignImageData
                                          )
                                        : ""
                                    }
                                    alt={"Sign"}
                                  />
                                ) : (
                                  ""
                                )}
                                <div>Sign with Seal/Stamp </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="in_subput_date">
                                  Date:
                                  <input
                                    className="form_col width15 "
                                    type="text"
                                    name=""
                                    value={
                                      this.state.vsdmDataUpdated[19580].value
                                    }
                                    readOnly
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_t10">
                    <div className="fleft width33">
                      Enterprise Code
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19615].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width47">
                      Account Manager Name
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19616].value}
                        readOnly
                      />
                    </div>
                    <div className="flright width20">
                      Code
                      <input
                        className="form_col width75 flright"
                        type="text"
                        name=""
                        value={this.state.vsdmDataUpdated[19617].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="cuttingcard"></div>
                  <p>
                    <strong>
                      Valid documents which can be enclosed for Proof of
                      Identity
                    </strong>{" "}
                    (All identity proof to have photo)
                    <br />
                    Passport • Arms License • Driving License • Election
                    Commission ID card • Ration Card with Photo, for the person
                    whose photo is affixed • CGHS/ECHS card • Certificate of
                    address having photo issued by MP/MLA/Group-A Gazetted
                    officer in letter head • Certificate of address with photo
                    from Govt. recognized educational institutions (for students
                    only) • Certificate of photo identity, issued by Village
                    Panchayat head or its equivalent authority (for rural areas)
                    • Income Tax PAN card • Photo Credit card • Address card
                    with Photo issued by Deptt. of Posts, Govt. of India • Smart
                    card issued by CSD, Defence/Paramilitary • Current Passbook
                    of Post Office/any scheduled bank having Photo • Photo
                    Identity card (of Central Govt./PSU or State Govt./PSU only
                    • Caste and Domicile Certificate with photo issued by State
                    Govt. like Assam and other states • Pensioner card having
                    photo • Freedom Fighter Card having photo • Kissan Passbook
                    having Photo • Photo Identity Card issued by Govt.
                    recognised educational instiutions (for students only) •
                    ‘Aadhaar’ the Unique Identification Number issued by Unique
                    Identity Authority of India
                  </p>
                  <p>
                    {" "}
                    <strong>For Proof of Address</strong>
                    <br />• Passport • Arms License • Driving License • Election
                    Commission ID card • Ration Card with address • CGHS/ECHS
                    card • Certificate of address having photo issued by
                    MP/MLA/Group-A Gazetted officer in letter head • Certificate
                    of address with photo from Govt. recognized educational
                    institutions (for students only) • Certificate of photo
                    identity, issued by Village Panchayat head or its equivalent
                    authority (for rural areas) • Water Bill (not older than
                    last three months) • Telephone Bill of Fixed line (not older
                    than last 3 months) • Electricity Bill (not older than last
                    3 months) • Income Tax assessment Order • Vehicle
                    Registration Certificate • Registered Sale/lease Agreement •
                    Address card with Photo issued by Deptt. of Posts, Govt. of
                    India • Current Passbook of Post Office/any scheduled bank •
                    Photo Identity card having address (of Central Govt./PSU or
                    State Govt./PSU only • Pensioner card with address • Freedom
                    Fighter Card with address • Kissan Passbook with address •
                    Credit Card Statement (not older than last three months) •
                    Cast and Domicile Certificate with Address and photo issued
                    by State Govt. like Assam and other states • ‘Aadhaar’ the
                    Unique Identification Number issued by Unique Identity
                    Authority of India{" "}
                  </p>
                </div>
              </div>
            </page>
          </div>
        </div>
      );
    }
    return <div>{vsdmCafContentView}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VSDM);
