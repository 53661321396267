import React, { Component } from 'react'
import Select from "react-select";
import * as utils from '../../../../shared/Utils'
import * as actions from '../../../../store/Actions/index'
import { connect } from "react-redux";
export class CreditDebitCard extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedFile:{},
            selectedCard:{},
            transaction_amount:'',
            transaction_note:''
        }
        this.workflowSearchDebounce = utils.debounce(
            this.props.onFetchWorkflows,
            500
          );
    }
    
    buildWorkflowsRequestParams = (srchStr) => {
        let txtToSrch = "";
        if (srchStr && srchStr !== "") {
          txtToSrch = this.state.searchString;
        }
    
        let activityTypeId = 0;
        let tagId = 0;
        let tagTypeId = 0;
        let isParticipant = 0;
        let activityStatusTypeId = 0;
        let flagDependent = 0;
        let dependentFieldId = 0;
        let dependentFieldServiceName = "";
        if (
          !!this.props.shouldValidate.rules &&
          Object.keys(this.props.shouldValidate.rules).length > 0 &&
          this.props.shouldValidate.rules.workflow_reference_restriction
        ) {
          const {
            activity_type_id,
            tag_id,
            tag_type_id,
            flag_participating,
            activity_status_type_id,
            flag_dependent,
            dependent_field_id,
            dependent_field_service_param_name,
          } = this.props.shouldValidate.rules.workflow_reference_restriction;
          activityTypeId = activity_type_id || 0;
          tagId = tag_id || 0;
          tagTypeId = tag_type_id || 0;
          isParticipant = flag_participating || 0;
          activityStatusTypeId = activity_status_type_id || 0;
          flagDependent = flag_dependent || 0;
          dependentFieldId = dependent_field_id || 0;
          dependentFieldServiceName = dependent_field_service_param_name || "";
        }
        let params = {
            organization_id:this.props.urlData.organization_id,
            account_id:this.props.urlData.account_id,
            workforce_id: 0,
            search_string: txtToSrch,
            service_version: 0.1,
            app_version: 1.0,
            flag_status: 0,
            flag_participating: isParticipant,
            asset_id: this.props.urlData.asset_id,
            asset_token_auth: this.props.urlData.asset_token_auth,
            auth_asset_id:this.props.urlData.auth_asset_id,
            device_os_id: 5,
            page_limit: 50,
            page_start: 0,
            activity_type_category_id: 48,
            activity_type_id: activityTypeId,
            tag_id: tagId,
            tag_type_id: tagTypeId,
            activity_status_type_id: activityStatusTypeId,
        }
        if (flagDependent !== 0) {
            params.flag_dependent=flagDependent;
          };
       
          if (
            !!this.props.fieldList &&
            !!this.props.fieldList[`${dependentFieldId}i`]
          ) {
            const data = this.props.fieldList[`${dependentFieldId}i`]
              .fileUploadData;
            params[`${dependentFieldServiceName}`] = data.value;
          }
        
        return params;
      };
        inputWorkflowSelectChange = (e, elemenType=62) => {
            const srchStr = e;
            this.setState(
            {
                ...this.state,
                searchString: srchStr,
            },
            () => {
                this.workflowSearchDebounce(
                this.buildWorkflowsRequestParams(srchStr),
                "select",
                elemenType
                );
            }
            );
        };
        handleFileChange = (selectOption) => {
            let elementType = null;
            let onFly = false;

            this.setState({
            ...this.state,
            selectedFile: selectOption,
            });
            if (this.props.onFlyEnabled) {
            onFly = true;
            this.props.inputChangedHandler(
                selectOption.value,
                this.props.elementTypeId,
                elementType,
                onFly
            );
            } else {
            onFly = false;
            this.props.inputChangedHandler(
                selectOption.value,
                this.props.elementTypeId,
                elementType,
                onFly
            );
            }
            // this.props.changed(selectOption.value);
        };
        handleCardChange=(e)=>{
            this.setState({selectedCard:e})
            this.props.changed(e, "transaction_type_id");
        }
        handleaAmountChage=(e,field)=>{
            this.setState({
                ...this.state,
                [field]:e.target.value
            })
            this.props.changed(e, field);
        }
    render() {
        return (
          <div>
            <Select
              isSearchable={true}
              onInputChange={(e) =>
                this.inputWorkflowSelectChange(
                  e,
                  62
                )
              }
              className="select-form-textbox"
              style={{ color: "#fff" }}
              onChange={(e) => {
                this.handleFileChange(e);
              }}
              options={this.props.workflowListCredit}
              value={this.state.selectedFile}
              isDisabled={this.props.disabled}
              name="lead_search"
              placeholder="Search by Workflow name"
            />
            <Select
              isSearchable={true}
              className="select-form-textbox"
              value={this.state.selectedCard}
              isDisabled={this.props.disabled}
              onChange={(e)=>this.handleCardChange(e)
            //       (e) => {
            //     this.props.changed(e, "transaction_type_id");
            //   }
            }
              options={[
                {
                  label: "Please Select Account",
                  value: "-1",
                  target: {
                    value: "-1",
                  },
                  persist: () => null,
                },
                {
                  label: "Credit",
                  value: "1",
                  target: {
                    value: "1",
                  },
                  persist: () => null,
                },
                {
                  label: "Debit",
                  value: "2",
                  target: {
                    value: "2",
                  },
                  persist: () => null,
                },
              ]}
            />
            <div>
            <input
              className="textbox"
              value={this.state.transaction_amount}
              onChange={(e) => {
                  this.handleaAmountChage(e,"transaction_amount")
                // this.props.changed(e, "transaction_amount");
              }}
              type="number"
              placeholder={"Amount"}
            />
            </div>
            <div className="textarea_view">
                <textarea
                cols="57"
                rows="6"
                charswidth="23"
                onChange={(e) => {
                    this.handleaAmountChage(e,"transaction_note")
                    // this.props.changed(e, "transaction_note");
                }}
                // className="textarea_view margin-5"
                placeholder="notes"
                value={this.state.transaction_note}
                ></textarea>
            </div>
          </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        workflowListCredit: state.files.workflowListCredit,
}}
const mapDispatchToProps = (dispatch) => {
	return {
    inputChangedHandler: (event, formElementId, eleType, onFly) =>
    dispatch(
      actions.formFileFieldInputChange(event, formElementId, eleType, onFly)
    ),
        onFetchWorkflows: (requestData, reference, target = null) =>
      dispatch(actions.workflowsFetchByTags(requestData, reference, target)),
    }}
    export default connect(mapStateToProps, mapDispatchToProps)(CreditDebitCard)