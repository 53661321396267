import React, { Component } from 'react';
import VilLogo from './../../assets/images/vil/vi_logo_large_white.png';

import './FormLayout.scss';
export default class FormLayout extends Component {
	componentDidMount() {
		console.log('changes!');
	}
	render() {
		return (
			<div className='layout'>
				<div className='layout-header layout-vil'>
					<img src={VilLogo} className='logo' alt='ViL logo' />
					<div className='title'>
						<h3>Customer Request Form</h3>
					</div>
				</div>
				<div className='layout-container'></div>
			</div>
		);
	}
}
