import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter,HashRouter} from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import {Provider} from "react-redux";
import {createStore,applyMiddleware,compose,combineReducers} from "redux";
import thunk from "redux-thunk";
import axios from "axios";
import "./index.css";
import App from "./App";
import reducerAuthLogin from "./store/Reducers/login";
import reducerFiles from "./store/Reducers/files";
import reducerForms from "./store/Reducers/forms";
import reducerQueue from "./store/Reducers/queue";
import sharedReducer from "./store/Reducers/shared.reducer";
import ErrorBoundary from "./components/Exception/ErrorBoundary";

// import bootstrap 4 css
// import 'bootstrap/dist/css/bootstrap.css';
let composeEnhancers = compose;
if(!!process.env.IS_PRODUCTION) {
  console.log('preparing for production build');
  composeEnhancers = compose;
} else {
  console.log('in house build');
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

////////////////////////////////////////////
/////// Application configuration /////////
////////////////////////////////////////////
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.extUrl = process.env.REACT_APP_EXT_URL;
axios.defaults.widgetExtUrl = process.env.REACT_APP_WIDGET_EXT_URL;
axios.defaults.officeUrl = process.env.REACT_APP_OFFICE_URL;
axios.defaults.vilDashboardUrl = process.env.REACT_APP_VIL_DASHBOARD_URL;
axios.defaults.genericDashboardUrl =process.env.REACT_APP_GENERIC_DASHBOARD_URL;
axios.defaults.bucketName = process.env.REACT_APP_BUCKET_NAME;
const appReducer = combineReducers({
  loginAuth: reducerAuthLogin,
  files: reducerFiles,
  forms: reducerForms,
  queue: reducerQueue,
  shared:sharedReducer
});

const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <HttpsRedirect>
      <BrowserRouter>
        <HashRouter>
          {/* <ErrorBoundary> */}
            <App />
          {/* </ErrorBoundary> */}
        </HashRouter>
      </BrowserRouter>
    </HttpsRedirect>
  </Provider>
);

ReactDOM.render(app,document.getElementById("root"));
//registerServiceWorker();
