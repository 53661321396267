import {
//   loadQueueListConst,
//   getFocusCountConst,
//   loadUnAssignActivityListConst,
//   loadFocusActivityListConst,
//   loadMyActivityListConst,
//   loadQueueActivityListConst,
//   loadFilteredActivityListConst,
//   activityUnreadCountConst,
//   collapseChildActivityConst,
//   loadChildActivityConst,
//   loadActivityBulkSummaryConst,
//   loadResourceTimelineConst,
  loadRoleBasedAssetsConst,
//   loadTimelineDocsConst,
//   workflowSelectMappingConst,
//   getMentionCountConst,
//   getPastDueCountConst,
} from "../Types";
import * as actionTypes from "./actionTypes";
import Axios from "../../axios_ext";

export const sharedActions = {
  // showDocumentRepo,
  // closeDocumentRepo,
  // searchEmployeeDesk,
  // loadQueueList,
  // getFocusCount,
  // getMentionCount,
  // getPastDueCount,
  // loadUnAssignActivityList,
  // loadFocusActivityList,
  // loadMyActivityList,
  // loadQueueActivityList,
  // loadFilteredActivityList,
  // activityUnreadCountUpdate,
  // activityUnreadCountReset,
  // collapseChildActivities,
  // loadChildActivityList,
  // loadActivityBulkSummary,
  // loadResourceTimeline,
  loadRoleBasedAssets,
  // loadTimelineDocs,
  // cleanUpState,
  // startGreneLoader,
  // stopGreneLoader,
  // workflowSelectMapping,
  // getWorkflowSelectFilter,
};
// function startGreneLoader() {
//   return { type: actionTypes.SHOW_GRENELOADER };
// }
// function stopGreneLoader() {
//   return { type: actionTypes.CLOSE_GRENELOADER };
// }
// function showDocumentRepo() {
//   return { type: actionTypes.SHOW_DOCUMENT_REPOSITORY };
// }
// function closeDocumentRepo() {
//   return { type: actionTypes.CLOSE_DOCUMENT_REPOSITORY };
// }

// function searchEmployeeDesk(payload, callback) {
//   return (dispatch) => {
//     dispatch(request());
//     Axios.post("/admin/asset/employee_desk/search", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           let employeeList = res.data.response;
//           if (res.data.response && res.data.response.length > 0) {
//             employeeList = employeeList.filter(
//               (emp) => !!emp.operating_asset_first_name
//             );
//             dispatch(success({ employeeList }));
//             return employeeList.map((emp) => {
//               return {
//                 display: `${emp.operating_asset_first_name} - ${emp.account_name}`,
//                 id: emp.asset_id,
//               };
//             });
//           }
//           throw new Error("No user found");
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .then(callback)
//       .catch((error) => {
//         failure({ message: error.message });
//       });
//   };

//   function request() {
//     return { type: actionTypes.SEARCH_EMPLOYEE_DESK_START };
//   }
//   function success(payload) {
//     return { type: actionTypes.SEARCH_EMPLOYEE_DESK_SUCCESS, ...payload };
//   }
//   function failure(payload) {
//     return { type: actionTypes.SEARCH_EMPLOYEE_DESK_FAILED, payload };
//   }
// }

// function loadQueueList(payload) {
//   return (dispatch) => {
//     dispatch(request());
//     Axios.post("asset/access/queue/list", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             dispatch(success({ queueList: res.data.response }));
//           } else {
//             throw new Error("No queue found !");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure(error));
//       });
//   };

//   function request() {
//     return { type: loadQueueListConst.START };
//   }
//   function success(payload) {
//     return { type: loadQueueListConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadQueueListConst.ERROR, error };
//   }
// }

// function getFocusCount(payload) {
//   return (dispatch) => {
//     dispatch(request());
//     Axios.post("activity/asset/focus-list", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             dispatch(success({ focusCount: res.data.response[0].count }));
//           } else {
//             throw new Error("No focus count!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure(error));
//       });
//   };

//   function request() {
//     return { type: getFocusCountConst.START };
//   }
//   function success(payload) {
//     return { type: getFocusCountConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: getFocusCountConst.ERROR, error };
//   }
// }
// function getMentionCount(payload) {
//   return (dispatch) => {
//     dispatch(request());
//     Axios.post("activity/queue/list/all/v1", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             dispatch(success({ mentionCount: res.data.response[0].count }));
//           } else {
//             throw new Error("No data found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure(error));
//       });
//   };

//   function request() {
//     return { type: getMentionCountConst.START };
//   }
//   function success(payload) {
//     return { type: getMentionCountConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: getMentionCountConst.ERROR, error };
//   }
// }
// function getPastDueCount(payload) {
//   return (dispatch) => {
//     dispatch(request());
//     Axios.post("activity/queue/list/all/v1", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             dispatch(success({ pastDueCount: res.data.response[0].count }));
//           } else {
//             throw new Error("No focus count!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure(error));
//       });
//   };

//   function request() {
//     return { type: getPastDueCountConst.START };
//   }
//   function success(payload) {
//     return { type: getPastDueCountConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: getPastDueCountConst.ERROR, error };
//   }
// }

// // load unassigned vil specific activity
// function loadUnAssignActivityList(payload) {
//   return (dispatch) => {
//     if (!payload.page_start) {
//       dispatch(request());
//     }
//     Axios.post("activity/my_queue/list", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             const isMoreEnable =
//               res.data.response.length === payload.page_limit;
//             dispatch(
//               success({
//                 isMoreEnable,
//                 start_from: payload.page_start,
//                 activityList: res.data.response,
//               })
//             );
//           } else {
//             throw new Error("No activities found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request() {
//     return { type: loadUnAssignActivityListConst.START };
//   }
//   function success(payload) {
//     return { type: loadUnAssignActivityListConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadUnAssignActivityListConst.ERROR, ...error };
//   }
// }

// // load my focused activity list
// function loadFocusActivityList(payload) {
//   return (dispatch) => {
//     if (!payload.page_start) {
//       dispatch(request());
//     }
//     Axios.post("activity/asset/focus-list/v1", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             const isMoreEnable =
//               res.data.response.length === payload.page_limit;
//             dispatch(
//               success({
//                 isMoreEnable,
//                 start_from: payload.page_start,
//                 activityList: res.data.response,
//               })
//             );
//           } else {
//             throw new Error("No activities found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request() {
//     return { type: loadFocusActivityListConst.START };
//   }
//   function success(payload) {
//     return { type: loadFocusActivityListConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadFocusActivityListConst.ERROR, ...error };
//   }
// }

// // load my activity list
// function loadMyActivityList(payload) {
//   return (dispatch) => {
//     payload["start_from"] = payload.page_start;
//     payload["limit_value"] = payload.page_limit;
//     if (!payload.start_from && !payload.status_type_id) {
//       dispatch(request());
//     }
//     Axios.post("activity/queue/list/all/v1", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             const isMoreEnable =
//               res.data.response.length === payload.limit_value;
//             dispatch(
//               success({
//                 isMoreEnable,
//                 isSearch: payload.is_search,
//                 start_from: payload.start_from,
//                 activityList: res.data.response,
//                 status_type_id: payload.status_type_id,
//               })
//             );
//           } else {
//             throw new Error("No activities found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request() {
//     return { type: loadMyActivityListConst.START };
//   }
//   function success(payload) {
//     return { type: loadMyActivityListConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadMyActivityListConst.ERROR, ...error };
//   }
// }

/**
 * @request params
 *  organization_id,
    asset_id,
    activity_type_category_id || 0,
    queue_id || 0,
    is_search,
    search_string,
    flag || 0, // 0 => Due date | 1 => Created date
    sort_flag || 0, // 0 => Ascending | 1 => Descending
    page_start || 0,
    page_limit || 50
 * 
*/

// load queue specific activity list
// function loadQueueActivityList(payload) {
//   return (dispatch) => {
//     if (!payload.page_start) {
//       dispatch(request());
//     }
//     Axios.post("activity/queue/list/all/v2", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             const isMoreEnable =
//               res.data.response.length === payload.page_limit;
//             dispatch(
//               success({
//                 isMoreEnable,
//                 start_from: payload.page_start,
//                 activityList: res.data.response,
//               })
//             );
//           } else {
//             throw new Error("No activities found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request() {
//     return { type: loadQueueActivityListConst.START };
//   }
//   function success(payload) {
//     return { type: loadQueueActivityListConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadQueueActivityListConst.ERROR, ...error };
//   }
// }

// load queue specific activity list
// function loadFilteredActivityList(payload) {
//   return (dispatch) => {
//     if (!payload.page_start) {
//       dispatch(request());
//     }
//     Axios.post("activity/asset/mapping/new-filters/list", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             const isMoreEnable =
//               res.data.response.length === payload.page_limit;
//             dispatch(
//               success({
//                 isMoreEnable,
//                 start_from: payload.page_start,
//                 activityList: res.data.response,
//               })
//             );
//           } else {
//             throw new Error("No activities found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request() {
//     return { type: loadFilteredActivityListConst.START };
//   }
//   function success(payload) {
//     return { type: loadFilteredActivityListConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadFilteredActivityListConst.ERROR, ...error };
//   }
// }

// function activityUnreadCountUpdate(payload) {
//   return {
//     type: activityUnreadCountConst.UPDATE,
//     ...payload,
//   };
// }

// function activityUnreadCountReset(payload) {
//   return {
//     type: activityUnreadCountConst.RESET,
//     ...payload,
//   };
// }

// // load child orders / activity list
// function loadChildActivityList(payload) {
//   return (dispatch) => {
//     payload["start_from"] = payload.page_start;
//     payload["limit_value"] = payload.page_limit;
//     dispatch(request({ start_from: payload.start_from }));
//     Axios.post("activity/workflow/child_orders/list", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             const isMoreEnable =
//               res.data.response.length === payload.limit_value;
//             dispatch(
//               success({
//                 isMoreEnable,
//                 start_from: payload.start_from,
//                 childActivityList: res.data.response,
//                 parent_activity_id: payload.parent_activity_id,
//               })
//             );
//           } else {
//             throw new Error("No activities found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request(payload) {
//     return { type: loadChildActivityConst.START, ...payload };
//   }
//   function success(payload) {
//     return { type: loadChildActivityConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadChildActivityConst.ERROR, ...error };
//   }
// }

// function collapseChildActivities(activity) {
//   return {
//     type: collapseChildActivityConst.COLLAPSE,
//     activity,
//   };
// }

// // load activity bulk summary.
// function loadActivityBulkSummary(payload) {
//   return (dispatch) => {
//     dispatch(request());
//     Axios.post("activity/bulk-summary/list/v1", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (res.data.response && res.data.response.length > 0) {
//             const message = res.data.response[0].message;
//             dispatch(
//               success({
//                 message,
//               })
//             );
//           } else {
//             throw new Error("No summary found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request() {
//     return { type: loadActivityBulkSummaryConst.START };
//   }
//   function success(payload) {
//     return { type: loadActivityBulkSummaryConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadActivityBulkSummaryConst.ERROR, ...error };
//   }
// }

// function loadResourceTimeline(payload) {
//   return (dispatch) => {
//     dispatch(request);
//     Axios.post("asset/access/timeline/list", payload)
//       .then((res) => {
//         if (res.data.status === 200) {
//           dispatch(
//             success({
//               data: res.data.response,
//             })
//           );
//         }
//       })
//       .catch((err) => {
//         dispatch(failure({ error: err.message }));
//       });
//   };
//   function request() {
//     return { type: loadResourceTimelineConst.START };
//   }
//   function success(payload) {
//     return { type: loadResourceTimelineConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadResourceTimelineConst.ERROR, ...error };
//   }
// }

function loadRoleBasedAssets(payload) {
  return (dispatch) => {
    dispatch(request());
    Axios.post("/activity/asset-role-reference/list", payload)
      .then((res) => {
        if (res.data.status === 200) {
          const roleBasedAssetList = res.data.response.map((value) => {
            const {
              asset_first_name,
              asset_id,
              operating_asset_first_name,
              operating_asset_id,
              operating_asset_phone_number,
              account_name,
            } = value;
            return {
              label: value.operating_asset_first_name,
              value: value.operating_asset_id,
              details: {
                asset_id,
                asset_first_name,
                operating_asset_id,
                operating_asset_first_name,
                operating_asset_phone_number,
                account_name,
              },
            };
          });
          dispatch(
            success({
              data: {
                asset_type_category_id: payload.asset_type_category_id,
                roleBasedAssetList,
              },
            })
          );
        }
      })
      .catch((err) => {
        dispatch(failure({ error: err.message }));
      });
  };
  function request() {
    return { type: loadRoleBasedAssetsConst.START };
  }
  function success(payload) {
    return { type: loadRoleBasedAssetsConst.SUCCESS, ...payload };
  }
  function failure(error) {
    return { type: loadRoleBasedAssetsConst.ERROR, ...error };
  }
}
// function loadTimelineDocs(payload, tempArray = []) {
//   return (dispatch) => {
//     dispatch(request);
//     Axios.post("activity/timeline/list", payload)
//       .then((res) => {
//         if (res.data.status === 200) {
//           let timelineData = res.data.response.data;
//           if (timelineData.length === payload.page_limit) {
//             payload.page_start = payload.page_start + payload.page_limit;
//             dispatch(
//               loadTimelineDocs(payload, [...timelineData, ...tempArray])
//             );
//           } else {
//             let timelineList = [...timelineData, ...tempArray]
//               .map((timeline) => {
//                 let content;
//                 if (typeof timeline.data_entity_inline == "string") {
//                   content = JSON.parse(timeline.data_entity_inline);
//                 } else {
//                   content = timeline.data_entity_inline;
//                 }
//                 if (
//                   timeline.timeline_stream_type_id === 325 &&
//                   content.hasOwnProperty("attachments")
//                 ) {
//                   return timeline;
//                 }
//               })
//               .filter(Boolean);
//             dispatch(
//               success({
//                 data: timelineList,
//               })
//             );
//           }
//         }
//       })
//       .catch((err) => {
//         dispatch(failure({ error: err.message }));
//       });
//   };
//   function request() {
//     return { type: loadTimelineDocsConst.START };
//   }
//   function success(payload) {
//     return { type: loadTimelineDocsConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadTimelineDocsConst.ERROR, ...error };
//   }
// }

// function workflowSelectMapping(payload) {
//   return (dispatch) => {
//     dispatch(request);
//     Axios.post("/activity/search/mapping/select", payload)
//       .then((res) => {
//         if (res.data.status === 200) {
//           dispatch(
//             success({
//               selectMapping: !!res.data.response ? res.data.response : [],
//             })
//           );
//         }
//       })
//       .catch((err) => {
//         dispatch(failure({ error: err.message }));
//       });
//   };
//   function request() {
//     return { type: workflowSelectMappingConst.START };
//   }
//   function success(payload) {
//     return { type: workflowSelectMappingConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: workflowSelectMappingConst.ERROR, ...error };
//   }
// }

// // load queue specific activity list
// function getWorkflowSelectFilter(payload) {
//   return (dispatch) => {
//     if (!payload.page_start) {
//       dispatch(request());
//     }
//     Axios.post("/workflow_reference/activity_type/search/v1", payload)
//       .then((res) => {
//         if (res.status == 200) {
//           if (
//             res.data.response &&
//             res.data.response.length > 0 &&
//             !res.data.response[0].query_status
//           ) {
//             const isMoreEnable =
//               res.data.response.length === payload.page_limit;
//             dispatch(
//               success({
//                 isMoreEnable,
//                 start_from: payload.page_start,
//                 activityList: res.data.response,
//               })
//             );
//           } else {
//             throw new Error("No activities found!");
//           }
//         } else {
//           throw new Error("Something went wrong!");
//         }
//       })
//       .catch((error) => {
//         dispatch(failure({ error: error.message }));
//       });
//   };
//   function request() {
//     return { type: loadQueueActivityListConst.START };
//   }
//   function success(payload) {
//     return { type: loadQueueActivityListConst.SUCCESS, ...payload };
//   }
//   function failure(error) {
//     return { type: loadQueueActivityListConst.ERROR, ...error };
//   }
// }

// function cleanUpState() {
//   return (dispatch) => {
//     dispatch(cleanLovRefList());
//     dispatch(cleanRoleBasedAssetsRef());
//     dispatch(cleanAssetsRef());
//   };

//   function cleanLovRefList() {
//     return { type: actionTypes.LOV_DATATYPE_LIST_START, lovListRef: [] };
//   }
//   function cleanRoleBasedAssetsRef() {
//     return { type: loadRoleBasedAssetsConst.START };
//   }
//   function cleanAssetsRef() {
//     return { type: actionTypes.ASSET_FETCH_START };
//   }
// }
