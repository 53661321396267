import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import "../../VodafoneIdea.css";
import "../PriCaf/PRICafForm.css";
import ReactToPrint from "react-to-print";
import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
import CloudImg from "../../../../../assets/images/Process/VodafoneIdea/cloud.jpg";
import lotImg from "../../../../../assets/images/Process/VodafoneIdea/lot.jpg";
import WireImg from "../../../../../assets/images/Process/VodafoneIdea/wire.jpg";
import WirelessImg from "../../../../../assets/images/Process/VodafoneIdea/wireless.jpg";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import Axios from "axios";
import AxiosWeb from "../../../../../axios_ext";
import { JsonToTable } from "react-json-to-table";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class PriSipCafForm extends Component {
  state = {
    priCafData: false,
    stopdidmount: 0,
    priCafDataupdated: 1,
    authorizedSignName: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    companySeal: null,
    imageData: null,
    imageType: null,
    acctMngSignImageData: null,
    acctMngSignImageType: null,
    photoGraph: null,
    photoGraphImageData: null,
    photoGraphImageType: null,
    excelJson: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };
  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1563,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";

        let data = [];
        if (res.data.response.length > 0) {
          console.log(
            "formFieldData ::" +
              JSON.parse(res.data.response[0].data_entity_inline).form_submitted
          );
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 14534) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 14533) {
            photoGraph = fieldObj.field_value;
            console.log("photoGraph:: " + photoGraph);
          }
          if (fieldObj.field_id == 14532) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("companySeal:: " + companySeal);
          console.log("photoGraph:: " + photoGraph);
          console.log("authorizedSignatoryName:: " + authorizedSignatoryName);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          custPhotoGraph: photoGraph,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.photoGraph);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let digital_caf_form_id = 1557; // DIGITAL PRI CAF form Id
    let orderform = 1369; //Order Documents Forms
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        priCafData: true,
      });
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+JSON.stringify(submittedFormArry));
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == digital_caf_form_id
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );
        this.setState({
          ...this.state,
          priCafData: true,
        });
      }

      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == digital_caf_form_id
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: digital_caf_form_id,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("14024") &&
      this.state.stopdidmount === 0
    ) {
      let x = this.props.fieldList;

      if (!!this.state.custPhotoGraph) {
        utils.loadImageBlob(
          this.s3,
          this.state.custPhotoGraph,
          (data, type) => {
            this.setState({
              ...this.state,
              photoGraphImageData: data,
              photoGraphImageType: type,
              stopdidmount: 1,
              priCafDataupdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          priCafDataupdated: this.props.fieldList,
        });
      }

      if (!!this.state.custCompanySeal) {
        utils.loadImageBlob(
          this.s3,
          this.state.custCompanySeal,
          (data, type) => {
            this.setState({
              ...this.state,
              imageData: data,
              imageType: type,
              stopdidmount: 1,
              priCafDataupdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          priCafDataupdated: this.props.fieldList,
        });
      }

      if (!!x[14332] && x[14332].value) {
        // manager sign url
        let acctManagerSignUrl = x[14332].value;
        utils.loadImageBlob(this.s3, acctManagerSignUrl, (data, type) => {
          this.setState({
            ...this.state,
            acctMngSignImageData: data,
            acctMngSignImageType: type,
            stopdidmount: 1,
            priCafDataupdated: this.props.fieldList,
          });
        });
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          priCafDataupdated: this.props.fieldList,
        });
      }
    }
  }

  render() {
    console.log(" IN RENDER :: " + this.state.priCafDataupdated);
    console.log("this.state.stopdidmount :: " + this.state.stopdidmount);
    let PriSipCafContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );

    if (
      this.state.priCafDataupdated != null &&
      this.state.priCafDataupdated !== "" &&
      this.state.stopdidmount == 1
    ) {
      let x = this.state.priCafDataupdated;
      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }
      let firstCaps = "";
      let secondBold = "";
      let Signcss;

      //console.log('this.state.authorizedSignName :: '+this.state.authorizedSignName);

      if (
        this.state.authorizedSignName != null &&
        this.state.authorizedSignName != ""
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        //console.log(firstCaps,"console.log(firstCaps);");
      }

      PriSipCafContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ paddingTop: "57px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsstyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsstyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <div className="over_hid w_85">
                    <div className="">
                      <div className="textcenter">
                        Unique ID
                        <input
                          className="form_col brdr_b1 "
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="customfom textcenter margin0">
                    {" "}
                    Customer Application Form{" "}
                  </p>
                  <div className="over_hid pad_t10">
                    <div className="fleft width75">
                      <div className="">
                        <strong>Vodafone Idea Business Servies</strong>{" "}
                        <span className="pad_l10">
                          Kindly fill the form in BLOCK letters
                        </span>{" "}
                      </div>
                      <p className="ligthbg">Name and Address </p>
                      <p className="auth">
                        {" "}
                        Name of Company
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14026].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Comany's Registered
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14027].value}
                          readOnly
                        />
                      </p>
                      <div className="auth margin0">
                        <span className="width54px">Address</span>
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Landmark
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14028].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          State
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14030].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          Pincode
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14031].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landline No. +91
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14032].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="faxn">Fax No.</span>
                          <input
                            className="form_col width52 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14033].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          CIN Number
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14034].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="pad_t5">
                            Company PAN Card Number
                          </span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14035].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright cross_fill">
                      <div className="crossign" style={{ height: "100px" }}>
                        {this.state.photoGraphImageData != null &&
                        this.state.photoGraphImageData ? (
                          <div
                            style={{
                              padding: "5px",
                              position: "relative",
                              textAlign: "center",
                            }}
                            className="signate_img"
                          >
                            <img
                              style={{ height: "90px" }}
                              src={
                                this.state.photoGraphImageData != null
                                  ? "data:" +
                                    this.state.photoGraphImageType +
                                    ";base64," +
                                    this.encode(this.state.photoGraphImageData)
                                  : ""
                              }
                              alt={"PhotoGraph with Crosssign"}
                            />
                            {firstCaps != "" ? (
                              <span
                                style={{
                                  padding: "10px",
                                  position: "absolute",
                                  left: "-20px",
                                  top: "20px",
                                }}
                                className={Signcss}
                              >
                                {firstCaps + "  .  "}
                                <span className="under_line">{secondBold}</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">Basic Organisation Details </p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <p className="margin0">
                        <strong>
                          Address 1 (Authorised Signatory Location)
                        </strong>
                      </p>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Name</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14038].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Father's/ Husbands</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14039].value}
                          readOnly
                        />
                      </div>

                      <div className="auth margin0">
                        <span className="width54px">Nationality</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14040].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Billing Address</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14041].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">&nbsp;</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landmark
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14042].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">City</span>
                          <input
                            className="form_col width70 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14043].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          State *
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14044].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">Pincode</span>
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14045].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14046].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Landline No. +91</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14047].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Email ID</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14048].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width33">
                          Designation
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14049].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Emp.Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14050].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Vehicle No.
                          <input
                            className="form_col flright width33"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14051].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p>*Mandatory for GST registered companies</p>
                      <div className="check margin0 over_hid">
                        <div className="fleft width20">
                          GSTIN
                          {this.state.priCafDataupdated[14078].value ===
                          "GSTIN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                        <div className="fleft width20">
                          UIN
                          {this.state.priCafDataupdated[14078].value ===
                          "UIN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                        <div className="fleft width20">
                          GST_ISD
                          {this.state.priCafDataupdated[14078].value ===
                          "GST_ISD" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="margin0">
                        <strong>Address 2 (Key contract Location)</strong>
                      </p>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Name</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14053].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Address</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14054].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">&nbsp;</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landmark
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14055].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">City</span>
                          <input
                            className="form_col width70 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14056].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          State *
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14057].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">Pincode</span>
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14058].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14059].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Landline No. +91</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14060].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Email ID</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14061].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width33">
                          Designation
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14062].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Emp.Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14063].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Vehicle No.
                          <input
                            className="form_col flright width33"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14064].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p className="margin0">
                        <strong>Organisation Type </strong>
                      </p>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[14065].value ===
                          "Partner Ship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Partnership
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[14065].value ===
                          "Proprietorship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Proprietorship
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[14065].value ===
                          "Private Limited" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Private Limited{" "}
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[14065].value ===
                          "Public Sector" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Public Sector
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[14065].value ===
                          "Government" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Government
                        </div>
                      </div>
                      <div className="margin0 over_hid pad_5t">
                        <div className="fleft">
                          <span className="">
                            {this.state.priCafDataupdated[14065].value ===
                            "Others" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                            Others
                          </span>{" "}
                          <span className="auth">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15020].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid typecheck">
                        <div className="fleft width12 pad_t2">
                          <strong>Telemarketer</strong>
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[14071].value ===
                          "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[14071].value ===
                          "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <div className="auth margin0">
                        {" "}
                        If Yes, Telemarketer Registration Number
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14073].value}
                          readOnly
                        />
                      </div>
                      <div className="check margin0 over_hid typecheck">
                        <div className="fleft pad_t2">
                          <strong>OSP &nbsp; &nbsp; </strong>
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[14074].value ===
                          "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[14074].value ===
                          "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <div className="auth margin0">
                        {" "}
                        If Yes, OSP Registration Number
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14076].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="check margin0 over_hid typecheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Type
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14081].value ===
                      "Normal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Normal
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14081].value === "SEZ" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SEZ
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14081].value ===
                      "Diplomat" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diplomat
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14081].value ===
                      "Embassy" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Embassy
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14081].value ===
                      "Consulate" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consulate
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14081].value ===
                      "Special Agency" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Special Agency
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14081].value ===
                      "Person/Class of Persons" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Person/Class of Persons
                    </div>
                  </div>
                  <p className="auth">
                    GSTIN / UIN / GST_ISD No
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[14088].value}
                      readOnly
                    />
                  </p>
                  <div className="auth">
                    <span className="width70px in_block">
                      GST Regd. Address**
                    </span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[14089].value}
                      readOnly
                    />
                  </div>
                  <div className="auth">
                    <span className="width70px in_block">State**</span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[14090].value}
                      readOnly
                    />
                  </div>
                  <p>If GST Address is diffrent from Billing Address</p>
                  <div className="check over_hid typecheck gstcheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Dept
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value === "IT" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value === "Tech" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Tech
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value ===
                      "Customer Service" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Cust Service
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value === "Legal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Legal
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value ===
                      "Finance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finanace
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value ===
                      "Purchase" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Purchase
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value === "SCM" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SCM
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value ===
                      "Delivery" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Delivery
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[14092].value ===
                      "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                    <div className="fleft width20 pad_t2">
                      Others
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[15021].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="auth namsupp">
                    Name of Supporting Document
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[14101].value}
                      readOnly
                    />
                  </p>
                  <p className="gsttext">
                    (GST Certificate, SEZ Certificate, Diploma Crtificate)
                  </p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <p className="ligthbg">Proof of Identity Details</p>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Type of POI
                          <input
                            className="form_col width60"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14103].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Document No.</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14104].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width45">
                          Date of Issue
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14105].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          <span className="faxn">Place of Issue</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14106].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Issuing Authority</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14107].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 pad_5t">
                        {" "}
                        Multiple Connections (Please fill enclosed declaration)
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="ligthbg">Proof of Address Details</p>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Type of POA
                          <input
                            className="form_col width60"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14112].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Document No.</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14113].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width45">
                          Date of Issue
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14114].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          <span className="faxn">Place of Issue</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14115].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Issuing Authority</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14116].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Existing VIL Numbers (Count)</span>
                        <input
                          className="form_col width55 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14117].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width40">
                      <span className="">Tariff Plan applied</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14109].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width60">
                      <span className="">
                        Value Added Services applied (if any)
                      </span>
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14110].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">
                    Local Reference (For National Outstation and Foreign
                    National Customers)
                  </p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width45">
                      <span className="">Name</span>
                      <input
                        className="form_col width85 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14119].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      <span className="">Contact No.</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14120].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      <span className="">Time&Date of Call</span>
                      <input
                        className="form_col width45 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14122].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width75">
                      <span className="">Address</span>
                      <input
                        className="form_col width85 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14121].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      <span className="">
                        Calling Party's No.
                        <br />
                        <span className="fnt6">(to be filled by POS)</span>
                      </span>
                      <input
                        className="form_col width45 flright posinput"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14123].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="ligthbg">Current Requirement</p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <div className="auth margin0 pad_5t over_hid">
                        <div className="fleft width55">
                          <span className="">No. of connections required</span>
                          <input
                            className="form_col width25 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[14125].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          <span className="">NDNC Regestration Opt IN</span>
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf("No commercial Call/ SMS") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          1. No commercial Call/ SMS{" "}
                        </div>
                        <div className="flright">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf(
                              "Banking/ Insurance/ Financal Produts/ Credit Card"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          2. Banking/ Insurance/ Financal Produts/ Credit Card{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf("Real Estate") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          3. Real Estate{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf("Education") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          4. Education{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf("Health") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          5. Health{" "}
                        </div>
                        <div className="flright">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf("Consumer goods and Automoblies") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          6. Consumer goods and Automoblies{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf(
                              "Communication/ Broadcasting/ Entertainment/ IT"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          7. Communication/ Broadcasting/ Entertainment/ IT{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf("Tourism and Leisure") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          8. Tourism and Leisure{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14126].value
                            .split("|")
                            .indexOf("Any other category") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          9. Any other category{" "}
                        </div>
                      </div>
                      <p className="margin0">Type of Service</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14135].value ===
                          "Mobile  Voice" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Voice{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[14135].value ===
                          "Mobile Broadband 2G 3G" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Broadbond 2G/3G/4G{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[14135].value ===
                          "Wireline Voice (PRI)" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Wireline Voice (PRI){" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[14135].value ===
                          "Domestic SIP Trunk" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Domestic SIP Trunk{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14135].value ===
                          "SIP Central" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          SIP Central{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14135].value ===
                          "Domestic Toll Free" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Domestic Toll Free{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14135].value ===
                          "International Toll Free" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          International Toll Free{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14135].value ===
                          "Audio Conferencing" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Audio Conferencing{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14135].value ===
                          "Video Conferencing " ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Video Conferencing{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14135].value ===
                          "2G/3G/4G API Solution" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          2G/3G/4G API Solution{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14135].value ===
                          "GVN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GVN{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14135].value ===
                          "Cloud Telephony" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Cloud Telephony{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14135].value ===
                          "Call Recording" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Call Recording{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14135].value ===
                          "Mobile Applications" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Applications{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft pad_t3">
                          {" "}
                          <strong>Internet of Things</strong>{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14157].value ===
                          "Connectivity" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Connectivity{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14157].value ===
                          "Managed Connectivity" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Managed Connectivity{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14157].value ===
                          "SuperIOT" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          SuperIOT{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14157].value ===
                          "Location Based Services" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Location Based Services{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14157].value ===
                          "Logistics Tracking" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Logistics Tracking{" "}
                        </div>
                        <div className="fleft pad_l_2 ">
                          {this.state.priCafDataupdated[14157].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15022].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <p className="auth">
                        Tariff Plan
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14109].value}
                          readOnly
                        />
                      </p>
                    </div>
                    <div className="flright width49">
                      <p className="margin0">Services on Voice</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14163].value ===
                          "NCUG" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          NCUG{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14163].value ===
                          "IR" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          IR{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14163].value ===
                          "Itemised Bill" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Itemised Bill{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14163].value ===
                          "GPRS" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GPRS{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14163].value ===
                          "ISD" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          ISD{" "}
                        </div>
                        <div className="fleft pad_l_2 ">
                          {this.state.priCafDataupdated[14163].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15023].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <p className="margin0">Value Added Services:</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14169].value ===
                          "CCT" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          CCT{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14169].value ===
                          "MCI" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          MCI{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14169].value ===
                          "Enterprise Alerts" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Enterprise Alerts{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14169].value ===
                          "VMC" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          VMC{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14169].value ===
                          "Mobile Internet 2G/3G/4G" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Internet 2G/3G/4G{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {this.state.priCafDataupdated[14169].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15024].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <p className="margin0">Devices</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14175].value ===
                          "BlackBerry" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Blackberry{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14175].value ===
                          "Smartphone" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Smartphone{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14175].value ===
                          "Tablet" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Tablet{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14175].value ===
                          "Netbook" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Netbook{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14175].value ===
                          "USB Stick" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          USB Stick{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14175].value ===
                          "Moible Wifi" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Moible Wifi{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[14175].value ===
                          "Sharing Dock" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Sharing Dock{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[14175].value ===
                          "GSM Gateway" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GSM Gateway{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {this.state.priCafDataupdated[14175].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[14184].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {" "}
                          CUG Code{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[14188].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      E-Bill Opt In (Go Green)
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width30"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14186].value}
                        readOnly
                      />
                    </div>
                    <div className="flright width50">
                      {" "}
                      E-Mail ID for sending bills
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14187].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">Payment Details</p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width70">
                      <span className="">
                        Name of Person Responsiable for Payments
                      </span>
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14190].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      <span className="">Mobile No. +91</span>
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14191].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid font_8">
                    <div className="fleft width30"> Billing Address</div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[14192].value ===
                      "Authorised Sign Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Authorised Sign Location
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[14192].value ===
                      "Key Contact Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Key Contact Location
                    </div>
                    <div className="fleft ">
                      {this.state.priCafDataupdated[14192].value ===
                      "Company Registered Address" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Company Registered Address
                    </div>
                  </div>
                  <div className="check margin0 over_hid font_8">
                    <div className="fleft pad_l_5 width30"> Payment Mode</div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[14195].value === "ECS" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ECS
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[14195].value === "SI" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SI
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[14195].value ===
                      "CHEQUE" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CHEQUE
                    </div>
                    <div className="fleft pad_l_5 pad_5t">
                      {" "}
                      Check (for ECS and SI, please fill adational form){" "}
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft">
                      {" "}
                      Deposit Amount{" "}
                      <span className="in_subput">
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14198].value}
                          readOnly
                        />
                      </span>{" "}
                    </div>
                    <div className="fleft pad_l10">
                      {" "}
                      Deposit Type
                      {this.state.priCafDataupdated[14199].value ===
                      "Deposit" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2">
                      {" "}
                      Security
                      {this.state.priCafDataupdated[14199].value ===
                      "Security" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2">
                      {" "}
                      IR Deposit
                      {this.state.priCafDataupdated[14199].value ===
                      "IR Deposit" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2 ">
                      {" "}
                      Others{" "}
                      <span className="in_subput">
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14202].value}
                          readOnly
                        />
                      </span>{" "}
                    </div>
                  </div>
                  <p className="ligthbg">Customer Declaration</p>
                  <div className="check margin0 over_hid">
                    <div className="fleft width70">
                      <div className="pad_r15_pri">
                        I/We agree to refundable security deposit and all
                        charges associated with the services selected by me/us.
                        I/We have read and terms and conditions mentioned
                        overleaf and unconditionally accept them as binding on
                        me/us. I/'We have understood all the rates. tariffs and
                        other related co it telecommunications services be
                        provided inside and outside India as applicable as on
                        this date and as amended from time to time. I/We hereby
                        undertake to pay all charges raised on account Of
                        Services availed, l/We undertake that in the event this
                        connection is used for any telemarketing purpose. I/We
                        shall be solely responsible for registration of the
                        connection with the mandated statutory authorities.
                        further declare and undertake that above information
                        provided by me/us is true and correct in all respect.
                        I/We understand that the SIM non-transferable. Any
                        misuse of SIM by customer or any other person is illegal
                        and shall be liable for criminal actions.
                      </div>
                      <div className="check margin0 over_hid pad_t10">
                        <div className="">
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                          I agree to receive a welcome mail giving details of
                          service(s) subscribed{" "}
                        </div>
                      </div>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td width="50%">
                                {firstCaps != "" ? (
                                  <div
                                    style={{
                                      padding: "10px",
                                      position: "absolute",
                                    }}
                                    className={Signcss}
                                  >
                                    {firstCaps + "  .  "}
                                    <span className="under_line">
                                      {secondBold}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="50%">
                                {this.state.imageData != null &&
                                this.state.imageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      src={
                                        this.state.imageData != null
                                          ? "data:" +
                                            this.state.imageType +
                                            ";base64," +
                                            this.encode(this.state.imageData)
                                          : ""
                                      }
                                      alt={"Company Seal"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput">
                                    <input
                                      className="form_col width60 flright"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.priCafDataupdated[15573]
                                          .value
                                      }
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">VIL Internet Use</p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width60">
                      <span className="">Enterprise Description Code</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14204].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      <span className="">Large Account Code</span>
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14205].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width60">
                      <span className="">FA/BA/PCN ID</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14206].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft width49">
                      <div className="check margin0 over_hid">
                        <div className="fleft width65 pad_r15_pri">
                          {" "}
                          Verification by Dealer/Agent (Distributor/ Retailer):
                          I confirm that the applicant has signed the form and
                          documents attached in my presence and that the photo
                          affixed in this form is of the applicant. I have
                          verified the documents With their respective originals
                          and certify the same to be true. I further confirm
                          that all necessary documents are in order including
                          that the signatures on the self attested photo match
                          with the signature on application form and Photo on
                          the form matches the one on the photo ID document.{" "}
                        </div>
                        <div className="flright width34">
                          {this.state.acctMngSignImageData != null &&
                          this.state.acctMngSignImageData != "" ? (
                            <img
                              height="75px"
                              width="75px"
                              src={
                                this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != ""
                                  ? "data:" +
                                    this.state.acctMngSignImageType +
                                    ";base64," +
                                    this.encode(this.state.acctMngSignImageData)
                                  : ""
                              }
                              alt={"Sign"}
                            />
                          ) : (
                            ""
                          )}
                          <div>Sign in with Seal/Stamp &nbsp; &nbsp; </div>
                          <div className="in_subput_date">
                            Date
                            <input
                              className="form_col"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[14335].value}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="ligthbg">
                        Declaration of VIL employee activating SIM
                      </p>
                      <div className="check margin0 over_hid">
                        <div className="fleft width55 pad_r15_pri">
                          {" "}
                          I hereby confirm that the required documentation Such
                          as POI/POA is available and that details of the
                          Customer is complete and have been entered in the
                          database.{" "}
                        </div>
                        <div className="flright width45">
                          <div className="activatesim">
                            <div className="check margin0 over_hid">
                              <div className="flright width70">
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <img
                                    height="25px"
                                    width="75px"
                                    src={
                                      this.state.acctMngSignImageData != null &&
                                      this.state.acctMngSignImageData != ""
                                        ? "data:" +
                                          this.state.acctMngSignImageType +
                                          ";base64," +
                                          this.encode(
                                            this.state.acctMngSignImageData
                                          )
                                        : ""
                                    }
                                    alt={"Sign"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="flright width70">
                                <div className="font7">
                                  <div className="in_subput">
                                    Employee Name
                                    <input
                                      className="form_col width40px"
                                      type="text"
                                      name=""
                                      value=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="font7 in_subput">
                                  <input
                                    className="form_col width100per"
                                    type="text"
                                    name=""
                                    value={
                                      this.state.priCafDataupdated[14331].value
                                    }
                                    readOnly
                                  />
                                </div>
                                <div className="font7">
                                  <div className="in_subput">
                                    Designation
                                    <input
                                      className="form_col width40px"
                                      type="text"
                                      name=""
                                      value=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="font7">
                                  <div className="in_subput_date">
                                    Date
                                    <input
                                      className="form_col"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.priCafDataupdated[14335]
                                          .value
                                      }
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                    Cellular Limited )<br />
                    An Adity Birla Group and Vodafone partneship
                    <br />
                    MergerCo CIN No: L32100GJ1996PLC030976
                    <br />
                    <strong>Registred Office:</strong> Suman Tower, Plot no. 18,
                    Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79 66714000
                    | F: +91 79 23232251
                    <br />
                    <strong>Circle Office Address:</strong>{" "}
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="ligthbg font8">TERMS &amp; CONDITIONS </p>
                  <p className="font10">
                    <strong>
                      The "Customer Application Form" (CAD shall mean the
                      Customer information form. to which the following
                      conditions ("Regulatory Terms") are required to be
                      provided/completed with effect from /prior to activation
                      of any Services, and continue during the term of service.
                      These are conditions specified by the Department of
                      Telecommunication ("DOT) Ministry of Communication and
                      Information Technology ("MCIT"), Government of India
                      ("Go") and are mandatory conditions. There shall be no
                      deletion or deviation from the following Regulatory Terms.
                      This CAF shall be read with the Product Specific Terms
                      which shall form the Agreement between the Parties.
                      Licensed Service Area • shall mean the area where Operator
                      is permitted to offer telecommunication services, (i)
                      Operator • shall mean VIL entity having license to operate
                      in Circle. o 'Authority' shall mean the Department of
                      Telecommunications (DOT). Telecom Regulatory Authority of
                      India (TRAI), Ministry of Communication and Information
                      Technology (MOCIT), Government of India (GOI) and includes
                      any officer of the Authority,' "Corporate Plan" shall mean
                      tariff structure devised by for Corporate Customers. •
                      'Charges' means all fees, charges/ tariffs,
                      interconnection cost and rates chargeable by VIL from time
                      to time for provisioning the Services to the Customer •
                      'Customer' shall mean Corporate organization named in the
                      CAF entering into the requisite contract comprising of
                      CAF, Verification documents etc, For Internet of Things
                      products, refer Intemet Of Things GTCs agreed by the
                      customer.{" "}
                    </strong>{" "}
                  </p>
                  <div className="over_hid">
                    <div className="fleft width49">
                      <ul className="list">
                        <li>
                          It is agreed betweet "Vodafone Idea Limited (VIL)" &
                          "Customer" as fallows;
                        </li>
                        <li>
                          1. PROVISION SERVICES: VIL agrees to provide the
                          services to the Customer subject to terms and
                          condition of the Agreement.{" "}
                        </li>
                        <li>
                          2. OBLIGATIONS OF VIL: VIL shall provide services with
                          reference to and subject to the telecommunication
                          permitting the operations of a telephony services. The
                          Customer expressly agrees that activation of the
                          Services shall be Subject to verification of the
                          particulars and documents submitted by the Customer
                          including tele-verification. The availability and
                          quality of services may be affected by factor outside
                          VIL control such as physical obstruction. geographic
                          and weather conditions and other cause of radio
                          interference or faults in other telecommunications
                          network to which network is connected. The Services
                          may be Suspended in Whole or in part at any time with
                          proper notice and in compliance to existing
                          guidelines. policies and regulations.. however if the
                          network fails or requires modifications or maintenance
                          due to a sudden or force majeure event beyond control
                          of operator. such advance notice may not be possible.
                          Please note all extra discounting promos on special
                          deals or on Corporate Plans and Closed User Group
                          (COG). Vodafone Mobile Connect (VMC). Vodafone Live
                          (VI). Blackberry (BB) and other Data services along
                          with respective discounts on usage will be activated
                          minimum of 72hrs from date of number activation or the
                          request as the case may be, The allotment of a number
                          will be made by VIL in its absolute discretion. In the
                          event of any provisioned SIM ( where a Service
                          involves a provisioning of SIM) being lost or stolen.
                          VIL will replace the SIM card as soon as it is
                          reasonable & practicable. subject to the recovery of
                          any charges for the same. VIL reserve the right to
                          apply a monthly financial limit and such other
                          conditions for charges incurred by the Customer and to
                          demand interim advance payment and also suspend
                          (and/or disconnect access to the services if such
                          limit is exceeded with proper intimation and in
                          compliance to existing guidelines, policies and
                          regulations VIL has the right to check the credential
                          Of the Customer including the Customer financial
                          standing & to use the services of any person or agency
                          for such purposes. Any waiver. concession or extra
                          time allowed or granted by VIL to the Customer is
                          limited the specific circumstances in which it was
                          given and the same shall not effect VIL's right under
                          this agreement in any ways.
                        </li>
                        <li>
                          3. OBLIGATIONS OF THE CUSTOMER: The Customer shall
                          ensure (i) that it signs all bulk connection
                          declarations and forms as are created pursuant to DOT
                          guidelines on bulk connections; (ii) that it has the
                          users comply with the said regulations and conclude
                          the tele-verification process by calling the call
                          centre at 1 17 so as to enable VIL to activate his/her
                          subscribed service plan: (iii) thet it shall not use
                          or cause or allow others to use the Services for any
                          improper. immoral or unlawful purpose including in any
                          manner which may jeopardise or impair the operation Of
                          the Network and/or the Services. Customer agrees to
                          inform VIL immediately and confirm the same in writing
                          if the Equipment and/or the SIM Card is lost. stolen
                          or damaged. The Customer will remain liable for all
                          Charges incurred until the SIM Card is de-activated by
                          VIC (iv) to furnish correct and complete information
                          and documents as required by VIL from time to time.
                          The Services agreed to be provided by VIL shall always
                          be subject to verification Of the Customer'S
                          credentials and documents and if at any time, any
                          information and/or documents furnished by the Customer
                          Mare found incorrect or incomplete or suspicious. VIL
                          shall be entitled to suspend/terminate the Service
                          forthwith without any further notice. In case any
                          change of address of the Customer or end user. the
                          Customer agrees to inform VIL in writing with regard
                          to change in address and furnish documents in support
                          Of such new address. In case during any time during
                          the subscription, in case the Customer does not inform
                          with regard to change in his address or end user then
                          VIL reserves its right to disconnect the Services
                          and/or cancel the connection without any notice in
                          this regard and VIL shall not be liable to the
                          Customer/end user in any manner whatsoever.{" "}
                        </li>
                        <li>
                          4. DOT LICENSING AND VERIFICATION OF SUBSCRIBE: (i)
                          VIL may be requited to disclose any information
                          pertaining to the Customer/Subscriber to any statutory
                          / regulatory / security authority, financial
                          institution or bank and as per regulation. VIL can do
                          so without any prior intimation to the Customer: (ii)
                          Customer shall indemnify and keep indemnified VIL for
                          all and any claims. proceedings or actions brought
                          against VIL arising out of any breach of the Customer
                          of the Regulatory Terms: (iii) VIL may use. process
                          and/or transfer Personal Information: (iv) in
                          connection with the provision Of Services; (v) to
                          incorporate Personal Information into databases
                          controlled by VIL for the purpose of account
                          administration, billing and reconciliation.
                          operational maintenance and support activities. fraud
                          detection and prevention. and customer and market
                          analysis and reporting. (vi) The Customer shall use
                          the Services only for the purposes Specified by the
                          Customer in the Agreement / Customer Application Form
                          (or Customer Order Form or Customer Information Form
                          hereinafter CAF/COF/CIF) and shall not be used for or
                          connected to any telecommunication service unless
                          otherwise permitted by the Governmental Authority with
                          specific approvals/ licenses. The Customer understands
                          and agrees that VIL shall be entitled to terminate the
                          Service and collect financial penalty as levied by the
                          Governmental Authority for any unauthorized use. VIL
                          shall also. at its sole discretion, be entitled to
                          disconnect the unauthorized circuit with immediate
                          effect. The Services shall not be used for call
                          center/ telemarketing/ other service provider (OSP)
                          operations without registration With the Department of
                          Telecommunications and/ or Telecom Regulatory
                          Authority of India as a call center/ OSP. The Customer
                          who wants to take the services for call center/ OSP
                          operations shall submit copy of call center/ OSP
                          license or registration to VIL (vii)The Customer shall
                          be allotted a phone number/iden- tity number by VIL
                          which is and shall always remain the property of VIL.
                          The Customer cannot transfer market, re•brand. resell
                          and/or otherwise assign Services purchased including
                          the phone number/iden tity number. Services to any
                          Other person/entity unless agreed by VIL in writing.
                          (viii) The Customer undertakes to comply with the
                          applicable laws and regulations regarding the use Of
                          the Service. including without limitation. the TRAI
                          Act 1997. the Indian Telegraph Act 1885. the Indian
                          Wireless
                        </li>
                      </ul>
                    </div>
                    <div className="fright width49">
                      <ul className="list">
                        <li>
                          Telegraphy Act 1933. Information Technology Act 2000
                          and all amendments, replacements, rules and
                          regulations made under such legislations from time to
                          time. The Customer shall obtain any relevant consents
                          and approvals for the installation and use of any
                          equipment located at the Customer sites and associated
                          with the Service and any permits, consents or
                          approvals required by the Subscriber to use the
                          Service. including any applicable other service
                          provider permits ("Permits"). Customer shall use the
                          Services in accordance with the Permits. Customer
                          shall provide VIL with an up-to-date copy of the
                          Customefs CIJG approval (if any) issued by the
                          Department Of Telecommunications from time-to-time.
                          Customer shall ensure that any network equipment (not
                          provided by Services Provider) which it uses in
                          relation to the Services meet the relevant
                          International Telecommunication Union
                          (ITU)/Telecommunication Engineering Centre (TEC)
                          standarc'fr (ix) The Customer shall ensure that
                          unauthorized or any Other Content. messages or
                          communica- tians infringing copynght. Intellectual
                          property right and international & domestic cyber
                          laws. in any form or inconsistent with the laws of
                          India. are not carried in his network by him or any
                          other person using his network or dunng the
                          provisioning of Semces. The Customer must take all
                          necessary measures to prevent such use, The use of the
                          Service for anti-national activities shall be
                          construed as an offence punishable under the Indian
                          Penal Code or other applicable law. The Customer must
                          ensure that any service provided by Service Provider
                          is not used for any unlawful purposes or any Other
                          person using the Services as End User. G) Customer
                          agrees and acknowledges that regulatory and law
                          enforcement agencies Law Enforcement Agency (LEA),
                          shatt have rights to access the network and intercept
                          or listen or record calls being made using the
                          Services and facilities provided by Service Provider
                          and Customer shall provide necessary
                          facilities/access/consent to Service Provider and Law
                          Enforcement Agency (LEA), including but not limited to
                          T RAJ. DOT. Police authorities. Reserve Bank of India.
                          Enforcement Directorate and Or any other Government
                          Agencies to monitor. contiol. prevent counteract
                          espionage. subversive act sabotage or any other
                          unlavvfulactivity. (xi) Customer understands Customer
                          and its End Users use the Services at their own risk
                          and consequences and Service Provider has no control
                          over and accepts no responsibility whatsoever for such
                          the Customer use of the Services and the transacted
                          business/transmitted communication and Customer shall
                          comply with all regulatory/legal and Statutory
                          requirements during such use of the Services. (xii)
                          Customer understands and agrees that Service Provider
                          shall terminate the Service provided to the Customer
                          immediately without any prior notice. upon any
                          violation of these terms and conditions by the
                          Customer and/or On account Of any regulatory violation
                          by Customer or its End Users in using the Service or
                          in case of any directive of any Law Enforcement Agency
                          (LEA), Department Of Telecommunication ("DOT"),
                          Judicial authority or any other Government agency.
                          (xiii) The customer is required to maintain and
                          provide updated list Of actual users to VIL at ad
                          times and the customer agrees to give VIL the
                          unhindered right to visit the customer premises and
                          verify bonafide use of the servrces , (o) Customer
                          shall not use the Services for any abusive, improper.
                          immoral. offensive. illegal or fraudulent purposes
                          including making calls or sending data that in VILS
                          reasonable opinion is a nuisance. hoax, menacing,
                          indecent. racist. obscene and/ Or defamatory or do
                          anything that causes the Network to be impaired or
                          damaged. Using Services to access the Internet: In the
                          event that Customer or End User uses Services to
                          access facilities and services not included in a
                          Tariff, VIL shall not be liable under this Agreement
                          for the security or confidentiality of such facilities
                          or services. (xiv) No information with regard to an
                          account information can be shared with any third party
                          other than the duly notified authorized signatory of
                          the Customer as notified by Customer to VIL and VIL
                          cannot provide any account related information Outside
                          India.{" "}
                        </li>
                        <li>
                          5. CONTRACT TERMS AND COMMERCIAL TERMS: This CAF,
                          shall be read With the General Terms and Conditions
                          except where any master services agreement (MSA) is
                          entered including (or any India specific in-country
                          agreement (ICC) between the Customer and Service
                          Provider entered subsequent to or pursuant to any
                          globat level MSA entered between the global
                          principals/affiliates Of the Customer and Service
                          Provider) and the MSA provisions shall prevail over
                          the GTC). Any Specific terms agreed for the mobility
                          shall be set Out in the Part C Or a Product Terms and
                          Conditions and Schedules to the GTC. The CAF terms
                          will prevail over any provisions in the GTC/ or the
                          MSA/ICC. which conflict With terms in CAF. All types
                          of Voice. SMS and Data offerings are a part of the
                          licensed services. The usage charges for these
                          services would be governed by the Tariff plan opted by
                          the customer.
                        </li>
                        <li>
                          6. GOVERNING LAW: This CAF shall be subject to Indian
                          Law and courts Of jurisdiction shall include Courts in
                          the place where Customer has a place of business or
                          where the cause of action arises or the place where
                          the Services have been offered.{" "}
                        </li>
                        <li>
                          7. MISCELLANEOUS: The Authorised Signatory signing
                          this CAF on behalf of the customer hereby confirm,
                          consent and authorize VIL to sell Vodafone Reload Plan
                          or such other plans as may be branded by VIL for their
                          COCP users. Such Plan/s will be activated upon the end
                          user's request and their advance payments made
                          directly by the end user either through VILS dedicated
                          channels or including but not limited to Vodafone
                          website, app. etc.. with no liability on the
                          enterprise customer entity. Subscriber hereby declare
                          and gives their consent to VIL for the collection,
                          processing and use Of their personal data such as.
                          including but not limited to. their name. contact
                          details and employment related information for the
                          purposes of ustng the Services and additional services
                          according to VIL's Privacy Policy displayed on its
                          website www.vodafone.in.
                        </li>
                        <li>
                          8. GST &amp; SEZ STATUS: The customer will be solely
                          responsible for providing the correct GST number and
                          'SEZ" related information against the "Place Of
                          Supply" as per the GST law. S/he waives Off all the
                          rights to dispute or withhold any payments in case of
                          incorrect GST Numbers or incorrect SEZ certificates/
                          status being provided. which donet match the details
                          available on the Government GSTN portal. The customer
                          acknowledges that s/he will not be able to claim the
                          GST tax credit under such circumstances and shall pay
                          100% invoiced amount. including taxes.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p className="ligthbg font8">Customer Acceptance </p>
                  <div className="margin0 over_hid ">
                    <div className="fleft width70">
                      <p className="auth pad_t10">
                        Customer Name
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14208].value}
                          readOnly
                        />
                      </p>
                      <p className="auth pad_t10">
                        Designation
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14209].value}
                          readOnly
                        />
                      </p>
                      <p className="auth pad_t10">
                        Company Name
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14210].value}
                          readOnly
                        />
                      </p>
                      <p>
                        <strong>
                          Note: I/We accept the responsiblates of providing the
                          list of mobile nos/DID nos with user name &nbsp;
                          Designation, alongwith CFA
                        </strong>
                      </p>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td width="50%">
                                {firstCaps != "" ? (
                                  <div
                                    style={{
                                      padding: "10px",
                                      position: "absolute",
                                    }}
                                    className={Signcss}
                                  >
                                    {firstCaps + "  .  "}
                                    <span className="under_line">
                                      {secondBold}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="50%">
                                {this.state.imageData != null &&
                                this.state.imageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      src={
                                        this.state.imageData != null
                                          ? "data:" +
                                            this.state.imageType +
                                            ";base64," +
                                            this.encode(this.state.imageData)
                                          : ""
                                      }
                                      alt={"Company Seal"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg font8">
                    {" "}
                    Fulfilment and channel details{" "}
                  </p>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Documents Verified by
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14214].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Date
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14215].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Channel Partner</div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14216].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14217].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Vodafone Store</div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14218].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14219].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Vodafone No.</div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14220].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Sim Card No.
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14221].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft width70">
                      <div className="pad_r15 pad_t10">
                        I/We hereby undertake and confirm that I/We have seen
                        the customer and certify that (a) the customer enrolment
                        form has been duly filled by the applicant, (b) the
                        photograph submitted has been matched with the
                        applicant, (c )the form has been personally signed by
                        the applicant in my presence, (d) proof of identity &
                        address has been collected, (e) the original proof of
                        identity & address has been matched and verified with
                        the self-attested documents submitted by the applicant.
                        Details on the Vodafone CAF match with these documents.
                        In case of outstation customer, I/ We hereby confirm
                        that I/We tele-verified the particulars furnished by the
                        applicant with local reference and recorded its
                        correctness. Name of Local Reference contacted ( for
                        Outstation subscribers).
                        <span className="in_subput">
                          <input
                            className="form_col width150px"
                            type="text"
                            name=""
                            value=""
                            readOnly
                          />
                        </span>{" "}
                      </div>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      height="25px"
                                      width="100px"
                                      src={
                                        this.state.acctMngSignImageData !=
                                          null ||
                                        this.state.acctMngSignImageData != ""
                                          ? "data:" +
                                            this.state.acctMngSignImageType +
                                            ";base64," +
                                            this.encode(
                                              this.state.acctMngSignImageData
                                            )
                                          : ""
                                      }
                                      alt={"Signature of Account Manager"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col width60 flright"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.priCafDataupdated[15573]
                                          .value
                                      }
                                      readOnly
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width40">
                      Enterprise Code
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14223].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width35">
                      Account Manager Name
                      <input
                        className="form_col width45"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14224].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Code
                      <input
                        className="form_col width70 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14225].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="enroll_form"> Tariff Enrolment Form</p>
                  <p className="sip_central">SIP Trunking</p>
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width70">
                      <div className="fleft width40">
                        <strong>SDCA name</strong> (where service is required):
                      </div>
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14226].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      <strong>STD Code</strong>
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[15039].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width70">
                      <div className="fleft width40">
                        <strong>Proof of Billing Address attached:</strong>
                      </div>
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14227].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p>
                    <strong>Installation Address:</strong>
                  </p>
                  <div className="pad_l50">
                    <p className="auth">
                      Contact Person Name
                      <input
                        className="form_col width45"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14229].value}
                        readOnly
                      />
                    </p>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width40">
                        Designation
                        <input
                          className="form_col width65"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14230].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width25">
                        Department
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14231].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <p className="auth">
                      Address
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14232].value}
                        readOnly
                      />
                    </p>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width40">
                        City/ Village/ Post Office:
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14233].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width25">
                        Pin:
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14234].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width40">
                        Telephone Number:
                        <input
                          className="form_col width58"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14235].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width25">
                        Fax:
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14236].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width36">
                        Contact email id:
                        <input
                          className="form_col width58"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14237].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width40">
                        Mobile No./ Alt No.:
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14238].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p>
                    <strong>GST Customer Information:</strong>
                  </p>
                  <div className="pad_l50">
                    <div className="check margin0 over_hid">
                      <div className="fleft width10">
                        GSTIN
                        {this.state.priCafDataupdated[14078].value ===
                        "GSTIN" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width10">
                        UIN
                        {this.state.priCafDataupdated[14078].value === "UIN" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width20">
                        GST_ISD
                        {this.state.priCafDataupdated[14078].value ===
                        "GST_ISD" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width12 pad_t2">Customer Type</div>
                      <div className="fleft width12">
                        {" "}
                        Normal
                        {this.state.priCafDataupdated[14081].value ===
                        "Normal" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        SEZ
                        {this.state.priCafDataupdated[14081].value === "SEZ" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Diplomat
                        {this.state.priCafDataupdated[14081].value ===
                        "Diplomat" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Embassy
                        {this.state.priCafDataupdated[14081].value ===
                        "Embassy" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Consulate
                        {this.state.priCafDataupdated[14081].value ===
                        "Consulate" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Special Agency
                        {this.state.priCafDataupdated[14081].value ===
                        "Special Agency" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Persons/ Class of Persons
                        {this.state.priCafDataupdated[14081].value ===
                        "Persons/ Class of Persons" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <p className="auth">
                      GSTIN/ UIN/ GST_ID No
                      <input
                        className="form_col wid30"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14088].value}
                        readOnly
                      />
                    </p>
                    <p className="auth">
                      GST Regd. Address (in Installation State)
                      <input
                        className="form_col wid50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14243].value}
                        readOnly
                      />
                    </p>
                    <div className="check over_hid typecheck gstcheck gstone">
                      <div className="fleft width12 pad_t2">
                        GST Customer Dept
                      </div>
                      <div className="fleft width12">
                        {" "}
                        IT
                        {this.state.priCafDataupdated[14244].value === "IT" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Tech
                        {this.state.priCafDataupdated[14244].value ===
                        "Tech" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Customer Service
                        {this.state.priCafDataupdated[14244].value ===
                        "Customer Service" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Legal
                        {this.state.priCafDataupdated[14244].value ===
                        "Legal" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Finance
                        {this.state.priCafDataupdated[14244].value ===
                        "Finance" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Purchase
                        {this.state.priCafDataupdated[14244].value ===
                        "Purchase" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        SCM
                        {this.state.priCafDataupdated[14244].value === "SCM" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {this.state.priCafDataupdated[14244].value ===
                        "Delivery" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Delivery{" "}
                      </div>
                      <div className="fleft width20 pad_t2">
                        {this.state.priCafDataupdated[14244].value ===
                        "Others" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Others
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14245].value}
                        />
                      </div>
                    </div>
                    <p className="auth">
                      GST Supporting Document (GST Certificate, SEZ Certificate,
                      Diplomat certificate)
                      <input
                        className="form_col width40"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[14246].value}
                        readOnly
                      />
                    </p>
                  </div>
                  <p>
                    <strong>Service Requirements:</strong>
                  </p>
                  <div className="pad_l50">
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft pad_t2 pad_r50">Flavour</div>
                      <div className="fleft width15">
                        {" "}
                        Standard
                        {this.state.priCafDataupdated[14247].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width12">
                        {" "}
                        Non-Standard
                        {this.state.priCafDataupdated[14247].value ===
                        "Non Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft fnt7 pad_t5">
                        {" "}
                        (for a customized tariff plan, select Non-Standard){" "}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft pad_t2 pad_r50">Session Type:</div>
                      <div className="fleft width12">
                        {" "}
                        Voice
                        {this.state.priCafDataupdated[14248].value ===
                        "Voice" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">
                        Concurrent number of Sessions:{" "}
                      </div>
                      <div className="fleft width50">
                        Voice
                        <input
                          className="form_col width10"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14249].value}
                          readOnly
                        />
                        <span className="fnt7 pad_l10">
                          (Session granularity = 1)
                        </span>{" "}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">Codec </div>
                      <div className="fleft width50">
                        Voice
                        <input
                          className="form_col width10"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14250].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">
                        Additional bandwidth needed:{" "}
                      </div>
                      <div className="fleft width5">
                        <input
                          className="form_col width60"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14252].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft check">
                        {this.state.priCafDataupdated[14251].value ===
                        "Mbps" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Mbps &nbsp; &nbsp; &nbsp;{" "}
                      </div>
                      <div className="fleft check">
                        {this.state.priCafDataupdated[14251].value ===
                        "Gbps" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gbps{" "}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">
                        Multiple Pilot Numbers Required:{" "}
                      </div>
                      <div className="fleft check">
                        {" "}
                        Yes
                        {this.state.priCafDataupdated[14253].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        &nbsp; &nbsp; &nbsp;{" "}
                      </div>
                      <div className="fleft check">
                        {" "}
                        No
                        {this.state.priCafDataupdated[14253].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft pad_l30 width50 pad_5t">
                        Pilot Number Quantity:
                        <input
                          className="form_col width10"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14254].value}
                          readOnly
                        />
                        <span className="fnt7">(max 5)</span>{" "}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">Pilot Number 1: </div>
                      <div className="fleft width10">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14255].value}
                          readOnly
                        />
                        <div className="fnt6 textcenter">(STD code) </div>
                      </div>
                      <div className="fleft width20 pad_l50">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14256].value}
                          readOnly
                        />
                        <div className="fnt6 textcenter">(Pilot Number) </div>
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">Pilot Number 2: </div>
                      <div className="fleft width10">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14257].value}
                          readOnly
                        />
                        <div className="fnt6 textcenter">(STD code) </div>
                      </div>
                      <div className="fleft width20 pad_l50">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14258].value}
                          readOnly
                        />
                        <div className="fnt6 textcenter">(Pilot Number) </div>
                      </div>
                      <div className="fleft width20 pad_l50">
                        {" "}
                        <br />
                        <div className="fnt6 textcenter">
                          (use additional sheets if required)
                        </div>
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">
                        DID Number Block Quantity 1:{" "}
                      </div>
                      <div className="fleft width10">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14259].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r15 pad_t3">
                        DID Numbers Required 1: From
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14261].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width5 pad_t3">to &nbsp; </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14262].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">
                        DID Number Block Quantity 2:{" "}
                      </div>
                      <div className="fleft width10">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14263].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r15 pad_t3">
                        DID Numbers Required 2: From
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14265].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width5 pad_t3">to &nbsp; </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14266].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft fnt7 pad_t3 pad_l10">
                        (use additional sheets if required)
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft pad_r50">
                        Number Reservation ID:{" "}
                      </div>
                      <div className="fleft width10">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14267].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_l50">
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width20 pad_t2">Billing Type: </div>
                      <div className="fleft width20">
                        {" "}
                        Pilot Wise
                        {this.state.priCafDataupdated[14268].value ===
                        "Pilot Wise" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width20 pad_t2">
                        Facilities Required:{" "}
                      </div>
                      <div className="fleft width20">
                        {" "}
                        Incoming & Outgoing Local Only
                        {this.state.priCafDataupdated[14269].value
                          .split("|")
                          .indexOf("Incoming & Outgoing Local Only") >= 0 ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width20">
                        {" "}
                        STD Calling
                        {this.state.priCafDataupdated[14269].value
                          .split("|")
                          .indexOf("STD Calling") >= 0 ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width20">
                        {" "}
                        ISD Calling
                        {this.state.priCafDataupdated[14269].value
                          .split("|")
                          .indexOf("ISD Calling") >= 0 ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width20">
                        {" "}
                        Incoming Calls only
                        {this.state.priCafDataupdated[14269].value
                          .split("|")
                          .indexOf("Incoming Calls only") >= 0 ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width20 pad_t2">CUG: </div>
                      <div className="fleft width15">
                        {" "}
                        Yes
                        {this.state.priCafDataupdated[14270].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft">
                        {" "}
                        No
                        {this.state.priCafDataupdated[14270].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        <span className="fnt7 pad_t3">
                          (SIP Central-2-SIP Central)
                        </span>{" "}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width20 pad_t2">
                        UCC Preference:{" "}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Yes
                        {this.state.priCafDataupdated[14271].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft">
                        {" "}
                        No
                        {this.state.priCafDataupdated[14271].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width20 pad_t2">DID Operator: </div>
                      <div className="fleft width15">
                        {" "}
                        Yes
                        {this.state.priCafDataupdated[14272].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft">
                        {" "}
                        No
                        {this.state.priCafDataupdated[14272].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft pad_t3 fnt7">
                        {" "}
                        (If Yes, please execute DID Operator Agreement with VIL
                        and submit a copy of the VNO license){" "}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width25 pad_t2">
                        Existing VIL L2/L3 VPN customer:
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Yes
                        {this.state.priCafDataupdated[14274].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        No
                        {this.state.priCafDataupdated[14274].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width20">Circuit ID: </div>
                      <div className="fleft width10">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14275].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width25 pad_t2">
                        CPE Provided by:{" "}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        VIL
                        {this.state.priCafDataupdated[14276].value === "VIL" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Customer
                        {this.state.priCafDataupdated[14276].value ===
                        "Customer" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width25 pad_t2">
                        If provided by VIL:{" "}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Rent
                        {this.state.priCafDataupdated[14277].value ===
                        "Rent" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Sale
                        {this.state.priCafDataupdated[14277].value ===
                        "Sale" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width33">
                      Private and Confidential – Vodafone C2{" "}
                    </div>
                    <div className="fleft width33 textcenter">
                      Vodafone SIP Central Services{" "}
                    </div>
                    <div className="fleft width33 text_r">
                      © Vodafone India Limited
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <div className="pad_l50">
                    <div className="wifitable width70">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>Sr No</td>
                            <td>
                              Type
                              <br />
                              (Standard/Other)
                            </td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Version</td>
                            <td>Quantity</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              {this.state.priCafDataupdated[14278].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14279].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14280].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14281].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14282].value}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              {this.state.priCafDataupdated[14283].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14284].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14285].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14286].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14287].value}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              {this.state.priCafDataupdated[14288].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14289].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14290].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14291].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14292].value}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      <strong>Last Mile Details:</strong>
                    </p>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width20 pad_t2">
                        Dual Last Mile Needed:{" "}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Yes
                        {this.state.priCafDataupdated[14294].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        No
                        {this.state.priCafDataupdated[14294].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width40">
                        Primary LM Service Provider:
                        <input
                          className="form_col width30 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14295].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width40 pad_l30">
                        Primary Bandwidth: &nbsp; &nbsp; &nbsp; &nbsp;
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14296].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width40">
                        Secondary LM Service Provided:
                        <input
                          className="form_col width30 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14297].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width40 pad_l30">
                        Secondary bandwidth: &nbsp; &nbsp;
                        <input
                          className="form_col width40 "
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14298].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck pad_5t">
                      <div className="fleft width20 pad_t2">
                        Primary LM Media:{" "}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Fiber
                        {this.state.priCafDataupdated[14299].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width20 pad_t2">
                        Secondary LM Media:{" "}
                      </div>
                      <div className="fleft width15">
                        {" "}
                        Fiber
                        {this.state.priCafDataupdated[14300].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <p>Other Operator Contact Details:</p>
                    <div className="wifitable width70">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>Department</td>
                            <td>First Name</td>
                            <td>Last Name</td>
                            <td>Contact Number</td>
                            <td>Email</td>
                          </tr>
                          <tr>
                            <td>
                              {this.state.priCafDataupdated[14302].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14303].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14304].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14305].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14306].value}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {this.state.priCafDataupdated[14307].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14308].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14309].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14310].value}{" "}
                            </td>
                            <td>
                              {this.state.priCafDataupdated[14311].value}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      <strong>Commercials:</strong>
                    </p>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">Tariff Plan Name:</div>
                      <div className="fleft width50">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14109].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">Rental per Session: </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14314].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <p>FCV Multiplier </p>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        (FCV Value/ Rental value per session):
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14316].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">Security Deposit: </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14317].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        Premium Number Charges (one time):{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14318].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        Installation Charges (one time):{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14319].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        Service Registration Charges (one time):
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14320].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        CPE charges (one time) if any:{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14321].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        CPE rental (monthly) if any:{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14322].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        Bandwidth rental (monthly) if any:{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14323].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        CUG rental (monthly) if any:{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14324].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        DID rental (monthly) if any:
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14325].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        Total Amount Payable:{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14326].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        Total Amount Paid with CAF:{" "}
                      </div>
                      <div className="fleft width50">
                        {" "}
                        Rs.
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14327].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="decl">
                      <div className="sealleft">
                        <strong>Seal :</strong>
                        {firstCaps != "" ? (
                          <div
                            style={{ padding: "10px", position: "absolute" }}
                            className={Signcss}
                          >
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <div
                            style={{ padding: "10px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="dectext">
                        {" "}
                        <strong>Declaration:</strong>
                        <br />
                        We have read and understood the General Terms and
                        Conditions mentioned overleaf and unconditionally accept
                        them as binding on us. We have understood all the rates,
                        tariffs and other related conditions at which the
                        Services will be provider inside India as applicable on
                        this date as amended from time to time. We undertake to
                        pay all the charges raised on account of the Services
                        availed. We further declare and undertake that the above
                        provided by us is true and correct in all aspect.
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width33">
                      Private and Confidential – Vodafone C2{" "}
                    </div>
                    <div className="fleft width33 textcenter">
                      Vodafone SIP Central Services{" "}
                    </div>
                    <div className="fleft width33 text_r">
                      © Vodafone India Limited
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <div className="pad_l50">
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        <strong>Customer Account Code:</strong>{" "}
                      </div>
                      <div className="fleft width50">
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14330].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        <strong>Name of the Account Manager: </strong>{" "}
                      </div>
                      <div className="fleft width50">
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[14331].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        <strong>Signature of the Account Manager:</strong>{" "}
                      </div>
                      <div className="fleft width50">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            height="50px"
                            width="100px"
                            src={
                              this.state.acctMngSignImageData != null &&
                              this.state.acctMngSignImageData != ""
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        <strong>Signature of the Customer:</strong>{" "}
                      </div>
                      <div className="fleft width50">
                        {firstCaps != "" ? (
                          <div
                            style={{ padding: "10px", position: "absolute" }}
                            className={Signcss}
                          >
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        <strong>Seal:</strong>{" "}
                      </div>
                      {this.state.imageData != null &&
                      this.state.imageData != "" ? (
                        <div
                          style={{ padding: "10px" }}
                          className="signate_img"
                        >
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width30">
                        <strong>Date:</strong>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t30">
                    <div className="fleft width49">
                      <p className="gen">
                        GENERAL TERMS AND CONDITIONS (“GTC”) for SIP Trunk
                        Service{" "}
                      </p>
                      <div className="over_hid pad_b10">
                        <div className="fleft width7">
                          {" "}
                          <strong>1.</strong>{" "}
                        </div>
                        <div className="fleft width93">
                          {" "}
                          <strong>RECITAL</strong>
                          <br />
                          VIL is willing to provide the Session Initiation
                          Protocol Trunk (“Services”) to the Customer on the
                          terms and conditions (T&C) provided below which
                          otherwise have been made available to Customer.
                          Customer acknowledges that for availing the Services
                          it agrees to comply with the T&C and is seeking to
                          avail the Service herein. Basis the same, VIL shall
                          provide the Services to the Customer and/or any person
                          who for and on behalf of the Customer enterprise in
                          its premises, makes use or avails the Services will be
                          construed as a “User”.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7">
                          {" "}
                          <strong>2.</strong>{" "}
                        </div>
                        <div className="fleft width93">
                          {" "}
                          <strong>PRECEDENCE</strong>{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 1 </div>
                        <div className="fleft width93">
                          {" "}
                          The GTC consists of following documents and their
                          order of precedence in ascending order is: (i)
                          Customer Application Form (“CAF”) duly completed (ii)
                          Tariff Enrolment Form; (iii) Terms of Service; (iv)
                          any other signed documents referenced{" "}
                        </div>
                      </div>
                      <div className="over_hid pad_b10">
                        <div className="fleft width7"> 2 </div>
                        <div className="fleft width93">
                          In the event that the Parties wish to make any change
                          to this GTC then they shall do so expressly in
                          writing, which must be signed by authorised
                          representatives of both Parties.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7">
                          {" "}
                          <strong>3.</strong>{" "}
                        </div>
                        <div className="fleft width93">
                          {" "}
                          <strong>
                            GENERAL TERMS APPLICABLE TO SERVICE
                          </strong>{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 1 </div>
                        <div className="fleft width93">
                          Customer shall ensure that: (i) the Services shall not
                          be used for a call centre / other service provider
                          (OSP) operations without registration with the
                          Department Of Telecommunication (DOT).{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 2 </div>
                        <div className="fleft width93">
                          Of Telecommunication (DOT). 2. Except as expressly set
                          out herein, VIL shall have right to: (i) charge
                          Customers such fees/charges (if any) as may be
                          determine from time to time; and (ii) promote /
                          package the Service{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 3 </div>
                        <div className="fleft width93">
                          {" "}
                          For Services provisioned, Parties acknowledge and
                          agree VIL acts as an intermediary and may not have
                          supervisory, editorial rights over the Services
                          provided to Customer{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 4 </div>
                        <div className="fleft width93">
                          {" "}
                          On violation of CAF terms or Applicable Laws for
                          Services, VIL shall have the right to refuse, reject,
                          suspend, remove or cause to be removed any Services
                          that may be made available to Customers.{" "}
                        </div>
                      </div>
                      <div className="over_hid pad_b10">
                        <div className="fleft width7"> 5 </div>
                        <div className="fleft width93">
                          Customer shall not use or permit the Services for: (a)
                          establishing a connection to the gateway between
                          Internet & PSTN/ISDN/PLMN in India is not permitted &
                          the Customer must ensure that the voice traffic over
                          the enterprise data network and the public switched
                          telephone network must be strictly separated via
                          logical partitioning of the IP-PBX/EPABX so that
                          proper management of the IPPBX/EPABX and no mixing of
                          calls between the two networks can occur for the
                          purpose of toll bypass; or (b) interconnectivity is
                          not permitted for ISPs offering Internet Telephony
                          Services and for ISPs usage; or (c) being used to
                          route unauthorised telecommunication network including
                          to prohibited destinations; or (d) lease/resell or
                          create any third party right over the Services
                          provisioned; or (e) negligence use by Customer, its
                          employees or agents thereby being responsible for
                          ensuring that the Services are used legally and in
                          compliance with the existing regulations; or (f)
                          excess or excessive usage or for any unusual patterns
                          in the usage and/or for any unwarranted/not permitted
                          usage; or (g) use, directly or indirectly, VIL’s
                          network or infrastructure or any part thereof for
                          sending bulk or unsolicited messages to VIL customers
                          or other mobile phone users in any manner whatsoever;
                          or (h) acquiring any customers or promote any messages
                          through unethical practices.{" "}
                        </div>
                      </div>
                      <div className="over_hid pad_b10">
                        <div className="fleft width7">
                          {" "}
                          <strong>4.</strong>{" "}
                        </div>
                        <div className="fleft width93">
                          {" "}
                          <strong>DEFINITIONS</strong>{" "}
                        </div>
                      </div>
                      <div className="termtable">
                        <table
                          width="100%"
                          border="0"
                          cellspacing="0"
                          cellpadding="0"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <strong>
                                  "Applicable"
                                  <br />
                                  Law”
                                </strong>
                              </td>
                              <td>
                                means all laws, legislation, regulations,
                                binding codes of practice, or rules or
                                requirements of any relevant government or
                                governmental agency applicable to the Services;
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong> "CAF" </strong>
                              </td>
                              <td>
                                means VIL’s standard format of the CAF, as
                                amended from time to time, contains regulatory
                                conditions and / or instructions / or guidelines
                                issued / mandated upon the Licensee under the
                                Telecom Access Service License issued by
                                Government of India{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong> "Services" </strong>
                              </td>
                              <td>
                                {" "}
                                means Session Initiation Protocol (“SIP”) is a
                                communications protocol for signalling and
                                controlling multimedia communication sessions.{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>
                                  "VIL Group"
                                  <br />
                                  Company”{" "}
                                </strong>
                              </td>
                              <td>
                                {" "}
                                mean a company that is directly or indirectly
                                under the management control of Vodafone Idea
                                Limited or entity provisioning of the Services;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="grybg">
                        <div className="over_hid">
                          <div className="fleft width7">
                            {" "}
                            <strong>5.</strong>{" "}
                          </div>
                          <div className="fleft width93">
                            {" "}
                            <strong>OTHER TERMS AND CONDITIONS</strong>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 1 </div>
                        <div className="fleft width93">
                          {" "}
                          Either Party may terminate the Services on: - (a) on
                          formal written notice and without liability, if other
                          party becomes subject to (i) an Insolvency Event; or
                          (ii) Force Majeure Event for (45) consecutive days; or
                          (iii) convenience with prior (20) Business Days
                          written notice, except if the termination is effective
                          prior to Lock in Period then Exit Charge and any other
                          sums will be payable; or (iv) commits a material
                          breach, and failure to remedy such breach within 20
                          Business Days’; or (ii) commits a series of breaches
                          which when taken together amount to a material breach.
                          Material breach also shall construe: (i) Customer’s
                          non-payment of an undisputed amount due beyond the
                          Payment Period; (ii) any violation by the Customer or
                          End User of Applicable Law; or (iii) any Service
                          misuse contrary to telecom licence.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7">2 </div>
                        <div className="fleft width93">
                          {" "}
                          If Services are terminated and there are unfulfilled
                          or accrued obligations including payment of fees,
                          expenses, indemnification and confidentiality then
                          Parties shall be bound by those obligations and
                          perform accordingly.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 3 </div>
                        <div className="fleft width93">
                          Save as stated otherwise, the maximum aggregate
                          liability (whether in contract or in tort) of each
                          Party in each 12 month period (the first such period
                          commencing and ending at the close of business before
                          the first anniversary of the Service commencement)
                          shall be limited to a sum equal to the value of total
                          charges paid (excluding taxes and duties) for Services
                          herein, during that period
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 4 </div>
                        <div className="fleft width93">
                          {" "}
                          Save as stated otherwise, the maximum aggregate
                          liability (whether in contract or in tort) of each
                          Party in each 12 month period (the first such period
                          commencing and ending at the close of business before
                          the first anniversary of the Service commencement)
                          shall be limited to a sum equal to the value of total
                          charges paid (excluding taxes and duties) for Services
                          herein, during that period
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 5 </div>
                        <div className="fleft width93">
                          Nothing in this GTC, excludes or limits liability in
                          relation to: (i) any loss, damage, costs, and other
                          liabilities (“Actions”) recoverable under Clause 5.13
                          & 5.14{" "}
                        </div>
                      </div>
                    </div>
                    <div className="flright width49">
                      <div className="over_hid">
                        <div className="fleft width7"> &nbsp; </div>
                        <div className="fleft width93">
                          (Indemnification); (ii) Customer’s liability for
                          repudiatory breach, wilful default and wilful
                          abandonment; (iii) Customer’s obligation to pay all
                          Charges for the provision of Services; (iv) either
                          Party’s liability for death or personal injury
                          resulting from its negligence; (iv) either Party’s
                          liability for fraud or fraudulent misrepresentation;
                          (v) either Party’s liability which cannot be excluded
                          by law; or (viii) the use of either Party’s
                          Intellectual Property Rights, including any patents,
                          designs, trademarks, and business names (including
                          goodwill associated), copyright and related rights or
                          forms of protection in any part of the world; by the
                          other Party in breach of this GTC.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 6 </div>
                        <div className="fleft width93">
                          Parties do hereby consent that all confidential
                          information or documentation disclosed between them
                          relating to business which is of a technical nature or
                          involves research and development, that is rightfully
                          obtained, will be kept under strict confidentiality
                          and the recipient party shall not be permitted to
                          reproduce any copies or ownership of the same. The
                          obligation of confidentiality shall be valid for 3
                          years post termination or expiry of term.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 7 </div>
                        <div className="fleft width93">
                          {" "}
                          Each Party warrants that: (a) they are in possession
                          of and shall maintain all necessary consents,
                          approvals, permissions and licenses that are required
                          for their performance obligations; and (b) each Party
                          shall inform and cooperate as may be required for
                          exchange relevant and necessary data and work out
                          measures to prevent or eliminate fraud, misuse or
                          damage and also in defending any claim threatened or
                          brought by any Governmental Authorities or third party
                          in relation to the matters arising out of this GTC.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 8 </div>
                        <div className="fleft width93">
                          VIL makes no warranties or representations, express or
                          implied, either in fact or by operation of law,
                          statutory or otherwise, including warranties or terms
                          of merchantability, satisfactory quality, fitness for
                          purpose, title or non-infringement.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 9 </div>
                        <div className="fleft width93">
                          Customer shall as a result of availing Services: (i)
                          not knowingly or intentionally introduce software
                          virus into VIL’s network; and (ii) take precautions to
                          ensure that no virus is introduced; (iii) immediately
                          report, if it finds a virus, and provide all
                          information reasonably requested; and (iv) where a
                          virus is detected, promptly take all steps necessary
                          as agreed by VIL to support VIL in eliminating the
                          Virus. If Customer breaches the obligations in this
                          clause it shall indemnify VIL against any Actions
                          arising as a result of the breach.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 10 </div>
                        <div className="fleft width93">
                          Customer shall co-operate or provide assistance and
                          share correct, accurate and complete details to enable
                          VIL to resolve any technical or system related issues
                          pertaining to the Services, including by: (i)
                          providing logs from its system to permit VIL to debug
                          any technical issues; and (ii) engaging its equipment
                          vendors on a need basis for joint trouble shooting.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 11 </div>
                        <div className="fleft width93">
                          Customer shall co-operate or provide assistance and
                          share correct, accurate and complete details to enable
                          VIL to resolve any technical or system related issues
                          pertaining to the Services, including by: (i)
                          providing logs from its system to permit VIL to debug
                          any technical issues; and (ii) engaging its equipment
                          vendors on a need basis for joint trouble shooting.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 12 </div>
                        <div className="fleft width93">
                          Customer agrees to compensate for: (i) damage caused
                          to VIL equipment in the event of any negligence or
                          man-handling; or (ii) site visits made to resolve or
                          isolate an issue pertaining to the Customer network.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 13 </div>
                        <div className="fleft width93">
                          Customer shall indemnify and keep indemnified VIL
                          against all claims, losses, demand, proceeding or
                          damages or other liability from a third party claim,
                          arising under or in connection with (i) any breach of
                          the provisions on compliance with Applicable Law; or
                          (ii) use of the Services for any purpose other than as
                          specified in the applicable CAF; (iii) any breach of
                          its confidentiality obligations; or (iv) in relation
                          to negligence, fraud, wilful misconduct, breach of
                          statutory duty.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7">14 </div>
                        <div className="fleft width93">
                          Customer shall indemnify and keep indemnified VIL (or
                          its officers, employees, affiliates and their agents)
                          against all Actions arising under or in connection
                          with: (i) Customer’s or User’s: (a) use of the
                          Services for any purpose other than as specified in
                          the applicable CAF; or (b) transmission of illegal or
                          unlawful content; or (c) infringement of privacy and
                          data protection laws; (ii) fines/penalties imposed on
                          VIL by Governmental Authorities resulting from
                          Customer’s/User’s acts or omissions; (iii) any breach
                          of the provisions on compliance with Applicable Law;.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 15 </div>
                        <div className="fleft width93">
                          Each Party acknowledges that legally binding demands
                          from a law enforcement authority for the disclosure
                          of, or other assistance in respect of, Services or
                          Personal Data (including any information identifying,
                          directly or indirectly, the Customer, Users or
                          employees), or be required by law, court order,
                          warrant, subpoena, or other legal judicial process to
                          disclose any data in this regard and in such
                          circumstances it will not be construed as breach for
                          complying with such obligations to the extent legally
                          bound. Customer shall bear all risk of loss or damage
                          to any part of the VIL network infrastructure at times
                          when they are under Customer’s control.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 16 </div>
                        <div className="fleft width93">
                          Formal written notices in English are given at the
                          addresses for Service
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 17 </div>
                        <div className="fleft width93">
                          Neither Party shall be responsible for failure due to
                          an impeding ‘force majeure event’, provided that the
                          subjected Party: (i) has promptly notified of the
                          circumstances which may result in failure to perform
                          its obligations; (ii) could not have avoided the
                          effect of the force majeure event by taking
                          precautions.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 18 </div>
                        <div className="fleft width93">
                          This GTC shall be subject to laws of India, and courts
                          of jurisdiction shall be of New Delhi, over any
                          dispute, controversy or claim (including any question
                          regarding its existence, validity or termination)
                          arising herein.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 19 </div>
                        <div className="fleft width93">
                          Neither Party shall without prior written consent of
                          other, not assign, novate, subcontract or otherwise
                          transfer any of its rights or obligations, save that
                          VIL may assign, novate, subcontract or otherwise
                          transfer any of its rights or obligations herein to a
                          VIL Group Company and also subcontract the
                          provisioning of part of the Services{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 20 </div>
                        <div className="fleft width93">
                          Customer shall raise order for Service by submitting
                          an order form, subject to satisfaction of the
                          verification process as identified in CAF.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7">21 </div>
                        <div className="fleft width93">
                          All sums payable by Customer shall be without any
                          deductions, withholdings and without any right of set
                          off or counter claim, unless agreed otherwise.
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 22 </div>
                        <div className="fleft width93">
                          Customer agree that the Services, detailed as annexed
                          to the GTC/CAF, are acceptable and further agreed to
                          pay the amount set out in the Tariff Enrolment Form in
                          accordance with out payment conditions stated therein.{" "}
                        </div>
                      </div>
                      <div className="over_hid">
                        <div className="fleft width7"> 23 </div>
                        <div className="fleft width93">
                          Any Purchase order or other document issued by
                          Customer is for administrative convenience only. In
                          event of any inconsistency, the terms of this GTC
                          shall prevail.
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width33">
                      Private and Confidential – Vodafone C2{" "}
                    </div>
                    <div className="fleft width33 textcenter">
                      Vodafone SIP Central Services{" "}
                    </div>
                    <div className="fleft width33 text_r">
                      © Vodafone India Limited
                    </div>
                  </div>
                </div>
              </div>
            </page>
          </div>
        </div>
      );
    }
    return <div>{PriSipCafContentView}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PriSipCafForm);
