import React, { Component } from 'react'
import Dropzone from "react-dropzone";
import { ProgressBar } from "react-bootstrap";
export default class DropzoneFiles extends Component {
    render() {
        return (
            <div>
						<Dropzone
							disabled={this.props.disabled}
							className="upload_dropzone_fields"
							accept="image/jpeg, image/png"
							onDrop={
								this.props.disabled
									? null
									: (accepted, rejected) =>
											this.props.handleFileUpload(accepted, rejected)
							}
						>
							<div className="uploaded_files" style={{ width: "100%" }}>
								{this.props.accepted.map((filedata) => {
									return (
										<div key={filedata.name} className="thumbnail_dropzone">
											<img
												src={
													filedata.type === "application/pdf"
														? require("../../../../assets/images/Pdf_thumb.png")
														: filedata.preview
												}
												alt={filedata.name}
											/>
										</div>
									);
								})}
							</div>

							{this.props.accepted.length === 0 && !this.props.disabled ? (
								<p className="upload_dropzone_title">
									Try dropping some files here, or click to select files to
									upload.
								</p>
							) : null}
						</Dropzone>
						{this.props.isProgress > 0 ? (
							<ProgressBar
								style={{ width: "100%" }}
								striped
								bsStyle="success"
								now={this.props.isProgress}
							/>
						) : null}
            </div>
        )
    }
}
