// import panGSTFields from "./config/pan_gst_validation_config";

const textFieldValidation = (rules, fieldValue) => {
  const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9 ]*$/;
  const ALPHA_NUMERIC_WITH_SPECIAL_CHAR_REGEX = /^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$/;
  const ASCII_ONLY_REGEX = /^[\x00-\x7F]+$/;
  let isValid = false;
  let errText = "";
  if (!rules.flag_input_setting) {
    return { isValid, errText };
  }
  if (rules.flag_input_setting === 2) {
    ///Only Alphanumeric with no special characters
    if (ALPHA_NUMERIC_REGEX.test(fieldValue)) {
      isValid = true;
    } else {
      isValid = false;
      errText =
        "Invalid input only alphanumeric with no special characters allowed";
      // break;
    }
  } else if (rules.flag_input_setting === 3) {
    /// Alphanumeric with special characters
    if (ALPHA_NUMERIC_WITH_SPECIAL_CHAR_REGEX.test(fieldValue)) {
      isValid = true;
    } else {
      isValid = false;
      errText = "Invalid input only alphanumeric with special chars Allowed";
      // break;
    }
  } else if (rules.flag_input_setting === 4) {
    ///ASCII Only
    if (ASCII_ONLY_REGEX.test(fieldValue)) {
      isValid = true;
    } else {
      isValid = false;
      errText = "Invalid input only ASCII chars allowed";
      // break;
    }
  }

  if (rules.text_max_length > 0 && rules.text_max_length < fieldValue.length) {
    isValid = false;
    errText = `Min char length should be ${rules.text_max_length}`;
  }

  return { isValid, errText };
};

const validateFormFields = (
  fieldTypeId,
  fieldValue,
  fieldCatType,
  isRequired,
  fieldId,
  panNumber = null,
  rules = {}
) => {
  let isValid = false;
  let errText = "";
  const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //const phone_regex = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
  const phone_regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const pan_no_regex = /^([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1})$/;
  const gst_regex = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})$/;
  // if (fieldValue.length) {
  switch (fieldTypeId) {
    case 23: //Phone Number
      if (isRequired) {
        if (fieldValue.match(phone_regex)) {
          isValid = true;
        }
      } else {
        isValid = true;
      }
      break;
    case 5: //Phone Number
      if (isRequired) {
        if (fieldCatType === 10) {
          if (fieldValue.match(phone_regex)) {
            isValid = true;
          }
        } else {
          isValid = true;
        }
      } else {
        isValid = true;
      }
      break;
    case 22: //Email
      if (isRequired) {
        if (fieldValue.match(email_regex)) {
          isValid = true;
        }
      } else {
        isValid = true;
      }
      break;
    case 65: //Business Card
      if (isRequired) {
        if (
          (!fieldValue.contact_email ||
            fieldValue.contact_email.match(email_regex)) &&
          (!fieldValue.contact_phone ||
            fieldValue.contact_phone.match(phone_regex)) &&
          (!fieldValue.contact_alternative_phone ||
            fieldValue.contact_alternative_phone.match(phone_regex))
          // && (!fieldValue.contact_landline || fieldValue.contact_landline.match(phone_regex))
          // && (!fieldValue.contact_fax || fieldValue.contact_fax.match(phone_regex))
        ) {
          isValid = true;
        }
      } else {
        isValid = true;
      }
      break;
    case 71: //Product Selection
      if (isRequired) {
        if (
          !!fieldValue.product_variant &&
          fieldValue.product_variant.length > 0
        ) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = true;
      }
      break;
    case 74: // LOV data type.
      if (isRequired) {
        if (
          !!fieldValue.city_id &&
          !!fieldValue.city_name &&
          !!fieldValue.pincode_id &&
          !!fieldValue.pincode_name &&
          !!fieldValue.state_id &&
          !!fieldValue.state_name
        ) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = true;
      }
      break;
    // case 19: // Short Text for PAN and GST no. Validations
    //   if ((!!isRequired || !!fieldValue) && rules && rules.flag_input_setting) {
    //     let validition_Result = textFieldValidation(rules, fieldValue);
    //     if (!validition_Result.isValid) {
    //       isValid = validition_Result.isValid;
    //       errText = validition_Result.errText;
    //       break;
    //     } else {
    //       isValid = validition_Result.isValid;
    //     }
    //   }
      // // PAN Number Validation
      // if (
      //   panGSTFields.panFields.some(
      //     (field) => field.field_id === Number(fieldId)
      //   )
      // ) {
      //   if (isRequired || fieldValue !== "") {
      //     if (pan_no_regex.test(fieldValue.trim())) {
      //       isValid = true;
      //     } else {
      //       isValid = false;
      //       errText = "Invalid PAN number";
      //     }
      //   } else {
      //     isValid = true;
      //   }
      // }
      // // GST Number Validation
      // else if (
      //   panGSTFields.gstFields.some(
      //     (field) => field.field_id === Number(fieldId)
      //   ) &&
      //   (isRequired || fieldValue !== "")
      // ) {
      //   let inValidGst = [];
      //   fieldValue.split(",").map((gstvalue) => {
      //     let gstTrim = gstvalue.trim();
      //     if (!gst_regex.test(gstTrim)) {
      //       inValidGst.push(gstTrim);
      //     } else if (panNumber && !gstTrim.includes(panNumber)) {
      //       inValidGst.push(gstTrim);
      //     }
      //   });
      //   if (inValidGst.length > 0) {
      //     isValid = false;
      //     errText = `${inValidGst.join(",")} are invalid GST numbers`;
      //   } else {
      //     isValid = true;
      //   }
      // } else {
      //   console.log(rules, fieldValue);
      //   if (
      //     rules &&
      //     rules.text_max_length > 0 &&
      //     rules.text_max_length < fieldValue.length
      //   ) {
      //     isValid = false;
      //     errText = `Min char length should be ${rules.text_max_length}`;
      //   } else {
      //     isValid = true;
      //   }
      // }
      // break;
    case 20: //Long Text
      if ((!!isRequired || !!fieldValue) && rules && rules.flag_input_setting) {
        let validition_Result = textFieldValidation(rules, fieldValue);
        if (!validition_Result.isValid) {
          isValid = validition_Result.isValid;
          errText = validition_Result.errText;
          break;
        } else {
          isValid = validition_Result.isValid;
        }
      }
      if (isRequired) {
        fieldValue = fieldValue.replace(/\n+/g, " ");
        if (fieldValue.trim() !== "") {
          if (
            rules &&
            rules.text_max_length > 0 &&
            rules.text_max_length < fieldValue.length
          ) {
            isValid = false;
            errText = `Min char length should be ${rules.text_max_length}`;
          } else {
            isValid = true;
          }
        }
      } else {
        if (
          rules &&
          rules.text_max_length > 0 &&
          rules.text_max_length < fieldValue.length
        ) {
          isValid = false;
          errText = `Min char length should be ${rules.text_max_length}`;
        } else {
          isValid = true;
        }
      }
      break;
    default:
      isValid = true;
  }
  // }
  return { isValid: isValid, errText: errText };
};

export default validateFormFields;
