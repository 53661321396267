import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import "../../VodafoneIdea.css";
import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
import CloudImg from "../../../../../assets/images/Process/VodafoneIdea/cloud.jpg";
import lotImg from "../../../../../assets/images/Process/VodafoneIdea/lot.jpg";
import WireImg from "../../../../../assets/images/Process/VodafoneIdea/wire.jpg";
import WirelessImg from "../../../../../assets/images/Process/VodafoneIdea/wireless.jpg";
import ReactToPrint from "react-to-print";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import AxiosWeb from "../../../../../axios_ext";
import "./NPLCCrfForm.css";
import { JsonToTable } from "react-json-to-table";
import Axios from "axios";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class NPLCCrfForm extends Component {
  state = {
    nplcCrfData: false,
    stopdidmount: 0,
    nplcCrfDataUpdated: null,
    authorizedSignName: "",
    companySeal: "",
    imageData: "",
    imageType: "",
    acctMngSignImageData: "",
    acctMngSignImageType: "",
    excelJson: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };

  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1330,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    console.log("REQUEST DATA : ", requestData);
    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 11751) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 11750) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("fieldObj:: " + fieldObj.field_id);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.custCompanySeal);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 1234; // ILL CRF form Id
    let orderform = 1266;
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        nplcCrfData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 11748
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+submittedFormArry);
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );

        this.setState({
          ...this.state,
          nplcCrfData: true,
        });
      }
      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 11748
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("10092") &&
      this.state.stopdidmount === 0
    ) {
      //console.log('submitted forms list',this.props.fieldList);
      // console.log('this.props.fieldList : ', this.props.fieldList)
      let x = this.props.fieldList;
      console.log("********************");
      console.log("Company Seal URL : ", this.state.custCompanySeal);
      console.log("Account Manager Signature URL : ", x[10264].value);
      console.log("********************");

      let companySealUrl = "";
      let BucketName = "";
      let KeyName = "";
      let temp = "";

      if (
        this.state.custCompanySeal != null &&
        this.state.custCompanySeal != ""
      ) {
        companySealUrl = this.state.custCompanySeal || "";
        //BucketName = this.state.custCompanySeal.slice(8, 25);
        //KeyName = this.state.custCompanySeal.slice(43);
        [BucketName, KeyName, temp] = await utils.getBucketKeyFileName(
          this.state.custCompanySeal
        );
      }
      console.log("companySealUrl : ", companySealUrl);

      if (companySealUrl !== "") {
        this.s3.getObject(
          {
            Bucket: BucketName,
            Key: KeyName,
          },
          async (err, data) => {
            if (err) {
              console.log("error 1", err);

              this.setState({
                ...this.state,
                nplcCrfDataUpdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 1 ", data);
              console.log("data is 1 ", data.Body);

              let AcctManagerSignUrl = x[10264].value;
              //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
              //const KeyName1 = AcctManagerSignUrl.slice(43);
              let BucketName1, KeyName1;
              [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
                AcctManagerSignUrl
              );

              this.s3.getObject(
                {
                  Bucket: BucketName1,
                  Key: KeyName1,
                },
                (err, data1) => {
                  if (err) {
                    console.log("error 2", err);

                    this.setState({
                      ...this.state,
                      nplcCrfDataUpdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      stopdidmount: 1,
                    });
                  } else {
                    console.log("data is 2 ", data1);
                    console.log("data is 2 ", data1.Body);

                    this.setState({
                      ...this.state,
                      nplcCrfDataUpdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      acctMngSignImageData: data1.Body,
                      acctMngSignImageType: data1.ContentType,
                      stopdidmount: 1,
                    });
                  }
                }
              );
            }
          }
        );
      } else {
        let AcctManagerSignUrl = x[10264].value;
        //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
        //const KeyName1 = AcctManagerSignUrl.slice(43);

        let temp = "";
        let BucketName1, KeyName1;
        [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
          AcctManagerSignUrl
        );

        this.s3.getObject(
          {
            Bucket: BucketName1,
            Key: KeyName1,
          },
          (err, data1) => {
            if (err) {
              console.log("error 2", err);

              this.setState({
                ...this.state,
                nplcCrfDataUpdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 2 ", data1);
              console.log("data is 2 ", data1.Body);

              this.setState({
                ...this.state,
                nplcCrfDataUpdated: this.props.fieldList,
                acctMngSignImageData: data1.Body,
                acctMngSignImageType: data1.ContentType,
                stopdidmount: 1,
              });
            }
          }
        );
      }
    }
  }

  render() {
    console.log("nplcCrfDataUpdated :: " + this.state.nplcCrfDataUpdated);

    let NplcCrfContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );
    if (
      this.state.nplcCrfDataUpdated != null &&
      this.state.nplcCrfDataUpdated !== "" &&
      this.state.stopdidmount == 1 &&
      this.props.formFullLoaded === 1
    ) {
      let x = this.state.nplcCrfDataUpdated;
      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }

      let firstCaps = "";
      let secondBold = "";
      let Signcss;
      let Overlapcss = "signate_nplccrf_in float_img";
      console.log(
        "this.state.authorizedSignName :: " + this.state.authorizedSignName
      );

      if (
        this.state.authorizedSignName != "" &&
        this.state.authorizedSignName != null
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        console.log(firstCaps, "console.log(firstCaps);");
        if (this.props.external && this.props.external === true) {
          Overlapcss = "signate_nplccrf_ext float_img";
        }
      }

      NplcCrfContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ padding: "20px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsStyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsStyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <div className="pad_20t">
                    <div className="over_hid">
                      <div className="pd_t5">
                        {" "}
                        <span className="textcenter width70 fleft font15 mplhed">
                          NPLC Change Request Form
                        </span>
                        <div className="caf">
                          CAF ID
                          <input
                            className="form_col"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10092].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="over_hid mar_t-5">
                      <div className="pd_t5">
                        <strong>Company(Customer) Name</strong>{" "}
                        <span className="pad_l10">
                          <input
                            className="form_col_input"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10093].value}
                            readOnly
                          />
                        </span>
                        <div className="caf_inner">
                          <strong>Account Code</strong>
                          <input
                            className="form_col no_top"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10094].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <p className="sign font12">
                      <strong>Authorised Signatory Details</strong>
                    </p>
                  </div>
                  <div className="over_hid auth">
                    <div className="fleft width45">
                      Name
                      <input
                        className="form_col width85"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10096].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Contact No:
                      <input
                        className="form_col width59"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10097].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Email:
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10098].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width45 textcenter">
                      {" "}
                      Feasibility ID (FR ID){" "}
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10099].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_5t">
                    <div className="fleft width45 textcenter">
                      {" "}
                      Please mention circuit id.
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10100].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_5t">
                    <div className="fleft width45 textcenter">
                      Please mention VOX ID
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10101].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="margin0 font15">TYPE OF CHANGE REQUEST ORDER</p>
                  <div className="over_hid pad_t15">
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          1 Company Name Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Company Name Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          2 Customer contact Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Customer contact Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          3 Billing Address change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Billing Address change") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          4 Bandwidth Upgrade -same interface{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Bandwidth Upgrade -same interface") >=
                          0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          5 Bandwidth Upgrade with interface change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf(
                              "Bandwidth Upgrade with interface change"
                            ) >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          6 Bandwidth Downgrade- Same Interface{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Bandwidth Downgrade- Same Interface") >=
                          0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          7 Bandwidth Downgrade- Interface change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Bandwidth Downgrade- Interface change") >=
                          0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          8 Interface Change
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Interface Change") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          9 Interface Change- Media Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Interface Change- Media Change") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          10 Interface Change- LM provider change
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Interface Change- LM provider change") >=
                          0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          11 Change in CPE- Add/ Remove{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Change in CPE- Add/ Remove") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          12 Site Shifting
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Site Shifting") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          13 Billing Periodicity Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Billing Periodicity Change") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          14 Subscribe Managed Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Subscribe Managed Services") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          15 Unsubscribe Managed Services
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Unsubscribe Managed Services") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          16 Change of Managed Services Variant{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Change of Managed Services Variant") >=
                          0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          17 Change in SLA Type{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Change in SLA Type") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          18 Change in Self care service variant{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Change in Self care service variant") >=
                          0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          19 Revision in charges{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Revision in charges") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          20 Other Changes{" "}
                        </div>
                        <div className="fleft">
                          {this.state.nplcCrfDataUpdated[10102].value
                            .split("|")
                            .indexOf("Other Changes") >= 0 ? (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col check15"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Company Name Change
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10104].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10105].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Customer Contact Details Change
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing telephone Number
                    </div>
                    <div className="fleft width49">
                      {" "}
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10107].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New telephone Number
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10108].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing email address
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10109].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New email address
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10110].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Billing Address Change
                  </p>
                  <p className="font12 margin0"> New Billing Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10113].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10114].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10115].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10116].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10117].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10118].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col width90"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10119].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10120].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Site Shifting Installation Address Change
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft font12 wid150p pad_t3">
                      Old Installation Address
                    </div>
                    <div className="fleft width78 pad_l10">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10122].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="font12"> New Installation Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10124].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10125].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10126].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10127].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10128].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10129].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col width90"
                            type="text"
                            name=""
                            value={this.state.nplcCrfDataUpdated[10130].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.nplcCrfDataUpdated[10131].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Bandwidth Upgrade - Same Interface
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width30 ">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10133].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10134].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Bandwidth Downgrade - Same Interface
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10136].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10137].value}
                          readOnly
                        />
                        Kbps / Mbps
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Bandwidth Upgrade with Interface / LM / Media Change
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10139].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10140].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10141].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10141].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10141].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10141].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10141].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10141].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10142].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10142].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10142].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10142].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10142].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10142].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">Existing Media</span>
                        {this.state.nplcCrfDataUpdated[10143].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10143].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10143].value ===
                        "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.nplcCrfDataUpdated[10143].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">New Media</span>
                        {this.state.nplcCrfDataUpdated[10144].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10144].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10144].value ===
                        "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.nplcCrfDataUpdated[10144].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10146].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10147].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Bandwidth Downgrade with Interface / LM / Media Change
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        {" "}
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10149].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width130px pad_t3">
                        {" "}
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10150].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className={Overlapcss}>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10151].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10151].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10151].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10151].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10151].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10151].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10152].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10152].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10152].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10152].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10152].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10152].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">Existing Media</span>
                        {this.state.nplcCrfDataUpdated[10153].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10153].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10153].value ===
                        "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.nplcCrfDataUpdated[10153].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">New Media</span>
                        {this.state.nplcCrfDataUpdated[10154].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10154].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10154].value ===
                        "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.nplcCrfDataUpdated[10154].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10156].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10157].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </page>

            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Interface Change{" "}
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10159].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10159].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10159].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10159].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10159].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10159].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10160].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10160].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10160].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10160].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10160].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10160].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Interface Change with Media / LM Change
                  </p>
                  <div className="pad_t10">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10162].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10162].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10162].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10162].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10162].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10162].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10162].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10163].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10163].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10163].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10163].value ===
                        "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10163].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10163].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.nplcCrfDataUpdated[10163].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">Existing Media</span>
                        {this.state.nplcCrfDataUpdated[10164].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10164].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10164].value ===
                        "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.nplcCrfDataUpdated[10164].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">New Media</span>
                        {this.state.nplcCrfDataUpdated[10165].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10165].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10165].value ===
                        "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.nplcCrfDataUpdated[10165].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10167].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10168].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Addition of CPE
                  </p>
                  <div className="domestictable">
                    <div className="center_70per">
                      {" "}
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>Sr. No.</td>
                            <td>Type (Standard/Other)</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Version</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10171].value}
                            </td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10172].value}
                            </td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10173].value}
                            </td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10174].value}
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>{" "}
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Deletion of CPE
                  </p>
                  <div className="domestictable">
                    <div className="center_70per">
                      {" "}
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>Sr. No.</td>
                            <td>Type (Standard/Other)</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Version</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10176].value}
                            </td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10177].value}
                            </td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10178].value}
                            </td>
                            <td>
                              {this.state.nplcCrfDataUpdated[10179].value}
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>{" "}
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Billing Periodicity Change
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        Existing Billing Periodicity{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10181].value ===
                        "Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10181].value ===
                        "Bi-Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Bi-Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10181].value ===
                        "Quarterly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Quarterly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10181].value ===
                        "Half Yearly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Half Yearly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10181].value ===
                        "Annualy" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Annualy
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        Revised Billing Periodicity{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10182].value ===
                        "Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10182].value ===
                        "Bi-Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Bi-Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10182].value ===
                        "Quarterly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Quarterly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10182].value ===
                        "Half Yearly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Half Yearly
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10182].value ===
                        "Annualy" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Annualy
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Managed Services
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        Managed services required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10184].value ===
                        "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20 text_r pad_r5">
                        {" "}
                        Managed Service Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10185].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10185].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>

                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10185].value ===
                        "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>

                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10185].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Managed Services
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        Managed services required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10187].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change of Managed Services Variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        Existing Managed Service Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10189].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10189].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>

                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10189].value ===
                        "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>

                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10189].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        New Managed Service Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10190].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10190].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>

                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10190].value ===
                        "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>

                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10190].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>

            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in SLA Type
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width15 pad_t3">
                        {" "}
                        Existing SLA Type{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10192].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10192].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                      <div className="fleft width16 pad_5t"> New SLA Type</div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10193].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft width16">
                        {this.state.nplcCrfDataUpdated[10193].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_7t textcenter  pad_r5">
                        If SLA Type Customized{" "}
                      </div>
                      <div className="fleft width16 pad_r20">
                        Service Availability (%)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10195].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        MTTR (Hours)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10196].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        Latency (ms)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10197].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in Self care service variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        Existing Self Care Service Variant{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10199].value ===
                        "None" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        None
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10199].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10199].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10199].value ===
                        "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10199].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        New Self Care Service Variant{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10200].value ===
                        "None" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        None
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10200].value ===
                        "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10200].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10200].value ===
                        "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.nplcCrfDataUpdated[10200].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Revision in Charges
                  </p>
                  <p>
                    (Only do required changes. Leave others blank if no change)
                  </p>
                  <div className="char">
                    <div className="check over_hid pad_t5">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Specify Currency{" "}
                      </div>
                      <div className="fleft width7">
                        {this.state.nplcCrfDataUpdated[10203].value ===
                        "INR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        INR{" "}
                      </div>
                      <div className="fleft width7">
                        {this.state.nplcCrfDataUpdated[10203].value ===
                        "US$" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        US${" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        One Time(A){" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Recurring (B){" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Security Deposit ©{" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Grand Total (A+B+C){" "}
                      </div>
                    </div>
                    <div className="check over_hid">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Service Rental
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10204].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10205].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10206].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10207].value}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        SLA Charges{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10208].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10209].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10210].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Self Care Portal Service Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10211].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10212].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10213].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed Services Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10214].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10215].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10216].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed CPE Charges{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10217].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10218].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10219].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        CPE Rentals{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10220].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10221].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10222].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10223].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 1 </div>
                      <div className="fleft" style={{ width: "9.5%" }}>
                        {" "}
                        &nbsp;
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10224].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10225].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10226].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10227].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 2 </div>
                      <div className="fleft" style={{ width: "9.5%" }}>
                        {" "}
                        &nbsp;
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10228].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10229].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10230].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10231].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 3</div>
                      <div className="fleft" style={{ width: "9.5%" }}>
                        {" "}
                        &nbsp;
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10232].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10233].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10234].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10235].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 4 </div>
                      <div className="fleft" style={{ width: "9.5%" }}>
                        {" "}
                        &nbsp;
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10236].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10237].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10238].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10239].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 5 </div>
                      <div className="fleft" style={{ width: "9.5%" }}>
                        {" "}
                        &nbsp;
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10240].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10241].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10242].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10243].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Miscellaneous Charges - 1{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10244].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10245].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">&nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10246].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Miscellaneous Charges - 2{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10247].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10248].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10249].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Shifting Charges{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10250].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10251].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        CPE Replacement Charges
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10252].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10253].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p>
                    <strong>** Taxes as applicable.</strong>
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  ...
                  <br />
                  <br />
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>

            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    COMMENTS
                  </p>
                  <div className="cmt_spl">
                    {" "}
                    Comments/ Special Considerations:{" "}
                    {this.state.nplcCrfDataUpdated[10255].value}
                  </div>
                  <div className="over_hid">
                    <div className="fleft width65 pad_t10">
                      <p className="margin0">
                        <strong>Declaration</strong>
                      </p>
                      <p className="margin0">
                        I / We confirm having received, read and understood the
                        Product Terms & Conditions (provided overleaf) and the
                        General Terms & Conditions. I / We further confirm that
                        the tariff plan selected and applicable rates form part
                        of this Agreement (as defined herein) and I / We agree
                        to abide by the Applicable Law in force and also any
                        statutory amendments, or new legistlations as may be
                        enacted from time to time, in so far as they realte to
                        the services. I / We hereby declare and confirm that the
                        above information provided by us is true and correct in
                        all respects and I / We hereby undertake to be bound by
                        the same.{" "}
                      </p>
                      <p className="margin0 pad_5t">
                        Authorised Signatory's Name
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10096].value}
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_t15">
                        <div className="fleft width30">
                          <p className="margin0">Designation</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.nplcCrfDataUpdated[10258].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Place</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.nplcCrfDataUpdated[10259].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Date</p>
                          <p className="margin0">
                            <input
                              className="form_col width96per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.nplcCrfDataUpdated[10260].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                      <p className="margin0 pad_5t">Account Manager Name</p>
                      <div className="signate float_img">
                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <div
                            style={{ padding: "10px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10262].value}
                          readOnly
                        />
                      </p>
                      <p className="margin0 pad_20t">
                        Account Manager Circle Office
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col  wid120p cmnt_input"
                          type="text"
                          name=""
                          value={this.state.nplcCrfDataUpdated[10263].value}
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_20t">
                        <div className="fleft width50">
                          <p className="margin0">Channel Partner Name</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.nplcCrfDataUpdated[10265].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="flright width30">
                          <p className="margin0">Channel Partner Code</p>
                          <p className="margin0">
                            <input
                              className="form_col width92per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.nplcCrfDataUpdated[10266].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flright width30">
                      <div style={{ padding: "5px" }} className="signate">
                        {firstCaps != "" ? (
                          <div className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          Signature of the Customer / Authorised signatory with
                          in this box only (In case of institutinal, please
                          affix office/company seal){" "}
                        </div>
                      </div>
                      <div
                        style={{ padding: "5px", height: "120px" }}
                        className="signate"
                      >
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            height="90px"
                            width="100px"
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Signature of Account Manager"}
                          />
                        ) : (
                          ""
                        )}
                        <div> Signature of the Account Manager </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter">
                    <strong>
                      Vodafone Idea Limited (formerly Idea Cellular Limited) An
                      Aditya Birla Group and Vodafone Partnership Merger Co CIN
                      No. L32100GJ1996PLC030976Registered Office: Suman Tower,
                      Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
                    </strong>
                  </p>
                </div>
              </div>
            </page>
            {this.state.excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">Annexure to NPLC CRF :- </p>
                  </div>
                  <JsonToTable json={this.state.excelJson} />
                  <div>
                    <p class="font10 " style={{ marginTop: "20px" }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div class="fleft width50 pad_t10">
                    <p class="marb5">Account Manager Name</p>
                    <p class="margin0">
                      <input
                        class="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        readonly=""
                        value={this.state.nplcCrfDataUpdated[10262].value}
                      />
                    </p>
                    <div className="signate">
                      <div className="signate_img input_sign">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Account Manager Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div className="over_hid ">
                        Authorised Signatory Name
                        {
                          <input
                            className="form_col inputext"
                            type="text"
                            name="Name_CD"
                            value={this.state.nplcCrfDataUpdated[10096].value}
                            readOnly
                          />
                        }
                      </div>
                    </div>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        {
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        }
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      NplcCrfContentView = this.state.nplcCrfData ? (
        <div style={{ paddingTop: "30%", marginLeft: "45%" }}>
          <PulseLoader color={"#000000"} loading={true} />
        </div>
      ) : (
        <div
          style={{ marginTop: "200px", height: "300px", textAlign: "center" }}
        >
          {" "}
          CRF Form not submitted yet.
        </div>
      );
    }
    return <div>{NplcCrfContentView}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NPLCCrfForm);
