import moment from "moment";

///-------- caf_version-----------------

export const CAF_VERSION = {
  VI_BRANDING: "VI_BRANDING",
  OLD_BRANDING: "OLD_BRANDING",
  IDEA_BRANDING: "IDEA_BRANDING",
};
export const getBranding = (date) => {
  let new_logo_date = moment("2020-09-08");
  let branding_date = moment(date);
  if (new_logo_date.diff(branding_date) > 0) {
    return CAF_VERSION.OLD_BRANDING;
  } else {
    return CAF_VERSION.VI_BRANDING;
  }
};

export const Caf_Crf_Ids={
  133892:{
    name:'AC CAF',
    order_id:1841,
    form_id:1687
  },
  133893:{
    name:'ItFscaf',
    order_id:1842,
    form_id:1693
  },
  133894:{
    name:'ITFS CAF',
    order_id:1843,
    form_id:1700
  },
  133895:{
    name:'VLT CAF',
    order_id:1844,
    form_id:1707
  },
  133896:{
    name:'VC CAF',
    order_id:1845,
    form_id:1713
  },
  133897:{
    name:'AC CAF',
    order_id:1846,
    form_id:1719
  },
  133898:{
    name:'VSDM CAF',
    order_id:1847,
    form_id:1726
  },
  133899:{
    name:'NFL CAF',
    order_id:1753,
    form_id:1733
  }
  ,
  134562:{
    name:'MPLS CAF',
    order_id:1068,
    form_id:1058
  },
  134564:{
    name:'MPLS CRF',
    order_id:1074,
    form_id:1109
  },
  134565:{
    name:'ILL CAF',
    order_id:1368,
    form_id:1119
  },
  134566:{
    name:'ILL CRF',
    order_id:1137,
    form_id:1229
  },
  134569:{
    name:'NPLC CAF',
    order_id:1261,
    form_id:1230
  },
  134573:{
    name:'NPLC CRF',
    order_id:1266,
    form_id:1234
  },
  134574:{
    name:'IPLC CAF',
    order_id:1278,
    form_id:1337
  }
  ,
  134575:{
    name:'PRI/SIP/IP CRF',
    order_id:1346,
    form_id:1277
  }
  ,
  134576:{
    name:'PRI CAF/TEF',
    order_id:1369,
    form_id:1316
  },
  134583:{
    name:'Superwifi CAF',
    order_id:1372,
    form_id:1347
  }
  ,
  142431:{
    name:'SIP CAF',
    order_id:1577,
    form_id:1557
  },
  142432:{
    name:'IPC/Pri Centric CAF',
    order_id:1592,
    form_id:1566
  },
  145268:{
    name:'MssCafForm',
    order_id:2279,
    form_id:2184
  },
  152451:{
    name:'IPLC CRF',
    order_id:4382,
    form_id:4405
  }
}
