import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import "../../VodafoneIdea.css";
//import '../../../cafForm.css';
import ReactToPrint from "react-to-print";
import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
//import CrossSignImg from '../../../../../../assets/images/Process/VodafoneIdea/customtext.jpg';
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import Axios from "axios";
import AxiosWeb from "../../../../../axios_ext";
import { JsonToTable } from "react-json-to-table";
import ReactExport from "react-data-export";

import "./mss_caf.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class MssCafForm extends Component {
  state = {
    cafData: false,
    stopdidmount: 0,
    mssCafDataupdated: 1,
    authorizedSignName: "",
    photoGraph: "",
    companySeal: "",
    imageData: null,
    acctMngSignImageData: null,
    acctMngSignImageType: null,
    excelJson: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };

  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 2192, // Authorised Signatory Sign & Seal
      field_id: 26209, // Company Seal field id
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };
    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        //console.log('formFieldData ::'+JSON.parse(res.data.response[0].data_entity_inline).form_submitted);

        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 26209) {
            //Compare with Authorised Signatory Sign & Seal
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 26210) {
            //Compare with Self Attested Photo
            photoGraph = fieldObj.field_value;
          }

          if (fieldObj.field_id == 26208) {
            //Compare with Signatory Name
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("fieldObj:: " + fieldObj.field_id);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          custPhotoGraph: photoGraph,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.custCompanySeal);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 2184; // MSS CAF form Id
    let orderform = 2279; //Order form Id

    this.getCustomerCompanySeal();
    console.log("this.props.external :: " + this.props.external);
    if (this.props.external && this.props.external === true) {
      console.log(
        "console the entry",
        this.buildFormFieldFetchParam(this.props.formData)
      );
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        cafData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("ResJaya", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 26440
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      // console.log('submittedFormArry :: '+JSON.stringify(submittedFormArry));
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      // console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );
        this.setState({
          ...this.state,
          cafData: true,
        });
      }
      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );

      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 26440
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              console.log("params", excelParames);
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      // console.log("Json",JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // console.log("props", this.props.fieldList)
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("25924") &&
      this.state.stopdidmount === 0
    ) {
      let x = this.props.fieldList;

      if (!!this.state.custPhotoGraph) {
        utils.loadImageBlob(
          this.s3,
          this.state.custPhotoGraph,
          (data, type) => {
            this.setState({
              ...this.state,
              photoGraphImageData: data,
              photoGraphImageType: type,
              stopdidmount: 1,
              mssCafDataupdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          mssCafDataupdated: this.props.fieldList,
        });
      }

      if (!!this.state.custCompanySeal) {
        utils.loadImageBlob(
          this.s3,
          this.state.custCompanySeal,
          (data, type) => {
            this.setState({
              ...this.state,
              imageData: data,
              imageType: type,
              stopdidmount: 1,
              mssCafDataupdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          mssCafDataupdated: this.props.fieldList,
        });
      }

      if (!!x[26082] && x[26082].value) {
        // manager sign url
        let acctManagerSignUrl = x[26082].value;
        utils.loadImageBlob(this.s3, acctManagerSignUrl, (data, type) => {
          this.setState({
            ...this.state,
            acctMngSignImageData: data,
            acctMngSignImageType: type,
            stopdidmount: 1,
            mssCafDataupdated: this.props.fieldList,
          });
        });
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          mssCafDataupdated: this.props.fieldList,
        });
      }
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  render() {
    console.log(" this.state.excelJson " + this.state.excelJson);

    let mssCafContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );

    if (
      this.state.mssCafDataupdated != null &&
      this.state.mssCafDataupdated !== "" &&
      this.state.stopdidmount === 1
    ) {
      let x = Object.assign({}, this.state.mssCafDataupdated);
      // x[8831].label = x[8831].value;
      // x[8844].label = x[8844].value;
      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }

      let firstCaps = "";
      let secondBold;
      let Signcss;
      let Overlapcss = "signate float_img_mssCaf";
      if (
        this.state.authorizedSignName != "" &&
        this.state.authorizedSignName != null
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        if (this.props.external && this.props.external === true) {
          Overlapcss = "signate float_img_mssCaf_ext";
        }
        //   console.log(firstCaps,"console.log(firstCaps);");
      }

      mssCafContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ padding: "20px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsstyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsstyle="info"
              >
                Print Preview
              </a>
            )}

            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsstyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="pad_l_r10 over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="customfom redcolor">
                    {" "}
                    Customer Application Form - Managed Security Services{" "}
                  </p>
                  <div className="over_hid">
                    <div className="pd_t5">
                      <strong>Vodafone Idea Business Services</strong>{" "}
                      <span className="pad_l10">
                        Kindly fill the form in BLOCK letters
                      </span>
                      <div className="caf">
                        CAF No.:
                        <input
                          className="form_col"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[25925].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid">
                    <div className="width75 fleft">
                      <div className="pad_5t">
                        <strong>Company(Customer) Name</strong>{" "}
                        <span className="pad_l10">
                          <input
                            className="form_col_input width71"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25926].value}
                            readOnly
                          />
                        </span>
                      </div>
                      <div className="pad_5t">
                        <strong>Account Code</strong>{" "}
                        <span className="pad_l10">
                          <input
                            className="form_col_input width83"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25927].value}
                            readOnly
                          />
                        </span>
                      </div>

                      <div className="pad_20t">
                        <p className="sign">
                          <strong>Authorised Signatory Details</strong>
                        </p>
                      </div>
                      <div className="pad_t15">
                        {" "}
                        Name{" "}
                        <span className="pad_l10">
                          <input
                            className="form_col_input width91"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25929].value}
                            readOnly
                          />
                        </span>
                      </div>
                      <div className="over_hid auth pad_t10">
                        <div className="fleft width50">
                          Contact No:
                          <input
                            className="form_col width59"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25930].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          Email:
                          <input
                            className="form_col width80"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25931].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright cross_fill">
                      <div className="crossign" style={{ height: "100px" }}>
                        {this.state.photoGraphImageData != null &&
                        this.state.photoGraphImageData != "" ? (
                          <div
                            style={{
                              padding: "5px",
                              position: "relative",
                              textAlign: "center",
                            }}
                            className="signate_img"
                          >
                            <img
                              style={{ height: "90px" }}
                              src={
                                this.state.photoGraphImageData != null
                                  ? "data:" +
                                    this.state.photoGraphImageType +
                                    ";base64," +
                                    this.encode(this.state.photoGraphImageData)
                                  : ""
                              }
                              alt={"PhotoGraph with Crosssign"}
                            />
                            {firstCaps != "" ? (
                              <span
                                style={{
                                  padding: "10px",
                                  position: "absolute",
                                  left: "-10px",
                                  top: "8px",
                                }}
                                className={Signcss}
                              >
                                {firstCaps + "  .  "}
                                <span className="under_line">{secondBold}</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="self_attach">Self attested <br />
                                passport size<br />
                                color photography<br />
                                of Authorised Signatory <img src={CrossSignImg} className="custext" /> </div> */}
                    </div>
                  </div>
                  <p className="font9 margin0">
                    I/We hereby order for VIL MSS subject to terms and
                    conditions as described in the service order form. We
                    furnish the necessary details as fallow.
                  </p>
                  <div className="pad_t10">
                    <p className="ligthbg">BILLING ADDRESS</p>
                  </div>

                  <p className="auth margin0">
                    Contact Person name
                    <input
                      className="form_col width60"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25935].value}
                      readOnly
                    />
                  </p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Designation
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25936].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Dept. Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25937].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0">
                    <span className="width54px">Address</span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25938].value}
                      readOnly
                    />
                  </div>
                  <div className="auth margin0">
                    <span className="width54px"> </span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width40">
                      City/Village/Post office
                      <input
                        className="form_col width54"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25939].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      State
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25940].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Pincode
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25941].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Telephone
                      <input
                        className="form_col width58"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25942].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <div className="fleft width58">
                      Landmark
                      <input
                        className="form_col width84"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25943].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      <span className="faxn">Fax No.</span>
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25944].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <div className="fleft width58">
                      Contact Email Id
                      <input
                        className="form_col width77"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25945].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      Mobile No / Alt No.
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25946].value}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="pad_t10">
                    <p className="ligthbg">
                      SHIPPING ADDRESS (If same as the Billing address then tick
                      the box
                      {this.state.mssCafDataupdated[25947].value
                        .split("|")
                        .indexOf("SHIPPING ADDRESS") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ; if different, then fill the form below)
                    </p>
                  </div>

                  <p className="auth margin0">
                    Contact Person name
                    <input
                      className="form_col width60"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25948].value}
                      readOnly
                    />
                  </p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Designation
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25949].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Dept. Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25950].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0">
                    <span className="width54px">Address</span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25951].value}
                      readOnly
                    />
                  </div>
                  <div className="auth margin0">
                    <span className="width54px"> </span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width40">
                      City/Village/Post office
                      <input
                        className="form_col width54"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25952].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      State
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25953].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Pincode
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25954].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Telephone
                      <input
                        className="form_col width58"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25955].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <div className="fleft width58">
                      Landmark
                      <input
                        className="form_col width84"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25956].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      <span className="faxn">Fax No.</span>
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25957].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <div className="fleft width58">
                      Contact Email Id
                      <input
                        className="form_col width77"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25958].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      Mobile No / Alt No.
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25959].value}
                        readOnly
                      />
                    </div>
                  </div>

                  <p className="ligthbg">INSTALLATION ADDRESS</p>
                  <p className="auth">
                    Contact Person name
                    <input
                      className="form_col width60"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25962].value}
                      readOnly
                    />
                  </p>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width50">
                      Designation
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25963].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Dept. Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25964].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0">
                    <span className="width54px">Address</span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25965].value}
                      readOnly
                    />
                  </div>
                  <div className="auth pad_b5">
                    <span className="width54px"> </span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width40">
                      City/Village/Post office
                      <input
                        className="form_col width54"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25966].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      State
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25967].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Pincode
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25968].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Telephone
                      <input
                        className="form_col width58"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25969].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width55">
                      Landmark
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25970].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Telecom Circle
                      <input
                        className="form_col width55"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25971].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      <span className="faxn widthauto">Fax No.</span>
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25972].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid">
                    <div className="fleft width58">
                      Contact Email Id
                      <input
                        className="form_col width77"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25973].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      Mobile No / Alt No.
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25974].value}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="check margin0 over_hid">
                    <div className="fleft width15">
                      GSTIN
                      {this.state.mssCafDataupdated[25975].value === "GSTIN" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width15">
                      UIN
                      {this.state.mssCafDataupdated[25975].value === "UIN" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width15">
                      GST_ISD
                      {this.state.mssCafDataupdated[25975].value ===
                      "GST_ISD" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>

                  <div className="check over_hid typecheck">
                    <div className="fleft width12 pad_t7">Customer Type</div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25976].value ===
                      "Normal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Normal
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25976].value === "SEZ" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SEZ
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25976].value ===
                      "Diplomat" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diplomat
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25976].value ===
                      "Embassy" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Embassy
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25976].value ===
                      "Consulate" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consulate
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25976].value ===
                      "Special Agency" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Special Agency
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25976].value ===
                      "Person/Class of Persons" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Person/Class of Persons
                    </div>
                  </div>
                  <p className="auth">
                    GSTIN / UIN / GST_ISD No
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25977].value}
                      readOnly
                    />
                  </p>
                  <div className="auth">
                    <span className="width70px in_block">GST Reg. Address</span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25978].value}
                      readOnly
                    />
                  </div>
                  <div className="auth">
                    <span className="width70px in_block">
                      in installation state
                    </span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                  <div className="check over_hid typecheck gstcheck">
                    <div className="fleft width12 pad_t7">
                      GST Customer Dept
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value === "IT" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value === "Tech" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Tech
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value ===
                      "Cust Service" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Cust Service
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value === "Legal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Legal
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value ===
                      "Finance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finance
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value ===
                      "Purchase" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Purchase
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value === "SCM" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SCM
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value ===
                      "Delivery" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Delivery
                    </div>
                    <div className="fleft width12">
                      {this.state.mssCafDataupdated[25979].value ===
                      "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25980].value}
                        readOnly
                      />
                    </div>
                    <div className="signate float_img">
                      {firstCaps != "" ? (
                        <div style={{ padding: "10px" }} className={Signcss}>
                          {firstCaps + "  .  "}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.imageData != null &&
                      this.state.imageData != "" ? (
                        <div
                          style={{ padding: "10px" }}
                          className="signate_img"
                        >
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <p className="auth namsupp">
                    Name of Supporting Document
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25981].value}
                      readOnly
                    />
                  </p>

                  <p className="gsttext">
                    (GST Certificate, SEZ Certificate, Diploma Certificate)
                  </p>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>

            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <div className="pad_t5">
                    <p className="ligthbg">CUSTOMER INFORMATION</p>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">Customer Type</div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25983].value ===
                      "General" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      General
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25983].value === "BPO" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BPO
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25983].value ===
                      "Telemarketer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Telemarketer
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25983].value ===
                      "ISP License No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ISP License No
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25983].value ===
                      "OSP Reg. No." ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      OSP Reg. No.
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25983].value ===
                      "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                  </div>

                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width15_2 pad_7t"> &nbsp; </div>
                    <div className="fleft width20">
                      ISP License No{" "}
                      <input
                        className="form_col wid60px ipslinc"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25984].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width14">
                      Date{" "}
                      <input
                        className="form_col wid60px ipslinc"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25985].value}
                        readOnly
                      />
                    </div>

                    <div className="fleft width20">
                      OSP Reg. No.
                      <input
                        className="form_col wid60px ipslinc"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25986].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width14">
                      Date{" "}
                      <input
                        className="form_col wid60px ipslinc"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25987].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width14">
                      Others{" "}
                      <input
                        className="form_col wid60px ipslinc"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[25988].value}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="check over_hid custypeone pad_t5">
                    <div className="fleft width15_2 pad_7t">
                      Customer Vertical Type
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Automotive" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Automotive
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Consumer Goods" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consumer Goods
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Diversified & Projects" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diversified & Projects
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Education" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Education
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Engineering" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Engineering
                    </div>
                    <div className="fleft width14 fnt8">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Industry Associations" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Industry Associations
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t"> </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Health Care" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Health Care
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Finance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finance
                    </div>

                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Government" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Government
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "IT & Telecom" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT & Telecom
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Logistics" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Logistics
                    </div>
                    <div className="fleft width14 fnt6">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Manufacturing & Processing" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Manufacturing & Processing
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t"> </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Trading" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Trading
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Services" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Services
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Projects EPC" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Projects EPC
                    </div>
                    <div className="fleft width14">
                      {this.state.mssCafDataupdated[25989].value ===
                      "Others" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                    <div className="fleft width14">
                      <div className="auth">
                        <input
                          className="form_col wid100px"
                          type="input"
                          name=""
                          value={this.state.mssCafDataupdated[25990].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <p className="ligthbg">CUSTOMER ORDER DETAILS</p>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">Type of Order </div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[25992].value === "New" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      New
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25992].value ===
                      "Change" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Change
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25992].value ===
                      "Demo - Paid" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Demo-Paid
                    </div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[25992].value ===
                      "Demo - Free" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Demo-Free
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">
                      Type of Change Order
                    </div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("CPE Upgrade") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CPE Upgrade
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("CPE Downgrade") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CPE Downgrade
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Feature(s) Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Feature(s) Change
                    </div>
                    <div className="fleft width25">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Customer Provided CPE") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer Provided CPE
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Contract Extension") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Contract Extension
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Billing Address Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing Address Change
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Shipping Address/Contact Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Shipping Address Change
                    </div>
                    <div className="fleft width25">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Billing Periodicity Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing Periodicity Change
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width15 fnt6">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Billing in (Payment Mode) Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing In (Payment Mode) Change{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Company Name change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Company Name Change
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Installation Contact Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Installation Contact Change
                    </div>
                    <div className="fleft width25">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Billing Customer Name Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Billing Customer Name Change
                    </div>
                  </div>
                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width15 fnt7">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Authorized Signatory Change") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Authorized Signatory Change{" "}
                    </div>
                    <div className="fleft width20 fnt8">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Other Non Commercial Changes") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other Non-Commercial Changes
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Price Revision") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Price Revision
                    </div>
                    <div className="fleft width25">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Demo Extension") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Demo Extention
                    </div>
                  </div>

                  <div className="check over_hid custype">
                    <div className="fleft width15_2 pad_7t">&nbsp;</div>
                    <div className="fleft width15 fnt7">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Enable Tax Exemption") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Enable Tax Exemption{" "}
                    </div>
                    <div className="fleft width20 fnt8">
                      {this.state.mssCafDataupdated[25993].value
                        .split("|")
                        .indexOf("Disable Tax Exemption") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Disable Tax Exemption
                    </div>
                  </div>

                  <div className="auth over_hid">
                    <span className="width15_2 in_block">
                      Change Order Remarks
                    </span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[25994].value}
                      readOnly
                    />
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft wid50">
                      <div className="wifitable">
                        <table
                          width="90%"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td>Year</td>
                              <td>Yearly Price Reduction%</td>
                              <td>
                                Early Termation Penalty as % <br />
                                of rental for remaining
                                <br />
                                contract period
                              </td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>
                                {this.state.mssCafDataupdated[26001].value}
                              </td>
                              <td>
                                {this.state.mssCafDataupdated[26002].value}
                                &nbsp;
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>
                                {this.state.mssCafDataupdated[26003].value}
                                &nbsp;
                              </td>
                              <td>
                                {this.state.mssCafDataupdated[26004].value}
                                &nbsp;
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>
                                {this.state.mssCafDataupdated[26005].value}
                                &nbsp;
                              </td>
                              <td>
                                {this.state.mssCafDataupdated[26006].value}
                                &nbsp;
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>
                                {this.state.mssCafDataupdated[26007].value}
                                &nbsp;
                              </td>
                              <td>
                                {this.state.mssCafDataupdated[26008].value}
                                &nbsp;
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>
                                {this.state.mssCafDataupdated[26009].value}
                                &nbsp;
                              </td>
                              <td>
                                {this.state.mssCafDataupdated[26010].value}
                                &nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="fleft wid50">
                      <div className="check over_hid custype pad_t5">
                        <div className="fleft wid20per pad_7t">Demo Period</div>
                        <div className="fleft width70">
                          <input
                            className="form_col fleft wid100px"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25995].value}
                            readOnly
                          />
                          <div className="fleft pd_t5 pad_l3">
                            {" "}
                            &nbsp; in days
                          </div>
                        </div>
                      </div>
                      <div className="check over_hid custype pad_t5">
                        <div className="fleft wid20per pad_7t">
                          Contract Period
                        </div>
                        <div className="fleft width70">
                          <input
                            className="form_col fleft wid100px"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25996].value}
                            readOnly
                          />
                          <div className="fleft pd_t5 pad_l3">
                            {" "}
                            &nbsp; in months
                          </div>
                        </div>
                      </div>

                      <div className="check over_hid custype pad_t5">
                        <div className="fleft wid20per pad_7t">
                          MSS Circuit ID
                        </div>
                        <div className="fleft width70">
                          <input
                            className="form_col fleft wid100px"
                            type="text"
                            name=""
                            value={this.state.mssCafDataupdated[25997].value}
                            readOnly
                          />
                          <div className="fleft pd_t5 pad_l3 fnt7">
                            {" "}
                            &nbsp; (Applicable for existing MSS customers)
                          </div>
                        </div>
                      </div>

                      <div className="check over_hid custype pad_t5">
                        <div className="fleft width25 pad_7t">
                          Product Flavour{" "}
                        </div>
                        <div className="fleft width30">
                          {this.state.mssCafDataupdated[25998].value ===
                          "Basic Firewall" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Basic Firewall{" "}
                        </div>
                        <div className="fleft width30">
                          {this.state.mssCafDataupdated[25998].value ===
                          "Advanced UTM" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Advanced UTM
                        </div>
                      </div>

                      <div className="check over_hid custype pad_t5">
                        <div className="fleft width25 pad_7t">
                          Service Flavour{" "}
                        </div>
                        <div className="fleft width30">
                          {this.state.mssCafDataupdated[25999].value ===
                          "Self-Service" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Self-Service
                        </div>
                        <div className="fleft width30 fnt8">
                          {this.state.mssCafDataupdated[25999].value ===
                          "Standard service" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Standard Service
                        </div>
                      </div>
                      <div className="check over_hid custype pad_t5">
                        <div className="fleft width60">
                          {this.state.mssCafDataupdated[25999].value ===
                          "Premium Service Support" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Premium Service Support
                        </div>
                      </div>

                      <div className="check over_hid custype pad_t5">
                        <div className="fleft width30 pad_7t">
                          HA Mode Required{" "}
                        </div>
                        <div className="fleft width30">
                          {this.state.mssCafDataupdated[26000].value ===
                          "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft width30 fnt8">
                          {this.state.mssCafDataupdated[26000].value ===
                          "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="auth over_hid">
                    <span className="width15_2 in_block">Remarks</span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.mssCafDataupdated[26011].value}
                      readOnly
                    />
                  </div>

                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Billing In </div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[26012].value ===
                      "Advance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Advance
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26012].value ===
                      "Arrears" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Arrears
                    </div>
                  </div>

                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Billing Periodicity
                    </div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[26013].value ===
                      "Monthly" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Monthly{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26013].value ===
                      "Quarterly" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quarterly
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26013].value ===
                      "Half-Yearly" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Half Yearly
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26013].value ===
                      "Annually" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Annually
                    </div>
                  </div>

                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Tax Exemption</div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[26014].value === "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26014].value === "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>

                  <div className=" over_hid custype pad_t5">
                    <div className="fleft width35 pad_7t">
                      <div className="auth">
                        If Yes, then, provide details{" "}
                        <input
                          className="form_col wid100px"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26015].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="fleft check width35">
                      Tax Exemption Ref. Number{" "}
                      <input
                        className="form_col wid100px"
                        type="input"
                        name=""
                        value={this.state.mssCafDataupdated[26016].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      <div className="auth">
                        Tax Exemption Reason{" "}
                        <input
                          className="form_col wid100px"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26017].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="check over_hid custype pad_5t">
                    <div className="fleft width13 pad_r5">
                      {" "}
                      CPE Provided By{" "}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {this.state.mssCafDataupdated[26018].value ===
                      "Vodafone Idea" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}{" "}
                      Vodafone Idea
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {this.state.mssCafDataupdated[26018].value ===
                      "Customer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}{" "}
                      Customer
                    </div>

                    <div className="fleft wid20per pad_r5 check text_r">
                      {" "}
                      VIL Provided Hardware On
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {this.state.mssCafDataupdated[26019].value === "Rent" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}{" "}
                      Rent
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {this.state.mssCafDataupdated[26019].value === "Sale" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}{" "}
                      Sale
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>VIL Provided CPE</strong>
                  </p>
                  <div className="wifitable">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr No</td>
                          <td>Hardware Type</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Quantity</td>
                          <td>Remarks</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>CPE</td>
                          <td>
                            {this.state.mssCafDataupdated[26030].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26031].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26032].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26033].value} &nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>CPE</td>
                          <td>
                            {this.state.mssCafDataupdated[26022].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26023].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26024].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26025].value} &nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>CPE</td>
                          <td>
                            {this.state.mssCafDataupdated[26026].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26027].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26028].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26029].value} &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p className="margin0 pad_t10">
                    <strong>Customer Provided CPE</strong>
                  </p>
                  <div className="wifitable">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr No</td>
                          <td>Hardware Type</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Quantity</td>
                          <td>Remarks</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>CPE</td>
                          <td>
                            {this.state.mssCafDataupdated[26044].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26045].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26046].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26047].value} &nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>CPE</td>
                          <td>
                            {this.state.mssCafDataupdated[26036].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26037].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26038].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26039].value} &nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>CPE</td>
                          <td>
                            {this.state.mssCafDataupdated[26040].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26041].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26042].value} &nbsp;
                          </td>
                          <td>
                            {this.state.mssCafDataupdated[26043].value} &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="pad_t10">
                    {" "}
                    <div className="check  over_hid custype pad_5t">
                      <div className="fleft width25 pad_r5">
                        {" "}
                        Service Monitored & Managed By{" "}
                      </div>
                      <div className="fleft wid12per pad_r5 check text_r">
                        {this.state.mssCafDataupdated[26048].value ===
                        "VodafoneIdea" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        VodafoneIdea
                      </div>
                      <div className="fleft wid12per pad_r5 check text_r">
                        {this.state.mssCafDataupdated[26048].value ===
                        "Customer" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customer
                      </div>
                    </div>
                  </div>

                  <div className="check over_hid custype pad_5t">
                    <div className="fleft width25 pad_r5">
                      {" "}
                      Service Maintaned By{" "}
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {this.state.mssCafDataupdated[26049].value ===
                      "VodafoneIdea" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      VodafoneIdea
                    </div>
                    <div className="fleft wid12per pad_r5 check text_r">
                      {this.state.mssCafDataupdated[26049].value ===
                      "Customer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>

                  <p className="ligthbg">WAN SERVICE DETAILS</p>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service for MSS
                    </div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[26051].value ===
                      "Existing VIL" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Existing - VIL{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26051].value ===
                      "Existing Non VIL" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Existing-Non - VIL
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26051].value ===
                      "New VIL" ? (
                        <input
                          className="form_col"
                          type="checkb  ox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      New - VIL
                    </div>
                  </div>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service Circuit ID
                    </div>
                    <div className="fleft width70">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[26052].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        &nbsp; If WAN Service is Existing - VIL
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service Feasibility ID (FR ID)
                    </div>
                    <div className="fleft width70">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[26053].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        &nbsp; If WAN Service is New - VIL
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN Service Provider
                    </div>
                    <div className="fleft width70">
                      <input
                        className="form_col fleft wid50per"
                        type="text"
                        name=""
                        value={this.state.mssCafDataupdated[26054].value}
                        readOnly
                      />
                      <div className="fleft pd_t5 pad_l3">
                        {" "}
                        &nbsp; If WAN Service is Existing-Non - VIL
                      </div>
                    </div>
                  </div>

                  <div className="check over_hid custype pad_t5">
                    <div className="fleft width22 pad_7t">
                      WAN CPE Managed By
                    </div>
                    <div className="fleft width15">
                      {this.state.mssCafDataupdated[26055].value ===
                      "VodafoneIdea" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea{" "}
                    </div>
                    <div className="fleft width20">
                      {this.state.mssCafDataupdated[26055].value ===
                      "Customer" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>

            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <div className="pad_t5">
                    <p className="ligthbg">CHARGE DESCRIPTION</p>
                  </div>
                  <div className="char inchar">
                    <div className="check over_hid pad_t5">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Specify Currency{" "}
                      </div>
                      <div className="fleft width5 pad_r5">
                        {this.state.mssCafDataupdated[26057].value === "INR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}{" "}
                        INR
                      </div>
                      <div className="fleft width5 pad_r5">
                        {this.state.mssCafDataupdated[26057].value === "US$" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}{" "}
                        US${" "}
                      </div>
                      <div className="fleft width5">
                        {this.state.mssCafDataupdated[26057].value === "GBP" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}{" "}
                        GBP{" "}
                      </div>
                      <div className="fleft width20 textcenter">
                        {" "}
                        One Time (A){" "}
                      </div>
                      <div className="fleft width20 textcenter">
                        {" "}
                        Annual Recurring (B){" "}
                      </div>
                      <div className="fleft width20 textcenter">
                        {" "}
                        Grand Total (A+B+C){" "}
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3"> CPE Charge</div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26058].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26059].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26060].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        License/AMC Charge
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26061].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26062].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26063].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed Service Charge
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26064].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26065].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26066].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Change Management Charge
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26067].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26068].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26069].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pd_t3">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        <strong>Total MSS Service Charge</strong>{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26070].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26071].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26072].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t10">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        <strong>Total Order Value</strong>{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width50 pad_l10">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26073].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <p className="ligthbg">COMMENTS :</p>
                  <textarea
                    className="textarea"
                    placeholder="Comments/Special Consideration:"
                    value={this.state.mssCafDataupdated[26074].value}
                    readOnly
                  ></textarea>
                  {/* 
                    <textarea className="textarea min50" placeholder="Comments/ Special Considerations:" value={this.state.mssCafDataupdated[26074].value} readOnly>
                      </textarea> */}

                  {/* <div className="cmt_spl"> Comments/ Special Considerations: </div> */}
                  <div className="over_hid">
                    <div className="fleft width65 pad_t10">
                      <p className="margin0">
                        <strong>Declaration</strong>
                      </p>
                      <p className="margin0">
                        I / We confirm having received, read and understood the
                        Product Terms & Conditions (provided overleaf) and the
                        General Terms & Conditions. I / We further confirm that
                        the Service selected and applicable charges form part of
                        this Agreement (as defined herein) and I / We agree to
                        abide by the Applicable Law in force and also any
                        statutory amendments, or new legislations as may be
                        enacted from time to time, in so far as they realte to
                        the services. I / We hereby declare and confirm that the
                        above information provided by us is true and correct in
                        all respects and I / We hereby undertake to be bound by
                        the same.{" "}
                      </p>
                      {/* <p className="margin0 pad_5t">Authorised Signatory's Name</p>
                      <p className="margin0">
                        <input className="form_col width98per cmnt_input" type="text" name="" value={this.state.mssCafDataupdated[26076].value} readOnly />
                      </p> */}
                      <div className="over_hid pad_t15">
                        <div className="fleft width30">
                          <p className="margin0">
                            Applicant’s Name / Authorised Signatory's Name
                          </p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.mssCafDataupdated[25929].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Place</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.mssCafDataupdated[26077].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Date</p>
                          <p className="margin0">
                            <input
                              className="form_col width96per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.mssCafDataupdated[26078].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                      <p className="margin0 pad_5t">Account Manager Name</p>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26080].value}
                          readOnly
                        />
                      </p>
                      <p className="margin0 pad_20t">
                        Account Manager Circle Office
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col  wid120p cmnt_input"
                          type="text"
                          name=""
                          value={this.state.mssCafDataupdated[26081].value}
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_20t">
                        <div className="fleft width50">
                          <p className="margin0">Channel Partner Name</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.mssCafDataupdated[26083].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="flright width30">
                          <p className="margin0">Channel Partner Code</p>
                          <p className="margin0">
                            <input
                              className="form_col width92per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.mssCafDataupdated[26084].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flright width30">
                      <div
                        style={{ padding: "5px" }}
                        className="signate_account"
                      >
                        {firstCaps != "" ? (
                          <div className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          Signature of the Customer / Authorised signatory with
                          in this box only (In case of institutinal, please
                          affix office/company seal){" "}
                        </div>
                      </div>
                      <div
                        style={{ padding: "5px", height: "120px" }}
                        className="signate"
                      >
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            height="90px"
                            width="100px"
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Signature of Account Manager"}
                          />
                        ) : (
                          ""
                        )}
                        <div>Signature of the Account Manager </div>
                      </div>
                      {/* <div className="signate_account">Signature of the Account Manager</div> */}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="signate float_img">
                      {/* {firstCaps != "" ?
                                    <div style={{ padding: '10px' }} className={Signcss}>{firstCaps + "  .  "}<span className="under_line">{secondBold}</span></div>
                                    : ""} */}

                      {this.state.imageData != null &&
                      this.state.imageData != "" ? (
                        <div
                          style={{ padding: "10px" }}
                          className="signate_img"
                        >
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <p>
                    {" "}
                    <strong>Vodafone Idea Limited</strong> (formerly Idea
                    Cellular Limited)
                    <br />
                    An Aditya Birla Group and Vodafone partnership
                    <br />
                    MergerCo CIN No: L32100GJ1996PLC030976
                    <br />
                    <strong>Registered Office:</strong> Suman Tower, Plot no.
                    18, Sector 11, Gandhinagar - 382 011, Gujarat. T: +91 79
                    66714000 I F: +91 79 23232251
                    <br />
                    <strong>Circle Office Address:</strong>
                  </p>
                </div>
              </div>
            </page>
            <page>
              <div className="section2">
                <div className="font8">
                  <p className="ligthbg">TERMS & CONDITIONS</p>
                  <p>
                    {" "}
                    This “Customer Application Form” (CAF) shall mean together
                    (i) the customer information form; and (ii) the following
                    conditions (“Regulatory Terms”), which are required to be
                    provided/completed with effect from /or prior to activation
                    of Managed Security Service provided by Vodafone Idea
                    Limited under its ISP- All India (Cat A) License and these
                    ‘Regulatory Terms’ shall continue during the term of
                    service.
                  </p>

                  <p className="reglat">
                    <strong>REGULATORY TERMS</strong>
                  </p>

                  <div className="over_hid pad_t10">
                    <div className="fleft width48">
                      It is agreed between Vodafone Idea Ltd. OR its successor,
                      assignee, transferee, and you, (‘You’ or the ‘Subscriber’)
                      to the following:
                      <br />
                      <br />
                      <strong>1. DEFINITION:</strong>
                      <br />
                      (a) Service shall mean the service set out on the VIL
                      customer information form and any related solutions
                      provided by VIL. (b) Agreement: means this (i) CAF; and
                      (ii) the Master Service Agreement (MSA) or General Terms &
                      Conditions (GTCs) together with any schedules thereto,
                      which shall constitute the agreement between Customer and
                      VIL. (c) Customer: shall mean the name as set out in the
                      CAF.
                      <br />
                      <br />
                      <strong>2. LICENSE CONDITIONS:</strong>
                      <br />
                      (a) Telecommunication and related Services are provided by
                      VIL, by virtue of the Internet Service Provider license
                      given by DOT, MCIT, and GOI. A change mandated by the DOT
                      or Telecom Regulatory Authority of India (TRAI) shall
                      apply to the Service from the date of notification by
                      Government. VIL shall inform of any change impacting the
                      Customer or the Services from time to time; (b) VIL may
                      require resubmission of any CAF, should CAF be incomplete
                      or with insufficient information or incorrect information
                      (c) Services will be provisioned after completion of CAF
                      verification (d) Customer shall indemnify and keep
                      indemnified VIL for all and any claims, proceedings or
                      actions brought against VIL arising out of any breach by
                      the Customer of the Regulatory Terms.
                      <br />
                      <br />
                      <strong>3. USE OF SERVICES:</strong>
                      <br />
                      The Customer shall use the Services only for the purposes
                      specified by the Customer in the CAF and shall not use for
                      any other purpose unless otherwise permitted. The Customer
                      understands and agrees that VIL shall be entitled to
                      suspend / terminate the Service, for any unauthorized or
                      fraudulent use and be indemnified for financial penalty as
                      levied by an Authority. The Services shall not be used for
                      call center/ other service provider (OSP) operations
                      without registration with DOT as a call center/ OSP and
                      submit copy of license / registration. The customer having
                      OSP registration shall ensure that logical partitioning of
                      public network has been undertaken with the NPLC/MPLS IP
                      network and necessary declaration to this effect has been
                      given to the respective TERM Cell of DOT (Licensor).{" "}
                      <br />
                      <br />
                      <strong>4. OBLIGATIONS OF THE CUSTOMER:</strong>
                      <br />
                      A (a) The Customer shall intimate in writing details of
                      change of name, address, title or constitution whenever
                      such change takes place. (b) The Customer shall be
                      allotted an identity number by VIL which is and shall
                      always remain the property of VIL. B What Customer shall
                      do: Customer shall use all reasonable efforts to ensure
                      that user’s use the Services in accordance with this
                      Agreement, all applicable law, regulations, circulars,
                      directives and all relevant codes of practice of
                      Governmental Authorities. C. What Customer shall not do:
                      Customer shall not: (a) use any Services for any abusive,
                      improper, immoral, offensive, illegal or fraudulent
                      purposes; (b) do anything that causes the Network to be
                      impaired or damaged; (c) re-sell any Services. D VIL is
                      (and shall) not be responsible for the content, usage or
                      any losses that arise to the Customer from the use of the
                      service and VIL shall not be liable for the security or
                      confidentiality of such facilities or services. E Customer
                      shall cooperate with Governmental Security Agencies / or
                      Law & Enforcement Agencies in case of any inspection / or
                      investigation is carried out by them. F VIL is only a
                      managed solution provider for MSS service to the customer
                      and is not liable for any breach of law committed by
                      customer for use of the services. G Customer hereby
                      provides consent to the collection, processing and use of
                      its personal data such as, including but not limited to,
                      Customer name, contact details and employment related
                      information for the purposes using the Services and
                      Additional Services according to (Short form of Company
                      name)’s privacy policy displayed on its web site
                      www.vodafone.in
                      <br />
                      <br />
                      <strong>
                        5. REGULATORY COMPLIANCE SPECIFIC PROVISIONS:
                      </strong>
                      <br />
                      (a) The CAF binds the Customer, their executors,
                      administrators, successors and permitted assignees to the
                      terms & conditions of the CAF; (b) Customer shall allow or
                      obtain the required permission to enable VIL employees or
                      authorized personnel, enter at all times into the premises
                      where the Services are provided for periodical inspection,
                      installing, maintaining, replacing and removing equipment
                      hardware and/or software prior to, during and after the
                      provision of the services; (c) The Customer undertakes to
                      comply with relevant and applicable laws, licensing
                      conditions, directions, notifications, rules and
                      regulations regarding the use of the Service, and its
                      amendments, replacements made from time to time by the
                      Licensor in India and TRAI and any other statutory
                      authority in India; (d) The Customer shall obtain any
                      relevant consents and approvals for the installation and
                      use of any equipment located at the Customer sites and
                      associated with the Service and any permits, consents or
                      approvals required by the Customer to use the Service,
                      including any applicable other VIL permits (“Permits”);
                      (e) Customer shall use the Services in accordance with the
                      Permits; (f) Customer shall ensure that any network
                      equipment (not provided by Services Provider) which it
                      uses in relation to the Services meet the relevant
                      International Telecommunication Union
                      (ITU)/Telecommunication Engineering Centre (TEC)
                      standards; (g) The Customer must ensure that Service
                      provided, is not used for any unlawful purposes using the
                      Services as End User. The Customer shall ensure that
                      objectionable, obscene, unauthorized or any other content,
                      messages or communications infringing copyright,
                      Intellectual property right and international & domestic
                      cyber laws, in any form or inconsistent with the laws of
                      India, are not carried in their network by them or any
                      other authorized person using their network or during the
                      provisioning of Services. The Customer must take all
                      necessary measures to prevent such use. The use of the
                      Service for anti-national activities shall be construed as
                      an offence punishable under the Indian Penal Code or other
                      applicable law. (h) Customer agrees and acknowledges that
                      regulatory and law enforcement agencies (Law Enforcement
                      Agency (LEA)), shall have rights to access the network
                      including access to customer premises and intercept or
                      listen or record calls being made using the Services and
                      facilities provided by Service Provider and Customer shall
                      provide necessary facilities/access/consent to VIL,
                      including but not limited to LEAs /Authorities to monitor,
                      control, prevent counter any such or other unlawful
                      activity. (i) Customer understands Customer and its End
                      Users use the Services at their own risk and consequences
                      and VIL has no control over and accepts no responsibility
                      whatsoever for such the Customer use of the Services and
                      the transacted business/transmitted communication and
                      Customer shall comply with all regulatory/legal and
                      statutory requirements during such use of the Services.
                      (j) Customer
                    </div>
                    <div className="flright width48">
                      understands and agrees that VIL shall terminate the
                      Service provided to the Customer immediately without any
                      prior notice, upon any violation of these terms and
                      conditions by the Customer and/or directive of any Law
                      Enforcement Agency (LEA) / Judicial / authority or any
                      other Government agency. (k)
                      Individuals/Groups/Organizations are permitted to use as
                      customer encryption up to only the permitted bit key
                      length in the RSA algorithms or its equivalent in other
                      algorithms without having to obtain permission. However,
                      if encryption equipment higher than the permitted limit is
                      to be deployed, individuals/groups/organizations shall do
                      so with the permission of the Telecom Authority and
                      deposit the decryption key split into two parts, with the
                      Telecom Authority.
                      <br />
                      <br />
                      <strong>6. PRODUCT TERMS & CONDITIONS:</strong>
                      <br />
                      A The detailed layout diagram for the Services stating all
                      the termination ends may be required from the Customer,
                      which may include the following details viz. make, model
                      and type of terminal interface proposed to be used. In
                      case the VIL is not providing terminal equipment for the
                      Services, then Customer would have to arrange for the
                      terminal equipment and also maintain the same at the
                      Customer’s own cost. The terminal equipment arranged and
                      maintained by Customer shall at all-time be in accordance
                      with the specifications approved by the Telecom
                      Engineering Centre (TEC), Department of
                      Telecommunications, Ministry of Communications &
                      Information Technology, Government of India, New Delhi –
                      110 001 and any other amendment thereof. B Before carrying
                      out any changes or alteration in any configuration of the
                      equipment used under this Service, Customer shall ensure
                      prior written consent has to be obtained from VIL. C
                      Without prior notice, VIL may at any time through its
                      authorized representative inspect the Customer’s operating
                      room and associated apparatus during the service contract
                      period and the Customer has agreed to co-operate in all
                      manners and provide all assistance, for and during such
                      inspection. D VIL shall have the right, at any time and
                      without notice to the Customer to suspend and resume
                      providing of Service for such period or periods as it
                      shall think fit in case it is of the opinion that such an
                      action is necessary or expedient in the public interest.
                      The decision of VIL in this respect will be final and VIL
                      shall not be liable to pay any compensation or other
                      payment of any kind such as those in the nature of damages
                      of any kind to the customer for exercising such rights.
                      <br />
                      G VIL will issue to the Customer with certain devices /
                      equipment in order for the Customer to avail of MSS
                      services and connect to the internet. These devices are
                      liable to be returned by the Customer to VIL on
                      termination of services / connection or on request by VIL.
                      H The Customer shall keep the VIL owned equipment in good
                      condition. In case of non-return of VIL owned
                      devices/equipment or return of VIL owned devices/equipment
                      in damaged/non-working conditions, penalty charges will be
                      applicable and payable by the Customer as per MSS GTCs
                      separately agreed by the Customer. I Upon receiving
                      written request from the Customer, Service termination
                      will be done after the receipt of all VIL provided
                      equipment under this service and settlement of all
                      outstanding dues, penalty/damages (if applicable) by the
                      Customer. J Disclaimer- Customer hereby acknowledges that,
                      except as specifically set forth herein, VIL has neither
                      made nor makes any warranty, representation or indemnity
                      with respect to the quality of service provided to
                      Customer under the Service. <br />
                      <br />
                      <strong>7. CONTRACT TERMS AND COMMERCIAL TERMS:</strong>
                      <br />
                      A This CAF, shall be read with General Terms & Conditions
                      (GTCs) or any Master Service Agreement (MSA) entered
                      between the Customer and VIL or any India specific
                      in-country agreement (ICC) between the Customer and VIL
                      entered subsequent to or pursuant to any global MSA
                      entered between the global principals/affiliates of the
                      Customer and VIL. B The CAF terms will prevail over any
                      provisions in the MSA/ICC or GTCs which conflict with
                      terms in CAF. C Minimum Contract Term for this service is
                      1 year (12 months), which will be effective from the date
                      of service commissioning. However, the actual contract
                      term may vary basis mutual agreement between parties. If
                      contract is due for renewal and the Customer has not put a
                      written request to VIL for renewal or termination, then,
                      the Services will be deemed accepted and renewed
                      automatically as per existing GTC or MSA/ICC. If price
                      revision is pending the due to ongoing negotiation, then,
                      the new prices shall be effective from the date of receipt
                      of renewal purchase order or signed CAF or revised
                      contract. D Customer has agreed to make timely payment
                      against invoice as per service charges and terms of
                      existing CAF, GTC or MSA/ICC unless there is a revision
                      mutually agreed by both Customer and VIL. E In case of
                      conflicting terms between various documents, unless
                      specifically mentioned, following will be the priority of
                      the documents for the interpretation of the terms, in
                      descending order 1) CAF 2) MSA/ICC 3) GTC 4) Customer PO.
                      F In the event of early termination request by customer
                      before contract term expiry date, the Customer has agreed
                      to pay Early Termination Charges as defined in GTC with
                      this CAF. <br />
                      <br />
                      <strong>8. GOVERNING LAW:</strong>
                      <br />
                      This CAF shall be subject to Indian Law and courts of
                      jurisdiction shall include courts in the place where
                      Customer has a place of business or where the cause of
                      action arises or the place where the Services have been
                      offered. <br />
                      <br />
                      <strong>9. GST & SEZ STATUS:</strong>
                      <br />
                      The customer will be solely responsible for providing the
                      correct GST number and "SEZ" related information against
                      the “Place of Supply” as per the GST law. S/he waives off
                      all the rights to dispute or withhold any payments in case
                      of incorrect GST numbers or incorrect SEZ certificates/
                      status being provided, which don't match the details
                      available on the Government GSTN portal. The customer
                      acknowledges that s/he will not be able to claim the GST
                      tax credit under such circumstances and shall pay 100%
                      invoiced amount, including taxes.
                      <div className="signate float_img">
                        {firstCaps != "" ? (
                          <div style={{ padding: "10px" }} className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <div
                            style={{ padding: "10px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>

            {this.state.excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">Annexure to MSS CAF :- </p>
                  </div>
                  <JsonToTable json={this.state.excelJson} />
                  <div>
                    <p className="font10 " style={{ marginTop: "20px" }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div className="fleft width50 pad_t10">
                    <p className="marb5">Account Manager Name</p>
                    <p className="margin0">
                      <input
                        className="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        readonly=""
                        value={this.state.mssCafDataupdated[26080].value}
                        readOnly
                      />
                    </p>
                    <div className="signate">
                      <div className="signate_img input_sign">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Account Manager Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div>
                        Authorised Signatory Name
                        <input
                          className="form_col inputext"
                          type="text"
                          name="Name_CD"
                          value={this.state.mssCafDataupdated[25929].value}
                          readOnly
                        />
                      </div>
                    </div>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      mssCafContentView = this.state.mssCafDataupdated ? (
        <div style={{ paddingTop: "30%", marginLeft: "45%" }}>
          <PulseLoader color={"#000000"} loading={true} />
        </div>
      ) : (
        <div
          style={{ marginTop: "200px", height: "300px", textAlign: "center" }}
        >
          {" "}
          MSS CAF Form not submitted yet.
        </div>
      );
    }

    return <div>{mssCafContentView}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MssCafForm);
