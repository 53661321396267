import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import "../../VodafoneIdea.css";
import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
import CloudImg from "../../../../../assets/images/Process/VodafoneIdea/cloud.jpg";
import lotImg from "../../../../../assets/images/Process/VodafoneIdea/lot.jpg";
import WireImg from "../../../../../assets/images/Process/VodafoneIdea/wire.jpg";
import WirelessImg from "../../../../../assets/images/Process/VodafoneIdea/wireless.jpg";
import ReactToPrint from "react-to-print";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import AxiosWeb from "../../../../../axios_ext";
import "./PriCrfForm.css";
import { JsonToTable } from "react-json-to-table";
import Axios from "axios";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class PriCrfForm extends Component {
  state = {
    priCrfData: false,
    stopdidmount: 0,
    priCrfDataupdated: null,
    authorizedSignName: "",
    companySeal: "",
    imageData: "",
    imageType: "",
    acctMngSignImageData: "",
    acctMngSignImageType: "",
    excelJson: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };
  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1350,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 12634) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 12633) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("fieldObj:: " + fieldObj.field_id);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.custCompanySeal);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 1277; // PRI CRF form Id
    let orderform = 1346;
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        priCrfData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12415
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+submittedFormArry);
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );

        this.setState({
          ...this.state,
          priCrfData: true,
        });
      }
      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12415
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("10909") &&
      this.state.stopdidmount === 0
    ) {
      //console.log('submitted forms list',this.props.fieldList);
      // console.log('this.props.fieldList : ', this.props.fieldList)
      let x = this.props.fieldList;
      console.log("********************");
      console.log("Company Seal URL : ", this.state.custCompanySeal);
      console.log("Account Manager Signature URL : ", x[11013].value);
      console.log("********************");

      let companySealUrl = "";
      let BucketName = "";
      let KeyName = "";
      let temp = "";

      if (
        this.state.custCompanySeal != null &&
        this.state.custCompanySeal != ""
      ) {
        companySealUrl = this.state.custCompanySeal || "";
        //BucketName = this.state.custCompanySeal.slice(8, 25);
        //KeyName = this.state.custCompanySeal.slice(43);
        [BucketName, KeyName, temp] = await utils.getBucketKeyFileName(
          this.state.custCompanySeal
        );
      }
      console.log("companySealUrl : ", companySealUrl);

      if (companySealUrl !== "") {
        this.s3.getObject(
          {
            Bucket: BucketName,
            Key: KeyName,
          },
          async (err, data) => {
            if (err) {
              console.log("error 1", err);

              this.setState({
                ...this.state,
                priCrfDataupdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 1 ", data);
              console.log("data is 1 ", data.Body);

              let AcctManagerSignUrl = x[11013].value;
              //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
              //const KeyName1 = AcctManagerSignUrl.slice(43);
              let BucketName1, KeyName1;
              [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
                AcctManagerSignUrl
              );

              this.s3.getObject(
                {
                  Bucket: BucketName1,
                  Key: KeyName1,
                },
                (err, data1) => {
                  if (err) {
                    console.log("error 2", err);

                    this.setState({
                      ...this.state,
                      priCrfDataupdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      stopdidmount: 1,
                    });
                  } else {
                    console.log("data is 2 ", data1);
                    console.log("data is 2 ", data1.Body);

                    this.setState({
                      ...this.state,
                      priCrfDataupdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      acctMngSignImageData: data1.Body,
                      acctMngSignImageType: data1.ContentType,
                      stopdidmount: 1,
                    });
                  }
                }
              );
            }
          }
        );
      } else {
        let AcctManagerSignUrl = x[11013].value;
        //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
        //const KeyName1 = AcctManagerSignUrl.slice(43);
        let temp = "";
        let BucketName1, KeyName1;
        [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
          AcctManagerSignUrl
        );

        this.s3.getObject(
          {
            Bucket: BucketName1,
            Key: KeyName1,
          },
          (err, data1) => {
            if (err) {
              console.log("error 2", err);

              this.setState({
                ...this.state,
                priCrfDataupdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 2 ", data1);
              console.log("data is 2 ", data1.Body);

              this.setState({
                ...this.state,
                priCrfDataupdated: this.props.fieldList,
                acctMngSignImageData: data1.Body,
                acctMngSignImageType: data1.ContentType,
                stopdidmount: 1,
              });
            }
          }
        );
      }
    }
  }

  render() {
    console.log("priCrfDataupdated :: " + this.state.priCrfDataupdated);

    let PriCrfContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );
    if (
      this.state.priCrfDataupdated != null &&
      this.state.priCrfDataupdated !== "" &&
      this.state.stopdidmount == 1 &&
      this.props.formFullLoaded === 1
    ) {
      let x = this.state.priCrfDataupdated;
      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }

      let firstCaps = "";
      let secondBold = "";
      let Signcss;

      console.log(
        "this.state.authorizedSignName :: " + this.state.authorizedSignName
      );

      if (this.state.authorizedSignName != "") {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        console.log(firstCaps, "console.log(firstCaps);");
      }

      PriCrfContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ padding: "20px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsStyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsStyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <div className="pad_20t">
                    <div className="over_hid">
                      <div className="pd_t5">
                        {" "}
                        <span className="textcenter width70 fleft font15 mplhed">
                          <strong>PRI/TFS/ACS/VCS Change Request Form</strong>
                        </span>
                        <div className="caf">
                          CAF ID
                          <input
                            className="form_col"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10909].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="over_hid mar_t-5">
                      <div className="pd_t5">
                        <strong>Company(Customer) Name</strong>{" "}
                        <span className="pad_l10">
                          <input
                            className="form_col_input"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10910].value}
                            readOnly
                          />
                        </span>
                        <div className="caf_inner">
                          <strong>Account Code</strong>
                          <input
                            className="form_col no_top"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10911].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <p className="sign font12">
                      <strong>Authorised Signatory Details</strong>
                    </p>
                  </div>
                  <div className="over_hid auth">
                    <div className="fleft width45">
                      Name
                      <input
                        className="form_col width85"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10913].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Contact No:
                      <input
                        className="form_col width59"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10914].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Email:
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10915].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width20"> &nbsp; </div>
                    <div className="fleft width30">Product Name </div>
                    <div className="fleft width45">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10916].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_5t">
                    <div className="fleft width20"> &nbsp; </div>
                    <div className="fleft width30">
                      Pilot Number/Resource ID/ITFS number
                    </div>
                    <div className="fleft width45">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10917].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="margin0 font15">TYPE OF CHANGE REQUEST ORDER</p>
                  <div className="over_hid pad_t15">
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          1 Company Name Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Company Name Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          2 Customer contact Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Customer contact Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          3 Billing Adress change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Billing Adress change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          4 Site Change (PRI){" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Site Change (PRI)") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          5 Activation of Service{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Activation of Service") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          6 Subscribe to service - Excel based Billing for PRI{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Subscribe to service - Excel based Billing for PRI"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          7 Pilot Number Change (PRI){" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Pilot Number Change (PRI)") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          8 DID range change/extension (PRI)
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("DID range change/extension (PRI)") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          9 Change in landing number (TFS/UAN/ITFS){" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Change in landing number (TFS/UAN/ITFS)"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          10 Change in Call Distribution (TFS/UAN/ITFS)
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Change in Call Distribution (TFS/UAN/ITFS)"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          11 IVR configuration (TFS/UAN/ITFS)
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("IVR configuration (TFS/UAN/ITFS)") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          12 Subscribe to service - ILD Dial out (ACS)
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Subscribe to service - ILD Dial out (ACS)"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          13 Subscribe to service - Recording (ACS)
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Subscribe to service - Recording (ACS)"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          14 Subscribe to service - Operator Assistance (ACS)
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Subscribe to service - Operator Assistance (ACS)"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          15 Addition of CPE{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Addition of CPE") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          16 Deletion of CPE
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Deletion of CPE") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          17 Change in number of ports (VCS){" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Change in number of ports (VCS)") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          {"18 Change in Resolution (SD<-->HD<-->FHD)(VCS)"}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Change in Resolution (SD<-->HD<-->FHD)(VCS)"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          {"19 Change in QoS( Shared<-->Dedicated) (VCS)"}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf(
                              "Change in QoS( Shared<-->Dedicated) (VCS)"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          20 Interface Change- LM provider change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Interface Change- LM provider change") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          21 Addition of E1 (PRI){" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Addition of E1 (PRI)") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          22 Plan Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCrfDataupdated[10918].value
                            .split("|")
                            .indexOf("Plan Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Company Name Change
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10920].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10921].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Customer Contact Details Change
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing telephone Number
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10923].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New telephone Number
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10924].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing email address
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10925].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New email address
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10926].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Billing Address Change
                  </p>
                  <p className="font12 margin0"> New Billing Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10929].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10930].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10931].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10932].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10933].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10934].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col width90"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10935].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10936].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Site Shifting Installation Address Change
                  </p>
                  <p className="font12"> New Installation Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person Name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10939].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10940].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10941].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10942].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10943].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10944].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col width90"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10945].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10946].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Activation of Service- PRI
                  </p>
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      {" "}
                      Service Required{" "}
                    </div>
                    <div className="fleft ">
                      {this.state.priCrfDataupdated[10949].value === "STD" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      STD
                    </div>
                    <div className="fleft pad_l10 ">
                      {this.state.priCrfDataupdated[10949].value === "ISD" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ISD{" "}
                    </div>
                    <div className="fleft pad_l10">
                      {this.state.priCrfDataupdated[10949].value ===
                      "Outbound" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Outbound{" "}
                    </div>
                    <div className="fleft pad_l10">
                      {this.state.priCrfDataupdated[10949].value ===
                      "Inbound" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Inbound{" "}
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe to services
                  </p>
                  <div className="check over_hid">
                    <div className="fleft width25">
                      {" "}
                      Excel Billing (PRI)
                      <div className="flright pad_r10">
                        {this.state.priCrfDataupdated[10950].value
                          .split("|")
                          .indexOf("Excel Billing (PRI)") >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="fleft width25 ">
                      {" "}
                      ILD Dial Out (ACS)
                      <div className="flright pad_r10">
                        {this.state.priCrfDataupdated[10950].value
                          .split("|")
                          .indexOf("ILD Dial Out (ACS)") >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="fleft width22">
                      {" "}
                      Call Recording (ACS)
                      <div className="flright pad_r10">
                        {this.state.priCrfDataupdated[10950].value
                          .split("|")
                          .indexOf("Call Recording (ACS)") >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="fleft">
                      {" "}
                      Operator Assistance (ACS) (Specify rate)
                      {this.state.priCrfDataupdated[10950].value
                        .split("|")
                        .indexOf("Operator Assistance (ACS) (Specify rate)") >=
                      0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width25">
                      {" "}
                      Change in number of ports(VCS)
                      <div className="flright pad_r10">
                        {this.state.priCrfDataupdated[10950].value
                          .split("|")
                          .indexOf("Change in number of ports(VCS)") >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="fleft width25 ">
                      {" "}
                      Change in Quality of Service(VCS)
                      <div className="flright pad_r10">
                        {this.state.priCrfDataupdated[10950].value
                          .split("|")
                          .indexOf("Change in Quality of Service(VCS)") >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="fleft width22">
                      {" "}
                      Change in Resolution(VCS)
                      <div className="flright pad_r10">
                        {this.state.priCrfDataupdated[10950].value
                          .split("|")
                          .indexOf("Change in Resolution(VCS)") >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Pilot Number Change
                  </p>

                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          <strong>Existing Pilot Number</strong>
                        </div>
                        <div className="fleft width40">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10952].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          <strong>New Pilot Number</strong>
                        </div>
                        <div className="fleft width40">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10953].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          <strong>New DID Range Details</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          DID block Quantity
                        </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10955].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          {" "}
                          DID Range Required{" "}
                        </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10956].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width10"> &nbsp; </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t5">
                    <div className="">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          <strong>&nbsp;</strong>
                        </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value=""
                          />
                        </div>
                        <div className="fleft width10"> &nbsp; </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    DID range Addition / Extension
                  </p>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          <strong>Pilot Number</strong>
                        </div>
                        <div className="fleft width40">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10958].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          <strong>New DID Range Details</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          Total DID block Quantity
                        </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10960].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          {" "}
                          Current DID Range{" "}
                        </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10961].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width10"> &nbsp; </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="over_hid pad_t5">
                    <div className="">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          DID Range Required
                        </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={this.state.priCrfDataupdated[10962].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width10"> &nbsp; </div>
                        <div className="fleft width30">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value=""
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in Landing Number
                  </p>
                  <div className="check over_hid">
                    <div className="fleft wid120p pad_t3">
                      New Default Number
                    </div>
                    <div className="fleft width14 pad_r10">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10964].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 pad_l10 pad_t3">
                      {" "}
                      On busy{" "}
                    </div>
                    <div className="fleft width14">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10965].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width14 textcenter pad_l10 pad_t3">
                      {" "}
                      On No Answer{" "}
                    </div>
                    <div className="fleft width14">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10966].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t10">
                    <div className="fleft wid120p pad_t3">
                      On Route Failure{" "}
                    </div>
                    <div className="fleft width14 pad_r10">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10967].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 pad_l10 pad_t3">
                      {" "}
                      On over run{" "}
                    </div>
                    <div className="fleft width14">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[10968].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in Call Distribution
                  </p>
                  <div className="check over_hid pad_t10">
                    <div className="fleft width25">
                      <div className="changecol">
                        <p className="margin0">
                          <strong>Day of the month</strong>
                        </p>
                        <div className="check over_hid pad_t10">
                          <div className="fleft width40 pad_l10 pad_t3">
                            Monday{" "}
                          </div>
                          <div className="fleft width50">
                            {this.state.priCrfDataupdated[10970].value ===
                            "Monday" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="check over_hid pad_t3">
                          <div className="fleft width40 pad_l10 pad_t3">
                            Tuesday{" "}
                          </div>
                          <div className="fleft width50">
                            {this.state.priCrfDataupdated[10970].value ===
                            "Tuesday" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="check over_hid pad_t3">
                          <div className="fleft width40 pad_l10 pad_t3">
                            Wednesday{" "}
                          </div>
                          <div className="fleft width50">
                            {this.state.priCrfDataupdated[10970].value ===
                            "Wednesday" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="check over_hid pad_t3">
                          <div className="fleft width40 pad_l10 pad_t3">
                            Thursday{" "}
                          </div>
                          <div className="fleft width50">
                            {this.state.priCrfDataupdated[10970].value ===
                            "Thursday" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="check over_hid pad_t3">
                          <div className="fleft width40 pad_l10 pad_t3">
                            Friday{" "}
                          </div>
                          <div className="fleft width50">
                            {this.state.priCrfDataupdated[10970].value ===
                            "Friday" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="check over_hid pad_t3">
                          <div className="fleft width40 pad_l10 pad_t3">
                            Saturday{" "}
                          </div>
                          <div className="fleft width50">
                            {this.state.priCrfDataupdated[10970].value ===
                            "Saturday" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="check over_hid pad_t3">
                          <div className="fleft width40 pad_l10 pad_t3">
                            Sunday{" "}
                          </div>
                          <div className="fleft width50">
                            {this.state.priCrfDataupdated[10970].value ===
                            "Sunday" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fleft width25">
                      <div className="changecol">
                        <p className="margin0">
                          <strong>Time of the Day</strong>
                        </p>
                        <div className="check over_hid pad_t10">
                          <div className="fleft width49">
                            <div className="pad_t10">Start Time</div>
                            <div className="pad_t10">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value={utils.formatDDMMYYYY(
                                  this.state.priCrfDataupdated[10972].value
                                )}
                                readOnly
                              />
                            </div>
                            <div className="pad_t10">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                            <div className="pad_t10">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="flright width49">
                            <div className="pad_t10">End Time</div>
                            <div className="pad_t10">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value={utils.formatDDMMYYYY(
                                  this.state.priCrfDataupdated[10973].value
                                )}
                                readOnly
                              />
                            </div>
                            <div className="pad_t10">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                            <div className="pad_t10">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fleft width25">
                      <div className="changecol">
                        <p className="margin0">
                          <strong>Percentage Routing</strong>
                        </p>
                        <div className="check over_hid pad_t10">
                          <div className="fleft width49">
                            <div className="pad_t10">Landing Number</div>
                            <div className="pad_t10">
                              1 &nbsp;
                              <input
                                className="form_col width70"
                                type="text"
                                name=""
                                value={
                                  this.state.priCrfDataupdated[10975].value
                                }
                                readOnly
                              />
                            </div>
                            <div className="pad_t10">
                              2 &nbsp;
                              <input
                                className="form_col width70"
                                type="text"
                                name=""
                                value=""
                              />
                            </div>
                            <div className="pad_t10">
                              3 &nbsp;
                              <input
                                className="form_col width70"
                                type="text"
                                name=""
                                value=""
                              />
                            </div>
                            <div className="pad_t10">
                              4 &nbsp;
                              <input
                                className="form_col width70"
                                type="text"
                                name=""
                                value=""
                              />
                            </div>
                          </div>
                          <div className="flright width40 pad_l10">
                            <div className="pad_t10">Percentage</div>
                            <div
                              style={{ paddingTop: "23px" }}
                              className="pad_t10"
                            >
                              <input
                                className="form_col width50"
                                type="text"
                                name=""
                                value={
                                  this.state.priCrfDataupdated[10976].value
                                }
                                readOnly
                              />
                            </div>
                            <div className="pad_t10">
                              <input
                                className="form_col width50"
                                type="text"
                                name=""
                                value=""
                              />
                            </div>
                            <div className="pad_t10">
                              <input
                                className="form_col width50"
                                type="text"
                                name=""
                                value=""
                              />
                            </div>
                            <div className="pad_t10">
                              <input
                                className="form_col width50"
                                type="text"
                                name=""
                                value=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fleft width25">
                      <div className="changecol">
                        <p className="margin0">
                          <strong>IVR Routing</strong>
                        </p>
                        <div className="check over_hid">
                          <div className="fleft width40 textcenter sectionlines">
                            <div className="pad_5t">Selection</div>
                            <div className="pad_5t">1 </div>
                            <div className="pad_5t">2 </div>
                            <div className="pad_5t">3 </div>
                            <div className="pad_5t">4 </div>
                            <div className="pad_5t">5 </div>
                            <div className="pad_5t">6 </div>
                          </div>
                          <div className="flright width55">
                            <div className="pad_5t">Landing Number</div>
                            <div className="pad_5t">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value={
                                  this.state.priCrfDataupdated[10978].value
                                }
                                readOnly
                              />
                            </div>
                            <div className="pad_5t">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                            <div className="pad_5t">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                            <div className="pad_5t">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                            <div className="pad_5t">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                            <div className="pad_5t">
                              <input
                                className="form_col width80"
                                type="text"
                                name=""
                                value=""
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Addition of CPE
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="domestictable">
                    <div className="center_70per">
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>Sr. No.</td>
                            <td>Type (Standard/Other)</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Version</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>{this.state.priCrfDataupdated[10980].value}</td>
                            <td>{this.state.priCrfDataupdated[10981].value}</td>
                            <td>{this.state.priCrfDataupdated[10982].value}</td>
                            <td>{this.state.priCrfDataupdated[10983].value}</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Deletion of CPE
                  </p>
                  <div className="domestictable">
                    <div className="center_70per">
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>Sr. No.</td>
                            <td>Type (Standard/Other)</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Version</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>{this.state.priCrfDataupdated[10985].value}</td>
                            <td>{this.state.priCrfDataupdated[10986].value}</td>
                            <td>{this.state.priCrfDataupdated[10987].value}</td>
                            <td>{this.state.priCrfDataupdated[10988].value}</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Interface Change with Media / LM Change
                  </p>
                  <div className="pad_t10">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.priCrfDataupdated[10990].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10990].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10990].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10990].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10990].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10990].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10990].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10990].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.priCrfDataupdated[10991].value ===
                        "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}{" "}
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        V.35
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10991].value ===
                        "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10991].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10991].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10991].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10991].value ===
                        "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                      <div className="fleft pad_r15">
                        {this.state.priCrfDataupdated[10991].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10991].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">Existing Media</span>
                        {this.state.priCrfDataupdated[10993].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.priCrfDataupdated[10993].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.priCrfDataupdated[10993].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.priCrfDataupdated[10993].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10993].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        {" "}
                        <span className="pad_r5">New Media</span>
                        {this.state.priCrfDataupdated[10994].value ===
                        "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.priCrfDataupdated[10994].value ===
                        "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.priCrfDataupdated[10994].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r5">
                        {this.state.priCrfDataupdated[10994].value ===
                        "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10994].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10996].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10997].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Addition of E1 (PRI)
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 wid120p">
                        Existing No of E1{" "}
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10999].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 wid120p">
                        New No of E1 Required
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[11000].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Plan Change
                  </p>
                  <div className="check over_hid ">
                    <div className="fleft wid120p pad_t3"> Existing Plan </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[11002].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t10">
                    <div className="fleft wid120p pad_t3"> New Plan </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={this.state.priCrfDataupdated[11003].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    COMMENTS
                  </p>
                  <div className="cmt_spl">
                    {" "}
                    Comments/ Special Considerations:{" "}
                    {this.state.priCrfDataupdated[11004].value}
                  </div>
                  <div className="over_hid">
                    <div className="fleft width65 pad_t10">
                      <p className="margin0">
                        <strong>Declaration</strong>
                      </p>
                      <p className="margin0">
                        I / We confirm having received, read and understood the
                        Product Terms & Conditions (provided overleaf) and the
                        General Terms & Conditions. I / We further confirm that
                        the tariff plan selected and applicable rates form part
                        of this Agreement (as defined herein) and I / We agree
                        to abide by the Applicable Law in force and also any
                        statutory amendments, or new legistlations as may be
                        enacted from time to time, in so far as they realte to
                        the services. I / We hereby declare and confirm that the
                        above information provided by us is true and correct in
                        all respects and I / We hereby undertake to be bound by
                        the same.{" "}
                      </p>
                      <p className="margin0 pad_5t">
                        Authorised Signatory's Name
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[10913].value}
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_t15">
                        <div className="fleft width30">
                          <p className="margin0">Designation</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.priCrfDataupdated[11007].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Place</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.priCrfDataupdated[11008].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Date</p>
                          <p className="margin0">
                            <input
                              className="form_col width96per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.priCrfDataupdated[11009].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                      <p className="margin0 pad_5t">Account Manager Name</p>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[11011].value}
                          readOnly
                        />
                      </p>
                      <p className="margin0 pad_20t">
                        Account Manager Circle Office
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col  wid120p cmnt_input"
                          type="text"
                          name=""
                          value={this.state.priCrfDataupdated[11012].value}
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_20t">
                        <div className="fleft width50">
                          <p className="margin0">Channel Partner Name</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={this.state.priCrfDataupdated[11014].value}
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="flright width30">
                          <p className="margin0">Channel Partner Code</p>
                          <p className="margin0">
                            <input
                              className="form_col width92per cmnt_input"
                              type="text"
                              name=""
                              value={this.state.priCrfDataupdated[11015].value}
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flright width30">
                      <div style={{ padding: "5px" }} className="signate">
                        {firstCaps != "" ? (
                          <div className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          Signature of the Customer / Authorised signatory with
                          in this box only (In case of institutinal, please
                          affix office/company seal){" "}
                        </div>
                      </div>
                      <div
                        style={{ padding: "5px", height: "120px" }}
                        className="signate"
                      >
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            height="90px"
                            width="100px"
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Signature of Account Manager"}
                          />
                        ) : (
                          ""
                        )}
                        <div>Signature of the Account Manager </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter">
                    <strong>
                      Vodafone Idea Limited (formerly Idea Cellular Limited) An
                      Aditya Birla Group and Vodafone Partnership Merger Co CIN
                      No. L32100GJ1996PLC030976Registered Office: Suman Tower,
                      Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
                    </strong>
                  </p>
                  <div className="signate float_img">
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </page>
            {this.state.excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">Annexure to PRI CRF :- </p>
                  </div>
                  <JsonToTable json={this.state.excelJson} />
                  <div>
                    <p class="font10 " style={{ marginTop: "20px" }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div class="fleft width50 pad_t10">
                    <p class="marb5">Account Manager Name</p>
                    <p class="margin0">
                      <input
                        class="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        readonly=""
                        value={this.state.priCrfDataupdated[11011].value}
                      />
                    </p>
                    <div className="signate">
                      <div className="signate_img input_sign">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Account Manager Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div className="over_hid ">
                        Authorised Signatory Name
                        {
                          <input
                            className="form_col inputext"
                            type="text"
                            name="Name_CD"
                            value={this.state.priCrfDataupdated[10913].value}
                            readOnly
                          />
                        }
                      </div>
                    </div>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        {
                          <img
                            src={
                              this.state.imageData != null &&
                              this.state.imageData != ""
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        }
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      PriCrfContentView = this.state.priCrfData ? (
        <div style={{ paddingTop: "30%", marginLeft: "45%" }}>
          <PulseLoader color={"#000000"} loading={true} />
        </div>
      ) : (
        <div
          style={{ marginTop: "200px", height: "300px", textAlign: "center" }}
        >
          {" "}
          CRF Form not submitted yet.
        </div>
      );
    }
    return <div>{PriCrfContentView}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PriCrfForm);
