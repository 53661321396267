import Axios from "axios";
import CryptoJS from "crypto-js";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import config from "react-global-configuration";
import AxiosAPI from "../../axios_ext";
import CustomHeader from "../../hoc/Header/Header";
import * as utils from "../../shared/Utils";
import "../FormEntry/FormLayout.scss";

import GreneLogo from "../../assets/images/logo.png";
import VILLogo from "../../assets/vil/vi_logo_large_white.png";
import GreneLoader from "../../components/GreneLoader/GreneLoader";

import "./CafCrfModalView.scss";
import * as CafUtility from "../Activity/CafCrfModalView/cafCrf.util";
import ACCafForm from "../Activity/CafCrfModalView/CafView/AcCaf/AC";
import ACCafFormOld from "../Activity/CafCrfModalView/CafView/AcCaf/ACOld";
import BulkSMSCafForm from "../Activity/CafCrfModalView/CafView/BulkSmsCaf/BulkSMS";
import BulkSMSCafFormOld from "../Activity/CafCrfModalView/CafView/BulkSmsCaf/BulkSMSOld";
import CafForm from "../Activity/CafCrfModalView/CafView/CafForm/cafForm";
import CafFormOld from "../Activity/CafCrfModalView/CafView/CafForm/cafFormOld";
import IllCAFForm from "../Activity/CafCrfModalView/CafView/ILLCaf/IllCafForm";
import IllCAFFormOld from "../Activity/CafCrfModalView/CafView/ILLCaf/IllCafFormOld";
import IplcCafForm from "../Activity/CafCrfModalView/CafView/IplcCaf/IplcCafForm";
import IplcCafFormOld from "../Activity/CafCrfModalView/CafView/IplcCaf/IplcCafFormOld";
import ITFSCafForm from "../Activity/CafCrfModalView/CafView/ItfsCaf/ITFS";
import ITFSCafFormOld from "../Activity/CafCrfModalView/CafView/ItfsCaf/ITFSOld";
import MSSCafForm from "../Activity/CafCrfModalView/CafView/MssCaf/MssCafForm";
import MSSCafFormOld from "../Activity/CafCrfModalView/CafView/MssCaf/MssCafFormOld";
import NplcCAFForm from "../Activity/CafCrfModalView/CafView/NplcCaf/NplcCafForm";
import NplcCAFFormOld from "../Activity/CafCrfModalView/CafView/NplcCaf/NplcCafFormOld";
import PriIPCentrixCafForm from "../Activity/CafCrfModalView/CafView/PreCentricCaf/PriIPCentrixCafForm";
import PriIPCentrixCafFormOld from "../Activity/CafCrfModalView/CafView/PreCentricCaf/PriIPCentrixCafFormOld";
import PRICAFForm from "../Activity/CafCrfModalView/CafView/PriCaf/PRICafForm";
import PRICAFFormOld from "../Activity/CafCrfModalView/CafView/PriCaf/PRICafFormOld";
import PriSipCafForm from "../Activity/CafCrfModalView/CafView/PriSipCaf/PriSipCafForm";
import PriSipCafFormOld from "../Activity/CafCrfModalView/CafView/PriSipCaf/PriSipCafFormOld";
import SuperWifiCafForm from "../Activity/CafCrfModalView/CafView/SuperWifiCaf/superWifiCafForm";
import SuperWifiCafFormOld from "../Activity/CafCrfModalView/CafView/SuperWifiCaf/superWifiCafFormOld";
import TFSCafForm from "../Activity/CafCrfModalView/CafView/TfsCaf/TFS";
import TFSCafFormOld from "../Activity/CafCrfModalView/CafView/TfsCaf/TFSOld";
import VCCafForm from "../Activity/CafCrfModalView/CafView/VcCaf/VC";
import VCCafFormOld from "../Activity/CafCrfModalView/CafView/VcCaf/VCOld";
import VLTCafForm from "../Activity/CafCrfModalView/CafView/VltCaf/VLT";
import VLTCafFormOld from "../Activity/CafCrfModalView/CafView/VltCaf/VLTOld";
import VSDMCafForm from "../Activity/CafCrfModalView/CafView/VsdmCaf/VSDM";
import VSDMCafFormOld from "../Activity/CafCrfModalView/CafView/VsdmCaf/VSDMOld";
import IllCRFForm from "../Activity/CafCrfModalView/CrfView/ILLCrf/IllCrfForm";
import IllCRFFormOld from "../Activity/CafCrfModalView/CrfView/ILLCrf/IllCrfFormOld";
import MplsCRFForm from "../Activity/CafCrfModalView/CrfView/MplsCrf/CrfForm";
import MplsCRFFormOld from "../Activity/CafCrfModalView/CrfView/MplsCrf/CrfFormOld";
import PriCRFForm from "../Activity/CafCrfModalView/CrfView/PriCrf/PriCrfForm";
import PriCRFFormOld from "../Activity/CafCrfModalView/CrfView/PriCrf/PriCrfFormOld";
import {
  default as NFLCrfForm,
  default as NPLCCRFForm,
} from "../Activity/CafCrfModalView//CrfView/NplcCrf/NPLCCrfForm";
import {
  default as NFLCrfFormOld,
  default as NPLCCRFFormOld,
} from "../Activity/CafCrfModalView//CrfView/NplcCrf/NPLCCrfFormOld";


import ReactToPrint, { PrintContextConsumer } from "react-to-print";
class CafPreview extends Component {
  state = {
    urlData: {},
    queueData: {
      activity_id: 0,
    },
    loadForm: false,
    navLinks: null,
    navLinksSet: false,
  };
  openLink = async (ApprJson, RevJson, cafApprove, cafReview) => {
    if (this.state.urlData.show_headers === 1) {
      if (!this.state.navLinksSet) {
        let FinalJsonApprUrlId = await utils.getUrlId("", ApprJson);
        let FinalJsonRevUrlId = await utils.getUrlId("", RevJson);
        this.setState({
          ...this.state,
          loadForm: true,
          navLinksSet: true,
          navLinks: [
            <ReactToPrint content={() => this.componentRef}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <button className="btn" onClick={handlePrint} data-tip="Print">
                    Print {/* <MdPrint color="#fff" size="20px" /> */}
                  </button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>,
            <a
              href={
                Axios.defaults.extUrl +
                "#/forms/entry/" +
                btoa(JSON.stringify(FinalJsonApprUrlId))
              }
              target="_blank"
            >
              {cafApprove}
            </a>,
            <a
              href={
                Axios.defaults.extUrl +
                "#/forms/entry/" +
                btoa(JSON.stringify(FinalJsonRevUrlId))
              }
              target="_blank"
            >
              {cafReview}
            </a>,
          ],
        });
      }
    }
  };
  async componentDidMount() {
    localStorage.setItem("x-grene-header-disable", true);
    localStorage.setItem("x-grene-form-view", true);
    localStorage.setItem("x-form-pass", true);
    let FormUrlData = JSON.parse(atob(this.props.match.params.formsdata));
    if (FormUrlData) {
      localStorage.setItem("x-grene-form-view", true);
      localStorage.setItem("x-form-pass", true);
    }

    if (FormUrlData.hasOwnProperty("uuid")) {
      let requestData = {};
      requestData.device_os_id = 5;
      requestData.access_key_type_id = 1;
      requestData.asset_id = 31347;
      requestData.auth_asset_id = 31347;
      requestData.asset_token_auth = "05986bb0-e364-11e8-a1c0-0b6831833754";
      requestData.url_id = FormUrlData.url_id;
      requestData.url_uuid = FormUrlData.uuid;
      requestData.organization_id = FormUrlData.organization_id;
      requestData.preview = true;

      localStorage.setItem("preview", "true");
      await AxiosAPI.post("/url/parameters/lookup", requestData).then((res) => {
        FormUrlData = JSON.parse(res.data.response[0].url_form_data);
      });
    }

    let requestData = {};
    requestData.device_os_id = 5;
    requestData.access_key_type_id = 1;
    requestData.asset_id = FormUrlData.asset_id;
    requestData.auth_asset_id = FormUrlData.auth_asset_id;
    requestData.asset_token_auth = !!FormUrlData.asset_token_auth
      ? FormUrlData.asset_token_auth
      : FormUrlData.auth_token;
    AxiosAPI.post("aws_resources/credentials/fetch", requestData).then(
      (res) => {
        const credentials = res.data.response;
        let decryptThis = credentials[0].access_key_inline_data;
        var bytes = CryptoJS.AES.decrypt(
          decryptThis.toString(),
          "lp-n5^+8M@62"
        );
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        decryptedData = JSON.parse(decryptedData);
        config.set(
          {
            s3_access_key: decryptedData.access_key,
            s3_secret_key: decryptedData.secret_key,
          },
          {
            freeze: false,
          }
        );

        this.setState({
          urlData: FormUrlData,
        });
      }
    );
  }
  render() {
    if (isMobile) {
      return (
        <div>
          <h3>Please open in Desktop browser</h3>
        </div>
      );
    }
    let CafModule = "";
    let cafApprove = "CAF Approve";
    let cafReview = "CAF Revision";

    if (this.state.urlData !== null) {
      let isOldView =
        CafUtility.getBranding(this.state.urlData.activity_datetime_created) ===
        CafUtility.CAF_VERSION.OLD_BRANDING
          ? true
          : false;
      if (this.state.urlData.form_transaction_id > 0) {
        const userData = {
          asset_id: this.state.urlData.auth_asset_id,
        };
        const formData = {
          form_id: this.state.urlData.form_id,
          id: this.state.urlData.form_id,
          form_transaction_id: this.state.urlData.form_transaction_id,
          order_form_transaction_id: this.state.urlData
            .order_form_transaction_id,
        };
        const props = {
          orgIdExt: this.state.urlData.organization_id,
          accountIdExt: this.state.urlData.accountId,
          workforceIdExt: this.state.urlData.workforce_id,
          authTokenExt: this.state.urlData.asset_token_auth,
          userDataId: userData,
          formData: formData,
          external: true,
          urlData: this.state.urlData,
        };
        const JsonForAppr = { ...this.state.urlData };
        const JsonForRev = { ...this.state.urlData };
        if (this.state.urlData.activity_type_id === 134562) {
          //MPLS CAF
          CafModule = isOldView ? (
            <CafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <CafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1064; // 882;
          JsonForRev.form_id = 1069; // 982;
        } else if (this.state.urlData.activity_type_id === 134564) {
          //MPLS CRF
          CafModule = isOldView ? (
            <MplsCRFFormOld queueData={this.props.activity} {...props} />
          ) : (
            <MplsCRFForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1112; // 882;
          JsonForRev.form_id = 1202; // 982;
          cafApprove = "CRF Approve";
          cafReview = "CRF Revision";
        } else if (this.state.urlData.activity_type_id === 134565) {
          // ILL CAF
          CafModule = isOldView ? (
            <IllCAFFormOld queueData={this.props.activity} {...props} />
          ) : (
            <IllCAFForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1115; // 882;
          JsonForRev.form_id = 1207; // 982;
        } else if (this.state.urlData.activity_type_id === 134566) {
          // ILL CRF
          CafModule = isOldView ? (
            <IllCRFFormOld queueData={this.props.activity} {...props} />
          ) : (
            <IllCRFForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1306; // 882;
          JsonForRev.form_id = 1309; // 982;
          cafApprove = "CRF Approve";
          cafReview = "CRF Revision";
        } else if (this.state.urlData.activity_type_id === 134576) {
          // PRI CAF/TEF
          CafModule = isOldView ? (
            <PRICAFFormOld queueData={this.props.activity} {...props} />
          ) : (
            <PRICAFForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1363; // 882;
          JsonForRev.form_id = 1364; // 982;
        } else if (this.state.urlData.activity_type_id === 134569) {
          // NPLC CAF
          CafModule = isOldView ? (
            <NplcCAFFormOld queueData={this.props.activity} {...props} />
          ) : (
            <NplcCAFForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1256; // 882;
          JsonForRev.form_id = 1257; // 982;
        } else if (this.state.urlData.activity_type_id === 134573) {
          // NPLC CRF
          CafModule = isOldView ? (
            <NPLCCRFFormOld queueData={this.props.activity} {...props} />
          ) : (
            <NPLCCRFForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1330; // 882;
          JsonForRev.form_id = 1344; // 982;
          cafApprove = "CRF Approve";
          cafReview = "CRF Revision";
        } else if (this.state.urlData.activity_type_id === 134575) {
          // PRI/SIP/IP CRF
          CafModule = isOldView ? (
            <PriCRFFormOld queueData={this.props.activity} {...props} />
          ) : (
            <PriCRFForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1350; // 882;
          JsonForRev.form_id = 1354; // 982;
          cafApprove = "CRF Approve";
          cafReview = "CRF Revision";
        } else if (this.state.urlData.activity_type_id === 134574) {
          // IPLC CAF
          CafModule = isOldView ? (
            <IplcCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <IplcCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1289; // 882;
          JsonForRev.form_id = 1291; // 982;
        } else if (this.state.urlData.activity_type_id === 134583) {
          // Superwifi CAF
          CafModule = isOldView ? (
            <SuperWifiCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <SuperWifiCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1374; // 882;
          JsonForRev.form_id = 1375; // 982;
        } else if (this.state.urlData.activity_type_id === 142431) {
          // PRI SIP
          CafModule = isOldView ? (
            <PriSipCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <PriSipCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1563; // 882;
          JsonForRev.form_id = 1595; // 982;
        } else if (this.state.urlData.activity_type_id === 142432) {
          // PRI IPC
          CafModule = isOldView ? (
            <PriIPCentrixCafFormOld
              queueData={this.props.activity}
              {...props}
            />
          ) : (
            <PriIPCentrixCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1571; // 882;
          JsonForRev.form_id = 1596; // 982;
        } else if (this.state.urlData.activity_type_id === 133892) {
          // NFL AC
          CafModule = isOldView ? (
            <ACCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <ACCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1692; // 882;
          JsonForRev.form_id = 1798; // 982;
        } else if (this.state.urlData.activity_type_id === 133893) {
          // NFL TFS
          CafModule = isOldView ? (
            <TFSCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <TFSCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1699; // 882;
          JsonForRev.form_id = 1799; // 982;
        } else if (this.state.urlData.activity_type_id === 133894) {
          // New NFL - ITFS
          CafModule = isOldView ? (
            <ITFSCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <ITFSCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1706; // 882;
          JsonForRev.form_id = 1800; // 982;
        } else if (this.state.urlData.activity_type_id === 133895) {
          // NFL VLT
          CafModule = isOldView ? (
            <VLTCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <VLTCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1712; // 882;
          JsonForRev.form_id = 1801; // 982;
        } else if (this.state.urlData.activity_type_id === 133896) {
          // NFL VC
          CafModule = isOldView ? (
            <VCCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <VCCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1718; // 882;
          JsonForRev.form_id = 1802; // 982;
        } else if (this.state.urlData.activity_type_id === 133897) {
          // New NFL - Bulk SMS
          CafModule = isOldView ? (
            <BulkSMSCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <BulkSMSCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1724; // 882;
          JsonForRev.form_id = 1803; // 982;
        } else if (this.state.urlData.activity_type_id === 133898) {
          // NFL VSDM
          CafModule = isOldView ? (
            <VSDMCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <VSDMCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1730; // 882;
          JsonForRev.form_id = 1804; // 982;
        } else if (this.state.urlData.activity_type_id === 133899) {
          // NFL CRF
          CafModule = isOldView ? (
            <NFLCrfFormOld queueData={this.props.activity} {...props} />
          ) : (
            <NFLCrfForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 1756; // 882;
          JsonForRev.form_id = 1758; // 982;
          cafApprove = "CRF Approve";
          cafReview = "CRF Revision";
        } else if (this.state.urlData.activity_type_id === 145268) {
          // MSS CAF
          CafModule = isOldView ? (
            <MSSCafFormOld queueData={this.props.activity} {...props} />
          ) : (
            <MSSCafForm queueData={this.props.activity} {...props} />
          );

          JsonForAppr.form_id = 2192; // 882;
          JsonForRev.form_id = 2278; // 982;
          cafApprove = "CAF Approve";
          cafReview = "CAF Revision";
        }
        if (!this.state.navLinksSet) {
          this.openLink(JsonForAppr, JsonForRev, cafApprove, cafReview);
        }
      }
    }
    if (!CafModule) {
      return <GreneLoader show={true} />;
    }
    return (
      <div
        className={`preview ${
          this.state.urlData.organization_id === 868 ? " vil" : ""
        }`}
      >
      
        <div className="preview-header" style={{ height: "100px" }}>
          <img
            style={{ width: "100px", margin: "0 25px" }}
            src={`
						${this.state.urlData.organization_id === 868 ? VILLogo : GreneLogo}
            `}
            alt="Logo"
          />
          <div className="header-actions">{this.state.navLinks}</div>
        </div>
        <div className="preview-container" style={{ padding: "0" }} ref={(el) => (this.componentRef = el)}>
        {/* <>test</> */}
          {CafModule}
        </div>
      </div>
    );
  }
}

export default CafPreview;
