import React, { Component } from "react";
import cafFooter from "../../../../assets/vil/caf-footer.jpg";
import vilLogo from "../../../../assets/vil/vil_logo.svg";
import "./vil-branding.css";

export default class CafLastPage extends Component {
  render() {
    return (
      <page className="cover-page-last">
        <div className="last-page-container">
          <div className="logo">
            <div className="vil">
              <img src={vilLogo} alt="vil logo" />
            </div>
          </div>
          <div className="page-footer">
            <h4 className="footer-title">
              Total Communication Solutions from Vi <sup>TM</sup> business
            </h4>
            <div className="product-container">
              <img src={cafFooter} alt="" className="product-image" />
            </div>
            <p className="footer-title">www.MyVi.in/business</p>
          </div>
        </div>
      </page>
    );
  }
}
