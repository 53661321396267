import * as actionTypes from './actionTypes';
import Axios from 'axios';
import Axiosweb from '../../axios_ext';
import AxiosIp from '../../axios_ip';
import Axiospublic from '../../axios_public';
import * as utils from '../../shared/Utils';
import CryptoJS from 'crypto-js';
import config from 'react-global-configuration';
import * as actions from './index';


export const getUserIpSuccess = (ipData) => {
    return {
        type: actionTypes.GET_USER_IP_SUCCESS,
        ipData: ipData
    }
}

export const getUserIp = () => {
    return dispatch => {
        AxiosIp.get("?format=json")
            .then(res => {
                const IpData = res.data.ip;
                dispatch(getUserIpSuccess(IpData))
            })
    }
}

export const getUserCoordsSuccess = (coords) => {
    return {
        type: actionTypes.GET_USER_COORDS_SUCCESS,
        coordsData: coords
    }
}

export const getUserCoords = (coords) => {
    return dispatch => {
        dispatch(getUserCoordsSuccess(coords))
    }
}