import React,{Component} from 'react';
import {Document,Page} from 'react-pdf';

class PdfViewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = ({numPages}) => {
    this.setState({numPages});
  }
  componentDidMount() {

  }

  render() {
    const {numPages} = this.state;
    console.log("data value",this.props.dataPdf);
    const pageArray = [];
    if(numPages) {
      for(let i = 1; i <= numPages; i++) {
        pageArray.push(
          <div className="page">
            <div style={{padding: '0px'}} className="subpage">
              <Page pageNumber={i} />
              <p>Page {i} of {numPages}</p>
            </div>
          </div>
        )
      }
    }
    return (
      <div className="book">

        <Document
          file={{data: this.props.dataPdf}}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >

          {pageArray}
        </Document>


      </div>

    );
  }
}

export default PdfViewer;
