import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import "../../VodafoneIdea.css";
import ReactToPrint from "react-to-print";
import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import Axios from "axios";
import AxiosWeb from "../../../../../axios_ext";
import { JsonToTable } from "react-json-to-table";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class CrfForm extends Component {
  state = {
    crfData: false,
    stopdidmount: 0,
    crfDataupdated: 1,
    imageData: null,
    imageType: null,
    acctMngSignImageData: null,
    acctMngSignImageType: null,
    custCompanySeal: null,
    excelJson: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };

  buildSubmittedFormsListParams = (activityId) => {
    return {
      organization_id: this.props.orgId,
      account_id: this.props.accId,
      workforce_id: this.props.workforceId,
      asset_id: this.props.userData.asset_id,
      asset_token_auth: this.props.authToken,
      form_id: 0,
      activity_id: activityId,
      message_unique_id: utils.getMessageUniqueId(this.props.userData.asset_id),
      start_from: 0,
      limit_value: 50,
    };
  };

  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1112,
      field_id: 8584,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };
    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";

        let data;
        if (res.data.response.length > 0) {
          data = JSON.parse(res.data.response[0].data_entity_inline)
            .form_submitted;
        } else {
          data = [];
        }
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 8584) {
            companySeal = fieldObj.field_value;
          }

          console.log("fieldObj:: " + fieldObj.field_id);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
        });
        console.log("fieldObj.field_value:: " + this.state.custCompanySeal);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    console.log("In crfForm Component Did mount");
    this.getCustomerCompanySeal();

    let value = 1109; // Mpls CRF form Id
    let orderform = 1074;

    if (this.props.external && this.props.external === true) {
      // console.log('In IF Condition');
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        crfData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 11746
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      //console.log('In ELSE Condition');
      const submittedFormArry = this.props.submittedForms;
      const indexCrf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('indexCrf : ', indexCrf);
      if (indexCrf > -1) {
        //console.log('In if of indexCrf')
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCrf]),
          "view"
        );
        this.setState({
          ...this.state,
          crfData: true,
        });
      }
      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 11746
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }

    //this.props.onFormFieldsFetch(this.buildFormFieldFetchParam(1058));
    //this.props.onSubmittedFormsFetch(this.buildSubmittedFormsListParams(this.props.queueData.activity_id));
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("8375") &&
      this.state.stopdidmount === 0
    ) {
      //console.log('submitted forms list',this.props.fieldList);
      // console.log('this.props.fieldList : ', this.props.fieldList)
      let x = this.props.fieldList;
      console.log("********************");
      console.log("Company Seal URL : ", this.state.custCompanySeal);
      console.log("Account Manager Signature URL : ", x[8561].value);
      console.log("********************");

      let companySealUrl = "";
      let BucketName = "";
      let KeyName = "";
      let temp = "";

      if (
        this.state.custCompanySeal != null &&
        this.state.custCompanySeal != ""
      ) {
        companySealUrl = this.state.custCompanySeal || "";
        //BucketName = this.state.custCompanySeal.slice(8, 25);
        //KeyName = this.state.custCompanySeal.slice(43);
        [BucketName, KeyName, temp] = await utils.getBucketKeyFileName(
          this.state.custCompanySeal
        );
      }
      console.log("companySealUrl : ", companySealUrl);

      if (companySealUrl !== "") {
        this.s3.getObject(
          {
            Bucket: BucketName,
            Key: KeyName,
          },
          async (err, data) => {
            if (err) {
              console.log("error 1", err);

              this.setState({
                ...this.state,
                crfDataupdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 1 ", data);
              console.log("data is 1 ", data.Body);

              let AcctManagerSignUrl = x[8561].value;
              //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
              //const KeyName1 = AcctManagerSignUrl.slice(43);

              let BucketName1, KeyName1;
              [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
                AcctManagerSignUrl
              );

              this.s3.getObject(
                {
                  Bucket: BucketName1,
                  Key: KeyName1,
                },
                (err, data1) => {
                  if (err) {
                    console.log("error 2", err);

                    this.setState({
                      ...this.state,
                      crfDataupdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      stopdidmount: 1,
                    });
                  } else {
                    console.log("data is 2 ", data1);
                    console.log("data is 2 ", data1.Body);

                    this.setState({
                      ...this.state,
                      crfDataupdated: this.props.fieldList,
                      imageData: data.Body,
                      imageType: data.ContentType,
                      acctMngSignImageData: data1.Body,
                      acctMngSignImageType: data1.ContentType,
                      stopdidmount: 1,
                    });
                  }
                }
              );
            }
          }
        );
      } else {
        let AcctManagerSignUrl = x[8561].value;
        //const BucketName1 = AcctManagerSignUrl.slice(8, 25);
        //const KeyName1 = AcctManagerSignUrl.slice(43);

        let BucketName1, KeyName1;
        let temp = "";

        [BucketName1, KeyName1, temp] = await utils.getBucketKeyFileName(
          AcctManagerSignUrl
        );

        this.s3.getObject(
          {
            Bucket: BucketName1,
            Key: KeyName1,
          },
          (err, data1) => {
            if (err) {
              console.log("error 2", err);

              this.setState({
                ...this.state,
                crfDataupdated: this.props.fieldList,
                stopdidmount: 1,
              });
            } else {
              console.log("data is 2 ", data1);
              console.log("data is 2 ", data1.Body);

              this.setState({
                ...this.state,
                crfDataupdated: this.props.fieldList,
                acctMngSignImageData: data1.Body,
                acctMngSignImageType: data1.ContentType,
                stopdidmount: 1,
              });
            }
          }
        );
      }
    }
  }
  render() {
    let multibox_data = null;
    //multibox_data = (''+this.state.crfDataupdated[8373].value).split("\|");

    let crfContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );
    if (
      this.state.crfDataupdated != null &&
      this.state.crfDataupdated !== "" &&
      this.state.stopdidmount === 1
    ) {
      //console.log('this.state.crfDataupdated  : ', this.state.crfDataupdated);
      let x = this.state.crfDataupdated;
      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }
      //console.log('dataSet  : ', dataSet);

      let customerSignSplit = this.state.crfDataupdated[8558].value.split(" ");
      let firstCaps;
      let secondBold;
      let Signcss;
      let Overlapcss = "signate_mplscrf_in float_img";
      if (customerSignSplit.length > 1) {
        firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
        secondBold = customerSignSplit["1"];
        Signcss = "signate_img input_sign";
      } else {
        firstCaps = customerSignSplit["0"];
        secondBold = "";
        Signcss = "signate_img input_sign under_line";
      }
      console.log(firstCaps, "console.log(firstCaps);");
      if (this.props.external && this.props.external === true) {
        Overlapcss = "signate_mplscrf_ext float_img";
      }

      crfContentView = (
        <div>
          <div className="action_area" style={{ padding: "20px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsStyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsStyle="info"
              >
                Print Preview
              </a>
            )}

            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>

          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section1">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <div className="pad_20t">
                    <div className="over_hid">
                      <div className="pd_t5">
                        {" "}
                        <span className="textcenter width70 fleft font15 mplhed">
                          MPLS Change Request Form
                        </span>
                        <div className="caf">
                          CAF ID
                          <input
                            className="form_col"
                            type="text"
                            name=""
                            value={this.state.crfDataupdated[8375].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="over_hid mar_t-5">
                      <div className="pd_t5">
                        <strong>Company(Customer) Name</strong>{" "}
                        <span className="pad_l10">
                          <input
                            className="form_col_input"
                            type="text"
                            name=""
                            value={this.state.crfDataupdated[8367].value}
                            readOnly
                          />
                        </span>
                        <div className="caf_inner">
                          <strong>Account Code</strong>
                          <input
                            className="form_col no_top"
                            type="text"
                            name=""
                            value={this.state.crfDataupdated[8368].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <p className="sign font12">
                      <strong>Authorised Signatory Details</strong>
                    </p>
                  </div>
                  <div className="over_hid auth">
                    <div className="fleft width45">
                      Name
                      <input
                        className="form_col width85"
                        type="text"
                        name=""
                        value={this.state.crfDataupdated[8376].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Contact No:
                      <input
                        className="form_col width59"
                        type="text"
                        name=""
                        value={this.state.crfDataupdated[8377].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Email:
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.crfDataupdated[8378].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t10">
                    <div className="fleft width45 textcenter">
                      {" "}
                      Feasibility ID (FR ID){" "}
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.crfDataupdated[8370].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_5t">
                    <div className="fleft width45 textcenter">
                      {" "}
                      Please mention circuit id.
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.crfDataupdated[8371].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_5t">
                    <div className="fleft width45 textcenter">
                      Please mention VOX ID
                    </div>
                    <div className="fleft width45 textcenter">
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.crfDataupdated[8372].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="margin0 font15">TYPE OF CHANGE REQUEST ORDER</p>
                  {console.log(
                    " SIGNATURE1 : ",
                    "" + this.state.crfDataupdated[8558].value
                  )}
                  {console.log(
                    " SIGNATURE2 : ",
                    "" + this.state.crfDataupdated[8561].value
                  )}
                  {console.log(
                    " TYPE OF ORDER : ",
                    ("" + this.state.crfDataupdated[8373].value).indexOf(
                      "|CIR Bandwidth Upgrade with interface change|"
                    )
                  )}
                  <div className="over_hid pad_t15">
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          1 Company Name Change{" "}
                        </div>
                        <div className="fleft">
                          {console.log(
                            "XXXXXXXXXXXX" +
                              this.state.crfDataupdated[8373].value.split("|")
                          )}
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Company Name Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          2 Customer contact Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Customer Contact Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>

                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          3 Billing Adress change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Billing Address Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          4 CIR Bandwidth Upgrade - same interface{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf(
                              "CIR Bandwidth Upgrade - same interface"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          5 CIR Bandwidth Upgrade with interface change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf(
                              "CIR Bandwidth Upgrade with interface change"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          6 CIR Bandwidth Downgrade- Same Interface{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf(
                              "CIR Bandwidth Downgrade- Same Interface"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          7 CIR Bandwidth Downgrade- Interface change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf(
                              "CIR Bandwidth Downgrade- Interface change"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          8 Interface Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Interface Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          9 Interface Change- Media Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Interface Change- Media Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          10 Interface Change- LM provider change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Interface Change- LM provider change") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          11 Change in className of Service{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Change in Class of Service") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          12 Change in Topology{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Change in Topology") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          13 Change in Protocol{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Change in Protocol") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          14 Change in CPE- Add/ Remove{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Change in CPE- Add/ Remove") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fleft width45 pad_l5per">
                      <div className="over_hid">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          15 Feature Change- Multicast{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Feature Change- Multicast") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          16 Site Shifting{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Site Shifting") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          17 Billing Periodicity Change{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Billing Periodicity Change") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          18 Subscribe Managed Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Subscribe Managed Services") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          19 Un Subscribe Managed Services{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Un Subscribe Managed Services") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          20 Change of Managed Services Variant{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Change of Managed Services Variant") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          21 Change in SLA Type{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Change in SLA Type") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          22 SSL VPN - Change in Domain Name{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("SSL VPN - Change in Domain Name") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          23 SSL VPN - Change in number of concurrent users{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf(
                              "SSL VPN - Change in number of concurrent users"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          24 IP Sec VPN - Change in port bandwidth{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("IP Sec VPN - Change in port bandwidth") >=
                          0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          25 Dial Up VPN - Change in port bandwidth{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf(
                              "Dial Up VPN - Change in port bandwidth"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          26 2G 3G API Solution - Change in port bandwidth{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf(
                              "2G 3G API Solution - Change in port bandwidth"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          27 Change in Self care service variant{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Change in CPE- Add/ Remove") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="over_hid pad_t2">
                        <div className="fleft width80 pad_t2">
                          {" "}
                          28 Revision in charges{" "}
                        </div>
                        <div className="fleft">
                          {this.state.crfDataupdated[8373].value
                            .split("|")
                            .indexOf("Revision in charges") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Company Name Change
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8380)
                            ? this.state.crfDataupdated[8380].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New Name of company
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8381)
                            ? this.state.crfDataupdated[8381].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">&nbsp;</div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Customer Contact Details Change
                  </p>

                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing telephone Number
                    </div>
                    <div className="fleft width49">
                      {" "}
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8383)
                            ? this.state.crfDataupdated[8383].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New telephone Number
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8384)
                            ? this.state.crfDataupdated[8384].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Existing email address
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8385)
                            ? this.state.crfDataupdated[8385].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      New email address
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8386)
                            ? this.state.crfDataupdated[8386].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Billing Address Change
                  </p>
                  <p className="font12 margin0"> New Billing Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8389)
                            ? this.state.crfDataupdated[8389].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8390)
                                ? this.state.crfDataupdated[8390].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8391)
                                ? this.state.crfDataupdated[8391].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8392)
                            ? this.state.crfDataupdated[8392].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8393)
                                ? this.state.crfDataupdated[8393].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8394)
                                ? this.state.crfDataupdated[8394].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8395)
                                ? this.state.crfDataupdated[8395].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8396)
                            ? this.state.crfDataupdated[8396].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>

                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Site Shifting Installation Address Change
                  </p>
                  <p className="font12 margin0"> New Installation Address</p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">
                      Contact Person name
                    </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8399)
                            ? this.state.crfDataupdated[8399].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">Designation</div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8400)
                                ? this.state.crfDataupdated[8400].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3 textcenter">
                          <span className="">Dept. Name</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8401)
                                ? this.state.crfDataupdated[8401].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3">Address</div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8402)
                            ? this.state.crfDataupdated[8402].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft wid120p pad_t3"> &nbsp; </div>
                    <div className="fleft width81">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="over_hid pad_t15">
                    <div className="fleft wid50">
                      <div className="check over_hid ">
                        <div className="fleft wid120p pad_t3">
                          City / Village / Post Office
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8403)
                                ? this.state.crfDataupdated[8403].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Pin</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col widthfull"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8404)
                                ? this.state.crfDataupdated[8404].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fleft wid25">
                      <div className="check over_hid ">
                        <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                          <span className="">Telephone</span>
                        </div>
                        <div className="fleft width63">
                          <input
                            className="form_col width90"
                            type="text"
                            name=""
                            value={
                              this.state.crfDataupdated.hasOwnProperty(8405)
                                ? this.state.crfDataupdated[8405].value
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid ">
                    <div className="fleft wid120p pad_t3 text_r">
                      Landmark &nbsp; &nbsp;{" "}
                    </div>
                    <div className="fleft width63">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8406)
                            ? this.state.crfDataupdated[8406].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Upgrade - Same Interface
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width20 pad_t3">
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8408)
                              ? this.state.crfDataupdated[8408].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                      <div className="fleft width25 pad_t3 pad_l50">
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8409)
                              ? this.state.crfDataupdated[8409].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Downgrade - Same Interface
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width20 pad_t3">
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8411)
                              ? this.state.crfDataupdated[8411].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                      <div className="fleft width25 pad_t3 pad_l50">
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8412)
                              ? this.state.crfDataupdated[8412].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Upgrade with Interface / LM / Media Change
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width20 pad_t3">
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8414)
                              ? this.state.crfDataupdated[8414].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                      <div className="fleft width25 pad_t3 pad_l50">
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8415)
                              ? this.state.crfDataupdated[8415].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {console.log(
                          " 8416 VALUE : ",
                          this.state.crfDataupdated[8416].value
                        )}
                        {this.state.crfDataupdated[8416].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8416].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8416].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8416].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8416].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8416].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8417].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8417].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8417].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8417].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8417].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8417].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">
                        <span>Existing Media</span>
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8418].value === "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8418].value === "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8418].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8418].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">
                        <span>New Media</span>
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8419].value === "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8419].value === "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8419].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8419].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8421)
                              ? this.state.crfDataupdated[8421].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8422)
                              ? this.state.crfDataupdated[8422].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CIR Bandwidth Downgrade with Interface / LM / Media Change
                  </p>
                  <div className={Overlapcss}>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width20 pad_t3">
                        Existing Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8426)
                              ? this.state.crfDataupdated[8426].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                      <div className="fleft width25 pad_t3 pad_l50">
                        New Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8427)
                              ? this.state.crfDataupdated[8427].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8428].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8428].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8428].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8428].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8428].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8428].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8429].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8429].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8429].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8429].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8429].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8429].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">Existing Media</div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8430].value === "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8430].value === "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8430].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8430].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">New Media</div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8431].value === "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8431].value === "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8431].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8431].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8433)
                              ? this.state.crfDataupdated[8433].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8434)
                              ? this.state.crfDataupdated[8434].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Interface Change{" "}
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8435].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8435].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8435].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8435].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8435].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8435].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8436].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8436].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8436].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8436].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8436].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8436].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Interface Change with Media / LM Change
                  </p>
                  <div className="pad_t10">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8438].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8438].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8438].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8438].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8438].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8438].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8438].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft width130px pad_t3">
                        New Interface{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8439].value === "V.35" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        V.35
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8439].value === "G.703" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        G.703
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8439].value ===
                        "Ethernet Electrical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Electrical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8439].value === "BNC" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        BNC
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8439].value ===
                        "Ethernet Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8439].value === "Optical" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Optical
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8439].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Media{" "}
                      </div>
                      <div className="fleft pad_r25">Existing Media</div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8441].value === "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8441].value === "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8441].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8441].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                      <div className="fleft pad_r25">New Media</div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8442].value === "Copper" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Copper
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8442].value === "Fiber" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Fiber
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8442].value === "UBR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        UBR
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8442].value === "Other" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Other
                      </div>
                      <div className="fleft width12">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pad_5t">
                    <div className="check gstcheck over_hid">
                      <div className="fleft pad_r25 pad_t3">
                        Interface Change with Change in Last Mile provider
                      </div>
                      <div className="fleft wid36">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          Existing LM Provider{" "}
                        </span>
                        <input
                          className="form_col width56im"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8444)
                              ? this.state.crfDataupdated[8444].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft wid25">
                        {" "}
                        <span className="pad_5t fleft pad_r5">
                          New LM Provider
                        </span>
                        <input
                          className="form_col width47im"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8445)
                              ? this.state.crfDataupdated[8445].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    CoS Change
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width15 pad_t3">
                        {" "}
                        Existing CoS Type{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8447].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft">
                        {this.state.crfDataupdated[8447].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width15 pad_t3"> New CoS Type </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8448].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft">
                        {this.state.crfDataupdated[8448].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                    </div>
                  </div>
                  <p className="margin0 pad_l10">
                    If COS Type is customized, enter COS split in percentage
                  </p>
                  <div className="check over_hid pad_t10">
                    <div className="fleft width6 pad_t3"> </div>
                    <div className="fleft width10 pad_r15">
                      {" "}
                      CoS 1<br></br>
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8450)
                            ? this.state.crfDataupdated[8450].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 pad_r15">
                      CoS 2<br></br>
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8451)
                            ? this.state.crfDataupdated[8451].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 pad_r15">
                      CoS 3 <br></br>
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8452)
                            ? this.state.crfDataupdated[8452].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 pad_r15">
                      {" "}
                      CoS 4 <br></br>
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8453)
                            ? this.state.crfDataupdated[8453].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 pad_r15">
                      {" "}
                      Total<br></br>
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8454)
                            ? this.state.crfDataupdated[8454].value
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft pad_t15">
                      {" "}
                      (Total should be 100%){" "}
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Topology Change
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        Existing Topology{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8456].value === "Hub" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Hub
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8456].value === "Spoke" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Spoke
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8456].value ===
                        "any-to-any" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        any-to-any
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid">
                      <div className="fleft width130px pad_t3">
                        New Topology{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8457].value === "Hub" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Hub
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8457].value === "Spoke" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Spoke
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8457].value ===
                        "any-to-any" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        any-to-any
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Protocol change
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft wid30per pad_t3">
                        Existing Protocol / Encapsulation{" "}
                      </div>
                      <div className="fleft wid20per">
                        {this.state.crfDataupdated[8459].value === "IP" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        IP
                      </div>
                      <div className="fleft wid20per">
                        {this.state.crfDataupdated[8459].value ===
                        "Ethernet" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet
                      </div>
                      <div className="fleft wid20per">
                        {this.state.crfDataupdated[8459].value ===
                        "HDLC/ PPP" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        HDLC/ PPP
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid">
                      <div className="fleft wid30per pad_t3">
                        New Protocol / Encapsulation{" "}
                      </div>
                      <div className="fleft wid20per">
                        {this.state.crfDataupdated[8460].value === "IP" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        IP
                      </div>
                      <div className="fleft wid20per">
                        {this.state.crfDataupdated[8460].value ===
                        "Ethernet" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Ethernet
                      </div>
                      <div className="fleft wid20per">
                        {this.state.crfDataupdated[8460].value ===
                        "HDLC/ PPP" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        HDLC/ PPP
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Addition of CPE
                  </p>
                  <div
                    className="domestictable"
                    align="left"
                    style={{ width: "75%" }}
                  >
                    <table
                      width="70%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8463)
                              ? this.state.crfDataupdated[8463].value
                              : ""}
                          </td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8464)
                              ? this.state.crfDataupdated[8464].value
                              : ""}
                          </td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8465)
                              ? this.state.crfDataupdated[8465].value
                              : ""}
                          </td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8466)
                              ? this.state.crfDataupdated[8466].value
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Deletion of CPE
                  </p>

                  <div
                    className="domestictable"
                    align="left"
                    style={{ width: "75%" }}
                  >
                    <table
                      width="70%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8468)
                              ? this.state.crfDataupdated[8468].value
                              : ""}
                          </td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8469)
                              ? this.state.crfDataupdated[8469].value
                              : ""}
                          </td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8470)
                              ? this.state.crfDataupdated[8470].value
                              : ""}
                          </td>
                          <td>
                            {this.state.crfDataupdated.hasOwnProperty(8471)
                              ? this.state.crfDataupdated[8471].value
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="signate float_img">
                      {firstCaps != "" ? (
                        <div style={{ padding: "10px" }} className={Signcss}>
                          {firstCaps + "  .  "}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.imageData != null &&
                      this.state.imageData != "" ? (
                        <div
                          style={{ padding: "10px" }}
                          className="signate_img"
                        >
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in Service Variant
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 pad_r25 width16">
                        {" "}
                        Existing Service Variant{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8473].value ===
                        "Layer 3 MPLS" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Layer 3 MPLS"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Layer 3 MPLS
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8473].value ===
                        "Layer 2 MPLS" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Layer 2 MPLS"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Layer 2 MPLS
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8473].value ===
                        "Multicast VPN" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Multicast VPN"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Multicast VPN
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 pad_r25 width16">
                        {" "}
                        New Service Variant{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8474].value ===
                        "Layer 3 MPLS" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Layer 3 MPLS"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Layer 3 MPLS
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8474].value ===
                        "Layer 2 MPLS" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Layer 2 MPLS"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Layer 2 MPLS
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8474].value ===
                        "Multicast VPN" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Multicast VPN"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Multicast VPN
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Billing Periodicity Change
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        Existing Billing Periodicity{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8476].value === "Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Monthly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8476].value ===
                        "Bi-Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Bi-Monthly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Bi-Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8476].value ===
                        "Quarterly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Quarterly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Quarterly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8476].value ===
                        "Half Yearly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Half Yearly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Half Yearly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8476].value ===
                        "Annually" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Annually"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Annually
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        Revised Billing Periodicity{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8477].value === "Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Monthly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8477].value ===
                        "Bi-Monthly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Bi-Monthly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Bi-Monthly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8477].value ===
                        "Quarterly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Quarterly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Quarterly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8477].value ===
                        "Half Yearly" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Half Yearly"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Half Yearly
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8477].value === "Annualy" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Annualy"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Annualy
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Subscribe Managed Services
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        Managed services required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8479].value === "Yes" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="managed services required"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20 text_r pad_r5">
                        {" "}
                        Managed Service Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8480].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8480].value === "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8480].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8480].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8480].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8480].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8480].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Un Subscribe Managed Services
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width20">
                        {" "}
                        Managed services required{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8482].value === "No" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change of Managed Services Variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        Existing Managed Service Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8484].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Change of Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8484].value === "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Change of Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8484].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Change of Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8484].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Change of Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8484].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Change of Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8484].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Change of Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8484].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Change of Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        New Managed Service Variants{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8485].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8485].value === "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8485].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8485].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8485].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8485].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8485].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in SLA Type
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width15 pad_t3">
                        {" "}
                        Existing SLA Type{" "}
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8487].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8487].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                      <div className="fleft width16 pad_5t"> New SLA Type</div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8488].value ===
                        "Standard" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Standard
                      </div>
                      <div className="fleft width16">
                        {this.state.crfDataupdated[8488].value ===
                        "Customized" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="New Managed Service Variants"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Customized
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft width20 pad_7t textcenter  pad_r5">
                        If SLA Type Customized{" "}
                      </div>
                      <div className="fleft width16 pad_r20">
                        Service Availability (%)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8490)
                              ? this.state.crfDataupdated[8490].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        MTTR (Hours)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8491)
                              ? this.state.crfDataupdated[8491].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        Latency (ms)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8492)
                              ? this.state.crfDataupdated[8492].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width12 pad_r20">
                        Packet Loss (%)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8493)
                              ? this.state.crfDataupdated[8493].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width12">
                        Jitter (ms)
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8494)
                              ? this.state.crfDataupdated[8494].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Change in Self care service variant
                  </p>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        Existing Self Care Service Variant{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8496].value === "None" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        None
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8496].value === "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8496].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8496].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8496].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8496].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8496].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <div className="pad_t5">
                    <div className="check over_hid pd_t5">
                      <div className="fleft pad_t3 width22">
                        {" "}
                        New Self Care Service Variant{" "}
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8497].value === "None" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        None
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8497].value === "Silver" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8497].value ===
                        "Silver Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Silver Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8497].value === "Gold" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8497].value ===
                        "Gold Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Gold Plus
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8497].value ===
                        "Platinum" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum
                      </div>
                      <div className="fleft pad_r25">
                        {this.state.crfDataupdated[8497].value ===
                        "Platinum Plus" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Existing Self Care Service Variant"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Platinum Plus
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    SSL VPN - Change in Domain Name
                  </p>
                  <div className="signate float_img">
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width25 pad_t3">
                        Existing Customer Domain name{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8499)
                              ? this.state.crfDataupdated[8499].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width20 pad_t3 pad_l50">
                        {" "}
                        New Customer Domain name{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8500)
                              ? this.state.crfDataupdated[8500].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    IPSEC VPN - Change in Port Bandwidth
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width25 pad_t3">
                        Existing Port Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8502)
                              ? this.state.crfDataupdated[8502].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                      <div className="fleft width20 pad_t3 pad_l50">
                        New port bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8503)
                              ? this.state.crfDataupdated[8503].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Dial Up VPN - Change in Port Bandwidth
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width25 pad_t3">
                        Existing Port Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8505)
                              ? this.state.crfDataupdated[8505].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                      <div className="fleft width20 pad_t3 pad_l50">
                        New port bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8506)
                              ? this.state.crfDataupdated[8506].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    2G 3G API solution - Change in Port Bandwidth
                  </p>
                  <div className="pad_t10">
                    <div className="check over_hid">
                      <div className="fleft width25 pad_t3">
                        Existing Port Bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8508)
                              ? this.state.crfDataupdated[8508].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                      <div className="fleft width20 pad_t3 pad_l50">
                        New port bandwidth{" "}
                      </div>
                      <div className="fleft width20">
                        <input
                          className="form_col wid60px"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8509)
                              ? this.state.crfDataupdated[8509].value
                              : ""
                          }
                          readOnly
                        />
                        <span className="pad_t5 flright">(Kbps / Mbps)</span>{" "}
                      </div>
                    </div>
                  </div>
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    Revision in Charges
                  </p>
                  <p>
                    (Only do required changes. Leave others blank if no change)
                  </p>
                  <div className="char">
                    <div className="check over_hid pad_t5">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Specify Currency{" "}
                      </div>
                      <div className="fleft width7">
                        {this.state.crfDataupdated[8512].value === "INR" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Specify Currency"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Specify Currency"
                            value=""
                            disabled
                          />
                        )}
                        INR{" "}
                      </div>
                      <div className="fleft width7">
                        {this.state.crfDataupdated[8512].value === "US$" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Specify Currency"
                            value=""
                            checked
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name="Specify Currency"
                            value=""
                            disabled
                          />
                        )}
                        US${" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        One Time(A){" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Recurring (B){" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Security Deposit ©{" "}
                      </div>
                      <div className="fleft width15 textcenter">
                        {" "}
                        Grand Total (A+B+C){" "}
                      </div>
                    </div>
                    <div className="check over_hid">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Service Rental
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8513)
                              ? this.state.crfDataupdated[8513].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8514)
                              ? this.state.crfDataupdated[8514].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8515)
                              ? this.state.crfDataupdated[8515].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8565)
                              ? this.state.crfDataupdated[8565].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        IP Address Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7">&nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8516)
                              ? this.state.crfDataupdated[8516].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8517)
                              ? this.state.crfDataupdated[8517].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8566)
                              ? this.state.crfDataupdated[8566].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        SLA Charges{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8518)
                              ? this.state.crfDataupdated[8518].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8519)
                              ? this.state.crfDataupdated[8519].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8567)
                              ? this.state.crfDataupdated[8567].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Self Care Portal Service Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8520)
                              ? this.state.crfDataupdated[8520].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8521)
                              ? this.state.crfDataupdated[8521].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8568)
                              ? this.state.crfDataupdated[8568].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed Services Charges
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8522)
                              ? this.state.crfDataupdated[8522].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8523)
                              ? this.state.crfDataupdated[8523].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8569)
                              ? this.state.crfDataupdated[8569].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Managed CPE Charges{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8524)
                              ? this.state.crfDataupdated[8524].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8525)
                              ? this.state.crfDataupdated[8525].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8570)
                              ? this.state.crfDataupdated[8570].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        CPE Rentals{" "}
                      </div>
                      <div className="fleft width7">&nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8526)
                              ? this.state.crfDataupdated[8526].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8527)
                              ? this.state.crfDataupdated[8527].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8528)
                              ? this.state.crfDataupdated[8528].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8571)
                              ? this.state.crfDataupdated[8571].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 1 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8529)
                              ? this.state.crfDataupdated[8529].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8530)
                              ? this.state.crfDataupdated[8530].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8531)
                              ? this.state.crfDataupdated[8531].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8572)
                              ? this.state.crfDataupdated[8572].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 2 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8532)
                              ? this.state.crfDataupdated[8532].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8533)
                              ? this.state.crfDataupdated[8533].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8534)
                              ? this.state.crfDataupdated[8534].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8573)
                              ? this.state.crfDataupdated[8573].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 3</div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8535)
                              ? this.state.crfDataupdated[8535].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8536)
                              ? this.state.crfDataupdated[8536].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8537)
                              ? this.state.crfDataupdated[8537].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8574)
                              ? this.state.crfDataupdated[8574].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 4 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8538)
                              ? this.state.crfDataupdated[8538].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8539)
                              ? this.state.crfDataupdated[8539].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8540)
                              ? this.state.crfDataupdated[8540].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8575)
                              ? this.state.crfDataupdated[8575].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3"> </div>
                      <div className="fleft pad_t5 pad_r5">CPE 5 </div>
                      <div className="fleft cpelist">
                        <input
                          className="form_col wid90per"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8541)
                              ? this.state.crfDataupdated[8541].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8542)
                              ? this.state.crfDataupdated[8542].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8543)
                              ? this.state.crfDataupdated[8543].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8576)
                              ? this.state.crfDataupdated[8576].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Miscellaneous Charges - 1{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8544)
                              ? this.state.crfDataupdated[8544].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8545)
                              ? this.state.crfDataupdated[8545].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8577)
                              ? this.state.crfDataupdated[8577].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        Miscellaneous Charges - 2{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8546)
                              ? this.state.crfDataupdated[8546].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8547)
                              ? this.state.crfDataupdated[8547].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8578)
                              ? this.state.crfDataupdated[8578].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        Shifting Charges{" "}
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8548)
                              ? this.state.crfDataupdated[8548].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8579)
                              ? this.state.crfDataupdated[8579].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="check over_hid pad_t7">
                      <div className="fleft width170px pad_t3">
                        {" "}
                        CPE Replacement Charges
                      </div>
                      <div className="fleft width7 pad_t5"> &nbsp; </div>
                      <div className="fleft width7"> &nbsp;</div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8549)
                              ? this.state.crfDataupdated[8549].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                      <div className="fleft width15 textcenter"> &nbsp; </div>
                      <div className="fleft width15 textcenter">
                        <input
                          className="form_col width75"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8580)
                              ? this.state.crfDataupdated[8580].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p>
                    <strong>** Taxes as applicable.</strong>
                  </p>
                </div>
              </div>
              <div className="signate float_img">
                {firstCaps != "" ? (
                  <div style={{ padding: "10px" }} className={Signcss}>
                    {firstCaps + "  .  "}
                    <span className="under_line">{secondBold}</span>
                  </div>
                ) : (
                  ""
                )}
                {this.state.imageData != null && this.state.imageData != "" ? (
                  <div style={{ padding: "10px" }} className="signate_img">
                    <img
                      src={
                        this.state.imageData != null
                          ? "data:" +
                            this.state.imageType +
                            ";base64," +
                            this.encode(this.state.imageData)
                          : ""
                      }
                      alt={"Company Seal"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                    COMMENTS
                  </p>
                  <div className="cmt_spl">
                    {" "}
                    Comments/ Special Considerations:{" "}
                    {this.state.crfDataupdated.hasOwnProperty(8551)
                      ? this.state.crfDataupdated[8551].value
                      : ""}{" "}
                  </div>
                  <div className="over_hid">
                    <div className="fleft width65 pad_t10">
                      <p className="margin0">
                        <strong>Declaration</strong>
                      </p>
                      <p className="margin0">
                        I / We confirm having received, read and understood the
                        Product Terms & Conditions (provided overleaf) and the
                        General Terms & Conditions. I / We further confirm that
                        the tariff plan selected and applicable rates form part
                        of this Agreement (as defined herein) and I / We agree
                        to abide by the Applicable Law in force and also any
                        statutory amendments, or new legistlations as may be
                        enacted from time to time, in so far as they realte to
                        the services. I / We hereby declare and confirm that the
                        above information provided by us is true and correct in
                        all respects and I / We hereby undertake to be bound by
                        the same.{" "}
                      </p>
                      <p className="margin0 pad_5t">
                        Authorised Signatory's Name
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8376)
                              ? this.state.crfDataupdated[8376].value
                              : ""
                          }
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_t15">
                        <div className="fleft width30">
                          <p className="margin0">Designation</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={
                                this.state.crfDataupdated.hasOwnProperty(8555)
                                  ? this.state.crfDataupdated[8555].value
                                  : ""
                              }
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Place</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={
                                this.state.crfDataupdated.hasOwnProperty(8556)
                                  ? this.state.crfDataupdated[8556].value
                                  : ""
                              }
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="fleft width30 mar_l20">
                          <p className="margin0">Date</p>
                          <p className="margin0">
                            <input
                              className="form_col width96per cmnt_input"
                              type="text"
                              name=""
                              value={
                                this.state.crfDataupdated.hasOwnProperty(8557)
                                  ? this.state.crfDataupdated[8557].value
                                  : ""
                              }
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                      <p className="margin0 pad_5t">Account Manager Name</p>
                      <p className="margin0">
                        <input
                          className="form_col width98per cmnt_input"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8559)
                              ? this.state.crfDataupdated[8559].value
                              : ""
                          }
                          readOnly
                        />
                      </p>
                      <p className="margin0 pad_20t">
                        Account Manager Circle Office
                      </p>
                      <p className="margin0">
                        <input
                          className="form_col  wid120p cmnt_input"
                          type="text"
                          name=""
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8560)
                              ? this.state.crfDataupdated[8560].value
                              : ""
                          }
                          readOnly
                        />
                      </p>
                      <div className="over_hid pad_20t">
                        <div className="fleft width50">
                          <p className="margin0">Channel Partner Name</p>
                          <p className="margin0">
                            <input
                              className="form_col widthfull cmnt_input"
                              type="text"
                              name=""
                              value={
                                this.state.crfDataupdated.hasOwnProperty(8562)
                                  ? this.state.crfDataupdated[8562].value
                                  : ""
                              }
                              readOnly
                            />
                          </p>
                        </div>
                        <div className="flright width30">
                          <p className="margin0">Channel Partner Code</p>
                          <p className="margin0">
                            <input
                              className="form_col width92per cmnt_input"
                              type="text"
                              name=""
                              value={
                                this.state.crfDataupdated.hasOwnProperty(8563)
                                  ? this.state.crfDataupdated[8563].value
                                  : ""
                              }
                              readOnly
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flright width30">
                      <div style={{ padding: "5px" }} className="signate">
                        {firstCaps != "" ? (
                          <div className={Signcss}>
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          Signature of the Customer / Authorised signatory with
                          in this box only (In case of institutinal, please
                          affix office/company seal){" "}
                        </div>
                      </div>
                      <div
                        style={{ padding: "5px", height: "120px" }}
                        className="signate"
                      >
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            height="90px"
                            width="100px"
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Signature of Account Manager"}
                          />
                        ) : (
                          ""
                        )}
                        <div>Signature of the Account Manager </div>
                      </div>
                      <div
                        style={{ padding: "5px", height: "120px" }}
                        className="signate"
                      >
                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <img
                            height="90px"
                            width="100px"
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        ) : (
                          ""
                        )}
                        <div>Company Seal</div>
                      </div>
                    </div>
                  </div>
                  <p className="textcenter">
                    <strong>
                      Vodafone Idea Limited (formerly Idea Cellular Limited) An
                      Aditya Birla Group and Vodafone Partnership Merger Co CIN
                      No. L32100GJ1996PLC030976Registered Office: Suman Tower,
                      Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
                    </strong>
                  </p>
                </div>
              </div>
            </page>
            {this.state.excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">
                      Annexure to MPLS CRF - Site details basis CRF number :-{" "}
                    </p>
                  </div>
                  <JsonToTable json={this.state.excelJson} />
                  <div>
                    <p class="font10 " style={{ marginTop: "20px" }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div class="fleft width50 pad_t10">
                    <p class="marb5">Account Manager Name</p>
                    <p class="margin0">
                      <input
                        class="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        readonly=""
                        value={
                          this.state.crfDataupdated.hasOwnProperty(8559)
                            ? this.state.crfDataupdated[8559].value
                            : ""
                        }
                        readOnly
                      />
                    </p>
                    <div className="signate">
                      <div className="signate_img input_sign">
                        {/* <img src={(this.state.acctMngSignImageData != null  && this.state.acctMngSignImageData != "") ? "data:"+this.state.imageType+";base64," + this.encode(this.state.acctMngSignImageData) : ""} 
              alt={"Account Manager Sign"}/> */}
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            src={
                              this.state.acctMngSignImageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Account Manager Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div className="over_hid ">
                        Authorised Signatory Name
                        <input
                          className="form_col inputext"
                          type="text"
                          name="Name_CD"
                          value={
                            this.state.crfDataupdated.hasOwnProperty(8376)
                              ? this.state.crfDataupdated[8376].value
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        <img
                          src={
                            this.state.imageData != null
                              ? "data:" +
                                this.state.imageType +
                                ";base64," +
                                this.encode(this.state.imageData)
                              : ""
                          }
                          alt={"Company Seal"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      crfContentView = this.state.crfData ? (
        <div style={{ paddingTop: "30%", marginLeft: "45%" }}>
          <PulseLoader color={"#000000"} loading={true} />
        </div>
      ) : (
        <div
          style={{ marginTop: "200px", height: "300px", textAlign: "center" }}
        >
          {" "}
          CRF Form not submitted yet.
        </div>
      );
    }
    return <div>{crfContentView}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CrfForm);
