import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import "../../VodafoneIdea.css";
import "./PRICafForm.css";
import ReactToPrint from "react-to-print";
import VodafoneImg from "../../../../../assets/images/Process/VodafoneIdea/vodafone.jpg";
import IdeaImg from "../../../../../assets/images/Process/VodafoneIdea/idealogo.jpg";
import CloudImg from "../../../../../assets/images/Process/VodafoneIdea/cloud.jpg";
import lotImg from "../../../../../assets/images/Process/VodafoneIdea/lot.jpg";
import WireImg from "../../../../../assets/images/Process/VodafoneIdea/wire.jpg";
import WirelessImg from "../../../../../assets/images/Process/VodafoneIdea/wireless.jpg";
import * as actions from "../../../../../store/Actions/index";
import * as utils from "../../../../../shared/Utils";
import { PulseLoader } from "react-spinners";
import AWS from "aws-sdk";
import Axios from "axios";
import AxiosWeb from "../../../../../axios_ext";
import { JsonToTable } from "react-json-to-table";
import ReactExport from "react-data-export";
import callConfig from "./callConfig.json";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class PRICafFormOld extends Component {
  state = {
    priCafData: false,
    stopdidmount: 0,
    priCafDataupdated: 1,
    authorizedSignName: null,
    custCompanySeal: null,
    custPhotoGraph: null,
    companySeal: null,
    imageData: null,
    imageType: null,
    acctMngSignImageData: null,
    acctMngSignImageType: null,
    photoGraph: null,
    photoGraphImageData: null,
    photoGraphImageType: null,
    excelJson: null,
    exturlLink: null,
  };

  constructor(props) {
    super(props);
    AWS.config.update({
      accessKeyId: utils.s3AccessKey(),
      secretAccessKey: utils.s3SecretKey(),
    });
    this.s3 = new AWS.S3();
  }

  encode = (data) => {
    let str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  };

  printPage = () => {
    //let printPreviewButton = document.getElementById("print_preview_button").style.visibility = 'hidden';
    document.getElementById("print_button").style.visibility = "hidden";
    window.print();
    document.getElementById("print_button").style.visibility = "visible";
  };
  getCustomerCompanySeal = () => {
    let requestData = {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.urlData.account_id
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.urlData.auth_asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: 1363,
      field_id: 0,
      activity_id: this.props.external
        ? this.props.urlData.activity_id
        : this.props.queueData.activity_id,
      message_unique_id: utils.getMessageUniqueId(
        this.props.external
          ? this.props.urlData.asset_id
          : this.props.userData.asset_id
      ),
    };

    AxiosWeb.post("/activity/form_transaction/check/v1", requestData)
      .then((res) => {
        const formFieldData = res.data.response;
        let companySeal = "";
        let photoGraph = "";
        let authorizedSignatoryName = "";
        console.log(
          "formFieldData ::" +
            JSON.parse(res.data.response[0].data_entity_inline).form_submitted
        );
        let data = JSON.parse(res.data.response[0].data_entity_inline)
          .form_submitted;
        data.forEach(function (fieldObj, index) {
          if (fieldObj.field_id == 12868) {
            companySeal = fieldObj.field_value;
          }
          if (fieldObj.field_id == 13013) {
            photoGraph = fieldObj.field_value;
            console.log("photoGraph:: " + photoGraph);
          }
          if (fieldObj.field_id == 12867) {
            authorizedSignatoryName = fieldObj.field_value;
          }
          console.log("companySeal:: " + companySeal);
          console.log("photoGraph:: " + photoGraph);
          console.log("authorizedSignatoryName:: " + authorizedSignatoryName);
        });
        this.setState({
          ...this.state,
          custCompanySeal: companySeal,
          custPhotoGraph: photoGraph,
          authorizedSignName: authorizedSignatoryName,
        });
        console.log("fieldObj.field_value:: " + this.state.photoGraph);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    let value = 1316; // DIGITAL PRI CAF form Id
    let orderform = 1369;
    this.getCustomerCompanySeal();
    //console.log('this.props.external :: '+this.props.external);
    if (this.props.external && this.props.external === true) {
      // console.log("console the entry", this.buildFormFieldFetchParam(this.props.formData));
      this.props.onFormFieldsFetch(
        this.buildFormFieldFetchParam(this.props.formData),
        "view"
      );
      this.setState({
        ...this.state,
        priCafData: true,
      });
      if (this.props.formData.order_form_transaction_id !== 0) {
        let getOrderDetailsParams = {
          organization_id: this.props.external
            ? this.props.orgIdExt
            : this.props.orgId,
          account_id: this.props.external
            ? this.props.accountIdExt
            : this.props.accId,
          workforce_id: this.props.external
            ? this.props.workforceIdExt
            : this.props.workforceId,
          asset_id: this.props.external
            ? this.props.userDataId.asset_id
            : this.props.userData.asset_id,
          asset_token_auth: this.props.external
            ? this.props.authTokenExt
            : this.props.authToken,
          form_id: orderform,
          //activity_id : formData.activity_id,
          form_transaction_id: this.props.formData.order_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        };
        AxiosWeb.post("activity/form/validation/data", getOrderDetailsParams)
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12963
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const submittedFormArry = this.props.submittedForms;
      //console.log('submittedFormArry :: '+JSON.stringify(submittedFormArry));
      const indexCaf = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      //console.log('submittedFormArry[indexCaf] :: '+JSON.stringify(submittedFormArry[indexCaf]));

      if (indexCaf > -1) {
        this.props.onFormFieldsFetch(
          this.buildFormFieldFetchParam(submittedFormArry[indexCaf]),
          "view"
        );
        this.setState({
          ...this.state,
          priCafData: true,
        });
      }

      const indexOrder = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrder > -1) {
        AxiosWeb.post(
          "activity/form/validation/data",
          this.buildFormFieldFetchParam(submittedFormArry[indexOrder])
        )
          .then((res) => {
            console.log("Res", res);
            let ExcelIndex = res.data.response[0].activity_inline_data.findIndex(
              (res1) => res1.field_id == 12963
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl =
                res.data.response[0].activity_inline_data[ExcelIndex]
                  .field_value;
            }
            if (excelUrl !== "" && ExcelIndex > -1) {
              const excelParames = {
                bucket_url: excelUrl,
                asset_id: this.props.external
                  ? this.props.userDataId.asset_id
                  : this.props.userData.asset_id,
                asset_token_auth: this.props.external
                  ? this.props.authTokenExt
                  : this.props.authToken,
              };
              AxiosWeb.post("s3/excel_json/list", excelParames)
                .then((res) => {
                  console.log("ExcelData", res.data.response);
                  this.setState({
                    ...this.state,
                    excelJson: JSON.parse(res.data.response),
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // Print Json
      let formTransationId = 0;
      let orderformTransationId = 0;
      const indexCafNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == value
      );
      if (indexCafNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        formTransationId = submittedFormArry[indexCafNew].form_transaction_id;
      }
      const indexOrderFormNew = submittedFormArry.findIndex(
        (res) => res.submitted === true && res.id == orderform
      );
      if (indexOrderFormNew > -1) {
        //console.log("caf data",submittedFormArry[indexCaf]);
        orderformTransationId =
          submittedFormArry[indexOrderFormNew].form_transaction_id;
      }
      const JsonStrReviewCAF = {
        organization_id: this.props.orgId,
        account_id: this.props.accId,
        workforce_id: this.props.workforceId,
        asset_id: this.props.userData.asset_id,
        auth_asset_id: 31347,
        asset_token_auth: "05986bb0-e364-11e8-a1c0-0b6831833754",
        activity_id: this.props.queueData.activity_id,
        activity_type_category_id: 9,
        activity_stream_type_id: 705,
        form_transaction_id: formTransationId,
        order_form_transaction_id: orderformTransationId,
        form_id: value,
        activity_type_id: this.props.queueData.activity_type_id,
        type: "approval",
        asset_first_name: this.props.userData.asset_first_name,
        asset_phone_number: this.props.userData.asset_phone_number,
        operating_asset_first_name: this.props.userData
          .operating_asset_first_name,
        show_headers: 0,
      };
      console.log("Json", JsonStrReviewCAF);
      const formattedJsonReviewCAF = btoa(JSON.stringify(JsonStrReviewCAF));
      const urlStrReview =
        Axios.defaults.extUrl + "#/forms/view/" + formattedJsonReviewCAF;
      this.setState({
        ...this.state,
        exturlLink: urlStrReview,
      });
    }
  }

  buildFormFieldFetchParam = (formData) => {
    return {
      organization_id: this.props.external
        ? this.props.orgIdExt
        : this.props.orgId,
      account_id: this.props.external
        ? this.props.accountIdExt
        : this.props.accId,
      workforce_id: this.props.external
        ? this.props.workforceIdExt
        : this.props.workforceId,
      asset_id: this.props.external
        ? this.props.userDataId.asset_id
        : this.props.userData.asset_id,
      asset_token_auth: this.props.external
        ? this.props.authTokenExt
        : this.props.authToken,
      form_id: formData.id,
      //activity_id : formData.activity_id,
      form_transaction_id: formData.form_transaction_id,
      start_from: 0,
      limit_value: 50,
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.fieldList).length > 0 &&
      this.props.fieldList.hasOwnProperty("11330") &&
      this.state.stopdidmount === 0
    ) {
      let x = this.props.fieldList;

      if (!!this.state.custPhotoGraph) {
        utils.loadImageBlob(
          this.s3,
          this.state.custPhotoGraph,
          (data, type) => {
            this.setState({
              ...this.state,
              photoGraphImageData: data,
              photoGraphImageType: type,
              stopdidmount: 1,
              priCafDataupdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          priCafDataupdated: this.props.fieldList,
        });
      }

      if (!!this.state.custCompanySeal) {
        utils.loadImageBlob(
          this.s3,
          this.state.custCompanySeal,
          (data, type) => {
            this.setState({
              ...this.state,
              imageData: data,
              imageType: type,
              stopdidmount: 1,
              priCafDataupdated: this.props.fieldList,
            });
          }
        );
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          priCafDataupdated: this.props.fieldList,
        });
      }

      if (!!x[11644] && x[11644].value) {
        // manager sign url
        let acctManagerSignUrl = x[11644].value;
        utils.loadImageBlob(this.s3, acctManagerSignUrl, (data, type) => {
          this.setState({
            ...this.state,
            acctMngSignImageData: data,
            acctMngSignImageType: type,
            stopdidmount: 1,
            priCafDataupdated: this.props.fieldList,
          });
        });
      } else {
        this.setState({
          ...this.state,
          stopdidmount: 1,
          priCafDataupdated: this.props.fieldList,
        });
      }
    }
  }

  render() {
    let planTariff = "";
    //console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
    //console.log(" IN RENDER :: " , this.state.priCafDataupdated);
    //console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
    let priCafContentView = (
      <div style={{ paddingTop: "30%" }}>
        <PulseLoader color={"#000000"} loading={true} />
      </div>
    );

    if (
      this.state.priCafDataupdated != null &&
      this.state.priCafDataupdated !== "" &&
      this.state.stopdidmount == 1
    ) {
      let x = this.state.priCafDataupdated;

      let n_l_ll_to_mobile = "";
      let n_l_ll_to_ll = "";
      let n_l_ll_to_other_mob = "";
      let n_l_ll_to_other_ll = "";
      let n_l_international = "";
      let n_s_ll_to_mobile = "";
      let n_s_ll_to_ll = "";
      let n_s_ll_to_other_mob = "";
      let n_s_ll_to_other_ll = "";
      let n_s_international = "";
      let c_l_ll_to_mobile = "";
      let c_l_ll_to_ll = "";
      let c_s_ll_to_mobile = "";
      let c_s_ll_to_ll = "";

      if (this.state.priCafDataupdated[15070].value != "") {
        planTariff = this.state.priCafDataupdated[15070].value;
        console.log("Target Object " + callConfig[planTariff].n_l_ll_to_mobile);
        n_l_ll_to_mobile = callConfig[planTariff].n_l_ll_to_mobile;
        n_l_ll_to_ll = callConfig[planTariff].n_l_ll_to_ll;
        n_l_ll_to_other_mob = callConfig[planTariff].n_l_ll_to_other_mob;
        n_l_ll_to_other_ll = callConfig[planTariff].n_l_ll_to_other_ll;
        n_l_international = callConfig[planTariff].n_l_international;
        n_s_ll_to_mobile = callConfig[planTariff].n_s_ll_to_mobile;
        n_s_ll_to_ll = callConfig[planTariff].n_s_ll_to_ll;
        n_s_ll_to_other_mob = callConfig[planTariff].n_s_ll_to_other_mob;
        n_s_ll_to_other_ll = callConfig[planTariff].n_s_ll_to_other_ll;
        n_s_international = callConfig[planTariff].n_s_international;
        c_l_ll_to_mobile = callConfig[planTariff].c_l_ll_to_mobile;
        c_l_ll_to_ll = callConfig[planTariff].c_l_ll_to_ll;
        c_s_ll_to_mobile = callConfig[planTariff].c_s_ll_to_mobile;
        c_s_ll_to_ll = callConfig[planTariff].c_s_ll_to_ll;
      } else if (this.state.priCafDataupdated[11392].value != "") {
        planTariff = this.state.priCafDataupdated[11392].value;
        n_l_ll_to_mobile = this.state.priCafDataupdated[11620].value;
        n_l_ll_to_ll = this.state.priCafDataupdated[11621].value;
        n_l_ll_to_other_mob = this.state.priCafDataupdated[11622].value;
        n_l_ll_to_other_ll = this.state.priCafDataupdated[11623].value;
        n_l_international = this.state.priCafDataupdated[11624].value;
        n_s_ll_to_mobile = this.state.priCafDataupdated[11625].value;
        n_s_ll_to_ll = this.state.priCafDataupdated[11626].value;
        n_s_ll_to_other_mob = this.state.priCafDataupdated[11627].value;
        n_s_ll_to_other_ll = this.state.priCafDataupdated[11628].value;
        n_s_international = this.state.priCafDataupdated[11629].value;
        c_l_ll_to_mobile = this.state.priCafDataupdated[11631].value;
        c_l_ll_to_ll = this.state.priCafDataupdated[11632].value;
        c_s_ll_to_mobile = this.state.priCafDataupdated[11633].value;
        c_s_ll_to_ll = this.state.priCafDataupdated[11634].value;
      }

      console.log(
        "this.state.priCafDataupdated[15070].value :: " +
          this.state.priCafDataupdated[15070].value
      );
      console.log(
        "this.state.priCafDataupdated[11392].value :: " +
          this.state.priCafDataupdated[11392].value
      );
      console.log("planTariff :: " + planTariff);

      let dataSet = [];
      let i;
      for (i in x) {
        //console.log(x[i].label + ' - ' + x[i].value);
        dataSet.push({
          label: x[i].label,
          value: x[i].value,
        });
      }
      let firstCaps = "";
      let secondBold = "";
      let Signcss;

      //console.log('this.state.authorizedSignName :: '+this.state.authorizedSignName);

      if (
        this.state.authorizedSignName != null &&
        this.state.authorizedSignName != ""
      ) {
        let customerSignSplit = this.state.authorizedSignName.split(" ");

        if (customerSignSplit.length > 1) {
          firstCaps = customerSignSplit["0"].slice(0, 1).toUpperCase();
          secondBold = customerSignSplit["1"];
          Signcss = "signate_img input_sign";
        } else {
          firstCaps = customerSignSplit["0"];
          secondBold = "";
          Signcss = "signate_img input_sign under_line";
        }
        //console.log(firstCaps,"console.log(firstCaps);");
      }

      priCafContentView = (
        <div>
          {" "}
          <div className="action_area" style={{ paddingTop: "57px" }}>
            {this.props.external && this.props.external === true ? (
              <Button id="print_button" onClick={this.printPage} bsstyle="info">
                Print
              </Button>
            ) : (
              <a
                href={this.state.exturlLink}
                id="print_preview_button"
                target="_blank"
                bsstyle="info"
              >
                Print Preview
              </a>
            )}
            <span>&nbsp;</span>
            <span>&nbsp;</span>

            {this.props.queueData ? (
              <ExcelFile
                element={
                  <a
                    style={{ cursor: "pointer" }}
                    id="print_preview_button"
                    bsStyle="info"
                  >
                    Export to Excel
                  </a>
                }
                filename={this.props.queueData.activity_title}
              >
                <ExcelSheet
                  data={dataSet}
                  name={this.props.queueData.activity_title}
                >
                  <ExcelColumn label="Name" value="label" />
                  <ExcelColumn label="Value" value="value" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ textAlign: "left" }}
            className="tab_content"
            ref={(el) => (this.componentRef = el)}
          >
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <div className="over_hid w_85">
                    <div className="">
                      <div className="textcenter">
                        Unique ID
                        <input
                          className="form_col brdr_b1 "
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="customfom textcenter margin0">
                    {" "}
                    Customer Application Form{" "}
                  </p>
                  <div className="over_hid pad_t10">
                    <div className="fleft width75">
                      <div className="">
                        <strong>Vodafone Idea Business Servies</strong>{" "}
                        <span className="pad_l10">
                          Kindly fill the form in BLOCK letters
                        </span>{" "}
                      </div>
                      <p className="ligthbg">Name Address </p>
                      <p className="auth">
                        {" "}
                        Nme of Company
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11332].value}
                          readOnly
                        />
                      </p>
                      <p className="auth">
                        Comany's Registered
                        <input
                          className="form_col width80 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11333].value}
                          readOnly
                        />
                      </p>
                      <div className="auth margin0">
                        <span className="width54px">Address</span>
                        <input
                          className="form_col width85 flright"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Landmark
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11334].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          State
                          <input
                            className="form_col width65"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11336].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width25">
                          Pincode
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11337].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landline No. +91
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11338].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="faxn">Fax No.</span>
                          <input
                            className="form_col width52 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11339].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          CIN Number
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11340].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="pad_t5">
                            Company PAN Card Number
                          </span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11341].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flright cross_fill">
                      <div className="crossign" style={{ height: "100px" }}>
                        {this.state.photoGraphImageData != null &&
                        this.state.photoGraphImageData ? (
                          <div
                            style={{
                              padding: "5px",
                              position: "relative",
                              textAlign: "center",
                            }}
                            className="signate_img"
                          >
                            <img
                              style={{ height: "90px" }}
                              src={
                                this.state.photoGraphImageData != null
                                  ? "data:" +
                                    this.state.photoGraphImageType +
                                    ";base64," +
                                    this.encode(this.state.photoGraphImageData)
                                  : ""
                              }
                              alt={"PhotoGraph with Crosssign"}
                            />
                            {firstCaps != "" ? (
                              <span
                                style={{
                                  padding: "10px",
                                  position: "absolute",
                                  left: "-20px",
                                  top: "20px",
                                }}
                                className={Signcss}
                              >
                                {firstCaps + "  .  "}
                                <span className="under_line">{secondBold}</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">Basic Organisation Details </p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <p className="margin0">
                        <strong>
                          Address 1 (Authorised Signatory Location)
                        </strong>
                      </p>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Name</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11344].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Father's/ Husbends</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11345].value}
                          readOnly
                        />
                      </div>

                      <div className="auth margin0">
                        <span className="width54px">Nationality</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11346].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Billing Address</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11347].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">&nbsp;</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landmark
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11348].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">City</span>
                          <input
                            className="form_col width70 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11349].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          State *
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11350].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">Pincode</span>
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11351].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11352].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Landline No. +91</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11353].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Email ID</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11354].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width33">
                          Designation
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11355].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Emp.Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11356].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Vehicle No.
                          <input
                            className="form_col flright width33"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11357].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p>*Mandatory for GST registered companies</p>
                      <div className="check margin0 over_hid">
                        <div className="fleft width20">
                          GSTIN
                          {this.state.priCafDataupdated[11377].value ===
                          "GSTIN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                        <div className="fleft width20">
                          UIN
                          {this.state.priCafDataupdated[11377].value ===
                          "UIN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                        <div className="fleft width20">
                          GST_ISD
                          {this.state.priCafDataupdated[11377].value ===
                          "GST_ISD" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="margin0">
                        <strong>Address 2 (Key contract Location)</strong>
                      </p>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Name</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11359].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">Address</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11360].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0">
                        <span className="width54px">&nbsp;</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          Landmark
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11361].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">City</span>
                          <input
                            className="form_col width70 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11362].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width58">
                          State *
                          <input
                            className="form_col width70"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11363].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width42">
                          <span className="pad_l10">Pincode</span>
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11364].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Mobile No. +91
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11365].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Landline No. +91</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11366].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="width54px">Email ID</span>
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11367].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width33">
                          Designation
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11368].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Emp.Code
                          <input
                            className="form_col width30"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11369].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width33">
                          Vehicle No.
                          <input
                            className="form_col flright width33"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11370].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <p className="margin0">
                        <strong>Organisation Type </strong>
                      </p>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[11371].value ===
                          "Partner Ship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Partnership
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[11371].value ===
                          "Proprietorship" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Proprietorship
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[11371].value ===
                          "Private Limited" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Private Limited{" "}
                        </div>
                        <div className="fleft pad_r3">
                          {this.state.priCafDataupdated[11371].value ===
                          "Public Sector" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Public Sector
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[11371].value ===
                          "Government" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Government
                        </div>
                      </div>
                      <div className="margin0 over_hid pad_5t">
                        <div className="fleft">
                          <span className="">
                            {this.state.priCafDataupdated[11371].value ===
                            "Others" ? (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                checked
                                readOnly
                              />
                            ) : (
                              <input
                                className="form_col"
                                type="checkbox"
                                name=""
                                value=""
                                disabled
                              />
                            )}
                            Others
                          </span>{" "}
                          <span className="auth">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15061].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid typecheck">
                        <div className="fleft width12 pad_t2">
                          <strong>Telemarketer</strong>
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[11372].value ===
                          "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[11372].value ===
                          "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <div className="auth margin0">
                        {" "}
                        If Yes, Telemarketer Registration Number
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11373].value}
                          readOnly
                        />
                      </div>
                      <div className="check margin0 over_hid typecheck">
                        <div className="fleft pad_t2">
                          <strong>OSP &nbsp; &nbsp; </strong>
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[11374].value ===
                          "Yes" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Yes
                        </div>
                        <div className="fleft width12">
                          {this.state.priCafDataupdated[11374].value ===
                          "No" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          No
                        </div>
                      </div>
                      <div className="auth margin0">
                        {" "}
                        If Yes, OSP Registration Number
                        <input
                          className="form_col width40"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11375].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="check margin0 over_hid typecheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Type
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Normal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Normal
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value === "SEZ" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SEZ
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Diploma" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diploma
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Embassy" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Embassy
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Consulate" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consulate
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Special Agency" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Special Agency
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Person/Class of Persons" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Person/Class of Persons
                    </div>
                  </div>
                  <p className="auth">
                    GSTIN / UIN / GST_ISD No
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11379].value}
                      readOnly
                    />
                  </p>
                  <div className="auth">
                    <span className="width70px in_block">
                      GST Regd. Address**
                    </span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11380].value}
                      readOnly
                    />
                  </div>
                  <div className="auth">
                    <span className="width70px in_block">State**</span>
                    <input
                      className="form_col width78"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11381].value}
                      readOnly
                    />
                  </div>
                  <p>If GST Address is diffrent from Billing Address</p>
                  <div className="check over_hid typecheck gstcheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Dept
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value === "IT" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value === "Tech" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Tech
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value ===
                      "Cust Service" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Cust Service
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value === "Legal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Legal
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value ===
                      "Normal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finanace
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value ===
                      "Purchase" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Purchase
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value === "SCM" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SCM
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11383].value ===
                      "Delivery" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Delivery
                    </div>
                    <div className="fleft width20 pad_t2">
                      Others
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[15062].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="auth namsupp">
                    Name of Supporting Document
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11384].value}
                      readOnly
                    />
                  </p>
                  <p className="gsttext">
                    (GST Certificate, SEZ Certificate, Diploma Crtificate)
                  </p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <p className="ligthbg">Proof of Identity Details</p>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Type of POI
                          <input
                            className="form_col width60"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11386].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Document No.</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11387].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width45">
                          Date of Issue
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11388].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          <span className="faxn">Place of Issue</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11389].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Issuing Authority</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11390].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 pad_5t">
                        {" "}
                        Multiple Connections (Please fill enclosed declaration)
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="ligthbg">Proof of Address Details</p>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width50">
                          Type of POA
                          <input
                            className="form_col width60"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11395].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          <span className="faxn">Document No.</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11396].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 over_hid pad_5t">
                        <div className="fleft width45">
                          Date of Issue
                          <input
                            className="form_col width50"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11397].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width55">
                          <span className="faxn">Place of Issue</span>
                          <input
                            className="form_col width45 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11398].value}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Issuing Authority</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11399].value}
                          readOnly
                        />
                      </div>
                      <div className="auth margin0 pad_5t">
                        <span className="">Existing VIL Numbers (Count)</span>
                        <input
                          className="form_col width55 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11400].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width40">
                      <span className="">Tariff Plan applied</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={planTariff}
                        readOnly
                      />
                    </div>
                    <div className="fleft width60">
                      <span className="">
                        Value Added Services applied (if any)
                      </span>
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11393].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">
                    Local Reference (For National Outstation and Foreign
                    National Customers)
                  </p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width45">
                      <span className="">Name</span>
                      <input
                        className="form_col width85 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11402].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      <span className="">Contact No.</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11403].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      <span className="">Time&Date of Call</span>
                      <input
                        className="form_col width45 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11405].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width75">
                      <span className="">Address</span>
                      <input
                        className="form_col width85 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11404].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      <span className="">
                        Calling Party's No.
                        <br />
                        <span className="fnt6">(to be filled by POS)</span>
                      </span>
                      <input
                        className="form_col width45 flright posinput"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11406].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="ligthbg">Current Requirement</p>
                  <div className="over_hid ">
                    <div className="fleft width49">
                      <div className="auth margin0 pad_5t over_hid">
                        <div className="fleft width55">
                          <span className="">No. of connections required</span>
                          <input
                            className="form_col width25 flright"
                            type="text"
                            name=""
                            value={this.state.priCafDataupdated[11408].value}
                            readOnly
                          />
                        </div>
                        <div className="flright ">
                          <span className="">NDNC Regestration Opt IN</span>
                          <input
                            className="flright"
                            type="checkbox"
                            name=""
                            value=""
                          />
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf("No commercial Call/ SMS") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          1. No commercial Call/ SMS{" "}
                        </div>
                        <div className="flright">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf(
                              "Banking/ Insurance/ Financal Produts/ Credit Card"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          2. Banking/ Insurance/ Financal Produts/ Credit Card{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf("Real Estate") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          3. Real Estate{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf("Education") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          4. Education{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf("Health") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          5. Health{" "}
                        </div>
                        <div className="flright">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf("Consumer goods and Automoblies") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          6. Consumer goods and Automoblies{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf(
                              "Communication/ Broadcasting/ Entertainment/ IT"
                            ) >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          7. Communication/ Broadcasting/ Entertainment/ IT{" "}
                        </div>
                        <div className="fleft pad_l10">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf("Tourism and Leisure") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          8. Tourism and Leisure{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid fon8_5">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11409].value
                            .split("|")
                            .indexOf("Any other category") >= 0 ? (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col width37px"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          9. Any other category{" "}
                        </div>
                      </div>
                      <p className="margin0">Type of Service</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11410].value ===
                          "Mobile Voice" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Voice{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[11410].value ===
                          "Mobile Broadband 2G 3G" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Broadbond 2G/3G/4G{" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[11410].value ===
                          "Wireline Voice (E1 DID)" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Wireline Voice (E1 DID){" "}
                        </div>
                        <div className="fleft">
                          {this.state.priCafDataupdated[11410].value ===
                          "Domestic SIP Trunk" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Domestic SIP Trunk{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11410].value ===
                          "SIP Central" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          SIP Central{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11410].value ===
                          "Domestic Toll Free" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Domestic Toll Free{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11410].value ===
                          "International Toll Free" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          International Toll Free{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11410].value ===
                          "Audio Conferencing" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Audio Conferencing{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11410].value ===
                          "Video Conferencing " ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Video Conferencing{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11410].value ===
                          "2G/3G/4G API Solution" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          2G/3G/4G API Solution{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11410].value ===
                          "GVN" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GVN{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11410].value ===
                          "Cloud Telephony" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Cloud Telephony{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11410].value ===
                          "Call Recording" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Call Recording{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11410].value ===
                          "Mobile Applications" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Applications{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft pad_t3">
                          {" "}
                          <strong>Internet of Things</strong>{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11411].value ===
                          "Connectivity" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Connectivity{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11411].value ===
                          "Managed Connectivity" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Managed Connectivity{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11411].value ===
                          "SuperIOT" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          SuperIOT{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11411].value ===
                          "Location Based Services" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Location Based Services{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11411].value ===
                          "Logistics Tracking" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Logistics Tracking{" "}
                        </div>
                        <div className="fleft pad_l_2 ">
                          {this.state.priCafDataupdated[11411].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15063].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <p className="auth">
                        Tariff Plan
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={planTariff}
                          readOnly
                        />
                      </p>
                    </div>
                    <div className="flright width49">
                      <p className="margin0">Services on Voice</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11412].value ===
                          "NCUG" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          NCUG{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11412].value ===
                          "IR" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          IR{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11412].value ===
                          "Itemised Bill" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Itemised Bill{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11412].value ===
                          "GPRS" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GPRS{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11412].value ===
                          "ISD" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          ISD{" "}
                        </div>
                        <div className="fleft pad_l_2 ">
                          {this.state.priCafDataupdated[11412].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col width60 flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15064].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <p className="margin0">Value Added Services:</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11413].value ===
                          "CCT" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          CCT{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11413].value ===
                          "MCI" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          MCI{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11413].value ===
                          "Enterprise Alerts" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Enterprise Alerts{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11413].value ===
                          "VMC" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          VMC{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11413].value ===
                          "Mobile Internet 2G/3G/4G" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Mobile Internet 2G/3G/4G{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {this.state.priCafDataupdated[11413].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[15065].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <p className="margin0">Devices</p>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11414].value ===
                          "BlackBerry" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Blackberry{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11414].value ===
                          "Smartphone" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Smartphone{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11414].value ===
                          "Tablet" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Tablet{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11414].value ===
                          "Netbook" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Netbook{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11414].value ===
                          "USB Stick" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          USB Stick{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11414].value ===
                          "Moible Wifi" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Moible Wifi{" "}
                        </div>
                      </div>
                      <div className="check margin0 over_hid font_8">
                        <div className="fleft">
                          {this.state.priCafDataupdated[11414].value ===
                          "Sharing Dock" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Sharing Dock{" "}
                        </div>
                        <div className="fleft pad_l_2">
                          {this.state.priCafDataupdated[11414].value ===
                          "GSM Gateway" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          GSM Gateway{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {this.state.priCafDataupdated[11414].value ===
                          "Others" ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[11415].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                      <br />
                      <div className="check margin0 over_hid">
                        <div className="">
                          {" "}
                          CUG Code{" "}
                          <span className="in_subput">
                            <input
                              className="form_col wid83p flright"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[11419].value}
                              readOnly
                            />
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      E-Bill Opt In (Go Green)
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width30"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11417].value}
                        readOnly
                      />
                    </div>
                    <div className="flright width50">
                      {" "}
                      E-Mail ID for sending bills
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11418].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="ligthbg">Payment Details</p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width70">
                      <span className="">
                        Name of Person Responsiable for Payments
                      </span>
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11421].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      <span className="">Mobile No. +91</span>
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11422].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid font_8">
                    <div className="fleft width30"> Billing Address</div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[11423].value ===
                      "Authorised Sign Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Authorised Sign Location
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[11423].value ===
                      "Key Contact Location" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Key Contact Location
                    </div>
                    <div className="fleft ">
                      {this.state.priCafDataupdated[11423].value ===
                      "Company Registered Address" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Company Registered Address
                    </div>
                  </div>
                  <div className="check margin0 over_hid font_8">
                    <div className="fleft pad_l_5 width30"> Payment Mode</div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[11424].value === "ECS" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ECS
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[11424].value === "SI" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SI
                    </div>
                    <div className="fleft pad_l_5">
                      {this.state.priCafDataupdated[11424].value ===
                      "CHEQUE" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CHEQUE
                    </div>
                    <div className="fleft pad_l_5 pad_5t">
                      {" "}
                      Check (for ECS and SI, please fill adational form){" "}
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft">
                      {" "}
                      Deposit Amount{" "}
                      <span className="in_subput">
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11425].value}
                          readOnly
                        />
                      </span>{" "}
                    </div>
                    <div className="fleft pad_l10">
                      {" "}
                      Deposit Type
                      {this.state.priCafDataupdated[11426].value ===
                      "Deposit" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2">
                      {" "}
                      Security
                      {this.state.priCafDataupdated[11426].value ===
                      "Security" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2">
                      {" "}
                      IR Deposit
                      {this.state.priCafDataupdated[11426].value ===
                      "IR Deposit" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft pad_l_2 ">
                      {" "}
                      Others{" "}
                      <span className="in_subput">
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[13736].value}
                          readOnly
                        />
                      </span>{" "}
                    </div>
                  </div>
                  <p className="ligthbg">Customer Declaration</p>
                  <div className="check margin0 over_hid">
                    <div className="fleft width70">
                      <div className="pad_r15_pri">
                        I/We agree to refundable security deposit and all
                        charges associated with the services selected by me/us.
                        I/We have read and terms and conditions mentioned
                        overleaf and unconditionally accept them as binding on
                        me/us. I/'We have understood all the rates. tariffs and
                        other related co it telecommunications services be
                        provided inside and outside India as applicable as on
                        this date and as amended from time to time. I/We hereby
                        undertake to pay all charges raised on account Of
                        Services availed, l/We undertake that in the event this
                        connection is used for any telemarketing purpose. I/We
                        shall be solely responsible for registration of the
                        connection with the mandated statutory authorities.
                        further declare and undertake that above information
                        provided by me/us is true and correct in all respect.
                        I/We understand that the SIM non-transferable. Any
                        misuse of SIM by customer or any other person is illegal
                        and shall be liable for criminal actions.
                      </div>
                      <div className="check margin0 over_hid pad_t10">
                        <div className="">
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                          I agree to receive a welcome mail giving details of
                          service(s) subscribed{" "}
                        </div>
                      </div>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td width="50%">
                                {firstCaps != "" ? (
                                  <div
                                    style={{
                                      padding: "10px",
                                      position: "absolute",
                                    }}
                                    className={Signcss}
                                  >
                                    {firstCaps + "  .  "}
                                    <span className="under_line">
                                      {secondBold}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="50%">
                                {this.state.imageData != null &&
                                this.state.imageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      src={
                                        this.state.imageData != null
                                          ? "data:" +
                                            this.state.imageType +
                                            ";base64," +
                                            this.encode(this.state.imageData)
                                          : ""
                                      }
                                      alt={"Company Seal"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col flright"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.priCafDataupdated[11647]
                                          .value
                                      }
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg">VIL Internet Use</p>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width60">
                      <span className="">Enterprise Description Code</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11428].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      <span className="">Large Account Code</span>
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11429].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 pad_5t over_hid">
                    <div className="fleft width60">
                      <span className="">FA/BA/PCN ID</span>
                      <input
                        className="form_col width65 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11430].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft width49">
                      <div className="check margin0 over_hid">
                        <div className="fleft width65 pad_r15_pri">
                          {" "}
                          Verification by Dealer/Agent (Distributor/ Retailer):
                          I confirm that the applicant has signed the form and
                          documents attached in my presence and that the photo
                          affixed in this form is of the applicant. I have
                          verified the documents With their respective originals
                          and certify the same to be true. I further confirm
                          that all necessary documents are in order including
                          that the signatures on the self attested photo match
                          with the signature on application form and Photo on
                          the form matches the one on the photo ID document.{" "}
                        </div>
                        <div className="flright width34">
                          {this.state.acctMngSignImageData != null &&
                          this.state.acctMngSignImageData != "" ? (
                            <img
                              height="75px"
                              width="75px"
                              src={
                                this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != ""
                                  ? "data:" +
                                    this.state.acctMngSignImageType +
                                    ";base64," +
                                    this.encode(this.state.acctMngSignImageData)
                                  : ""
                              }
                              alt={"Sign"}
                            />
                          ) : (
                            ""
                          )}
                          <div>Sign in with Seal/Stamp &nbsp; &nbsp; </div>
                          <div className="in_subput_date">
                            Date
                            <input
                              className="form_col"
                              type="text"
                              name=""
                              value={this.state.priCafDataupdated[11647].value}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flright width49">
                      <p className="ligthbg">
                        Declaration of VIL employee activating SIM
                      </p>
                      <div className="check margin0 over_hid">
                        <div className="fleft width55 pad_r15_pri">
                          {" "}
                          I hereby confirm that the required documentation Such
                          as POI/POA is available and that details of the
                          Customer is complete and have been entered in the
                          database.{" "}
                        </div>
                        <div className="flright width45">
                          <div className="activatesim">
                            <div className="check margin0 over_hid">
                              <div className="flright width70">
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <img
                                    height="25px"
                                    width="75px"
                                    src={
                                      this.state.acctMngSignImageData != null &&
                                      this.state.acctMngSignImageData != ""
                                        ? "data:" +
                                          this.state.acctMngSignImageType +
                                          ";base64," +
                                          this.encode(
                                            this.state.acctMngSignImageData
                                          )
                                        : ""
                                    }
                                    alt={"Sign"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="flright width70">
                                <div className="font7">
                                  <div className="in_subput">
                                    Employee Name
                                    <input
                                      className="form_col width40px"
                                      type="text"
                                      name=""
                                      value=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="font7 in_subput">
                                  <input
                                    className="form_col width100per"
                                    type="text"
                                    name=""
                                    value={
                                      this.state.priCafDataupdated[11643].value
                                    }
                                    readOnly
                                  />
                                </div>
                                <div className="font7">
                                  <div className="in_subput">
                                    Designation
                                    <input
                                      className="form_col width40px"
                                      type="text"
                                      name=""
                                      value=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="font7">
                                  <div className="in_subput_date">
                                    Date
                                    <input
                                      className="form_col"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.priCafDataupdated[11647]
                                          .value
                                      }
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                    Cellular Limited )<br />
                    An Adity Birla Group and Vodafone partneship
                    <br />
                    MergerCo CIN No: L32100GJ1996PLC030976
                    <br />
                    <strong>Registred Office:</strong> Suman Tower, Plot no. 18,
                    Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79 66714000
                    | F: +91 79 23232251
                    <br />
                    <strong>Circle Office Address:</strong>{" "}
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="ligthbg font8">TERMS &amp; CONDITIONS </p>
                  <p className="font10">
                    <strong>
                      The "Customer Application Form" (CAD shall mean the
                      Customer information form. to which the following
                      conditions ("Regulatory Terms") are required to be
                      provided/completed with effect from /prior to activation
                      of any Services, and continue during the term of service.
                      These are conditions specified by the Department of
                      Telecommunication ("DOT) Ministry of Communication and
                      Information Technology ("MCIT"), Government of India
                      ("Go") and are mandatory conditions. There shall be no
                      deletion or deviation from the following Regulatory Terms.
                      This CAF shall be read with the Product Specific Terms
                      which shall form the Agreement between the Parties.
                      Licensed Service Area • shall mean the area where Operator
                      is permitted to offer telecommunication services, (i)
                      Operator • shall mean VIL entity having license to operate
                      in Circle. o 'Authority' shall mean the Department of
                      Telecommunications (DOT). Telecom Regulatory Authority of
                      India (TRAI), Ministry of Communication and Information
                      Technology (MOCIT), Government of India (GOI) and includes
                      any officer of the Authority,' "Corporate Plan" shall mean
                      tariff structure devised by for Corporate Customers. •
                      'Charges' means all fees, charges/ tariffs,
                      interconnection cost and rates chargeable by VIL from time
                      to time for provisioning the Services to the Customer •
                      'Customer' shall mean Corporate organization named in the
                      CAF entering into the requisite contract comprising of
                      CAF, Verification documents etc, For Internet of Things
                      products, refer Intemet Of Things GTCs agreed by the
                      customer.{" "}
                    </strong>{" "}
                  </p>
                  <div className="over_hid">
                    <div className="fleft width49">
                      <ul className="list">
                        <li>
                          It is agreed betweet "Vodafone Idea Limited (VIL)" &
                          "Customer" as fallows;
                        </li>
                        <li>
                          1. PROVISION SERVICES: VIL agrees to provide the
                          services to the Customer subject to terms and
                          condition of the Agreement.{" "}
                        </li>
                        <li>
                          2. OBLIGATIONS OF VIL: VIL shall provide services with
                          reference to and subject to the telecommunication
                          permitting the operations of a telephony services. The
                          Customer expressly agrees that activation of the
                          Services shall be Subject to verification of the
                          particulars and documents submitted by the Customer
                          including tele-verification. The availability and
                          quality of services may be affected by factor outside
                          VIL control such as physical obstruction. geographic
                          and weather conditions and other cause of radio
                          interference or faults in other telecommunications
                          network to which network is connected. The Services
                          may be Suspended in Whole or in part at any time with
                          proper notice and in compliance to existing
                          guidelines. policies and regulations.. however if the
                          network fails or requires modifications or maintenance
                          due to a sudden or force majeure event beyond control
                          of operator. such advance notice may not be possible.
                          Please note all extra discounting promos on special
                          deals or on Corporate Plans and Closed User Group
                          (COG). Vodafone Mobile Connect (VMC). Vodafone Live
                          (VI). Blackberry (BB) and other Data services along
                          with respective discounts on usage will be activated
                          minimum of 72hrs from date of number activation or the
                          request as the case may be, The allotment of a number
                          will be made by VIL in its absolute discretion. In the
                          event of any provisioned SIM ( where a Service
                          involves a provisioning of SIM) being lost or stolen.
                          VIL will replace the SIM card as soon as it is
                          reasonable & practicable. subject to the recovery of
                          any charges for the same. VIL reserve the right to
                          apply a monthly financial limit and such other
                          conditions for charges incurred by the Customer and to
                          demand interim advance payment and also suspend
                          (and/or disconnect access to the services if such
                          limit is exceeded with proper intimation and in
                          compliance to existing guidelines, policies and
                          regulations VIL has the right to check the credential
                          Of the Customer including the Customer financial
                          standing & to use the services of any person or agency
                          for such purposes. Any waiver. concession or extra
                          time allowed or granted by VIL to the Customer is
                          limited the specific circumstances in which it was
                          given and the same shall not effect VIL's right under
                          this agreement in any ways.
                        </li>
                        <li>
                          3. OBLIGATIONS OF THE CUSTOMER: The Customer shall
                          ensure (i) that it signs all bulk connection
                          declarations and forms as are created pursuant to DOT
                          guidelines on bulk connections; (ii) that it has the
                          users comply with the said regulations and conclude
                          the tele-verification process by calling the call
                          centre at 1 17 so as to enable VIL to activate his/her
                          subscribed service plan: (iii) thet it shall not use
                          or cause or allow others to use the Services for any
                          improper. immoral or unlawful purpose including in any
                          manner which may jeopardise or impair the operation Of
                          the Network and/or the Services. Customer agrees to
                          inform VIL immediately and confirm the same in writing
                          if the Equipment and/or the SIM Card is lost. stolen
                          or damaged. The Customer will remain liable for all
                          Charges incurred until the SIM Card is de-activated by
                          VIC (iv) to furnish correct and complete information
                          and documents as required by VIL from time to time.
                          The Services agreed to be provided by VIL shall always
                          be subject to verification Of the Customer'S
                          credentials and documents and if at any time, any
                          information and/or documents furnished by the Customer
                          Mare found incorrect or incomplete or suspicious. VIL
                          shall be entitled to suspend/terminate the Service
                          forthwith without any further notice. In case any
                          change of address of the Customer or end user. the
                          Customer agrees to inform VIL in writing with regard
                          to change in address and furnish documents in support
                          Of such new address. In case during any time during
                          the subscription, in case the Customer does not inform
                          with regard to change in his address or end user then
                          VIL reserves its right to disconnect the Services
                          and/or cancel the connection without any notice in
                          this regard and VIL shall not be liable to the
                          Customer/end user in any manner whatsoever.{" "}
                        </li>
                        <li>
                          4. DOT LICENSING AND VERIFICATION OF SUBSCRIBE: (i)
                          VIL may be requited to disclose any information
                          pertaining to the Customer/Subscriber to any statutory
                          / regulatory / security authority, financial
                          institution or bank and as per regulation. VIL can do
                          so without any prior intimation to the Customer: (ii)
                          Customer shall indemnify and keep indemnified VIL for
                          all and any claims. proceedings or actions brought
                          against VIL arising out of any breach of the Customer
                          of the Regulatory Terms: (iii) VIL may use. process
                          and/or transfer Personal Information: (iv) in
                          connection with the provision Of Services; (v) to
                          incorporate Personal Information into databases
                          controlled by VIL for the purpose of account
                          administration, billing and reconciliation.
                          operational maintenance and support activities. fraud
                          detection and prevention. and customer and market
                          analysis and reporting. (vi) The Customer shall use
                          the Services only for the purposes Specified by the
                          Customer in the Agreement / Customer Application Form
                          (or Customer Order Form or Customer Information Form
                          hereinafter CAF/COF/CIF) and shall not be used for or
                          connected to any telecommunication service unless
                          otherwise permitted by the Governmental Authority with
                          specific approvals/ licenses. The Customer understands
                          and agrees that VIL shall be entitled to terminate the
                          Service and collect financial penalty as levied by the
                          Governmental Authority for any unauthorized use. VIL
                          shall also. at its sole discretion, be entitled to
                          disconnect the unauthorized circuit with immediate
                          effect. The Services shall not be used for call
                          center/ telemarketing/ other service provider (OSP)
                          operations without registration With the Department of
                          Telecommunications and/ or Telecom Regulatory
                          Authority of India as a call center/ OSP. The Customer
                          who wants to take the services for call center/ OSP
                          operations shall submit copy of call center/ OSP
                          license or registration to VIL (vii)The Customer shall
                          be allotted a phone number/iden- tity number by VIL
                          which is and shall always remain the property of VIL.
                          The Customer cannot transfer market, re•brand. resell
                          and/or otherwise assign Services purchased including
                          the phone number/iden tity number. Services to any
                          Other person/entity unless agreed by VIL in writing.
                          (viii) The Customer undertakes to comply with the
                          applicable laws and regulations regarding the use Of
                          the Service. including without limitation. the TRAI
                          Act 1997. the Indian Telegraph Act 1885. the Indian
                          Wireless
                        </li>
                      </ul>
                    </div>
                    <div className="fright width49">
                      <ul className="list">
                        <li>
                          Telegraphy Act 1933. Information Technology Act 2000
                          and all amendments, replacements, rules and
                          regulations made under such legislations from time to
                          time. The Customer shall obtain any relevant consents
                          and approvals for the installation and use of any
                          equipment located at the Customer sites and associated
                          with the Service and any permits, consents or
                          approvals required by the Subscriber to use the
                          Service. including any applicable other service
                          provider permits ("Permits"). Customer shall use the
                          Services in accordance with the Permits. Customer
                          shall provide VIL with an up-to-date copy of the
                          Customefs CIJG approval (if any) issued by the
                          Department Of Telecommunications from time-to-time.
                          Customer shall ensure that any network equipment (not
                          provided by Services Provider) which it uses in
                          relation to the Services meet the relevant
                          International Telecommunication Union
                          (ITU)/Telecommunication Engineering Centre (TEC)
                          standarc'fr (ix) The Customer shall ensure that
                          unauthorized or any Other Content. messages or
                          communica- tians infringing copynght. Intellectual
                          property right and international & domestic cyber
                          laws. in any form or inconsistent with the laws of
                          India. are not carried in his network by him or any
                          other person using his network or dunng the
                          provisioning of Semces. The Customer must take all
                          necessary measures to prevent such use, The use of the
                          Service for anti-national activities shall be
                          construed as an offence punishable under the Indian
                          Penal Code or other applicable law. The Customer must
                          ensure that any service provided by Service Provider
                          is not used for any unlawful purposes or any Other
                          person using the Services as End User. G) Customer
                          agrees and acknowledges that regulatory and law
                          enforcement agencies Law Enforcement Agency (LEA),
                          shatt have rights to access the network and intercept
                          or listen or record calls being made using the
                          Services and facilities provided by Service Provider
                          and Customer shall provide necessary
                          facilities/access/consent to Service Provider and Law
                          Enforcement Agency (LEA), including but not limited to
                          T RAJ. DOT. Police authorities. Reserve Bank of India.
                          Enforcement Directorate and Or any other Government
                          Agencies to monitor. contiol. prevent counteract
                          espionage. subversive act sabotage or any other
                          unlavvfulactivity. (xi) Customer understands Customer
                          and its End Users use the Services at their own risk
                          and consequences and Service Provider has no control
                          over and accepts no responsibility whatsoever for such
                          the Customer use of the Services and the transacted
                          business/transmitted communication and Customer shall
                          comply with all regulatory/legal and Statutory
                          requirements during such use of the Services. (xii)
                          Customer understands and agrees that Service Provider
                          shall terminate the Service provided to the Customer
                          immediately without any prior notice. upon any
                          violation of these terms and conditions by the
                          Customer and/or On account Of any regulatory violation
                          by Customer or its End Users in using the Service or
                          in case of any directive of any Law Enforcement Agency
                          (LEA), Department Of Telecommunication ("DOT"),
                          Judicial authority or any other Government agency.
                          (xiii) The customer is required to maintain and
                          provide updated list Of actual users to VIL at ad
                          times and the customer agrees to give VIL the
                          unhindered right to visit the customer premises and
                          verify bonafide use of the servrces , (o) Customer
                          shall not use the Services for any abusive, improper.
                          immoral. offensive. illegal or fraudulent purposes
                          including making calls or sending data that in VILS
                          reasonable opinion is a nuisance. hoax, menacing,
                          indecent. racist. obscene and/ Or defamatory or do
                          anything that causes the Network to be impaired or
                          damaged. Using Services to access the Internet: In the
                          event that Customer or End User uses Services to
                          access facilities and services not included in a
                          Tariff, VIL shall not be liable under this Agreement
                          for the security or confidentiality of such facilities
                          or services. (xiv) No information with regard to an
                          account information can be shared with any third party
                          other than the duly notified authorized signatory of
                          the Customer as notified by Customer to VIL and VIL
                          cannot provide any account related information Outside
                          India.{" "}
                        </li>
                        <li>
                          5. CONTRACT TERMS AND COMMERCIAL TERMS: This CAF,
                          shall be read With the General Terms and Conditions
                          except where any master services agreement (MSA) is
                          entered including (or any India specific in-country
                          agreement (ICC) between the Customer and Service
                          Provider entered subsequent to or pursuant to any
                          globat level MSA entered between the global
                          principals/affiliates Of the Customer and Service
                          Provider) and the MSA provisions shall prevail over
                          the GTC). Any Specific terms agreed for the mobility
                          shall be set Out in the Part C Or a Product Terms and
                          Conditions and Schedules to the GTC. The CAF terms
                          will prevail over any provisions in the GTC/ or the
                          MSA/ICC. which conflict With terms in CAF. All types
                          of Voice. SMS and Data offerings are a part of the
                          licensed services. The usage charges for these
                          services would be governed by the Tariff plan opted by
                          the customer.
                        </li>
                        <li>
                          6. GOVERNING LAW: This CAF shall be subject to Indian
                          Law and courts Of jurisdiction shall include Courts in
                          the place where Customer has a place of business or
                          where the cause of action arises or the place where
                          the Services have been offered.{" "}
                        </li>
                        <li>
                          7. MISCELLANEOUS: The Authorised Signatory signing
                          this CAF on behalf of the customer hereby confirm,
                          consent and authorize VIL to sell Vodafone Reload Plan
                          or such other plans as may be branded by VIL for their
                          COCP users. Such Plan/s will be activated upon the end
                          user's request and their advance payments made
                          directly by the end user either through VILS dedicated
                          channels or including but not limited to Vodafone
                          website, app. etc.. with no liability on the
                          enterprise customer entity. Subscriber hereby declare
                          and gives their consent to VIL for the collection,
                          processing and use Of their personal data such as.
                          including but not limited to. their name. contact
                          details and employment related information for the
                          purposes of ustng the Services and additional services
                          according to VIL's Privacy Policy displayed on its
                          website www.vodafone.in.
                        </li>
                        <li>
                          8. GST &amp; SEZ STATUS: The customer will be solely
                          responsible for providing the correct GST number and
                          'SEZ" related information against the "Place Of
                          Supply" as per the GST law. S/he waives Off all the
                          rights to dispute or withhold any payments in case of
                          incorrect GST Numbers or incorrect SEZ certificates/
                          status being provided. which donet match the details
                          available on the Government GSTN portal. The customer
                          acknowledges that s/he will not be able to claim the
                          GST tax credit under such circumstances and shall pay
                          100% invoiced amount. including taxes.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p className="ligthbg font8">Customer Acceptance </p>
                  <div className="margin0 over_hid ">
                    <div className="fleft width70">
                      <p className="auth pad_t10">
                        Customer Name
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11432].value}
                          readOnly
                        />
                      </p>
                      <p className="auth pad_t10">
                        Designation
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11433].value}
                          readOnly
                        />
                      </p>
                      <p className="auth pad_t10">
                        Company Name
                        <input
                          className="form_col width80"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11434].value}
                          readOnly
                        />
                      </p>
                      <p>
                        <strong>
                          Note: I/We accept the responsiblates of providing the
                          list of mobile nos/DID nos with user name &nbsp;
                          Designation, alongwith CFA
                        </strong>
                      </p>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td width="50%">
                                {firstCaps != "" ? (
                                  <div
                                    style={{
                                      padding: "10px",
                                      position: "absolute",
                                    }}
                                    className={Signcss}
                                  >
                                    {firstCaps + "  .  "}
                                    <span className="under_line">
                                      {secondBold}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width="50%">
                                {this.state.imageData != null &&
                                this.state.imageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      src={
                                        this.state.imageData != null
                                          ? "data:" +
                                            this.state.imageType +
                                            ";base64," +
                                            this.encode(this.state.imageData)
                                          : ""
                                      }
                                      alt={"Company Seal"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col flright"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.priCafDataupdated[11647]
                                          .value
                                      }
                                      readOnly
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p className="ligthbg font8">
                    {" "}
                    Fulfilment and channel details{" "}
                  </p>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">
                      Documents Verified by
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11438].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Date
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11439].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Channel Partner</div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11440].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11441].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Vodafone Store</div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11442].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width20">
                      Code
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11443].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid custype pad_t5">
                    <div className="fleft width20 pad_7t">Vodafone No.</div>
                    <div className="fleft width30">
                      <input
                        className="form_col fleft width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11444].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Sim Card No.
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11445].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft width70">
                      <div className="pad_r15 pad_t10">
                        I/We hereby undertake and confirm that I/We have seen
                        the customer and certify that (a) the customer enrolment
                        form has been duly filled by the applicant, (b) the
                        photograph submitted has been matched with the
                        applicant, (c )the form has been personally signed by
                        the applicant in my presence, (d) proof of identity &
                        address has been collected, (e) the original proof of
                        identity & address has been matched and verified with
                        the self-attested documents submitted by the applicant.
                        Details on the Vodafone CAF match with these documents.
                        In case of outstation customer, I/ We hereby confirm
                        that I/We tele-verified the particulars furnished by the
                        applicant with local reference and recorded its
                        correctness. Name of Local Reference contacted ( for
                        Outstation subscribers).
                        <span className="in_subput">
                          <input
                            className="form_col width150px"
                            type="text"
                            name=""
                            value=""
                            readOnly
                          />
                        </span>{" "}
                      </div>
                    </div>
                    <div className="fleft width30 ">
                      <div className="signate_box">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {this.state.acctMngSignImageData != null &&
                                this.state.acctMngSignImageData != "" ? (
                                  <div
                                    style={{ padding: "0px" }}
                                    className="signate_img"
                                  >
                                    <img
                                      height="25px"
                                      width="100px"
                                      src={
                                        this.state.acctMngSignImageData !=
                                          null ||
                                        this.state.acctMngSignImageData != ""
                                          ? "data:" +
                                            this.state.acctMngSignImageType +
                                            ";base64," +
                                            this.encode(
                                              this.state.acctMngSignImageData
                                            )
                                          : ""
                                      }
                                      alt={"Signature of Account Manager"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Sign &amp; Seal</td>
                              <td>
                                <spa className=" pad_l_2 ">
                                  {" "}
                                  Date{" "}
                                  <span className="in_subput_date">
                                    <input
                                      className="form_col flright"
                                      type="text"
                                      name=""
                                      value={
                                        this.state.priCafDataupdated[11647]
                                          .value
                                      }
                                    />
                                  </span>{" "}
                                </spa>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_5t">
                    <div className="fleft width40">
                      Enterprise Code
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11447].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width35">
                      Account Manager Name
                      <input
                        className="form_col width45"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11448].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25">
                      Code
                      <input
                        className="form_col width70 flright"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11449].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="tariff">
                    <strong>Tariff Enrollment Form</strong>
                  </p>
                  <p className="e1">E1 DID Service</p>
                  <div className="auth margin0 over_hid pad_t10">
                    <div className="fleft width70">
                      SDCA Name (Where Service is required)
                      <input
                        className="form_col width60"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11578].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      STD Code
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11579].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth margin0 over_hid pad_t10">
                    <div className="fleft width70">
                      Proof of Billing Address Attached :
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11580].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p>
                    <strong>Service Requirements</strong>
                  </p>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Total E1s <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11582].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      DID Number Block Quantity{" "}
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11583].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      DID Numbers required From{" "}
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11584].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width5 pad_5t textcenter"> To </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[15068].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      DID Numbers required{" "}
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width14">
                      {this.state.priCafDataupdated[11585].value === "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width14">
                      {this.state.priCafDataupdated[11585].value === "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Pilot Number (STD + Pilot No){" "}
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width40">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11586].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      CPE Code <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11587].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      CPE Quantity <span className="flright pad_r20">
                        :
                      </span>{" "}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width70"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11588].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid typecheck gstcheck pad_5t">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Facilities Required{" "}
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width14">
                      {" "}
                      O/G Calls
                      {this.state.priCafDataupdated[11589].value
                        .split("|")
                        .indexOf("O/G Calls") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width14">
                      {" "}
                      STD Calling
                      {this.state.priCafDataupdated[11589].value
                        .split("|")
                        .indexOf("STD Calling") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width14">
                      {" "}
                      ISD Calling
                      {this.state.priCafDataupdated[11589].value
                        .split("|")
                        .indexOf("ISD Calling") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width14">
                      {" "}
                      Incoming Calls Only
                      {this.state.priCafDataupdated[11589].value
                        .split("|")
                        .indexOf("Incoming Calls Only") >= 0 ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check over_hid typecheck gstcheck pad_5t">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      UCC Preference <span className="flright pad_r20">
                        :
                      </span>{" "}
                    </div>
                    <div className="fleft width14">
                      {this.state.priCafDataupdated[11590].value === "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{" "}
                    </div>
                    <div className="fleft width14">
                      {this.state.priCafDataupdated[11590].value === "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No{" "}
                    </div>
                  </div>
                  <div className="check over_hid typecheck gstcheck pad_5t">
                    <div className="fleft width25 pad_5t">
                      Are you a DID Operator?
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width10">
                      {this.state.priCafDataupdated[11591].value === "Yes" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{" "}
                    </div>
                    <div className="fleft width10">
                      {this.state.priCafDataupdated[11591].value === "No" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No{" "}
                    </div>
                    <div className="fleft width65 pad_t2">
                      {" "}
                      (If Yes, please execute a DID Operator agreement with VIL
                      and submit a copy of the VNO License)
                    </div>
                  </div>
                  <p>
                    <strong>Commercials</strong>
                  </p>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Rate Plan Name <span className="flright pad_r20">
                        :
                      </span>{" "}
                    </div>
                    <div className="fleft width40">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={planTariff}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Security Deposit{" "}
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width40">
                      {" "}
                      Rs
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11594].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Vanity Number Charges
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width40">
                      {" "}
                      Rs
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11595].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Total Amount Payable
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width40">
                      {" "}
                      Rs
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11596].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid typecheck gstcheck">
                    <div className="fleft width25 pad_5t">
                      {" "}
                      Total Amount Paid with CAF
                      <span className="flright pad_r20">:</span>{" "}
                    </div>
                    <div className="fleft width40">
                      {" "}
                      Rs
                      <input
                        className="form_col width50"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11597].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />
                  <p>
                    <strong>Installation Address:</strong>
                  </p>
                  <p className="auth">
                    Contact Person name :
                    <input
                      className="form_col width83"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11599].value}
                      readOnly
                    />
                  </p>
                  <div className="auth over_hid">
                    <div className="fleft width50">
                      Designation :
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11600].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Dept. Name :
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11601].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth">
                    <span className="width54px">Address : </span>
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11602].value}
                      readOnly
                    />
                  </div>
                  <div className="auth  over_hid">
                    <div className="fleft width50">
                      City/Village/Post office :
                      <input
                        className="form_col width65"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11603].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Pin :
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11604].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <div className="fleft width50">
                      Country :
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11605].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Landmark :
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11606].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <div className="fleft width50">
                      Telephone No :
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11607].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width50">
                      Fax :
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11608].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="auth over_hid">
                    <div className="fleft width58">
                      Contact Email Id :
                      <input
                        className="form_col width77"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11609].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width42">
                      Mobile No / Alt No :
                      <input
                        className="form_col width66"
                        type="text"
                        name=""
                        value={this.state.priCafDataupdated[11610].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />
                  <p>
                    <strong>GST Customer Information:</strong>
                  </p>
                  <div className="check margin0 over_hid">
                    <div className="fleft width15">
                      GSTIN
                      {this.state.priCafDataupdated[11377].value === "GSTIN" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width15">
                      UIN
                      {this.state.priCafDataupdated[11377].value === "UIN" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                    <div className="fleft width15">
                      GST_ISD
                      {this.state.priCafDataupdated[11377].value ===
                      "GST_ISD" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="check margin0 over_hid typecheck">
                    <div className="fleft width12 pad_t2">
                      GST Customer Type
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Normal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Normal
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value === "SEZ" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SEZ
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Diplomat" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Diplomat
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Embassy" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Embassy
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Consulate" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Consulate
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Special Agency" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Special Agency
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11378].value ===
                      "Person/Class of Persons" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Person/Class of Persons
                    </div>
                  </div>
                  <p className="auth">
                    GSTIN / UIN / GST_ISD No
                    <input
                      className="form_col wid50"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11379].value}
                      readOnly
                    />
                  </p>
                  <div className="auth">
                    <span className="w192px in_block">
                      GST Regd. Address (in installation State){" "}
                    </span>
                    <input
                      className="form_col width70"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11615].value}
                      readOnly
                    />
                  </div>
                  <div className="check over_hid typecheck gstcheck pad_t10">
                    <div className="fleft width12 pad_t2">
                      GST Customer Dept
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value === "IT" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IT
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value === "Tech" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Tech
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value ===
                      "Cust Service" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Cust Service
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value === "Legal" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Legal
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value ===
                      "Finance" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Finanace
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value ===
                      "Purchase" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Purchase
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value === "SCM" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SCM
                    </div>
                    <div className="fleft width12">
                      {this.state.priCafDataupdated[11616].value ===
                      "Delivery" ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Delivery
                    </div>
                    <div className="fleft width20 pad_t2">
                      <span>
                        {" "}
                        {this.state.priCafDataupdated[11616].value ===
                        "Others" ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Others
                      </span>{" "}
                      <span className="auth">
                        <input
                          className="form_col oth_gst"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[15066].value}
                          readOnly
                        />
                      </span>
                    </div>
                  </div>
                  <p className="auth namsupp">
                    GST Supporting Document (GST Certificate, SEZ Certificate,
                    Diplomat Certificate)
                    <input
                      className="form_col width45 flright"
                      type="text"
                      name=""
                      value={this.state.priCafDataupdated[11617].value}
                      readOnly
                    />
                  </p>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="tariff_hed">
                    <strong>Tariffs</strong>
                  </p>
                  <div className="tariff_table">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <th>Normal Rates</th>
                          <th>VF LL to VF Mobile</th>
                          <th>VF LL to VF LL</th>
                          <th>VF LL toOther Mob</th>
                          <th>VF LL toOther LL</th>
                          <th colSpan="2">International</th>
                        </tr>
                        <tr>
                          <td>Local</td>
                          <td>{n_l_ll_to_mobile}</td>
                          <td>{n_l_ll_to_ll}</td>
                          <td>{n_l_ll_to_other_mob}</td>
                          <td>{n_l_ll_to_other_ll}</td>
                          <td>{n_l_international}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>STD</td>
                          <td>{n_s_ll_to_mobile}</td>
                          <td>{n_s_ll_to_ll}</td>
                          <td>{n_s_ll_to_other_mob}</td>
                          <td>{n_s_ll_to_other_ll}</td>
                          <td>{n_s_international}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="tariff_table">
                    <table
                      width="50%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <th>CUG Rates</th>
                          <th>VF LL to VF Mobile</th>
                          <th>VF LL to VF LL</th>
                        </tr>
                        <tr>
                          <td>Local</td>
                          <td>{c_l_ll_to_mobile}</td>
                          <td>{c_l_ll_to_ll}</td>
                        </tr>
                        <tr>
                          <td>STD</td>
                          <td>{c_s_ll_to_mobile}</td>
                          <td>{c_s_ll_to_ll}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pad_l20">
                    <p>
                      &nbsp;&nbsp; Default pulse for Telephony calls will be 60
                      seconds. ‘LL’ means landline and ‘CUG’ means Closed User
                      Group
                    </p>
                    <p>
                      &nbsp;&nbsp; For Standard ISD prices, please refer to the
                      proposal documentprovided by the VIL Account Manager, on
                      request.
                    </p>
                    <p>&nbsp;&nbsp; Figures in INR</p>
                    <br />
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Monthly Rental:</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11635].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Pulse (seconds):</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11636].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>
                          FCV Multiplier (FCV amount divided by Rental):
                        </strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11637].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Freebies (if Free Minutes):</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11638].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Lock in Period:</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11639].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Exit Charge:</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11640].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Payment Terms:</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11641].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Customer Account Code:</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11642].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Name of the Account Manager:</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11643].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Signature of the Account Manager:</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            height="50px"
                            width="100px"
                            src={
                              this.state.acctMngSignImageData != null &&
                              this.state.acctMngSignImageData != ""
                                ? "data:" +
                                  this.state.acctMngSignImageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Signature of Account Manager"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Signature of the Customer:</strong>{" "}
                      </div>
                      <div className="fleft width50">
                        {firstCaps != "" ? (
                          <div
                            style={{
                              padding: "20px",
                              position: "relative",
                              bottom: "40px",
                            }}
                            className={Signcss}
                          >
                            {firstCaps + "  .  "}
                            <span className="under_line">{secondBold}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="auth over_hid typecheck gstcheck">
                      <div className="fleft width33 pad_5t">
                        <strong>Date :</strong>{" "}
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={this.state.priCafDataupdated[11647].value}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="over_hid">
                      <div className="sealleft">
                        <strong>Seal :</strong>
                        {this.state.imageData != null &&
                        this.state.imageData != "" ? (
                          <div
                            style={{ padding: "10px" }}
                            className="signate_img"
                          >
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="sealright">
                        <strong>Declaration:</strong>
                        <br />
                        We have read and understood the General Terms and
                        Conditions mentioned overleaf and unconditionally accept
                        them as binding on us. We have understood all the rates,
                        tariffs and other related conditions at which the
                        Services will be provider inside India as applicable on
                        this date as amended from time to time. We undertake to
                        pay all the charges raised on account of the Services
                        availed. We further declare and undertake that the above
                        provided by us is true and correct in all aspect.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </page>
            <page size="A4">
              <div className="section2">
                <div className="font8">
                  <p className="over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <div className="over_hid generalterms">
                    <div className="termleft">
                      <p className="textcenter">
                        GENERAL TERMS AND CONDITIONS (“GTC”) for PRI Service
                      </p>
                      <ul className="tarifflist">
                        <li>
                          <strong>RECITAL</strong>
                          <br />
                          Vodafone Idea Limited (“<strong>VIL</strong>”) are
                          willing to provide the Services to the Customer on the
                          terms and conditions (T&C) provided below which
                          otherwise have been made available to Customer.
                          Customer acknowledges that for availing the Services
                          it agrees to comply with the T&C and is seeking to
                          avail the Service herein. Basis the same, VIL shall
                          provide the Services to the Customer and/or any person
                          who for and on behalf of the Customer enterprise in
                          its premises, makes use or avails the Services will be
                          construed as a “<strong>User</strong>”.
                        </li>
                        <li>
                          <strong>PRECEDENCE</strong>
                          <ul className="tarifflist2">
                            <li>
                              {" "}
                              The GTC consists of following documents and their
                              order of precedence in ascending order is: (i)
                              Customer Application Form (“CAF”) duly completed
                              (ii) Tariff Enrolment Form; (iii) Terms of
                              Service; (iv)any other signed documents
                              referenced.
                            </li>
                            <li>
                              In the event that the Parties wish to make any
                              change to this GTC then they shall do so expressly
                              in writing, which must be signed by authorised
                              representatives of both Parties.
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <strong>GENERAL TERMS APPLICABLE TO SERVICE</strong>
                          <ul className="tarifflist2">
                            <li>
                              Customer shall ensure that: (i) the Services shall
                              not be used for a call centre / other service
                              provider operations without registration with the
                              Department Of Telecommunication.{" "}
                            </li>
                            <li>
                              Except as expressly set out herein, VIL shall have
                              right to: (i) charge Customers such fees/charges
                              (if any) as may be determine from time to time;
                              and (ii) and promote / package the Service.
                            </li>
                            <li>
                              For Services provisioned, Parties acknowledge and
                              agree VIL acts as an intermediary and may not have
                              supervisory, editorial rights over the Services
                              provided to Customer.
                            </li>
                            <li>
                              {" "}
                              On violation of CAF terms or Applicable laws, VIL
                              shall have the right to refuse, reject, suspend,
                              remove or cause to be removed any Services that
                              may be made available to Customers.
                            </li>
                            <li>
                              {" "}
                              Customer shall not use or permit the Services for:
                              (a) establishing a connection to the gateway
                              between Internet & PSTN/ISDN/PLMN in India is not
                              permitted & the Customer must ensure that the
                              voice traffic over the enterprise data network and
                              the public switched telephone network must be
                              strictly separated via logical partitioning of the
                              EPABX so that proper management of the EPABX and
                              no mixing of calls between the two networks can
                              occur for the purpose of toll bypass; or (b)
                              interconnectivity is not permitted for ISPs
                              offering Internet Telephony Services and for ISPs
                              usage; or (c) being used to route unauthorised
                              telecommunication network including to prohibited
                              destinations; or (d) lease/resell or create any
                              third party right over the Services provisioned;
                              or (e) negligence use by Customer, its employees
                              or agents thereby being responsible for ensuring
                              that the Services are used legally and in
                              compliance with the existing regulations; or (f)
                              excess or excessive usage or for any unusual
                              patterns in the usage and/or for any
                              unwarranted/not permitted usage; or (g) use,
                              directly or indirectly, VIL’s SMS centres, network
                              or infrastructure or any part thereof for sending
                              bulk or unsolicited messages to VIL customers or
                              other mobile phone users in any manner whatsoever;
                              or (h) acquiring any customers or promote any
                              messages through unethical practices.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <strong>DEFINITIONS </strong>
                          <br />
                          <div className="auth over_hid">
                            <div className="fleft width20">
                              {" "}
                              <strong> “Applicable Law”</strong>{" "}
                            </div>
                            <div className="fleft width80">
                              {" "}
                              means all laws, legislation, regulations, binding
                              codes of practice, or rules or requirements of any
                              relevant government or governmental agency
                              applicable to the Services;{" "}
                            </div>
                          </div>
                          <div className="auth over_hid">
                            <div className="fleft width20">
                              {" "}
                              <strong> “CAF”</strong>{" "}
                            </div>
                            <div className="fleft width80">
                              means VIL’s standard format of the CAF, as amended
                              from time to time, contains regulatory conditions
                              and / or instructions / or guidelines issued /
                              mandated upon the Licensee under the Telecom
                              Access Service License issued by Government of
                              India
                            </div>
                          </div>
                          <div className="auth over_hid">
                            <div className="fleft width20">
                              {" "}
                              <strong> “Services”</strong>{" "}
                            </div>
                            <div className="fleft width80">
                              means Primary Rate Interface (‘PRI’) which is a
                              standardized telecommunications service within the
                              Integrated Services Digital Network (ISDN)
                              specification for carrying multiple DS0 voice and
                              data transmissions between VIL’s network and User.
                            </div>
                          </div>
                          <div className="auth over_hid">
                            <div className="fleft width20">
                              {" "}
                              <strong> “VIL Group Company”</strong>{" "}
                            </div>
                            <div className="fleft width80">
                              mean a company that is directly or indirectly
                              under the management control of Vodafone Idea
                              Limited or entity provisioning of the Services;
                            </div>
                          </div>
                        </li>
                        <li>
                          <strong>OTHER TERMS AND CONDITIONS</strong>
                          <ul className="tarifflist3">
                            <li>
                              1&nbsp; &nbsp;Either Party may terminate the
                              Services on: - (a) on formal written notice and
                              without liability, if other party becomes subject
                              to (i) an Insolvency Event; or (ii) Force Majeure
                              Event for (45) consecutive days; or (iii)
                              convenience with prior (20) Business Days written
                              notice, except if the termination is effective
                              prior to Lock in Period then Exit Charge and any
                              other sums will be payable; or (iv) commits a
                              material breach, and failure to remedy such breach
                              within 20 Business Days’; or (ii) commits a series
                              of breaches which when taken together amount to a
                              material breach. Material breach also shall
                              construe: (i) Customer’s non-payment of an
                              undisputed amount due beyond the Payment Period;
                              (ii) any violation by the Customer or End User of
                              Customer Applicable Law; or (iii) any Service
                              misuse contrary to telecom licence.
                            </li>
                            <li>
                              2&nbsp; &nbsp;If Services are terminated and there
                              are unfulfilled or accrued obligations including
                              payment of fees, expenses, indemnification and
                              confidentiality then Parties shall be bound by
                              those obligations and perform accordingly.
                            </li>
                            <li>
                              3&nbsp; &nbsp;Save as stated otherwise, the
                              maximum aggregate liability (whether in contract
                              or in tort) of each Party in each 12 month period
                              (the first such period commencing and ending at
                              the close of business before the first anniversary
                              of the Service commencement) shall be limited to a
                              sum equal to the value of total charges paid
                              (excluding taxes and duties) for Services herein,
                              during that period.
                            </li>
                            <li>
                              4&nbsp; &nbsp;Subject to clause 5.5, neither Party
                              shall be liable to the other Party for any
                              indirect, consequential, special, or exemplary
                              losses or damages, including but not limited to
                              loss of profits, loss of revenue, or loss of
                              savings, whether actual or anticipated under or in
                              connection with this GTC.
                            </li>
                            <li>
                              5&nbsp; &nbsp;Nothing in this GTC, excludes or
                              limits liability in relation to: (i) any loss,
                              damage, costs, and other liabilities (“Actions”)
                              recoverable under Clause 5.13 & 5.14
                              (Indemnification); (ii) Customer’s liability for
                              repudiatory breach, wilful default and wilful
                              abandonment; (iii) Customer’s obligation to pay
                              all Charges for the provision of Services; (iv)
                              either Party’s liability for death or personal
                              injury resulting from its negligence; (iv) either
                              Party’s liability for fraud or fraudulent
                              misrepresentation; (v) either Party’s liability
                              which cannot be excluded by law; or (viii) the use
                              of either Party’s Intellectual Property Rights,
                              including any patents, designs, trademarks, and
                              business names (including goodwill associated),
                              copyright and related rights or forms of
                              protection in any part of the world; by the other
                              Party in breach of this GTC.
                            </li>
                            <li>
                              6&nbsp; &nbsp;Parties do hereby consent that all
                              confidential information or documentation
                              disclosed between them relating to business which
                              is of a technical nature or involves research and
                              development, that is rightfully obtained, will be
                              kept under{" "}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="termright">
                      <p>
                        {" "}
                        strict confidentiality and the recipient party shall not
                        be permitted to reproduce any copies or ownership of the
                        same. The obligation of confidentiality shall be valid
                        for 3 years post termination or expiry of term.{" "}
                      </p>
                      <ul className="tarifflist4">
                        <li>
                          7&nbsp; &nbsp; Each Party warrants that: (a) they are
                          in possession of and shall maintain all necessary
                          consents, approvals, permissions and licenses that are
                          required for their performance obligations; and (b)
                          each Party shall inform and cooperate as may be
                          required for exchange relevant and necessary data and
                          work out measures to prevent or eliminate fraud,
                          misuse or damage and also in defending any claim
                          threatened or brought by any Governmental Authorities
                          or third party in relation to the matters arising out
                          of this GTC.
                        </li>
                        <li>
                          8&nbsp; &nbsp; VIL makes no warranties or
                          representations, express or implied, either in fact or
                          by operation of law, statutory or otherwise, including
                          warranties or terms of merchantability, satisfactory
                          quality, fitness for purpose, title or
                          non-infringement.
                        </li>
                        <li>
                          9&nbsp; &nbsp; Customer shall as a result of availing
                          Services: (i) not knowingly or intentionally introduce
                          software virus into VIL’s network; and (ii) take
                          precautions to ensure that no virus is introduced;
                          (iii) immediately report, if it finds a virus, and
                          provide all information reasonably requested; and (iv)
                          where a virus is detected, promptly take all steps
                          necessary as agreed by VIL to support VIL in
                          eliminating the Virus. If Customer breaches the
                          obligations in this clause it shall indemnify VIL
                          against any Actions arising as a result of the breach.{" "}
                        </li>
                        <li>
                          10&nbsp; &nbsp; Customer shall co-operate or provide
                          assistance and share correct, accurate and complete
                          details to enable VIL to resolve any technical or
                          system related issues pertaining to the Services,
                          including by: (i) providing logs from its system to
                          permit VIL to debug any technical issues; and (ii)
                          engaging its equipment vendors on a need basis for
                          joint trouble shooting.
                        </li>
                        <li>
                          11&nbsp; &nbsp; Customer shall, provide access to its
                          network, premises or sites, inter alia, to: (i)
                          diagnose any technical issues arising due to the
                          equipment installed; (ii) inspect the premises or
                          sites for resolution of Services outage; or (iii)
                          improve the quality of the Services; or (iv) implement
                          any changes in the provision of the Services; or (v)
                          enable any technical trouble shooting.{" "}
                        </li>
                        <li>
                          12&nbsp; &nbsp; Customer agrees to compensate for: (i)
                          damage caused to VIL equipment in the event of any
                          negligence or man-handling; or (ii) site visits made
                          to resolve or isolate an issue pertaining to the
                          Customer network.
                        </li>
                        <li>
                          13&nbsp; &nbsp; Customer shall indemnify and keep
                          indemnified VIL against all claims, losses, demand,
                          proceeding or damages or other liability from a third
                          party claim, arising under or in connection with (i)
                          any breach of the provisions on compliance with
                          Applicable Law; or (ii) use of the Services for any
                          purpose other than as specified in the applicable CAF;
                          (iii) any breach of its confidentiality obligations;
                          or (iv) in relation to negligence, fraud, wilful
                          misconduct, breach of statutory duty.
                        </li>
                        <li>
                          14&nbsp; &nbsp; Customer shall indemnify and keep
                          indemnified VIL (or its officers, employees,
                          affiliates and their agents) against all Actions
                          arising under or in connection with: (i) Customer’s or
                          User’s: (a) use of the Services for any purpose other
                          than as specified in the applicable CAF; or (b)
                          transmission of illegal or unlawful content; or (c)
                          infringement of privacy and data protection laws; (ii)
                          fines/penalties imposed on VIL by Governmental
                          Authorities resulting from Customer’s/User’s acts or
                          omissions; (iii) any breach of the provisions on
                          compliance with Applicable Law;.
                        </li>
                        <li>
                          15&nbsp; &nbsp; Each Party acknowledges that legally
                          binding demands from a law enforcement authority for
                          the disclosure of, or other assistance in respect of,
                          Services or Personal Data (including any information
                          identifying, directly or indirectly, the Customer,
                          Users or employees), or be required by law, court
                          order, warrant, subpoena, or other legal judicial
                          process to disclose any data in this regard and in
                          such circumstances it will not be construed as breach
                          for complying with such obligations to the extent
                          legally bound. Customer shall bear all risk of loss or
                          damage to any part of the VIL network infrastructure
                          at times when they are under Customer’s control.
                        </li>
                        <li>
                          16&nbsp; &nbsp; Formal written notices in English be
                          given at the addresses for Service.
                        </li>
                        <li>
                          17&nbsp; &nbsp; Neither Party shall be responsible for
                          failure due to an impeding ‘force majeure event’,
                          provided that the subjected Party: (i) has promptly
                          notified of the circumstances which may result in
                          failure to perform its obligations; (ii) could not
                          have avoided the effect of the force majeure event by
                          taking precautions
                        </li>
                        .
                        <li>
                          18&nbsp; &nbsp; This GTC shall be subject to laws of
                          India, and courts of jurisdiction shall be of Mumbai,
                          over any dispute, controversy or claim (including any
                          question regarding its existence, validity or
                          termination) arising herein.
                        </li>
                        <li>
                          19&nbsp; &nbsp; Neither Party shall without prior
                          written consent of other, not assign, novate,
                          subcontract or otherwise transfer any of its rights or
                          obligations, save that VIL may assign, novate,
                          subcontract or otherwise transfer any of its rights or
                          obligations herein to a VIL Group Company and also
                          subcontract the provisioning of part of the Services.
                        </li>
                        <li>
                          20&nbsp; &nbsp; Customer shall raise order for Service
                          by submitting an order form, subject to satisfaction
                          of the verification process as identified in CAF.
                        </li>
                        <li>
                          21&nbsp; &nbsp; All sums payable by Customer shall be
                          without any deductions, withholdings and without any
                          right of set off or counter claim, unless agreed
                          otherwise.
                        </li>
                        <li>
                          22&nbsp; &nbsp; Customer agree that the Services,
                          detailed as annexed to the GTC/CAF, are acceptable and
                          further agreed to pay the amount set out in the Tariff
                          Enrolment Form in accordance with out payment
                          conditions stated therein.
                        </li>
                        <li>
                          23&nbsp; &nbsp; Any Purchase order or other document
                          issued by Customer is for administrative convenience
                          only. In event of any inconsistency, the terms of this
                          GTC shall prevail.
                        </li>
                        <li>
                          24&nbsp; &nbsp; Bill Dispute: Customer agrees that all
                          bill disputes can only be settled either via credit /
                          debit note or an adjustment in the current invoice.
                          VIL shall invoice as per the standard bill frequency
                          and customer is liable to pay as per bill frequency
                          agreed in the CAF form.
                        </li>
                        <li>
                          25&nbsp; &nbsp; Price Revision: If new prices are not
                          negotiated between the parties 30 days in advance
                          prior to the P.O expiry date (applicable if customer
                          has issued the P.O’s) then the existing prices shall
                          prevail and the new prices negotiated shall be
                          applicable only from the original P.O receipt date by
                          VIL. In all cases, the new prices shall be applicable
                          only from the date of P.O released by Customer.
                        </li>
                        <li>
                          26&nbsp; &nbsp; Terminations: Customer agrees that all
                          terminations request with at least 30 days advance
                          notification shall be send to the email id mentioned
                          on the invoice. In case, the termination request is
                          not sent on the designated email id then customer is
                          liable to pay 100% of the invoiced value. Customer
                          agrees to return all the equipment / devices or
                          material (in working condition) provided by VIL at the
                          time of activation of service. In case customer
                          doesn’t provides so, VIL reserves the right and
                          customer agrees to pay 100% and settle the
                          invoice/debit note raised by VIL against the material
                          not returned.{" "}
                        </li>
                        <li>
                          27&nbsp; &nbsp; TDS: In case VIL provides the
                          certificate for deducting lower tax, the customer
                          agrees to deduct the tax accordingly and pay the
                          balance amount to VIL.
                        </li>
                      </ul>
                    </div>
                    <div
                      className="signate float_img"
                      style={{ marginTop: "1500px" }}
                    >
                      {firstCaps != "" ? (
                        <div style={{ padding: "10px" }} className={Signcss}>
                          {firstCaps + "  .  "}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.imageData != null &&
                      this.state.imageData != "" ? (
                        <div
                          style={{ padding: "10px" }}
                          className="signate_img"
                        >
                          {
                            <img
                              src={
                                this.state.imageData != null
                                  ? "data:" +
                                    this.state.imageType +
                                    ";base64," +
                                    this.encode(this.state.imageData)
                                  : ""
                              }
                              alt={"Company Seal"}
                            />
                          }
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </page>
            {this.state.excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">Annexure to PRI CAF:- </p>
                  </div>
                  <JsonToTable json={this.state.excelJson} />
                  <div>
                    <p className="font10 " style={{ marginTop: "20px" }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div className="fleft width50 pad_t10">
                    <p className="marb5">Account Manager Name</p>
                    <p className="margin0">
                      <input
                        className="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        value={this.state.priCafDataupdated[11448].value}
                        readOnly
                      />
                    </p>
                    <div className="signate">
                      <div className="signate_img input_sign">
                        {this.state.acctMngSignImageData != null &&
                        this.state.acctMngSignImageData != "" ? (
                          <img
                            src={
                              this.state.acctMngSignImageData != null &&
                              this.state.acctMngSignImageData != ""
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.acctMngSignImageData)
                                : ""
                            }
                            alt={"Account Manager Sign"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div className="signate float_img">
                    <div className="over_hid ">
                      <div className="over_hid ">
                        Authorised Signatory Name
                        {
                          <input
                            className="form_col inputext"
                            type="text"
                            name="Name_CD"
                            value={this.state.authorizedSignName}
                            readOnly
                          />
                        }
                      </div>
                    </div>
                    {firstCaps != "" ? (
                      <div style={{ padding: "10px" }} className={Signcss}>
                        {firstCaps + "  .  "}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.imageData != null &&
                    this.state.imageData != "" ? (
                      <div style={{ padding: "10px" }} className="signate_img">
                        {
                          <img
                            src={
                              this.state.imageData != null
                                ? "data:" +
                                  this.state.imageType +
                                  ";base64," +
                                  this.encode(this.state.imageData)
                                : ""
                            }
                            alt={"Company Seal"}
                          />
                        }
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
    return <div>{priCafContentView}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.loginAuth.authToken,
    empAssetId: state.loginAuth.empAssetId,
    deskAssetId: state.loginAuth.deskAssetId,
    loading: state.loginAuth.loading,
    workforceId: state.loginAuth.workforceId,
    orgId: state.loginAuth.orgId,
    accId: state.loginAuth.accId,
    userData: state.loginAuth.userData,
    submittedForms: state.queue.submittedForms,
    fieldList: state.forms.fieldList,
    formFullLoaded: state.forms.formFullLoaded,
    formSubmitted: state.forms.formSubmitted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmittedFormsFetch: (requestData) =>
      dispatch(actions.getQueueSubmittedFormsList(requestData)),
    onFormFieldsFetch: (requestData, entry) =>
      dispatch(actions.formFieldListFetchCRF(requestData, entry)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PRICafFormOld);
