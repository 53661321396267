import * as actionTypes from './actionTypes';
import Axios from 'axios';
import AxiosWeb from '../../axios_ext';
import * as utils from '../../shared/Utils';



export const filesFetchStart = (requestParams) => {
  return {
    type: actionTypes.FILES_FETCH_START,
    loadFiles: requestParams.page_start === 0 ? true : false,

  };
};
export const filesFetchSuccess = (fileList,requestParams) => {
  return {
    type: actionTypes.FILES_FETCH_SUCCESS,
    fileList: fileList,
    loadFiles: false,
    fileListParams: requestParams

  };
};
export const filesListFetchForReference = (fileList) => {
  return {
    type: actionTypes.FILES_LIST_FOR_REFERENCE,
    fileList: fileList,

  };
};

export const filesFetchFail = (error) => {
  return {
    type: actionTypes.FILES_FETCH_FAIL,
    error: error,
    loadFiles: false,

  };
};

export const filesClearStore = () => {
  return {
    type: actionTypes.FILES_CLEAR_STORE,

  };
};

export const filesFetch = (requestData,reference = null) => {

  return dispatch => {
    dispatch(filesFetchStart(requestData));

    const fetchDataStr = requestData;
    AxiosWeb.post('activity/access/folder/list',fetchDataStr)
      .then(res => {
        const fileData = res.data.response.data;

        //console.log(assetData.asset_id +" and "+requestData.assetId);
        if(reference && reference === 'select') {
          const FinalFileArray = [];
          fileData.forEach(value => {
            const FileJson = {
              label: value.activity_title,
              value: {
                activity_id: value.activity_id,
                activity_title: value.activity_title,
                activity_type_id: value.activity_type_id,
                activity_type_category_id: value.activity_type_category_id,
                actvity_sub_type_id: value.activity_sub_type_id
              }
            }
            FinalFileArray.push(FileJson)
          })
          dispatch(filesListFetchForReference(FinalFileArray));
        }
        else {
          dispatch(filesFetchSuccess(fileData,fetchDataStr));
        }


      })
      .catch(err => {
        console.log(err);
        dispatch(filesFetchFail(err));
      })

  }
};

export const idCardFetchStart = () => {
  return {
    type: actionTypes.ID_CARD_FETCH_START
  }
}
export const idCardFetchSuccess = (idCardData) => {
  return {
    type: actionTypes.ID_CARD_FETCH_SUCCESS,
    idCardData: idCardData
  }
}
export const idCardActivityFetch = (requestData) => {
  return dispatch => {
    const fetchDataStr = requestData;
    AxiosWeb.post('activity/cover/collection/v1',fetchDataStr)
      .then(res => {
        const fileData = res.data.response.data;

        dispatch(idCardFetchSuccess(fileData));

      })
      .catch(err => {
        console.log(err);

      })

  }
}

export const idCardFetchScoreStart = () => {
  return {
    type: actionTypes.ID_CARD_FETCH_SCORE_START
  }
}
export const idCardFetchScoreSuccess = (score) => {
  return {
    type: actionTypes.ID_CARD_FETCH_SCORE_SUCCESS,
    scoreData: score
  }
}
export const idCardScoreFetch = (requestData) => {
  return dispatch => {
    dispatch(idCardFetchScoreStart());
    const fetchDataStr = requestData;
    AxiosWeb.post('asset/weekly_monthly/summary/params',fetchDataStr)
      .then(res => {
        const scoreData = res.data.response;

        dispatch(idCardFetchScoreSuccess(scoreData));

      })
      .catch(err => {
        console.log(err);

      })

  }
}
export const fetchIDcard = (requestData) => {
  return dispatch => {
    dispatch(idCardFetchStart());
    const fetchDataStr = {
      asset_id: requestData.operating_asset_id,
      organization_id: requestData.organization_id
    };
    Axios.post('activity/asset/id_card/cover',fetchDataStr)
      .then(res => {
        const idData = res.data.response.data;
        if(idData.length > 0) {
          requestData.activity_id = idData[0].activity_id;
          dispatch(idCardActivityFetch(requestData));
        }
      })
      .catch(err => {
        console.log(err);
      })

  }
};



//Fetch Participants List

export const participantsFetchStart = () => {
  return {
    type: actionTypes.FILE_PARTICIPANTS_FETCH_START
  }
}
export const participantsFetchSuccess = (partList) => {
  return {
    type: actionTypes.FILE_PARTICIPANTS_FETCH_SUCCESS,
    participantList: partList
  }
}
export const fetchFileParticipants = (requestData) => {
  return dispatch => {
    dispatch(participantsFetchStart());

    AxiosWeb.post('activity/participant/list',requestData)
      .then(res => {
        const participantData = res.data.response.data;

        //console.log("ID CARD Data",idData);
        dispatch(participantsFetchSuccess(participantData));


      })
      .catch(err => {
        console.log(err);
        //dispatch(filesFetchFail(err));
      })

  }
};

//Fetch FILE Timeline 

export const timelineFetchStart = () => {
  return {
    type: actionTypes.FILE_TIMELINE_FETCH_START
  }
}
export const timelineFetchSuccess = (timeline) => {
  return {
    type: actionTypes.FILE_TIMELINE_FETCH_SUCCESS,
    timelineData: timeline
  }
}

/**
 * =================================================
 *  ## Actions for loading account summary Month, Quaterly, Yearly
 * =================================================
 */

export const accountSummaryMonthlySuccess = (summary) => {
  return {
    type: actionTypes.ACCOUNT_SUMMARY_MONTHLY_SUCCESS,
    accountSummary: summary
  }
}

export const fetchAccountSummaryMonthly = (payload) => {
  return dispatch => {
    AxiosWeb.post("ledger/transaction/summary/monthly",payload)
      .then((res) => {
        console.log('Response: Monthly summary',res);
        dispatch(accountSummaryMonthlySuccess(res.data.response));
      })
      .catch((error) => {
        console.log('Error: Monthly summary',error);
      })
  }
}

export const accountSummaryQuarterlySuccess = (summary) => {
  return {
    type: actionTypes.ACCOUNT_SUMMARY_QUARTERLY_SUCCESS,
    accountSummary: summary
  }
}
export const fetchAccountSummaryQuarterly = (payload) => {
  return dispatch => {
    AxiosWeb.post("ledger/transaction/summary/quarterly",payload)
      .then((res) => {
        console.log('Response: Quarterly summary',res);
        dispatch(accountSummaryQuarterlySuccess(res.data.response));
      })
      .catch((error) => {
        console.log('Error: Quarterly summary',error);
      })
  }
}

export const accountSummaryYearlySuccess = (summary) => {
  return {
    type: actionTypes.ACCOUNT_SUMMARY_YEARLY_SUCCESS,
    accountSummary: summary
  }
}
export const fetchAccountSummaryYearly = (payload) => {
  return dispatch => {
    AxiosWeb.post("ledger/transaction/summary/yearly",payload)
      .then((res) => {
        console.log('Response: yearly summary',res);
        dispatch(accountSummaryYearlySuccess(res.data.response));
      })
      .catch((error) => {
        console.log('Error: yearly summary',error);
      })
  }
}

export const rollBackStatusAction = (payload) => {
  return dispatch => {
    AxiosWeb.post("activity/add/v1",payload)
      .then((formRes) => {
        console.log('submitted the form 803');
      })
  }
}

export const leadChangeAction = (payload) => {
  return dispatch => {
    AxiosWeb.post("activity/lead/update",payload)
      .then((formRes) => {
        console.log('successful');
      })
  }
}

export const resetLocalFileCounts = (activity_id) => {
  return {
    type: actionTypes.FILE_RESET_UNREAD_COUNT,
    activityId: activity_id
  }
}
export const handleContentPagination = (mode) => {
  return {
    type: actionTypes.FILE_CONTENT_PAGINATION,
    mode: mode
  }
}

export const handleContentAppendToTimeline = (timelineData) => {
  return {
    type: actionTypes.FILE_TIMELINE_APPEND,
    contentData: timelineData
  }
}


export const addFileTimelineEntry = (requestData,entryType) => {
  return dispatch => {

    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
    AxiosWeb.post("activity/timeline/entry/add",requestData)
      .then(res => {
        //console.log("timelineEntry  params ",requestData);
        //console.log("timelineEntry ",res);
        if(entryType && entryType === "note") {
          dispatch(handleContentAppendToTimeline(requestData));
        }



      })
      .catch(err => {
        console.log(err);
        //dispatch(tasksFetchFail(err));
      })

  }
};

export const alterFileOwner = (requestData) => {
  return dispatch => {
    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
    AxiosWeb.post("activity/owner/alter",requestData)
      .then(res => {
        requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
        dispatch(alterFileStatus(requestData))
      })
      .catch(err => {
        console.log(err);
        //dispatch(tasksFetchFail(err));
      })
  }
}

export const activityParentAlter = (requestData) => {
  return dispatch => {
    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
    AxiosWeb.post("activity/parent/alter",requestData)
      .then(res => {
        //console.log("Owner alter  params ",requestData);
        //console.log("Owner res ",res);
        //dispatch(alterFileStatus(requestData))
      })
      .catch(err => {
        console.log(err);
        //dispatch(tasksFetchFail(err));
      })
  }
}

export const alterFileStatus = (requestData) => {
  return dispatch => {
    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
    AxiosWeb.post("activity/status/alter",requestData)
      .then(res => {
        //console.log("Status alter  params ",requestData);
        //console.log("Status res ",res);
        dispatch(addFileTimelineEntry(requestData));

      })
      .catch(err => {
        console.log(err);
        //dispatch(tasksFetchFail(err));
      })
  }
}
export const resetFileCoverData = (requestData) => {
  return {
    type: actionTypes.FILE_RESET_COVER_DATA,
    activityData: requestData
  }
}

export const fileEditProcessStart = () => {
  return {
    type: actionTypes.FILE_EDIT_COVER_START,

  }
}

export const fileEditProcessEnd = () => {
  return {
    type: actionTypes.FILE_EDIT_COVER_END,

  }
}

export const fileSetParticipant = (requestData) => {
  return dispatch => {
    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
    AxiosWeb.post("activity/participant/access/set",requestData)
      .then(res => {


      })
      .catch(err => {
        console.log(err);
        //dispatch(tasksFetchFail(err));
      })

  }
};
export const setLocalParticipants = (participants) => {
  return {
    type: actionTypes.FILE_SET_LOCAL_PARTICIPANTS,
    newParticipants: participants
  }
}

export const fileResetParticipant = (requestData) => {
  return dispatch => {
    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
    AxiosWeb.post("activity/participant/access/reset",requestData)
      .then(res => {


      })
      .catch(err => {
        console.log(err);
        //dispatch(tasksFetchFail(err));
      })

  }
};


export const fileParticipantResetStart = () => {
  return {
    type: actionTypes.FILE_PARTICIPANT_RESET_START,
  };
};

export const fileParticipantResetSuccess = () => {
  return {
    type: actionTypes.FILE_PARTICIPANT_RESET_SUCCESS,
  };
};

export const fileParticipantReset = (requestData) => {
  return dispatch => {
    requestData.message_unique_id = utils.getMessageUniqueId(requestData.asset_id);
    AxiosWeb.post("activity/participant/access/reset",requestData)
      .then(res => {
        console.log("File Participant Reset Result : ",res.data.response)
        if(res.data.status === 200) {
          dispatch(fileParticipantResetSuccess(requestData));
        }
      })
      .catch(err => {
        console.log(err);
        //dispatch(tasksFetchFail(err));
      })

  }
};

// Workflow Ref

export const workflowsFetchStart = (requestParams) => {
  return {
    type: actionTypes.WORKFLOWS_FETCH_START,
    loadFiles: requestParams.page_start === 0 ? true : false,

  };
};
export const workflowsFetchSuccess = (workflowList,requestParams) => {
  return {
    type: actionTypes.WORKFLOWS_FETCH_SUCCESS,
    workflowList: workflowList,
    loadWorkflows: false,
    workflowListParams: requestParams

  };
};
export const workflowListFetchForReference = (activity_type_id,workflowListOptions) => {
  let workflowListRef = {activity_type_id,workflowListOptions};
  return {
    type: actionTypes.WORKFLOWS_LIST_FOR_REFERENCE,
    workflowListRef
  };
};

export const workflowListFetchForCredit = (workflowList) => {
  return {
    type: actionTypes.WORKFLOWS_LIST_FOR_Credit,
    workflowListCredit: workflowList
  }
}

export const workflowsFetchFail = (error) => {
  return {
    type: actionTypes.WORKFLOWS_FETCH_FAIL,
    error: error,
    loadWorkflows: false,

  };
};

export const workflowsFetch = (requestData,reference,target = null) => {

  return dispatch => {
    dispatch(workflowsFetchStart(requestData));

    const fetchDataStr = requestData;
    let searchUrl = "v1";
    if(fetchDataStr.isElasticSearchEnabled === 1) {
      searchUrl = "v2";
    }
    AxiosWeb.post(
      `/workflow_reference/activity_type/search/${searchUrl}`,
      fetchDataStr
    )
      .then(res => {
        const fileData = res.data.response;
        if(reference && reference === 'select') {
          const optionList = [];
          fileData.forEach((value) => {
            const {
              activity_id,
              activity_title,
              activity_cuid_1,
              activity_cuid_2,
              activity_cuid_3,
              activity_creator_operating_asset_first_name,
            } = value;
            const option = {
              label: activity_title,
              value: activity_id,
              details: {
                activity_id,
                activity_title,
                activity_cuid_1,
                activity_cuid_2,
                activity_cuid_3,
                operating_asset_first_name: activity_creator_operating_asset_first_name,
              },
            };
            optionList.push(option);
          })
          if(target == 62) {
            let wList = optionList.map((item) => ({
              label: item.label,
              value: `${item.label}|${item.value}`,
              details: item.details,
            }));
            dispatch(workflowListFetchForCredit(wList));
          } else {
            dispatch(
              workflowListFetchForReference(
                requestData.activity_type_id,
                optionList
              )
            );
          }
        }
        else {
          dispatch(workflowsFetchSuccess(fileData,fetchDataStr));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(workflowsFetchFail(err));
      })
  }
};

// Document Ref

export const documentFetchStart = (requestParams) => {
  return {
    type: actionTypes.DOCUMENT_FETCH_START,
    loadDocuments: requestParams.page_start === 0 ? true : false,

  };
};

export const documentFetchSuccess = (documentList,requestParams) => {
  return {
    type: actionTypes.DOCUMENT_FETCH_SUCCESS,
    documentList: documentList,
    loadDocuments: false,
    documentListParams: requestParams

  };
};

export const documentListFetchForReference = (documentList) => {
  return {
    type: actionTypes.DOCUMENTS_LIST_FOR_REFERENCE,
    documentList: documentList,

  };
};

export const documentFetchFail = (error) => {
  return {
    type: actionTypes.DOCUMENTS_FETCH_FAIL,
    error: error,
    loadDocuments: false,

  };
};

export const documentsFetch = (requestData,reference = null) => {

  return dispatch => {
    dispatch(documentFetchStart(requestData));

    const fetchDataStr = requestData;
    AxiosWeb.post('/document_reference/attachment_type/search',fetchDataStr)
      .then(res => {
        const fileData = res.data.response;

        //console.log(assetData.asset_id +" and "+requestData.assetId);
        if(reference && reference === 'select' && res.data.response.length > 0) {
          const FinalFileArray = [];
          fileData.forEach(value => {
            const FileJson = {
              label: value.activity_title,
              value: {
                activity_id: value.activity_id,
                activity_title: value.activity_title,
                activity_type_id: value.activity_type_id,
                activity_type_category_id: value.activity_type_category_id,
                //actvity_sub_type_id : value.activity_sub_type_id
              }
            }
            FinalFileArray.push(FileJson)
          })
          dispatch(documentListFetchForReference(FinalFileArray));
        }
        else {
          dispatch(documentFetchSuccess(fileData,fetchDataStr));
        }


      })
      .catch(err => {
        console.log(err);
        dispatch(documentFetchFail(err));
      })

  }
};

// asset Ref

export const assetsFetchStart = (requestParams) => {
  return {
    type: actionTypes.ASSET_FETCH_START,
    loadAssets: requestParams.page_start === 0 ? true : false,

  };
};
export const assetsFetchSuccess = (assetList,requestParams) => {
  return {
    type: actionTypes.ASSET_FETCH_SUCCESS,
    assetList: assetList,
    loadAssets: false,
    assetListParams: requestParams

  };
};
export const assetListFetchForReference = (asset_type_category_id,assetListOptions) => {
  let assetListRef = {asset_type_category_id,assetListOptions};
  return {
    type: actionTypes.ASSET_LIST_FOR_REFERENCE,
    assetListRef,
  };
};

export const assetsFetchFail = (error) => {
  return {
    type: actionTypes.ASSET_FETCH_FAIL,
    error: error,
    loadAssets: false,

  };
};

export const assetsFetch = (requestData,reference = null) => {

  return dispatch => {
    dispatch(assetsFetchStart(requestData));

    const fetchDataStr = requestData;
    AxiosWeb.post('/asset_reference/asset_type/search',fetchDataStr)
      .then(res => {
        const assetData = res.data.response;
        if(reference && reference === 'select') {
          const assetListOptions = [];
          assetData.forEach(value => {
            const {
              asset_first_name,
              asset_id,
              operating_asset_first_name,
              operating_asset_id
            } = value;
            const option = {
              label: value.operating_asset_first_name,
              value: value.operating_asset_id,
              details: {
                asset_id,
                asset_first_name,
                operating_asset_first_name,
                operating_asset_id
              }
            }
            assetListOptions.push(option)
          })
          dispatch(assetListFetchForReference(requestData.asset_type_category_id,assetListOptions));
        }
        else {
          dispatch(assetsFetchSuccess(assetData,fetchDataStr));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(assetsFetchFail(err));
      })

  }
};

export const fetchTimesheetDataStart = () => {
  return {
    type: actionTypes.FETCH_TIMESHEET_DATA_START
  };
};
export const fetchTimesheetDataSuccess = (timesheetData) => {
  return {
    type: actionTypes.FETCH_TIMESHEET_DATA_SUCCESS,
    timesheetData: timesheetData
  };
};

export const fetchTimesheetData = (requestParams) => {
  return dispatch => {
    dispatch(fetchTimesheetDataStart());

    AxiosWeb.post('/asset/timesheet',requestParams)
      .then(res => {
        // console.log("response",res)
        dispatch(fetchTimesheetDataSuccess(res.data.response))
      })
      .catch(err => {
        console.log("err",err)
      })
  }
}

export const addTimesheetData = (requestParams) => {
  return dispatch => {
    AxiosWeb.post('/asset/timesheet/add',requestParams)
      .then(res => {
        console.log("response",res)
      })
      .catch(err => {
        console.log("err",err)
      })
  }
}

export const fetchManagerResourceList = (requestParams) => {
  return dispatch => {
    dispatch(fetchManagerResourceListStart());
    AxiosWeb.post('/asset/mananger/resources/list',requestParams)
      .then(res => {
        dispatch(fetchManagerResourceListSuccess(res.data.response));
      })
      .catch(err => {
        dispatch(fetchManagerResourceListFailed());
        console.log("err",err)
      })
  }
}

export const fetchManagerResourceListStart = () => {
  return {
    type: actionTypes.MANAGER_RESOURCE_LIST_START,
    managerResourceList: []
  };
};

export const fetchManagerResourceListSuccess = (data) => {
  return {
    type: actionTypes.MANAGER_RESOURCE_LIST_SUCCESS,
    managerResourceList: data
  };
};

export const fetchManagerResourceListFailed = () => {
  return {
    type: actionTypes.MANAGER_RESOURCE_LIST_FAILED,
    managerResourceList: []
  };
};
export const fetchCompositLovState = (params) => {
  return (dispatch) => {
    dispatch(fetchCompositLovStateStart());
    AxiosWeb.post("/admin/lov-datatype/list/v1",params)
      .then((res) => {
        const list = res.data.response;
        const optionList = [];
        if(list.length > 0) {
          list.forEach((variant) => {
            const {lov_id,lov_name} = variant;
            let option = {
              label: variant.lov_name,
              value: variant.lov_id,
              details: {
                lov_id,
                lov_name,
              },
            };
            optionList.push(option);
          });
        }
        dispatch(fetchCompositLovStateSuccess(optionList));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchCompositLovStateStart = () => {
  return {
    type: actionTypes.FETCH_COMPOSIT_LOV_STATE_LIST_START,
  };
};

export const fetchCompositLovStateSuccess = (data) => {
  return {
    type: actionTypes.FETCH_COMPOSIT_LOV_STATE_LIST_SUCCESS,
    compositLovStateList: data,
  };
};

export const fetchCompositLovCity = (params) => {
  return (dispatch) => {
    dispatch(fetchCompositLovCityStart());
    AxiosWeb.post("/admin/lov-datatype/list/v1",params)
      .then((res) => {
        const list = res.data.response;
        const optionList = [];
        if(list.length > 0) {
          list.forEach((variant) => {
            const {lov_id,lov_name} = variant;
            let option = {
              label: variant.lov_name,
              value: variant.lov_id,
              details: {
                lov_id,
                lov_name,
              },
            };
            optionList.push(option);
          });
        }
        dispatch(fetchCompositLovCitySuccess(optionList));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchCompositLovCityStart = () => {
  return {
    type: actionTypes.FETCH_COMPOSIT_LOV_CITY_LIST_START,
  };
};

export const fetchCompositLovCitySuccess = (data) => {
  return {
    type: actionTypes.FETCH_COMPOSIT_LOV_CITY_LIST_SUCCESS,
    compositLovCityList: data,
  };
};

export const fetchCompositLovZipCode = (params) => {
  return (dispatch) => {
    dispatch(fetchCompositLovZipCodeStart());
    AxiosWeb.post("/admin/lov-datatype/list/v1",params)
      .then((res) => {
        const list = res.data.response;
        const optionList = [];
        if(list.length > 0) {
          list.forEach((variant) => {
            const {lov_id,lov_name} = variant;
            let option = {
              label: variant.lov_name,
              value: variant.lov_id,
              details: {
                lov_id,
                lov_name,
              },
            };
            optionList.push(option);
          });
        }
        dispatch(fetchCompositLovZipCodeSuccess(optionList));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchCompositLovZipCodeStart = () => {
  return {
    type: actionTypes.FETCH_COMPOSIT_LOV_ZIPCODE_LIST_START,
  };
};

export const fetchCompositLovZipCodeSuccess = (data) => {
  return {
    type: actionTypes.FETCH_COMPOSIT_LOV_ZIPCODE_LIST_SUCCESS,
    compositLovZipCodeList: data,
  };
};
export const fetchProductCategorySuccesss = (categoryList) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORY_SUCCESS,
    categoryList: categoryList,
  };
};

export const fetchProductCategoryStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORY_START,
  };
};
export const fetchProductCategories = (requestData) => {
  return (dispatch) => {
    dispatch(fetchProductCategoryStart());
    AxiosWeb.post("/admin/tag_type/mappings/list",requestData)
      .then((res) => {
        const categoryList = res.data.response;
        const optionList = [];
        if(categoryList.length > 0) {
          categoryList.forEach((category) => {
            const {
              tag_type_category_id,
              tag_type_category_name,
              tag_type_id,
              tag_type_name,
            } = category;
            let option = {
              label: category.tag_name,
              value: category.tag_id,
              details: {
                tag_type_category_id,
                tag_type_category_name,
                tag_type_id,
                tag_type_name,
              },
            };
            optionList.push(option);
          });
        }
        dispatch(fetchProductCategorySuccesss(optionList));
      })
      .catch((err) => console.log(err));
  };
};
export const fetchProductVariantsStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_VARIANTS_START,
  };
};

export const fetchProductVariantsSuccess = (productVariants) => {
  return {
    type: actionTypes.FETCH_PRODUCT_VARIANTS_SUCCESS,
    productVariants: productVariants,
  };
};

export const fetchProductVariants = (requestData) => {
  return (dispatch) => {
    dispatch(fetchProductVariantsStart());
    AxiosWeb.post("/activity/workflow/child-products/list",requestData)
      .then((res) => {
        const list = res.data.response;
        const optionList = [];
        if(list.length > 0) {
          list.forEach((variant) => {
            const {activity_cuid_2,activity_cuid_3} = variant;
            let option = {
              label: variant.activity_title,
              value: variant.activity_id,
              details: {
                activity_cuid_2,
                activity_cuid_3,
              },
            };
            optionList.push(option);
          });
        }
        dispatch(fetchProductVariantsSuccess(optionList));
      })
      .catch((err) => console.log(err));
  };
};
export const fetchProductWorkflowTypeStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_WORKFLOW_TYPE_START,
  };
};

export const fetchProductWorkflowTypeSuccess = (workflowTypes) => {
  return {
    type: actionTypes.FETCH_PRODUCT_WORKFLOW_TYPE_SUCCESS,
    workflowTypes: workflowTypes,
  };
};

export const fetchProductWorkflowTypes = (requestData) => {
  return (dispatch) => {
    dispatch(fetchProductWorkflowTypeStart());
    AxiosWeb.post("/admin/tag/entity-mappings/list",requestData)
      .then((res) => {
        const list = res.data.response;
        const optionList = [];
        if(list.length > 0) {
          list.forEach((workflowType) => {
            const {
              activity_type_category_id,
              activity_type_category_name,
              tag_id,
              tag_type_id,
            } = workflowType;
            let option = {
              label: workflowType.activity_type_name,
              value: workflowType.activity_type_id,
              details: {
                activity_type_category_id,
                activity_type_category_name,
                tag_id,
                tag_type_id,
              },
            };
            optionList.push(option);
          });
        }
        dispatch(fetchProductWorkflowTypeSuccess(optionList));
      })
      .catch((err) => console.log(err));
  };
};
export const fetchProductWorkflowsStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_WORKFLOW_START,
  };
};

export const fetchProductWorkflowsSuccess = (workflows) => {
  return {
    type: actionTypes.FETCH_PRODUCT_WORKFLOW_SUCCESS,
    workflows: workflows,
  };
};

export const fetchProductWorkflows = (requestData) => {
  return (dispatch) => {
    dispatch(fetchProductWorkflowsStart());
    AxiosWeb.post("/workflow/activity-reference/list",requestData)
      .then((res) => {
        const list = res.data.response;
        const optionList = [];
        if(list.length > 0) {
          list.forEach((workflow) => {
            const {
              activity_type_category_id,
              activity_type_category_name,
              activity_type_id,
              activity_type_name,
              activity_datetime_start_expected,
              activity_datetime_end_deferred,
              activity_workbook_url,
            } = workflow;
            let option = {
              label: workflow.activity_title,
              value: workflow.activity_id,
              details: {
                activity_type_category_id,
                activity_type_category_name,
                activity_type_id,
                activity_type_name,
                activity_datetime_start_expected,
                activity_datetime_end_deferred,
                activity_workbook_url,
              },
            };
            optionList.push(option);
          });
        }
        dispatch(fetchProductWorkflowsSuccess(optionList));
      })
      .catch((err) => console.log(err));
  };
};

export const fetchSlotList = (params) => {
  return (dispatch) => {
    dispatch(fetchSlotListStart());
    AxiosWeb.post("/admin/organization/account/list",params)
      .then((res) => {
        const list = res.data.response;
        const optionList = [];
        if(list.length > 0) {
          list.forEach((variant) => {
            if(variant.account_inline_data) {
              JSON.parse(variant.account_inline_data).timeslots.forEach(
                (slot) => {
                  const {
                    slot_duration,
                    slot_start_time,
                    slot_end_time,
                  } = slot;
                  let option = {
                    label: slot.slot_start_time + " - " + slot.slot_end_time,
                    value: slot.slot_start_time + " - " + slot.slot_end_time,
                    details: {
                      slot_duration,
                      slot_start_time,
                      slot_end_time,
                    },
                  };
                  optionList.push(option);
                }
              );
            }
          });
        }
        dispatch(fetchSlotListSuccess(optionList));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchSlotListStart = () => {
  return {
    type: actionTypes.FETCH_COMPOSIT_SLOT_LIST_START,
    slotListData: [],
  };
};

export const fetchSlotListSuccess = (slotData) => {
  return {
    type: actionTypes.FETCH_COMPOSIT_SLOT_LIST_SUCCESS,
    slotListData: slotData,
  };
};
export const lovListFetchForReference = (requestParams) => {
  return (dispatch) => {
    dispatch(lovFetchStart());
    AxiosWeb.post("/admin/lov-datatype/list",requestParams)
      .then((res) => {
        const fileData = res.data.response;
        const optionList = [];
        fileData.forEach((value) => {
          const {lov_id,lov_name} = value;
          const option = {
            label: lov_name,
            value: lov_name, // lov_id, //`${lov_id}|${lov_name}`,
            details: {
              lov_name,
              lov_id,
            },
          };
          optionList.push(option);
        });
        dispatch(lovFetchSuccess(optionList));
      })
      .catch((err) => console.log(err));
  };
};

export const lovFetchStart = () => {
  return {
    type: actionTypes.LOV_DATATYPE_LIST_START,
    lovListRef: [],
  };
};

export const lovFetchSuccess = (lovListRef) => {
  return {
    type: actionTypes.LOV_DATATYPE_LIST,
    lovListRef: lovListRef,
  };
};
export const workflowsClearStore = () => {
  return {
    type: actionTypes.WORKFLOWS_CLEAR_STORE,
  };
};
export const workflowsFetchByTags = (
  requestData,
  reference = null,
  target = null
) => {
  return (dispatch) => {
    dispatch(workflowsFetchStart(requestData));

    const fetchDataStr = requestData;
    AxiosWeb.post("/workflow_reference/activity_type/search/v1",fetchDataStr)
      .then((res) => {
        const fileData = res.data.response;
        if(!!fileData) {
          if(reference && reference === "select") {
            const optionList = [];
            fileData.forEach((value) => {
              const {
                activity_id,
                activity_title,
                activity_cuid_1,
                activity_cuid_2,
                activity_cuid_3,
                activity_creator_operating_asset_first_name,
              } = value;
              const option = {
                label: activity_title,
                value: activity_id,
                details: {
                  activity_id,
                  activity_title,
                  activity_cuid_1,
                  activity_cuid_2,
                  activity_cuid_3,
                  operating_asset_first_name: activity_creator_operating_asset_first_name,
                },
              };
              optionList.push(option);
            });
            if(target == 62) {
              let wList = optionList.map((item) => ({
                label: item.label,
                value: `${item.label}|${item.value}`,
                details: item.details,
              }));
              dispatch(workflowListFetchForCredit(wList));
            } else {
              dispatch(
                workflowListFetchForReference(
                  requestData.activity_type_id,
                  optionList
                )
              );
            }
          } else {
            dispatch(workflowsFetchSuccess(fileData,fetchDataStr));
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(workflowsFetchFail(err));
      });
  };
};