import CryptoJS from "crypto-js";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { geolocated } from "react-geolocated";
import config from "react-global-configuration";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import Axiosweb from "../../axios_ext";
import * as utils from "../../shared/Utils";
import * as actions from "../../store/Actions/index";
import VilLogo from "./../../assets/images/vil/vi_logo_large_white.png";
import InputElement from "./FormFields/FormFields";
import "./FormLayout.scss";
import validateFormFields from "../FormPreview/FormDetail/config/form.validation.utils";
import GreneLogo from "../../assets/images/logo.png";
import GreneLoader from "../../components/GreneLoader/GreneLoader";

class FormLayout extends Component {
  state = {
    formIsValid: false,
    loading: false,
    urlData: {},
    uploading: false,
    dataSubmitted: false,
    formFieldsValidation: false,
    allFormFields: null,
    smartFormFields: null,
    lastField: false,
  };

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  buildFormFieldFetchParam = (
    orgId,
    accId,
    workforceId,
    formId,
    assetId,
    assetAuthToken
  ) => {
    return {
      organization_id: orgId,
      account_id: accId,
      workforce_id: workforceId,
      form_id: formId,
      start_from: 0,
      limit_value: 50,
      asset_id: assetId,
      asset_token_auth: assetAuthToken,
      showAllFields: true,
    };
  };

  buildCheckFormSubmit = (
    orgId,
    accId,
    workforceId,
    formId,
    assetId,
    assetAuthToken,
    activityId
  ) => {
    return {
      organization_id: orgId,
      account_id: accId,
      workforce_id: workforceId,
      asset_id: assetId,
      asset_token_auth: assetAuthToken,
      activity_id: activityId,
      message_unique_id: utils.getMessageUniqueId(assetId),
      form_id: formId,
      limit_value: 50,
    };
  };

  buildFormAddParams = () => {
    let activityAccessRoleId = 21;
    let activityStatusTypeId = 22;
    let fileActivityId = 0;

    let tempArray = [];
    const formElementsArray = [];
    for (let key in this.props.fieldList) {
      formElementsArray.push({
        id: key.slice(0, -1),
        config: this.props.fieldList[key],
      });
    }
    formElementsArray.forEach((field) => {
      // for attachments
      if (
        !!field.config.fileUploadData &&
        (field.config.elementCatType === 11 ||
          field.config.elementCatType === 12 ||
          field.config.elementCatType === 13)
      ) {
        // let prefixPath = this.state.urlData.organization_id + '/' + this.state.urlData.account_id + '/' + this.state.urlData.workforce_id + '/' + this.state.urlData.asset_id + '/' + utils.getYear(new Date()) + '/' + utils.getMonth(new Date());
        // let preFixUrl = 'https://deskertech.s3.amazonaws.com/' + prefixPath + '/' + field.config.fileUploadData.name;
        tempArray.push({
          form_id: this.state.urlData.form_id,
          field_id: field.id,
          field_name: field.config.label,
          field_data_type_id: field.config.elementType,
          field_data_type_category_id: field.config.elementCatType,
          data_type_combo_id: 0,
          data_type_combo_value: "0",
          field_value: field.config.fileUploadData,
          message_unique_id: utils.getMessageUniqueId(
            this.state.urlData.asset_id
          ),
        });
      } else if (field.config.elementCatType === 14) {
        // dropdown
        let data_type_combo_id_Arr = field.config.elementConfig.options;
        let data_type_combo_id_parsed = data_type_combo_id_Arr.findIndex(
          (res) => res.value === field.config.value
        );
        tempArray.push({
          form_id: this.state.urlData.form_id,
          field_id: field.id,
          field_name: field.config.label,
          field_data_type_id: field.config.elementType,
          field_data_type_category_id: field.config.elementCatType,
          data_type_combo_id: data_type_combo_id_parsed + 1,
          data_type_combo_value: field.config.value,
          field_value: field.config.value,
          message_unique_id: utils.getMessageUniqueId(
            this.state.urlData.asset_id
          ),
        });
      } else if (field.config.elementCatType === 4) {
        // File Reference

        if (
          field.config.elementType === 57 ||
          field.config.elementType === 59
        ) {
          tempArray.push({
            form_id: this.state.urlData.form_id,
            field_id: field.id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: field.config.fileUploadData,
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        } else if (
          field.config.elementType === 62 &&
          field.config.fileUploadData
        ) {
          let formatted_value = {
            transaction_data: {
              activity_id: field.config.fileUploadData.activity_id,
              activity_name: field.config.fileUploadData.activity_title,
              activity_type_id: field.config.fileUploadData.activity_type_id,
              activity_type_name:
                field.config.validation.rules.workflow_reference_restriction
                  .activity_type_name,
              transaction_type_id: field.config.value.transaction_type_id,
              transaction_type_name:
                field.config.value.transaction_type_id == 1
                  ? "Credit"
                  : "Debit",
              transaction_amount: field.config.value.transaction_amount,
              transaction_note: field.config.value.transaction_note,
              transaction_datetime: new Date()
                .toJSON()
                .slice(0, 19)
                .replace("T", " "),
              transaction_location_data: {
                location_latitude:
                  this.props.userCoords !== null
                    ? this.props.userCoords.lat
                    : 0,
                location_longitude:
                  this.props.userCoords !== null
                    ? this.props.userCoords.long
                    : 0,
                location_gps_enabled: 1,
                location_accuracy: "",
                location_address: "",
              },
            },
          };
          tempArray.push({
            form_id: this.state.urlData.form_id,
            field_id: field.id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: formatted_value,
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        } else if (field.config.elementType === 62) {
          let activity = field.config.fileUploadData
            ? field.config.fileUploadData
            : null;
          activity = !!activity ? `${activity}`.split("|") : null;
          let formatted_value = {
            transaction_data: {
              activity_id: activity ? activity[1] : null,
              activity_name: activity ? activity[0] : null,
              activity_type_id:
                field.config.validation.rules === null
                  ? ""
                  : field.config.validation.rules.workflow_reference_restriction
                      .activity_type_id,
              activity_type_name:
                field.config.validation.rules === null
                  ? ""
                  : field.config.validation.rules.workflow_reference_restriction
                      .activity_type_name,
              transaction_type_id: field.config.value.transaction_type_id,
              transaction_type_name:
                field.config.value.transaction_type_id == 1
                  ? "Credit"
                  : "Debit",
              transaction_amount: field.config.value.transaction_amount,
              transaction_note: field.config.value.transaction_note,
              transaction_datetime: new Date()
                .toJSON()
                .slice(0, 19)
                .replace("T", " "),
              transaction_location_data: {
                location_latitude:
                  this.props.userCoords !== null
                    ? this.props.userCoords.lat
                    : 0,
                location_longitude:
                  this.props.userCoords !== null
                    ? this.props.userCoords.long
                    : 0,
                location_gps_enabled: 1,
                location_accuracy: "",
                location_address: "",
              },
            },
          };
          tempArray.push({
            form_id: this.props.formData.form_id,
            field_id: field.id,
            field_reference_id: field.field_reference_id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: formatted_value,
            message_unique_id: utils.getMessageUniqueId(
              this.props.formData.asset_id
            ),
          });
        } else if (field.config.elementType === 71) {
          let fieldValue = field.config.value;
          let variants = field.config.value.product_variant;
          let productVariants =
            variants && variants.length
              ? variants.map((variant) => {
                  return {
                    product_variant_activity_id: variant.value,
                    product_variant_activity_title: variant.label,
                    product_variant_sku_code: variant.details.activity_cuid_2,
                    product_variant_unit_price: variant.details.activity_cuid_3,
                    product_variant_quantity: variant.quantity,
                    product_variant_total_cost: variant.productPrice,
                  };
                })
              : [];
          let inline_data = {
            product_tag_type_id:
              fieldValue.product_category.details.tag_type_id,
            product_tag_type_name:
              fieldValue.product_category.details.tag_type_name,
            product_tag_id: fieldValue.product_category.value,
            product_tag_name: fieldValue.product_category.label,
            product_activity_type_id:
              fieldValue.product_workflow.details.activity_type_id,
            product_activity_type_name:
              fieldValue.product_workflow.details.activity_type_name,
            product_activity_id: fieldValue.product_workflow.value,
            product_activity_title: fieldValue.product_workflow.label,
            product_activity_business_case:
              fieldValue.product_workflow.details.activity_workbook_url,
            cart_items: productVariants,
            cart_total_cost: fieldValue.cart_value,
          };
          tempArray.push({
            form_id: this.state.urlData.form_id, //this.props.formData.form_id,
            field_id: field.id,
            field_reference_id: field.field_reference_id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: JSON.stringify(inline_data),
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        } else {
          let formatted_value = {
            activity_id: field.config.fileUploadData.activity_id,
            inline_data: {
              activity_title: field.config.fileUploadData.activity_title,
              activity_type_id: field.config.fileUploadData.activity_type_id,
              activity_type_name: "Form",
              activity_type_category_id:
                field.config.fileUploadData.activity_type_category_id,
              activity_type_category_name: "Form",
            },
          };

          tempArray.push({
            form_id: this.state.urlData.form_id,
            field_id: field.id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: formatted_value,
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        }
      } else if (field.config.elementCatType === 10) {
        // Phone Number

        tempArray.push({
          form_id: this.state.urlData.form_id,
          field_id: field.id,
          field_name: field.config.label,
          field_data_type_id: field.config.elementType,
          field_data_type_category_id: field.config.elementCatType,
          data_type_combo_id: 0,
          data_type_combo_value: "0",
          field_value: "+91|" + field.config.value,
          message_unique_id: utils.getMessageUniqueId(
            this.state.urlData.asset_id
          ),
        });
      } else {
        if (field.config.elementType === 53) {
          tempArray.push({
            form_id: this.state.urlData.form_id,
            field_id: field.id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: this.props.userIp,
            //field_value: { "ip_address_data": { "flag_ip_address_available": this.props.userIp !== null ? 1 : 0, "ip_address": this.props.userIp } },
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        } else if (field.config.elementType === 17) {
          tempArray.push({
            form_id: this.state.urlData.form_id,
            field_id: field.id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value:
              this.props.userCoords !== null
                ? this.props.userCoords.lat + ", " + this.props.userCoords.long
                : "NA",
            //field_value: { "location_data": { "flag_location_available": this.props.userCoords !== null ? 1 : 0 , "location_latitude": this.props.userCoords.lat, "location_longitude": this.props.userCoords.long } },
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        } else if (field.config.elementType == 65) {
          tempArray.push({
            form_id: this.props.formData.form_id,
            field_id: field.id,
            field_reference_id: field.field_reference_id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: JSON.stringify(field.config.value),
            message_unique_id: utils.getMessageUniqueId(
              this.props.formData.asset_id
            ),
          });
        } else if (field.config.elementType == 60) {
          tempArray.push({
            form_id: this.props.formData.form_id,
            field_id: field.id,
            field_reference_id: field.field_reference_id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: JSON.stringify(field.config.value),
            message_unique_id: utils.getMessageUniqueId(
              this.props.formData.asset_id
            ),
          });
        } else if (field.config.elementType === 4) {
          //Date Time
          tempArray.push({
            form_id: this.state.urlData.form_id,
            field_id: field.id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value:
              field.config.value !== ""
                ? field.config.value.replace("T", " ").concat(":00")
                : field.config.value,
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        } else {
          tempArray.push({
            form_id: this.state.urlData.form_id,
            field_id: field.id,
            field_name: field.config.label,
            field_data_type_id: field.config.elementType,
            field_data_type_category_id: field.config.elementCatType,
            data_type_combo_id: 0,
            data_type_combo_value: "0",
            field_value: field.config.value,
            message_unique_id: utils.getMessageUniqueId(
              this.state.urlData.asset_id
            ),
          });
        }
      }
    });

    let mail_body_content = "Form Submitted";
    let mail_body_Form = this.props.emailFormName; //tempArray[0].field_value;
    let activityTimeline = {
      mail_body: mail_body_Form,
      asset_reference: [
        {
          account_id: "",
          organization: "",
          asset_id: "",
          asset_first_name: "",
          asset_type_category_id: "",
          asset_last_name: "",
          asset_image_path: "",
        },
      ],
      activity_reference: [
        {
          activity_title: "",
          activity_id: "",
        },
      ],
      form_approval_field_reference: [],
      subject: mail_body_Form,
      attachments: [],
      content: mail_body_content,
      form_submitted: tempArray,
      form_id: this.state.urlData.form_id,
    };
    const addParticipantInlineData = [
      {
        access_role_id: 22,
        account_id: this.state.urlData.account_id,
        activity_id: this.state.urlData.activity_id,
        asset_datetime_last_seen: "1970-01-01 00:00:00",
        asset_first_name: this.state.urlData.asset_first_name,
        asset_id: this.state.urlData.asset_id,
        asset_image_path: "",
        asset_last_name: "",
        asset_phone_number: this.state.urlData.asset_phone_number,
        asset_phone_number_code: 91,
        asset_type_category_id: 3,
        asset_type_id: 133001,
        field_id: 0,
        log_asset_id: this.state.urlData.asset_id,
        message_unique_id: utils.getMessageUniqueId(
          this.state.urlData.asset_id
        ),
        operating_asset_first_name:
          this.state.urlData.operating_asset_first_name,
        organization_id: this.state.urlData.organization_id,
        workforce_id: this.state.urlData.workforce_id,
      },
    ];

    const params = {
      organization_id: this.state.urlData.organization_id,
      account_id: this.state.urlData.account_id,
      workforce_id: this.state.urlData.workforce_id,
      asset_id: this.state.urlData.asset_id,
      auth_asset_id: this.state.urlData.auth_asset_id,
      asset_token_auth: this.state.urlData.asset_token_auth,
      asset_message_counter: 0,
      activity_id: this.state.urlData.activity_id,
      activity_title: utils.getCurrentTimeDateIST() + this.props.emailFormName, //tempArray[0].field_value,
      activity_description: JSON.stringify(tempArray[0].field_value),
      activity_inline_data: JSON.stringify(tempArray),
      activity_participant_collection: JSON.stringify(addParticipantInlineData),
      //activity_inline_data: JSON.stringify(activityTimeline),
      activity_datetime_start: utils.getCurrentDateUTC(),
      activity_datetime_end: utils.getCurrentDateUTC(),
      activity_type_category_id: 9,
      activity_sub_type_id: 0,
      activity_type_id: this.state.urlData.activity_type_id_form,
      activity_access_role_id: activityAccessRoleId, //27
      //activity_status_id: statusType[0].activity_status_id,
      activity_status_type_category_id: 1,
      activity_status_type_id: activityStatusTypeId, //74
      asset_participant_access_id: 21,
      activity_flag_file_enabled: -1,
      activity_parent_id: 0,
      activity_form_id: this.state.urlData.form_id,
      flag_pin: 0,
      flag_offline: 0,
      flag_retry: 0,
      message_unique_id: utils.getMessageUniqueId(this.state.urlData.asset_id),
      track_latitude: "0.0",
      track_longitude: "0.0",
      track_altitude: 0,
      track_gps_datetime: utils.getCurrentDateUTC(),
      track_gps_accuracy: "0",
      track_gps_status: 0,
      service_version: 1.0,
      app_version: 1.0,
      api_version: 1.0,
      device_os_id: 5,

      //for timeline entry //
      activity_stream_type_id: 705,
      //activity_stream_type_id: 325,
      form_transaction_id: 0,
      form_id: this.state.urlData.form_id,
      //activity_timeline_collection: JSON.stringify(tempArray),
      activity_timeline_collection: JSON.stringify(activityTimeline),
      //data_entity_inline: JSON.stringify(activityTimeline),
      data_entity_inline: JSON.stringify(tempArray),
      activity_timeline_text: "",
      activity_timeline_url: "",
      flag_timeline_entry: 1,
      file_activity_id: fileActivityId,
      workflow_activity_id: this.state.urlData.activity_id,
      workflow_activity_type_id: this.state.urlData.workflow_activity_type_id,
    };
    return params;
  };

  // inputChangedHandler = (event, inputIdentifier) => {
  //   const updatedOrderForm = {
  //     ...this.state.orderForm,
  //   };
  //   const updatedFormElement = {
  //     ...updatedOrderForm[inputIdentifier],
  //   };
  //   updatedFormElement.value = event.target.value;
  //   updatedFormElement.touched = true;
  //   updatedOrderForm[inputIdentifier] = updatedFormElement;
  //   this.setState({ orderForm: updatedOrderForm, formIsValid: true });
  // };

  async componentDidMount() {
    localStorage.setItem("x-grene-header-disable", true);
    localStorage.setItem("x-grene-form-view", true);
    localStorage.setItem("x-form-pass", true);
    this.props.onGetUserIp();
    let urlRequestData = JSON.parse(atob(this.props.match.params.formsdata));

    if (urlRequestData.hasOwnProperty("uuid")) {
      let requestData = {};
      requestData.device_os_id = 5;
      requestData.access_key_type_id = 1;
      requestData.asset_id = 31347;
      requestData.auth_asset_id = 31347;
      requestData.asset_token_auth = "05986bb0-e364-11e8-a1c0-0b6831833754";
      requestData.url_id = urlRequestData.url_id;
      requestData.url_uuid = urlRequestData.uuid;
      requestData.organization_id = urlRequestData.organization_id;
      requestData.preview = true;
      // localStorage.setItem("preview","true")
      await Axiosweb.post("/url/parameters/lookup", requestData).then((res) => {
        if (!!res.data.response[0]) {
          urlRequestData = JSON.parse(res.data.response[0].url_form_data);
        }
      });
    }

    let orgId = urlRequestData.organization_id;
    let accId = urlRequestData.account_id;
    let workforceId = urlRequestData.workforce_id;
    let formId = urlRequestData.form_id;
    let authAssetId = urlRequestData.auth_asset_id;
    let assetAuthToken = urlRequestData.asset_token_auth;
    let activityId = urlRequestData.activity_id;

    if (
      orgId !== "" &&
      accId !== "" &&
      workforceId !== "" &&
      formId !== "" &&
      authAssetId !== "" &&
      assetAuthToken !== "" &&
      activityId !== ""
    ) {
      this.props.onCheckFormActivityId(
        this.buildCheckFormSubmit(
          orgId,
          accId,
          workforceId,
          formId,
          authAssetId,
          assetAuthToken,
          activityId
        ),
        this.buildFormFieldFetchParam(
          orgId,
          accId,
          workforceId,
          formId,
          authAssetId,
          assetAuthToken
        )
      );
      //Fetch the S3 Credentials
      let requestData = {};
      requestData.device_os_id = 5;
      requestData.access_key_type_id = 1;
      requestData.asset_id = urlRequestData.auth_asset_id;
      requestData.asset_token_auth = urlRequestData.asset_token_auth;
      Axiosweb.post("aws_resources/credentials/fetch", requestData).then(
        (res) => {
          if (!!res.data.response[0]) {
            const credentials = res.data.response;
            let decryptThis = credentials[0].access_key_inline_data;
            var bytes = CryptoJS.AES.decrypt(
              decryptThis.toString(),
              "lp-n5^+8M@62"
            );
            var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            decryptedData = JSON.parse(decryptedData);
            config.set(
              {
                s3_access_key: decryptedData.access_key,
                s3_secret_key: decryptedData.secret_key,
              },
              {
                freeze: false,
              }
            );
            this.setState({
              ...this.state,
              urlData: urlRequestData,
            });
            this.props.onFormFieldsFetch(
              this.buildFormFieldFetchParam(
                orgId,
                accId,
                workforceId,
                formId,
                authAssetId,
                assetAuthToken
              )
            );
          }
        }
      );
    }

    var months = ["Jan", "Feb", "March", "April", "June"];
    months.unshift(" twd", "teree");
  }

  componentDidUpdate() {
    if (this.props.coords !== null && this.props.userCoords === null) {
      this.props.onGetUserCoords({
        lat: this.props.coords.latitude,
        long: this.props.coords.longitude,
        altitude: this.props.coords.altitude,
        heading: this.props.coords.heading,
        speed: this.props.coords.speed,
      });
    }
  }

  addFormAction(requestParams) {
    let oldActivityId = requestParams.activity_id;
    Axiosweb.post("activity/add/v1", requestParams)
      .then((res) => {
        requestParams.activity_id = res.data.response.activity_id;
        requestParams.form_transaction_id =
          res.data.response.form_transaction_id;
        requestParams.message_unique_id = utils.getMessageUniqueId(
          this.state.urlData.asset_id
        );
        this.addFormTimelineAction(requestParams, oldActivityId);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addFormTimelineAction(requestTimeLineParams, oldActivityId = null) {
    requestTimeLineParams.message_unique_id = utils.getMessageUniqueId(
      this.state.urlData.asset_id
    );
    if (oldActivityId) {
      requestTimeLineParams.activity_id = oldActivityId;
    }
    requestTimeLineParams.activity_type_category_id = 48;
    Axiosweb.post("activity/timeline/entry/add", requestTimeLineParams)
      .then((res) => {
        this.setState({
          ...this.state,
          uploading: false,
          dataSubmitted: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addParticipant(requestTimeLineParams) {
    requestTimeLineParams.message_unique_id = utils.getMessageUniqueId(
      this.state.urlData.asset_id
    );
    Axiosweb.post("activity/participant/access/set", requestTimeLineParams)
      .then((res) => {
        console.log("Participant added!");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  isValidate(element, index, array) {
    if (element.config.validation.required) {
      if (
        element.config.elementCatType === 11 ||
        element.config.elementCatType === 12 ||
        element.config.elementCatType === 13 ||
        element.config.elementCatType === 4
      ) {
        if ([65, 71].includes(element.config.elementType)) {
          if (element.config.value != "") {
            return true;
          } else {
            return false;
          }
        } else {
          if (element.config.fileUploadData != "") {
            return true;
          } else {
            return false;
          }
        }
      } else if (
        element.config.elementType == 53 ||
        element.config.elementType == 17 ||
        element.config.elementType === 19 ||
        element.config.elementType === 6 ||
        element.config.elementType === 20 ||
        element.config.elementType === 33 ||
        element.config.elementType === 21 ||
        element.config.elementType === 59 ||
        element.config.elementType === 68
      ) {
        return true;
      } else {
        if (element.config.value != "") {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  formSubmitHandler = (event) => {
    event.preventDefault();
    let requestCheckParams = this.buildCheckFormSubmit(
      this.state.urlData.organization_id,
      this.state.urlData.account_id,
      this.state.urlData.workforce_id,
      this.state.urlData.form_id,
      this.state.urlData.auth_asset_id,
      this.state.urlData.asset_token_auth,
      this.state.urlData.activity_id
    );
    Axiosweb.post("activity/form_transaction/check", requestCheckParams)
      .then((res) => {
        const checkData = res.data.response;
        if (checkData.length > 0) {
          alert("Already Submitted Refresh Browser to get form details");
        } else {
          const formElementsArray1 = [];
          for (let key in this.props.fieldList) {
            const fieldTypeId = this.props.fieldList[key].elementType;
            const fieldValue = this.props.fieldList[key].value;
            const fieldCatType = this.props.fieldList[key].elementCatType;
            const isRequired = this.props.fieldList[key].validation
              ? this.props.fieldList[key].validation.required
              : false;
            const fieldId = this.props.fieldList[key].field_id;
            let rules =
              this.props.fieldList[key] && this.props.fieldList[key].validation
                ? this.props.fieldList[key].validation.rules
                : {};
            let validationResult = validateFormFields(
              fieldTypeId,
              fieldValue,
              fieldCatType,
              isRequired,
              fieldId,
              rules
            );
            if (validationResult.isValid) {
              formElementsArray1.push({
                id: key,
                config: this.props.fieldList[key],
              });
            } else {
              this.setState({
                ...this.state,
                formFieldsValidation: true,
              });
              return false;
            }
          }
          console.log("formElementsArray1", formElementsArray1);
          console.log("validation2", formElementsArray1.every(this.isValidate));
          if (formElementsArray1.every(this.isValidate)) {
            let requestParams = {
              organization_id: this.state.urlData.organization_id,
              account_id: this.state.urlData.account_id,
              workforce_id: this.state.urlData.workforce_id,
              asset_id: this.state.urlData.asset_id,
              asset_token_auth: this.state.urlData.asset_token_auth,
              parent_activity_id: this.state.urlData.activity_id,
              flag: -1,
              sort_flag: 1,
              datetime_start: "1970-01-01 00:00:00",
              datetime_end: utils.getCurrentDateUTC(),
              start_from: 0,
              limit_value: 50,
            };

            Axiosweb.post("/activity/workflow/child_orders/list", requestParams)
              .then((res) => {
                let resp = res.data.response[0];
                if (resp.count > 0) {
                  alert(
                    "Please close all child orders before closing Parent Order!!"
                  );
                } else {
                  this.setState({
                    ...this.state,
                    uploading: true,
                    formFieldsValidation: false,
                  });
                  let addParams = this.buildFormAddParams();
                  let requestTimelineParams = this.buildFormAddParams();
                  this.addFormAction(addParams);
                  this.addParticipant(requestTimelineParams);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.setState({
              ...this.state,
              formFieldsValidation: true,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setFormFieldsToLocalState = () => {
    let formElementsArray = {};
    let cnt = 0;
    for (let key in this.props.fieldList) {
      formElementsArray[key] = this.props.fieldList[key];
      if (formElementsArray[key].nextFieldId !== "0i") {
        cnt++;
        break;
      }
    }
    cnt === 0
      ? this.setState({
          ...this.state,
          lastField: true,
          allFormFields: this.props.fieldList,
          smartFormFields: formElementsArray,
        })
      : this.setState({
          ...this.state,
          allFormFields: this.props.fieldList,
          smartFormFields: formElementsArray,
        });
  };

  fetchNextField = () => {
    let smartFormFields = this.state.smartFormFields;
    let lastObject =
      smartFormFields[
        Object.keys(smartFormFields)[Object.keys(smartFormFields).length - 1]
      ];
    if (lastObject.nextFieldId === "0i") {
      let formElementsArray = this.state.smartFormFields;
      let currObj;
      let cnt = 0;
      for (let key in this.props.fieldList) {
        currObj = this.props.fieldList[key];
        if (currObj.fieldSequenceId > lastObject.fieldSequenceId) {
          formElementsArray[key] = this.props.fieldList[key];
          if (formElementsArray[key].nextFieldId !== "0i") {
            cnt++;
            break;
          }
        }
      }
      if (cnt === 0) {
        this.setState({
          ...this.state,
          lastField: true,
        });
      } else {
        this.setState({
          ...this.state,
          smartFormFields: formElementsArray,
        });
      }
    } else if (lastObject.nextFieldId === "-1i") {
      this.setState({
        ...this.state,
        lastField: true,
      });
    } else {
      let formElementsArray = this.state.smartFormFields;
      for (let key in this.props.fieldList) {
        if (key === lastObject.nextFieldId) {
          formElementsArray[key] = this.props.fieldList[key];
          if (formElementsArray[key].elementCatType !== 8) {
            break;
          }
        }
      }
      this.setState({
        ...this.state,
        smartFormFields: formElementsArray,
      });
    }
  };

  enableSubmitButton = () => {
    this.setState({
      ...this.state,
      lastField: null,
    });
  };
  // inputChangedHandler=(event,field)=>{
  //   this.props.inputChangedHandler(
  //     event,
  //     field,
  //     null,
  //     null
  //   );
  // }
  render() {
    let urlRequestData = JSON.parse(atob(this.props.match.params.formsdata));
    console.log("urdat", this.state.urlData);
    if (isMobile) {
      return (
        <div>
          <h3>Please open in Desktop browser</h3>
        </div>
      );
    }

    let formName = "";
    if (this.props.emailFormName !== null) {
      formName = utils.getCleanValue(this.props.emailFormName);
    }
    const formElementsArray = [];
    let formFields = null;
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key],
      });
    }

    if (Object.keys(this.props.fieldList).length > 0) {
      if (this.state.allFormFields === null) {
        this.setFormFieldsToLocalState();
      } else {
        const formElementsArray = [];
        for (let key in this.state.smartFormFields) {
          formElementsArray.push({
            id: key,
            config: this.props.fieldList[key],
          });
        }
        formFields = formElementsArray.map((formElement) => (
          <InputElement
            key={formElement.id}
            elementTypeId={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            label={utils.getCleanValue(formElement.config.label)}
            entry="external"
            urlData={this.state.urlData}
            changed={(event, field = null) => {
              this.props.inputChangedHandler(
                event,
                formElement.id,
                formElement.config.elementType,
                field
              );
            }}
          />
        ));
      }
    }

    let returnContent = "";
    let actionButtons = "";
    if (!urlRequestData.disabledProperty) {
      if (this.props.loadingFields) {
        actionButtons = "";
      } else {
        actionButtons = (
          <tr>
            <td colSpan={2} className="form-action">
              <button
                type="submit"
                disabled={!this.props.formValidated}
                className="action btn btn-outline-primary"
              >
                {this.state.uploading ? "Submitting..." : "Submit"}
              </button>
            </td>
          </tr>
        );
      }
    }
    if (this.state.dataSubmitted) {
      returnContent = (
        <div className="submit-msg">
          {" "}
          Your Form has been Submitted successfully.
        </div>
      );
    } else if (Number(this.props.checkFormActivityData) === 0) {
      //(true){  //
      returnContent = (
        <form onSubmit={this.formSubmitHandler}>
          <table className="Formtable">
            <tbody>
              {this.props.loadingFields ? (
                <tr>
                  <td colSpan="2">
                    <PulseLoader color={"#000000"} loading={true} />
                  </td>
                </tr>
              ) : (
                formFields
              )}
              {actionButtons}
              {this.state.formFieldsValidation ? (
                <tr>
                  <td colSpan={2}>
                    <span style={{ color: "red" }}>
                      Please fill all the mandatory(*) fields.
                    </span>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </form>
      );
    } else {
      if (this.state.urlData !== null) {
        const JsonStr = {
          form_transaction_id:
            this.props.checkFormActivityData[0].data_form_transaction_id, //Add
          form_id: this.state.urlData.form_id,
          org_id: this.state.urlData.organization_id, // change formate
          account_id: this.state.urlData.account_id,
          workforce_id: this.state.urlData.workforce_id,
          asset_id: this.state.urlData.auth_asset_id,
          auth_asset_id: this.state.urlData.auth_asset_id,
          auth_token: this.state.urlData.asset_token_auth, // change formate
          form_name: this.props.checkFormActivityData[0].data_form_name, // add this
          activity_id: this.state.urlData.activity_id,
          s3BucketName: this.state.urlData.s3BucketName,
        };
        const FinalJson = btoa(JSON.stringify(JsonStr));
        let redirectionUrl = "/forms/preview/" + FinalJson;
        returnContent = <Redirect to={redirectionUrl} />;
      }
    }

    if (!Object.keys(this.props.fieldList).length > 0) {
      return <GreneLoader show={true} />;
    }
    return (
      <div
        className={`preview ${
          this.state.urlData.organization_id === 868 ? "vil" : ""
        }`}
      >
        <div className="preview-header">
          <img
            style={{ width: "80px", margin: "25px" }}
            src={`
						${this.state.urlData.organization_id === 868 ? VilLogo : GreneLogo}
						`}
          />
          <div className="form-name">
            <h3>{formName}</h3>
          </div>
        </div>
        <div className="preview-container">{returnContent}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formsList: state.forms.formsList,
    fieldList: state.forms.fieldList,
    loadingForms: state.forms.loadingForms,
    loadingFields: state.forms.loadingFields,
    emailFormName: state.forms.formName,
    checkFormActivityData: state.queue.checkFormActivityData,
    loadUrlForm: state.queue.loadUrlForm,
    formValidated: state.forms.formValidated,
    userIp: state.loginAuth.userIp,
    userCoords: state.loginAuth.userCoords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFormListFetch: (requestData) =>
      dispatch(actions.formsListFetch(requestData)),
    onFormFieldsFetch: (requestData) =>
      dispatch(actions.formFieldListFetch(requestData)),
    inputChangedHandler: (event, formElementId, formElCatId, field) =>
      dispatch(
        actions.formFieldInputChange(event, formElementId, formElCatId, field)
      ),
    onCheckFormActivityId: (requestCheckData, requestFormFieldsData) =>
      dispatch(
        actions.checkFormActivityId(requestCheckData, requestFormFieldsData)
      ),
    onGetUserIp: () => dispatch(actions.getUserIp()),
    onGetUserCoords: (requestData) =>
      dispatch(actions.getUserCoords(requestData)),
  };
};
export default geolocated()(
  connect(mapStateToProps, mapDispatchToProps)(FormLayout)
);
