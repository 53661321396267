import React, { Component } from 'react'

export default class BuissnessCard extends Component {
    state={
        editedFieldVal:{}
    }
    onChangedHandler=(event, field = null)=>{
        let editedFieldVal;
        if (typeof this.state.editedFieldVal == "string" && this.state.editedFieldVal.length>0) {
            editedFieldVal = JSON.parse(this.state.editedFieldVal);
        } else {
            editedFieldVal = typeof this.state.editedFieldVal == "object"?this.state.editedFieldVal:{};
        }
        if (field.includes(".")) {
            let fieldList = field.split(".");
            if(editedFieldVal[fieldList[0]]===undefined){
            editedFieldVal[fieldList[0]]={}
            }
            editedFieldVal[fieldList[0]][fieldList[1]] = event.target.value;
        } else {
            editedFieldVal[field] = event.target.value;
        }
        this.setState({
            ...this.state,
            editedFieldVal: editedFieldVal,
            isEditing: true,
        });
        this.props.changed(event, field)
    }
    render() {
        const {editedFieldVal}=this.state;
        return (
                <div className="field_edit_content">
					<label>Contact Name : </label>
					<input
					  className="textbox"
					  value={editedFieldVal.contact_name}
					  onChange={(e) => this.onChangedHandler(e, "contact_name")}
					/>
					<label>Phone #1 : </label>
					<input
					  className="textbox"
					  placeholder="Phone ex.+91 0000000000"
					  value={editedFieldVal.contact_phone}
					  onChange={(e) => this.onChangedHandler(e, "contact_phone")}
					/>
					<label>Phone #2 : </label>
					<input
					  className="textbox"
					  placeholder="Phone #2 ex.+91 0000000000"
					  value={editedFieldVal.contact_alternative_phone}
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_alternative_phone")
					  }
					/>
					<label>Landline : </label>
					<input
					  className="textbox"
					  placeholder="Landline number"
					  value={editedFieldVal.contact_landline}
					  onChange={(e) => this.onChangedHandler(e, "contact_landline")}
					/>
					<label>Email : </label>
					<input
					  className="textbox"
					  placeholder="example@domain.com"
					  value={editedFieldVal.contact_email}
					  onChange={(e) => this.onChangedHandler(e, "contact_email")}
					/>
					<label>Organization : </label>
					<input
					  className="textbox"
					  placeholder="Organization name"
					  value={editedFieldVal.contact_organization}
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_organization")
					  }
					/>
					<label>Designation : </label>
					<input
					  className="textbox"
					  placeholder="Designation name"
					  value={editedFieldVal.contact_designation}
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_designation")
					  }
					/>
					<label>Fax : </label>
					<input
					  className="textbox"
					  placeholder="Fax number"
					  value={editedFieldVal.contact_fax}
					  onChange={(e) => this.onChangedHandler(e, "contact_fax")}
					/>
					<label>Area : </label>
					<input
					  className="textbox"
					  value={
						editedFieldVal.contact_address
						  ? editedFieldVal.contact_address.area
						  : ""
					  }
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_address.area")
					  }
					/>
					<label>Building : </label>
					<input
					  className="textbox"
					  value={
						editedFieldVal.contact_address
						  ? editedFieldVal.contact_address.building
						  : ""
					  }
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_address.building")
					  }
					/>
					<label>City : </label>
					<input
					  className="textbox"
					  value={
						editedFieldVal.contact_address
						  ? editedFieldVal.contact_address.city
						  : ""
					  }
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_address.city")
					  }
					/>
					<label>State : </label>
					<input
					  className="textbox"
					  value={
						editedFieldVal.contact_address
						  ? editedFieldVal.contact_address.state
						  : ""
					  }
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_address.state")
					  }
					/>
					<label>PinCode : </label>
					<input
					  className="textbox"
					  value={
						editedFieldVal.contact_address
						  ? editedFieldVal.contact_address.pincode
						  : ""
					  }
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_address.pincode")
					  }
					/>
					<label>Country : </label>
					<input
					  className="textbox"
					  value={
						editedFieldVal.contact_address
						  ? editedFieldVal.contact_address.country
						  : ""
					  }
					  onChange={(e) =>
						this.onChangedHandler(e, "contact_address.country")
					  }
					/>
            </div>
        )
    }
}
