/**
 * @GreneAutoComplete
 *  - this is custom select option confugurable 
 *      params : 
 *          isMulti
 *          isSearchable
 *          isDisabled
 *          OnSelection
 *          OnInputChange
 *          Options
 *          helperText
 */

import React,{Component} from 'react';
import './GreneAutoComplete.scss';
import Select from "react-select";

const formatOptionLabel = (option) => (
    <div style={{display: "flex"}}>
        <div>{option.label}</div>
    </div>
);

export class GreneAutoComplete extends Component {
    render() {
        const {config} = this.props;
        return (
            <Select
                isSearchable={config.isSearchable}
                isMulti={config.isMulti}
                onInputChange={this.props.onInputChange}
                disabled={config.isDisabled || false}
                className={config.className || ''}
                style={config.style || {}}
                onChange={this.props.onSelectionChange}
                options={this.props.options}
                formatOptionLabel={config.formatOptionLabel || formatOptionLabel}
                value={this.props.value}
                name={config.name}
                placeholder={config.helperText}
            />
        )
    }
}
