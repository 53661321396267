import React, { Component } from "react";
import { GridLoader } from "react-spinners";
import Modal from "react-responsive-modal";
import "./GreneLoader.scss";
export default class GreneLoader extends Component {
  render() {
    return (
      <Modal
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => {}}
        open={this.props.show}
        classNames={{
          overlay: `grene-loader-modal-overlay`,
          modal: `grene-loader-modal`,
        }}
        center
      >
        <div className="loader-container">
          <GridLoader color={"#009B76"} size={15} loading={true} />
          <p>{this.props.title || "Loading..."}</p>
        </div>
      </Modal>
    );
  }
}
