import * as actionTypes from "../Actions/actionTypes";
import {
  loadQueueListConst,
  getFocusCountConst,
  getPastDueCountConst,
  getMentionCountConst,
  activityUnreadCountConst,
  loadUnAssignActivityListConst,
  loadFocusActivityListConst,
  loadMyActivityListConst,
  loadQueueActivityListConst,
  loadFilteredActivityListConst,
  collapseChildActivityConst,
  loadChildActivityConst,
  loadActivityBulkSummaryConst,
  loadResourceTimelineConst,
  loadRoleBasedAssetsConst,
  loadTimelineDocsConst,
  workflowSelectMappingConst,
} from "../Types";

const initialState = {
  isDocumentRepoVisible: false,
  employeeDeskList: [],
  activityList: [],
  employeeDeskLoadFailed: null,
  activityLoadError: false,
  activityLoadErrorText: "",
  activityLoading: false,
  isMoreEnable: false,
  messageId: 0,
  childActivityList: [],
  childActivityLoadError: false,
  childActivityLoading: false,
  childIsMoreEnable: false,
  childActivityLoadErrorText: "",
  downloadSummarySuccess: null,
  downloadSummaryMessage: null,
  downloadSummaryError: null,
  summaryList: [],
  resourceTimeline: [],
  resourceTimelineError: false,
  roleBasedAssetListRef: {},
  timelineDocList: [],
  isGreneLoaderVisible: false,
  isSearch: false,
  workflowSelectMapping: [],
  focusCount: 0,
  focusCountError: false,
  pastDueCount: 0,
  pastDueCountError: false,
  mentionCount: 0,
  mentionCountError: false,
};

const sharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_GRENELOADER:
      return {
        ...state,
        isGreneLoaderVisible: true,
      };
    case actionTypes.CLOSE_GRENELOADER:
      return {
        ...state,
        isGreneLoaderVisible: false,
      };
    case actionTypes.SHOW_DOCUMENT_REPOSITORY:
      return {
        ...state,
        isDocumentRepoVisible: true,
      };
    case actionTypes.CLOSE_DOCUMENT_REPOSITORY:
      return {
        ...state,
        isDocumentRepoVisible: false,
      };
    case actionTypes.SEARCH_EMPLOYEE_DESK_START:
      return {
        ...state,
        employeeDeskList: [],
        employeeDeskLoadFailed: null,
      };
    case actionTypes.SEARCH_EMPLOYEE_DESK_SUCCESS:
      return {
        ...state,
        employeeDeskList: action.employeeList,
        employeeDeskLoadFailed: false,
      };
    case actionTypes.SEARCH_EMPLOYEE_DESK_FAILED:
      return {
        ...state,
        employeeDeskList: [],
        employeeDeskLoadFailed: true,
      };
    case loadQueueListConst.START:
      return {
        ...state,
        queueList: [],
        queueListError: false,
      };
    case loadQueueListConst.SUCCESS:
      return {
        ...state,
        queueList: action.queueList,
        queueListError: true,
      };
    case loadQueueListConst.ERROR:
      return {
        ...state,
        queueList: [],
        queueListError: true,
      };
    case getFocusCountConst.START:
      return {
        ...state,
        focusCount: 0,
        focusCountError: false,
      };
    case getFocusCountConst.SUCCESS:
      return {
        ...state,
        focusCount: action.focusCount,
        focusCountError: false,
      };
    case getFocusCountConst.ERROR:
      return {
        ...state,
        focusCount: action.focusCount,
        focusCountError: true,
      };
    case getMentionCountConst.START:
      return {
        ...state,
        mentionCount: 0,
        mentionCountError: false,
      };
    case getMentionCountConst.SUCCESS:
      return {
        ...state,
        mentionCount: action.mentionCount,
        mentionCountError: false,
      };
    case getMentionCountConst.ERROR:
      return {
        ...state,
        mentionCount: action.mentionCount,
        mentionCountError: true,
      };
    case getPastDueCountConst.START:
      return {
        ...state,
        pastDueCount: 0,
        pastDueCountError: false,
      };
    case getPastDueCountConst.SUCCESS:
      return {
        ...state,
        pastDueCount: action.pastDueCount,
        pastDueCountError: false,
      };
    case getPastDueCountConst.ERROR:
      return {
        ...state,
        pastDueCount: action.pastDueCount,
        pastDueCountError: true,
      };
    case loadUnAssignActivityListConst.START:
      return {
        ...state,
        activityList: [],
        activityLoadError: false,
        activityLoading: true,
        isMoreEnable: false,
      };
    case loadUnAssignActivityListConst.SUCCESS: {
      const activityList =
        action.start_from > 0
          ? [...state.activityList, ...action.activityList]
          : action.activityList;
      return {
        ...state,
        activityList,
        activityLoadError: false,
        activityLoading: false,
        isMoreEnable: action.isMoreEnable,
      };
    }
    case loadUnAssignActivityListConst.ERROR:
      return {
        ...state,
        activityList: [],
        activityLoadError: true,
        activityLoadErrorText: action.error,
        activityLoading: false,
        isMoreEnable: false,
      };
    case loadFocusActivityListConst.START:
      return {
        ...state,
        activityList: [],
        activityLoadError: false,
        activityLoading: true,
        isMoreEnable: false,
      };
    case loadFocusActivityListConst.SUCCESS: {
      const activityList =
        action.start_from > 0
          ? [...state.activityList, ...action.activityList]
          : action.activityList;
      return {
        ...state,
        activityList,
        activityLoadError: false,
        activityLoading: false,
        isMoreEnable: action.isMoreEnable,
      };
    }
    case loadFocusActivityListConst.ERROR:
      return {
        ...state,
        activityList: [],
        activityLoadError: true,
        activityLoading: false,
        isMoreEnable: false,
        activityLoadErrorText: action.error,
      };
    case loadMyActivityListConst.START:
      return {
        ...state,
        activityList: [],
        activityLoadError: false,
        activityLoading: true,
        isMoreEnable: false,
        isSearch: false,
      };
    case loadMyActivityListConst.SUCCESS: {
      let activityList = [];
      if (!!action.status_type_id) {
        activityList = [...state.activityList];
        const findIndex = activityList.findIndex(
          (item) => item.activity_id === action.status_type_id
        );
        activityList.splice(findIndex + 1, 0, ...action.activityList);
      } else {
        activityList =
          action.start_from > 0
            ? [...state.activityList, ...action.activityList]
            : action.activityList;
      }

      return {
        ...state,
        activityList,
        activityLoadError: false,
        activityLoading: false,
        isSearch: !!action.isSearch,
        isMoreEnable: !!action.status_type_id
          ? state.isMoreEnable
          : action.isMoreEnable,
      };
    }
    case loadMyActivityListConst.ERROR:
      return {
        ...state,
        activityList: [],
        activityLoadError: true,
        activityLoading: false,
        isMoreEnable: false,
        isSearch: false,
        activityLoadErrorText: action.error,
      };
    case loadChildActivityConst.START:
      return {
        ...state,
        childActivityLoadError: false,
        childActivityLoading: true,
        childIsMoreEnable: false,
        childActivityList:
          action.start_from > 0 ? [...state.childActivityList] : [],
        childActivityLoadErrorText: "",
      };
    case loadChildActivityConst.SUCCESS: {
      return {
        ...state,
        childActivityList:
          action.start_from > 0
            ? [...state.childActivityList, ...action.childActivityList]
            : action.childActivityList,
        childActivityLoadError: false,
        childActivityLoading: false,
        childIsMoreEnable: action.isMoreEnable,
        childActivityLoadErrorText: "",
      };
    }
    case loadChildActivityConst.ERROR:
      return {
        ...state,
        childActivityList: [],
        childActivityLoadError: true,
        childActivityLoading: false,
        childActivityLoadErrorText: action.error,
      };
    case loadQueueActivityListConst.START:
      return {
        ...state,
        activityList: [],
        activityLoadError: false,
        activityLoading: true,
        isMoreEnable: false,
      };
    case loadQueueActivityListConst.SUCCESS: {
      const activityList =
        action.start_from > 0
          ? [...state.activityList, ...action.activityList]
          : action.activityList;
      return {
        ...state,
        activityList,
        activityLoadError: false,
        activityLoading: false,
        isMoreEnable: action.isMoreEnable,
      };
    }
    case loadQueueActivityListConst.ERROR:
      return {
        ...state,
        activityList: [],
        activityLoadError: true,
        activityLoading: false,
        isMoreEnable: false,
        activityLoadErrorText: action.error,
      };
    case loadFilteredActivityListConst.START:
      return {
        ...state,
        activityList: [],
        activityLoadError: false,
        activityLoading: true,
        isMoreEnable: false,
      };
    case loadFilteredActivityListConst.SUCCESS: {
      const activityList =
        action.start_from > 0
          ? [...state.activityList, ...action.activityList]
          : action.activityList;
      return {
        ...state,
        activityList,
        activityLoadError: false,
        activityLoading: false,
        isMoreEnable: action.isMoreEnable,
      };
    }
    case loadFilteredActivityListConst.ERROR:
      return {
        ...state,
        activityList: [],
        activityLoadError: true,
        activityLoading: false,
        isMoreEnable: false,
        activityLoadErrorText: action.error,
      };
    case activityUnreadCountConst.UPDATE: {
      const activityList = state.activityList;
      const activityIndex = state.activityList.findIndex(
        (x) => x.activity_id === action.activityId
      );
      if (activityIndex > -1 && action.uniqueId !== initialState.messageId) {
        // Add + 1 to the existing asset_unread_updates_count
        activityList[activityIndex].asset_unread_updates_count += 1;
        initialState.messageId = action.uniqueId;
      }

      return {
        ...state,
        activityList: activityList,
        messageId: action.messageId,
      };
    }
    case activityUnreadCountConst.RESET: {
      const activityList = state.activityList;
      const activityIndex = state.activityList.findIndex(
        (x) => x.activity_id === action.activityId
      );
      if (activityIndex > -1) {
        activityList[activityIndex].asset_unread_updates_count = 0;
      }
      return {
        ...state,
        activityList: activityList,
      };
    }
    case collapseChildActivityConst.COLLAPSE: {
      let activityList = [...state.activityList];
      const findIndex = activityList.findIndex(
        (item) => item.activity_id === action.activity.activity_id
      );
      activityList.splice(findIndex + 1, action.activity.child_order_count);
      return {
        ...state,
        activityList: activityList,
      };
    }
    case loadActivityBulkSummaryConst.START: {
      return {
        ...state,
        downloadSummarySuccess: null,
        downloadSummaryMessage: null,
        downloadSummaryError: null,
      };
    }
    case loadActivityBulkSummaryConst.SUCCESS: {
      return {
        ...state,
        downloadSummaryMessage: action.message,
        downloadSummarySuccess: true,
      };
    }
    case loadActivityBulkSummaryConst.ERROR: {
      return {
        ...state,
        downloadSummaryMessage: null,
        downloadSummaryError: action.error,
      };
    }
    case loadResourceTimelineConst.START: {
      return {
        ...state,
        resourceTimeline: [],
        resourceTimelineError: null,
      };
    }
    case loadResourceTimelineConst.SUCCESS: {
      return {
        ...state,
        resourceTimeline: action.data,
        resourceTimelineError: null,
      };
    }
    case loadResourceTimelineConst.ERROR: {
      return {
        ...state,
        resourceTimeline: [],
        resourceTimelineError: action.error,
      };
    }
    case loadRoleBasedAssetsConst.START: {
      return {
        ...state,
        roleBasedAssetListRef: {},
      };
    }
    case loadRoleBasedAssetsConst.SUCCESS: {
      return {
        ...state,
        roleBasedAssetListRef: action.data,
      };
    }
    case loadRoleBasedAssetsConst.ERROR: {
      return {
        ...state,
        roleBasedAssetListRef: {},
      };
    }
    case loadTimelineDocsConst.START: {
      return {
        ...state,
        timelineDocList: [],
      };
    }
    case loadTimelineDocsConst.SUCCESS: {
      return {
        ...state,
        timelineDocList: action.data,
      };
    }
    case loadTimelineDocsConst.ERROR: {
      return {
        ...state,
        timelineDocList: [],
      };
    }
    case workflowSelectMappingConst.SUCCESS: {
      return {
        ...state,
        workflowSelectMapping: action.selectMapping,
      };
    }
    case workflowSelectMappingConst.ERROR: {
      return {
        ...state,
        workflowSelectMapping: [],
      };
    }
    case workflowSelectMappingConst.ERROR: {
      return {
        ...state,
        workflowSelectMapping: [],
      };
    }
    default:
      return state;
  }
};

export default sharedReducer;
