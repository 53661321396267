import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    authToken: null,
    empAssetId: null,
    deskAssetId: null,
    orgId: null,
    accId: null,
    workforceId: null,
    loading: false,
    userData: null,
    error: null,
    loginErrorMessage: null,
    mode: null,
    logout: false,
    organizationName: null,
    contactData: [],
    employeeSearchResult: [],
    swipedIn: 0,
    loadingContacts: false,
    hasMoreContacts: false,
    limit_value: 20,
    logoutTimer: false,
    accounts: [],
    loadingAccounts: false,
    userIp: null,
    userCoords: null,
    enableMyDesk: true,
    orgLoading: false,
    isDNDEnable: false,
    isAway: false,
    accessKey: null,
    secretKey: null,
    currentUserData: {}
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LOGIN_AUTH_START:
            return {
                ...state,
                loading: true,
                error: null,
                logout: false
            }
        case actionTypes.LOGIN_AUTH_FAIL:
        case actionTypes.LOAD_COGNITO_SIGN_IN_TOKEN_FAILED:
        case actionTypes.VERIFY_COGNITO_CHALLENGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                loginErrorMessage: action.message
            }
        case actionTypes.LOGIN_AUTH_LOGOUT:
            localStorage.clear();
            sessionStorage.clear();
            return {
                ...state,
                authToken: null,
                empAssetId: null,
                deskAssetId: null,
                orgId: null,
                accId: null,
                logout: true
            }
        case actionTypes.LOGIN_AUTH_SUCCESS:

            return {
                ...state,
                loading: false,
                authToken: action.authToken,
                empAssetId: action.authData.operating_asset_id,
                deskAssetId: action.authData.asset_id,
                orgId: action.authData.organization_id,
                accId: action.authData.account_id,
                workforceId: action.authData.workforce_id,
                organizationName: action.authData.organization_name,
                userData: action.authData,
                mode: action.loginMode
            }

        case actionTypes.LOGIN_ACCOUNT_FETCH_START:
            return {
                ...state,
                accounts: [],
                loadingAccounts: true
            }
        case actionTypes.LOGIN_ACCOUNT_FETCH_SUCCESS:
            return {
                ...state,
                accounts: action.accountsData,
                loadingAccounts: false
            }
        case actionTypes.SEARCH_ORG_CONTACTS_START:
            if (action.contactsParams.start_from === 0) {
                return {
                    ...state,
                    contactData: [],
                    loadingContacts: true
                }
            }
            else {
                return {
                    ...state,
                    loadingContacts: true
                }
            }

        case actionTypes.SEARCH_ORG_CONTACTS_SUCCESS:
            const TempCont = [...state.contactData];
            let newContactArray = [];
            if (action.contactsParams.start_from === 0) {
                newContactArray = action.contactData;
            }
            else {
                newContactArray = TempCont.concat(action.contactData);
            }
            let moreContactCnt = false;
            if (action.contactData.length === state.limit_value) {

                moreContactCnt = true;
            }
            return {
                ...state,
                contactData: newContactArray,
                loadingContacts: false,
                hasMoreContacts: moreContactCnt
            }
        case actionTypes.SEARCH_ORG_EMPLOYEE_START:
            if (action.requestData.start_from === 0) {
                return {
                    ...state,
                    employeeSearchResult: [],
                }
            }
            else {
                return {
                    ...state,
                }
            }
        case actionTypes.SEARCH_ORG_EMPLOYEE_SUCCESS:
            const TempEmpList = [...state.employeeSearchResult];
            let newSearchResultArray = [];
            if (action.requestData.start_from === 0) {
                newSearchResultArray = action.employeeSearchResult;
            }
            else {
                newSearchResultArray = TempEmpList.concat(action.employeeSearchResult);
            }
            let moreResultLeft = false;
            if (action.employeeSearchResult.length === state.limit_value) {

                moreResultLeft = true;
            }
            return {
                ...state,
                employeeSearchResult: newSearchResultArray,
                hasMoreContacts: moreResultLeft
            }
        case actionTypes.SET_LOGOUT_TIMER:
            return {
                ...state,
                logoutTimer: !state.logoutTimer
            }
        case actionTypes.GET_USER_IP_SUCCESS:
            return {
                ...state,
                userIp: action.ipData
            }
        case actionTypes.GET_USER_COORDS_SUCCESS:
            return {
                ...state,
                userCoords: action.coordsData
            }
        case actionTypes.TOGGLE_MY_DESK_VIEW:
            return {
                ...state,
                enableMyDesk: false,
            }
        case actionTypes.ORG_SIGNUP_START:
            return {
                ...state,
                orgLoading: true,
            }
        case actionTypes.ORG_SIGNUP_END:
            return {
                ...state,
                orgLoading: false,
            }
        case actionTypes.ACCOUNT_CREATION_END:
            return {
                ...state,
                orgLoading: false,
                accountAdded: true,
                newAccountId: action.accountInfo.account_id,

            }
        case "SET_CURR_FLOOR_ID":
            if (!!state.userData) {
                state.userData.workforce_id = Number(action.floorId)
            }
            return {
                ...state,
                workforceId: Number(action.floorId),
                userData: state.userData
            }
        case actionTypes.S3_FETCH_START:
            return {
                ...state,
                accessKey: null,
                secretKey: null
            }
        case actionTypes.S3_FETCH_END:
            return {
                ...state,
                accessKey: action.data.access_key,
                secretKey: action.data.secret_key
            }
        case actionTypes.ENABLE_DND_FOR_USER:
            return {
                ...state,
                isDNDEnable: action.dndState
            }
        case actionTypes.CHANGE_USER_AWAY_STATUS:
            return {
                ...state,
                isAway: action.isAway
            }
        case actionTypes.SET_WORKFORCE_TYPE_ID:
            const {
                floorType,
                floorId,
                workforceName
            } = action;
            console.log('set workforce type id', action);
            state.userData.workforce_type_id = floorType;
            state.userData.workforce_id = floorId;
            state.userData.workforce_name = workforceName;
            return {
                ...state,
                userData: state.userData,
                workforceId: action.floorId,
                workforceName
            }
        case actionTypes.CURRENT_USER_DATA_SUCCESS:
            return {
                ...state,
                currentUserData: action.currentUserData
            }    
        default:
            return state;


    }

}
export default reducer;
