import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_EXT_URL,
});

instance.interceptors.request.use(
	(config) => {
		config.headers['x-grene-auth-flag'] = 1;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export default instance;
