import React, { Component } from 'react';
import FormDetail from './FormDetail/FormDetail';
import AxiosAPI from '../../axios_ext';
import CryptoJS from 'crypto-js';
import config from 'react-global-configuration';
import { isMobile } from 'react-device-detect';

import GreneLogo from "../../assets/images/logo.png";
import VILLogo from "../../assets/vil/vi_logo_large_white.png";
import GreneLoader from "../../components/GreneLoader/GreneLoader";
import "../FormEntry/FormLayout.scss";

class FormPreview extends Component {
  state = {
    formData: null,
    accessKey: null,
    secretKey: null,
  };

  async componentDidMount() {
    // localStorage.setItem("x-grene-header-disable", true);
    localStorage.setItem("x-grene-form-view", true);
    localStorage.setItem("x-form-pass", true);
    let urlRequestData = JSON.parse(atob(this.props.match.params.formsdata));
    console.log("Params data", urlRequestData);

    if (urlRequestData.hasOwnProperty("uuid")) {
      let requestData = {};
      requestData.device_os_id = 5;
      requestData.access_key_type_id = 1;
      requestData.asset_id = 31347;
      requestData.auth_asset_id = 31347;
      requestData.asset_token_auth = "05986bb0-e364-11e8-a1c0-0b6831833754";
      requestData.url_id = urlRequestData.url_id;
      requestData.url_uuid = urlRequestData.uuid;
      requestData.organization_id = urlRequestData.organization_id;

      await AxiosAPI.post("/url/parameters/lookup", requestData).then((res) => {
        console.log("res ", res);
        console.log(
          "res.data.response[0].url_form_data :: ",
          res.data.response[0].url_form_data
        );
        urlRequestData = JSON.parse(res.data.response[0].url_form_data);
      });
    }

    //Fetch the S3 Credentials
    let requestData = {};
    requestData.device_os_id = 5;
    requestData.access_key_type_id = 1;
    requestData.asset_id = urlRequestData.asset_id;
    requestData.asset_token_auth = urlRequestData.auth_token;
    AxiosAPI.post("aws_resources/credentials/fetch", requestData).then(
      (res) => {
        const credentials = res.data.response;
        let decryptThis = credentials[0].access_key_inline_data;
        var bytes = CryptoJS.AES.decrypt(
          decryptThis.toString(),
          "lp-n5^+8M@62"
        );
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        decryptedData = JSON.parse(decryptedData);
        config.set(
          {
            s3_access_key: decryptedData.access_key,
            s3_secret_key: decryptedData.secret_key,
          },
          {
            freeze: false,
          }
        );

        this.setState({
          ...this.state,
          formData: urlRequestData,
        });
      }
    );
  }

  render() {
    if (isMobile) {
      return (
        <div>
          <h3>Please open in Desktop browser</h3>
        </div>
      );
    }
    if (!this.state.formData) {
      return <GreneLoader show={true} />;
    }
    return (
      <div
        className={`preview ${this.state.formData.org_id === 868 ? "vil" : ""}`}
      >
        <div className="preview-header">
          <img
            style={{ width: "80px", margin: "25px" }}
            src={`
						${this.state.formData.org_id === 868 ? VILLogo : GreneLogo}
						`}
          />
          <div className="form-name">
            <h3>{this.state.formData.form_name}</h3>
          </div>
        </div>
        <div className="preview-container">
          <FormDetail
            validation="false"
            entry="external"
            formData={this.state.formData}
          />
        </div>
      </div>
    );
  }
}

export default FormPreview;
