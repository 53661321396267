import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GreneAutoComplete } from "../../../../components/GreneAutoComplete/GreneAutoComplete";
import * as utils from "../../../../shared/Utils";
import * as actions from "../../../../store/Actions/index";
export class productCart extends Component {
    state={
        selectedProductVariants:[],
        selectedProductWorkflowType:null,
        selectedProductWorkflow:null,
        selectedProductCategory:null
    }
    
    
    buildProductWorkflowTypeParams = (data) => {
		return {
			organization_id: this.props.urlData.organization_id,
			account_id: this.props.urlData.account_id,
			workforce_id: this.props.urlData.workforce_id,
			asset_id: this.props.urlData.asset_id,
			asset_token_auth: this.props.urlData.asset_token_auth,
			tag_id: data.value,
			start_from: 0,
			limit_value: 50,
			device_os_id: 5,
		};
	  };
	productCategoryChange = (selectOption) => {
		this.setState({
		  ...this.state,
		  selectedProductCategory: selectOption,
		  selectedProductWorkflowType: null,
		  selectedProductWorkflow: null,
		  selectedProductVariants: [],
		});
		this.props.changed(selectOption, "product_category");
		this.props.changed([], "product_variant");
		this.props.onFetchProductWorkflowTypes(
		  this.buildProductWorkflowTypeParams(selectOption)
		);
	  };
	  buildProductWorkflowParams = (data) => {
		return {
			organization_id: this.props.urlData.organization_id,
			account_id: this.props.urlData.account_id,
			workforce_id: this.props.urlData.workforce_id,
			asset_id: this.props.urlData.asset_id,
			asset_token_auth: this.props.urlData.asset_token_auth,
			activity_type_category_id: data.details.activity_type_category_id,
			activity_type_id: data.value,
			flag: 0,
			tag_id: data.details.tag_id,
			tag_type_id: data.details.tag_type_id,
			search_string: "",
			flag_status: 0,
			flag_participating: 2,
			start_from: 0,
			limit_value: 50,
			device_os_id: 5,
		};
	  };
	  handleWorkflowTypeChange = (selectedOption) => {
		this.setState({
		  ...this.state,
		  selectedProductWorkflowType: selectedOption,
		  selectedProductWorkflow: null,
		  selectedProductVariants: [],
		});
		this.props.changed(selectedOption, "product_workflow_type");
		this.props.changed([], "product_variant");
		this.props.onFetchProductWorkflows(
		  this.buildProductWorkflowParams(selectedOption)
		);
	  };
      buildProductVariantsParams = (data) => {
		return {
			organization_id: this.props.urlData.organization_id,
			account_id: this.props.urlData.account_id,
			workforce_id: this.props.urlData.workforce_id,
			asset_id: this.props.urlData.asset_id,
			asset_token_auth: this.props.urlData.asset_token_auth,
			parent_activity_id: data.value,
			flag: 1,
			sort_flag: 1,
			datetime_start: utils.getCurrentDateUTC(
				data.activity_datetime_start_expected
			),
			datetime_end: utils.getAddToCurrentDateUTC(
				data.activity_datetime_end_deferred
			),
			start_from: 0,
			limit_value: 50,
			device_os_id: 5,
		};
	  };
	
	  handleProductWorkflowChange = (selectedOption) => {
		this.setState({
		  ...this.state,
		  selectedProductWorkflow: selectedOption,
		  selectedProductVariants: [],
		});
		this.props.changed(selectedOption, "product_workflow");
		this.props.changed([], "product_variant");
		this.props.onFetchProductVariants(
		  this.buildProductVariantsParams(selectedOption)
		);
	  };
	  handleProductVariantChange = (selectedOptions) => {
		let total = 0;
		selectedOptions.forEach((variant) => {
		  let quantity = variant.quantity ? variant.quantity : 0;
		  if (!!variant.productPrice) {
			total += quantity * Number(variant.details.activity_cuid_3);
		  }
		});
		this.setState({
		  ...this.state,
		  selectedProductVariants: selectedOptions,
		  cartValue: total,
		});
		this.props.changed(selectedOptions, "product_variant");
		this.props.changed(total, "cart_value");
	  };
	  handleVariantQuantity = (e, option) => {
		let newArray = [...this.state.selectedProductVariants];
		let total = 0;
	
		let productPrice = e.target.value * Number(option.details.activity_cuid_3);
		let productIndex = this.state.selectedProductVariants.findIndex(
		  (variant) => Number(variant.value) == Number(option.value)
		);
		newArray[productIndex] = {
		  ...newArray[productIndex],
		  productPrice: productPrice,
		  quantity: e.target.value,
		};
	
		newArray.forEach((variant) => {
		  if (!!variant.quantity) {
			total +=
			  Number(variant.quantity) * Number(variant.details.activity_cuid_3);
		  }
		});
		this.setState({
		  selectedProductVariants: newArray,
		  cartValue: total,
		});
		this.props.changed(newArray, "product_variant");
		this.props.changed(total, "cart_value");
	  };
	
	  handleVariantPrice = (e, option) => {
		let newArray = [...this.state.selectedProductVariants];
		let total = 0;
	
		let productPrice = e.target.value * Number(option.quantity);
		let productIndex = this.state.selectedProductVariants.findIndex(
		  (variant) => Number(variant.value) == Number(option.value)
		);
		newArray[productIndex].details.activity_cuid_3 = e.target.value;
		newArray[productIndex] = {
		  ...newArray[productIndex],
		  productPrice: productPrice,
		  quantity: option.quantity,
		};
	
		newArray.forEach((variant) => {
		  if (!!variant.quantity && !!variant.productPrice) {
			total +=
			  Number(variant.quantity) * Number(variant.details.activity_cuid_3);
		  }
		});
		this.setState({
		  selectedProductVariants: newArray,
		  cartValue: total,
		});
		this.props.changed(newArray, "product_variant");
		this.props.changed(total, "cart_value");
	  };
    buildProductCategoriesParams = () => {
		return {
		  organization_id: this.props.urlData.organization_id,
		  account_id: this.props.urlData.account_id,
		  workforce_id: this.props.urlData.workforce_id,
		  asset_id: this.props.urlData.asset_id,
		  asset_token_auth: this.props.urlData.asset_token_auth,
		  tag_type_category_id: 5,
		  tag_type_id: 0,
		  start_from: 0,
		  limit_value: 50,
		  device_os_id: 5,
		};
	  };
    componentDidMount(){
        this.props.onFetchProductCategories(this.buildProductCategoriesParams());
    }

    render() {
        let productCategoryOptions = this.props.productCategoryList;
        let productWorkflowTypes = this.props.productWorkflowTypes;
        let productWorkflows = this.props.productWorkflows;
        let productVariantsProps = this.props.productVariants;
        let productVariants = productVariantsProps;
        if (!!this.state.selectedProductWorkflow) {
            productVariants = [
              ...[
                {
                  details: {
                    activity_cuid_2: null,
                    activity_cuid_3: "0",
                  },
                  label: "Custom variant",
                  value: 0,
                },
              ],
              ...productVariantsProps,
            ];
          }
        return (
            <div>
						<GreneAutoComplete
						  config={{
							name: "product_category_search",
							helperText: "Select Product Category",
							isSearchable: true,
							isMulti: false,
							isDisabled: this.props.disabled,
							className: "reference_search",
						  }}
						  value={this.state.selectedProductCategory}
						  options={productCategoryOptions}
						  onSelectionChange={this.productCategoryChange}
						/>
						<GreneAutoComplete
						  config={{
							name: "product_family_search",
							helperText: "Select Product Family",
							isSearchable: true,
							isMulti: false,
							isDisabled: this.props.disabled,
							className: "reference_search",
						  }}
						  value={this.state.selectedProductWorkflowType}
						  options={productWorkflowTypes}
						  onSelectionChange={this.handleWorkflowTypeChange}
						/>
						<GreneAutoComplete
						  config={{
							name: "product_search",
							helperText: "Select Product",
							isSearchable: true,
							isMulti: false,
							isDisabled: this.props.disabled,
							className: "reference_search",
						  }}
						  value={this.state.selectedProductWorkflow}
						  options={productWorkflows}
						  onSelectionChange={this.handleProductWorkflowChange}
						/>
						<GreneAutoComplete
						  config={{
							name: "product_variant_search",
							helperText: "Select Product Variants",
							isSearchable: true,
							isMulti: true,
							isDisabled: this.props.disabled,
							className: "reference_search",
						  }}
						  value={this.state.selectedProductVariants}
						  options={productVariants}
						  onSelectionChange={this.handleProductVariantChange}
						/>
						<table
						  className="table"
						  style={{
							display:
							  !!this.state.selectedProductVariants &&
							  this.state.selectedProductVariants.length > 0
								? "inline-table"
								: "none",
						  }}
						>
						  <thead>
							<tr>
							  <th scope="col">Variant Name</th>
							  <th scope="col">Quantity</th>
							  <th scope="col">Monthly Price</th>
							</tr>
						  </thead>
						  <tbody>
							{!!this.state.selectedProductVariants &&
							this.state.selectedProductVariants.length > 0
							  ? this.state.selectedProductVariants.map((variant, index) => {
								  return (
									<tr key={index}>
									  <td>{variant.label}</td>
									  <td>
										<input
										  type="number"
										  value={variant.quantity ? variant.quantity : 0}
										  onChange={(e) =>
											this.handleVariantQuantity(e, variant)
										  }
										  disabled={this.props.disabled}
										/>
									  </td>
									  {variant.value === 0 ? (
										<td>
										  <input
											type="number"
											value={variant.details.activity_cuid_3}
											onChange={(e) =>
											  this.handleVariantPrice(e, variant)
											}
											disabled={this.props.disabled}
										  />
										</td>
									  ) : (
										<td>{variant.details.activity_cuid_3}</td>
									  )}
									</tr>
								  );
								})
							  : null}
							{/* {!!this.state.selectedProductWorkflow && !!this.state.selectedProductWorkflow.value && productVariants.length > 0 && (
											productVariants[0].value !== 0 && (
												<tr key={"200"}>
													<td colSpan="3">
													<label for="addvariant">
														<input type="checkbox" name="addvariant" id="addvariant" value="1" onChange={e => this.handleAddCustomVariant(e)} />
														<span style={{fontWeight:'500', paddingLeft:'10px'}}>Add custom variant</span>
													</label></td>
												</tr>
											)
										)} */}
							<tr>
							  <td>Total Monthly Cart Value</td>
							  <td></td>
							  <td>{this.state.cartValue}</td>
							</tr>
						  </tbody>
						</table>
					  
					
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // userIp: state.loginAuth.userIp,
		// orgId: state.loginAuth.orgId,
		// accId: state.loginAuth.accId,
		// authToken: state.loginAuth.authToken,
		// userData: state.loginAuth.userData,
		// FileList: state.files.fileListRef,
		workflowListRef: state.files.workflowListRef,
        productCategoryList: state.files.productCategoryList,
		productWorkflowTypes: state.files.productWorkflowTypes,
		productWorkflows: state.files.productWorkflows,
		productVariants: state.files.productVariants,
    }
}

const mapDispatchToProps = (dispatch) => {
   return{ 
       onFetchProductCategories: (requestData) =>
			dispatch(actions.fetchProductCategories(requestData)),
		onFetchProductWorkflowTypes: (requestData) =>
			dispatch(actions.fetchProductWorkflowTypes(requestData)),
		onFetchProductWorkflows: (requestData) =>
			dispatch(actions.fetchProductWorkflows(requestData)),
		onFetchProductVariants: (requestData) =>
			dispatch(actions.fetchProductVariants(requestData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(productCart)
