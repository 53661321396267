export const loadQueueListConst = {
  START: "LOAD_QUEUE_LIST_START",
  SUCCESS: "LOAD_QUEUE_LIST_SUCCESS",
  ERROR: "LOAD_QUEUE_LIST_ERROR",
};
export const getFocusCountConst = {
  START: "GET_FOCUS_COUNT_START",
  SUCCESS: "GET_FOCUS_COUNT_SUCCESS",
  ERROR: "GET_FOCUS_COUNT_ERROR",
};
export const getMentionCountConst = {
  START: "GET_MENTION_COUNT_START",
  SUCCESS: "GET_MENTION_COUNT_SUCCESS",
  ERROR: "GET_MENTION_COUNT_ERROR",
};
export const getPastDueCountConst = {
  START: "GET_PAST_DUE_COUNT_START",
  SUCCESS: "GET_PAST_DUE_COUNT_SUCCESS",
  ERROR: "GET_PAST_DUE_COUNT_ERROR",
};
export const loadUnAssignActivityListConst = {
  START: "LOAD_UNASSIGN_ACTIVITIES_START",
  SUCCESS: "LOAD_UNASSIGN_ACTIVITIES_SUCCESS",
  ERROR: "LOAD_UNASSIGN_ACTIVITIES_ERROR",
};
export const loadFocusActivityListConst = {
  START: "LOAD_FOCUS_ACTIVITY_LIST_START",
  SUCCESS: "LOAD_FOCUS_ACTIVITY_LIST_SUCCESS",
  ERROR: "LOAD_FOCUS_ACTIVITY_LIST_ERROR",
};
export const loadMyActivityListConst = {
  START: "LOAD_MY_ACTIVITY_LIST_START",
  SUCCESS: "LOAD_MY_ACTIVITY_LIST_SUCCESS",
  ERROR: "LOAD_MY_ACTIVITY_LIST_ERROR",
};
export const loadQueueActivityListConst = {
  START: "LOAD_QUEUE_ACTIVITY_LIST_START",
  SUCCESS: "LOAD_QUEUE_ACTIVITY_LIST_SUCCESS",
  ERROR: "LOAD_QUEUE_ACTIVITY_LIST_ERROR",
};

export const loadFilteredActivityListConst = {
  START: "LOAD_FILTERED_ACTIVITY_LIST_START",
  SUCCESS: "LOAD_FILTERED_ACTIVITY_LIST_SUCCESS",
  ERROR: "LOAD_FILTERED_ACTIVITY_LIST_ERROR",
};

export const activityUnreadCountConst = {
  UPDATE: "ACTIVITY_UNREAD_COUNT_UPDATE",
  RESET: "ACTIVITY_UNREAD_COUNT_RESET",
};

export const loadChildActivityConst = {
  START: "LOAD_CHILD_ACTIVITY_LIST_START",
  SUCCESS: "LOAD_CHILD_ACTIVITY_LIST_SUCCESS",
  ERROR: "LOAD_CHILD_ACTIVITY_LIST_ERROR",
};

export const loadActivityBulkSummaryConst = {
  START: "LOAD_ACTIVITY_BULK_SUMMARY_START",
  SUCCESS: "LOAD_ACTIVITY_BULK_SUMMARY_SUCCESS",
  ERROR: "LOAD_ACTIVITY_BULK_SUMMARY_ERROR",
};
export const loadResourceTimelineConst = {
  START: "LOAD_RESOURCE_TIMELINE_START",
  SUCCESS: "LOAD_RESOURCE_TIMELINE_SUCCESS",
  ERROR: "LOAD_RESOURCE_TIMELINE_ERROR",
};

export const collapseChildActivityConst = {
  COLLAPSE: "COLLAPSE_CHILD_ACTIVITY_START",
};

export const loadRoleBasedAssetsConst = {
  START: "LOAD_ROLE_BASED_ASSETS_START",
  SUCCESS: "LOAD_ROLE_BASED_ASSETS_SUCCESS",
  ERROR: "LOAD_ROLE_BASED_ASSETS_ERROR",
};

export const loadTimelineDocsConst = {
  START: "LOAD_TIMELINE_DOCS_START",
  SUCCESS: "LOAD_TIMELINE_DOCS_SUCCESS",
  ERROR: "LOAD_TIMELINE_DOCS_ERROR",
};

export const workflowSelectMappingConst = {
  START: "LOAD_WORKFLOW_SELECT_MAPPING_START",
  SUCCESS: "LOAD_WORKFLOW_SELECT_MAPPING_SUCCESS",
  ERROR: "LOAD_WORKFLOW_SELECT_MAPPING_ERROR",
};
