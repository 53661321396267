import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as utils from "../../../../shared/Utils";
import * as actions from "../../../../store/Actions/index";
import Dropzone from "react-dropzone";
import { ProgressBar } from "react-bootstrap";
import './datatype.scss'
export default class DocumentRepository extends Component {
    // state={
    //     accepted:[]
    // }
    render() {
        let propsValueRepo = this.props.value;
        let repoValues = {};
        if (propsValueRepo.hasOwnProperty("document_title")) {
        repoValues = propsValueRepo;
        }
        return (
          
          <div>
            <div>
            <input
              className="textbox"
              type="text"
              placeholder="Product Reference"
              value={
                !!this.props.workflowInfo
                  ? this.props.workflowInfo.activity_title
                  : ""
              }
              disabled
            />
            </div>
            <div>
            <input
              className="textbox"
              type="text"
              placeholder="Document Title"
              autoFocus={true}
              value={repoValues.document_title}
              onChange={(e) => this.props.changed(e, "document_title")}
            />
            
            </div>
            <div>
            <input
              className="textbox"
              type="textarea"
              placeholder="Document Description"
              value={repoValues.document_description}
              onChange={(e) => this.props.changed(e, "document_description")}
            />
            </div>
            <div>
            <input
              className="textbox"
              type="number"
              placeholder="Document Version"
              value={repoValues.document_version}
              onChange={(e) => this.props.changed(e, "document_version")}
            />
            </div>
            <div>
              <Dropzone
                className="upload_dropzone_fields"
                accept="application/pdf"
                // disabled={
                //   this.props.elementConfig.disabled || this.props.disabled
                // }
                onDrop={(accepted, rejected) =>
                  this.props.handleFileUpload(accepted, rejected)
                }
              >
                <div className="uploaded_files" style={{ width: "100%" }}>
                  {this.props.accepted.map((filedata) => {
                    return (
                      <div key={filedata.name} className="thumbnail_dropzone">
                        <img
                          src={
                            filedata.type === "application/pdf"
                              ? require("../../../../assets/images/Pdf_thumb.png")
                              : filedata.preview
                          }
                          alt={filedata.name}
                        />
                      </div>
                    );
                  })}

                  {this.props.fileUploadData !== "" &&
                    Array.isArray(this.props.accepted) &&
                    this.props.accepted.length === 0 && (
                      <div key={"file"} className="thumbnail_dropzone">
                        <img
                          src={require("../../../../assets/images/Pdf_thumb.png")}
                          alt={"..."}
                        />
                      </div>
                    )}
                </div>

                {this.props.accepted.length === 0 &&
                this.props.fileUploadData === "" ? (
                  <p className="upload_dropzone_title">
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                ) : null}
              </Dropzone>
              {this.props.isProgress > 0 ? (
                <ProgressBar
                  style={{ width: "100%" }}
                  striped
                  bsStyle="success"
                  now={this.props.isProgress}
                />
              ) : null}
            </div>
            <input
              className="textbox"
              type="text"
              placeholder="File Name"
              disabled
              value={this.props.fileName || repoValues.file_title}
            />
          </div>
        );
    }
}
