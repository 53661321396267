import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as utils from '../../../../shared/Utils'
import * as actions from '../../../../store/Actions/index'
import { GreneAutoComplete } from "../../../../components/GreneAutoComplete/GreneAutoComplete";

import { sharedActions } from "../../../../store/Actions/index";
export class AssetRefrence extends Component {
    constructor(props){
        super(props)
        this.state={
            selectedAsset:''
        }
        this.assetSearchDebounce = utils.debounce(this.props.onFetchAssets, 500);
        this.roleBasedAssetFetchDebounce = utils.debounce(
          this.props.loadRoleBasedAssets,
          500
        );
    }
    buildRoleBaseAssetParams = (str) => {
        let organization_id,
          account_id,
          asset_id,
          workforce_id,
          auth_asset_id,
          asset_token_auth;
        let asset_type_category_id = 0;
        let asset_type_id = 0;
        let filter_organization_id = 0;
        let asset_ref_flag_filter = 0;
        let asset_form_id = 0;
    
        if (
          !!this.props.shouldValidate.rules &&
          Object.keys(this.props.shouldValidate.rules).length > 0 &&
          !!this.props.shouldValidate.rules.asset_reference_restriction
        ) {
          asset_type_category_id = this.props.shouldValidate.rules
            .asset_reference_restriction.asset_type_category_id;
          asset_type_id = this.props.shouldValidate.rules
            .asset_reference_restriction.asset_type_id;
          filter_organization_id = this.props.shouldValidate.rules
            .asset_reference_restriction.filter_organization_id;
          asset_ref_flag_filter = this.props.shouldValidate.rules
            .asset_reference_restriction.asset_ref_flag_filter;
          asset_form_id = !!this.props.shouldValidate.rules
            .asset_reference_restriction.form_id
            ? this.props.shouldValidate.rules.asset_reference_restriction.form_id
            : 0;
        }
      
          organization_id = this.props.urlData.organization_id;
          account_id = this.props.urlData.account_id;
          asset_id = this.props.urlData.asset_id;
          workforce_id = this.props.urlData.workforce_id;
          auth_asset_id = this.props.urlData.auth_asset_id;
          asset_token_auth = this.props.urlData.asset_token_auth;
        
        let params = {
          organization_id: filter_organization_id || organization_id,
          account_id: account_id,
          workforce_id: workforce_id,
          asset_id: asset_id,
          activity_id: this.props.workflowInfo
            ? this.props.workflowInfo.activity_id
            : 0,
          device_os_id: 5,
          asset_token_auth: asset_token_auth,
          search_string: str,
          flag_filter: 0, /// / 1 / 2,
          asset_type_id,
          asset_type_category_id,
          start_from: 0,
          limit_value: 50,
        };
        if (Number(asset_ref_flag_filter) === 4) {
          params["form_id"] = asset_form_id;
        }
        return params;
      };
      buildAssetRequestParamsV1 = (str) => {
        let txtToSrch = "";
        let asset_type_category_id = 0;
        let flag = 0;
        let asset_type_id = 0;
        let asset_form_id = 0;
        if (str && str !== "") {
          txtToSrch = str;
        }
        if (
          !!this.props.shouldValidate.rules &&
          Object.keys(this.props.shouldValidate.rules).length > 0 &&
          !!this.props.shouldValidate.rules.asset_reference_restriction
        ) {
          asset_type_category_id = this.props.shouldValidate.rules
            .asset_reference_restriction.asset_type_category_id;
          flag =
            this.props.shouldValidate.rules.asset_reference_restriction
              .asset_ref_flag_filter || 0;
          asset_form_id = !!this.props.shouldValidate.rules
            .asset_reference_restriction.form_id
            ? this.props.shouldValidate.rules.asset_reference_restriction.form_id
            : 0;
          asset_type_id = this.props.shouldValidate.rules
            .asset_reference_restriction.asset_type_id;
        }
    
        let params = {
          organization_id:  this.props.urlData.organization_id,
          account_id: this.props.urlData.account_id,
          workforce_id: 0,
          asset_id:this.props.urlData.asset_id,
          asset_token_auth:this.props.urlData.asset_token_auth,
          auth_asset_id:this.props.urlData.auth_asset_id,
          device_os_id: 5,
          flag_filter: flag, // 1 / 2,
          activity_id: this.props.workflowInfo
            ? this.props.workflowInfo.activity_id
            : 0,
          search_string: txtToSrch,
          asset_type_category_id,
          asset_type_id,
        };
    
        if (Number(flag) === 4) {
          params["form_id"] = asset_form_id;
        }
        return params;
      };
    inputAssetSelectChange = (e) => {
        const srchStr = e;
        this.setState(
          {
            ...this.state,
            searchString: srchStr,
          },
          () => {
            if (
              !!this.props.shouldValidate.rules &&
              Object.keys(this.props.shouldValidate.rules).length > 0 &&
              !!this.props.shouldValidate.rules.asset_reference_restriction &&
              !!this.props.shouldValidate.rules.asset_reference_restriction
                .asset_type_id &&
              this.props.shouldValidate.rules.asset_reference_restriction
                .asset_ref_flag_filter !== 3
            ) {
              this.roleBasedAssetFetchDebounce(
                this.buildRoleBaseAssetParams(srchStr)
              );
            } else {
              this.assetSearchDebounce(
                this.buildAssetRequestParamsV1(srchStr),
                "select"
              );
            }
          }
        );
      };
      handleAssetChange = (selectedOption) => {
        this.setState({
          ...this.state,
          selectedAsset: selectedOption,
        });
        this.props.changed(selectedOption)
        this.props.inputChangedHandler(
          selectedOption,
          this.props.elementTypeId,
          null,
          !!this.props.onFlyEnabled
        );
      };
    render() {
        let asset_type_category_id = 0;
          let asset_type_id = 0;
          let isMulti = !!(parseInt(this.props.elementType) == 69);
          if (
            !!this.props.shouldValidate &&
            !!this.props.shouldValidate.rules &&
            this.props.shouldValidate.rules.asset_reference_restriction
          ) {
            const {
              asset_reference_restriction,
            } = this.props.shouldValidate.rules;
            asset_type_category_id =
              asset_reference_restriction.asset_type_category_id;
            asset_type_id = asset_reference_restriction.asset_type_id;
          }
          let assetOptions = [];
          if (
            !!asset_type_id &&
            this.props.roleBasedAssetListRef.asset_type_category_id ==
              asset_type_category_id
          ) {
            assetOptions = this.props.roleBasedAssetListRef.roleBasedAssetList;
          } else if (
            this.props.assetListRef.asset_type_category_id ===
            asset_type_category_id
          ) {
            assetOptions = this.props.assetListRef.assetListOptions;
          }
        return (
            <div>
                <GreneAutoComplete
              config={{
                name: "asset_search",
                helperText: "Search by Resource name",
                isSearchable: true,
                isMulti: isMulti,
                // isDisabled: this.props.disabled,
                className: "reference_search",
                isAssetDetails: true,
              }}
              value={this.state.selectedAsset}
              options={assetOptions}
              onInputChange={this.inputAssetSelectChange}
              onSelectionChange={this.handleAssetChange}
            />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        workflowListRef: state.files.workflowListRef,
        roleBasedAssetListRef: state.shared.roleBasedAssetListRef,
        assetListRef: state.files.assetListRef,
}}

const mapDispatchToProps =(dispatch) => {
    return{
        inputChangedHandler: (event, formElementId, eleType, onFly) =>
      dispatch(
        actions.formFileFieldInputChange(event, formElementId, eleType, onFly)
      ),
        loadRoleBasedAssets: (payload) =>
            dispatch(sharedActions.loadRoleBasedAssets(payload)),
        onFetchAssets: (requestData, reference) =>
                dispatch(actions.assetsFetch(requestData, reference)),   
    }
    
}
export default connect(mapStateToProps, mapDispatchToProps)(AssetRefrence)
