import * as actionTypes from '../Actions/actionTypes';
const initialState = {
    queueList: [],
    formFieldAlter: false,
    formIdsOnOrderForm: [],
    queueActivityList: [],
    submittedForms: [],
    checkFormActivityData: [],
    recordCount: 0,
    queueStart: 0,
    validationData: [],
    fieldData: [],
    loadingStart: false,
    formDataValues: [],
    fieldDropDown: [],
    statusList: [],
    workforceLevelForms: [],
    searchWorkforceLevelForms: [],
    refinedSubmittedForms: null,
    queueActivityStatusList: [],
    searchRespFlag: 0,
    myFocusCount: null
}
const reducer = (state = initialState,action) => {

    switch(action.type) {
        case actionTypes.QUEUE_LIST_FETCH_START:
            return {
                ...state,
                queueList: [],
            }
        case actionTypes.QUEUE_LIST_FETCH_SUCCESS:
            return {
                ...state,
                queueList: action.queueData,
            }
        case actionTypes.QUEUE_ACTIVITY_LIST_FETCH_START:
            if(action.startFrom === 0) {
                return {
                    ...state,
                    queueStart: 0,
                    queueActivityList: [],
                }
            }
            else {
                return state;
            }

        case actionTypes.QUEUE_ACTIVITY_LIST_FETCH_SUCCESS:
            let activityListTemp = [];
            if(!!action.isShowMore) {
                activityListTemp = [...state.queueActivityList];
                activityListTemp = activityListTemp.concat(action.queueActivityData);
            } else {
                activityListTemp = action.queueActivityData;
            }
            return {
                ...state,
                queueActivityList: activityListTemp,
                recordCount: action.queueActivityData.length,
                queueStart: state.queueStart + action.limitValue,
                searchRespFlag: action.flag
            }

        case actionTypes.QUEUE_ACTIVITY_LIST_STATUSES_FETCH_START:
            if(action.startFrom === 0) {

                return {
                    ...state,
                    //queueStart:0,
                    queueActivityStatusList: [],
                }
            }
            else {
                return state;
            }

        case actionTypes.QUEUE_ACTIVITY_LIST_STATUSES_FETCH_SUCCESS:
            //let activityListTemp = [...state.queueActivityList];
            //activityListTemp = activityListTemp.concat(action.queueActivityData);
            return {
                ...state,
                queueActivityStatusList: action.queueActivityStatusData,
                //recordCount : action.queueActivityData.length,
                //queueStart : state.queueStart + action.limitValue
            }

        case actionTypes.QUEUE_FORM_FIELD_ALTER_START:
            const updatedFile = [
                ...state.formDataValues
            ];
            const IndexK = updatedFile.findIndex(res => res.field_id === action.fieldData.field_id);
            if(IndexK > -1) {
                updatedFile[IndexK].field_value = JSON.parse(action.fieldData.activity_inline_data)[0].field_value;
            }
            return {
                ...state,
                formFieldAlter: false,
                formDataValues: updatedFile,
            }
        case actionTypes.QUEUE_FORM_FIELD_ALTER_SUCCESS:
            return {
                ...state,
                formFieldAlter: true,
            }
        case actionTypes.QUEUE_CHECK_FORM_ACTIVITYID_START:
            return {
                ...state,
                checkFormActivityData: [],
            }
        case actionTypes.QUEUE_CHECK_FORM_ACTIVITYID_SUCCESS:
            return {
                ...state,
                checkFormActivityData: action.formActivityIdData,
            }
        case actionTypes.QUEUE_FORMIDS_ON_NEWORDERFORM_START:
            return {
                ...state,
                formIdsOnOrderForm: [],
            }
        case actionTypes.QUEUE_FORMIDS_ON_NEWORDERFORM_SUCCESS:
            return {
                ...state,
                formIdsOnOrderForm: action.formIdsOnNewOrderFormData,
            }
        case actionTypes.QUEUE_SUBMITTED_FORMS_FETCH_START:
            return {
                ...state,
                submittedForms: [],
            }
        case actionTypes.QUEUE_SUBMITTED_FORMS_FETCH_SUCCESS:
            return {
                ...state,
                submittedForms: action.activityForms,
            }
        case actionTypes.QUEUE_CHECK_SUBMITTED_FORMS_FETCH_START:
            return {
                ...state,
                refinedSubmittedForms: null,
            }
        case actionTypes.QUEUE_CHECK_SUBMITTED_FORMS_FETCH_SUCCESS:
            return {
                ...state,
                refinedSubmittedForms: action.checkActivityForms,
            }
        case actionTypes.QUEUE_CAF_VALIDATION_DATA_START:

            return {
                ...state,
                validationData: []
            };
        case actionTypes.QUEUE_CAF_VALIDATION_DATA_SUCCESS:
            return {
                ...state,
                validationData: action.cafValidationData
            };
        case actionTypes.QUEUE_CAF_VALIDATION_FIELD_FETCH_START:
            return {
                ...state,
                loadingStart: true,
                fieldData: []
                //validationData : action.cafValidationData
            };
        case actionTypes.QUEUE_CAF_VALIDATION_FIELD_FETCH_SUCCESS:
            return {
                ...state,
                loadingStart: false,
                fieldData: action.fieldData
            };
        case actionTypes.QUEUE_CAF_APPROVAL_SUCCESS:
            //console.log("Modify Field",action.fieldData);
            const TempValidation = [...state.validationData];
            const IndexV = TempValidation.findIndex(res => res.field_id === action.fieldData.field_id);
            if(IndexV > -1) {
                TempValidation[IndexV].valid = true;
            }
            return {
                ...state,
                validationData: TempValidation
            }
        case actionTypes.CAF_VALIDATION_MODIFY_FIELD_DATA:
            //console.log("Input change prev",action.elementId);
            //action.fieldValue.persist();

            const updatedFormValidation = [...state.validationData];
            const newIndex = updatedFormValidation.findIndex(res => res.field_id === action.elementId);
            if(newIndex > -1) {
                const updatedFormElement = {
                    ...updatedFormValidation[newIndex]
                };
                updatedFormElement.value = action.fieldValue;
                updatedFormValidation[newIndex] = updatedFormElement;
                //console.log("field value", updatedFormValidation[newIndex]);
                return {
                    ...state,
                    validationData: updatedFormValidation,
                    //loadingFields :false
                }

            }
            return {
                ...state,

            }
        case actionTypes.QUEUE_FORM_VALUES_FETCH_START:

            return {
                ...state,
                formDataValues: []
            };
        case actionTypes.QUEUE_FORM_VALUES_FETCH_SUCCESS:
            return {
                ...state,
                formDataValues: action.fieldData
            };
        case actionTypes.QUEUE_DROPDOWN_FETCH_START:

            return {
                ...state,
                fieldDropDown: []
            };
        case actionTypes.QUEUE_DROPDOWN_FETCH_SUCCESS:
            return {
                ...state,
                fieldDropDown: action.fieldDropdownData
            };
        case actionTypes.QUEUE_FETCH_STATUS_START:

            return {
                ...state,
                statusList: []
            };
        case actionTypes.QUEUE_FETCH_STATUS_SUCCESS:
            return {
                ...state,
                statusList: action.statusList
            };
        case actionTypes.QUEUE_UNASSIGN_PARTICIPANT_START:

            return {
                ...state
            };
        case actionTypes.GET_WORKFORCE_LEVEL_FORMS_START:
            return {
                ...state,
                workforceLevelForms: [],
            }
        case actionTypes.GET_WORKFORCE_LEVEL_FORMS_SUCCESS:
            return {
                ...state,
                workforceLevelForms: action.data,
            }
        case actionTypes.SEARCH_WORKFORCE_LEVEL_FORMS_START:
            return {
                ...state,
                searchWorkforceLevelForms: [],
            }
        case actionTypes.SERACH_WORKFORCE_LEVEL_FORM_SUCCESS:
            return {
                ...state,
                searchWorkforceLevelForms: action.data,
            }
        case actionTypes.ACCOUNT_SUMMARY_MONTHLY_SUCCESS:
            return {
                ...state,
                montlySummary: action.accountSummary
            };
        case actionTypes.ACCOUNT_SUMMARY_YEARLY_SUCCESS:
            return {
                ...state,
                yearlySummary: action.accountSummary
            };
        case actionTypes.ACCOUNT_SUMMARY_QUARTERLY_SUCCESS:
            return {
                ...state,
                quarterlySummary: action.accountSummary
            };

        case actionTypes.MY_FOCUS_DATA_FETCH_START:
            return {
                ...state,
                myFocusCount: state.myFocusCount,
            }
        case actionTypes.MY_FOCUS_DATA_FETCH_SUCCESS:
            let count = state.myFocusCount
            let myFocusData = state.queueActivityList
            let flag = 0; // 0 = non-empty response
            if(action.myFocusData.length > 0 && !!action.myFocusData[0].count) {
                count = action.myFocusData[0].count
            }
            else {
                if(!action.myFocusData || action.myFocusData.length === 0) {
                    flag = 1; // 1 = Received empty Response
                }
                myFocusData = action.myFocusData;
                    count = count
            }
            return {
                ...state,
                myFocusCount: count,
                queueActivityList: myFocusData,
                searchRespFlag: flag
            }
        default:
            return state;


    }

}
export default reducer;