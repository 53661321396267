import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { errorOccurred: false }
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true })
    //logErrorToMyService(error, info)
    console.log("componentDidCatch: Error");
    console.log(error);
    console.log(info);
    console.log("componentDidCatch: Info");
  }

  render() {
    return this.state.errorOccurred ? <div 
      style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:'20%'}}>
          <h1>Dear User!! Looks like we are unable to open the user because of network settings. <br></br> Please check with Network Admin to proceed further.</h1></div> : this.props.children
  }
}

export default ErrorBoundary;