import * as actionTypes from './actionTypes';
import AxiosWeb from '../../axios_ext';

export const alterFormFieldStart = (fieldData) => {
    return {
        type: actionTypes.QUEUE_FORM_FIELD_ALTER_START,
        fieldData: fieldData,
    };
};

export const alterFormFieldSuccess = () => {
    return {
        type: actionTypes.QUEUE_FORM_FIELD_ALTER_SUCCESS,
    };
};

// only 1 form field alter at a time
export const alterFormField = (requestData) => {
    return dispatch => {
        dispatch(alterFormFieldStart(requestData));
        AxiosWeb.post('form/activity/alter',requestData)
            .then(res => {
                if(res.status === 200) {
                    dispatch(alterFormFieldSuccess());
                    if(!requestData.ignoreFetch) {
                        setTimeout(() => {
                            dispatch(getFormDataWithValues({
                                organization_id: requestData.organization_id,
                                account_id: requestData.account_id,
                                workforce_id: requestData.workforce_id,
                                asset_id: requestData.asset_id || requestData.auth_asset_id,
                                auth_asset_id: requestData.auth_asset_id,
                                asset_token_auth: requestData.asset_token_auth,
                                form_id: requestData.form_id,
                                form_transaction_id: requestData.form_transaction_id,
                                start_from: 0,
                                limit_value: 50
                            }));
                        },5000);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                //dispatch(tasksFetchFail(err));
            })

    };
};


export const checkFormActivityIdStart = () => {
    return {
        type: actionTypes.QUEUE_CHECK_FORM_ACTIVITYID_START,
    };
};
export const checkFormActivityIdSuccess = (formActivityIdData) => {
    return {
        type: actionTypes.QUEUE_CHECK_FORM_ACTIVITYID_SUCCESS,
        formActivityIdData: formActivityIdData,
    };
};

export const checkFormActivityId = (requestcheckData,requestFormFieldsData) => {
    return dispatch => {
        dispatch(checkFormActivityIdStart());
        const fetchDataStr = requestcheckData;
        AxiosWeb.post('activity/form_transaction/check',fetchDataStr)
            .then(res => {
                const checkData = res.data.response;
                dispatch(checkFormActivityIdSuccess(checkData));

            })
            .catch(err => {
                console.log(err);
                //dispatch(tasksFetchFail(err));
            })

    };
};

export const queueSubmittedFormsFetchStart = () => {
    return {
        type: actionTypes.QUEUE_SUBMITTED_FORMS_FETCH_START,
    };
};
export const queueSubmittedFormsFetchSuccess = (formsList) => {
    return {
        type: actionTypes.QUEUE_SUBMITTED_FORMS_FETCH_SUCCESS,
        activityForms: formsList,
    };
};

export const queueCheckSubmittedFormsFetchStart = () => {
    return {
        type: actionTypes.QUEUE_CHECK_SUBMITTED_FORMS_FETCH_START,
    };
};
export const queueCheckSubmittedFormsFetchSuccess = (checkFormsList) => {
    return {
        type: actionTypes.QUEUE_CHECK_SUBMITTED_FORMS_FETCH_SUCCESS,
        checkActivityForms: checkFormsList,
    };
};


export const getQueueSubmittedFormsList = (requestData) => {
    if(requestData.organization_id === 860) { // only for vodafone
        return dispatch => {
            dispatch(queueSubmittedFormsFetchStart());
            const fetchDataStr = requestData;
            let allFormArray = [];
            const formParams = {
                organization_id: requestData.organization_id,
                account_id: requestData.account_id,
                workforce_id: requestData.workforce_id,
                asset_id: requestData.asset_id,
                asset_token_auth: requestData.asset_token_auth,
                form_id: 873,
                activity_type_category_id: 9,
            }
            AxiosWeb.post('activity/category/form/mapping',formParams)
                .then(res => {
                    const formIdsOnNewOrderFormData = res.data.response;
                    for(let key in formIdsOnNewOrderFormData) {
                        const entityForm = {
                            id: formIdsOnNewOrderFormData[key],
                            form_name: key,
                            submitted_by: "",
                            datetime: "",
                            submitted: false,
                            data_inline: "",
                            activity_id: 0,
                            form_submission_id: formIdsOnNewOrderFormData[key].form_submission_type_id,
                            form_submission_name: formIdsOnNewOrderFormData[key].form_submission_type_name,
                        }
                        allFormArray.push(entityForm);
                    }
                    AxiosWeb.post('activity/timeline/form/list',fetchDataStr)
                        .then(res => {
                            const queueData = res.data.response;
                            let finalFormArray = allFormArray;
                            allFormArray.forEach((form,index) => {
                                var indexFind = queueData.findIndex(res => res.data_form_id === form.id);
                                if(indexFind > -1) {
                                    finalFormArray[index].submitted = true;
                                    finalFormArray[index].submitted_by = queueData[indexFind].operating_asset_first_name;
                                    finalFormArray[index].data_inline = queueData[indexFind].data_entity_inline;
                                    finalFormArray[index].datetime = queueData[indexFind].log_datetime;
                                    finalFormArray[index].activity_id = queueData[indexFind].activity_id;
                                    finalFormArray[index].form_transaction_id = queueData[indexFind].data_form_transaction_id;
                                }
                            })
                            dispatch(queueSubmittedFormsFetchSuccess(finalFormArray));
                        })
                        .catch(err => {
                            console.log(err);
                        })
                })
                .catch(err => {
                    console.log(err);
                })


        };
    }
    else {
        return dispatch => {
            dispatch(queueSubmittedFormsFetchStart());
            dispatch(queueCheckSubmittedFormsFetchStart());
            const fetchDataStr = requestData;
            let allFormArray = [];
            AxiosWeb.post('/form/access/list',fetchDataStr)
                .then(res => {
                    const formIdsOnNewOrderFormData = res.data.response;
                    for(let key in formIdsOnNewOrderFormData) {
                        const entityForm = {
                            id: formIdsOnNewOrderFormData[key].form_id,
                            form_name: formIdsOnNewOrderFormData[key].form_name,
                            submitted_by: "",
                            datetime: "",
                            submitted: false,
                            data_inline: "",
                            activity_id: 0,
                            form_submission_id: formIdsOnNewOrderFormData[key].form_submission_type_id,
                            form_submission_name: formIdsOnNewOrderFormData[key].form_submission_type_name,
                        }
                        allFormArray.push(entityForm);
                    }
                    AxiosWeb.post('activity/timeline/form/list',fetchDataStr)
                        .then(res => {
                            const queueData = res.data.response;
                            let finalFormArray = allFormArray;
                            allFormArray.forEach((form,index) => {
                                var indexFind = queueData.findIndex(res => res.data_form_id === form.id);

                                if(indexFind > -1) {
                                    finalFormArray[index].submitted = true;
                                    finalFormArray[index].submitted_by = queueData[indexFind].operating_asset_first_name;
                                    finalFormArray[index].data_inline = queueData[indexFind].data_entity_inline;
                                    finalFormArray[index].datetime = queueData[indexFind].log_datetime;
                                    finalFormArray[index].activity_id = queueData[indexFind].activity_id;
                                    finalFormArray[index].form_transaction_id = queueData[indexFind].data_form_transaction_id;
                                }
                            })
                            dispatch(queueSubmittedFormsFetchSuccess(finalFormArray));
                            let requestParams =
                            {
                                organization_id: requestData.organization_id,
                                account_id: requestData.user_account_id,
                                workforce_id: requestData.user_workforce_id,
                                asset_id: requestData.asset_id,
                                asset_token_auth: requestData.asset_token_auth,
                                target_asset_id: requestData.asset_id,
                                activity_type_id: requestData.activity_type_id,
                                flag: 0,
                                forms: finalFormArray
                            }
                            AxiosWeb.post('/form/entity/access/asset/check',requestParams)
                                .then(res => {
                                    const checkqueueData = res.data.response;
                                    dispatch(queueCheckSubmittedFormsFetchSuccess(checkqueueData));
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                        .catch(err => {
                            console.log(err);
                            //dispatch(tasksFetchFail(err));
                        })
                    //dispatch(formIdsOnNewOrderFormData);
                    //dispatch(formIdsOnNewOrderFormSuccess(formIdsOnNewOrderFormData));
                })
                .catch(err => {
                    console.log(err);
                    //dispatch(tasksFetchFail(err));
                })


        };
    }

};

export const getCAFValidationDataStart = () => {
    return {
        type: actionTypes.QUEUE_CAF_VALIDATION_DATA_START,
    };
};
export const getCAFValidationDataSuccess = (cafData) => {
    return {
        type: actionTypes.QUEUE_CAF_VALIDATION_DATA_SUCCESS,
        cafValidationData: cafData,
    };
};

export const getCAFValidationData = (requestData) => {

    return (dispatch,getState) => {
        const {fieldList} = getState().forms;
        dispatch(getCAFValidationDataStart(requestData));
        const fetchDataStr = requestData;
        const ValidatedArray = [];
        AxiosWeb.post('activity/form/validation/data',fetchDataStr)
            .then(res => {
                const FormDefinition = res.data.response;
                if(FormDefinition.length > 0) {
                    const FilledData = FormDefinition[0].activity_inline_data;
                    //console.log("Filled Data",FilledData);
                    Object.keys(fieldList).forEach(key => {
                        const keyId = key.slice(0,-1);
                        const indexData = FilledData.findIndex(x => Number(x.field_id) === Number(keyId));
                        if(indexData > -1) {
                            fieldList[key].value = FilledData[indexData].field_value;
                            fieldList[key].valid = !!FilledData[indexData].field_validated;
                        }
                        fieldList[key].field_id = Number(keyId);
                        if(fieldList[key].validationEnabled) {
                            ValidatedArray.push(fieldList[key]);
                        }

                    })
                }
                //console.log("Final Array",ValidatedArray);
                dispatch(getCAFValidationDataSuccess(ValidatedArray));
            })
            .catch(err => {console.log(err);})
    }

}


export const getDropDownDefinationStart = () => {
    return {
        type: actionTypes.QUEUE_DROPDOWN_FETCH_START,
    };
};
export const getDropDownDefinationSuccess = (fieldDropdownData) => {
    return {
        type: actionTypes.QUEUE_DROPDOWN_FETCH_SUCCESS,
        fieldDropdownData: fieldDropdownData,
    };
};
export const getDropDownDefination = (requestData) => {

    return dispatch => {
        dispatch(getDropDownDefinationStart());
        AxiosWeb.post('form/field/combo/list',requestData)
            .then(res => {
                const FieldDDData = res.data.response;
                // console.log("Field Value",FieldData);
                dispatch(getDropDownDefinationSuccess(FieldDDData));
            })
            .catch(err => {console.log(err)})
    }
}

export const getFormDataWithValuesStart = () => {
    return {
        type: actionTypes.QUEUE_FORM_VALUES_FETCH_START,
    };
};
export const getFormDataWithValuesSuccess = (fieldValues) => {
    return {
        type: actionTypes.QUEUE_FORM_VALUES_FETCH_SUCCESS,
        fieldData: fieldValues,
    };
};
export const getFormDataWithValues = (requestData) => {
    return dispatch => {
        dispatch(getFormDataWithValuesStart());
        let resultSetArr = [];
        AxiosWeb.post('activity/form/validation/data',requestData).
            then(res => {
                let resultSet = res.data.response;
                if(resultSet.length > 0) {
                    resultSet = res.data.response[0].activity_inline_data;

                    if(resultSet.length > 0) {
                        resultSet.forEach(element => {
                            element.activity_id = res.data.response[0].activity_id;
                            element.activity_type_id = res.data.response[0].activity_type_id;
                            resultSetArr.push(element);
                        });
                    }
                }
                //console.log(resultSet);
                dispatch(getFormDataWithValuesSuccess(resultSetArr));

            })
            .catch(err => {console.log(err)})
    }
}
